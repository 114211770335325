import React from 'react';

const HomeDropdown = (props) => {
  const [selectedOption, setSelectedOption] = React.useState('news');

  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.selectedValue}
    >
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="news"
      >
        News
      </option>
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="live_blog"
      >
        Live Blog
      </option>
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="videos"
      >
        Videos
      </option>

      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="f"
      >
        Feature Card
      </option>
    </select>
  );
};

export default HomeDropdown;

// import React from 'react';
// import { Link, useHistory } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     display: 'block',
//     marginTop: theme.spacing(2),
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
// }));

// function HomeDropdown() {
//   const classes = useStyles();
//   const [home, setHome] = React.useState('');
//   const [open, setOpen] = React.useState(false);

//   const handleChange = (event) => {
//     setHome(event.target.value);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   return (
//     <div>
//       <FormControl className={classes.formControl}>
//         <InputLabel id="demo-controlled-open-select-label">Home</InputLabel>
//         <Select
//           labelId="demo-controlled-open-select-label"
//           id="demo-controlled-open-select"
//           open={open}
//           onClose={handleClose}
//           onOpen={handleOpen}
//           value={home}
//           onChange={handleChange}
//         >
//           <Link to="/home-page">
//             <MenuItem value={'news'}>News</MenuItem>
//           </Link>
//           <Link to="/HomeVideoComponent">
//             <MenuItem value={'videos'}>Videos</MenuItem>
//           </Link>
//           <Link to="/tagged-feature">
//             <MenuItem value={'feature-card'}>Feature Card</MenuItem>
//           </Link>
//         </Select>
//       </FormControl>
//     </div>
//   );
// }
// export default HomeDropdown;
