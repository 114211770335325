import React from 'react';

const FeaturedDropdown = (props) => {
  const [selectedOption, setSelectedOption] = React.useState('Videos');

  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.selectedValue}
    >
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="Video"
      >
        Videos
      </option>
      {/* <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="Image"
      >
        Images
      </option> */}
    </select>
  );
};

export default FeaturedDropdown;

