import React from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar'
import ModuleManager from './ModuleManager'
import AdminSideBar from './AdminSideBar'
import UserManager from './UserManager'
import EditPermission from './EditPermission'
import ViewRequest from './ViewRequest'

const AdminPanel = (props) => {
    return (
        <div>
            <AdminSideBar/>
            <div className="relative md:ml-64 bg-blueGray-100"  style={{paddingTop:10}}>
                <div className="flex flex-wrap">
                    <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                  
                    <ModuleManager color="light" />
                    
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPanel
