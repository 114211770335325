import AppConstants from '../constants';
import LoginAppConstants from '../constants/LoginAppConstants';
import AxiosInstance from './http.handlers';
import AxiosMessageInstance from './http.messageHandlers';

const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language')
const headers = { 'cms-access-token': accessToken, source: 'cms',language:CURRENT_LANGUAGE };
export const GetAuthor = async (_id) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.AUTHOR.GET_AUTHOR}/${_id}`,{headers}
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetAllAuthor = async () => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.AUTHOR.GET_ALL_AUTHOR}`,{headers}
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetAllAuthorList = async () => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.AUTHOR.GET_ALL_AUTHOR_LIST}`,{headers}
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UpdateAuthor = async (body) => {
  return await AxiosMessageInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.AUTHOR.UPDATE_AUTHOR}`,
    body,{headers}
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddAuthor = async (body) => {
  return await AxiosMessageInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.AUTHOR.ADD_AUTHOR}`,
    body,{headers}
  )
    .then((response) => response)
    .catch((error) => error);
};
export const DeleteAuthor = async (id) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.AUTHOR.DELETE_AUTHOR}/${id}`,{headers}
  )
    .then((response) => response)
    .catch((error) => error);
};
