import { VideosActionTypes } from "../action-types";

const initialState = {
  docs: [],
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  page: 1,
  totalDocs: 0,
  totalPages: 0,
  isVideoPaused: false,
  activeVideoReference: null,
};

const ytvideosReducer = (state = initialState, action) => {
  switch (action.type) {
    case VideosActionTypes.VIDEOS_LIST:
      const newData = [];
      action.payload.docs.forEach(newItem => {
        const isItemFound = state.docs.some(
          (item) => item.id === newItem.id
        );
        if (!isItemFound) {
          newData.push(newItem)
        }
      });
      return Object.assign({}, state, {
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        limit: action.payload.limit,
        page: action.payload.page,
        totalDocs: action.payload.totalDocs,
        totalPages: action.payload.totalPages,
        docs: [...state.docs, ...newData],
      });
    case VideosActionTypes.VIDEOS_LIST_FILTER:
      return Object.assign({}, state, {
        ...action.payload,
        docs: action.payload.docs,
      });
    case VideosActionTypes.PLAY_VIDEO:
      return Object.assign({}, state, {
        isVideoPaused: false,
        activeVideoReference: action.payload.activeVideoReference,
      });
    case VideosActionTypes.PAUSE_VIDEO:
      return Object.assign({}, state, {
        isVideoPaused: action.payload,
        videoIndex: action.payload.videoIndex,
      });
    case VideosActionTypes.RESET_VIDEOS_LIST:
      return Object.assign({}, state, {
        docs: [],
        hasNextPage: false,
        hasPrevPage: false,
        limit: 10,
        page: 1,
        totalDocs: 0,
        totalPages: 0,
        isVideoPaused: false,
        videoIndex: 0,
      });

    case VideosActionTypes.VIDEO_TOGGLE_STATUS:
      const docs = [...state.docs];
      docs[action.payload.index].status = action.payload.status;
      return Object.assign({}, state, {
        docs,
      });;
    default:
      return state;
  }
};
export default ytvideosReducer;
