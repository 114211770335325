import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { API_BASE_PATH, OLD_BASE_PATH } from '../../constants/workerConstants';

import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatchSelectedLeague } from '../../redux/action-dispatchers/leagues.dispatchers';
import LoginAppConstants from '../../constants/LoginAppConstants';
import { GetAllTagLeagues } from '../../handlers/leagues.handlers';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const LeaguesDropDown = (props) => {
  const [docs, setDocs] = useState([]);

  const getTopLeagues = () => {
    // Axios.get(`${API_BASE_PATH}leagues/leagueWatchList`, {
    //   headers: { 'cms-access-token': accessToken, source: 'cms' },
    // })
    //   .then(async (response) => {
    //     let leagueData = await response.data.data.data;
    //     setDocs(leagueData);
    //   })
    //   .catch((error) => error);
    GetAllTagLeagues(
        props.sports?.length>0?props?.sports:"All",
        "search"
      )
      .then((res)=>{setDocs(res)})
      .catch((error)=>console.log(error))
  };
  useEffect(() => {
    getTopLeagues();
  }, [props?.sports]);
  const onChange = (event) => {
    // const foundObject = _.find(docs, ['comp_id', event.target.value]);

    props.onChange &&
      props.onChange(event.target.value);
    // getTopLeagues();
    dispatchSelectedLeague({
      selectedLeague: event.target.value,
      selectedLeagueFilter: event.target.value,
    //   selectedLeagueId: foundObject._id,
    });
  };
  //
  return (
    <>
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        width:"250px",
        borderRadius: 10,
      }}
      value={props.leaguesReducer.selectedLeague}
      onChange={onChange}
    >
      <option value="All">Select League</option>
      {docs?.data?.map((league) => (
        <>
          <option value={league.comp_name.toLowerCase()=="all"?"all":league.comp_id}>
            {league.comp_name}
          </option>
        </>
      ))}

      {/* {props.leaguesReducer.leagues.map((league) => (
       league.abbr === "All" ? <></> : <option value={league.comp_id}>{league.abbr}</option>
      ))} */}
    </select>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leaguesReducer: state.leaguesReducer,
    selectedLeagueFilter: state.leaguesReducer.selectedLeagueFilter,
  };
};

export default connect(mapStateToProps)(LeaguesDropDown);
