import AppConstants from '../constants';
import AxiosInstance from './http.handlers';


export const AddInsights = async (body,headers) =>{
    return await AxiosInstance.post(AppConstants.WorkerConstants.API_ROUTES.GALLERY.ADD_INSIGHTS,body,
        {
            headers
        }
        ).then((response)=>response).catch((error)=>error) ;

 
    }

 export const ToggleIsPublished = async (body)=>{
    return await AxiosInstance.post(AppConstants.WorkerConstants.API_ROUTES.GALLERY.TOGGLE_IS_PUBLISHED_INSIGHTS,body).then((response)=>response).catch(error=>error)
 }   
 export const UpdateInsights = async (body)=>{
    return await AxiosInstance.post(AppConstants.WorkerConstants.API_ROUTES.GALLERY.UPDATE_INSIGHTS,body).then((response)=>response).catch(error=>error)
 }   
 export const DeleteInsights = async (body)=>{
    return await AxiosInstance.post(AppConstants.WorkerConstants.API_ROUTES.GALLERY.DELETE_INSIGHT,body).then((response)=>response).catch(error=>error)
 }  
 export const UpdateHomeInsightsRanking = async (body)=>{
    return await AxiosInstance.post(AppConstants.WorkerConstants.API_ROUTES.GALLERY.UPDATE_HOME_INSIGHT_RANKING,body).then((response)=>response).catch(error=>error)
 }  
 export const GetPaginatedInsights = async (page,limit)=>{
    return await AxiosInstance.get(`${AppConstants.WorkerConstants.API_ROUTES.GALLERY.GET_ALL_INSIGHTS}/${page}/${limit}`).then((response)=>response).catch(error=>error)
 }  
 export const GetInsightsInfo = async (id)=>{
    return await AxiosInstance.get(`${AppConstants.WorkerConstants.API_ROUTES.GALLERY.GET_INSIGHT_INFO}/${id}`).then((response)=>response).catch(error=>error)
 }  
 export const GetPaginatedInsightsHome = async (isHomeTagged)=>{
    return await AxiosInstance.get(`${AppConstants.WorkerConstants.API_ROUTES.GALLERY.TOGGLE_IS_HOME_TAGGED}/${isHomeTagged}`).then((response)=>response).catch(error=>error)
 }  
  

