import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Checkbox } from '@material-ui/core';
import Sidebar from '../../Sidebar/Sidebar';
import { useHistory } from 'react-router-dom';
import CropperModal from '../../CropperModal';
import {GetInsightsInfo, UpdateInsights} from '../../../handlers/optaGraphics.handlers'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
import ConfirmationModal from '../../ConfirmationModal';
import LeaguesDropDown from '../../Dropdowns/LeaguesDropDown';
import FilteredLeagueDropDown from '../../Dropdowns/FilteredLeagueDropDown';
import SportsDropDown from '../../Dropdowns/SportsDropDown';
import { dispatchSelectedLeague } from '../../../redux/action-dispatchers/leagues.dispatchers';
import { dispatchSelectedSports } from '../../../redux/action-dispatchers/sports.dispatchers';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const EditInsight = ()=>{
    const { id } = useParams();
    const [insightInfo, setInsightInfo] = useState(undefined);
    const [showCropper, setShowCropper] = useState(false);
    const [isHomeChecked, setIsHomeChecked] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [taggedLeague, setTaggedLeague] = useState(false);
    const [changeLeague, setChangeLeague] = useState(false);
    const [changeAllLeague, setChangeAllLeague] = useState(false);
    const [taggedSports, setTaggedSports] = useState(false);
    const [changeSports, setChangeSports] = useState(false);
    const [sportsId, setSportsId] = useState("");
    const history = useHistory();
    const getInsights = ()=>{
        GetInsightsInfo(id).then((response)=>{
            setInsightInfo(response.data) 
            setIsHomeChecked(response.data.isHomeTagged)  
            setIsPublished(response.data.isPublished) 
            setTaggedLeague(response.data.comp_id ? response.data.comp_id.comp_name :'Select league')        
            setTaggedSports(response.data.sports ? response.data.sports.name :'Select Sports')
            setSportsId(response?.data?.sports?._id?response?.data?.sports?._id:"all")
    })
    }

    const updateInsight = (body)=>{
        UpdateInsights(body).then((response)=>{
            if(response.status){
            alert(response.message)
            getInsights();
            setTaggedLeague('Select league')        
            setTaggedSports('Select Sports')
        }
    })
    }
    const handleBack = () => {
        history.goBack();
      };
      const homeCheckHandler = (event)=>{
        const body = {
            _id: id,
            isHomeTagged:event.target.checked,
        }

        if(event.target.checked){
          setIsHomeChecked(true)
        }
        else
        setIsHomeChecked(false)

        updateInsight(body)
      }
      
      const isPublishedHandler = (event)=>{
        const body={
            _id: id,
            isPublished:event.target.checked
        }
        if(event.target.checked){
            setIsPublished(true)
          }
          else
          setIsPublished(false)
  
          updateInsight(body)
        }
        const onLeagueSelected = (league, uuid,_id) => {
            setChangeLeague(false);
            const body = {
                _id:id,
                comp_id:_id,
            }
            updateInsight(body)
        };
        const onSportsSelected = (value) => {
            setChangeSports(false);
            setSportsId(value)
            const body = {
                _id:id,
                sports:value,
            }
            updateInsight(body)
          };
    useEffect(()=>{
        getInsights()
    },[])
    return(<>
    <Sidebar />
    <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {insightInfo &&(
            <>
            <div
              className=" pb-4"
              style={{
                cursor: 'pointer',
                marginLeft: '-20px',
                width: '30px',
              }}
              onClick={handleBack}
            >
                    <ArrowBackIcon />
                  </div>
                  <div
                    style={{ display: 'inline-block', verticalAlign: 'top' }}
                  >
                  <img src={insightInfo.imageUrl} style={{ height: 300, width: 220,marginTop:'10px' }} />  

                  </div>
                  <div><span>Home</span>
                  <Checkbox
                        checked={isHomeChecked}
                        onChange = {homeCheckHandler}
                        color="default"
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                    />
                </div>
                  <div><span>Publish</span>
                  <Checkbox
                        checked={isPublished}
                        onChange = {isPublishedHandler}
                        color="default"
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                    />
                </div>
                <div className="flex">

                {!changeSports && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: '500',
                          }}
                        >
                          Tagged Sports:{' '}
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeSports(true);
                              dispatchSelectedSports({
                                selectedSports:
                                  insightInfo.sports && insightInfo.sports._id
                                    ? insightInfo.sports._id
                                    : 'All',
                                selectedSportsFilter:
                                  insightInfo.sports && insightInfo.sports._id
                                    ? insightInfo.sports._id
                                    : 'All',
                              });
                            }}
                          ></i>
                          {taggedSports}
                        </div>
                      )}

                      {changeSports && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <SportsDropDown onChange={onSportsSelected} />
                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              margin: 8,
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeSports(false);
                            }}
                          ></i>
                        </div>
                      )}
                      {!changeLeague && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            marginLeft: '80px',
                            fontWeight: '500',
                          }}
                        >
                          Tagged League:{' '}
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeLeague(true);
                             
                            }}
                          ></i>
                          {taggedLeague}
                        </div>
                      )}

                      {changeLeague && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <FilteredLeagueDropDown onChange={onLeagueSelected} sports={sportsId}/>

                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              margin: 8,
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeLeague(false);
                              dispatchSelectedLeague({
                                selectedLeague: insightInfo.league,
                                selectedLeagueFilter: insightInfo.league,
                              });
                            }}
                          ></i>
                        </div>
                      )}

                      
                      </div>

</>
)}
          </div>
          </div>
          </div>
    
    </>)
}
export default EditInsight;