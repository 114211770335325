import { MatchesActionTypes } from '../action-types';

const initialState = {
  matchesTag: [],
  selectedMatchesTag: '',
  matchesSearch: '',
  matchesPage: 1,
  selectedMatchesFilterTag: '',
  selectedMatchesIDTag: '',
  totalPagesMatches: 1,
};

const mathcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MatchesActionTypes.GET_ALL_MATCHES_TAG:
      return Object.assign({}, state, {
        matchesTag: action.payload,
      });
    case MatchesActionTypes.CHANGE_SEARCH_MATCHES:
      return Object.assign({}, state, {
        matchesSearch: action.payload,
      });
    case MatchesActionTypes.MATCHES_CURRENT_PAGE:
      return Object.assign({}, state, {
        matchesPage: action.payload,
      });
    case MatchesActionTypes.NUMBER_OF_PAGES:
      return Object.assign({}, state, {
        totalPagesMatches: action.payload,
      });
    case MatchesActionTypes.CHANGE_SELECTED_MATCHES_TAGGING:
      return Object.assign({}, state, {
        selectedMatchesTag: action.payload.selectedMatchesTag,
        selectedMatchesFilterTag: action.payload.selectedMatchesFilterTag,
        selectedMatchesIDTag: action.payload.selectedMatchesIDTag,
      });
    default:
      return state;
  }
};

export default mathcesReducer;
