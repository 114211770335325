import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const categoryDataFromGA = async (body, headers) => {
    return await AxiosInstance.post(
      `${AppConstants.WorkerConstants.API_ROUTES.GOOGLE_ANALYTICS_MANAGER.CATEGORY_DATA_FROM_GA}`,
      body,
      {
        headers,
      }
    )
      .then((response) => response)
      .catch((error) => error);
  };
export const authorDataFromGA = async (body, headers) => {
    return await AxiosInstance.post(
      `${AppConstants.WorkerConstants.API_ROUTES.GOOGLE_ANALYTICS_MANAGER.AUTHOR_DATA_FROM_GA}`,
      body,
      {
        headers,
      }
    )
      .then((response) => response)
      .catch((error) => error);
  };
export const typeDataFromGA = async (body, headers) => {
    return await AxiosInstance.post(
      `${AppConstants.WorkerConstants.API_ROUTES.GOOGLE_ANALYTICS_MANAGER.TYPE_DATA_FROM_GA}`,
      body,
      {
        headers,
      }
    )
      .then((response) => response)
      .catch((error) => error);
  };