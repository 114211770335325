import { TwitterNativeActionTypes } from "../action-types";

const initialState = {
  docs: {},
  video: "",
  thumbnail: "",
  title: "",
  description: "",
  selectedTag: "",
  twitterMenuTxt: "",
};

const twitterNativeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TwitterNativeActionTypes.TWITTER_VIDEOS_DOCS:
      return Object.assign({}, state, {
        ...action.payload,
        video: action.payload.video,
        thumbnail: action.payload.thumbnail,
        title: action.payload.title,
        description: action.payload.description,
      });
    case TwitterNativeActionTypes.TWITTER_VIDEOS_ALL_INFO:
      return Object.assign({}, state, {
        ...action.payload,
        docs: action.payload,
      });
    case TwitterNativeActionTypes.GET_SELECTED_TAG:
      return Object.assign({}, state, {
        ...action.payload,
        selectedTag: action.payload,
      });
    case TwitterNativeActionTypes.GET_TWITTER_MENU_TEXT:
      return Object.assign({}, state, {
        ...action.payload,
        twitterMenuTxt: action.payload,
      });
    default:
      return state;
  }
};

export default twitterNativeReducer;
