import { configStore } from '../store';
import { TeamsActionTypes } from '../action-types';

export const dispatchTeamsForTagging = (payload) => {
  configStore.dispatch({
    payload,
    type: TeamsActionTypes.GET_ALL_TEAMS_TAG,
  });
};
export const dispatchTeamsForTaggingVideos = (payload) => {
  configStore.dispatch({
    payload,
    type: TeamsActionTypes.GET_ALL_TEAMS_TAG_VIDEOS,
  });
};
export const dispatchTeamsTaggedSuccess = (payload) => {
  configStore.dispatch({
    payload,
    type: TeamsActionTypes.TEAMS_TAGGING_SUCCESS,
  });
};
export const dispatchTeamsTagged = (payload) => {
  configStore.dispatch({
    payload,
    type: TeamsActionTypes.GET_ALL_TAGGED_TEAMS,
  });
};
