import React, { useEffect } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import TeamsTable from '../../../components/Cards/TeamsTable';

const TeamManager = (props) => {

  useEffect(()=>{
      
  },[])

  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <TeamsTable/>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamManager;
