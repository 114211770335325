import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetTwitterTagsForFilter } from "../../handlers/twitter.handlers";
import { dispatchSelectedTwitterTag } from "../../redux/action-dispatchers/twitterNative.dispatchers";

const TwitterTagFilterDropdown = (props) => {
  const [docs, setDocs] = useState([]);
  const onChange = (event) => {
    props.onChange && props.onChange(event.target.value);
    dispatchSelectedTwitterTag(event.target.value);
  };
  useEffect(() => {
    GetTwitterTagsForFilter()
      .then((response) => {
        console.log("response tag dropdown", response);
        setDocs(response.data);
      })
      .catch((error) => error);
  }, []);
  return (
    <select
      style={{
        // marginLeft: 20,
        border: "2px solid #acb9ce",
        borderRadius: 10,
      }}
      value={props.twitterNativeReducer.selectedTag}
      onChange={onChange}
    >
      <option value="">All Tags</option>
      {docs?.map((tag) => (
        <option value={tag.tag}>{tag.menuTxt}</option>
      ))}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    twitterNativeReducer: state.twitterNativeReducer,
  };
};

export default connect(mapStateToProps)(TwitterTagFilterDropdown);
