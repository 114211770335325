import AppConstants from '../constants';
import LoginAppConstants from '../constants/LoginAppConstants';
import AxiosInstance from './http.handlers';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = { 'cms-access-token': accessToken, source: 'cms',language:CURRENT_LANGUAGE };
export const AddOrRemoveHomeSpecial = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.HOME_SPECIAL}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetAllHomeSpecial = async () => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.GET_ALL_HOME_SPECIAL}`
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetLiveBlog = async () => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.GET_HOME_LIVE_BLOG}`, { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateSpecialRanking = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.REORDER_HOME_SPECIAL}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const addHomeLiveBlogHandler = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.ADD_HOME_LIVE_BLOG}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
