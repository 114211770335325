import axios from "axios";
import LoginAppConstants from "../constants/LoginAppConstants";
import AppConstants from "../constants";
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);

const AxiosTwitterInstanse = axios.create({
  baseURL: AppConstants.WorkerConstants.TWITTER_BASE_PATH,
  timeout: -1,
  headers: {
    "Content-Type": "application/json",
    "cms-access-token": accessToken,
    source: "cms",
  },
});
AxiosTwitterInstanse.interceptors.response.use(
  (response) => ({
    status: true,
    message: response.message,
    data: response.data,
  }),
  (error) => {
    return {
      statusCode: error?.response ? error?.response?.data.statusCode : 400,
      status: false,
      message: error?.response ? error?.response?.data : error.message,
    };
  }
);

export default AxiosTwitterInstanse;
