import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  DeleteInsights,
  GetPaginatedInsights,
  UpdateInsights,
} from '../../../handlers/optaGraphics.handlers';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router';
import { css } from '@emotion/core';
import SyncLoader from 'react-spinners/SyncLoader';
import { connect } from 'react-redux';
import { _dateTimeFormat } from '../../../constants/appDefaults';
import ConfirmationModal from '../../ConfirmationModal';
import { Checkbox } from '@material-ui/core';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const AllOptaInsights = (props) => {
  const mHistory = useHistory();
  const [isLoadingMore, setIsLoading] = useState(false);
  const [checkedInsight, setCheckInsight] = useState([]);
  const [checkedPublished, setCheckedPublished] = useState([]);
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [insightId, setInsightId] = useState();
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });

  const AllPginatedInsights = () => {
    setIsLoading(true);
    GetPaginatedInsights(page, 10).then((response) => {
      setIsLoading(false);
      if (response.status) {
        setIsLoading(false);
        setDocs(response.data?.docs);
      }

      setTotalPages(response.data?.totalPages);
    });
  };

  const onView = (insightId) => {
    mHistory.push(`/opta-graphics/${insightId}`);
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };
  const showModalForPublish = (id) => {
    let title = 'Delete Insight?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    const body = {
      _id: insightId,
    };

    DeleteInsights(body)
      .then((res) => {
        if (res.status) {
          alert('Insight Deleted Successfully');
          AllPginatedInsights();
        } else {
          alert('Something went wrong');
        }
      })
      .catch((error) => error);
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  // const UpdateInsightHandler = (event)=>{
  //   const body = {
  //     _id: id,
  //     isHomeTagged:event.target.checked,
  //   }
  //   UpdateInsights(body).then((response)=>{
  //     if(response.status){
  //     alert(response.message)
  //     AllPginatedInsights();
  //     }}
  // )}
  const UpdateInsightHandler = (event, id) => {
    const body = {
      _id: id,
      isHomeTagged: event.target.checked,
    };
    const recordFound = _.find(checkedInsight, (item) => item === id);

    if (recordFound) {
      const insightList = _.filter(checkedInsight, (item) => item !== id);

      setCheckInsight(insightList);
      UpdateInsights(body)
        .then((response) => {
          if (response.status) {
            alert(response.message);
            AllPginatedInsights();
          }
        })
        .catch((error) => error);
    } else {
      setCheckInsight([...checkedInsight, id]);
      UpdateInsights(body)
        .then((response) => {
          if (response.status) {
            alert(response.message);
            AllPginatedInsights();
          }
        })
        .catch((error) => error);
    }
  };
  const UpdateInsightPublishHandler = (event, id) => {
    const body = {
      _id: id,
      isPublished: event.target.checked,
    };
    const recordFound = _.find(checkedPublished, (item) => item === id);

    if (recordFound) {
      const insightList = _.filter(checkedPublished, (item) => item !== id);

      setCheckedPublished(insightList);
      UpdateInsights(body)
        .then((response) => {
          if (response.status) {
            alert(response.message);
            AllPginatedInsights();
          }
        })
        .catch((error) => error);
    } else {
      setCheckedPublished([...checkedPublished, id]);
      UpdateInsights(body)
        .then((response) => {
          if (response.status) {
            alert(response.message);
            AllPginatedInsights();
          }
        })
        .catch((error) => error);
    }
  };

  useEffect(() => {
    AllPginatedInsights();
  }, [page, props.openTab]);

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded ' +
          'bg-white'
        }
      >
        {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Insight
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Home
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  League
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Sports
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Publish
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Published Date
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  }
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {/* {!isLoading ? (
                Al
              ) : (
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
              )} */}

              {docs.map((insight, index) => (
                <tr>
                  <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <div
                      style={{
                        background: `url(${insight.imageUrl}) 0% 0% / cover no-repeat`,
                        width: '90px',
                        height: '110px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    ></div>
                  </th>
                  {/* <td
                    className="border border-green-800 border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                   
                    style={{  fontWeight: 'bolder' }}
                  >
                   {insight.isHomeTagged?(<span style={{ color: 'green', fontWeight: 'bold' }}>
                        Home Tagged
                      </span>
                    ) : (
                      <span style={{ color: 'red', fontWeight: 'bold' }}>
                        Home Not Tagged
                      </span>
                    )}
                  </td> */}

                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                    style={{
                      alignContent: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {/* _.find(
                                                checkedFeatureCards,
                                                (item) =>
                                                  item === league.match_id
                                              ) !== undefined */}
                    <Checkbox
                      checked={
                        insight.isHomeTagged === true
                          ? true
                          : false &&
                            _.find(
                              checkedInsight,
                              (item) => item === insight._id
                            ) !== undefined
                      }
                      onChange={(event) => {
                        UpdateInsightHandler(event, insight._id);
                      }}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                    {insight.comp_id
                      ? insight.comp_id.comp_name
                      : 'League not tagged'}
                  </td>

                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                    {insight.sports ? insight.sports.name : 'Sports not tagged'}
                  </td>
                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                    style={{
                      alignContent: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <Checkbox
                      checked={
                        insight.isPublished === true
                          ? true
                          : false &&
                            _.find(
                              checkedPublished,
                              (item) => item === insight._id
                            ) !== undefined
                      }
                      onChange={(event) => {
                        UpdateInsightPublishHandler(event, insight._id);
                      }}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  </td>

                  <td className="border border-green-800  border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                    {_dateTimeFormat(insight.createdAt, 'ddd, DD MMM hh:mm A')}
                  </td>
                  <td className="border border-green-800 border-t-0 border-l-0 border-r-0">
                    <div>
                      <i
                        onClick={() => onView(insight._id)}
                        className="fas fa-edit"
                        style={{
                          marginRight: '30px',
                          marginLeft: '20px',
                          cursor: 'pointer',
                        }}
                      ></i>

                      <i
                        onClick={() => {
                          showModalForPublish();
                          setInsightId(insight._id);
                        }}
                        className="fas fa-trash "
                        style={{
                          cursor: 'pointer',
                        }}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={page}
                onChange={handlePagination}
                count={totalPages}
                variant="outlined"
                color="primary"
              />
            </Stack>
          </div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    leagues: state.leaguesReducer.leagues,
    selectedSports: state.sportsReducer.selectedSports,
  };
};
export default connect(mapStateToProps)(AllOptaInsights);
