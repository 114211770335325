import AppConstants from '../constants';
import AxiosInstance from './http.handlers';
import { OLD_BASE_PATH, API_BASE_PATH } from '../constants/workerConstants';
import LoginAppConstants from '../constants/LoginAppConstants';

const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = {
  'cms-access-token': accessToken,
  source: 'cms',
  language: CURRENT_LANGUAGE,
};


export const mapTeamSegments = async (body) => {
  return await AxiosInstance.post(
    `${OLD_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.TEAMS.MAP_TEAM_SEGMENTS}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const sendArticleNotification = async (body) => {
  return await AxiosInstance.post(
    `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.TEAMS.SEND_NOTIFICATION_FOR_TEAMS}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const sendArticleNotificationSpecificUsers = async (body) => {
  return await AxiosInstance.post(
    `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.TEAMS.SEND_ARTICLE_NOTIFICATION}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const sendVideoNotification = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMS.SEND_VIDEO_NOTIFICATION_FOR_TEAMS}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const sendVideoNotificationSpecificUsers = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMS.SEND_VIDEO_NOTIFICATION_SPECIFIC_USERS}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};


//Teams-Manager Handlers

export const GetPaginatedTeamList = async (body) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMS.GET_TEAMS_LIST}`,
    {params: body},
    {headers}
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetTeamDetail = async (body) =>{
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMS.GET_TEAM_DETAILS}`,
    {params: body},
    {headers}
  )
    .then((response) => response)
    .catch((error) => error);
};

export const UpdateTeam = async (formData, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMS.UPDATE_TEAM_DETAILS}`,
    formData,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const DeleteTeam = async (formData, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMS.DELETE_TEAM}`,
    formData,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
