import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
import _ from 'lodash';
import { connect } from 'react-redux';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import { dispatchTopNewsCount } from '../../../../redux/action-dispatchers/news.dispatchers';
import './NewsManage.css';
import {
  ChangeNewsUpdate,
  GetSectionNews,
  updateNewsRanking,
} from '../../../../handlers/news.handlers';
import {
  _dateTimeFormat,
  _getDateTime,
} from '../../../../constants/appDefaults';
import AlertModal from '../../../../components/AlertModal';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const NewsManagement = (props) => {
  const mHistory = useHistory();
  const [render, setRender] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [section, setSection] = React.useState('TOP_NEWS');
  const [limit, SetLimit] = React.useState(10);
  const [docs, setDocs] = useState([]);
  const [updateDocs, setUpdateDocs] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [showAction, setShowAction] = React.useState(false);
  const color = 'light';

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(docs, source.index, destination.index);
    const docsUpdated = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        ranking: index + 1,
      });
    });

    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setDocs(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleRankingSort = (data) => {
    let temp = data.sort((a, b) => {
      return a.ranking - b.ranking;
    });
    return temp;
  };

  const getNews = (section, page, _limit, isLoadMore) => {
    setIsLoading(true);

    GetSectionNews(section, page, parseInt(_limit), {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setTotalDocs(response.data.totalDocs);
          if (isLoadMore) {
            // setDocs([...docs, handleRankingSort(response.data.docs)]);
            setDocs([...docs, response.data.docs]);
          } else {
            // setDocs(handleRankingSort(response.data.docs));
            setDocs(response.data.docs);
          }
        } else {
        }
      })

      .catch((error) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getNews(section, parseInt(page), parseInt(limit), false);
  }, []);

  const changeTopNewsType = (id) => {
    const data = {
      _id: id,
      section: 'ALL',
    };
    ChangeNewsUpdate(data, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response.status) {
          setAlertModal(true);
          getNews(section, parseInt(page), parseInt(limit), false);
        }
      })
      .catch((error) => error);
  };

  const updateNews = () => {
    updateNewsRanking(updateDocs, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response.status) {
          setUpdateDocs([]);
          setAlertModal(true);
          setShowAction(false);
          getNews(section, parseInt(page), parseInt(limit), false);
        }
      })
      .catch((error) => {});
  };

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  const onView = (newsId) => {
    mHistory.push(`/news/${newsId}`);
  };
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your article has been updated successfully!"
                onSuccess={() => setAlertModal(false)}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Top News
                      {/* <p
                        className={
                          'font-semibold text-lg ' +
                          (color === 'light'
                            ? 'text-blueGray-700'
                            : 'text-white')
                        }
                      >
                        Docs: {totalDocs}
                      </p> */}
                    </h3>
                    {showAction && (
                      <div className={'save-reset enabled'}>
                        <div onClick={() => updateNews()} className="save btn">
                          Save
                        </div>

                        <div
                          onClick={() => {
                            getNews(
                              section,
                              parseInt(page),
                              parseInt(limit),
                              false
                            );
                            setShowAction(false);
                            alert('No changes were made');
                          }}
                          className="reset btn"
                        >
                          Reset
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Thumb Image
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Headline
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Sports
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Type
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Published Date
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Expiry_Date Time
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Status
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Home
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Top
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {docs.length > 0 &&
                              docs.map((news, index) => {
                                return (
                                  <Draggable
                                    key={news._id}
                                    draggableId={news._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                          {/* <AspectRatio
                                   ratio={16 / 9}
                                   style={{
                                     background : `url('${news.imgUrl}')`,
                                     width:'450px',
                                     height:'253px',
                                     backgroundSize : 'cover',
                                     backgroundRepeat :'no-repeat'
                                   }}
                                 > */}
                                          {/* <img
                                     src={news.imgUrl}
                                     className="h-12 w-12 bg-white"
                                     alt="..."
                                   ></img> */}
                                          {/* </AspectRatio> */}

                                          <div
                                            style={{
                                              background: `url('${news.imgUrl}?${news._id}') 0% 0% / cover no-repeat`,
                                              width: '100px',
                                              height: '53px',
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                            }}
                                          ></div>
                                        </th>
                                        <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          onClick={() => onView(news._id)}
                                          style={{
                                            cursor: 'pointer',
                                            fontWeight: 'bolder',
                                          }}
                                        >
                                          {news.headline}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.sports && news.sports.name
                                            ? news.sports.name
                                            : 'N/A'}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.copyrights}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {leagueName(news.league)}
                                          {/* {news.league ? news.league : 'N/A'} */}
                                        </td>
                                        {/* news publish time */}
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {_dateTimeFormat(
                                            news.PublishedAt,
                                            'ddd, DD MMM hh:mm A'
                                          )}
                                          {/* {news.copyrights === "PTI"
                                            ? props.selectedLanguage ===
                                              "english"
                                              ? _dateTimeFormat(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}
                                          {news.copyrights === "Opta"
                                            ? props.selectedLanguage ===
                                              "english"
                                              ? _dateTimeFormat(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}
                                          {news.copyrights === "PTI_Bhasha"
                                            ? props.selectedLanguage === "hindi"
                                              ? _dateTimeFormat(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}                    */}
                                          {/* {news.copyrights === 'PTI'
                                   ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                                   : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                                          {/* {news.copyrights === 'PTI_Bhasha'
                                   ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                                   : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {_dateTimeFormat(
                                            news.topNewsExpiry,
                                            'ddd, DD MMM hh:mm A'
                                          )}
                                        </td>
                                        {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.showArticle ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Published
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Draft
                                            </span>
                                          )}
                                        </td> */}
                                        <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.showArticle ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Published
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Draft
                                            </span>
                                          )}
                                        </td>
                                        <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.tagHome ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Tagged
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Untagged
                                            </span>
                                          )}
                                        </td>
                                        <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.section == 'TOP_NEWS' ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Tagged
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Untagged
                                            </span>
                                          )}
                                        </td>
                                        <td
                                          className="border border-green-800"
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <div
                                            onClick={() =>
                                              changeTopNewsType(news._id)
                                            }
                                            className="text-center"
                                          >
                                            <i className="fas fa-trash"></i>
                                          </div>
                                        </td>
                                        {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                 <TableDropdown showArticle={news.showArticle} newsId={news._id} onView={onView} />
                                 <button
                                   className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                                   type="button"
                                   onClick={() => onView(news._id)}
                                 >
                                   Manage
                                 </button>
                               </td> */}
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </tbody>
                        </table>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
  };
};
export default connect(mapStateToProps)(NewsManagement);
