import AppConstants from '../constants';
import LoginAppConstants from '../constants/LoginAppConstants';
import AxiosInstance from './http.handlers';
import AxiosTwitterInstanse from './http.twitterhandlers';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = {
  'cms-access-token': accessToken,
  source: 'cms',
  language: CURRENT_LANGUAGE,
};

export const GetTwitterVideos = async (page, selectedTag, searchKey, language) => {
  return await AxiosTwitterInstanse.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.GET_TWITTER_VIDEOS}?page=${page}&tag=${selectedTag}&searchKey=${searchKey}&language=${language}`, // query params

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetTwitterSuggestions = async (page, searchKey) => {
  return await AxiosTwitterInstanse.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.SUGGESTED_TWITTER_HANDLES}?page=${page}&searchKey=${searchKey}`, // query params

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetTwitterVideosDetail = async (id) => {
  return await AxiosTwitterInstanse.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.TWITTER_VIDEOS_DETAILS}/${id}`,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetTwitterTagsForFilter = async () => {
  return await AxiosTwitterInstanse.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.TWITTER_TAG_FILTER}`,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const EditTwitterTags = async (id) => {
  return await AxiosTwitterInstanse.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.EDIT_TWITTER_CONTENT_DOCS}/${id}`,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddTwitterToVideos = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.ADD_TO_VIDEOS}`,

    body,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddOrEditTwitterContent = async (body) => {
  return await AxiosTwitterInstanse.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.ADD_OR_EDIT_TWITTER_CONTENT}`,

    body,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const DeleteTwitterContent = async (body) => {
  return await AxiosTwitterInstanse.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.DELETE_TWITTER_CONTENT}`,

    body,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const DeleteTweet = async (body) => {
  return await AxiosTwitterInstanse.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TWITTER.DELETE_TWEET}`,

    body,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

// Trending News

export const GetTrendingNewsforFilter = async (body) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TRENDING.GET_ALL_LOCATIONS}?${body}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ToggleLocation = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TRENDING.TOGGLE_LOCATIONS}`,

    body,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const  GetTaggedLocation = async (body) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TRENDING.TAGGED_LOCATIONS}?${body}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const  GetTopTrends = async (body) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TRENDING.TOP_TRENDS}?${body}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const PostContentLimit= async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TRENDING.CONTENT_LIMIT}`,

    body,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
