import { VideosActionTypes } from '../action-types';

const initialState = {
  docs: [],
};

const s3VideosReducer = (state = initialState, action) => {
  switch (action.type) {
    case VideosActionTypes.S3_VIDEOS_LIST:
      return Object.assign({}, state, {
        ...action.payload,
        docs: action.payload,
      });

    default:
      return state;
  }
};
export default s3VideosReducer;
