import React, { useEffect } from 'react';
import TwitterTable from '../../../components/Cards/TwitterTable';
import Sidebar from '../../../components/Sidebar/Sidebar';
const News = (props) => {
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <TwitterTable color="light" />
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
