import React from "react";
import Modal from "react-modal";
import ReactDevicePreview from "react-device-preview";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "400px",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
};
const AlertModal = (props) => {
  return (
    <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
      <h6 className="text-xl font-normal leading-normal mt-0 mb-2 text-lightBlue-800">
        {props.title}
      </h6>
      <p className="text-base font-light leading-relaxed mt-0 mb-4 text-lightBlue-800">
        {props.message}
      </p>

      <div style={{ paddingTop: 20 }}>
        <button
          className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          onClick={props.onSuccess}
        >
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default AlertModal;
