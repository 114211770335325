import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { checkAuthentication } from '../services/auth';
const LoggedOutRoute = (props) => {
  const firstModuleName = localStorage.getItem('FirstModuleName');
  const isAuthenticated = checkAuthentication();
  return isAuthenticated ? (
    <Redirect
      to={
        props.firstModuleName !== undefined
          ? props.firstModuleName
          : firstModuleName
      }
    />
  ) : (
    <Route path={props.path} exact={props.exact} component={props.component} />
  );
};
const mapStateToProps = (state) => {
  return {
    firstModuleName: state.authReducer.firstModuleName,
  };
};
export default connect(mapStateToProps)(LoggedOutRoute);
