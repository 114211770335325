import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LandingPage from '../pages/LandingPage/LandingPage';
import { checkAuthentication } from '../services/auth';

const LoggedInRoute = (props) => {
  const isAuthenticated = checkAuthentication();
  return isAuthenticated ? (
    <Route
      path={props.path}
      exact={props.exact}
      render={(routeProps) => (
        <React.Fragment>
          <props.component></props.component>
        </React.Fragment>
      )}
    />
  ) : (
    <Redirect to={'/'} />
  );
};

export default LoggedInRoute;
