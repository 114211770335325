import React, { useState } from 'react';

const DisplayFilterRequest = (props) => {
  const [selectedOption, setSelectedOption] = useState('All');
  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.selectedValue}
    >
      <option>Display/Filter</option>
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="firstName"
      >
        FirstName
     </option>
     <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="lastName"
      >
        LastName
     </option>
      
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="emailId"
      >
        Email Id
      </option>
    </select>
  );
};

export default DisplayFilterRequest;
