import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { connect } from 'react-redux';
import '../HomePage/HomeNews.css';
import ReactPlayer from 'react-player';
import { Link, useHistory } from 'react-router-dom';
import {
  tagHome,
  updateVideosRanking,
} from '../../../handlers/videos.handlers';
import {
  getKabaddiSpecial,
  KabaddiSpecialVideos,
} from '../../../handlers/Kabaddi.handlers';
import { _dateTimeFormat, _getDateTime } from '../../../constants/appDefaults';
import AlertModal from '../../../components/AlertModal';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
import KabaddiDropDown from './KabaddiDropdown';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const HomeSpecialVideos = (props) => {
  const [docs, setDocs] = useState([]);
  const [updateDocs, setUpdateDocs] = useState([]);
  const [page, setPage] = React.useState(1);
  const [limit, SetLimit] = React.useState(10);
  const [alertModal, setAlertModal] = useState(false);
  const [showAction, setShowAction] = React.useState(false);
  const [homeType, setHomeType] = useState('specailVideos');
  const mHistory = useHistory();
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [content, setContent] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [isPlaying, setIsplaying] = useState(false);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [videoResult, setVideoResult] = useState([]);
  const color = 'light';
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(docs, source.index, destination.index);
    const docsUpdated = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        homeTagRanking: index + 1,
      });
    });
    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setDocs(items);
  };

  // Media popup
  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };
  const onView = (videoId) => {
    props.isYoutube === true
      ? mHistory.push(`/videos/${videoId}/yes`)
      : mHistory.push(`/videos/${videoId}/no`);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  const onHomeTypeChange = (event) => {
    setHomeType(event.target.value);
  };
  if (homeType === 'news') {
    mHistory.push(`/home-page-news`);
  }
  else if (homeType === 'specailVideos') {
    mHistory.push(`/home-page-videos`);
  }
  else if (homeType === 'live_blog') {
    mHistory.push(`/live_blog`);
  }

  const getVideos = (page, limit, isLoadMore) => {
    setIsLoading(true);
    getKabaddiSpecial()
      .then((response) => {
        const resultVideo = response.data.filter(
          (mediaType) => mediaType.mediaType == 'video'
        );

        setVideoResult(resultVideo);
        setIsLoading(false);
        setHasNextPage(response.data.hasNextPage);
        setPage(response.data.page);
        setDocs([...docs, ...response.data.docs]);
        setUpdateDocs(response.data.homeTagRanking);
        setContent(response.data);
        // // if (response.status) {
        // //   if (isLoadMore) {
        // //     setDocs([...docs, ...response.data.docs]);
        // //     setUpdateDocs(...updateDocs, ...response.data.homeTagRanking);
        // //   } else {
        // //     setDocs(response.data.docs);
        // //   }
        // }
      })
      .catch((error) => error);
  };
  React.useEffect(() => {
    setIsLoading(true);
    getVideos(1, 10, false);
  }, []);

  const removeVideoFromHome = (id) => {
    const body = {
      mediaType: 'video',
      video: id,
      // ranking: '1',
      isTagg: false,
    };
    KabaddiSpecialVideos(body)
      .then((response) => response)
      .catch((error) => error);
    setAlertModal(true);
  };
  const updateVideos = () => {
    updateVideosRanking(updateDocs, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response.status) {
          setUpdateDocs([]);
          setAlertModal(true);
          setShowAction(false);
          getKabaddiSpecial(1, 10, false);
        }
      })
      .catch((error) => {});
  };
  const getVideoTableItem = (video, position) => {
    if (video.video.media.content.length > 0) {
      const videoUrl = video.video.media.content[1]
        ? video.video.media.content[1].url
        : video.video.media.content[0].url;
      const height = video.video.media.content[1]
        ? video.video.media.content[1].height
        : video.video.media.content[0].height;
      const width = video.video.media.content[1]
        ? video.video.media.content[1].width
        : video.video.media.content[0].width;
      const thumbnail =
        video.video.media.content[video.video.media.content.length - 1].type ===
        'image'
          ? video.video.media.content[video.video.media.content.length - 1].url
          : video.video.media.thumbnail[video.video.media.thumbnail.length - 1]
              .url;
      return (
        <Draggable key={video._id} draggableId={video._id} index={position}>
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                <img
                  src={thumbnail}
                  style={{
                    // maxHeight: 300,
                    // minHeight: 253,
                    // maxWidth: 450,
                    // minWidth: 400,
                    maxHeight: 100,
                    minHeight: 53,
                    maxWidth: 53,
                    minWidth: 100,
                    cursor: 'pointer',
                  }}
                  height={300}
                  width={400}
                  alt="..."
                  onClick={() => onClickOpenMedia(videoUrl, thumbnail)}
                ></img>
              </th>
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.video.copyright}
              </td>
              <td
                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                onClick={() => onView(video.video._id)}
                style={{ cursor: 'pointer', fontWeight: '500' }}
              >
                {video.video.title}
              </td>
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.video.sports && video.video.sports.name
                  ? video.video.sports.name
                  : 'N/A'}
              </td>
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {leagueName(video.video.league)}
              </td>
              {props.isYoutube === true ? (
                <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {video.video.channelTitle}
                </td>
              ) : (
                ''
              )}
              {props.isYoutube === true ? (
                <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {video.video.tags}
                </td>
              ) : (
                ''
              )}
              {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">N/A</td> */}
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {_dateTimeFormat(video.video.lastUpdate, 'ddd, DD MMM hh:mm A')}
              </td>
              {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {_dateTimeFormat(
                  video.video.homeTagExpiry,
                  'ddd, DD MMM hh:mm A'
                )}
              </td> */}

              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.video.status === 1 ? (
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    Published
                  </span>
                ) : (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Draft
                  </span>
                )}
              </td>
              <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.video.tagHome ? (
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    Tagged
                  </span>
                ) : (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Untagged
                  </span>
                )}
              </td>
              <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.video.section == 'TOP_VIDEO' ? (
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    Tagged
                  </span>
                ) : (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Untagged
                  </span>
                )}
              </td>
              <td
                className="border border-green-800"
                style={{ cursor: 'pointer' }}
              >
                <div
                  onClick={() => removeVideoFromHome(video._id)}
                  className="text-center"
                >
                  <i className="fas fa-trash"></i>
                </div>
              </td>
            </tr>
          )}
        </Draggable>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '55%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showEditHandler(false);
                setIsplaying((isPlaying) => !isPlaying);
                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={isPlaying}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div className="relative bg-blueGray-100" style={{ paddingTop: 10 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 ">
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your article has been updated successfully!"
                onSuccess={() => window.location.reload()}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                  <div className="flex flex-wrap items-center">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Special videos :{/* {content.totalDocs} */}
                    </h3>
                    {showAction && (
                      <div className={'save-reset enabled'}>
                        <div
                          onClick={() => updateVideos()}
                          className="save btn"
                        >
                          Save
                        </div>

                        <div
                          onClick={() => {
                            setUpdateDocs([]);
                            getKabaddiSpecial(1, 10, false);
                            setShowAction(false);
                            alert('No changes were made');
                          }}
                          className="reset btn"
                        >
                          Reset
                        </div>
                      </div>
                    )}
                    <div
                      className="relative w-full px-10 max-w-full flex-grow flex-1"
                      style={{ textAlign: 'right' }}
                    >
                      <KabaddiDropDown
                        selectedValue={homeType}
                        onChange={onHomeTypeChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Video
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Copyright
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Title
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Sports
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              {props.isYoutube === true ? (
                                <th
                                  className={
                                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                    (props.color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                  }
                                >
                                  YT Channel
                                </th>
                              ) : (
                                ''
                              )}
                              {props.isYoutube === true ? (
                                <th
                                  className={
                                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                    (props.color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                  }
                                >
                                  Tags
                                </th>
                              ) : (
                                ''
                              )}
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Published Date
                              </th>
                              {/* <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Expiry-Date/Time
                              </th> */}

                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Status
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Home
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Top
                              </th>
                              <th
                                className={
                                  'border border-green-800 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (props.color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          {!isLoading ? (
                            getKabaddiSpecial
                          ) : (
                            <SyncLoader
                              color="#23A5E0"
                              css={override}
                              size={10}
                            />
                          )}
                          <tbody>
                            {videoResult.map((video, position) =>
                              getVideoTableItem(video, position)
                            )}
                          </tbody>
                        </table>
                        {provided.placeholder}
                        {hasNextPage && !isLoadingMore && (
                          <div style={{ textAlign: 'center', padding: 20 }}>
                            <button
                              className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                              type="button"
                              onClick={() => {
                                getVideos(parseInt(page) + 1, parseInt(limit));
                              }}
                            >
                              Load More
                            </button>
                          </div>
                        )}
                        <div style={{ textAlign: 'center', padding: 10 }}>
                          <SyncLoader
                            color="#23A5E0"
                            loading={isLoadingMore}
                            css={override}
                            size={10}
                          />
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
  };
};
export default connect(mapStateToProps)(HomeSpecialVideos);
