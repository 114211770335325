import React from 'react';

const VideoTypeDropDown = (props) => {
  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.selectedValue}
    >
      <option value="">Select Video Type</option>
      <option value="">All</option>
      <option value="Opta">Opta</option>
      <option value="Youtube">Youtube</option>
      <option value="Sports Tak">Sports Tak</option>
    </select>
  );
};

export default VideoTypeDropDown;
