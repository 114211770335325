import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const getPublishedNews = async (params, headers) => {
    return await AxiosInstance.get(
        `${AppConstants.WorkerConstants.API_ROUTES.REPORT.PUBLISHED_NEWS}`,
        {
            params: params,
            headers:headers,
        }
    )
        .then((response) => response)
        .catch((error) => error);
};

export const getPublishedWebstories = async (params, headers) => {
    return await AxiosInstance.get(
        `${AppConstants.WorkerConstants.API_ROUTES.REPORT.PUBLISHED_WEBSTORIES}`,
        {
            params: params,
            headers: headers,
        }
    )
        .then((response) => response)
        .catch((error) => error);
};
export const getPublishedVideos = async (params, headers) => {
    return await AxiosInstance.get(
        `${AppConstants.WorkerConstants.API_ROUTES.REPORT.PUBLISHED_VIDEOS}`,
        {
            params: params,
            headers: headers,
        }
    )
        .then((response) => response)
        .catch((error) => error);
};
export const getPublishedShorts = async (params, headers) => {
    return await AxiosInstance.get(
        `${AppConstants.WorkerConstants.API_ROUTES.REPORT.PUBLISHED_SHORTS}`,
        {
            params: params,
            headers: headers,
        }
    )
        .then((response) => response)
        .catch((error) => error);
};

export const generateReport = async (params, headers) => {
    return await AxiosInstance.get(
        `${AppConstants.WorkerConstants.API_ROUTES.REPORT.GENERATE_REPORT}`,
        {
            params: params,
            headers: headers,
        }
    )
        .then((response) => response)
        .catch((error) => error);
};