// import * as WorkerConstants from "../constants/server.constants";
// import AxiosInstance from "./http.handlers";

// export const GetAllSports = async (headers) => {
//   return await AxiosInstance.get(WorkerConstants.API_ROUTE.SPORTS.GET_ALL, {
//     headers,
//   })
//     .then((response) => response)
//     .catch((error) => error);
// };
// import AppConstants from '../constants';
// import AxiosInstance from './http.handlers';

// export const getAllSports = async (headers) => {
//   return await AxiosInstance.get(
//     AppConstants.WorkerConstants.API_ROUTES.SPORTS.SPORTS_NAME,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const getAllSports = async (IHeader) => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.SPORTS.SPORTS_NAME
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getAllSportsTag = async (IHeader) => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.SPORTS.SPORTS_NAME_TAG
  )
    .then((response) => response)
    .catch((error) => error);
};

export const postsports = async (data, headers) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.NEWS.ADD_NEWS,
    data,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
