import React from "react";
import { connect } from "react-redux";
import { dispatchSelectedSportsTagging } from "../../redux/action-dispatchers/sports.dispatchers";

const SportsTaggingDropDown = (props) => {
  const onChange = (event) => {
    props.onChange && props.onChange(event.target.value);
    dispatchSelectedSportsTagging({
      selectSports: event.target.value,
      // selectedSportsTagging: event.target.value,
    });
    // alert(event.target.value);
  };
  return (
    <select
      style={{
        // marginLeft: 20,
        border: "2px solid #acb9ce",
        borderRadius: 10,
      }}
      value={props.sportsReducer.selectedSportsTagging}
      onChange={onChange}
    >
      {props.bannerType == "league" ? (
        <>
          <option value="All">Select Sports</option>
          <option value={props.sportsReducer.sports[0]?._id}>
            {props.sportsReducer.sports[0]?.name}
          </option>
        </>
      ) : (
        <>
          <option value="All">Select Sports</option>
          {props.sportsReducer.sports?.map((sports) => (
            <option value={sports._id}>{sports.name}</option>
          ))}
        </>
      )}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    sportsReducer: state.sportsReducer,
    selectedSportsFilter: state.sportsReducer.selectedSportsFilter,
  };
};

export default connect(mapStateToProps)(SportsTaggingDropDown);
