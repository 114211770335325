import React from 'react';

const ProductivityDropDown = (props) => {
  const [selectedOption, setSelectedOption] = React.useState('news');

  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.selectedValue}
    >
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="news"
      >
        News Report
      </option>
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="videos"
      >
        Video Report
      </option>
    </select>
  );
};

export default ProductivityDropDown;
