import { configStore } from '../store';
import { NewsActionTypes } from '../action-types';

export const dispatchNewsList = (payload) => {
  configStore.dispatch({
    payload,
    type: NewsActionTypes.NEWS_LIST,
  });
};
export const dispatchNumberOfNewsPages = (payload) => {
  configStore.dispatch({
    payload,
    type: NewsActionTypes.NUMBER_OF_PAGES,
  });
};
export const dispatchCurrnetNewsPage = (payload) => {
  configStore.dispatch({
    payload,
    type: NewsActionTypes.CURRENT_PAGE,
  });
};

export const dispatchNewsListFilter = (payload) => {
  configStore.dispatch({
    payload,
    type: NewsActionTypes.NEWS_LIST_FILTER,
  });
};

export const dispatchResetNews = () => {
  configStore.dispatch({
    type: NewsActionTypes.RESET_NEWS_LIST,
  });
};

export const dispatchToggleNewsStatus = (payload) => {
  configStore.dispatch({
    payload,
    type: NewsActionTypes.NEWS_TOGGLE_STATUS,
  });
};
export const dispatchTopNewsCount = (payload) => {
  configStore.dispatch({
    payload,
    type: NewsActionTypes.TOP_NEWS_COUNT,
  });
};
