import React from 'react'

export default function PollsSortDropDown(props) {
  return (
      <select
          style={{
              // marginLeft: 20,
              border: '2px solid #acb9ce',
              borderRadius: 10,
          }}
          onChange={props.onChange}
          value={props.selectedValue}
      >
          <option value="createdAt">Creation Date</option>
          <option value="rank">Rank</option>
          
      </select>
  )
}
