import React, { useState } from 'react';
import { UserSignUp } from '../../handlers/admin.handlers';
import { useHistory, Link } from 'react-router-dom';
import { BiUserMinus } from 'react-icons/bi';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';

import './Signup.css';
const SignUp = () => {
  const history = useHistory();
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();
    const { first_name, last_name, email, phone } = user;

    const body = {
      emailId: email,
      phoneNumber: phone,
      firstName: first_name,
      lastName: last_name,
    };
    UserSignUp(body).then((res) => {
      if (res.status === true) {
        window.alert('Registration Successful');
        history.push('/');
      } else {
        window.alert('Invalid credentials');
      }
    });
  };
  return (
    <>
      <div className="SignUp-container">
        <h1>Registration Form</h1>
        <div className="SignUp-Form-container">
          <form method="POST" onSubmit={postData}>
            <div className="input-fields">
              <BiUserMinus />
              <input
                type="text"
                name="first_name"
                id="first_name"
                placeholder="First Name"
                autoComplete="off"
                value={user.first_name}
                onChange={handleInputs}
              />
            </div>
            <div className="input-fields">
              <BiUserMinus />
              <input
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name"
                autoComplete="off"
                value={user.last_name}
                onChange={handleInputs}
              />
            </div>
            <div className="input-fields">
              <AiOutlineMail />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                autoComplete="off"
                value={user.email}
                onChange={handleInputs}
              />
            </div>
            <div className="input-fields">
              <AiOutlinePhone />
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Phone"
                autoComplete="off"
                value={user.phone}
                onChange={handleInputs}
              />
            </div>

            <button type="submit">Register</button>
            <div className="already">
              <Link to="/">I'm already registered</Link>
            </div>
          </form>

          <img src={`${process.env.PUBLIC_URL}/assets/img/SportsTak.png`} />
        </div>
      </div>
    </>
  );
};
export default SignUp;
