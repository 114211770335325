import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Chip from '@mui/material/Chip';
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { addPurgeURL } from '../../../handlers/purge.handler';


const Purge = () => {
  const history = useHistory();
  const [inputUrl, setInputUrl] = useState('');
  const [options, setOptions] = useState([]);
  const [purgeResp, setPurgeResp] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddOption = (option) => {
    if (!option) return options;
    var tempArr = options;
    tempArr.push(option);
    var tempOptions = new Set(tempArr);
    setOptions([...tempOptions]);
    setInputUrl("");
  };

  const { currentState } = useSelector(
    (state) => ({ currentState: state.progressions }),
    shallowEqual
  );
  
  const handleButtonClick = () => {
    setLoading(true);

    const body = {
      "urls": options,
    };

    addPurgeURL(body).then((response) => {
      if (response?.status) {
        setLoading(false);
        alert('Content Purged Successfully')
        
        setPurgeResp('Purged the Url Successfully');
        history.push('/purge');
      } else {
        setLoading(false);
        setPurgeResp(`Something went wrong...`);
      }
    });
  };
  const useStyles3 = makeStyles(theme => ({
    chip: {
      fontSize: '14px',
      margin: '5px',
      padding: "6px",
    }
  }));
  const classes3 = useStyles3();
  const handleRemoveOption = (index) => {
    const removeOptions = [...options];
    removeOptions.splice(index, 1);
    setOptions(removeOptions);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10, height:"100%" }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4" style={{borderRadius:"10px"}}>
          <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded "
        }
        
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " 
                }
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                Purge Urls
                </div>
                <div class="relative w-full  max-w-full flex-grow flex-1" style={{textAlign: "right",}}>
                    
                    <button className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear `}
                   onClick={
                    handleButtonClick
                  }
                  disabled={loading}
                   >Purge</button>
                </div>
              </h3>
              
            </div>

            
          </div>
        </div>
        <div class="block w-full overflow-x-auto" style={{backgroundColor:"#fff", borderRadius:"10px", }}>
            <div style={{margin:"16px", border:"1px solid #111", padding:"16px", borderRadius:"5px"}}>
                <br/>
            <div><b>Enter URLS : </b></div>
            <br/>
            <hr style={{marginTop:"5px", marginBottom:"5px"}}/>
                <br/>
                
<div>             
            <input
                type="text"
                name="urls"
                  value={inputUrl}
                  style={{
                    border: "2px solid #acb9ce",
                    borderRadius: "5px",
                    marginRight: "2%",
                    padding: "3px",
                    marginLeft:"5px",
                    paddingLeft: '10px',
                    width:"91%", 
                    marginBottom:"5px"

                  }}
                  onChange={(e) => {
                    setInputUrl(e.target.value)
                  }
                  }
                  placeholder="Please Enter a Valid Sportstak Website Page URL"
                  
                />
                
                

        <button className={`bg-${'emerald'}-500 text-white  font-bold uppercase text-xs px-4 py-2 rounded button-solid`} 
        outline="none"
        style={{marginTop:"5px",
        outline:"none"}}
        onClick={(e) => {
            e.preventDefault();
            handleAddOption(inputUrl);
          }}
          disabled={loading}>
        
            Add
            </button>
</div>
{console.log(options)}
<div>
            {
              options.map((tag, index) => (
                <span key={index} >
                  <Chip
                    label={`${tag}`}
                    onDelete={(e) => {
                      e.preventDefault();
                      handleRemoveOption(index)
                    }}
                    className={classes3.chip}
                    color="success"
                    variant="outlined"
                    style={{margin:"2px"}}
                  />
                  
                </span>
              ))
            }
          </div>
          {loading && (
            <div>
              <hr />
              Loading...
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}

              <br/>  
        <hr style={{marginTop:"5px", marginBottom:"5px"}}/>
        <div>
            <br /> 
            <label><b>Purge Response:-</b></label>
            <div>
              <textarea
                rows='7'
                style={{ width: '100%', borderRadius:"5px", backgroundColor:"#F0F0F0" }}
                disabled={true}
                value={purgeResp}
              />
            </div>
          </div>
            </div>
        </div>
        
      </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purge;
