import AppConstants from '../constants';
import AxiosAuthInstance from './http.authhandlers';

export const Login = async (IParam) => {
  return await AxiosAuthInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.USER.LOGIN,
    IParam
  )
    .then((response) => response)
    .catch((error) => error);
};
export const Logout = async (IParam) => {
  return await AxiosAuthInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.USER.LOGOUT,
    IParam
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ReqForgotPassword = async (body) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.FORGOT_PASSWORD}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const ReqUpdatePassword = async (body) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.UPDATE_PASSWORD}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UserSignUp = async (body) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.SIGN_UP}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetAddedRole = async (body, headers) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.ADD_ROLE}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const addPermissionToModule = async (body, headers) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.ADD_PERMISSION}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getAllRoles = async (headers) => {
  return await AxiosAuthInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_ALL_ROLE}`,
    {
      headers,
    }
  )
    .then((response) => response)

    .catch((error) => error);
};

export const getAllPermissions = async (roleName, headers) => {
  return await AxiosAuthInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_PERMISSION_ROLE}/${roleName}`,
    {
      headers,
    }
  )
    .then((response) => response)

    .catch((error) => error);
};

export const getSearch = async (filter, query, headers) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_SEARCH}/${filter}/${query}`,
    {},
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getSearchRequest = async (filter, query, headers) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_SEARCH_REQUEST}/${filter}/${query}`,
    {},
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getDateSearch = async (headers, body) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_DATE_SEARCH}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getDateSearchRequest = async (headers, body) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_DATE_SEARCH_REQUEST}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getAddedRequest = async (body) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.ADD_REQUEST}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const cancelRequest = async (emailId, body, headers) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.CANCEL_REQUEST}/${emailId}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const approveRequest = async (emailId, body, headers) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.APPROVE_REQUEST}/${emailId}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getRequestList = async (headers) => {
  return await AxiosAuthInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_REQUEST_LIST}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getAllUser = async (headers) => {
  return await AxiosAuthInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.GET_ALL_USER}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getActionToggled = async (emailId, headers) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.ACTION_TOGGLED}/${emailId}`,
    {},
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const deleteUser = async (headers, data) => {
  return await AxiosAuthInstance.delete(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.DELETE_USER}`,
    {
      headers,
      data,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const addUser = async (body) => {
  return await AxiosAuthInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.ADMIN.DELETE_USER}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
