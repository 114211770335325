import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import { GetAllAuthorList } from '../../handlers/author.handlers';
const LandingPage = () => {
  React.useEffect(() => {
    GetAllAuthorList()
      .then((res) => console.log('hi', res))
      .catch((err) => err);
  }, []);
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            Please Refresh the page first
          </div>
        </div>
      </div>
    </>
  );
};
export default LandingPage;
