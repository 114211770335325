import React, { useEffect, useState } from 'react';
import {
  GetAllHomeSpecial,
  updateSpecialRanking,
} from '../../../handlers/homeSpecial.handlers';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { connect } from 'react-redux';
import { _dateTimeFormat } from '../../../constants/appDefaults';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
const EditorsChoice = (props) => {
  const [data, setData] = useState([]);
  const [updateDocs, setUpdateDocs] = useState([]);
  const [showAction, setShowAction] = React.useState(false);
  const color = 'light';
  const mHistory = useHistory();
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(data, source.index, destination.index);
    const docsUpdated = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        section: data.section,
        ranking: index + 1,
      });
    });
    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setData(items);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const getEditorsChoice = () => {
    GetAllHomeSpecial().then((response) => {
      setData(response.data);
    });
  };
  useEffect(() => {
    getEditorsChoice();
  }, []);
  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  const onView = (newsId, videoId) => {
    if (newsId) {
      mHistory.push(`/news/${newsId}`);
    }
    if (videoId) {
      mHistory.push(`/videos/${videoId}/no`);
    }
  };
  const updateOrder = () => {
    updateSpecialRanking(
      { homeSpecial: updateDocs },
      {
        Authorization: props.Authorization,
      }
    ).then((response) => {
      if (response.status) {
        setUpdateDocs([]);
        setShowAction(false);
        alert('Reordered successfully...');
        getEditorsChoice();
      } else alert('Something went wrong...');
    });
  };
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                  <div className="flex flex-wrap items-center">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Editor's Choice
                    </h3>

                    {showAction && (
                      <div className={'save-reset enabled'}>
                        <div onClick={() => updateOrder()} className="save btn">
                          Save
                        </div>

                        <div
                          onClick={() => {
                            setUpdateDocs([]);
                            getEditorsChoice();
                            setShowAction(false);
                            alert('No changes were made');
                          }}
                          className="reset btn"
                        >
                          Reset
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Thumb Image
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Media Type
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Headline
                              </th>

                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Type
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Published Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data.map((items, index) => {
                                return (
                                  <>
                                    <Draggable
                                      key={
                                        items.article
                                          ? items.article?._id
                                          : items.video?._id
                                      }
                                      draggableId={
                                        items.article
                                          ? items.article?._id
                                          : items.video?._id
                                      }
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  ">
                                            <div
                                              style={{
                                                background: `url('${
                                                  items.article
                                                    ? items.article?.imgUrl
                                                    : items.video?.media
                                                        .thumbnail[0].url
                                                }?${
                                                  items.article
                                                    ? items.article?._id
                                                    : items.video?._id
                                                }') 0% 0% / cover no-repeat`,
                                                width: '100px',
                                                height: '53px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                              }}
                                            ></div>
                                          </th>
                                          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {items.mediaType}
                                          </td>
                                          <td
                                            className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                            onClick={() =>
                                              onView(
                                                items.article &&
                                                  items.article._id,
                                                items.video && items.video._id
                                              )
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontWeight: 'bolder',
                                            }}
                                          >
                                            {items.article
                                              ? items.article?.headline
                                              : items.video?.title}
                                          </td>
                                          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {items.article
                                              ? items.article?.copyrights
                                              : items.video?.copyright}
                                          </td>
                                          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {leagueName(
                                              items.article
                                                ? items.article?.league
                                                : items.video?.league
                                            )}
                                          </td>
                                          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {_dateTimeFormat(
                                              items.article
                                                ? items.article?.PublishedAt
                                                : items.video?.PublishedAt,
                                              'ddd, DD MMM hh:mm A'
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
  };
};
export default connect(mapStateToProps)(EditorsChoice);
