import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { css } from "@emotion/core";
import LeaguesDropDown from "../../../components/Dropdowns/LeaguesDropDown";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { getAllSports } from "../../../handlers/sports.handler";
import _ from "lodash";
import {
  GetTeamsAndPlayers,
  TagTopTeamsOrPlayers,
} from "../../../handlers/teamsOrPlayer";
import { Checkbox } from "@material-ui/core";
import "./TeamPlayerNotification.css";
import FilteredLeagueDropDown from "../../../components/Dropdowns/FilteredLeagueDropDown";
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const TeamPlayerNotification = (props) => {
  const [sportsName, setSportsName] = useState([]);
  const [appState, changeState] = useState({
    activeObject: 0,
    objects: sportsName,
  });
  const [selectedSportsName, setSelectedSportsName] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFirstIndexActive, setFirstIndexActive] = useState(true);
  const [selectedSportsId, setSelectedSportsId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [checkedFeatureCards, setCheckFeatureCards] = useState([]);
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    changeState({
      activeObject: 0,
      objects: sportsName,
    });
  }, [sportsName]);

  const getSports = () => {
    getAllSports()
      .then((response) => {
        setSportsName(response.data);
        setSelectedSportsId(response.data[0]?._id);
        setSelectedSportsName(response.data[0]?.name);
      })
      .catch((error) => error);
  };
  const getSportsData = (name, id) => {
    setSelectedSportsId(id);
    setSelectedSportsName(name);
  };
  const toggleActive = (index) => {
    setFirstIndexActive(false);
    changeState({ ...appState, activeObject: appState.objects[index] });
  };

  const toggleActiveStyle = (index) => {
    if (index == 0 && isFirstIndexActive == true) {
      return "teamPlayer-content active";
    } else if (appState.objects[index] === appState.activeObject) {
      return "teamPlayer-content active";
    } else {
      return "teamPlayer-content inactive";
    }
  };
  const teamsAndPlayerList = () => {
    setIsLoading(true);
    const body = {
      page: page,

      limit: 10,

      sportsId: selectedSportsId,

      sportsName: selectedSportsName,

      comp_id: props.selectedLeague,
    };

    GetTeamsAndPlayers(body)
      .then((response) => {
        setIsLoading(false);
        setDocs(response.data.docs);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    getSports();
  }, []);
  useEffect(() => {
    teamsAndPlayerList();
  }, [selectedSportsId, selectedSportsName, props.selectedLeague, page]);
  const selectHomeFeature = (event, id, tagTop) => {
    const body = {
      _id: id,

      sportsId: selectedSportsId,

      sportsName: selectedSportsName,

      tagTop: !tagTop,
    };
    const recordFound = _.find(checkedFeatureCards, (item) => item === id);

    if (recordFound) {
      const newList = _.filter(checkedFeatureCards, (item) => item !== id);

      setCheckFeatureCards(newList);
      TagTopTeamsOrPlayers(body)
        .then((response) => {
          if (response) {
            teamsAndPlayerList();
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });
    } else {
      setCheckFeatureCards([...checkedFeatureCards, id]);
      TagTopTeamsOrPlayers(body)
        .then((response) => {
          if (response) {
            teamsAndPlayerList();
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });
    }
  };
  const handlePagination = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    setPage(1);
  }, [selectedSportsName, props.selectedLeague]);
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                "bg-white"
              }
              style={{ height: "100%" }}
            >
              <h3
                className={
                  "font-semibold text-lg" +
                  "text-blueGray-700 productivity_title"
                }
                style={{ paddingTop: "10px", paddingLeft: "10px" }}
              >
                Notification Personalization
              </h3>
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <span style={{ marginLeft: 4 }}>
                    <FilteredLeagueDropDown sports={selectedSportsId} />
                  </span>
                  <div className="block w-full overflow-x-auto flex flex-row  ">
                    {sportsName?.map((item, index) => (
                      <>
                        <div
                          key={item._id}
                          onClick={() => {
                            toggleActive(index);
                            getSportsData(item.name, item._id);
                          }}
                          className={`relative flex flex-col shadow-lg shadow-ug rounded + ${toggleActiveStyle(
                            index
                          )}`}
                        >
                          {item.name}
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="flex justify-center w-full overflow-x-auto py-4 "
                    style={{ widht: "100vw" }}
                  >
                    {/* Projects table */}
                    <table
                      className="table-fixed"
                      style={{ background: "#6cf97d52", width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className={
                              "px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                              "bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading"
                            }
                          >
                            S No.
                          </th>
                          <th
                            className={
                              "px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                              "bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading"
                            }
                          >
                            Team/Player
                          </th>

                          <th
                            className={
                              "px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                              "bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading"
                            }
                          >
                            Tag Untag
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* {!isLoading ? (
                          teamsAndPlayerList()
                        ) : (
                          <SyncLoader
                            color="#23A5E0"
                            loading={isLoading}
                            css={override}
                            size={12}
                          />
                        )} */}

                        {docs?.map((item, index) => (
                          <tr>
                            <td className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data_title">
                              {index + 1}
                            </td>
                            <td className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data_title">
                              {item.name}
                            </td>
                            <td className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data">
                              <Checkbox
                                checked={
                                  item.tagTop === true
                                    ? true
                                    : false &&
                                      _.find(checkedFeatureCards, (item) => {
                                        // item === item._id;
                                      }) !== undefined
                                }
                                onChange={(event) => {
                                  selectHomeFeature(
                                    event,
                                    item._id,
                                    item.tagTop
                                  );
                                }}
                                color="default"
                                inputProps={{
                                  "aria-label": "checkbox with default color",
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    padding: 20,
                  }}
                >
                  <Stack spacing={2}>
                    <Pagination
                      page={page}
                      onChange={handlePagination}
                      count={totalPages}
                      variant="outlined"
                      color="primary"
                    />
                  </Stack>
                  {/* console.log */}
                </div>
                {/* <div style={{ textAlign: 'center', padding: 10 }}>
                      <SyncLoader
                        color="#23A5E0"
                        loading={isLoadingMore}
                        css={override}
                        size={12}
                      />
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedLeague: state.leaguesReducer.selectedLeague,
  };
};
export default connect(mapStateToProps)(TeamPlayerNotification);
