import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { ReqForgotPassword } from '../../../handlers/admin.handlers';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const mHistory = useHistory();
  const onChange = (event) => {
    setEmail(event.target.value);
  };
  const onForgotPassword = () => {
    const body = {
      emailId: email,
    };
    ReqForgotPassword(body).then((res) => {
      if (res.status == true) {
        alert(
          'Recovery mail sent to your registered mail id, Please save this reference number for updating your password: ' +
            ' ' +
            res.data.data.reference
        );
        mHistory.push('/updatePassword');
      }
    });
  };
  return (
    <>
      <div
        className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
        style={{
          background: '#383838',
        }}
      >
        <div className="container-fluid  " style={{ marginTop: 30 }}>
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 ">
              <div
                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0"
                style={{ background: '#525252' }}
              >
                <div className="flex-auto px-4 lg:px-10 py-10 pt-5">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/SportsTak.png`}
                  />
                  <div className="text-center relative w-full mb-3 mt-10">
                    <h1
                      className="block uppercase text-xs font-bold mb-2"
                      style={{ color: '#FFF', fontSize: '20px' }}
                    >
                      Account Recovery{' '}
                    </h1>
                  </div>

                  <div className="text-center   relative w-full mb-3 mt-5">
                    <h3 style={{ color: '#FFF', fontSize: '18px' }}>
                      Get a verification code
                    </h3>
                  </div>
                  <div className="relative w-full mb-6 mt-5">
                    <p style={{ color: '#FFF', fontSize: '13px' }}>
                      To get a verification code, first confirm the recovery
                      email address you added to your account
                    </p>
                  </div>

                  <div className="relative w-full mb-6">
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                      type="email"
                      onChange={onChange}
                      value={email}
                    />
                  </div>
                  <Button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    style={{ backgroundColor: '#797979' }}
                    onClick={onForgotPassword}
                  >
                    Send
                  </Button>
                  <div className="text-center mt-6">
                    <Link
                      className=" text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      to="/"
                    >
                      Go to Login page
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
