import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import PreviewModal from "../../../components/PreviewModal";

import Sidebar from "../../../components/Sidebar/Sidebar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SportsTaggingDropdown from "../../../components/Dropdowns/SportsTaggingDropdown";
import LeagueDropdownTagging from "../../../components/Dropdowns/LeagueDropdownTagging";
import { GetAllTagLeagues } from "../../../handlers/leagues.handlers";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeagueTagging,
} from "../../../redux/action-dispatchers/leagues.dispatchers";
import {
  dispatchAllSports,
  dispatchSelectedSportsTagging,
} from "../../../redux/action-dispatchers/sports.dispatchers";
import UnsavedChangesWarning from "../../../components/AlertModal/UnsavedChangesWarning";
import {
  AddTwitterToVideos,
  GetTwitterVideosDetail,
} from "../../../handlers/twitter.handlers";
import { sports } from "../../../handlers/videos.handlers";
const EditTwitterVideos = (props) => {
  let fileInput = React.useRef();
  const { id } = useParams();
  const history = useHistory();
  const [variants, setVariants] = useState([]);
  const [showMedia, setShowMedia] = useState();
  const [mediaThumbnail, setMediaThumbnail] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [seoTitle, setSEOTitle] = useState("");
  const [seoSlug, setSEOSlug] = useState("");
  const [seoDescription, setSEODescription] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [editSEOTitle, setEditSEOTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editSEODescription, setEditSEODescription] = useState(false);
  const [editKeywords, setEditKeyWords] = useState(false);
  const [editSEOSlug, setEditSEOSlug] = useState(false);
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: "",
    height: "",
    width: "",
    file: "",
  });
  const [changeLeague, setChangeLeague] = useState(false);
  const [changeSport, setChangeSport] = useState(false);
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const [docs, setDocs] = useState();
  const handleBack = () => {
    history.goBack();
  };
  let tid = parseInt(id);
  useEffect(() => {
    GetTwitterVideosDetail(id)
      .then((response) => {
        setDocs(response.data);
        setVariants(response.data?.media?.variants);
        setShowMedia(response.data?.media?.variants[0].url);
        setMediaThumbnail(response.data?.media?.preview_image_url);
        // setTitle(response.data?.text);
        // setDescription(response.data?.author?.description);
      })
      .catch((error) => error);
  }, []);
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        // if (height === 1080 && width === 1920) {
        setPreviewModal({
          imageData: reader.result,
          show: true,
          file: file,
        });
        // }
        //  else {
        //   alert("Image width and height must be 1920x1080 px");
        //   return false;
        // }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) {};
  };
  const onPreviewClose = () =>
    setPreviewModal({ imageData: "", file: "", show: false });
  const onPreviewSuccess = () => {
    setMediaThumbnail(previewModal.imageData);
    setPreviewModal({
      imageData: previewModal.imageData,
      show: false,
      file: previewModal.file,
    });
  };
  const getAllTaggedLeague = () => {
    GetAllTagLeagues(
      props.selectSports,
      props.leagueSearch == "" ? "search" : props.leagueSearch
    )
      .then((response) => {
        if (response.status) {
          dispatchAllLeaguesTag(response.data);
        } else {
          dispatchAllLeaguesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllLeaguesTag([]);
      });
  };
  const onSportSelected = (value) => {
    setChangeSport(false);
    setIsUnsaved();
    dispatchSelectedSportsTagging({
      selectSports: value,
      selectedSportsTagging: value,
    });
    dispatchAllLeaguesTag([]);
    dispatchSelectedLeagueTagging({ selectedLeagueTag: "" });
    // getAllTaggedLeague();
  };
  const onLeagueSelected = (value) => {
    setChangeLeague(false);
    setIsUnsaved();
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: value,
      selectedLeagueFilterTag: value,
    });
  };
  const changeTitle = () => {
    let new_seo_title = title;

    setSEOTitle(new_seo_title.toLowerCase());
  };
  useEffect(() => {
    changeTitle();
  }, [title]);
  const changeSlug = () => {
    let new_seo_slug = seoTitle;
    new_seo_slug = new_seo_slug.replaceAll(" ", "-");
    setSEOSlug(new_seo_slug.toLowerCase());
  };
  useEffect(() => {
    changeSlug();
  }, [seoTitle]);
  useEffect(() => {
    dispatchAllSports([]);
    dispatchSelectedSportsTagging("");
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getAllTaggedLeague();
  }, [props.leagueSearch, props.selectSports]);
  const publishTwitterVideos = () => {
    const body = {
      title: title,
      // title: title == "" ? docs?.text : title,
      seoSlug: seoSlug,
      tid: docs?.tid,
      // description: description == "" ? docs.author.description : description,
      description: description,
      sports: props.selectSports,
      variants: variants,
      status: true,
      seoTitle: seoTitle,
      seoDescription: seoDescription,
      keywords: keywords,
      league: props.selectedLeagueTag,
      thumbnail:
        previewModal.file == ""
          ? docs.media?.preview_image_url
          : previewModal.imageData,
      AllLeague: "",
      aspectRatio: docs?.media?.aspect_ratio,
      isNewThumbnail: previewModal.file !== "" ? true : false,
    };
    const formData = new FormData();
    Object.keys(body).forEach(function (key) {
      formData.append(key, body[key]);
    });

    if (title == "") {
      alert("Please add Title");
    } else if (description == "") {
      alert("Please add Description");
    } else if (body.league == "") {
      alert("Tagging to a league is compulsory");
    } else if (body.sports == undefined) {
      alert("Tagging to a sports is compulsory");
    } else if (seoSlug == "") {
      alert("Please add SEO Slug");
    } else if (seoTitle == "") {
      alert("Please add SEO Title");
    } else if (keywords == "") {
      alert("Please add Keywords");
    } else if (seoDescription == "") {
      alert("Please add SEO Description");
    } else {
      AddTwitterToVideos(body)
        .then((response) => {
          if (response.status) {
            alert("Success..");
            history.push("/twitterVideosList");
          } else {
            alert(`Something went wrong`);
          }
        })
        .catch((error) => error);
    }
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div>
              <div
                className=" pb-4"
                style={{
                  cursor: "pointer",
                  marginLeft: "-20px",
                  width: "30px",
                }}
                onClick={handleBack}
              >
                <ArrowBackIcon />
              </div>
              {previewModal.show && (
                <PreviewModal
                  imageData={previewModal.imageData}
                  title="Upload video image"
                  message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                  onSuccess={onPreviewSuccess}
                  onClose={onPreviewClose}
                />
              )}

              <div style={{ display: "inline-block", verticalAlign: "top" }}>
                <ReactPlayer controls url={showMedia} light={mediaThumbnail} />

                {/* {previewModal.imageData && (
                  <img
                    src={previewModal.imageData}
                    style={{
                      width: 210,
                      height: 115,
                      border: "2px solid gray",
                    }} // yha pehla Sir width thi vo bhi 500px for knowledge
                  />
                )} */}
                {isImageUploading ? (
                  <div style={{ textAlign: "center", padding: 10 }}>
                    <div>Uploading Image Please Wait...</div>
                    <div>
                      <PulseLoader
                        color="#23A5E0"
                        loading={true}
                        css={override}
                        size={10}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div></div>
                    <div>
                      <input
                        ref={fileInput}
                        type="file"
                        multiple={false}
                        onChange={onFileSelect}
                        accept="image/x-png,image/jpeg"
                        style={{
                          backgroundColor: "#000",
                          height: 35,
                          width: 40,
                          position: "absolute",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                      />
                      <i
                        className="fas fa-camera fa-2x"
                        style={{
                          margin: 8,
                          color: "#555",
                          cursor: "pointer",
                        }}
                      ></i>
                      Please upload image in the dimension of 1920x1080
                    </div>
                  </>
                )}
              </div>
              <hr className="my-4 md:min-w-full" />
              {/* seo */}
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditTitle(true)}
              >
                *Title:{" "}
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editTitle ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="5"
                    cols="80"
                    id="textbox"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditTitle(false);
                        setTitle("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{title}</div>
              )}
              <hr className="my-4 md:min-w-full" />
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditDescription(true)}
              >
                *Description:{" "}
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editDescription ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="5"
                    cols="80"
                    id="textbox"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        {
                          setEditDescription(false);
                          setDescription("");
                        }
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{description}</div>
              )}
              <hr className="my-4 md:min-w-full" />
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <SportsTaggingDropdown onChange={onSportSelected} />
              </div>
              {props.selectSports && (
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <LeagueDropdownTagging onChange={onLeagueSelected} />
                </div>
              )}
              <hr className="my-4 md:min-w-full" />
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditSEOTitle(true)}
              >
                *SEO Title:
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editSEOTitle ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="4"
                    cols="80"
                    id="textbox"
                    value={seoTitle}
                    onChange={(e) => setSEOTitle(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditSEOTitle(false);
                        setSEOTitle("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{seoTitle}</div>
              )}
              <hr className="my-4 md:min-w-full" />
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditSEOSlug(true)}
              >
                *SEO Slug:
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editSEOSlug ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="4"
                    cols="80"
                    id="textbox"
                    value={seoSlug}
                    onChange={(e) => setSEOSlug(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditSEOSlug(false);
                        setSEOSlug("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{seoSlug}</div>
              )}

              <hr className="my-4 md:min-w-full" />
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditKeyWords(true)}
              >
                *Keywords:{" "}
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editKeywords ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="4"
                    cols="80"
                    id="textbox"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditKeyWords(false);
                        setKeywords("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{keywords}</div>
              )}
              <hr className="my-4 md:min-w-full" />
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditSEODescription(true)}
              >
                *SEO Description:{" "}
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editSEODescription ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="3"
                    cols="80"
                    id="textbox"
                    value={seoDescription}
                    onChange={(e) => setSEODescription(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditSEODescription(false);
                        setSEODescription("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{seoDescription}</div>
              )}
            </div>
            <button
              className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mt-6`}
              type="submit"
              onClick={publishTwitterVideos}
            >
              {"Publish Video"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    docs: state.twitterNativeReducer.docs,
    video: state.twitterNativeReducer.video,
    thumbnail: state.twitterNativeReducer.thumbnail,
    title: state.twitterNativeReducer.title,
    description: state.twitterNativeReducer.description,
    sports: state.sportsReducer.sports,
    selectSports: state.sportsReducer.selectSports,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
    selectedSports: state.sportsReducer.selectedSports,
    selectedLeague: state.leaguesReducer.selectedLeague,
    leagueSearch: state.leaguesReducer.leagueSearch,
  };
};
export default connect(mapStateToProps)(EditTwitterVideos);
