import React, { useEffect, useState } from 'react';
import { GetPlayersList } from '../../handlers/players.handler';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {  tagTeamsSpoerWise} from "../../handlers/news.handlers"
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { tagTeams } from '../../handlers/news.handlers';
import AlertModal from '../AlertModal';
import { GetNewsInfo } from '../../handlers/news.handlers';
import LoginAppConstants from '../../constants/LoginAppConstants';
import { get } from 'https';

const useStyles = makeStyles((theme) => ({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
    }));
    
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

const PlayerListDropDownAdd = (props) => {
    // const playersData= props?.data;
    const classes = useStyles();
  const [alertModal, setAlertModal] = useState(false);
  const [name, setName] = useState("");
  const [playerName, setPlayerName] = useState([]);
  const [taggedplayer, setTaggedPlayer]= useState([]);
  const [playersData, setplayersData] = useState([]);
  const [sportsId, setSportsId] = useState();
  const [page, setPage] = useState(1);
  const [playersList, setPlayerList]= useState([]); 
  useEffect(()=>{
    setplayersData(props?.data);
  }, [props?.data]);
 
  useEffect(()=>{
    setSportsId(props?.sportsId);
  }, [sportsId]);
  const handlePage = (event, value) => {
    setPage(value);
    getPlayers(value, name);
  };
  const searchWithKey = (event, value) => {
    setPage(1);
    setName(event.target.value);
    getPlayers(page, event.target.value);
  };
  const tagplayerToNews = () => {
    let arr = []
    playerName?.map((item, index)=>{
        if(item!==undefined)
        {

            arr.push(item?._id);
        }
    })
    if (arr?.length ==0) {
        alert("No player to tag.");
      } else if(props?.newsId!==undefined) {
        const data = {
          _id: props?.newsId,
          players: arr
        };
        tagTeamsSpoerWise(data)
          .then((response) => {
            if (response.status) {
              setAlertModal(true);
            }
          })
          .catch((error) => error);
      }
      else{
            props.onChange(arr);
      }
  };

    const handleChange = (event) => {
            if(event.target.value){
                setPlayerName(event.target.value);
            }
            // setPlayerName(event.target.value);
            const value = event.target.value;
            if (value[value.length - 1] === 'all') {
              setPlayerName(playerName.length === playersData?.docs.length ? [] : playersData?.docs);
              return;
            }
          };

          const getPlayers = (page, name)=>{
            const body = {
              sportId : props?.sportsId,
              limit : 10 ,
              page : page,
              searchKey: name
            }
            GetPlayersList(body)
            .then((response) => {
              setplayersData(response?.data);
            })
            .catch((error) => error);
        }
       
    return (
    <>
    <div style={{display:"flex", alignItems:"center"}}>
    Tagged Players:{" "}
    {alertModal && (
        <AlertModal
          title="Thanks"
          message="Player has been updated successfully !"
          onSuccess={() => setAlertModal(false)}
        />
      )}
        <FormControl className={classes.formControl}>
                 <InputLabel id="demo-mutiple-checkbox-label">Tag Players</InputLabel>
               <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={playerName}
                  onChange={(val) => handleChange(val)}
                  input={<Input />}
                  renderValue={(selected) => {
                    return (
                      selected &&
                      selected
                        .map((items) => {
                          return items && items?.player?.title;
                        })
                        .join(', ')
                    );
                  }}
                  // MenuProps={MenuProps}
                >
                  {/* <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : '',
                    }}
                  >
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        playerName.length > 0 && playerName.length < playersData?.docs.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem> */}
                  {playersData?.docs?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Checkbox
                        checked={
                          playerName &&
                          playerName?.findIndex((x) => x && x._id === item._id) > -1
                        }
                        // onClick={() => selectedTeam(name._id)}
                      />
                      <ListItemText primary={item?.player?.title} />
                    </MenuItem>
                  ))}
                     <Stack spacing={2}>
      <Pagination count={playersData?.totalPages} page={page} onChange={handlePage}/>
      
    </Stack>
                </Select>

                  
              </FormControl>
              <input
          type="text"
          placeholder="Search Player"
          value={name}
          style={{width:"40%"}}
          onChange={searchWithKey}
          className="border-0 mx-3 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        /><Button
        className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
          style={{
            marginLeft: "5px",
            // height: '30px',
            // width: 'auto',
            backgroundColor: "rgba(52, 211, 153)",
            color:"#fff",
            marginLeft:"10px"
            
            // color:newsInfo.showArticle && 'white'
          }}
          onClick={tagplayerToNews}
      >
        Tag
      </Button>
    </div>
    <div>
        {playerName?.map((item, index)=>(
          <span style={{border:"1px solid green", margin:"5px", padding:"5px", borderRadius:"10px", backgroundColor:"#f1f2f6"}}>{item?.player?.title}</span>
        ))}
      </div>
    </>
    );
    }


export default PlayerListDropDownAdd;

