import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  dispatchCurrnetMatchesPage,
  dispatchSearchMatches,
  dispatchSelectedMatchesTagging,
} from '../../redux/action-dispatchers/matches.dispatcher';
import { Pagination, Stack } from '@mui/material';
const MatchesDropdownTagging = (props) => {
  const onChange = (event) => {
    const foundObject = _.find(props.matchesReducer.matchesTag, [
      'match_id',
      event.target.value,
    ]);

    props.onChange && props.onChange(event.target.value, foundObject?._id);

    dispatchSelectedMatchesTagging({
      selectedMatchesTag: event.target.value,
      selectedMatchesFilterTag: event.target.value,
      selectedMatchesIDTag: foundObject?._id,
    });
  };
  const onSearchMatches = (event) => {
    dispatchSearchMatches(event.target.value);
    dispatchCurrnetMatchesPage(1);
  };
  const handlePagination = (event, value) => {
    dispatchCurrnetMatchesPage(value);
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <select
            style={{
              // marginLeft: 20,
              border: '2px solid #acb9ce',
              borderRadius: 10,
              marginRight: '20px',
              minWidth: '500px',
            }}
            value={props.matchesReducer.selectedMatchesTag}
            onChange={onChange}
          >
            <option value="All">Select Matches</option>
            {props.matchesReducer.matchesTag?.map((matches) => (
              <>
                <option value={matches.match_id}>{matches.title}</option>
              </>
            ))}
          </select>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={props.matchesPage}
                onChange={handlePagination}
                count={props.totalPagesMatches}
                variant="outlined"
                color="primary"
              />
            </Stack>
          </div>
        </div>
        <input
          type="text"
          placeholder="Search Matches"
          value={props.matchesSearch}
          onChange={onSearchMatches}
          className="border-0 mx-3 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          style={{ maxHeight: '40px' }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    matchesReducer: state.matchesReducer,
    matchesSearch: state.matchesReducer.matchesSearch,
    totalPagesMatches: state.matchesReducer.totalPagesMatches,
    matchesPage: state.matchesReducer.matchesPage,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
  };
};

export default connect(mapStateToProps)(MatchesDropdownTagging);
