import { useState, useEffect } from "react";
import { GetTopTrends} from "../../handlers/twitter.handlers";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "@material-ui/core";

const TrendsAllLocations = (props)=>{
    const [data, setData] = useState(props?.twitterTaggedLocations);
    const [ trendKeywords, setTrendKeywords ] = useState([]);
    const [show, setShow] = useState(false)
    const [copyText, setCopyText] = useState(""); // Copy Trends
    const getTrends = (trends) =>{
        const body = `entryId=${trends?.entryId}`
        GetTopTrends(body)
        .then((response)=>{
            setTrendKeywords(response?.data?.twitter?.trends)
        })
    }
    
    // const CopyTrend = () =>{
    //   var copyText = document.getElementById("trendName");
    //   console.log('asd', copyText)
    //   // Select the text field
    //   copyText.select();
    //   // Copy the text inside the text field
    //   navigator.clipboard.writeText(copyText.value);
    //   alert("Copied the text: " + copyText.value);
    // }

    useEffect(() => {
        setData(props?.twitterTaggedLocations)
    }, [props?.twitterTaggedLocations]);

    const TextCopied = ()=>{
      alert('Trend Copied')
    };
    
    return (
        <>
        {!show ?
        <> 
        <div style={{color:'black', padding:'16px'}}>
            <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "60px",
                    }}
                  >
                    {data?.map((trends) => (
                      <div
                        className="relative flex flex-col min-w-0 w-full break-words
                    shadow-lg shadow-ug rounded twitterList-content"
                      >
                        <div className="twitterList-wrapper">
                          <div className="twitterList-container"
                            style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                            <h1
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                getTrends(trends)
                                setShow(true)
                                // dispatchTwitterMenuTxt(tags.menuTxt);
                              }}
                            >
                              {trends?.name}
                            </h1>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
        </div>
        </>
        :
        <>
        <div>
        <div style={{color:'black', padding:'16px'}}>
        <div >
            <ArrowBack onClick={()=> setShow(false)} style={{cursor:'pointer',marginLeft:'10%'}}/>
          </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "20px",
                  }}
                  >
                    {trendKeywords?.map((trends) => (
                      <div
                        className="relative flex flex-col min-w-0 w-full break-words
                    shadow-lg shadow-ug rounded twitterList-content"
                      >
                        <div className="twitterList-wrapper">
                          <div className="twitterList-container"
                            style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                            <div
                              id = 'trendName'
                              style={{ cursor: "pointer" }}
                              // onClick={CopyTrend}
                            >
                              {trends}
                            </div>
                          </div>
                          <CopyToClipboard
                        text={trends}
                        onCopy={() => setCopyText(true)}
                      >
                        <div  variant="contained" color="primary" 
                        style={{backgroundColor:'#0ea5e9', width:'100px', height:'35px', borderRadius:'50px',
                          display:'flex', justifyContent:'center',alignItems:'center', cursor:'pointer', fontWeight:'500'}}
                        onClick={TextCopied}>
                          Copy Trend
                        </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                    ))}
                  </div>
        </div>
        </div>
        </>
        }
        </>
    )
}
export default TrendsAllLocations;