import axios from 'axios';
import LoginAppConstants from '../constants/LoginAppConstants';
import AppConstants from '../constants';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);

const AxiosInstanceV2Call = axios.create({
  baseURL: AppConstants.WorkerConstants.API_BASE_PATH_V2,
  timeout: -1,
  headers: {
    'Content-Type': 'application/json',
    'cms-access-token': accessToken,
    source: 'cms',
    // Authorization: props.Authorization,
  },
});

AxiosInstanceV2Call.interceptors.response.use(
  (response) => {
    return {
    status: true,
    message: response.message,
    data: response.data.data,
  }},
  (error) => {
    return {
      statusCode: error?.response ? error?.response?.data.statusCode : 400,
      status: false,
      message: error?.response ? error?.response?.data : error.message,
    };
  }
);

export default AxiosInstanceV2Call;
