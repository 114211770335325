/* eslint-disable */
import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";

const TrWrapper = styled.tr`
  :nth-child(odd) {
    // background: #faf2f2;
    // background-color:#6cf97d52;
  }
  :nth-child(even) {
    background: #fafafa;
  }
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
    width:100px;
    width:fit-content;
    background-color:none;
  }

  &.helperContainerClass {
    // width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: left;
      width: 70%;
      vertical-align: middle;
      align-self: center;
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
  vertical-align: middle;
  align-self: center;

  .fa {
    color: black;
  }
`;

const RowHandler = SortableHandle(() => (
    <Handle className="handle">
        <i className="fa fa-bars"></i>
    </Handle>
));

const TableRow = ({ title, rank, className }) => {
    return (
        <TrWrapper className={className}>
            <td style={{ maxWidth: "900px",width:"10%" , backgroundColor:"#6cf97d52"}}>
                <div className="firstElement">
                    <RowHandler />
                    {title}
                </div>
            </td>
            <td style={{width:"1%"}}>{rank}</td>
        </TrWrapper>
    );
};

export default TableRow;
