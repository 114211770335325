// import { makeStyles } from '@material-ui/core';
// import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
// import { GetAllRoles } from '../../../../handlers/user.handlers';
// import {
//   dispatchRolesList,
//   dispatchSelectedRoles,
// } from '../../../../redux/action-dispatchers/roles.dispatchers';
// import FormControl from '@material-ui/core/FormControl';
// import { Button } from '@material-ui/core';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import ListItemText from '@material-ui/core/ListItemText';
// import AlertModal from '../../../../components/AlertModal';
// import Checkbox from '@material-ui/core/Checkbox';
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300,
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   chip: {
//     margin: 2,
//   },
//   noLabel: {
//     marginTop: theme.spacing(3),
//   },
// }));
// const RolesDropdown = (props) => {
//   // const classes = useStyles();

//   const [roleName, setRoleName] = React.useState([]);
//   // const [alertModal, setAlertModal] = useState(false);
//   // const isAllSelected =
//   //   props.docs.length > 0 && roleName.length === props.docs.length;
//   const getAllRolesData = () => {
//     GetAllRoles().then((response) => {
//       dispatchRolesList(response.data.data.roles);
//     });
//   };

//   // const handleChange = (event) => {
//   //   setRoleName(event.target.value);
//   //   const value = event.target.value;
//   //   if (value[value.length - 1] === 'all') {
//   //     setRoleName(roleName.length === props.docs.length ? [] : props.docs);
//   //     return;
//   //   }
//   //   let roleIdData = [];
//   //   roleName.map((item) => {
//   //     console.log('item', item);
//   //     roleIdData.push(item._id);
//   //   });
//   //   dispatchSelectedRoles(roleIdData);
//   //   props.onChange && props.onChange(roleIdData);
//   // };

//   const onChange = (event) => {
//     props.onChange && props.onChange(event.target.value);
//     dispatchSelectedRoles(event.target.value);
//   };
//   useEffect(() => {
//     getAllRolesData();
//   }, []);
//   return (
//     <>
//       <select
//         style={{
//           border: '2px solid #acb9ce',
//           borderRadius: 10,
//         }}
//         value
//         onChange={onChange}
//       >
//         <option>Select Roles</option>
//         {props.docs.map((roles) => (
//           <option value={roles._id}>{roles.name}</option>
//         ))}
//       </select>
//       {/* <div>
//         {alertModal && (
//           <AlertModal
//             title="Thanks"
//             message="Roles has been updated successfully !"
//             onSuccess={() => setAlertModal(false)}
//           />
//         )}
//         <FormControl className={classes.formControl}>
//           <InputLabel id="demo-mutiple-checkbox-label">Tag Roles</InputLabel>
//           <Select
//             labelId="demo-mutiple-checkbox-label"
//             id="demo-mutiple-checkbox"
//             multiple
//             value={roleName}
//             onChange={(val) => handleChange(val)}
//             input={<Input />}
//             renderValue={(selected) => {
//               return (
//                 selected &&
//                 selected
//                   .map((items) => {
//                     return items && items.name;
//                   })
//                   .join(', ')
//               );
//             }}
//             // MenuProps={MenuProps}
//           >
//             <MenuItem
//               value="all"
//               classes={{
//                 root: isAllSelected ? classes.selectedAll : '',
//               }}
//             >
//               <Checkbox
//                 classes={{ indeterminate: classes.indeterminateColor }}
//                 checked={isAllSelected}
//                 indeterminate={
//                   roleName.length > 0 && roleName.length < props.docs.length
//                 }
//               />
//               <ListItemText primary="Select All" />
//             </MenuItem>
//             {props.docs.map((name, index) => (
//               <MenuItem key={index} value={name}>
//                 <Checkbox
//                   checked={
//                     roleName &&
//                     roleName.findIndex((x) => x && x._id === name._id) > -1
//                   }
//                 />
//                 <ListItemText primary={name.name} />
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </div> */}
//     </>
//   );
// };

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GetAllRoles } from '../../../../handlers/user.handlers';
import {
  dispatchRolesList,
  dispatchSelectedRoles,
} from '../../../../redux/action-dispatchers/roles.dispatchers';
const RolesDropdown = (props) => {
  const onChange = (event) => {
    props.onChange && props.onChange(event.target.value);
    dispatchSelectedRoles({
      selectedRoles: event.target.value,
    });
  };
  const getAllRolesData = async() => {
   return await GetAllRoles(props.language).then((response) => {
      dispatchRolesList(response.data.data.roles);
    });
  };

  useEffect(() => {
    getAllRolesData();
  }, []);
  return (
    <>
      <select
        style={{
          border: '2px solid #acb9ce',
          borderRadius: 10,
        }}
        onChange={onChange}
      >
        {props.value ? <option>{props.value}</option> : <option>Select Roles</option>}
        {props.docs.map((roles, index) => (
          <option key={index} value={roles._id}>
            {roles.name}
          </option>
        ))}
      </select>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    docs: state.rolesReducer.docs,
    selectedRoles: state.rolesReducer.selectedRoles,
  };
};
export default connect(mapStateToProps)(RolesDropdown);
