import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SportsTaggingDropdown from '../../../components/Dropdowns/SportsTaggingDropdown';
import LeagueDropdownTagging from '../../../components/Dropdowns/LeagueDropdownTagging';
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeagueTagging,
} from '../../../redux/action-dispatchers/leagues.dispatchers';
import { dispatchSelectedSportsTagging } from '../../../redux/action-dispatchers/sports.dispatchers';
import { WithContext as ReactTags } from 'react-tag-input';
import Multiselect from 'multiselect-react-dropdown';
import UnsavedChangesWarning from '../../../components/AlertModal/UnsavedChangesWarning';
import { connect } from 'react-redux';
import { GetAllTagLeagues } from '../../../handlers/leagues.handlers';
import { Button } from '@material-ui/core';
import {
  AddOrEditTwitterContent,
  EditTwitterTags,
  GetTwitterSuggestions,
} from '../../../handlers/twitter.handlers';
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const EditTwitterContent = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [changeLeague, setChangeLeague] = useState(false);
  const [twitterHandlesTag, setTwitterHandlesTag] = useState([]);
  const [orKeywordsTag, setorKeywordsTag] = useState([]);
  const [ANDKeywordsTag, setANDKeywordsTag] = useState([]);
  const [NOTKeywordsTag, setNOTKeywordsTag] = useState([]);
  const [changeSport, setChangeSport] = useState(false);
  const [sportsName, setSportsName] = useState('');
  const [leagueName, setLeagueName] = useState('');
  const [twitterHandles, setTwitterHandles] = useState([]);
  const [ORkeywords, setORkeywords] = useState([]);
  const [ANDkeywords, setANDkeywords] = useState([]);
  const [NOTkeywords, setNOTkeywords] = useState([]);
  // multiselect
  const [options, setOptions] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [twitterSuggestion, setTwitterSuggestion] = useState([]);
  const [twitterSuggestionTag, setTwitterSuggestionTag] = useState([]);
  const [addedTwitterHandles, setAddedTwitterHandles] = useState([]);

  // multiselect end
  const [mongoId, set_id] = useState('');
  const [title, setTitle] = useState('');
  const [tag, setTag] = useState('');
  const [sportsAndLeague, setSportsAndLeague] = useState([]);
  const [formData, setFormData] = useState({
    comp_id: '',
    sportName: '',
    _id: '',
  });

  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const handleBack = () => {
    history.goBack();
  };
  const getTwitterInfo = () => {
    EditTwitterTags(id)
      .then((response) => {
        setANDkeywords(response.data.menuDetail.ANDkeywords);
        setNOTkeywords(response.data.menuDetail.NOTkeywords);
        setORkeywords(response.data.menuDetail.ORkeywords);
        setTwitterHandles(response.data.menuDetail.twitterHandles);
        setTitle(response.data.menuTxt);
        setTag(response.data.tag);
        set_id(response.data._id);
        let sportsAndLeague = response.data.tag;
        sportsAndLeague = sportsAndLeague.split('_');
        setSportsAndLeague(sportsAndLeague);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    let andArray = ANDkeywords;
    andArray = andArray.map((str, index) => ({ id: str, text: str }));
    setANDKeywordsTag(andArray);
  }, [ANDkeywords]);
  useEffect(() => {
    let andArray = ORkeywords;
    andArray = andArray.map((str, index) => ({ id: str, text: str }));
    setorKeywordsTag(andArray);
  }, [ORkeywords]);
  useEffect(() => {
    let andArray = NOTkeywords;
    andArray = andArray.map((str, index) => ({ id: str, text: str }));
    setNOTKeywordsTag(andArray);
  }, [NOTkeywords]);
  useEffect(() => {
    let andArray = twitterHandles;
    andArray = andArray.map((str, index) => ({ id: str, text: str }));
    setTwitterHandlesTag(andArray);
  }, [twitterHandles]);

  const onSportSelected = (value) => {
    const foundObject = _.find(props.sportsReducer.sports, ['_id', value]);
    setFormData({ ...formData, sportName: value });
    setChangeSport(false);
    setIsUnsaved();
    setSportsName(foundObject.name);
    dispatchSelectedSportsTagging({
      selectSports: value,
      selectedSportsTagging: value,
    });
    dispatchAllLeaguesTag([]);
    dispatchSelectedLeagueTagging({ selectedLeagueTag: '' });
    // getAllTaggedLeague();
  };
  const onLeagueSelected = (value) => {
    setFormData({ ...formData, comp_id: value });
    setChangeLeague(false);
    setIsUnsaved();
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: value,
      selectedLeagueFilterTag: value,
    });
  };
  const getAllTaggedLeague = () => {
    GetAllTagLeagues(
      props.selectSports,
      props.leagueSearch == '' ? 'search' : props.leagueSearch
    )
      .then((response) => {
        if (response.status) {
          dispatchAllLeaguesTag(response.data);
        } else {
          dispatchAllLeaguesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllLeaguesTag([]);
      });
  };
  useEffect(() => {
    getTwitterInfo();
    window.scrollTo(0, 0);
  }, []);

  let sports = sportsAndLeague[0];
  sports = sports?.charAt(0).toUpperCase() + sports?.slice(1);

  let league = sportsAndLeague?.[1] == 'All' ? 'AllFor'+ sports: sportsAndLeague[1];
  league = league?.charAt(0).toUpperCase() + league?.slice(1);

  // Twitter Issue fix from Here
  useEffect(() => {
    let sports = sportsAndLeague[0];
    sports = sports?.charAt(0).toUpperCase() + sports?.slice(1);

    let league = sportsAndLeague?.[1] == 'All' ? 'AllFor'+ sports: sportsAndLeague[1];
    league = league?.charAt(0).toUpperCase() + league?.slice(1); /// Uncommented this on my own


    const foundObject = _.find(props.sportsReducer.sports, ['name', sports]);
    const foundLeague = _.find(props.leaguesReducer.leagues, [
      'comp_id',
      league,
    ]);

    setLeagueName(foundLeague?.abbr);
    setFormData({ ...formData, sportName: sportsAndLeague[0] });
    setChangeSport(false);
    setIsUnsaved();
    setSportsName(sportsAndLeague[0]);
    dispatchSelectedSportsTagging({
      selectSports: foundObject?._id,
      selectedSportsTagging: foundObject?._id,
    });
    // dispatchAllLeaguesTag([]);
    // dispatchSelectedLeagueTagging({ selectedLeagueTag: '' });
    setFormData({ ...formData, comp_id: league });
    setChangeLeague(false);
    setIsUnsaved();
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: league,
      selectedLeagueFilterTag: league,
    });
  }, [sportsAndLeague]);
  useEffect(() => {
    getAllTaggedLeague();
  }, [props.leagueSearch, props.selectSports]);
  const onFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setIsUnsaved();
  };

  const handleSubmit = () => {
    const body = {
      title: title,
      twitterHandles: addedTwitterHandles,
      ORkeywords: ORkeywords,
      ANDkeywords: ANDkeywords,
      NOTkeywords: NOTkeywords,
      comp_id: formData.comp_id,
      sportName: sportsName,
      tag: tag,
      _id: mongoId,
    };
    if (title == '') {
      alert('Please enter Title');
    } else if (
      addedTwitterHandles.length == 0 &&
      ANDkeywords.length == 0 &&
      NOTkeywords.length == 0
    ) {
      alert(
        'Please add either Accounts or AND contain field or Not contain feild'
      );
    } else {
      AddOrEditTwitterContent(body)
        .then((response) => {
          if (response.status) {
            alert('Success');
            history.push('/twitterList');
          }
        })
        .catch((error) => error);
    }
  };
  //Account tags
  const handleDelete = (i) => {
    setTwitterHandles(twitterHandles.filter((tag, index) => index !== i));
    setTwitterHandlesTag(twitterHandlesTag.filter((tag, index) => index !== i));
  };
  const handleAddition = (tag) => {
    setTwitterHandles([...twitterHandles, tag.text]);
    setTwitterHandlesTag([...twitterHandlesTag, tag]);
  };
  // multiSelect
  const handleDeleteSuggestion = (i) => {
    setTwitterSuggestion(twitterSuggestion.filter((tag, index) => index !== i));
    setTwitterSuggestionTag(
      twitterSuggestionTag.filter((tag, index) => index !== i)
    );
  };
  const handleAdditionSuggestion = (tag) => {
    setSearchKey('');
    setTwitterSuggestion(tag.map((name) => name.name));
    setTwitterSuggestionTag([...twitterSuggestionTag, tag]);
  };
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = twitterHandlesTag.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {};

  //Account tags end

  //Hashtags or keywords tags
  const handleDeleteORKeywords = (i) => {
    setORkeywords(ORkeywords.filter((tag, index) => index !== i));
    setorKeywordsTag(orKeywordsTag.filter((tag, index) => index !== i));
  };
  const handleAdditionORKeywords = (tag) => {
    setORkeywords([...ORkeywords, tag.text]);
    setorKeywordsTag([...orKeywordsTag, tag]);
  };
  const handleDragORKeywords = (tag, currPos, newPos) => {
    const newTags = orKeywordsTag.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClickORKeywords = (index) => {};

  //Hashtags or keywords end

  //ANDKeywords tags
  const handleDeleteANDKeywords = (i) => {
    setANDkeywords(ANDkeywords.filter((tag, index) => index !== i));
    setANDKeywordsTag(ANDKeywordsTag.filter((tag, index) => index !== i));
  };
  const handleAdditionANDKeywords = (tag) => {
    setANDkeywords([...ANDkeywords, tag.text]);
    setANDKeywordsTag([...ANDKeywordsTag, tag]);
  };
  const handleDragANDKeywords = (tag, currPos, newPos) => {
    const newTags = ANDKeywordsTag.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClickANDKeywords = (index) => {};

  //ANDKeywords tags end

  //NOTKeywords tags
  const handleDeleteNOTKeywords = (i) => {
    setNOTkeywords(NOTkeywords.filter((tag, index) => index !== i));
    setNOTKeywordsTag(NOTKeywordsTag.filter((tag, index) => index !== i));
  };
  const handleAdditionNOTKeywords = (tag) => {
    setNOTkeywords([...NOTkeywords, tag.text]);
    setNOTKeywordsTag([...NOTKeywordsTag, tag]);
  };
  const handleDragNOTKeywords = (tag, currPos, newPos) => {
    const newTags = NOTKeywordsTag.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClickNOTKeywords = (index) => {};

  //NOTKeywords tags ends
  // ṃultiSelect Suggestion API
  const getSuggestionForTwtter = () => {
    GetTwitterSuggestions(1, searchKey).then((response) => {
      setSuggestion(response.data.docs);
    });
  };
  useEffect(() => {
    getSuggestionForTwtter();
  }, [searchKey]);
  useEffect(() => {
    let suggestionArr = suggestion;
    suggestionArr = suggestionArr.map((str) => ({
      name: str.username,
      id: str._id,
    }));
    setOptions(suggestionArr);
  }, [suggestion]);
  useEffect(() => {
    let totalHandlesArr = [];

    setAddedTwitterHandles([...twitterHandles, ...twitterSuggestion]);
  }, [twitterSuggestion, twitterHandles]);
  // ṃultiSelect Suggestion API end
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 20 }}>
        <div className="flex flex-wrap">
          <div
            className=" pb-4"
            style={{
              cursor: 'pointer',
              marginLeft: '-20px',
              width: '140px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleBack}
          >
            <ArrowBackIcon />
            <h1>Twitter List</h1>
          </div>
          <div className="w-full xl-12 mb-12 xl:mb-0 px-1">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                'bg-white'
              }
              style={{ height: '100%', width: '70vw' }}
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap flex-col">
                  <h1>Edit Twitter Content</h1>
                  <hr className="my-4 md:min-w-full" />
                  <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>*Title:</h1>
                      <input
                        type="text"
                        placeholder="Title"
                        value={title}
                        name="title"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>Tag:</h1>
                      <input
                        type="text"
                        placeholder="Tag"
                        value={tag}
                        name="tag"
                        onChange={(e) => setTag(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>Accounts:</h1>
                      <ReactTags
                        tags={twitterHandlesTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        handleTagClick={handleTagClick}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                      <div style={{ marginTop: '20px' }}>
                        <Multiselect
                          onSearch={(e) => setSearchKey(e)}
                          options={options} // Options to display in the dropdown
                          selectedValues={selectedValue} // Preselected value to persist in dropdown
                          onSelect={handleAdditionSuggestion} // Function will trigger on select event
                          onRemove={handleDeleteSuggestion} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                    </div>
                    {/* <div className="createTwitterContent-input-container">
                      {' '}
                      <h1>HashTags or Keywords:"</h1>
                      <ReactTags
                        tags={orKeywordsTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDeleteORKeywords}
                        handleAddition={handleAdditionORKeywords}
                        handleDrag={handleDragORKeywords}
                        handleTagClick={handleTagClickORKeywords}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                    </div> */}
                  </div>
                  <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>And contains these items:</h1>
                      <ReactTags
                        tags={ANDKeywordsTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDeleteANDKeywords}
                        handleAddition={handleAdditionANDKeywords}
                        handleDrag={handleDragANDKeywords}
                        handleTagClick={handleTagClickANDKeywords}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                    </div>
                    <div className="createTwitterContent-input-container">
                      {' '}
                      <h1>Does not contain these items:</h1>
                      <ReactTags
                        tags={NOTKeywordsTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDeleteNOTKeywords}
                        handleAddition={handleAdditionNOTKeywords}
                        handleDrag={handleDragNOTKeywords}
                        handleTagClick={handleTagClickNOTKeywords}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                    </div>
                  </div>
                  {/**Sports and League Edit */}
                  {/* <hr className="my-4 md:min-w-full" />
                  <div style={{ marginLeft: '30px' }}>
                    <h1>Sports and League Tag</h1>
                    <div>{sports}</div>
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <SportsTaggingDropdown onChange={onSportSelected} />
                    </div>
                    {props.selectSports && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <div>{leagueName}</div>

                        <LeagueDropdownTagging onChange={onLeagueSelected} />
                      </div>
                    )}
                  </div> */}
                  <hr className="my-4 md:min-w-full" />
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: '100px', justifySelf: 'center' }}
                      onClick={handleSubmit}
                    >
                      submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectSports: state.sportsReducer.selectSports,
    leagueSearch: state.leaguesReducer.leagueSearch,
    sportsReducer: state.sportsReducer,
    leaguesReducer: state.leaguesReducer,
  };
};
export default connect(mapStateToProps)(EditTwitterContent);
