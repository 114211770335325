import { configStore } from '../store';
import { RolesActionTypes } from '../action-types';

export const dispatchRolesList = (payload) => {
  configStore.dispatch({
    payload,
    type: RolesActionTypes.GET_ALL_ROLES,
  });
};

export const dispatchSelectedRoles = (payload) => {
  configStore.dispatch({
    payload,
    type: RolesActionTypes.CHANGE_SELECTED_ROLE,
  });
};
