import { configStore } from '../store';
import { TeamsActionTypes } from '../action-types';

export const dispatchAllTeamsTag = (payload) => {
  configStore.dispatch({
    payload,
    type: TeamsActionTypes.GET_ALL_TEAMS_TAG,
  });
};

export const dispatchSearchTeams = (payload) => {
  configStore.dispatch({
    payload,
    type: TeamsActionTypes.CHANGE_SEARCH_TEAMS,
  });
};

export const dispatchSelectedTeamsTagging = (payload) => {
  configStore.dispatch({
    payload,
    type: TeamsActionTypes.CHANGE_SELECTED_TEAMS_TAGGING,
  });
};
