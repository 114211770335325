import React from 'react';
import PropTypes from 'prop-types';
import AdminSidebar from './AdminSideBar';
import ModuleTable from './ModuleTable';

const ModuleManager = (props) => {
  let color = 'light';

  return (
    <>
      <AdminSidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <ModuleTable color="light" history={props.history} />
          </div>
        </div>
      </div>
    </>
  );
};
ModuleManager.defaultProps = {
  color: 'light',
};

ModuleManager.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};

export default ModuleManager;
