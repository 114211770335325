import { DynamicBannerActionTypes } from "../action-types";

const initialState = {
  selectedDynamicLocation: "Home",
  selectedDynamicType: "league",
};

const dynamicBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DynamicBannerActionTypes.GET_SELECTED_BANNER_LOCATION:
      return Object.assign({}, state, {
        selectedDynamicLocation: action.payload,
      });
    case DynamicBannerActionTypes.GET_SELECTED_BANNER_TYPE:
      return Object.assign({}, state, {
        selectedDynamicType: action.payload,
      });
    default:
      return state;
  }
};

export default dynamicBannerReducer;
