import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const KabaddiSpecialNews = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.HOME_SPECIAL_CONTENT}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const KabaddiSpecialVideos = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.HOME_SPECIAL_CONTENT}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const KabaddiSpecialUntagged = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.HOME_SPECIAL_UNTAGGED}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getKabaddiSpecial = async (body, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.HOME_SPECIAL.GET_HOME_SPECIAL}`,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
