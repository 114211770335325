import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { OLD_BASE_PATH } from '../../constants/workerConstants';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { tagTeams } from '../../handlers/news.handlers';
import AlertModal from '../AlertModal';
import { GetNewsInfo } from '../../handlers/news.handlers';
import LoginAppConstants from '../../constants/LoginAppConstants';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
export default function TeamSelectDropdown(props) {
  const classes = useStyles();
  const [alertModal, setAlertModal] = useState(false);
  const [teamName, setTeamName] = React.useState([]);
  const [docs, setDocs] = React.useState([]);
  const [teamData, setTeamData] = React.useState([]);
  // const [teamDocs, setTeamDocs] = useState([]);
  const isAllSelected = docs.length > 0 && teamName.length === docs.length;
  // const handleChange = (event) => {
  //   setTeamName(event.target.value);
  // };
  const handleChange = (event) => {
    setTeamName(event.target.value);
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setTeamName(teamName.length === docs.length ? [] : docs);
      return;
    }
  };
  useEffect(() => {
    Axios.get(`${OLD_BASE_PATH}teams/getAllTeams`, {
      headers: { 'cms-access-token': accessToken, source: 'cms' },
    })
      .then((res) => {
        setDocs(res.data.result.data);
        var array = [];
        res.data.result.data.map((item) => {
          let isItemFound = props.selected.some((val) => val._id === item._id);
          if (isItemFound) {
            array.push(item);
          }
        });
        setTeamName(array);
      })
      .catch((error) => error);
  }, []);

  const tagTeamToNews = (newsID) => {
    let teamIdData = [];
    teamName.map((item) => teamIdData.push(item._id));
    const data = { teamIds: teamIdData, _id: newsID };
    tagTeams(data)
      .then((response) => {
        if (response.status) {
          // setAlertModal(true);
          window.location.reload();
        }
      })
      .catch((error) => error);
  };
  // alert(teamName);
  return (
    <div>
      {alertModal && (
        <AlertModal
          title="Thanks"
          message="Team has been updated successfully !"
          onSuccess={() => setAlertModal(false)}
        />
      )}
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Tag Teams</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={teamName}
          onChange={(val) => handleChange(val)}
          input={<Input />}
          renderValue={(selected) => {
            return (
              selected &&
              selected
                .map((items) => {
                  return items && items.name;
                })
                .join(', ')
            );
          }}
          // MenuProps={MenuProps}
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : '',
            }}
          >
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                teamName.length > 0 && teamName.length < docs.length
              }
            />
            <ListItemText primary="Select All" />
          </MenuItem>
          {docs.map((name, index) => (
            <MenuItem key={index} value={name}>
              <Checkbox
                checked={
                  teamName &&
                  teamName.findIndex((x) => x && x._id === name._id) > -1
                }
                // onClick={() => selectedTeam(name._id)}
              />
              <ListItemText primary={name.name} />
            </MenuItem>
          ))}

          <Button
            variant="outlined"
            color="secondary"
            onClick={() => tagTeamToNews(props.newsID)}
            style={{
              position: 'relative',
              left: '25%',
              marginTop: '5px',
              width: '50%',
            }}
          >
            Tag
          </Button>
        </Select>
      </FormControl>
    </div>
  );
}
