import React from 'react'
import AdminSidebar from './AdminSideBar'
import PermissionTable from './PermissionTable'

const EditPermission = () => {
    return (
        <div>
            <AdminSidebar/>
            <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
             <PermissionTable color="light" />
             </div>
             </div>
             </div>
        </div>
    )
}

export default EditPermission
