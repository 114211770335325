import { ScheduleActionTypes } from "../action-types";

const initialState = {
  scheduleData: [],
  // schedules: {
  //   docs: [],
  //   total: 0,
  //   limit: 10,
  //   page: 0,
  //   pages: 0,
  // },
  // upcomingSchedules: {
  //   docs: [],
  //   total: 0,
  //   limit: 10,
  //   page: 0,
  //   pages: 0,
  // },
  // liveSchedules: {
  //   docs: [],
  //   total: 0,
  //   limit: 10,
  //   page: 0,
  //   pages: 0,
  // },
};
const schedulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ScheduleActionTypes.GET_ALL_SCHEDULES:
      return Object.assign({}, state, {
        ...action.payload,
        scheduleData: action.payload,
      });
    case ScheduleActionTypes.PUT_RESULT_SCHEDULES_PAGINATION:
      return Object.assign({}, state, {
        ...action.payload,
        scheduleData: [...state.scheduleData, ...action.payload],
      });

    // case ScheduleActionTypes.GET_ALL_UPCOMING:
    //   return Object.assign({}, state, {
    //     upcomingSchedules: {
    //       ...action.payload,
    //       docs: [...action.payload.data],
    //     },
    //   });

    // case ScheduleActionTypes.GET_ALL_LIVE:
    //   return Object.assign({}, state, {
    //     liveSchedules: {
    //       ...action.payload,
    //       docs: [...action.payload],
    //     },
    //   });
    default:
      return state;
  }
};
export default schedulesReducer;
