import { ceil, flatMap } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import {
  getAllRoles,
  addPermissionToModule,
  GetAddedRole,
} from '../../../handlers/admin.handlers';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const ModuleTable = (props) => {
  const history = useHistory();

  const [showAdd, showAddHandler] = useState(false);
  const [role, setRole] = useState('');
  const [docs, setdocs] = useState([]);
  const [data, setData] = useState();
  const [moduleDocs, setModule] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onClickAdd = () => {
    showAddHandler(true);
  };

  const handleClickEditRow = (rowIndex) => {
    if (docs[rowIndex].isEditing !== undefined) {
      setdocs((prev) =>
        prev.map((r, index) => ({
          ...r,
          isEditing: rowIndex === index,
        }))
      );
    } else {
      let data = [...docs];
      data[rowIndex].isEditing = false;

      setdocs(data);
    }
  };
  const headers = {
    Authorization: props.Authorization,
  };
  const getRoles = () => {
    getAllRoles(headers)
      .then((res) => {
        if (res.status == true) {
          setIsLoading(false);
          setdocs(res.data);
        }
      })
      .catch((error) => error);
  };
  useEffect(() => {
    setIsLoading(true);
    getRoles();
  }, [props.Authorization]);

  const saveRolesData = () => {
    // saveRolesData
    let array = [];
    docs.map((item, index) => {
      let payload = { rolename: item.name };
      item.module.map((modules, ind) => {
        payload[Object.keys(modules)[0]] = modules[Object.keys(modules)[0]];
      });
      array.push(payload);
    });

    //
    GetAddedRole({ roles: array }, headers).then((response) => {
      if (response.status) {
        alert(response.data);
      }
    });
    getRoles();
  };
  const addRole = () => {
    const body = {
      roles: [
        {
          rolename: role,
          optagraphics: false,
          news: false,
          videos: false,
          topnews: false,
          topvideos: false,
          addarticle: false,
          addvideo: false,
          homepage: false,
          teams: false,
          stvideos: false,
          managelive: false,
          manageleague: false,
          adminpanel: false,
          highlights: false,
          youtube: false,
          vimeo: false,
          version: false,
          manageAuthor: false,
          banners: false,
          featureCard: false,
          homepageNews: false,
          homepageVideos: false,
          homepageFeaturedCard: false,
        },
      ],
    };
    GetAddedRole(body, headers)
      .then((res) => {
        if (res.status == true) {
          showAddHandler(false);
          setRole('');
          getRoles();
          alert('Role has been added successfully');
        }
        getRoles();
      })
      .catch((error) => alert('error'));
  };
  //
  //
  // let array = docs;
  //
  // let module = () =>
  //   array.map((module) => {
  //     //
  //     setModule(module.module);
  //   });
  // useEffect(() => {
  //   module();
  // }, []);

  const isChecked = (event, arrayIndex, objectKey, objectIndex) => {
    let data = [...docs];
    data[arrayIndex].module[objectIndex][objectKey] =
      data[arrayIndex].module[objectIndex][objectKey] == 'true'
        ? 'false'
        : 'true';
    setdocs(data);
    setData(data[arrayIndex]);
  };

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1 py-4">
              <Link
                className=" pb-4"
                style={{
                  cursor: 'pointer',
                  marginLeft: '10px',
                  marginBottom: '-30px',
                  width: '30px',
                  fontSize: '15px',
                  display: 'flex',
                  flexDirection: 'row',
                  fontWeight: 'bold',
                  color: '#1e1e1e',
                }}
                to="/"
              >
                <ArrowBackIcon />
                Home
              </Link>

              <h3
                className={
                  'font-semibold text-lg ' +
                  (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '24px',
                }}
              >
                MODULE MANAGER
              </h3>
            </div>
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              color="success"
              onClick={saveRolesData}
            >
              {/*  onClickAdd saveRolesData */}
              Save Role
            </Button>
            <Button variant="contained" color="primary" onClick={onClickAdd}>
              {/*  onClickAdd saveRolesData */}
              Add Role
            </Button>
            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table className="table-fixed">
                <thead>
                  <tr>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      #
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Role
                    </th>
                    {/* <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Headline
                  </th> */}
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Opta Graphics
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      News
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Videos
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Top News
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Top Videos
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Add Article
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Add Video
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Home Page
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Teams
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      ST-Videos
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Manage-Live
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Manage-League
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Admin Panel
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Highlights
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Youtube
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Vimeo
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Version
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Manage Author
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Banners
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      FeatureCard
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      HomePage-News
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      HomePage-Videos
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      HomePage-FeatureCard
                    </th>
                    {/* <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      Action
                    </th> */}
                  </tr>
                </thead>
                <tbody id="myTable">
                  {/* map */}
                  {!isLoading ? (
                    getAllRoles
                  ) : (
                    <SyncLoader
                      color="#23A5E0"
                      loading={isLoading}
                      css={override}
                      size={12}
                    />
                  )}

                  {docs &&
                    docs.map((role, index) => (
                      <>
                        <tr>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            {index + 1}
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            {role.name}
                          </td>
                          {role.module &&
                            role.module.length > 0 &&
                            role.module.map((modules, i) => (
                              <td
                                key={index + '_' + i}
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                              >
                                <input
                                  type="checkbox"
                                  // disabled={
                                  //   role.isEditing !== undefined
                                  //     ? role.isEditing
                                  //     : true
                                  // }
                                  checked={
                                    modules[Object.keys(modules)[0]] == 'true'
                                      ? 'checked'
                                      : ''
                                  }
                                  onChange={(event) =>
                                    // setChecked(
                                    //   role.module && role.module[3].topnews
                                    // )
                                    isChecked(
                                      event,
                                      index,
                                      Object.keys(modules)[0],
                                      i
                                    )
                                  }
                                />
                              </td>
                            ))}
                          {/*                           
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module &&
                                role.module[1] &&
                                role.module[1].news == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module && role.module[2].videos == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={!checked}
                              onChange={(event) =>
                                // setChecked(
                                //   role.module && role.module[3].topnews
                                // )
                                isChecked(
                                  index,
                                  role.module,
                                  role.module[3],
                                  index
                                )
                              }
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module &&
                                role.module[4].topvideos == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module &&
                                role.module[5].addarticle == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module && role.module[6].addvideo == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module && role.module[7].homepage == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module && role.module[8].teams == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module && role.module[9].stvideos == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module &&
                                role.module[10].manageLive == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module &&
                                role.module[11].manageLeague == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td>
                          <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <input
                              type="checkbox"
                              checked={
                                role.module &&
                                role.module[12].adminPanel == "true"
                                  ? "checked"
                                  : ""
                              }
                              onChange={isChecked}
                            />
                          </td> */}

                          {/* <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                      <input type="checkbox" />
                    </td> */}
                          {/* <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                            <div className="league__field actions">
                              <div onClick={() => handleClickEditRow(index)}>
                                <i className="far fa-edit"></i>
                              </div>
                              <div onClick={() => saveRolesData()}>
                                <i className="far fa-save"></i>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>

              {/* <div className="edit__popup" style={{ marginTop: '-200px' }}>
                <div
                  onClick={() => showAddHandler(false)}
                  className={
                    showAdd ? 'edit__backdrop active' : 'edit__backdrop'
                  }
                ></div>

                {/* <div
                  className={
                    showAdd ? 'edit__popup-body active' : 'edit__popup-body'
                  }
                  style={{ width: '45%' }}
                >
                  Admin Panel
                  <tbody id="myTable">
                    <tr>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        1
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        Administrator
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <input type="checkbox" />
                      </td>
                    </tr>
                  </tbody>
                  <div className="edit__popup" style={{ marginTop: '-200px' }}>
                    <div
                      onClick={() => showAddHandler(false)}
                      className={
                        showAdd ? 'edit__backdrop active' : 'edit__backdrop'
                      }
                    ></div>

                    {/*model popup 
                  </div>
                </div> 
                
              </div> */}

              {/* <div onClick={onClickAdd} style={{ cursor: 'pointer' }}>
                <span>
                  <i class="fas fa-plus 7x"></i>
                </span>
              </div> */}
            </div>
            <div className="edit__popup">
              <div
                onClick={() => showAddHandler(false)}
                className={showAdd ? 'edit__backdrop active' : 'edit__backdrop'}
              ></div>
              <div
                className={
                  showAdd ? 'edit__popup-body active' : 'edit__popup-body'
                }
                style={{ width: '40%' }}
              >
                <div className="edit__popup-inner">
                  <i
                    onClick={() => {
                      showAddHandler(false);
                    }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                  <div>
                    Role:{' '}
                    <input
                      type="text"
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                      placeholder="Add Role"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                      style={{
                        width: '80%',
                        height: '33px',
                        margin: '1.2%',
                        marginRight: '4%',
                        marginTop: '5%',
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addRole}
                      style={{ marginBottom: '1.5%' }}
                    >
                      Saves
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
  };
};

export default connect(mapStateToProps)(ModuleTable);
