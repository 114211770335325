import React, { useEffect, useState,useRef } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import CropperModal from "../../../../components/CropperModal";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import {
  GetVideosInfo,
  mapVideosToLeague,
  mapVideosToSports,
  ChangeVideoStatus,
  uploadVideoThumbnail,
  tagHome,
  tagVideoToTop,
  removeTeams,
  tagHighlight,
  mapVideosToMatches,
  mapVideosToTeams,
  tagTwitterTrends, // Tag Twitter Trends
  generateClickbaitHeadline // Clickbait Title
} from "../../../../handlers/videos.handlers";
import { GetTaggedLocation } from "../../../../handlers/twitter.handlers"; // Trending Twitter Location
import HomeSpecialDropdown from "../../../../components/Dropdowns/HomeSpecialDropdown";
import {
  sendVideoNotification,
  sendVideoNotificationSpecificUsers,
} from "../../../../handlers/team.handlers";
import { KabaddiSpecialVideos } from "../../../../handlers/Kabaddi.handlers";
import {
  getAllSports,
  getAllSportsTag,
} from "../../../../handlers/sports.handler";
import LeaguesDropDown from "../../../../components/Dropdowns/LeaguesDropDown";

import SportsDropdown from "../../../../components/Dropdowns/SportsDropDown";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeague,
  dispatchSelectedLeagueTagging,
} from "../../../../redux/action-dispatchers/leagues.dispatchers";
import {
  dispatchAllSports,
  dispatchAllTagSports,
  dispatchSelectedSports,
  dispatchSelectedSportsTagging,
} from "../../../../redux/action-dispatchers/sports.dispatchers";
import Axios from "axios";
import { OLD_BASE_PATH } from "../../../../constants/workerConstants";
import PreviewModal from "../../../../components/PreviewModal";
import PulseLoader from "react-spinners/PulseLoader";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { css } from "@emotion/core";
import {
  _getDateTime,
  _dateTimeFormat,
  _dateTimeFormatinIST,
} from "../../../../constants/appDefaults";
import AlertModal from "../../../../components/AlertModal";
import TeamSelectVideos from "../../../../components/Dropdowns/TeamSelectVideos";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AddOrRemoveHomeSpecial } from "../../../../handlers/homeSpecial.handlers";
import ReactQuill from "react-quill";
import AllLeagueDropDown from "../../../../components/Dropdowns/AllLeagueDropDown";
import LoginAppConstants from "../../../../constants/LoginAppConstants";
import SportsTaggingDropdown from "../../../../components/Dropdowns/SportsTaggingDropdown";
import LeagueDropdownTagging from "../../../../components/Dropdowns/LeagueDropdownTagging";
import { GetAllTagLeagues } from "../../../../handlers/leagues.handlers";
import {
  dispatchAllMatchesTag,
  dispatchNumberOfMatchesPages,
  dispatchSelectedMatchesTagging,
} from "../../../../redux/action-dispatchers/matches.dispatcher";
import MatchesDropdownTagging from "../../../../components/Dropdowns/MatchesDropdownTagging";
import { GetMatchesForTagging } from "../../../../handlers/matches.handlers";
import { dispatchSelectedTeamsTagging } from "../../../../redux/action-dispatchers/teamsTagging.dispatcher";
import TeamsDropdownForTagging from "../../../../components/Dropdowns/TeamsDropdownForTagging";
import { GetTeamsForTagging } from "../../../../handlers/teamsOrPlayer";
import TeamsDropdownForTaggingVideos from "../../../../components/Dropdowns/TeamsDropdownForTaggingVideos";
{/**Modal Css */}
import TrendsAllLocations from "../../../../components/Cards/TrendsAllLocations";
import Fab from "@mui/material/Fab";
import {  Tooltip, Overlay } from "react-bootstrap";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const VideosInfo = (props) => {
  const [copyText, setCopyText] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [teamDocs, setTeamDocs] = useState([]);
  const [notificationDisable, setNotificationDisable] = useState(false);
  const [segmentData, setSegmentData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [isSpecificUserDisabled, setIsSpecificUserDisabled] = useState(false);
  const [VideosInfo, setVideosInfo] = useState(undefined);
  const [showCropper, setShowCropper] = useState(false);
  const [taggedLeague, setTaggedLeague] = useState(false);
  // const [taggedAllLeague, setTaggedAllLeague] = useState(false);
  const [changeLeague, setChangeLeague] = useState(false);
  const [changeTeams, setChangeTeams] = useState(false);
  const [changeAllLeague, setChangeAllLeague] = useState(false);
  const [changeMatches, setChangeMatches] = useState(false);
  const [taggedSports, setTaggedSports] = useState(false);
  const [taggedMatches, setTaggedMatches] = useState(false);
  const [taggedTeams, setTaggedTeams] = useState([]);
  const [changeSports, setChangeSports] = useState(false);
  const [homeSpecialType, setHomeSpecialType] = useState("");
  const [taggedAllLeague, setTaggedAllLeague] = useState(false);
  // const [changeAllLeague, setChangeAllLeague] = useState(false);
  const [topVidepChange, setTopVideoChange] = useState(false);
  const [topVideosChange, setTopVideosChange] = useState(false);
  const [topVideosType, setTopVideosType] = useState("");
  const [homeVideoChange, setHomeVideoChange] = useState(false);
  const [homeSpecialVideoChange, setHomeSpecialVideoChange] = useState(false);
  const [hightlightVideoChange, setHightlightVideoChange] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [topVideosChecked, setTopVideosChecked] = useState(false);
  const [homeVideosChecked, setHomeVideosChecked] = useState(false);
  const [homeSpecialVideosChecked, setHomeSpecialVideosChecked] =
    useState(false);
  const [highlightChecked, setHighlightChecked] = useState(false);
  const [homeVideoType, setHomeVideoType] = useState();
  const [homeExpiryDate, setHomeExpiryDate] = useState(null);
  const [highlightExpiryDate, setHightlightExpiryDate] = useState(null);
  const [dateTime, setDateTime] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [showMedia, setShowMedia] = useState(null);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [headline, setHeadline] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keyword, setKeyword] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoSlug, setSeoSlug] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [editDescription, setEditDescription] = useState(false);
  const [editKeyword, setEditKeyword] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [editShortTitle, setShortEditTitle] = useState(false);
  const [editSEOTitle, setEditSEOTitle] = useState(false);
  const [editSEOSlug, setEditSEOSlug] = useState(false);
  const [editSEODescription, setEditSEODescription] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [open, setOpen] = useState(false); // for Modal Open State
  const [twitterTaggedLocations, setTwitterTaggedLocations] = useState([]) // All trending places tagged from Trends api
  const [tagTrends, setTagTrends] = useState(); // Tag Trends
  // const [isHomeTagged, setIsHomeTagged] = useState();
  const [titleDisabled, setTitleDisabled] = useState(3) // For ChatGpt Attempts
  // const [clickbaitTitle, setClickbaitTitle] = useState('')  // Fot ChatGpt Title

  const [descriptionLength, setDescriptionLength] = useState(0); 
  const [seoDescriptionLength, setSeoDescriptionLength] = useState(0); 
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: "",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });
  //if SEO Slug is Invalid characters
  const [invalidSeoSlug, setInvalidSeoSlug] = useState(false);
  const { id } = useParams();
  const { isyoutube } = useParams();

  const [selectedImage, setSelectedImage] = useState();
  const [titleInput, setTitleInput] = useState("");
  let fileInput = React.useRef();
  const history = useHistory();
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  {/**Modal open and close for Trends*/}
  const handleOpen = () => {
    setOpen(true);
  };
  const changeTopVideosType = async (event) => {
    if (event.target.checked) {
      setDateTime(true);

      updateTopVideoTime("TOP_VIDEO"); //new line
      setTopVideosType("TOP_VIDEO");
    } else {
      updateTopVideoTime("ALL");
      setTopVideosType("ALL");
    }
  };
  const updateTopVideoTime = (value, time) => {
    // debugger;
    if (VideosInfo.status) {
      const data = {
        _id: VideosInfo._id,
        section: value,
        // topVideoExpiry: time,
      };
      tagVideoToTop(data)
        .then((response) => {
          if (response.status) {
            if (response.data.statusCode && response.data.statusCode == 522) {
              alert(response.message);
              setTopVideosType("ALL");
            } else {
              setDateTime(false);
              setTopVideoChange(false);
              setAlertModal(true);
              getVideosInformation();
            }
          } else {
            alert("Sorry, Video is not tagged to top.");
            setTopVideosType("ALL");
          }
        })
        .catch((error) => {});
    } else {
      alert(
        "The video is not published. Please publish the video first, Thank You."
      );
    }
  };
  // home expiry
  const selectHomeVideos = async (event) => {
    // setHomeVideosChecked(event.target.checked);

    let body = { _id: id, tagHome: false };
    if (event.target.checked) {
      setDateTime(true);
      body.tagHome = true;
    } else {
      body.tagHome = false;
    }
    // tagHome(body);
    setHomeVideosChecked(event.target.checked);
    updateHomeVideoTime(body);
  };
  // Tagged Locations from twitter Trending part
  useEffect(()=> {
    const body = 'section=twitter'
    if (open){
    GetTaggedLocation(body)
    .then((response)=>{
      setTwitterTaggedLocations(response?.data)
    })
    .catch((error)=> error)
  } 
  }, [open])
  const updateHomeVideoTime = (data) => {
    if (VideosInfo.status == 1) {
      // const data = {
      //   _id: VideosInfo._id,
      //   tagHome: homeVideosChecked,
      //   // homeTagExpiry: time,
      // };
      tagHome(data)
        .then((response) => {
          if (response.status) {
            if (response.data.statusCode && response.data.statusCode == 522) {
              // homeNewsChecked

              alert(response.message);
              setHomeVideosChecked(false);
            } else {
              setDateTime(false);
              setHomeVideoChange(false);
              setAlertModal(true);
              getVideosInformation();
            }
          } else {
            alert("Sorry, Video not tagged");
            const body = { _id: id, tagHome: false };
            // tagHome(body);
          }
        })
        .catch((error) => {});
    } else {
      alert(
        "The video is not published. Please publish the video first, Thank You."
      );
    }
  };
  // home expiry
  // special home start
  const selectHomeSpecialVideos = async (event) => {
    // setHomeVideosChecked(event.target.checked);

    // let body = { _id: id, tagHome: false };
    // if (event.target.checked) {
    //   setDateTime(true);
    //   body.tagHome = true;
    // } else {
    //   body.tagHome = false;
    // }
    // tagHome(body);
    const body = {
      mediaType: "video",
      video: VideosInfo._id,
      // ranking: '1',
      isTagg: event.target.checked,
    };
    setHomeSpecialVideosChecked(event.target.checked);
    updateHomeSpecialVideoTime(body);
  };
  const updateHomeSpecialVideoTime = (data) => {
    if (VideosInfo.status) {
      // const data = {
      //   _id: VideosInfo._id,
      //   tagHome: homeVideosChecked,
      //   // homeTagExpiry: time,
      // };
      KabaddiSpecialVideos(data)
        .then((response) => {
          if (response.status) {
            setDateTime(false);
            setHomeSpecialVideoChange(false);
            setAlertModal(true);
            getVideosInformation();
          } else {
            alert("Sorry, Video not tagged to highlights.");
            const body = { _id: id, tagHome: false };
            // tagHome(body);
          }
        })
        .catch((error) => {});
    } else {
      alert(
        "The video is not published. Please publish the video first, Thank You."
      );
    }
  };
  // special home end
  // hightlight
  const selectHighlightVideos = (event) => {
    // setHighlightChecked(event.target.checked);
    let body = {
      _id: VideosInfo._id,
      tagHighlight: false,
    };
    if (event.target.checked) {
      setDateTime(true);
      body.tagHighlight = true;
    } else {
      // const body = {
      //   _id: VideosInfo._id,
      //   tagHighlight: false,
      // };
      body.tagHighlight = false;
    }
    // tagHighlight(body);

    setHighlightChecked(event.target.checked);
    updateHighlightTime(body);
  };
  // const updateHighlightTime = (time) => {
  const updateHighlightTime = (body) => {
    if (VideosInfo.status) {
      // const body = {
      //   _id: VideosInfo._id,
      //   tagHighlight: highlightChecked,
      //   // highlightVideoExpiry: time,
      // };
      tagHighlight(body)
        .then((response) => {
          if (response?.status) {
            setDateTime(false);
            setHightlightVideoChange(false);
            setAlertModal(true);
            getVideosInformation();
          } else {
            alert("Sorry, Video not tagged to home.");
            const body = {
              _id: VideosInfo._id,
              tagHighlight: false,
            };
            tagHighlight(body);
          }
        })
        .catch((error) => {});
    } else {
      alert(
        "The video is not published. Please publish the video first, Thank You."
      );
    }
  };

  //See if Trend is Tagger or Not

  const TagNewsToTrends = (trends) =>{
    // setTagTrends(!tagTrends)
    // console.log('asdddddd', newsInfo)
    setTagTrends(trends)
    const payload = {
      "contentId":VideosInfo?.contentId,
      "isTrending": trends,
    }
    tagTwitterTrends(payload)
    .then((response)=>{
      console.log('asdd', response)
    })
    .catch((error)=>error)
  }


  // highlights
  useEffect(() => {
    // GetAllDefaultLeagues({
    //   Authorization: props.Authorization,
    // }).then((response) => {
    //   if (response.status) {
    //     dispatchAllLeagues(response.data);
    //   }
    // });

    getAllSportsTag({ Authorization: props.Authorization })
      .then((response) => {
        if (response.status) {
          dispatchAllTagSports(response.data);
        } else {
          dispatchAllTagSports([]);
        }
      })
      .catch((error) => {
        dispatchAllTagSports([]);
      });

    getVideosInformation();
    //onSportSelected();  //Sport
  }, []);
  useEffect(() => {
    getVideosInformation();
  }, [props.sports, taggedSports, taggedLeague, props.leaguesTag]);
  const getAllTaggedMatches = () => {
    GetMatchesForTagging(
      props.selectedLeagueTag,
      props.selectedSportsTagging,
      props.matchesSearch == "" ? "search" : props.matchesSearch,
      props.matchesPage,
      20
    )
      .then((response) => {
        if (response.status) {
          dispatchNumberOfMatchesPages(response.data.totalPages);
          dispatchAllMatchesTag(response.data.docs);
        } else {
          dispatchAllMatchesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllMatchesTag([]);
      });
  };
  useEffect(() => {
    getAllTaggedMatches();
  }, [props.matchesSearch, taggedLeague, props.matchesPage]);
  useEffect(() => {
    if (VideosInfo?.league) {
      const leagueInfo = props.leaguesTag.find(
        (item) => item.comp_id === VideosInfo.league
      );
      if (leagueInfo) {
        setTaggedLeague(leagueInfo.comp_name);
      }
      // setTaggedLeague(leagueInfo?.comp_name);
    }

    if (VideosInfo && VideosInfo.AllLeague) {
      setTaggedAllLeague(VideosInfo.AllLeague);
    }

    //
    if (VideosInfo?.matchId) {
      const matchesInfo = props.matchesTag?.find(
        (item) => item.match_id == VideosInfo.matchId
      );

      if (matchesInfo) {
        setTaggedMatches(matchesInfo?.title);
      }
    }
    if (VideosInfo && VideosInfo.sports && VideosInfo.sports._id) {
      const sportsInfo = props.sports?.find(
        (item) => item._id === VideosInfo.sports._id
      );
      if (sportsInfo) {
        setTaggedSports(sportsInfo.name);
        dispatchSelectedSportsTagging({
          selectSports: sportsInfo?._id ? sportsInfo._id : "All",
          selectedSportsTagging: sportsInfo?._id ? sportsInfo._id : "All",
        });
      }
    }
    if (VideosInfo && VideosInfo.status == 0) {
      setHomeSpecialType("All");
      const body = {
        mediaType: "video",
        section: "ALL",
        video: VideosInfo._id,
        copyright: VideosInfo.copyright,
      };
      AddOrRemoveHomeSpecial(body, {
        Authorization: props.Authorization,
      })
        .then((response) => {})
        .catch((error) => error);
    }
  }, [
    VideosInfo,
    homeSpecialType,
    props.leaguesTag,
    props.sports,
    props.matchesTag,
  ]);

  //league api Start
  const getAllTaggedLeague = () => {
    // console.log('props.selectedSportsTagging', props.selectedSportsTagging);
    GetAllTagLeagues(
      props.selectedSportsTagging,
      props.leagueSearch == "" ? "search" : props.leagueSearch
    )
      .then((response) => {
        if (response.status) {
          dispatchAllLeaguesTag(response.data);
        } else {
          dispatchAllLeaguesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllLeaguesTag([]);
      });
  };
  useEffect(() => {
    getAllTaggedLeague();
  }, [props.leagueSearch, taggedSports]);
  //league api End

  const getVideosInformation = () => {
    GetVideosInfo(id, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        let taggedTeamName = [];
        setTeamDocs(response?.data?.teams);
        setVideosInfo(response?.data);
        setTopVideosType(response?.data?.section);
        setTitleInput(response?.data?.notification?.title);
        setExpiryDate(response?.data?.topVideoExpiry);
        setHomeExpiryDate(response?.data?.homeTagExpiry);
        setHightlightExpiryDate(response?.data?.highlightVideoExpiry);
        setHomeVideosChecked(response?.data?.tagHome);
        setHighlightChecked(response?.data?.tagHighlight);
        setHomeSpecialVideosChecked(response?.data?.isTaggedSpecial);
        setShowMedia(response?.data?.media?.content[0]?.url);
        setMediaThumbnail(response?.data?.media?.thumbnail[0]?.url);
        setTagTrends(response?.data?.isTrending) // update isTrending
        setTitleInput(response?.data?.openAISuggestions?.clickbait_headline?.text)// GPT title from api initially
        setTitleDisabled(response?.data?.openAISuggestions?.clickbait_headline?.apiAttemptsLeft) // GPT attempts left
        setHeadline(response?.data?.title);
        setShortTitle(response?.data?.shortTitle);
        setDescription(response?.data?.description);
        setKeyword(
          response.data.slug
            ? response.data.slug !== ""
              ? response.data.slug
              : response.data.keyword
            : response.data.keyword
        );
        setSeoTitle(response.data.seo_title);
        setSeoSlug(response.data.seo_slug);
        setSeoDescription(response.data.seo_description);
        setHomeSpecialType(response.data.taggedHomeSpecial);
        dispatchSelectedLeagueTagging({
          selectedLeagueTag: response.data?.league,
          selectedLeagueFilterTag: response.data?.league,
        });
        if (response.data.teams) {
          response?.data?.teams?.map((team) => {
            taggedTeamName.push(team.name);
          });
          setTaggedTeams(taggedTeamName);
        }
      }
    });
  };

  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setVideosInfo({
      ...VideosInfo,
      imgUrl: imagePath,
    });
    uploadVideoThumbnail(VideosInfo._id, imageData, {
      Authorization: props.Authorization,
    })
      .then((response) => {})
      .catch((error) => {});

    setShowCropper(false);
  };
  // get team data
  const getAllTeamData = () => {
    Axios.get(`${OLD_BASE_PATH}teams/getAllTeams`, {
      headers: { "cms-access-token": accessToken, source: "cms" },
    })
      .then((res) => {
        setSegmentData(...segmentData, res.data.result.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAllTeamData();
  }, []);
  // Edit video
  // const OnClickEditVideo = (VideosInfo) => {
  //   setHeadline(VideosInfo.title);
  //   setStory(VideosInfo.description);
  //   showEditHandler(true);
  // };

  // Headline Update
  const updateTitle = () => {
    const body = {
      _id: VideosInfo._id,
      title: headline,
    }; if (headline == undefined || body?.title?.length == 0) {
      alert("Headline can not be empty. Please enter Headline.");
      return;
    } 
    tagVideoToTop(body)
      .then((response) => {
        if (response.status) {
          getVideosInformation();
          setAlertModal(true);
          setEditTitle(false);
        } else {
          alert("Sorry your video is not updated");
          setEditTitle(false);
        }
      })
      .catch((error) => error);
  };
  const updateShortTitle = () => {
    const body = {
      _id: VideosInfo._id,
      shortTitle: shortTitle,
    }; if ((shortTitle?.length > 0 || body.shortTitle?.length > 0) && (body.shortTitle.length < 100 || shortTitle.length < 100)) {
      alert("shortTitle can not be less than 100.");
      return;
    }  else if ((shortTitle?.length > 160) || (body.shortTitle?.length > 160)) {
      alert("shortTitle can not be more than 160.");
      return;
    }
    tagVideoToTop(body)
      .then((response) => {
        if (response.status) {
          getVideosInformation();
          setAlertModal(true);
          setShortEditTitle(false);
        } else {
          alert("Sorry your video is not updated");
          setShortEditTitle(false);
        }
      })
      .catch((error) => error);
  };
  const updateDescription = () => {
    const body = {
      _id: VideosInfo._id,

      description: description,
    }; 
    if (description == undefined || body?.description?.length == 0 || description == "" || body?.description == "<p><br></p>"|| body?.description == "<p></p>" || description == "<p><br></p>") {
      alert("Description can not be empty. Please enter Description");
      return;
    } 
    tagVideoToTop(body)
      .then((response) => {
        if (response.status) {
          getVideosInformation();
          setAlertModal(true);
          setEditDescription(false);
        } else {
          alert("Sorry your video is not updated");
          showEditHandler(false);
        }
      })
      .catch((error) => error);
    
  };
  const updateKeyword = () => {
    const body = {
      _id: VideosInfo._id,
      slug: keyword,
      keyword: keyword,
    };
    if (keyword == undefined || body?.keyword?.length == 0) {
      alert("Keyword can not be empty. Please enter Keyword.");
      return;
    }
    tagVideoToTop(body)
      .then((response) => {
        if (response.status) {
          getVideosInformation();
          setAlertModal(true);
          setEditKeyword(false);
        } else {
          alert("Sorry keywords is not updated");
        }
      })
      .catch((error) => error);
  };
  const updateSeoTitle = () => {
    const body = {
      _id: VideosInfo._id,
      seo_title: seoTitle,
    };
    if (seoTitle == undefined || body?.seo_title?.length == 0) {
     alert("SEO Title can not be empty. Please enter SEO Title.");
      return;
    } if (body?.seo_title?.length < 50) {
      alert("Please update your SEO Title to minimum 50 Characters.");
      return;
    }
    if (body?.seo_title?.length > 140) {
      alert("Please update your SEO Title to maximum 140 Characters.");
      return;
    } else {
      tagVideoToTop(body)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
            setEditSEOTitle(false);
          } else {
            alert("Sorry your content is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  const updateSeoSlug = () => {
    const body = {
      _id: VideosInfo._id,
      seo_slug: seoSlug,
      selfSeoSlugCreated:true,
      translatedTitleExists:true
    };

    if (seoSlug == undefined || body?.seo_slug?.length == 0) {
      alert("SEO Slug can not be empty. Please enter SEO Slug.");
      return;
    } 
    if (body?.seo_slug?.length < 50) {
      alert("Please update your SEO Slug to minimum 50 Characters.");
      return;
    }
    if (body?.seo_slug?.length >140) {
      alert("Please update your SEO Slug to maximum 140 Characters.");
      return;
    }  
    if(/^[A-Za-z0-9-\s]*$/.test(seoSlug) && seoSlug.charAt(0)!='-')
      {
        tagVideoToTop(body)
          .then((response) => {
            if (response.status) {
              getVideosInformation();
              setAlertModal(true);
              setEditSEOSlug(false);
            } else {
              alert("Sorry your content is not updated");
            }
          })
          .catch((error) => error);
      }
      else
      {
        setInvalidSeoSlug(true);
      alert("SEO Slug is INVALID. It must conatin only letters between a-z, A-Z, 0-9 or -.");
      return;
      }
    };
  const updateSeoDescription = () => {
    const body = {
      _id: VideosInfo._id,
      seo_description: seoDescription,
    };

    if (seoDescription == undefined || body?.seo_description?.length == 0) {
      alert("SEO Description can not be empty. Please enter SEO description.");
      return;
    } if (body?.seo_description?.length < 187) {
      alert("Please update your SEO Description to minimum 180 Characters.");
      return;
    }
    if (body?.seo_description?.length > 227) {
      alert("Please update your SEO Description to maximum 220 Characters.");
      return;
    } else {
      tagVideoToTop(body)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
            setEditSEODescription(false);
          } else {
            alert("Sorry your content is not updated");
          }
        })
        .catch((error) => error);
    }
  };

  {/**Chat GPT Clickbait Title */}
  const clickbaitHeadline = (headline) =>{
    const body = {
      "title": headline?.title,
      "contentId":headline?.contentId, 
      "type": "video"
    }
    generateClickbaitHeadline(body)
    .then((res)=>{
      setTitleInput(res?.data?.openAISuggestions?.clickbait_headline?.text)// GPT title
      setTitleDisabled(res?.data?.openAISuggestions?.clickbait_headline?.apiAttemptsLeft) // GPT attempts left
    })
    .catch((error)=>error)
  }

  //send notificaion tagged teams
  const sendNotification = () => {
    if (titleInput?.trim() != "" && (taggedAllLeague || taggedLeague) != "") {
      var array = "";
      const lang = localStorage.getItem("Current_Language");
      // segmentData.map((item) => {
      //   let isItemFound = teamDocs.some((val) => val._id === item._id);
      //   if (
      //     isItemFound &&
      //     item.segments.length > 0 &&
      //     item.segments[0].event == 'articles'
      //   ) {
      //     // array.push(item);
      //     array += item.segments[0].segmentId + ', ';
      //   }
      // });
      // setTeamName(array);

      const body = {
        title: titleInput,
        videoId: VideosInfo._id,
        lang: lang === "English" ? "english" : "hindi",
        thumbnail: VideosInfo.imgUrl,
        headline: VideosInfo.title,
        league: taggedLeague || taggedAllLeague,
        // segments: array.replace(/,\s*$/, ''),
        teams: VideosInfo?.teams,
        // comp_name: VideosInfo.comp_name,
      };

      sendVideoNotification(body)
        .then((response) => {
          if (response.status) {
            setDisable(true);
            alert("Notification sent successfully.");
          } else {
            alert("Please try again.");
          }
        })
        .catch((error) => error);
    } else {
      alert(
        "Please give title of the notification and also select the league from league dropdown"
      );
    }
  };
  const sendUserSpecificNotification = (title) => {
    if (title?.trim() != ""  && (taggedAllLeague || taggedLeague) != "") {
      setNotificationDisable(true);
      const lang = localStorage.getItem("Current_Language");
      const body = {
        headline: VideosInfo.title,
        title,
        videoId: VideosInfo._id,
        // articleId: VideosInfo._id,
        lang: lang === "English" ? "english" : "hindi",
        sportsId: VideosInfo.sports && VideosInfo.sports._id,
        comp_id: VideosInfo.league,
      };
      if((!title||title=="") ){
        alert("Enter another notification title");
        setNotificationDisable(false);
        return;
      }
      sendVideoNotificationSpecificUsers(body)
        .then((response) => {
          if (response.status) {
            alert(response.message);
            setIsSpecificUserDisabled(true);
          } else {
            alert("Please try again.");
          }
          setNotificationDisable(false);
        })
        .catch((error) => error);
    } else {
      alert(
        "Please give title of the notification and also select the league from league dropdown"
      );
    }
  };
  useEffect(() => {
    dispatchSelectedSportsTagging({
      selectSports: "",
      selectedSportsTagging: "",
    });
  }, []);
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
          });
        } else {
          alert("Image width and height must be 1920x1080 px");
          return false;
        }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) {};
  };

  const onClose = () => {
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };

  const onSuccess = () => {
    var publishedStatus = VideosInfo.status;
    publishedStatus === 1 ? (publishedStatus = 0) : (publishedStatus = 1);
    if (props.selectedSportsTagging == "") {
      alert("Please Select Sports before publishing...");
      setConfirmationModal({
        message: "",
        show: false,
        title: "",
      });
    } else {
      ChangeVideoStatus(VideosInfo._id, publishedStatus, {
        Authorization: props.Authorization,
      }).then((response) => {
        if (response.status) {
          getVideosInformation();
        }
      });
      setConfirmationModal({
        message: "",
        show: false,
        title: "",
      });
    }
  };

  const showModalForPublish = () => {
    var title = "Publish/Unpublish Video";
    var message = "Are you sure to continue?";

    if(/^[A-Za-z0-9-\s]*$/.test(seoSlug) && seoSlug.charAt(0)!='-' || VideosInfo?.status === 1)
    {
      setConfirmationModal({
        message: message,
        show: true,
        title: title,
      });
    }
    else{
      setInvalidSeoSlug(true);
        alert("SEO Slug is INVALID. It must conatin only letters between a-z, A-Z, 0-9 or -.");
    }
  };
  // home expiry

  // Top videos home videos

  const onLeagueSelected = (value, uuid) => {
    setChangeLeague(false);
    VideosInfo &&
      value !== 0 &&
      mapVideosToLeague(VideosInfo._id, value, uuid)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };
  // All league selection dropdown
  const onAllLeagueSelected = (value, uuid) => {
    setChangeAllLeague(false);
    VideosInfo &&
      value !== 0 &&
      mapVideosToLeague(VideosInfo._id, value, uuid)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };
  // Sport Start
  const onSportsSelected = (value) => {
    setChangeSports(false);
    VideosInfo &&
      value !== 0 &&
      mapVideosToSports(VideosInfo._id, value, "")
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };

  // Sport End
  // Matches select logic
  const onMatchesSelected = (value) => {
    setChangeMatches(false);
    VideosInfo &&
      value !== 0 &&
      mapVideosToMatches(value, VideosInfo._id)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };
  // Matches select logic end
  // Teams select logic
  const onTeamsSelected = (value, uuid) => {
    setChangeTeams(false);
    VideosInfo &&
      value !== 0 &&
      mapVideosToTeams(VideosInfo._id, value, uuid)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };
  // teams select logic end
  const onPreviewClose = () => setPreviewModal({ imageData: "", show: false });
  const onPreviewSuccess = () => {
    let videoId = VideosInfo._id;
    let thumbnailId = VideosInfo.media.thumbnail[0]._id;

    setIsImageUploading(true);
    setVideosInfo({
      ...VideosInfo,
      imgUrl: previewModal.imageData,
    });
    setPreviewModal({
      imageData: "",
      show: false,
    });
    uploadVideoThumbnail(videoId, thumbnailId, previewModal.imageData, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setIsImageUploading(false);
      })
      .catch((error) => {
        setIsImageUploading(false);
      });
  };
  const handleBack = () => {
    history.goBack();
  };

  const onHomeSpecialTypeChange = (event) => {
    if (VideosInfo.status == 1) {
      setHomeSpecialType(event.target.value);
      const body = {
        mediaType: "video",
        section: event.target.value,
        video: VideosInfo._id,
        copyright: VideosInfo.copyright,
      };
      AddOrRemoveHomeSpecial(body, {
        Authorization: props.Authorization,
      })
        .then((response) => {
          if (response.status === true) {
            alert("Tagged successfully");
          } else {
            alert("Oops something went wrong.. Please try again later.");
            setHomeSpecialType("All");
          }
        })
        .catch((error) => error);
    } else {
      alert("Please publish the video first");
    }
  };
  function CopyButton({ row }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleClick = async () => {
      if (row?.permalink) {
        if ("clipboard" in navigator) {
          await navigator.clipboard.writeText(row.permalink);
          setShow(true);
          setTimeout(() => setShow(false), 500); // Hide the tooltip after 2 seconds
        }
      }
    };

    return (
      <>
        <Button
          ref={target}
          onClick={handleClick}
          disabled={!row?.permalink}
          title="Click to copy permalink"
          style={{ outline: "none" }}
          className={`btn btn-icon btn-light btn-sm mx-3`}
        >
          <img
            src="/copy-1.png"
            alt="copy to clipboard"
            style={{ width: "25px", height: "25px", marginLeft: "10px" }}
          />
        </Button>

        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Copied!
            </Tooltip>
          )}
        </Overlay>
      </>
    );
  }
  return (
    <>
      <Sidebar />
      {/* <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
        >
          <div className="edit__popup-inner">
            <i
              onClick={() => showEditHandler(false)}
              className="far fa-times-circle edit__popup-close"
            ></i>

            <div className="edit__popup--form">
              <div className="edit__popup-row">
                <span>Title:</span>

                <div className="relative w-full ">
                  <input
                    type="text"
                    name="text"
                    value={headline}
                    onChange={(e) => setHeadline(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="edit__popup-row">
                <span>Description:</span>

                <div className="relative w-full ">
                  <input
                    type="text"
                    name="text"
                    value={story}
                    onChange={(e) => setStory(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder=""
                  />
                </div>
              </div>

              <div className={'edit__popup-row button-row'}>
                <div onClick={() => updateVideo()} className={'save btn'}>
                  Update
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="relative md:ml-64" style={{ padding: 50,display:open?'none':'block' }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {/* <div>Video Page Render</div> */}
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            {showCropper && VideosInfo && (
              <CropperModal
                image={selectedImage}
                onClose={onCropperClose}
                closeCropperAndSetData={closeCropperAndSetData}
              />
            )}
            {previewModal.show && (
              <PreviewModal
                imageData={previewModal.imageData}
                title="Upload Video"
                message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                onSuccess={onPreviewSuccess}
                onClose={onPreviewClose}
              />
            )}
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your video has been updated successfully !"
                onSuccess={() => setAlertModal(false)}
              />
            )}
            {VideosInfo && (
              <div>
                <div>
                  <div
                    className=" pb-4"
                    style={{
                      cursor: "pointer",
                      marginLeft: "-20px",
                      width: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIcon />
                  </div>
                  <div
                    style={{ display: "inline-block", verticalAlign: "top" }}
                  >
                    <ReactPlayer
                      controls
                      url={showMedia}
                      light={mediaThumbnail}
                    />
                    {/* <img
                      src={
                        VideosInfo.media && VideosInfo.imgUrl !== undefined
                          ? VideosInfo.imgUrl
                          : VideosInfo.media.thumbnail[0].url
                      }
                      style={{ width: 500 }}
                    /> */}
                    {isImageUploading ? (
                      <div style={{ textAlign: "center", padding: 10 }}>
                        <div>Uploading Image Please Wait...</div>
                        <div>
                          <PulseLoader
                            color="#23A5E0"
                            loading={true}
                            css={override}
                            size={10}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div></div>
                        <div>
                          <input
                            ref={fileInput}
                            type="file"
                            multiple={false}
                            onChange={onFileSelect}
                            accept="image/x-png,image/jpeg"
                            style={{
                              backgroundColor: "#000",
                              height: 35,
                              width: 40,
                              position: "absolute",
                              opacity: 0,
                              cursor: "pointer",
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                              margin: 8,
                              color: "#555",
                              cursor: "pointer",
                            }}
                          ></i>
                          Please upload image in the dimension of 1920x1080
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      padding: 20,
                    }}
                  >
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ marginBottom: 15 }}>
                        {VideosInfo.status === 1
                          ? "Video is published. Click below to unpublish this video"
                          : "Video is not published. Click below to publish this video"}
                      </div>
                      <div style={{display:"flex"}}>

                      
                     
                        <button
                          className={`bg-${
                            VideosInfo.status === 1 ? "red" : "emerald"
                          }-500 text-white active:bg-${
                            VideosInfo.status === 1 ? "red" : "emerald"
                          }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                          type="button"
                          onClick={showModalForPublish}
                        >
                          {VideosInfo?.status === 1
                            ? "Unpublish Video"
                            : "Publish Video"}
                        </button>
                      
                        
                      <CopyToClipboard
                        text={VideosInfo._id}
                        onCopy={() => setCopyText(true)}
                      >
                        <Button variant="contained" color="primary">
                          Copy Video Link
                        </Button>
                      </CopyToClipboard>

                      {copyText ? (
                        <span style={{ color: "green", marginLeft: "10px" }}>
                          Video Id Copied...
                        </span>
                      ) : (
                        ""
                      )}
                        {VideosInfo.status == 1 && (
                          <div
                            style={{
                              marginTop: 5,
                              marginBottom: 10,
                              fontWeight: "500",
                              marginRight: "80px",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            {`Permalink: `}
                            <a href={VideosInfo?.permalink} target="_blank">
                              <img
                                src="/LinkIcon.png"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "10px",
                                }}
                              />
                            </a>
                            <CopyButton row={VideosInfo} />
                          </div>
                        )}
                      </div>
                     
                      <hr className="my-4 md:min-w-full" />
                      {VideosInfo?.status == 1 && disable == false && (
                        <input
                          type="text"
                          onPaste={(e) => {
                            e.preventDefault();
                            alert("Copy Paste is not Allowed!!!!");
                            return false;
                          }}
                          style={{
                            margin: "2px 5px",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          placeholder="Please Enter Title here"
                          value={titleInput}
                          onChange={(e) => setTitleInput(e.target.value)}
                        />
                      )}

                      {/**CLickbait Headline */}
                      {/* <button
                        // variant="outlined"
                        // color="secondary"
                        className={`bg-${
                          VideosInfo?.status === 1 && !disable && "emerald"
                        }-500 text-white active:bg-${
                          VideosInfo?.status === 1 && !disable && "emerald"
                        }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        style={{
                          marginLeft: "5px",
                          // height: '30px',
                          // width: 'auto',

                          pointerEvents:
                            (VideosInfo.status !== 1) && "none",
                          color: (titleDisabled < 1) && "grey",
                          borderColor:
                            (titleDisabled < 1) && "grey",
                          // color:newsInfo.showArticle && 'white'
                        }}
                        onClick={() => clickbaitHeadline(VideosInfo)}
                        disabled = {titleDisabled<1}
                      >
                        Generate Headline
                      </button> */}

                      {/**All User Notification */}
                      {/* <button
                        // variant="outlined"
                        // color="secondary"
                        className={`bg-${
                          VideosInfo?.status === 1 && !disable && "emerald"
                        }-500 text-white active:bg-${
                          VideosInfo?.status === 1 && !disable && "emerald"
                        }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        style={{
                          marginLeft: "5px",
                          // height: '30px',
                          // width: 'auto',
                          pointerEvents:
                            (VideosInfo.status !== 1 || disable) && "none",
                          color: (VideosInfo.status !== 1 || disable) && "grey",
                          borderColor:
                            (VideosInfo.status !== 1 || disable) && "grey",
                        }}
                        onClick={() => sendNotification()}
                        disabled={disable}
                      >
                        Notify All Users
                      </button> */}
                      {/* user specific notification start */}
                      <button
                        className={`bg-${
                          VideosInfo?.status === 1 &&
                          "emerald"
                        }-500 text-white active:bg-${
                          VideosInfo?.status === 1 &&
                          "emerald"
                        }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        style={{
                          marginLeft: "5px",

                          pointerEvents:
                            (VideosInfo.status !== 1 || notificationDisable) &&
                            "none",
                          color:
                            (VideosInfo.status !== 1 || notificationDisable) &&
                            "lightgray",
                          borderColor:
                            (VideosInfo.status !== 1 || notificationDisable) &&
                            "grey",
                        }}
                        onClick={() => sendUserSpecificNotification(titleInput)}
                        disabled = {notificationDisable}
                      >
                        Notify All Users
                      </button>
                    </div>
                    <hr className="my-4 md:min-w-full" />
                    {/* Tagged Sport  */}

                    {!changeSports && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 10,
                          fontWeight: "500",
                        }}
                      >
                        Tagged Sports:
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            marginLeft: "10px",
                            marginRight: "5px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setChangeSports(true);
                            dispatchSelectedSports({
                              selectedSports:
                                VideosInfo.spports && VideosInfo.sports._id
                                  ? VideosInfo.sports._id
                                  : "All",
                              selectedSportsFilter:
                                VideosInfo.spports && VideosInfo.sports._id
                                  ? VideosInfo.sports._id
                                  : "All",
                            });
                          }}
                        ></i>
                        {taggedSports}
                        {taggedSports && (
                          <i
                            onClick={() => {
                              mapVideosToSports("", VideosInfo._id)
                                .then((response) => {
                                  if (response.status) {
                                    setAlertModal(true);
                                    setTaggedSports(false);
                                    getVideosInformation();
                                  }
                                })
                                .catch((error) => {});
                            }}
                            className="fas fa-trash "
                            style={{
                              cursor: "pointer",
                              paddingLeft: "15px",
                              color: "red",
                            }}
                          >
                            {"  "}
                            (Untag Sports)
                          </i>
                        )}
                      </div>
                    )}
                    {changeSports && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 10,
                          fontWeight: "500",
                          marginRight: "80px",
                          display: "flex",
                          // justifyContent: 'space-between',
                          alignItems: "center",
                          // width: '300px',
                        }}
                      >
                        Tagged Sports:{" "}
                        <div
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            marginRight: "30px",
                            marginLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <SportsTaggingDropdown onChange={onSportsSelected} />
                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              margin: 8,
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setChangeSports(false);
                            }}
                          ></i>
                        </div>
                      </div>
                    )}
                    {/* End Tagged Sports  */}
                    <hr className="my-2 md:min-w-full" />
                    <div className="flex">
                      {!changeLeague && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            marginRight: "80px",
                            fontWeight: "500",
                          }}
                        >
                          Tagged League:
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: "6px",
                              marginRight: "5px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setChangeLeague(true);
                              getAllTaggedLeague();
                              dispatchSelectedLeagueTagging({
                                selectedLeague: VideosInfo.league,
                                selectedLeagueFilter: VideosInfo.league,
                              });
                            }}
                          ></i>
                          {taggedLeague}
                          {taggedLeague && (
                            <i
                              onClick={() => {
                                mapVideosToLeague(VideosInfo._id, "", "")
                                  .then((response) => {
                                    if (response.status) {
                                      setAlertModal(true);
                                      setTaggedLeague(false);
                                      getVideosInformation();
                                    }
                                  })
                                  .catch((error) => {});
                              }}
                              className="fas fa-trash "
                              style={{
                                cursor: "pointer",
                                paddingLeft: "15px",
                                color: "red",
                              }}
                            >
                              {"  "}
                              (Untag League)
                            </i>
                          )}
                        </div>
                      )}
                      {changeLeague && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                            display: "flex",
                            // justifyContent: 'space-between',
                            alignItems: "center",
                            // width: '300px',
                          }}
                        >
                          Tagged League:{" "}
                          <div
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              marginLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LeagueDropdownTagging
                              onChange={onLeagueSelected}
                            />

                            <i
                              className="fas fa-times fa-1x"
                              style={{
                                margin: 8,
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChangeLeague(false);
                              }}
                            ></i>
                          </div>
                        </div>
                      )}
                    </div>
                    {taggedLeague == "T20 World Cup" && (
                      <>
                        <hr className="my-2 md:min-w-full" />
                        <TeamSelectVideos
                          videoId={VideosInfo._id}
                          selected={teamDocs}
                        />
                      </>
                    )}
                    {/* {taggedLeague == 'T20 World Cup' && teamDocs.length > 0 && ( */}
                    {/* <Button
                        variant="outlined"
                        color="secondary"
                        style={{
                          marginLeft: '5px',
                          // height: '30px',
                          // width: 'auto',
                        }}
                        onClick={() => sendNotification()}
                        disabled={disable}
                      >
                        Notify Users
                      </Button> */}
                    {/* )} */}
                    <hr className="my-4 md:min-w-full" />
                    {/* All league dropdown */}
                    <div>
                      {!changeAllLeague && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            marginRight: "80px",
                            fontWeight: "500",
                          }}
                        >
                          Tagged All League:{" "}
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: "6px",
                              marginRight: "5px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setChangeAllLeague(true);
                              dispatchSelectedLeague({
                                selectedLeague: VideosInfo.league,
                                selectedLeagueFilter: VideosInfo.league,
                              });
                            }}
                          ></i>
                          {taggedAllLeague}
                          {taggedAllLeague && (
                            <i
                              onClick={() => {
                                mapVideosToLeague(VideosInfo._id, "", "",true)
                                  .then((response) => {
                                    if (response.status) {
                                      setAlertModal(true);
                                      setTaggedAllLeague(false);
                                      getVideosInformation();
                                    }
                                  })
                                  .catch((error) => {});
                              }}
                              className="fas fa-trash "
                              style={{
                                cursor: "pointer",
                                paddingLeft: "15px",
                                color: "red",
                              }}
                            >
                              {"  "}
                              (Untag All League)
                            </i>
                          )}
                        </div>
                      )}

                      {changeAllLeague && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          {/* Tagged All League */}
                          <AllLeagueDropDown onChange={onAllLeagueSelected} />

                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              margin: 8,
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setChangeAllLeague(false);
                              dispatchSelectedLeague({
                                selectedLeague: VideosInfo.league,
                                selectedLeagueFilter: VideosInfo.league,
                              });
                            }}
                          ></i>
                        </div>
                      )}
                      {/* All league dropdown end*/}
                    </div>
                    {taggedLeague !== false && taggedSports !== false && (
                      <>
                        <hr className="my-4 md:min-w-full" />
                        {/* Teams Tagging */}
                        {!changeTeams && (
                          <div
                            style={{
                              marginTop: 5,
                              marginBottom: 10,
                              marginRight: "8px",
                              fontWeight: "500",
                            }}
                          >
                            Tagged Teams:{" "}
                            <i
                              className="fas fa-edit fa-1x"
                              style={{
                                marginLeft: "6px",
                                marginRight: "5px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChangeTeams(true);
                                GetTeamsForTagging(
                                  props.selectedLeagueTag,
                                  props.selectedSportsTagging,
                                  props.teamsSearch == ""
                                    ? "search"
                                    : props.teamsSearch
                                );
                                dispatchSelectedTeamsTagging({
                                  selectedTeamsTag: VideosInfo?.teams,
                                });
                              }}
                            ></i>
                            {VideosInfo?.teams
                              ?.map((items) => {
                                return items.name;
                              })
                              .join(", ")
                              ? VideosInfo?.teams
                                  ?.map((items) => {
                                    return items?.name;
                                  })
                                  .join(", ")
                              : "N/A"}
                          </div>
                        )}

                        {changeTeams && (
                          <div
                            style={{
                              marginTop: 5,
                              marginBottom: 10,
                              fontWeight: "500",
                              display: "flex",
                              // justifyContent: 'space-between',

                              alignItems: "center",
                              // width: '300px',
                            }}
                          >
                            Tagged Teams:{" "}
                            <div
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginLeft: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <TeamsDropdownForTaggingVideos
                                onChange={onTeamsSelected}
                                videosID={VideosInfo._id}
                              />

                              <i
                                className="fas fa-times fa-1x"
                                style={{
                                  marginLeft: "30px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setChangeTeams(false);
                                  dispatchSelectedTeamsTagging({
                                    selectedTeamsTag: VideosInfo?.teams,
                                  });
                                }}
                              ></i>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {/* Teams End*/}
                    <hr className="my-4 md:min-w-full" />
                    {taggedLeague !== false &&
                      taggedSports !== false &&
                      props.matchesTag.length !== 0 && (
                        <>
                          <div className="flex">
                            {/* Matches */}
                            {!changeMatches && (
                              <div
                                style={{
                                  marginTop: 5,
                                  marginBottom: 10,
                                  marginRight: "80px",
                                  fontWeight: "500",
                                }}
                              >
                                Tagged Matches:{" "}
                                <i
                                  className="fas fa-edit fa-1x"
                                  style={{
                                    marginTop: 5,
                                    marginBottom: 10,
                                    marginRight: "10px",
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setChangeMatches(true);
                                    getAllTaggedMatches();
                                    dispatchSelectedMatchesTagging({
                                      selectedMatchesTag: VideosInfo.matchId,
                                      selectedMatchesFilterTag:
                                        VideosInfo.matchId,
                                    });
                                  }}
                                ></i>
                                {taggedMatches}
                                {taggedMatches && (
                                  <i
                                    onClick={() => {
                                      mapVideosToMatches("", VideosInfo._id)
                                        .then((response) => {
                                          if (response.status) {
                                            setAlertModal(true);
                                            setTaggedMatches(false);
                                            getVideosInformation();
                                          }
                                        })
                                        .catch((error) => {});
                                    }}
                                    className="fas fa-trash "
                                    style={{
                                      cursor: "pointer",
                                      paddingLeft: "15px",
                                      color: "red",
                                    }}
                                  >
                                    {"  "}
                                    (Untag Match)
                                  </i>
                                )}
                              </div>
                            )}

                            {changeMatches && (
                              <div
                                style={{
                                  marginTop: 5,
                                  marginBottom: 10,
                                  fontWeight: "500",
                                  display: "flex",
                                  // justifyContent: 'space-between',

                                  alignItems: "center",
                                  // width: '300px',
                                }}
                              >
                                Tagged Matches:{" "}
                                <div
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    marginRight: "30px",
                                    marginRight: "30px",
                                    marginLeft: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <MatchesDropdownTagging
                                    onChange={onMatchesSelected}
                                  />

                                  <i
                                    className="fas fa-times fa-1x"
                                    style={{
                                      marginLeft: "20px",
                                      marginTop: "-70px",
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setChangeMatches(false);
                                      dispatchSelectedMatchesTagging({
                                        selectedMatchesTag: VideosInfo.matchId,
                                        selectedMatchesFilterTag:
                                          VideosInfo.matchId,
                                      });
                                    }}
                                  ></i>
                                </div>
                              </div>
                            )}
                            {/* Matches End*/}
                          </div>
                          <hr className="my-4 md:min-w-full" />
                        </>
                      )}

                    <div className="flex justify-between items-center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          onClick={() => {
                            setTopVideoChange(true);
                            setDateTime(true);
                          }}
                          style={{ fontWeight: "500" }}
                        >
                          <Checkbox
                            checked={
                              topVideosType === "TOP_VIDEO" ? true : false
                            }
                            onChange={changeTopVideosType}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          Top Videos
                          {/* <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the Video)
                        </span> */}
                        </div>
                        {/* {topVideosType == 'TOP_VIDEO' && (
                        <div className="edit__popup-row">
                          <span>Top Video Expiry:</span>

                          <div className="relative w-full ">
                            {dateTime ? (
                              <DateTimePicker
                                autoOk
                                hideTabs
                                minDateMessage=""
                                minDate={_dateTimeFormat(
                                  new Date(),
                                  'ddd,YYYY DD MMM hh:mm A'
                                )}
                                value={_dateTimeFormat(
                                  new Date().getTime() + 6 * 60 * 60 * 1000,
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                onChange={(e) =>
                                  updateTopVideoTime(topVideosType, e)
                                }
                              />
                            ) : (
                              _dateTimeFormat(expiryDate, 'ddd, DD MMM hh:mm A')
                            )}
                          </div>
                        </div>
                      )} */}
                      </MuiPickersUtilsProvider>
                      {/* <hr className="my-4 md:min-w-full" /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          onClick={() => {
                            setHightlightVideoChange(true);
                            setDateTime(true);
                          }}
                          style={{ fontWeight: "500" }}
                        >
                          <Checkbox
                            checked={highlightChecked}
                            onChange={selectHighlightVideos}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          Highlights
                          {/* <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the
                          Highlights)
                        </span> */}
                        </div>
                        {/* {highlightChecked && (
                        <div className="edit__popup-row">
                          <span>Highlights Expiry:</span>

                          <div className="relative w-full ">
                            {dateTime ? (
                              <DateTimePicker
                                autoOk
                                hideTabs
                                minDateMessage=""
                                minDate={_dateTimeFormat(
                                  new Date(),
                                  'ddd,YYYY DD MMM hh:mm A'
                                )}
                                value={_dateTimeFormat(
                                  new Date().getTime() + 24 * 60 * 60 * 1000,
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                onChange={(e) => updateHighlightTime(e)}
                              />
                            ) : (
                              _dateTimeFormat(
                                h ighlightExpiryDate,
                                'ddd, DD MMM hh:mm A'
                              )
                            )}
                          </div>
                        </div>
                      )} */}
                      </MuiPickersUtilsProvider>
                      {/* <hr className="my-4 md:min-w-full" /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          onClick={() => {
                            setHomeVideoChange(true);
                            setDateTime(true);
                          }}
                          style={{ fontWeight: "500" }}
                        >
                          <Checkbox
                            checked={homeVideosChecked}
                            onChange={selectHomeVideos}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          Home
                          {/* <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the Video)
                        </span> */}
                        </div>
                        {/* {homeVideosChecked && (
                        <div className="edit__popup-row">
                          <span>Home Expiry Date: </span>

                          <div className="relative w-full ">
                            {dateTime ? (
                              <DateTimePicker
                                autoOk
                                hideTabs
                                minDateMessage=""
                                minDate={_dateTimeFormat(
                                  new Date(),
                                  'ddd,YYYY DD MMM hh:mm A'
                                )}
                                value={_dateTimeFormat(
                                  new Date().getTime() + 6 * 60 * 60 * 1000,
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                onChange={(e) => updateHomeVideoTime(e)}
                              />
                            ) : (
                              _dateTimeFormat(
                                homeExpiryDate,
                                'ddd, DD MMM hh:mm A'
                              )
                            )}
                          </div>
                        </div>
                      )} */}
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          // onClick={() => {
                          //   (true);
                          //   setDateTime(true);
                          // }}
                          style={{ fontWeight: "500" }}
                        >
                          <input 
                            type="checkbox"
                            checked={tagTrends}
                            onChange = {
                              ()=> {TagNewsToTrends(!tagTrends)}
                            }
                            style={{marginRight:'10px', borderRadius:'2px', border:'2px solid grey', cursor:'pointer'}}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          Trending
                          {/* <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the News)
                        </span> */}
                        </div>
                        {/* {topNewsType == 'TOP_NEWS' && (
                        <div className="edit__popup-row">
                          <span>Top News Expiry:</span>

                          <div className="relative w-full ">
                            {dateTime ? (
                              <DateTimePicker
                                // format="ddd, dd MM hh:mm A"
                                autoOk
                                hideTabs
                                minDateMessage=""
                                minDate={_dateTimeFormat(
                                  new Date(),
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                value={_dateTimeFormat(
                                  new Date().getTime() + 6 * 60 * 60 * 1000,
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                onChange={(e) =>
                                  updateTopNewsTime(topNewsType, e)
                                }
                              />
                            ) : (
                              _dateTimeFormat(expiryDate, 'ddd, DD MMM hh:mm A')
                            )}
                          </div>
                        </div>
                      )} */}
                      </MuiPickersUtilsProvider>
                    </div>
                    <hr className="my-4 md:min-w-full" />
                    {/* <div
                      onClick={() => {
                        setHomeSpecialVideoChange(true);
                        setDateTime(true);
                      }}
                      style={{ fontWeight: '500' }}
                    >
                      <Checkbox
                        checked={homeSpecialVideosChecked}
                        onChange={selectHomeSpecialVideos}
                        color="default"
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                      />
                      Home Special
                    </div> */}
                    <div style={{ fontWeight: "bold" }}>
                      Home Special:{" "}
                      <HomeSpecialDropdown
                        selectedValue={
                          homeSpecialType == undefined ? "ALL" : homeSpecialType
                        }
                        onChange={onHomeSpecialTypeChange}
                      />
                    </div>
                    {/* make a chekbox for tagging top or home
                    on selecting a checkbox it should tag to either 
                    top or home
                    */}

                    <div>
                      {/* <div
                        onClick={() => OnClickEditVideo(VideosInfo)}
                        // className={
                        //   VideosInfo.copyrights !== 'SportsTak'
                        //     ? 'icon-disabled'
                        //     : ''
                        // }
                      >
                        <div style={{ fontWeight: 'bolder', color: 'tomato' }}>
                          Edit Video: <i className="far fa-edit"></i>
                        </div>
                      </div> */}
                      <hr className="my-4 md:min-w-full" />
                      <div
                        style={{ fontWeight: "500" }}
                        onClick={() => setEditTitle(true)}
                      >
                        Headline*:{" "}
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            marginLeft: "6px",
                            marginRight: "5px",
                            color: "black",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                      {editTitle ? (
                        <>
                          {" "}
                          <textarea
                            name="story"
                            rows="2"
                            cols="80"
                            id="textbox"
                            value={headline}
                            onChange={(e) => setHeadline(e.target.value)}
                            style={{ marginTop: 20, borderRadius: "5px" }}
                          ></textarea>{" "}
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${headline?.length}`}
                          </span>
                          {/* <textarea
                              rows="3"
                              cols="100"
                              name="story"
                              value={headline}
                              id="textbox"
                              onChange={(e) => setHeadline(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=""
                              style={{
                                width: '500px',
                                height: '50px',
                                marginRight: '20px',
                              }}
                            ></textarea> */}
                          <div className={"edit__popup-row button-row"}>
                            <div
                              onClick={() => updateTitle()}
                              className={"save btn"}
                            >
                              Update
                            </div>
                            <i
                              onClick={() => setEditTitle(false)}
                              className="far fa-times-circle secondary btn "
                              style={{
                                padding: "9px 10px",
                                background: "tomato",
                              }}
                            >
                              Close
                            </i>
                          </div>
                        </>
                      ) : (
                        <div>{VideosInfo.title}</div>
                      )}
                    </div>
                    
                    
                    <hr className="my-4 md:min-w-full" />
                    <div style={{ display: "flex" }}>
                      <div>
                        <div style={{ fontWeight: "500" }}>Published At:</div>
                        {/* <div>{VideosInfo.PublishedAt}</div> */}
                        {_dateTimeFormatinIST(
                          VideosInfo.date,
                          "ddd, DD MMM hh:mm A"
                        )}
                        {/* {VideosInfo.copyrights === 'PTI'
                        ? _dateTimeFormat(
                            VideosInfo.date,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(VideosInfo.date, 'ddd, DD MMM hh:mm A')} */}
                      </div>
                      {isyoutube === "yes" ? (
                        <div style={{ marginTop: 20 }}>
                          <div>Hashtag</div>
                          <div>
                            {VideosInfo.tags != ""
                              ? VideosInfo.tags
                              : "No Tags Available"}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {isyoutube === "yes" ? (
                        <div style={{ marginTop: 20 }}>
                          <div>YT Channel</div>
                          <div>{VideosInfo.channelTitle}</div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div style={{ marginLeft: 100 }}>
                        <div style={{ fontWeight: "500" }}>Videos Type:</div>
                        <div>{VideosInfo.copyright}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-4 md:min-w-full" />
                {/* seo */}
                <div
                  style={{ fontWeight: "500" }}
                  onClick={() => setShortEditTitle(true)}
                >
                  Short Title:{" "}
                  <i
                    className="fas fa-edit fa-1x"
                    style={{
                      marginLeft: "6px",
                      marginRight: "5px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>

                {editShortTitle ? (
                  <div className="py-2 px-2">
                    {" "}
                    <textarea
                      name="story"
                      rows="2"
                      cols="80"
                      id="textbox"
                      value={shortTitle}
                      onChange={(e) => setShortTitle(e.target.value)}
                      style={{ marginTop: 20, borderRadius: "5px" }}
                    ></textarea>{" "}
                    <span
                      style={{
                        background: "#50C878",
                        height: "auto",
                        width: "auto",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "5px",
                        borderRadius: "8px",
                        marginLeft: "2rem",
                        marginBottom: "0.1px",
                      }}
                    >
                      {`Characters: ${shortTitle?.length ?? 0}`}
                    </span>
                    <div
                      className={"edit__popup-row button-row"}
                      style={{ marginTop: "10px" }}
                    >
                      <div
                        onClick={() => updateShortTitle()}
                        className={"save btn"}
                      >
                        Update
                      </div>
                      <i
                        onClick={() => setShortEditTitle(false)}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "#f34a17",
                        }}
                      >
                        Cancel
                      </i>
                    </div>
                  </div>
                ) : (
                  <div>{VideosInfo.shortTitle}</div>
                )}
                <hr className="my-4 md:min-w-full" />
                {/* seo */}
                <div
                  style={{ fontWeight: "500" }}
                  onClick={() => setEditSEOTitle(true)}
                >
                  SEO Title*:{" "}
                  <i
                    className="fas fa-edit fa-1x"
                    style={{
                      marginLeft: "6px",
                      marginRight: "5px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>

                {editSEOTitle ? (
                  <div className="py-2 px-2">
                    {" "}
                    <textarea
                      name="story"
                      rows="2"
                      cols="80"
                      id="textbox"
                      value={seoTitle}
                      onChange={(e) => setSeoTitle(e.target.value)}
                      style={{ marginTop: 20, borderRadius: "5px" }}
                    ></textarea>{" "}
                    <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${seoTitle?.length}`}
                          </span>
                    <div
                      className={"edit__popup-row button-row"}
                      style={{ marginTop: "10px" }}
                    >
                      <div
                        onClick={() => updateSeoTitle()}
                        className={"save btn"}
                      >
                        Update
                      </div>
                      <i
                        onClick={() => setEditSEOTitle(false)}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "#f34a17",
                        }}
                      >
                        Cancel
                      </i>
                    </div>
                  </div>
                ) : (
                  <div>{VideosInfo.seo_title}</div>
                )}
                <hr className="my-4 md:min-w-full" />
                <div
                  style={{ fontWeight: "500", color:invalidSeoSlug?"red":""  }}
                  onClick={() => setEditSEOSlug(true)}
                >
                  SEO Slug:{" "}*
                  <i
                    className="fas fa-edit fa-1x"
                    style={{
                      marginLeft: "6px",
                      marginRight: "5px",
                      color: invalidSeoSlug?"red":"black",
                      cursor: "pointer",
                    }}
                  ></i>
                  {invalidSeoSlug && <span style={{fontSize:"12px",  color:"#ff0000"}}>{` (*SEO Slug must contain character in between a-z, A-Z, 0-9 or -.)`}</span>}
                </div>

                {editSEOSlug ? (
                  <div className="py-2 px-2">
                    {" "}
                    <textarea
                      name="story"
                      rows="2"
                      cols="80"
                      id="textbox"
                      value={seoSlug}
                      onInput={(e)=> setInvalidSeoSlug(false)}
                      onChange={(e) => setSeoSlug(e.target.value)}
                      style={{ borderRadius: "5px", borderColor:invalidSeoSlug?"red":"", color:invalidSeoSlug?"red":"" }}
                    ></textarea>{" "}
                    <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${seoSlug?.length}`}
                          </span>
                    <div
                      className={"edit__popup-row button-row"}
                      style={{ marginTop: "10px" }}
                    >
                      <div
                        onClick={() => updateSeoSlug()}
                        className={"save btn"}
                      >
                        Update
                      </div>
                      <i
                        onClick={() => {setEditSEOSlug(false); setInvalidSeoSlug(false)}}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "#f34a17",
                        }}
                      >
                        Cancel
                      </i>
                    </div>
                  </div>
                ) : (
                  <div style={{color:invalidSeoSlug?"red":""}}>{VideosInfo.seo_slug}</div>
                )}

              <hr className="my-0 md:min-w-full" />
                <div
                  style={{ fontWeight: "500", marginTop:'1rem' }}
                  onClick={() => setEditKeyword(true)}
                >
                  
                  Keyword*:{" "}
                  <i
                    className="fas fa-edit fa-1x"
                    style={{
                      marginLeft: "6px",
                      marginRight: "5px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>

                {editKeyword ? (
                  <div className="py-2 px-2">
                    <textarea
                      name="keyword"
                      // onPaste={(e) => {
                      //   e.preventDefault();
                      //   alert("Copy Paste is not Allowed!!!!");
                      //   return false;
                      // }}
                      rows="2"
                      cols="80"
                      id="textbox"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      style={{ marginTop: 20, borderRadius: "5px" }}
                    ></textarea>{" "}
                    <div
                      className={"edit__popup-row button-row"}
                      style={{ marginTop: "10px" }}
                    >
                      <div
                        onClick={() => updateKeyword()}
                        className={"save btn"}
                      >
                        Update
                      </div>
                      <i
                        onClick={() => setEditKeyword(false)}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "#f34a17",
                        }}
                      >
                        Cancel
                      </i>
                    </div>
                  </div>
                ) : (
                  <div>
                    {VideosInfo.slug ? VideosInfo.slug : VideosInfo.keyword}
                  </div>
                )}
                <hr className="my-4 md:min-w-full" />
                <div
                  style={{ fontWeight: "500" }}
                  onClick={() => setEditDescription(true)}
                >
                  Description*:{" "}
                  <i
                    className="fas fa-edit fa-1x"
                    style={{
                      marginLeft: "6px",
                      marginRight: "5px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
                {editDescription ? (
                  <>
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      style={{ width: '200px', overflowY: 'scorll' }}
                      onChange={(e, editor) => setDescription(editor.getData())}
                    /> */}
                    <ReactQuill
                      className="shadow-sm"
                      theme="snow"
                      name="description"
                      style={{
                        minHeight: 50,
                        marginTop: "1rem",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      value={description}
                      modules={{
                        toolbar: [
                          [
                            // { header: "1" },
                            // { header: "2" },
                            { header: [1, 2, 3, 4, 5, 6] },
                            { font: [] },
                          ],
                          [{ size: [] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ align: [] }],
                          [{ color: [] }, { background: [] }],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                          ],
                          // ["link", "image"],
                          ["link", "video", "image"],
                          // ["link", "video", "image", "code-block"],
                          // ['clean'],
                          ["embed"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "color",
                        "background",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "video",
                        "image",
                        "code-block",
                        "align",
                      ]}
                      onChange={(val, delta, source, editor) => { 
                        setDescription(val)
                        setDescriptionLength(editor.getText()?.length);

                      }}
                    />
                    <br></br>

                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                            }}
                          >
                            {`Characters: ${
                              descriptionLength - 1 < 1
                                ? 0
                                : descriptionLength - 1
                            }`}
                          </span>

                    <div className={"edit__popup-row button-row"}>
                      <div
                        onClick={() => updateDescription()}
                        className={"save btn"}
                      >
                        Update
                      </div>
                      <i
                        onClick={() => setEditDescription(false)}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "tomato",
                        }}
                      >
                        Close
                      </i>
                    </div>
                  </>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: VideosInfo.description }}
                  ></div>
                )}
                <hr className="my-4 md:min-w-full" />
                <div
                  style={{ fontWeight: "500" }}
                  onClick={() => setEditSEODescription(true)}
                >
                  SEO Description*:
                  <i
                    className="fas fa-edit fa-1x"
                    style={{
                      marginLeft: "6px",
                      marginRight: "5px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
                {editSEODescription ? (
                  <>
                    {/* <FroalaEditorComponent /> */}
                    <ReactQuill
                      className="shadow-sm"
                      theme="snow"
                      name="description"
                      style={{
                        minHeight: 50,
                        marginTop: "1rem",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      value={seoDescription}
                      modules={{
                        toolbar: [
                          [
                            // { header: "1" },
                            // { header: "2" },
                            { header: [1, 2, 3, 4, 5, 6] },
                            { font: [] },
                          ],
                          [{ size: [] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ align: [] }],
                          [{ color: [] }, { background: [] }],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                          ],
                          // ["link", "image"],
                          ["link", "video", "image"],
                          // ["link", "video", "image", "code-block"],
                          // ['clean'],
                          ["embed"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "color",
                        "background",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "video",
                        "image",
                        "code-block",
                        "align",
                      ]}
                      onChange={(val, delta, source, editor) => { 
                        setSeoDescription(val)
                        setSeoDescriptionLength(editor.getText()?.length);
                      }}
                    />
                    <br></br>

                    <span
                      style={{
                        background: "#50C878",
                        height: "auto",
                        width: "auto",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "5px",
                        borderRadius: "8px",
                      }}
                    >
                      {`Characters: ${
                        seoDescriptionLength - 1 < 1
                          ? 0
                          : seoDescriptionLength - 1
                      }`}
                    </span>

                    <div className={"edit__popup-row button-row"}>
                      <div
                        onClick={() => updateSeoDescription()}
                        className={"save btn"}
                      >
                        Update
                      </div>
                      <i
                        onClick={() => setEditSEODescription(false)}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "#f34a17",
                        }}
                      >
                        Cancel
                      </i>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      style={{ paddingTop: 10 }}
                      dangerouslySetInnerHTML={{
                        __html: VideosInfo.seo_description,
                      }}
                    ></div>
                  </>
                )}
                <hr className="my-4 md:min-w-full" />
              </div>
            )}
          </div>
        </div>
        
      </div>
      {/*Trending Keyword List*/}
      <>
      <div style={{position:'fixed',bottom:'30px',right:'20px'}}>
      <Fab variant="extended" size="medium" color="primary" aria-label="add"
      onClick={() => setOpen(true)}
              style={{ marginRight: 5 }}>
              Trending Keywords
      </Fab>
          </div>
          <>
      <div className="edit__popup" style={{display:open?'block':'none',}}>
        <div
          onClick={() => setOpen(false)}
          className={
            open ? "edit__backdrop active" : "edit__backdrop"
          }
        ></div>

        <div
          className={
            open ? "edit__popup-body active" : "edit__popup-body"
          }
          style={{ zIndex: 1000,maxWidth:'1000px',width:'1000px',height:'80%',
            position:'relative',top:'50%',left:'50%',transform:'translate(-50%, -50%)'}}
        > 
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div className="edit__popup-heading">Top Trends</div>
          <div className="edit__popup-inner"
            style={{marginTop:'7px'}}>
            <i
              onClick={() => setOpen(false)}
              className="far fa-times-circle edit__popup-close"
            ></i>
 
          </div>
        </div>
        <TrendsAllLocations twitterTaggedLocations = {twitterTaggedLocations}/>
        </div>
      </div>
      </>
      </>
      
      
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leagues: state.leaguesReducer.leagues,
    sports: state.sportsReducer.sports,
    leaguesTag: state.leaguesReducer.leaguesTag,
    matchesTag: state.matchesReducer.matchesTag,
    leagueSearch: state.leaguesReducer.leagueSearch,
    matchesSearch: state.matchesReducer.matchesSearch,
    teamsSearch: state.teamsReducer.teamsSearch,
    matchesPage: state.matchesReducer.matchesPage,
    selectedSportsTagging: state.sportsReducer.selectedSportsTagging,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
  };
};
export default connect(mapStateToProps)(VideosInfo);
