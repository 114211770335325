import GoogleAnalyticsTable from '../../../components/Cards/GoogleAnalyticsTable';
import Sidebar from '../../../components/Sidebar/Sidebar';

const GoogleAnalytics = ()=>{
    return(
        <>
        <Sidebar />
        <div  className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}> 
                   <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <GoogleAnalyticsTable color="light"/>
          </div>
        </div>
        </div>
        </>
    );

}

export default GoogleAnalytics;
