import React from 'react';
import { connect } from 'react-redux';
import { dispatchSelectedSports } from '../../redux/action-dispatchers/sports.dispatchers';

const SportsDropDown = (props) => {
  const onChange = (event) => {
    props.onChange && props.onChange(event.target.value);
    // dispatchSelectedSports({
    //   selectedSports: event.target.value,
    //   selectedSportsFilter: event.target.value,
    // });
    // alert(event.target.value);
  };

  return (
    <select
      style={{
        // marginLeft: 20,
        width:"150px",
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      value={props?.selectedSports}
      onChange={onChange}
    >
      <option value="All">Select Sports</option>
      {props.sportsReducer.sports?.map((sports) => (
        <option value={sports._id}>{sports.name}</option>
      ))}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    sportsReducer: state.sportsReducer,
    selectedSportsFilter: state.sportsReducer.selectedSportsFilter,
  };
};

export default connect(mapStateToProps)(SportsDropDown);
