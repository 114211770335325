import React, { useCallback, useRef, useState } from "react";
import Cropper from 'react-easy-crop'

import { getCroppedImg } from './canvasUtils'

const CropperModal = (props) => {
    const [cropperData, setCropperData] = useState({
        image: props.image,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 16/9,
    });
    const onCropChange = (crop) => {
        setCropperData({
            ...cropperData,
            crop
        })
    };
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                cropperData.image,
                croppedAreaPixels,
                0
            );
            props.closeCropperAndSetData(cropperData.image,croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [cropperData.image, croppedAreaPixels]);

    const setAndCloseCropper = () => {
        showCroppedImage();
        props.onClose();
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const onZoomChange = (zoom) => {
        setCropperData({
            ...cropperData,
            zoom
        })
    };

    const zoomIn = () => {
        setCropperData({
            ...cropperData,
            zoom: cropperData.zoom + 1
        });
    };

    const zoomOut = () => {
        setCropperData({
            ...cropperData,
            zoom: cropperData.zoom === 1 ? 1 : cropperData.zoom - 1
        });
    };

    return (
        <>
            <div style={{ position: 'absolute', left: '0vw', top: 0, backgroundColor: 'rgba(0,0,0,0.8)', width: '100%', padding: 10 }}>
                <div> <i className="fas fa-search-plus fa-lg" style={{ margin: 8, color: '#FFF', cursor: 'pointer' }} onClick={zoomIn}></i>
                    <i className="fas fa-search-minus fa-lg" style={{ margin: 8, color: '#FFF', cursor: 'pointer' }} onClick={zoomOut}></i>
                    <i className="fas fa-check fa-lg" style={{ margin: 8, color: '#FFF', cursor: 'pointer' }} onClick={setAndCloseCropper}></i>
                    <i className="fas fa-times fa-lg" style={{ margin: 8, color: '#FFF', cursor: 'pointer' }} onClick={props.onClose}></i></div>
            </div>
            <div>
                <Cropper
                    image={cropperData.image}
                    crop={cropperData.crop}
                    zoom={cropperData.zoom}
                    aspect={cropperData.aspect}
                    style={{
                        containerStyle: {
                            top: 50
                        }
                    }}
                    cropSize={{ height: 500, width: 1080 }}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                />
            </div>
        </>
    );
};

export default CropperModal;