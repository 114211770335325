import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { connect } from 'react-redux';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import '../../News/NewsManagement/NewsManage.css';
import Axios from 'axios';

import isEqual from 'lodash.isequal';
import { GetSectionNews } from '../../../../handlers/news.handlers';
import {
  _dateTimeFormat,
  _getDateTime,
} from '../../../../constants/appDefaults';

let originalLeagueList;

const TwitterManagement = (props) => {
  const [render, setRender] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, SetLimit] = React.useState(10);
  const [docs, setDocs] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAction, setShowAction] = React.useState(false);
  const color = 'light';
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    const items = reorder(docs, source.index, destination.index);

    setDocs(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getNews = (page, _limit, isLoadMore) => {
    isLoadMore && setIsLoadingMore(true);

    GetSectionNews(page, parseInt(_limit), {
      Authorization: props.Authorization,
    })
      .then((response) => {
        isLoadMore && setIsLoadingMore(false);

        if (response.message) {
          if (isLoadMore) {
            setDocs([...docs, response.data.docs]);
            setIsLoading(true);
          } else {
            setDocs(response.data.docs);
          }
        } else {
        }
      })

      .catch((error) => {
        isLoadMore && setIsLoadingMore(false);
      });
  };

  React.useEffect(() => {
    getNews(parseInt(page), parseInt(limit), false);
  }, []);

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };

  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Twitter
                    </h3>
                  </div>
                  <div className="form-outline">
                    <input
                      type="search"
                      id="form1"
                      className="form-control"
                      placeholder="Search"
                      aria-label="Search"
                      style={{ border: '1px solid black', padding: '5px' }}
                    />
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Image
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Title
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Sports
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Match
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Tagged League
                              </th>
                              {/* <th
                                className={
                                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                  (color === "light"
                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                              >
                                Type
                              </th> */}
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Published Date
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Status
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              ></th>
                            </tr>
                          </thead>

                          <tbody>
                            {docs.length > 0 &&
                              docs.map((news, index) => {
                                return (
                                  <Draggable
                                    key={news._id}
                                    draggableId={news._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                          {/* <AspectRatio
                                   ratio={16 / 9}
                                   style={{
                                     background : `url('${news.imgUrl}')`,
                                     width:'450px',
                                     height:'253px',
                                     backgroundSize : 'cover',
                                     backgroundRepeat :'no-repeat'
                                   }}
                                 > */}
                                          {/* <img
                                     src={news.imgUrl}
                                     className="h-12 w-12 bg-white"
                                     alt="..."
                                   ></img> */}
                                          {/* </AspectRatio> */}

                                          <div
                                            style={{
                                              background: `url('${news.imgUrl}?${news._id}') 0% 0% / cover no-repeat`,
                                              width: '100px',
                                              height: '63px',
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                            }}
                                          ></div>
                                        </th>
                                        <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          style={{
                                            // cursor: "pointer",
                                            fontWeight: 'bolder',
                                          }}
                                        >
                                          {news.headline}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.sports && news.sports.name
                                            ? news.sports.name
                                            : 'N/A'}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.sports && news.sports.name
                                            ? news.sports.name
                                            : 'N/A'}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.sports && news.sports.name
                                            ? news.sports.name
                                            : 'N/A'}
                                        </td>
                                        {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.copyrights}
                                        </td> */}
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {leagueName(news.league)}
                                          {/* {news.league ? news.league : 'N/A'} */}
                                        </td>
                                        {/* news publish time */}
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {_dateTimeFormat(
                                            news.PublishedAt,
                                            'ddd, DD MMM hh:mm A'
                                          )}
                                          {/* {news.copyrights === "PTI"
                                            ? props.selectedLanguage ===
                                              "english"
                                              ? _dateTimeFormat(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}
                                          {news.copyrights === "Opta"
                                            ? props.selectedLanguage ===
                                              "english"
                                              ? _dateTimeFormat(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}
                                          {news.copyrights === "PTI_Bhasha"
                                            ? props.selectedLanguage === "hindi"
                                              ? _dateTimeFormat(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""} */}
                                          {/* {news.copyrights === 'PTI'
                                   ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                                   : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                                          {/* {news.copyrights === 'PTI_Bhasha'
                                   ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                                   : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.showArticle ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Published
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Draft
                                            </span>
                                          )}
                                        </td>
                                        {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                 <TableDropdown showArticle={news.showArticle} newsId={news._id} onView={onView} />
                                 <button
                                   className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                                   type="button"
                                   onClick={() => onView(news._id)}
                                 >
                                   Manage
                                 </button>
                               </td> */}
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </tbody>
                        </table>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
  };
};
export default connect(mapStateToProps)(TwitterManagement);
