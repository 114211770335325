import { defaultModifiers } from '@popperjs/core/lib/popper-lite';
import { TeamsActionTypes } from '../action-types';

const initialState = {
  teamsTag: [],
  teamsTagVideos: [],
  selectedTeamsTag: [],
  teamName: [],
  teamsSearch: '',
  isTeamsTagginSuccess: false,
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TeamsActionTypes.GET_ALL_TEAMS_TAG:
      return Object.assign({}, state, {
        teamsTag: action.payload,
      });
    case TeamsActionTypes.GET_ALL_TEAMS_TAG_VIDEOS:
      return Object.assign({}, state, {
        teamsTagVideos: action.payload,
      });
    case TeamsActionTypes.GET_ALL_TAGGED_TEAMS:
      return Object.assign({}, state, {
        teamName: action.payload,
      });
    case TeamsActionTypes.TEAMS_TAGGING_SUCCESS:
      return Object.assign({}, state, {
        isTeamsTagginSuccess: action.payload,
      });
    case TeamsActionTypes.CHANGE_SEARCH_TEAMS:
      return Object.assign({}, state, {
        teamsSearch: action.payload,
      });
    case TeamsActionTypes.CHANGE_SELECTED_TEAMS_TAGGING:
      return Object.assign({}, state, {
        selectedTeamsTag: action.payload,
      });

    default:
      return state;
  }
};

export default teamsReducer;
