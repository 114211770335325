import { configStore } from '../store';
import { VideosActionTypes } from '../action-types';

export const dispatchVideosListFilter = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.VIDEOS_LIST_FILTER,
  });
};
export const dispatchNumberOfVideosPages = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.NUMBER_OF_PAGES_VIDEOS,
  });
};
export const dispatchNumberOfVideosPagesShorts = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.NUMBER_OF_PAGES_SHORTS_VIDEOS,
  });
};
export const dispatchCurrnetVideosPage = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.CURRENT_PAGE_VIDEOS,
  });
};
export const dispatchCurrnetVideosPageShorts = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.CURRENT_PAGE_VIDEOS_SHORTS,
  });
};
export const dispatchVideosList = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.VIDEOS_LIST,
  });
};
export const dispatchS3VideosList = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.S3_VIDEOS_LIST,
  });
};
export const dispatchShortsVideosList = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.SHORTS_VIDEOS_LIST,
  });
};

export const dispatchRemoveVideo = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.REMOVE_VIDEO_TOGGLE,
  });
};

export const dispatchPlayVideo = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.PLAY_VIDEO,
  });
};

export const dispatchPauseVideo = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.PAUSE_VIDEO,
  });
};

export const dispatchResetVideos = () => {
  configStore.dispatch({
    type: VideosActionTypes.RESET_VIDEOS_LIST,
  });
};

export const dispatchToggleVideoStatus = (payload) => {
  configStore.dispatch({
    payload,
    type: VideosActionTypes.VIDEO_TOGGLE_STATUS,
  });
};
