import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const GetPlayersList = async (body, headers) => {
    return await AxiosInstance.get(
      `${AppConstants.WorkerConstants.API_ROUTES.PLAYERS.GET_PLAYERS}?sportId=${body?.sportId}&page=${body?.page}&limit=${body?.limit}&searchKey=${body?.searchKey}&selectedPlayers=${body?.selectedPlayers}`,
      body
    )
      .then((response) => response)
      .catch((error) => {
        error;
      });
  };