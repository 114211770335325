import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import ConfirmationModal from '../../ConfirmationModal';
import { css } from '@emotion/core';
import PulseLoader from 'react-spinners/PulseLoader';
import _ from 'lodash';
import {
  GetHomeGraphics,
  UploadHomeGraphics,
  updateHomeGraphics,
} from '../../../handlers/leagues.handlers';
import { UploadImageToGallery } from '../../../handlers/gallery.handlers';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

import { GetPaginatedInsightsHome, UpdateHomeInsightsRanking, UpdateInsights } from '../../../handlers/optaGraphics.handlers';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const HomeOptaGraphic = (props) => {
  let fileInput = useRef();
  const [homeGraphics, setHomeGraphics] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isPublished,setIsPublished] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const getHomeOptaGraphics = () => {
    setIsLoading(true);
    
    GetPaginatedInsightsHome(true)
      .then((response) => {
        setIsLoading(false);
      
        if (response.status) {
          const homeGraphicsToSet = [];
          response.data.map((item, position) => {
            homeGraphicsToSet.push({
              data: item,
              id: position,
            });
          });
          setHomeGraphics(homeGraphicsToSet);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  
  useEffect(() => {
    if (homeGraphics.length > 0) {
      // debugger
      updateHomeGraphics(homeGraphics);
    }
  }, [homeGraphics]);
  const updateHomeGraphics = (data) => {
    const graphicsOrderToUpdate = [];
    homeGraphics.forEach((graphic, index) => {
      graphicsOrderToUpdate.push({
        ...graphic.data,
       homeTagRanking: index,
      });
    });
    const dataToUpdate = {
      insightList: graphicsOrderToUpdate,
    };

    UpdateHomeInsightsRanking(dataToUpdate)
      .then((response) => {})
      .catch((error) => {});
  };
  const removeImage = (removeIndexAt) => {
    setIsLoading(true);

    const graphicsOrderToUpdate = [];
    const data = _.filter(homeGraphics, (item, position) => {
      return position !== removeIndexAt;
    });
    data.map((response) => {
      graphicsOrderToUpdate.push({
        url: response.data.url,
        blobName: 'fourth',
        index: response.data.index,
      });
    });

    const dataToPush = {
      Homegraphics: graphicsOrderToUpdate,
    };
    UploadHomeGraphics(dataToPush, {})
      .then((graphicResponse) => {
        setIsLoading(false);
        if (graphicResponse.status) {
          getHomeOptaGraphics();
        }
      })
      .catch((error) => setIsLoading(false));
  };

  
  const untagHome = (id)=>{
    const body ={
      isHomeTagged: false,
      _id:id,
    }
    UpdateInsights(body).then((response)=>{if(response.status)    
    {      
      getHomeOptaGraphics()
    }
    }).catch((error)=>error)
  }
  

 
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
    setSelectedImage('');
  };
  const getMatchOptaGraphics = () => {
    setIsLoading(true);
    GetPaginatedInsightsHome(true)
      .then((response) => {
        setIsLoading(false);
        if (response.status) {
          const homeGraphicsToSet = [];
          response.data.map((item, position) => {
          
            homeGraphicsToSet.push({
              data: item,
              id: position,
            });
          });
          setHomeGraphics(homeGraphicsToSet);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  useEffect(()=>{getMatchOptaGraphics()},[props.openTab])
  const onSuccess = () => {
    if (homeGraphics.length === 10) {
      alert('Maximum 10 graphics are allowed to upload');
    } else {
      const largestIndex = Math.max.apply(
        Math,
        homeGraphics.map(function (o) {
          return o.data.index;
        })
      );
      setIsLoading(true);
      UploadImageToGallery(selectedImage).then((response) => {
        if (response.status) {
          const graphicsOrderToUpdate = [];
          homeGraphics.forEach((graphic, index) => {
            graphicsOrderToUpdate.push({
              ...graphic.data,
              index,
            });
          });
          graphicsOrderToUpdate.push({
            url: response.data,
            blobName: 'fourth',
            index: largestIndex + 1,
          });
          const dataToPush = {
            Homegraphics: graphicsOrderToUpdate,
          };
          UploadHomeGraphics(dataToPush, {})
            .then((graphicResponse) => {
              setIsLoading(false);
              if (graphicResponse.status) {
                getHomeOptaGraphics();
              }
            })
            .catch((error) => setIsLoading(false));
        }
      });

      setConfirmationModal({
        message: '',
        show: false,
        title: '',
      });
    }
  };
  useEffect(() => {
    getHomeOptaGraphics();
  }, []);
  const OptaGraphicImage = (graphicData) => {
    return (
      <div style={{ width: 220, float: 'left', margin: 5 }}>
        <img src={graphicData.imageUrl} style={{ height: 300, width: 220 }} />
        <div style={{ textAlign: 'center' }}>
         
          <button
            onClick={() => 
              untagHome(graphicData._id,graphicData.isHomeTagged)
            }
            style={{ marginTop: 5 }}
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            <i className="fas fa-times"></i>&nbsp;&nbsp;&nbsp;Remove From Home
          </button>
        </div>
      </div>
    );
  };
  return (
    <div style={{ height: 500, overflow: 'scroll' }}>
      {confirmationModal.show && (
        <ConfirmationModal
          message={confirmationModal.message}
          title={confirmationModal.title}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
      <div>
        <div style={{ display: 'inline-block', marginLeft: 10 }}>
          <PulseLoader
            color="#23A5E0"
            loading={isLoading}
            css={override}
            size={10}
          />
        </div>
        <div>
          <RLDD
            items={homeGraphics}
            layout={'horizontal'}
            threshold={80}
            itemRenderer={(item, position) => {
              return <OptaGraphicImage {...item.data} dataIndex={position} />;
            }}
            onChange={(newItems) => {
              setHomeGraphics(newItems);
              // updatehomeGraphics(newItems);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default HomeOptaGraphic;
