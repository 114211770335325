import { VideosActionTypes } from '../action-types';

const initialState = {
  docs: [],
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  page: 1,
  totalDocs: 0,
  totalPages: 0,
  totalPagesShorts: 0,
  _id: '',
  isVideoPaused: false,
  activeVideoReference: null,
  pageShorts: 1,
};

const videosReducer = (state = initialState, action) => {
  switch (action.type) {
    case VideosActionTypes.VIDEOS_LIST:
      // const newData = [];
      // action.payload.docs.forEach((newItem) => {
      //   const isItemFound = state.docs.some((item) => item.id === newItem.id);
      //   if (!isItemFound) {
      //     newData.push(newItem);
      //   }
      // });
      return Object.assign({}, state, {
        ...action.payload,
        docs: [...state.docs, ...action.payload.docs],
      });
    case VideosActionTypes.VIDEOS_LIST_FILTER:
      return Object.assign({}, state, {
        ...action.payload,
        docs: action.payload.docs,
      });
    case VideosActionTypes.NUMBER_OF_PAGES_VIDEOS:
      return Object.assign({}, state, {
        ...action.payload,
        totalPages: action.payload.totalPages,
      });
    case VideosActionTypes.NUMBER_OF_PAGES_SHORTS_VIDEOS:
      return Object.assign({}, state, {
        ...action.payload,
        totalPagesShorts: action.payload,
      });
    case VideosActionTypes.CURRENT_PAGE_VIDEOS:
      return Object.assign({}, state, {
        ...action.payload,
        page: action.payload,
      });
    case VideosActionTypes.CURRENT_PAGE_VIDEOS_SHORTS:
      return Object.assign({}, state, {
        ...action.payload,
        pageShorts: action.payload,
      });

    case VideosActionTypes.PLAY_VIDEO:
      return Object.assign({}, state, {
        isVideoPaused: false,
        activeVideoReference: action.payload.activeVideoReference,
      });
    case VideosActionTypes.PAUSE_VIDEO:
      return Object.assign({}, state, {
        isVideoPaused: action.payload,
        videoIndex: action.payload.videoIndex,
      });
    case VideosActionTypes.RESET_VIDEOS_LIST:
      return Object.assign({}, state, {
        docs: [],
        hasNextPage: false,
        hasPrevPage: false,
        limit: 10,
        page: 1,
        pageShorts: 1,
        totalPagesShorts: 0,
        totalDocs: 0,
        totalPages: 0,
        _id: '',
        isVideoPaused: false,
        videoIndex: 0,
      });

    case VideosActionTypes.VIDEO_TOGGLE_STATUS:
      const docs = [...state.docs];
      docs[action.payload.index].status = action.payload.status;
      return Object.assign({}, state, {
        docs,
      });
    default:
      return state;
  }
};
export default videosReducer;
