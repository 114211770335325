import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { GetCMSAllVimeoLiveStream } from '../../handlers/live.handlers';
import { TagMatchAtVimeo } from '../../handlers/live.handlers';
const VimeoTitleDropdown = (props) => {
  const [docs, setDocs] = useState([]);

  const getLiveData = () => {
    GetCMSAllVimeoLiveStream()
      .then((response) => {
        setDocs(response.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getLiveData();
  }, []);
  const onChange = (event) => {
    const body = {
      matchId: props.match_id.toString(),
      event_id: event.target.value,
    };

    TagMatchAtVimeo(body).then((response) => {
      if (response.message == 'success') {
        alert('Event tagged succesfully.');
      }
    });
  };

  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      // value={props.vimeosReducer.selectedvimeo}
      onChange={onChange}
    >
      <option value="All">Select vimeo</option>
      {docs &&
        docs.map((vimeo) => (
          <>
            <option value={vimeo.event_id}>{vimeo.title}</option>
          </>
        ))}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    // vimeosReducer: state.vimeosReducer,
    // selectedvimeoFilter: state.vimeosReducer.selectedvimeoFilter,
  };
};

export default connect(mapStateToProps)(VimeoTitleDropdown);
