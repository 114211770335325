import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  DeleteTwitterContent,
  GetTwitterTagsForFilter,
  PostContentLimit,
} from "../../../handlers/twitter.handlers";
import { GetTrendingNewsforFilter, ToggleLocation } from "../../../handlers/twitter.handlers";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { dispatchTwitterMenuTxt } from "../../../redux/action-dispatchers/twitterNative.dispatchers";
import { dispatchSelectedSportsTagging } from "../../../redux/action-dispatchers/sports.dispatchers";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
import { Checkbox } from '@material-ui/core';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const TwitterTrendingNewsList = () => {
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setTwitterID] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [state, setState] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLimit, setPageLimit] = useState({})
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });
 

  const onInputChange = (value,city) => {
      let obj = pageLimit;
      obj[city] = parseInt(value); 
      setPageLimit(obj)
      setState(state+1);
    }

  const ContentLimit = (id) => {
    const body = {
      "entryId": id,
      "section": "twitter",
      "contentLimit": pageLimit[id]
    }
  PostContentLimit(body).then((res)=>{
    console.log(res)
    if (res.status == true){
      alert('Count Limit Updated Successfully');
    }
  })
  }

  const getTwitterDocs = () => {
    setIsLoading(true);
    const body = `section=twitter&page=${page}&searchKey=${searchKey}`
    GetTrendingNewsforFilter(body)
      .then((response) => {
        setIsLoading(false);
        setDocs(response?.data?.docs);
        setTotalPages(response?.data?.totalPages);
        let obj = {};
        response?.data?.docs.forEach(element => {
        obj[element.entryId] = element.twitter.contentLimit
        })
        setPageLimit(obj)
      })
      .catch((error) => error);
  };

  {/**Toggle Location checkbox */}

  const toggleLocation = (trends)=>{
    const body = {
      "entryId": trends?.entryId,
      "section" : "twitter",
      "isActive" : !(trends?.twitter?.isActive)
    }
    ToggleLocation(body)
    .then((response)=>{
      setIsLoading(false);
      getTwitterDocs();
    })
    .catch((error)=>error)

  }

  useEffect(() => {
    getTwitterDocs();
  }, [page, searchKey]);

  useEffect(() => {
    setPage(1);
  }, [searchKey]);

  useEffect(() => {
    getTwitterDocs();
    window.scrollTo(0, 0);
  }, []);

  const mHistory = useHistory();
  const onView = (tag) => {
    mHistory.push(`/searchTagTwitter/${tag}`);
  };
  const showModalForPublish = (id) => {
    let title = "Delete Twitter?";
    let message = "Are you sure to continue?";

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };
  const onClick1= () =>{
    dispatchSelectedSportsTagging({
      selectSports: "",
      selectedSportsTagging: "",
    });
  }
  const onClose = () => {
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };
  const onSuccess = () => {
    DeleteTwitterContent({ _id: id })
      .then((res) => {
        if (res) {
          // alert("Twitter Deleted Successfully");
          getTwitterDocs();
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => error);
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };

  const handleEdit = (id) => {
    mHistory.push(`/editTwitterList/${id}`);
  };
  const handlePagination = (event, value) => {
    setPage(value);
  };

  const handleBack = () => {
    mHistory.goBack();
  };
  
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 60 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                "bg-white"
              }
              style={{ height: "100%" }}
            >
              <div className="rounded-t mb-0 px-4 py-1 border-0">
                <div
                  className=" pb-4"
                  style={{
                    cursor: "pointer",
                    marginLeft: "-60px",
                    width: "140px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleBack}
                >
                  <ArrowBackIcon />
                </div>
                <div className="flex flex-wrap flex-col">
                  <div className="twitterList-button-container">
                  {/* <Button variant="contained" color="primary" onClick={onClick1}>
                        Create New Twitter Trend
                      </Button> */}
                    {/* <Link to="/createTwitterList">
                      <Button variant="contained" color="primary" onClick={onClick1}>
                        Create New Twitter Trend
                      </Button>
                    </Link> */}
                    <div
                    style={{
                      width: '30%',

                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <input
                      value={searchKey}
                      style={{
                        border: '2px solid #acb9ce',
                        borderRadius: '4px',
                        marginRight: '3%',
                        padding: '3px',
                        paddingLeft: '10px',
                        height: '37px',
                      }}
                      placeholder="Search Trends"
                      onChange={(e) => setSearchKey(e?.target?.value)}
                    />
                  </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "60px",
                    }}
                  >
                    {docs?.map((trends) => (
                      <div
                        className="relative flex flex-col min-w-0 w-full break-words
                    shadow-lg shadow-ug rounded twitterList-content"
                      >
                        <div className="twitterList-wrapper">
                          <div className="twitterList-container"
                            style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                            <h1
                              style={{ cursor: "pointer"}}
                              // onClick={() => {
                              //   onView(tags.tag);
                              //   // dispatchTwitterMenuTxt(tags.menuTxt);
                              // }}
                            >
                              {trends?.name}
                            </h1>
                          </div>
                          <div className="twitterList-list-button"
                          style={{}}>
                            <div style={{minWidth:'200px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <input
                            style={{width:'100px', marginRight:'10px', borderRadius:'5px'}}
                              type="number"
                              placeholder="Content Limit"
                              name="slug"
                              value = {pageLimit[trends?.entryId]}
                              onChange={($event)=>  {onInputChange($event.target.value, trends?.entryId)}}
                            />
                            <div style={{height:'35px',cursor:'pointer',borderRadius:'5px', background:'rgb(14, 165, 233)'}}
                              onClick = {()=>{ContentLimit(trends?.entryId)}}>
                              <span style={{padding:'5px', height:'100%',display:'flex', justifyContent:'center',flexDirection:'column'}}>Submit</span>
                            </div>
                          </div>
                          <Checkbox
                              style={{cursor:'pointer'}}
                              checked={
                                trends?.twitter?.isActive 
                              }
                              onChange={(event) => {
                                toggleLocation(trends)
                              }}
                              color="primary"
                              inputProps={{
                                'aria-label': 'checkbox with primary color',
                              }}
                            />

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: 20,
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  page={page}
                  onChange={handlePagination}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
              {/* console.log */}
            </div>
                  <div style={{ textAlign: "center", padding: 10 }}>
                    <SyncLoader
                      color="#23A5E0"
                      loading={isLoading}
                      css={override}
                      size={12}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ textAlign: 'center', padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoading}
              css={override}
              size={12}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TwitterTrendingNewsList;
