import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  GetPaginatedNews,
  ChangeNewsStatus,
  ChangeNewsUpdate,
} from "../../handlers/news.handlers";
import TableDropdown from "../Dropdowns/TableDropdown.js";
import { connect } from "react-redux";
import {
  dispatchCurrnetNewsPage,
  dispatchNewsList,
  dispatchNewsListFilter,
  dispatchNumberOfNewsPages,
  dispatchToggleNewsStatus,
} from "../../redux/action-dispatchers/news.dispatchers";
import { GetTaggedLocation } from '../../handlers/twitter.handlers' // Trending Twitter Location
import Axios from "axios";
import { API_BASE_PATH, OLD_BASE_PATH } from "../../constants/workerConstants";
import NewsTypeDropDown from "../Dropdowns/NewsTypeDropDown";
import LeaguesDropDown from "../Dropdowns/LeaguesDropDown";
import FilteredLeagueDropDown from "../Dropdowns/FilteredLeagueDropDown";
import SportsDropDown from "../Dropdowns/SportsDropDown.js";
import { useHistory } from "react-router";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import moment from "moment";
// import { _getDateTime, _dateTimeFormat } from '../../constants/appDefaults';
import {
  dispatchAllLeagues,
  dispatchSelectedLeague,
} from "../../redux/action-dispatchers/leagues.dispatchers";
import { dispatchSelectedSports } from "../../redux/action-dispatchers/sports.dispatchers.js";
import ArticleStatusDropDown from "../Dropdowns/ArticleStatusDropdown";
import AspectRatio from "react-aspect-ratio";

import { _getDateTime, _dateTimeFormat, _dateTimeFormatinIST } from "../../constants/appDefaults";
import { mapArticleToLeague } from "../../handlers/news.handlers";
import SyncLoader from "react-spinners/SyncLoader";
import { logoutUser } from "../../services/auth";
import LoginAppConstants from "../../constants/LoginAppConstants";
import { css } from "@emotion/core";
import "../../pages/Dashboard/LeagueManagement/LeagueManage.css"
import { Button, Tooltip, Overlay } from "react-bootstrap";
{/**Modal Css */ }
import TrendsAllLocations from './TrendsAllLocations';
import Fab from "@mui/material/Fab";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const NewsTable = (props) => {
  const mHistory = useHistory();
  const [newsType, setNewsType] = useState(localStorage.getItem("newsTypeDropdown")??"");
  const [startDate, setStartDate] = useState(localStorage.getItem("newsFilterStartDate")??null);
  const [endDate, setEndDate] = useState(localStorage.getItem("newsFilterEndDate")??new Date());
  const [newsStatusType, setNewsStatusType] = useState(localStorage.getItem("newsStatusType")??'All');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getsports, setGetSports] = useState(localStorage.getItem("newsSportsFilter")??"");
  const [leagueFilter, setLeagueFilter] = useState(localStorage.getItem("newsLeagueFilter")??"")
  const [newsId, setNewsId] = useState("");
  const [docs, setDocs] = React.useState([]);
  const [open, setOpen] = useState(false); // for Modal Open State
  const [twitterTaggedLocations, setTwitterTaggedLocations] = useState([]) // All trending places tagged from Trends api
  const [language, setLanguage] = useState(localStorage.getItem('Current_Language'))
  const [activeLocations, setActiveLocation] = useState([]);
  const [showFeatureEdit, showEditFeatureHandler] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [togglingStatus, setTogglingStatus] = useState({
    index: 0,
    loading: false,
  });
  {/**Modal open and close */ }
  const handleOpen = () => {
    setOpen(true);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const [searchString, setSearchString] = useState(localStorage.getItem("newsSearchFilter")??"");
  let searchStringGlobal = "";
  let timer = null;
  useEffect(() => {
    dispatchSelectedLeague({
      selectedLeague: localStorage.getItem("newsLeagueFilter"),
      selectedLeagueFilter: localStorage.getItem("newsLeagueFilter"),
    });
  }, []);

  useEffect(() => {
    if (typeof window != undefined) {
      setLanguage(localStorage.getItem('Current_Language'))
    }
  }, [typeof window])

  // Tagged Locations from twitter Trending Section
  useEffect(() => {
    const body = 'section=twitter'
    if (open) {
      GetTaggedLocation(body)
        .then((response) => {
          setTwitterTaggedLocations(response?.data)
        })
        .catch((error) => error)
    }
  }, [open])

  useEffect(() => {
    dispatchSelectedSports({
      selectedSports: localStorage.getItem("newsSportsFilter"),
      selectedSportsFilter: localStorage.getItem("newsSportsFilter"),
    });
  }, []);
  const updateStartDate = (time) => {
    setStartDate(time);
    localStorage.setItem("newsFilterStartDate", time);
  };
  const updateEndDate = (time) => {
    setEndDate(time);
    localStorage.setItem("newsFilterEndDate", time);
  };


  // Toggle news status
  const toggleNewsStatus = (index, newsId, status) => {
    setTogglingStatus({
      index,
      loading: true,
    });
    ChangeNewsStatus(newsId, status, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setTogglingStatus({
          index: -1,
          loading: false,
        });
        if (response.status) {
          dispatchToggleNewsStatus({ index, status });
        } else {
          alert(JSON.stringify(response.message));
        }
      })
      .catch((error) => {
        setTogglingStatus({
          index: -1,
          loading: false,
        });
        alert(JSON.stringify(error));
      });
  };

  useEffect(() => {
    Axios.get(`${OLD_BASE_PATH}teams/getAllTeams`, {
      headers: { "cms-access-token": accessToken, source: "cms" },
    })
      .then((res) => setDocs(res.data.result.data))
      .catch((error) => error);
  }, []);
  useEffect(() => {
    getNews(
      props.page,
      parseInt(props.limit),
      leagueFilter,
      getsports,
      newsType,
      newsStatusType,
      startDate,
      endDate,
      searchString,
      false
    );
  }, [
    props.page,
    props.selectedSports,
    props.selectedLeague,
    newsType,
    newsStatusType,
    startDate,
    endDate,
    searchString,
  ]);
  useEffect(() => {
    Axios.get(
      `${API_BASE_PATH}leagues/leagueWatchList`,
      { headers: { "cms-access-token": accessToken, source: "cms" } },
      {
        params: JSON.stringify({
          username: localStorage.getItem("email"),
        }),
      }
    ).then((res) => {
      if (res.data.message == "Error in authorization middleware") {
        alert(`${res.data.message} in League watch list`);
      } else dispatchAllLeagues(res.data.data?.data);
    });
  }, []);
  const leagueName = (id) => {
    const leagueInfo = props.leagues?.find((item) => item.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return "N/A";
  };
  const onSportsTypeChange = (value) => {
    setGetSports(value);
    setLeagueFilter("");
    localStorage.setItem("newsSportsFilter", value);
    localStorage.removeItem('newsLeagueFilter')
  };

  const getNews = (
    _page,
    _limit,
    _selectedLeague,
    _selectedSports,
    _newsStatusType,
    _newsType,
    startDate,
    endDate,
    _searchKey,
    isLoadMore,
    keyword
  ) => {
    setIsLoading(true);
    // if (endDate < startDate) {
    //   setEndDate(null);
    //   setStartDate(null);
    //   // alert("Start Date should be less than end date");
    // }
    let body = {
      page: props.page,
      limit: parseInt(props.limit),
      copyRight: newsType,
      league: leagueFilter,
      sportId: getsports,
      startDate: startDate,
      endDate: endDate,
      searchKey: searchString,
    }
    if(newsStatusType != 'All'){
      body = {
        ...body,
        showArticle: newsStatusType,
      }
    }
    GetPaginatedNews(body)
      .then((response) => {
        setIsLoading(false);
        // if (response.statusCode == 401) {
        //   alert('Token Expired please log in again!!!');
        //   logoutUser();
        //   mHistory.push('/');
        // }
        // else if (response.message == 'Request failed with status code 403') {
        //   window.alert(
        //     `You don't have permission to perform this action in this module`
        //   );
        // }
        // else
        if (response.status) {
          dispatchNumberOfNewsPages(response.data.totalPages);
          if (isLoadMore) {
            dispatchNewsList(response.data);
          } else {
            dispatchNewsListFilter(response.data);
          }
        }
      })

      .catch((error) => {
        // console.log('error 401 catch', error);

        isLoadMore && setIsLoadingMore(false);
      });
  };

  const handleFeaturedStatus = (value, id) => {
    setKeyword(value);
    setNewsId(id);
    showEditFeatureHandler(true);

  };
  const onLeagueChange = (value) =>{
    localStorage.setItem("newsLeagueFilter", value);
    setLeagueFilter(value);
  }
  const onView = (newsId) => {
    mHistory.push(`/news/${newsId}`);
  };
  const visitPermaLink = (permaLink) => {
    mHistory.push(permaLink);
  };

  const onNewsTypeChange = (event) => {
    setNewsType(event.target.value);
    localStorage.setItem("newsTypeDropdown", event.target.value);
  };
  // const onSportsTypeChange = (value) => {
  //   setGetSports(value);
  // };
  const handlePagination = (event, value) => {
    dispatchCurrnetNewsPage(value);
  };
  const handleInputChange = (event) => {
    setSearchString(event.target.value);
    localStorage.setItem("newsSearchFilter", event.target.value)
  };
  useEffect(() => {
    searchedData();
  }, [searchString, newsType]);
  const updateKeyword = () => {
    const body = {
      _id: newsId,
      slug: keyword,
      keyword: keyword,
    };
    if (keyword == undefined || body?.keyword?.length == 0) {
      alert("keywords cannot be empty");
      return;
    }
    ChangeNewsUpdate(body)
      .then((response) => {
        if (response.status) {
          alert("keywords updated");
          window.location.reload(false);
        } else {
          alert("Sorry your Keyword is not updated");
        }
      })
      .catch((error) => error);
  };
  const searchedData = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      searchStringGlobal = searchString;

      getNews(
        props.page,
        parseInt(props.limit),
        props.selectedLeague,
        props.selectedSports,
        newsType,
        newsStatusType,
        startDate,
        endDate,
        searchStringGlobal,
        false
      );
    }, 500);
  };
  // useEffect(() => {
  //   const search = localStorage.getItem("searchString");
  //   if (search) {
  //     setSearchString(JSON.parse(search));
  //   }
  // }, []);
  // useEffect(() => {
  //   localStorage.setItem("searchString", JSON.stringify(searchString));
  // }, [searchString]);
  // News type dropdown local
  // useEffect(() => {
  //   const newsTypeDropdown = localStorage.getItem("newsTypeDropdown");
  //   if (newsTypeDropdown) {
  //     setNewsType(JSON.parse(newsTypeDropdown));
  //   }
  // }, []);
  // useEffect(() => {
  //   localStorage.setItem("newsTypeDropdown", newsType);
  // }, [newsType]);
  function CopyButton({ row }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleClick = async () => {
      if (row?.permalink) {
        if ("clipboard" in navigator) {
          await navigator.clipboard.writeText(row.permalink);
          setShow(true);
          setTimeout(() => setShow(false), 500); // Hide the tooltip after 2 seconds
        }
      }
    };

    //to retain league 
   
    return (
      <>
        <Button
          ref={target}
          onClick={handleClick}
          disabled={!row?.permalink}
          title="Click to copy permalink"
          style={{ outline: "none" }}
          className={`btn btn-icon btn-light btn-sm mx-3`}
        >
          <img
            src="/copy-1.png"
            alt="copy to clipboard"
            style={{ width: "25px", height: "25px", marginLeft: "10px" }}
          />
        </Button>

        {row.showArticle&&<Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Copied!
            </Tooltip>
          )}
        </Overlay>}
      </>
    );
  }
  // News type dropdown end
  return (
    <>{console.log(newsType)}
      <div className="edit__popup" style={{ zIndex: "100" }}>
        <div
          onClick={() => showEditFeatureHandler(false)}
          className={showFeatureEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showFeatureEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '65%', height: "50%" }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                showEditFeatureHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            Upload Keywords:{' '}

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px" }}>


              <div>
                <div className="py-2 px-2">
                  <textarea
                    name="keyword"
                    // onPaste={(e) => {
                    //   e.preventDefault();
                    //   alert("Copy Paste is not Allowed!!!!");
                    //   return false;
                    // }}
                    rows="2"
                    cols="80"
                    id="textbox"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>{" "}
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px" }}
                  >
                    <div
                      onClick={() => updateKeyword()}
                      className={"save btn"}
                    >
                      Update
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div style={{ position: 'fixed', bottom: '30px', right: '20px' }}>
              <Fab variant="extended" size="medium" color="primary" aria-label="add"
                onClick={() => setOpen(true)}
                style={{ marginRight: 5 }}>
                Trending Keywords
              </Fab>
            </div>

          </div>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
        style={{ display: open ? 'none' : 'block', }}
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (props.color === "light" ? "text-blueGray-700" : "text-white")
                }
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                News
                <div>
                  <span style={{ marginLeft: 20 }}>
                    <NewsTypeDropDown
                      selectedValue={newsType}
                      onChange={onNewsTypeChange}
                    />
                  </span>
                  <span style={{ marginLeft: 20 }}>
                    <SportsDropDown
                      selectedValue={getsports}
                      onChange={onSportsTypeChange}
                    />
                  </span>

                  <span style={{ marginLeft: 4 }}>
                    <FilteredLeagueDropDown sports={getsports} onChange={onLeagueChange}/>
                  </span>
                  <span style={{ marginLeft: 4 }}>
                    <ArticleStatusDropDown
                      value={newsStatusType}
                      onChange={(event) =>{
                        setNewsStatusType(event.target.value);
                        localStorage.setItem("newsStatusType", event.target.value);
                      }
                      }
                    />
                  </span>
                </div>
              </h3>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                position: "relative",
                left: "3.4%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <input
                  value={searchString}
                  style={{
                    border: "2px solid #acb9ce",
                    borderRadius: 10,
                    marginRight: "3%",
                    padding: "3px",
                    // paddingLeft: '10px',
                  }}
                  placeholder="Search News"
                  onChange={handleInputChange}
                />
              </div>
              {/* <DateFilter startDate={startDate} endDate={endDate} /> */}
              <div className="wrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="relative ">
                    <DateTimePicker
                      label="Start Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={startDate} 
                      onChange={updateStartDate}
                      style={{
                        marginRight: "10px",
                        width: "50%",
                      }}
                    />
                    <DateTimePicker
                      label="End Date"
                      // emptyLabel="End Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={endDate}
                      onChange={(e) => updateEndDate(e)}
                      style={{
                        width: "40%",
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>

             <Button style={{marginRight:"50px", minWidth:"fit-content", background:"#1876d1", padding:"10px", borderRadius:"10px", color:"#FFFFFF"}} onClick={()=>{
              setNewsType(""); 
              localStorage.removeItem("newsTypeDropdown")
              setGetSports("");
              dispatchSelectedSports({
                selectedSports: "",
                selectedSportsFilter: "",
              });
              dispatchSelectedLeague({
                     selectedLeague: "",
                     selectedLeagueFilter: "",
                  });
                  setNewsStatusType("All");
                  localStorage.removeItem("newsStatusType");
                  setSearchString("");
                  setStartDate(null);
                  setEndDate(new Date());
                  localStorage.removeItem("newsFilterEndDate");
                  localStorage.removeItem("newsFilterStartDate");
                  localStorage.removeItem("newsSportsFilter");
                  localStorage.removeItem("newsLeagueFilter");
                  localStorage.removeItem("newsSearchFilter");
                  setSearchString("");
                  setLeagueFilter("");
             }}>
              Reset Filter
             </Button>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Thumb Image
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Keywords
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Headline
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Perma Link
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Sports
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Teams
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Type
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  League
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Published Date
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Update At
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Home
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Top
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>

            <tbody>
              {!isLoading ? (
                GetPaginatedNews
              ) : (
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
              )}

              {props.docs.map((news, position) => (
                <tr>
                  <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {/* <AspectRatio
                      ratio={16 / 9}
                      style={{
                        background : `url('${news.imgUrl}')`,
                        width:'450px',
                        height:'253px',
                        backgroundSize : 'cover',
                        backgroundRepeat :'no-repeat'
                      }}
                    > */}
                    {/* <img
                        src={news.imgUrl}
                        className="h-12 w-12 bg-white"
                        alt="..."
                      ></img> */}
                    {/* </AspectRatio> */}

                    <div
                      style={{
                        background: `url('${news.imgUrl}?${news._id}') 0% 0% / cover no-repeat`,
                        width: "100px",
                        height: "53px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      key={position}
                    ></div>
                  </th>
                  <td
                    className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    onClick={() =>
                      handleFeaturedStatus(news?.keyword, news?._id)
                    }
                    style={{ cursor: "pointer", fontWeight: "bolder", textAlign: "center" }}
                  >
                    <i
                      className="fas fa-edit fa-1x"
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        color: "black",
                        cursor: "pointer",
                        marginLeft: "6px",
                      }}
                    // onClick={() => {
                    //   setChangeLeague(true);
                    //   getAllTaggedLeague();
                    //   dispatchSelectedLeagueTagging({
                    //     selectedLeagueTag: newsInfo.league,
                    //     selectedLeagueFilterTag: newsInfo.league,
                    //   });
                    // }}
                    ></i>
                  </td>
                  <td
                    className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    onClick={() => onView(news._id)}
                    style={{ cursor: "pointer", fontWeight: "bolder" }}
                  >
                    {news.headline}
                  </td>
                  <td
                    className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    onClick={() => {
                      if (!news.showArticle) {

                        alert("Article is an unpublished one...")
                      }

                    }}
                    style={{ cursor: "pointer", fontWeight: "bolder" }}
                  >
                    <div style={{ display: 'flex' }}>

                      <a href={news?.showArticle?news?.permalink:"#"}
                            target={news?.showArticle?"_blank":"_self"}>
                        <img src="/LinkIcon.png" style={{width:"25px", height:"25px", marginLeft:"10px"}}/>
                      </a>
                      <CopyButton row={news} />
                    </div>
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.sports && news.sports.name ? news.sports.name : "N/A"}
                  </td>
                  <td className="border border-green-800 border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.teams &&
                      news.teams
                        .map((items) => {
                          return items.name;
                        })
                        .join(", ")
                      ? news.teams
                        .map((items) => {
                          return items.name;
                        })
                        .join(", ")
                      : "N/A"}
                  </td>
                  {/* {docs &&
                    docs.map((team) => (
                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {teamd && teamd.name ? team.name : 'N/A'}
                      </td>
                    ))} */}

                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.copyrights}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {props.selectedLeague == "All"
                      ? "All"
                      : leagueName(news.league)}
                    {news.AllLeague !== undefined && " / " + news.AllLeague}
                    {/* {news.league ? news.league : 'N/A'} */}
                  </td>
                  {/* news publish time */}
                  <td className="border border-green-800  border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {language?.toLocaleLowerCase() == 'english'
                      ?
                      _dateTimeFormatinIST(
                        news?.PublishedAt,
                        'ddd, DD MMM hh:mm A'
                      )
                      :
                      _dateTimeFormat(
                        news?.PublishedAt,
                        'ddd, DD MMM hh:mm A'
                      )}
                    {/* {news.copyrights === 'PTI'
                      ? props.selectedLanguage === 'english'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''}
                    {news.copyrights === 'Opta'
                      ? props.selectedLanguage === 'english'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''}
                    {news.copyrights === 'PTI_Bhasha'
                      ? props.selectedLanguage === 'hindi'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''} */}
                    {/* {news.copyrights === 'PTI'
                      ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                    {/* {news.copyrights === 'PTI_Bhasha'
                      ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                  </td>
                  <td className="border border-green-800  border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {language?.toLocaleLowerCase() == 'english'
                      ?
                      _dateTimeFormatinIST(
                        news?.updatedAt,
                        'ddd, DD MMM hh:mm A'
                      )
                      :
                      _dateTimeFormat(
                        news?.updatedAt,
                        'ddd, DD MMM hh:mm A'
                      )}
                    {/* {news.copyrights === 'PTI'
                      ? props.selectedLanguage === 'english'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''}
                    {news.copyrights === 'Opta'
                      ? props.selectedLanguage === 'english'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''}
                    {news.copyrights === 'PTI_Bhasha'
                      ? props.selectedLanguage === 'hindi'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''} */}
                    {/* {news.copyrights === 'PTI'
                      ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                    {/* {news.copyrights === 'PTI_Bhasha'
                      ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                  </td>
                  <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.showArticle ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Published
                      </span>
                    ) : (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Draft
                      </span>
                    )}
                  </td>
                  <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.tagHome ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Tagged
                      </span>
                    ) : (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Untagged
                      </span>
                    )}
                  </td>
                  <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.section == "TOP_NEWS" ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Tagged
                      </span>
                    ) : (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Untagged
                      </span>
                    )}
                  </td>

                  {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    {news.status === 1 ? (
                      <button
                        className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        onClick={() => {
                          toggleNewsStatus(position, news._id, 0);
                          // onView(video._id)
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        onClick={() => toggleNewsStatus(position, news._id, 1)}
                      >
                        Unhide
                      </button>
                    )}
                  </td> */}
                  {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <TableDropdown showArticle={news.showArticle} newsId={news._id} onView={onView} />
                    <button
                      className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      onClick={() => onView(news._id)}
                    >
                      Manage
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          {/* {props.hasNextPage && !isLoadingMore ? (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  getNews(
                    parseInt(props.page) + 1,
                    parseInt(props.limit),
                    props.selectedLeague,
                    props.selectedSports,
                    newsType,
                    newsStatusType,
                    startDate,
                    endDate,
                    searchString,
                    true
                  );
                }}
              >
                Load More
              </button>
            </div>
          ) : (
            <></>
          )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={props.page}
                onChange={handlePagination}
                count={props.totalPages}
                variant="outlined"
                color="primary"
              />
            </Stack>
            {/* console.log */}
          </div>
          <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>

      </div>
      <div style={{ position: 'fixed', bottom: '30px', right: '20px' }}>
        <Fab variant="extended" size="medium" color="primary" aria-label="add"
          onClick={() => setOpen(true)}
          style={{ marginRight: 5 }}>
          Trending Keywords
        </Fab>
      </div>
      <>
        <div className="edit__popup" style={{ display: open ? 'block' : 'none', }}>
          <div
            onClick={() => setOpen(false)}
            className={
              open ? "edit__backdrop active" : "edit__backdrop"
            }
          ></div>

          <div
            className={
              open ? "edit__popup-body active" : "edit__popup-body"
            }
            style={{
              zIndex: 1000, maxWidth: '1000px', width: '1000px', height: '80%',
              position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="edit__popup-heading">Top Trends</div>
              <div className="edit__popup-inner"
                style={{ marginTop: '7px' }}>
                <i
                  onClick={() => setOpen(false)}
                  className="far fa-times-circle edit__popup-close"
                ></i>

              </div>
            </div>
            <TrendsAllLocations twitterTaggedLocations={twitterTaggedLocations} />
          </div>
        </div>
      </>
    </>
  );
};

NewsTable.defaultProps = {
  color: "light",
};

NewsTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
const mapStateToProps = (state) => {
  return {
    totalPages: state.newsReducer.totalPages,
    docs: state.newsReducer.docs,
    page: state.newsReducer.page,
    limit: state.newsReducer.limit,
    leagues: state.leaguesReducer.leagues,
    hasNextPage: state.newsReducer.hasNextPage,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
    selectedSports: state.sportsReducer.selectedSports,
  };
};
export default connect(mapStateToProps)(NewsTable);