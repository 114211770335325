import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import { Route, Switch } from 'react-router';
import News from './News';

const Dashboard = (props) => {
    return (
        <>
            <Sidebar />
            <div className="bg-blueGray-100">
                <div className="flex flex-wrap">
                </div>
            </div>
        </>
    );
};

export default Dashboard;