import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Accordion from '../../../components/Accordion';
import LeaguesDropDown from '../../../components/Dropdowns/LeaguesDropDown';
import Sidebar from '../../../components/Sidebar/Sidebar';
import subscribeSocketMatchId from '../../../handlers/socket.handlers';
import HomeOptaGraphic from '../../../components/Accordion/OptaGraphicItem/HomeOptaGraphics';
import {
  GetAllMatches,
  GetHomeGraphics,
  GetLeagueSchedules,
  GetUpcomingSchedules,
} from '../../../handlers/leagues.handlers';
import {
  dispatchAllSchedules,
  dispatchPutResultSchedulesPagination,
  dispatchUpcomingSchedules,
} from '../../../redux/action-dispatchers/schedules.dispatchers';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { _dateTimeFormat } from '../../../constants/appDefaults';
import { GetPaginatedInsights } from '../../../handlers/optaGraphics.handlers';
// import AllOptaGraphics from '../../../components/Accordion/OptaGraphicItem/AllOptaGraphics';
import AllOptaInsights from '../../../components/Accordion/OptaGraphicItem/AllOptaInsights';
import { dispatchSelectedSports } from '../../../redux/action-dispatchers/sports.dispatchers';
import { dispatchSelectedLeague } from '../../../redux/action-dispatchers/leagues.dispatchers';

const OptaGraphics = (props) => {
  const [openTab, setOpenTab] = React.useState(1);
  const [isOpen, setIsOpen] = React.useState(false);

  const [graphicsData, setGraphicsData] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState();
  const [prevDate, setPrevDate] = React.useState();
  const [nexDate, setNexDate] = React.useState();
  let fileInput = React.useRef();
  useEffect(() => {
    dispatchAllSchedules([]);
    // getSchedules();
  }, [props.selectedLeague, openTab]);

  useEffect(() => {
    // GetHomeGraphics()
    GetPaginatedInsights(1, 10)
      .then((response) => setGraphicsData(response.data))
      .catch((error) => error);
  }, []);

  const getSchedules = () => {
    // if (openTab != 1) {
    //   getResultSchedules();
    // }
    //  else if (openTab === 3) {
    //   // getAllLiveScores();
    // } else if (openTab === 4) {
    //   getUpcomingSchedules();
    // }
  };
  const getInsightUpdatedData = () => {};
  function loadMore() {
    let prev = new Date(nexDate);
    prev.setDate(nexDate.getDate() + 1);
    let next = new Date(nexDate);
    next.setDate(nexDate.getDate() + 7);
    setNexDate(next);
    const payload = {
      comp_id: props.selectedLeague,
      page: props.page,
      prevDate: _dateTimeFormat(prev, 'YYYY-MM-DD hh:mm:ss'), //"2021-09-03 14:00:00",
      nextDate: _dateTimeFormat(next, 'YYYY-MM-DD hh:mm:ss'),
    };
    GetAllMatches(payload)
      .then((response) => {
        // console.log("responseresponseresponse", response);
        if (
          response.status &&
          typeof response.data === 'object' &&
          response.data !== null
        ) {
          response.data.matches &&
            response.data.matches.docs &&
            dispatchPutResultSchedulesPagination(response.data.matches.docs);
          // setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }

  const getResultSchedules = () => {
    let previousDate = new Date();
    previousDate.setDate(previousDate.getDate() - 15);
    let nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 15);
    setPrevDate(previousDate);
    setNexDate(nextDate);
    const payload = {
      comp_id: props.selectedLeague ? props.selectedLeague : 'All',
      nextDate: _dateTimeFormat(nextDate, 'YYYY-MM-DD hh:mm:ss'),
      page: 1,
      prevDate: _dateTimeFormat(previousDate, 'YYYY-MM-DD hh:mm:ss'),
    };

    GetAllMatches(payload).then((response) => {
      if (response.status === 200) {
        // console.log(response.data);
        dispatchAllSchedules(response.data.data.matches.docs);
      }
    });
  };

  const getUpcomingSchedules = () => {
    GetUpcomingSchedules(
      props.selectedLeague,
      props.schedulesReducer.upcomingSchedules.page + 1,
      {
        Authorization: props.Authorization,
      }
    ).then((response) => {
      if (response.status === 200) {
        dispatchUpcomingSchedules(response.data.result);
      }
    });
  };
  //   const getAllLiveScores = () => {
  //     GetAllLiveMatches(props.selectedLeague).then((response) => {
  //       if (response.status === 200) {

  //         //dispatchLiveSchedules(response.data.result);
  //       }
  //     });
  //   };
  // const loadMore = () => {
  //   getSchedules();
  // };

  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white'
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3 className={'font-semibold text-lg text-blueGray-700'}>
                      Opta Graphics
                    </h3>
                  </div>
                  <div
                    className="relative w-full px-8 max-w-full flex-grow flex-1"
                    style={{ textAlign: 'right' }}
                  >
                    <span style={{ marginLeft: 20 }}>
                      {/* <LeaguesDropDown /> */}
                      <Link to="/upload-graphics">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginBottom: '1.5%' }}
                          onClick={() => {
                            dispatchSelectedSports({
                              selectedSports: 'All',
                              selectedSportsFilter: 'All',
                            });
                            dispatchSelectedLeague({
                              selectedLeague: 'All',
                              selectedLeagueFilter: 'All',
                            });
                          }}
                        >
                          Add Insight
                        </Button>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <ul
                  className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                  role="tablist"
                >
                  <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                        (openTab === 1
                          ? 'text-white bg-lightBlue-600'
                          : 'text-lightBlue-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle="tab"
                      href="#link1"
                      role="tablist"
                    >
                      All
                    </a>
                  </li>

                  <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                        (openTab === 2
                          ? 'text-white bg-lightBlue-600'
                          : 'text-lightBlue-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                    >
                      Home
                    </a>
                  </li>
                  {/* <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                        (openTab === 3
                          ? 'text-white bg-lightBlue-600'
                          : 'text-lightBlue-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      Buzz
                    </a>
                  </li> */}
                  {/* <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                    <a
                      className={
                        "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                        (openTab === 4
                          ? "text-white bg-lightBlue-600"
                          : "text-lightBlue-600 bg-white")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(4);
                      }}
                      data-toggle="tab"
                      href="#link4"
                      role="tablist"
                    >
                      Upcoming
                    </a>
                  </li> */}
                </ul>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                  <div className="px-4 py-5 flex-auto">
                    <div className="tab-content tab-space">
                      <div
                        className={openTab === 1 ? 'block' : 'hidden'}
                        id="link1"
                      >
                        <div
                          className="block w-full overflow-x-auto"
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          {/* {graphicsData &&
                            graphicsData.data.map((graphics) => {
                              <HomeOptaGraphic id={graphics._id} />;
                            })} */}
                          <AllOptaInsights openTab={openTab} />
                        </div>
                      </div>
                      <div
                        className={openTab === 2 ? 'block' : 'hidden'}
                        id="link2"
                      >
                        <div
                          className="block w-full overflow-x-auto"
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          {/* <Accordion
                            activeTab={openTab}
                            data={props.scheduleData}
                            LoadMore={loadMore}
                          /> */}
                          <HomeOptaGraphic openTab={openTab} />
                        </div>
                      </div>
                      <div
                        className={openTab === 3 ? 'block' : 'hidden'}
                        id="link3"
                      >
                        <div className="block w-full overflow-x-auto">
                          {/* <Accordion
                            activeTab={openTab}
                            data={props.scheduleData}
                            LoadMore={loadMore}
                          /> */}
                        </div>
                      </div>
                      {/* <div
                        className={openTab === 4 ? "block" : "hidden"}
                        id="link4"
                      >
                        <div
                          className="block w-full overflow-x-auto"
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                        >
                          <Accordion
                            activeTab={openTab}
                            data={props.schedulesReducer.upcomingSchedules}
                            LoadMore={loadMore}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    docs: state.newsReducer.docs,
    page: state.newsReducer.page,
    limit: state.newsReducer.limit,
    schedulesReducer: state.schedulesReducer,
    scheduleData: state.schedulesReducer.scheduleData,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
  };
};
export default connect(mapStateToProps)(OptaGraphics);
