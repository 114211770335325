// import React from 'react';
// import { SketchPicker } from 'react-color';
// import { connect } from 'react-redux';
// import reactCSS from 'reactcss';
// import { dispatchHexColor } from '../../redux/action-dispatchers/colorPicker.dispatchers';

// class ColorPicker extends React.Component {
//   state = {
//     showPicker: false,
//     color: {
//       r: this.props.colorPickerReducer.r,
//       g: this.props.colorPickerReducer.g,
//       b: this.props.colorPickerReducer.b,
//       a: this.props.colorPickerReducer.a,
//     },
//     // color: { hex: "74F2A1" },
//   };

//   onClick = () => {
//     this.setState({
//       showPicker: !this.state.showPicker,
//     });
//   };
//   onClose = () => {
//     this.setState({
//       showPicker: false,
//     });
//   };

//   onChange = (color) => {
//     dispatchHexColor(color.hex);
//     this.setState({
//       color: color.rgb,
//     });
//   };

//   render() {

//     console.log('props', this.props);

//     return (
//       <div>
//         <div style={styles.swatch} onClick={this.onClick}>
//           <div style={styles.color} />
//         </div>
//         {this.state.showPicker ? (
//           <div style={styles.popover}>
//             <div style={styles.cover} onClick={this.onClose} />
//             <SketchPicker color={this.state.color} onChange={this.onChange} />
//           </div>
//         ) : null}
//       </div>
//     );
//   }
// }
// const mapStateToProps = (state) => {
//   return {
//     r: state.colorPickerReducer.r,
//     g: state.colorPickerReducer.g,
//     b: state.colorPickerReducer.b,
//     a: state.colorPickerReducer.a,
//   };
// };
// export default connect(mapStateToProps)(ColorPicker);

import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";
import reactCSS from "reactcss";
import { dispatchHexColor } from "../../redux/action-dispatchers/colorPicker.dispatchers";

const ColorPicker = (props) => {
  const [color, setColor] = useState({
    // r: '116',
    // g: '242',
    // b: '161',
    // a: "100",
    r: props.r,
    g: props.g,
    b: props.b,
    a: props.a,
  });
  const [showPicker, setShowPicker] = useState(false);
  useEffect(() => {
    setColor({
      r: props.r,
      g: props.g,
      b: props.b,
      a: props.a,
    });
  }, [props.r]);
  const styles = reactCSS({
    default: {
      color: {
        width: "80px",
        height: "35px",
        borderRadius: "3px",
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      popover: {
        position: "absolute",
        zIndex: "3",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
      swatch: {
        padding: "6px",
        background: "#ffffff",
        borderRadius: "2px",
        cursor: "pointer",
        display: "inline-block",
        boxShadow: "0 0 0 1px rgba(0,0,0,.2)",
      },
    },
  });
  const onClick = () => {
    setShowPicker(!showPicker);
  };
  const onClose = () => {
    setShowPicker(false);
  };

  const onChange = (color) => {
    dispatchHexColor(color.hex);
    // setColor({
    //   color: color.rgb,
    // });
    setColor({
      r: color.rgb.r,
      g: color.rgb.g,
      b: color.rgb.b,
      a: color.rgb.a,
    });
    props.onChange && props.onChange(color);
  };

  return (
    <>
      <div>
        <div style={styles.swatch} onClick={onClick}>
          <div style={styles.color} />
        </div>
        {showPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={onClose} />
            <SketchPicker color={color} onChange={onChange} />
          </div>
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    colorPickerReducer: state.colorPickerReducer,
    r: state.colorPickerReducer.r,
    g: state.colorPickerReducer.g,
    b: state.colorPickerReducer.b,
    a: state.colorPickerReducer.a,
  };
};
export default connect(mapStateToProps)(ColorPicker);
