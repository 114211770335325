import { AuthActionTypes } from '../action-types';

const initialState = {
  isLoggedIn: false,
  authorizationToken: undefined,
  userType: null,
  userData: undefined,
  userProfilePic: undefined,
  languagesAllowed: undefined,
  currentLanguage: undefined,
  userRole: undefined,
  modulePermission: undefined,
  firstModuleName: undefined,
  userLanguage: undefined,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return Object.assign({}, state, action.payload);
    case AuthActionTypes.LOGOUT:
      return Object.assign({}, state, {
        isLoggedIn: false,
        authorizationToken: undefined,
        userType: null,
        userData: undefined,
        userProfilePic: undefined,
        languagesAllowed: undefined,
        currentLanguage: undefined,
        userRole: undefined,
        modulePermission: undefined,
        firstModuleName: undefined,
        userLanguage: undefined,
      });
    case AuthActionTypes.PROFILE_IMAGE:
      return Object.assign({}, state, action.payload);
    case AuthActionTypes.FIRST_MODULE_NAME:
      return Object.assign({}, state, action.payload);
    case AuthActionTypes.LANGUAGES_ALLOWED:
      return Object.assign({}, state, { languagesAllowed: action.payload });
    case AuthActionTypes.CURRENT_LANGUAGE:
      return Object.assign({}, state, { currentLanguage: action.payload });
    case AuthActionTypes.GET_USER_ROLE:
      return Object.assign({}, state, { userRole: action.payload });
    case AuthActionTypes.GET_USER_MODULE:
      return Object.assign({}, state, { modulePermission: action.payload });
    case AuthActionTypes.USER_LANGUAGE:
      return Object.assign({}, state, { userLanguage: action.payload });
    default:
      return state;
  }
};
export default authReducer;
