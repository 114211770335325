import React, { useEffect } from 'react';
import HomeDropdown from '../../../components/Dropdowns/HomeDropdown';
import Sidebar from '../../../components/Sidebar/Sidebar';
import {
  addHomeLiveBlogHandler,
  GetLiveBlog,
} from '../../../handlers/homeSpecial.handlers';
import { useHistory } from 'react-router-dom';
const HomeLiveBlog = () => {
  const [liveBlogId, setLiveBlogId] = React.useState('');
  const [liveBlogEmbedFeed, setLiveBlogEmbedFeed] = React.useState('');
  const [homeType, setHomeType] = React.useState('live_blog');
  const mHistory = useHistory();
  const getLiveBlogHander = () => {
    GetLiveBlog().then((res) => {
      setLiveBlogId(res?.data.liveBlogId);
      setLiveBlogEmbedFeed(res?.data.liveBlogEmbedFeed);
    });
  };
  useEffect(() => {
    getLiveBlogHander();
  }, []);
  const onUpdateLiveBlog = () => {
    const data = {
      liveBlogId: liveBlogId,
    };
    addHomeLiveBlogHandler(data).then((res) => {
      if (res.status) {
        alert('Live Blog Id Updated.');
        getLiveBlogHander();
      }
    });
  };

  const onHomeTypeChange = (event) => {
    setHomeType(event.target.value);
  };

  if (homeType === 'news') {
    mHistory.push(`/home-page`);
  } else if (homeType === 'videos') {
    mHistory.push(`/HomeVideoComponent`);
  } else if (homeType === 'f') {
    mHistory.push(`/home-page-feature`);
  }
  //   else if (homeType === 'live_blog'){
  //     mHistory.push('/live_blog');
  //   }

  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ padding: '150px 60px' }}
      >
        <div
          className="relative w-full px-10 max-w-full flex-grow flex-1"
          style={{ textAlign: 'right', marginLeft: '-17px' }}
        >
          <HomeDropdown selectedValue={homeType} onChange={onHomeTypeChange} />
        </div>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                'bg-white'
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <p style={{ padding: '40px 0 0 15px', fontWeight: 'bold' }}>
                      {liveBlogEmbedFeed}
                    </p>
                    <div className="edit__popup-inner">
                      <div className="edit__popup--form">
                        <div className="edit__popup-row">
                          <span style={{ fontWeight: '600' }}>
                            Live Blog Id :
                          </span>
                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={liveBlogId}
                              onChange={(e) => setLiveBlogId(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Please provide Live Blog id here..."
                            />
                          </div>
                        </div>
                        <div className={'edit__popup-row button-row'}>
                          <div
                            onClick={() => onUpdateLiveBlog()}
                            className={'save btn'}
                          >
                            Update
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLiveBlog;
