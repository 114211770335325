import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import LoginAppConstants from "../../../constants/LoginAppConstants";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import SyncLoader from "react-spinners/SyncLoader";
import TwitterTagFilterDropdown from "../../../components/Dropdowns/TwitterTagFilterDropdown";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { css } from "@emotion/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  GetDynamicBanner,
  UpdateDynamicBannerRanking,
} from "../../../handlers/dynamicBanner.handers";
import DynamicBannerTypeDropdown from "../../../components/Dropdowns/DynamicBannerTypeDropdown";
import DynamicDropdownLocationDropdown from "../../../components/Dropdowns/DynamicDropdownLocationDropdown";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { dispatchSelectedDynamicType } from "../../../redux/action-dispatchers/dynamicBanner.dispatcher";

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem("Current_Language");
const headers = {
  "cms-access-token": accessToken,
  source: "cms",
  language: CURRENT_LANGUAGE,
};
const DynamicBannerList = (props) => {
  const [docs, setDocs] = useState([]);
  const [updateDocs, setUpdateDocs] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [getLocation, setGetLocation] = useState("");
  const [getType, setGetType] = useState("");
  const [showEdit, showEditHandler] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showAction, setShowAction] = React.useState(false);
  const getDynamicBannerDocs = () => {
    setIsLoading(true);
    GetDynamicBanner(
      page,
      60,
      props.dynamicBannerReducer.selectedDynamicLocation,
      "All"
    )
      .then((response) => {
        if (response.status) {
          setIsLoading(false);
          setDocs(response.data.docs);
          setTotalPages(response.data.totalPages);
        }
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getDynamicBannerDocs();
    window.scrollTo(0, 0);
  }, [page, props.dynamicBannerReducer.selectedDynamicLocation]);
  useEffect(() => {
    setPage(1);
  }, []);

  const mHistory = useHistory();
  const onView = (id) => {
    mHistory.push(`/dynamicBannerDetail/${id}`);
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };
  const handleBack = () => {
    mHistory.goBack();
  };
  const onBannerTypeChange = (value) => {
    setGetType(value);
  };
  const onBannerLocationChange = (value) => {
    setGetLocation(value);
  };
  const handleDragEnd = ({ destination, source }) => {
    console.log("drag");
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(docs, source.index, destination.index);
    const docsUpdated = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        section: data.section,
        ranking: index + 1,
      });
    });
    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setDocs(items);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const updateOrder = () => {
    UpdateDynamicBannerRanking(
      { bannerList: updateDocs },
      {
        Authorization: props.Authorization,
      }
    ).then((response) => {
      if (response.status) {
        setUpdateDocs([]);
        setShowAction(false);
        alert("Reordered successfully...");
        getDynamicBannerDocs();
      }
    });
  };
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                "bg-white"
              }
            >
              <div className="rounded-t mb-0 px-2 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <div
                      className=" pb-4"
                      style={{
                        cursor: "pointer",
                        marginLeft: "-5px",
                        width: "20px",
                      }}
                      onClick={handleBack}
                    >
                      <ArrowBackIcon />
                    </div>{" "}
                    <p style={{ fontWeight: "bolder" }}>Dynamic Banner</p>
                  </div>

                  {/* <DynamicDropdownLocationDropdown
                    selectedValue={getLocation}
                    onChange={onBannerLocationChange}
                  /> */}

                  <span style={{ marginLeft: 5 }}>
                    <Link to="/addDynamicBanner">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => dispatchSelectedDynamicType("league")}
                        style={{ marginBottom: "1.5%" }}
                      >
                        Add Dynamic Banner
                      </Button>
                    </Link>
                  </span>
                  {showAction && (
                    <div className={"save-reset enabled"}>
                      <div onClick={() => updateOrder()} className="save btn">
                        Save
                      </div>

                      <div
                        onClick={() => {
                          setUpdateDocs([]);
                          getDynamicBannerDocs();
                          setShowAction(false);
                          alert("No changes were made");
                        }}
                        className="reset btn"
                      >
                        Reset
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                          <tr>
                            <th
                              className={
                                "px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Banner
                            </th>

                            <th
                              className={
                                "px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Title
                            </th>
                            <th
                              className={
                                "px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Start Date
                            </th>
                            <th
                              className={
                                "px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              End Date
                            </th>
                            <th
                              className={
                                "px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Type
                            </th>
                            <th
                              className={
                                "px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Location
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {docs?.map((item, index) => {
                            return (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4 flex justify-center">
                                      <img
                                        src={item.imgUrl}
                                        style={{
                                          // maxHeight: 300,
                                          // minHeight: 253,
                                          // maxWidth: 450,
                                          // minWidth: 400,
                                          maxHeight: 50,
                                          minHeight: 53,
                                          maxWidth: 179,
                                          minWidth: 100,
                                        }}
                                        height={300}
                                        width={400}
                                        alt="..."
                                      ></img>
                                    </th>

                                    <td
                                      className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => onView(item._id)}
                                    >
                                      {item.shortTitle}
                                    </td>
                                    <td
                                      className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => onView(item._id)}
                                    >
                                      {item?.startDate ? `${new Date(item?.startDate).toLocaleDateString()} ${new Date(item?.startDate).toLocaleTimeString()}` : "Date not set"}
                                    </td>
                                    <td
                                      className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => onView(item._id)}
                                    >
                                      {item?.endDate ? `${new Date(item?.endDate).toLocaleDateString()} ${new Date(item?.endDate).toLocaleTimeString()}` : "Date not set"}
                                    </td>

                                    <th className="border border-green-800 border-t-0 px-6 align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {item.bannerType}
                                    </th>
                                    <th className="border border-green-800 border-t-0 px-6 align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {item.location}
                                    </th>
                                  </tr>
                                )}
                              </Draggable>
                            );
                          })}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 20,
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  page={page}
                  onChange={handlePagination}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
              {/* console.log */}
            </div>
          </div>
          <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoading}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    dynamicBannerReducer: state.dynamicBannerReducer,
  };
};

export default connect(mapStateToProps)(DynamicBannerList);
