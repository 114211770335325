import React from 'react';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import UserList from './UserList';

const ManageUser = () => {
  return (
    <>
      <Sidebar />

      <UserList color="light" />
    </>
  );
};

export default ManageUser;
