import { SportsActionTypes } from "../action-types";

const initialState = {
  sports: [],
  selectedSports: "all",
  selectedSportsFilter: "",
  selectSports: "All",
  selectedSportsTagging: "",
};
const sportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SportsActionTypes.GET_ALL_SPORTS:
      return Object.assign({}, state, {
        sports: action.payload,
      });
    // return Object.assign({}, state, {
    //   sports: action.payload,
    //   selectedSports: action.payload.length > 0 ? action.payload[0]._id : '',
    //   selectedSportsFilter:
    //     action.payload.length > 0 ? action.payload[0]._id : '',
    // });
    case SportsActionTypes.CHANGE_SELECTED_SPORTS:
      return Object.assign({}, state, {
        selectedSports: action?.payload?.selectedSports,
        selectedSportsFilter: action.payload.selectedSportsFilter,
      });
    case SportsActionTypes.CHANGE_SELECTED_SPORTS_TAGGING:
      return Object.assign({}, state, {
        selectSports: action.payload.selectSports,
        selectedSportsTagging: action.payload.selectedSportsTagging,
      });
    default:
      return state;
  }
};
export default sportsReducer;
