import { configStore } from "../store";
import { DynamicBannerActionTypes } from "../action-types";

export const dispatchSelectedDynamicLocation = (payload) => {
  configStore.dispatch({
    payload,
    type: DynamicBannerActionTypes.GET_SELECTED_BANNER_LOCATION,
  });
};
export const dispatchSelectedDynamicType = (payload) => {
  configStore.dispatch({
    payload,
    type: DynamicBannerActionTypes.GET_SELECTED_BANNER_TYPE,
  });
};
