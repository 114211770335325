import AppConstants from '../constants';
import AxiosInstance from './http.handlers';
import AxiosMessageInstance from './httpMessage.handler';

export const GetFeaturedContent = async (body) => {
    return await AxiosInstance.get(
      AppConstants.WorkerConstants.API_ROUTES.FEATURED.GET_FEATURED_CONTENT+body,
    )
      .then((response) => response)
      .catch((error) => {
        error;
      });
};

//For changing the status of isFeatured or not.
export const ToggleVideoFeatured = async (body) => {
  return await AxiosMessageInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.FEATURED.TOGGLE_FEATURED_STATUS,
   body 
  )
    .then((response) => response)
    .catch((error) => error);
};