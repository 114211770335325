import React, { useState } from "react";
import { connect } from "react-redux";
import { dispatchSelectedDynamicLocation } from "../../redux/action-dispatchers/dynamicBanner.dispatcher";

const DynamicBannerLocationDropdown = (props) => {
  const [docs, setDocs] = useState([
    { location: "Home" },
    { location: "Sports" },
    // { location: "League" },
  ]);
  const onChange = (event) => {
    props.onChange && props.onChange(event.target.value);
    dispatchSelectedDynamicLocation(event.target.value);
  };

  return (
    <select
      style={{
        // marginLeft: 20,
        border: "2px solid #acb9ce",
        borderRadius: 10,
      }}
      value={props.dynamicBannerReducer.selectedDynamicLocation}
      onChange={onChange}
    >
      {docs?.map((tag) => (
        <option value={tag.location}>{tag.location}</option>
      ))}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    dynamicBannerReducer: state.dynamicBannerReducer,
  };
};

export default connect(mapStateToProps)(DynamicBannerLocationDropdown);
