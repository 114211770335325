import { configStore } from '../store';
import { LanguageActionTypes } from '../action-types';

export const dispatchHindiLang = (payload) => {
  configStore.dispatch({
    payload,
    type: LanguageActionTypes.SWITCH_HINDI,
  });
};
export const dispatchEnglishLang = (payload) => {
  configStore.dispatch({
    payload,
    type: LanguageActionTypes.SWITCH_ENGLISH,
  });
};
