import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
const headers = { 'cms-access-token': cms_access_token, source: 'cms' };

export const GetTeamsForTagging = async (compId, sportsId, searchKey) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMSTAGGING.GET_TEAMS_FOR_TAGGING}/${compId}/${sportsId}/${searchKey}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetTeamsAndPlayers = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMSTAGGING.GET_TEAMS_PLAYERS}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const TagTopTeamsOrPlayers = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.TEAMSTAGGING.TEAMS_TOP_TAGGING}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
