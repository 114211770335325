import { configStore } from "../store";
import { ScheduleActionTypes } from "../action-types";

export const dispatchAllSchedules = (payload) => {
  configStore.dispatch({
    payload,
    type: ScheduleActionTypes.GET_ALL_SCHEDULES,
  });
};

export const dispatchUpcomingSchedules = (payload) => {
  configStore.dispatch({
    payload,
    type: ScheduleActionTypes.GET_ALL_UPCOMING,
  });
};

export const dispatchPutResultSchedulesPagination = (payload) => {
  configStore.dispatch({
    payload,
    type: ScheduleActionTypes.PUT_RESULT_SCHEDULES_PAGINATION,
  });
};

export const dispatchLiveSchedules = (payload) => {
  configStore.dispatch({
    payload,
    type: ScheduleActionTypes.GET_ALL_LIVE,
  });
};
