import AppRoutes from "./routes";
import { useHistory } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const App = () => {
  const [open, setOpen] = React.useState(false);
  const mHistory = useHistory();

  const handleOpen = () => setOpen(true);
  const handleClick = () => {
    setOpen(false);
    // window.location.reload();
  };
  window?.addEventListener("storage", (event) => {
    if (mHistory?.location?.pathname == "/") {
      window.location.reload();
    }
    if (event?.key?.trim() === "SESSION_STORAGE_ACCESS_TOKEN") {
      setOpen(true);
    }
  });

  // const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ display: "flex", gap: "4px" }}>
              <div>The user has been logged out from other windows, and no additional changes will be taken into account. 
                Press OK to view your Entered Fields.</div>
              <div
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#0ea5e9",
                  width: "80px",
                  minWidth:'80px',
                  height: "30px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  fontWeight: "500",
                  color: "#fff",
                }}
                onClick={handleClick}
              >
                OK
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      <AppRoutes />
    </>
  );
};

export default App;
