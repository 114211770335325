import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { deleteQuiz, getQuiz } from "../../../handlers/quiz.handlers";
import { _dateTimeFormat } from "../../../constants/appDefaults";
import ReactPlayer from "react-player";
import ConfirmationModal from "../../../components/ConfirmationModal";
const QuizScreen = (props) => {
  const [page, setPage] = useState(1);
  const [docs, setDocs] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [showEdit, showEditHandler] = useState(false);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [showMedia, setShowMedia] = useState(null);
  const [quizId, setQuizId] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });
  const mHistory = useHistory();
  const getAllQuiz = () => {
    const body = {
      page: page,
      limit: 10,
      active: isActive,
    };
    getQuiz(body).then((response) => {
      if (response.status == true) {
        setDocs(response.data.docs);
      }
    });
  };

  useEffect(() => {
    getAllQuiz();
  }, [page]);

  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };
  const showModalForPublish = (id) => {
    let title = "Delete Quiz?";
    let message = "Are you sure to continue?";

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onClose = () => {
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };
  const onSuccess = () => {
    const body = {
      _id: quizId,
    };
    deleteQuiz(body)
      .then((res) => {
        if (res.status) {
          alert("Quiz Deleted Successfully");
          getAllQuiz();
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => error);
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };

  const onView = (quizId) => {
    mHistory.push(`/quizDetial/${quizId}`);
  };
  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? "edit__backdrop active" : "edit__backdrop"}
        ></div>

        <div
          className={showEdit ? "edit__popup-body active" : "edit__popup-body"}
          style={{ width: "55%" }}
        >
          {" "}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showEditHandler(false);
                // setIsplaying((isPlaying) => !isPlaying);
                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={showEdit ? true : false}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div className="relative bg-blueGray-100">
        {confirmationModal.show && (
          <ConfirmationModal
            message={confirmationModal.message}
            title={confirmationModal.title}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            (props.color === "light"
              ? "bg-white"
              : "bg-lightBlue-900 text-white")
          }
        >
          <div className="rounded-t mb-0 px-2 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg " +
                    (props.color === "light"
                      ? "text-blueGray-700"
                      : "text-white")
                  }
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {"Quiz"}
                  <div
                    className="relative w-full  max-w-full flex-grow flex-1"
                    style={{ textAlign: "right" }}
                  >
                    <span style={{ marginLeft: 5 }}>
                      <Link to="/addQuiz">
                        <Button
                          variant="contained"
                          color="primary"
                          // onClick={() => OnClickAddPolls()}
                          style={{ marginBottom: "1.5%" }}
                        >
                          Add Quiz
                        </Button>
                      </Link>
                    </span>
                  </div>
                </h3>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  position: "relative",
                  left: "3.4%",
                }}
              >
                <div className="wrapper"></div>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto">
            <table className="table-fixed">
              <thead>
                <tr>
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Thumb Image
                  </th>
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Topic
                  </th>

                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Plays
                  </th>
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Difficulty
                  </th>
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Home
                  </th>
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Live
                  </th>
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Active
                  </th>

                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Published Date
                  </th>
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (props.color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {docs &&
                  docs.map((quiz, index) => (
                    <tr>
                      <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                        <div
                          style={{
                            background: `url('${quiz?.videoThumbnail}?${quiz?._id}') 0% 0% / cover no-repeat`,
                            width: "100px",
                            height: "53px",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() =>
                            onClickOpenMedia(
                              quiz?.videoUrl,
                              quiz?.videoThumbnail
                            )
                          }
                        ></div>
                      </th>
                      <td
                        className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        onClick={() => onView(quiz?._id)}
                        style={{ cursor: "pointer", fontWeight: "bolder" }}
                      >
                        {quiz?.topic}
                      </td>

                      <td
                        className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        onClick={() => onView(quiz?._id)}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bolder",
                          textAlign: "center",
                        }}
                      >
                        {quiz?.plays}
                      </td>
                      <td
                        className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        // onClick={() => onView(quiz._id)}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bolder",
                          // paddingLeft: '50px',
                          textAlign: "center",
                        }}
                      >
                        {quiz?.difficulty}
                      </td>
                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        {quiz?.isHomeTagged ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            Tagged
                          </span>
                        ) : (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Untagged
                          </span>
                        )}
                      </td>
                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        {quiz?.isTaggedOnLive ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            Tagged
                          </span>
                        ) : (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Untagged
                          </span>
                        )}
                      </td>
                      <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {quiz?.active ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            Published
                          </span>
                        ) : (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Draft
                          </span>
                        )}
                      </td>
                      <td className="border border-green-800  border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <p style={{ textAlign: "center" }}>
                          {_dateTimeFormat(
                            quiz?.addedTime,
                            "ddd, DD MMM hh:mm A"
                          )}
                        </p>
                      </td>
                      <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div>
                          <i
                            onClick={() => {
                              showModalForPublish();
                              setQuizId(quiz?._id);
                            }}
                            className="fas fa-trash "
                            style={{
                              cursor: "pointer",
                              paddingLeft: "15px",
                            }}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuizScreen;
