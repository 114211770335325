import axios from "axios";
import LoginAppConstants from "../constants/LoginAppConstants";
let cms_access_token = localStorage.getItem("SESSION_STORAGE_ACCESS_TOKEN ");
let current_language = localStorage.getItem("Current_Language");
const AxiosAuthInstance = axios.create({
  baseURL: LoginAppConstants.LoginConstant.AUTH_PATH,
  timeout: -1,
  headers: {
    "Content-Type": "application/json",
    // 'cms-access-token': cms_access_token,
    //  source: 'cms',
    // authorization:'YWNjZXNzX3Rva2Vuc3BvX2Ntc3Nwb19jbXNfc3BvQDMyMTE2NjA3Mzc1NTIzNTk=',
    language: current_language,
  },
});

AxiosAuthInstance.interceptors.response.use(
  (response) =>
    // console.log('REspohandsifh',response),
    ({
      status: true,
      message: response.statusText,
      data: response.data,
    }),
  (error) => {
    return {
      error: true,
      message: error.message,
    };
  }
);

export default AxiosAuthInstance;
