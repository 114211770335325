import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { _getDateTime, _dateTimeFormat } from "../../constants/appDefaults";
import SyncLoader from "react-spinners/SyncLoader";
// import LoginAppConstants from "../../constants/LoginAppConstants";
import { css } from "@emotion/core";
import "../../pages/Dashboard/LeagueManagement/LeagueManage.css"
import { GetPaginatedTeamList, DeleteTeam } from "../../handlers/team.handlers";
import SportsDropDown from "../../components/Dropdowns/SportsDropDown";
import LeaguesDropDown from "../../components/Dropdowns/LeaguesDropDown";
import FilteredLeagueDropDown from "../Dropdowns/FilteredLeagueDropDown";
import { Button } from "react-bootstrap";
import { dispatchSelectedSports } from "../../redux/action-dispatchers/sports.dispatchers";
import { dispatchSelectedLeague } from "../../redux/action-dispatchers/leagues.dispatchers";
import { get } from "lodash";

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
// const accessToken = localStorage.getItem(
//   LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
// );
const TeamsTable = (props) => {
  const mHistory = useHistory();
  const [teamList, setTeamList] = useState([]);
  const [sportsId, setSportsId] = useState(localStorage.getItem("teamsSportFilter")??"");
  const [compId, setCompId] = useState(localStorage.getItem("teamLeagueFilter")??"");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [open, setOpen] = useState(false); // for Modal Open State
  const [page, setPage] = useState(1);
  {/**Modal open and close */}
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const [searchString, setSearchString] = useState(localStorage.getItem("teamSearchFilter")??"");
  // let searchStringGlobal = "";
//   let timer = null;
  useEffect(() => {
    dispatchSelectedLeague({
      selectedLeague: localStorage.getItem("teamLeagueFilter"),
      selectedLeagueFilter: localStorage.getItem("teamLeagueFilter"),
    });
  }, []);

  useEffect(() => {
    dispatchSelectedSports({
      selectedSports: localStorage.getItem("teamsSportFilter"),
      selectedSportsFilter:localStorage.getItem("teamsSportFilter"),
    });
  }, []);

  useEffect(() => {
    getPlayers(
     
    );
  }, [
   searchString, 
   page,
   sportsId,
   compId
  ]);
//   useEffect(() => {
//     Axios.get(
//       `${API_BASE_PATH}leagues/leagueWatchList`,
//       { headers: { "cms-access-token": accessToken, source: "cms" } },
//       {
//         params: JSON.stringify({
//           username: localStorage.getItem("email"),
//         }),
//       }
//     ).then((res) => {
//       if (res.data.message == "Error in authorization middleware") {
//         alert(`${res.data.message} in League watch list`);
//       } else dispatchAllLeagues(res.data.data?.data);
//     });
//   }, []);
  // const leagueName = (id) => {
  //   const leagueInfo = props.leagues?.find((item) => item.comp_id === id);
  //   if (leagueInfo) {
  //     return leagueInfo.abbr;
  //   }
  //   return "N/A";
  // };
  // const onSportsTypeChange = (value) => {
  //   setGetSports(value);
  // };
  // const onAuthorChange = (event) => {
  //   setAuthorName(event.target.value);
  //   getPlayers(
  //     page,
  //     parseInt(props.limit),
  //     // props.selectedLeague,
  //     // props.selectedSports,
  //     searchStringGlobal,
  //     event.target.value,
  //     false
  //   );
  // };

  const getPlayers = (
    isLoadingMore
  ) => {
    setIsLoading(true);
    GetPaginatedTeamList({
      page: page,
      limit: 10,
      comp_id: compId,
      sportsId: sportsId,
      searchKey: searchString,
      // author:authorName
    })
      .then((response) => {
        setIsLoading(false);
        // if (response.statusCode == 401) {
        //   alert('Token Expired please log in again!!!');
        //   logoutUser();
        //   mHistory.push('/');
        // }
        // else if (response.message == 'Request failed with status code 403') {
        //   window.alert(
        //     `You don't have permission to perform this action in this module`
        //   );
        // }
        // else
        if (response.status) {
          setTeamList(response?.data);
        }
      })

      .catch((error) => {
        // console.log('error 401 catch', error);

        isLoadingMore && setIsLoadingMore(false);
      });
  };

  const onView = (teamSlug) => {
    mHistory.push(`/team-manager/${teamSlug}`);
  };
  const onSportsTypeChange = (value) => {
    setSportsId(value);
    localStorage.setItem("teamsSportFilter", value);
    localStorage.removeItem("teamLeagueFilter")
    setCompId("all");
  };
  const handlePagination = (event, value) => {
    setPage(value);
    // getPlayers(
    //   value,
    //   parseInt(props.limit),
    //   // props.selectedLeague,
    //   // props.selectedSports,
    //   // searchStringGlobal,
    //   authorName,
    //   false
    // );
  };
  const handleInputChange = (event) => {
   
    setSearchString(event.target.value);
    localStorage.setItem("teamSearchFilter",event.target.value)
    
  };


  const handleLeagueChange = (value) =>{
        setCompId(value);
        localStorage.setItem("teamLeagueFilter", value);
  };

  const onDelete = (team)=>{
        DeleteTeam({
          seo_slug:team?.seo_slug,
          emailId:localStorage.getItem("email"),
        })
        .then((response)=>{
          alert('Team deleted Successfully!');
          window.location.reload(false);
        })
        .catch((error)=>{console.log(error)});
  };
    
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
        style={{display:'block',}}
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (props.color === "light" ? "text-blueGray-700" : "text-white")
                }
                style={{marginBottom:"30px"  }}
              >
                Team Manager
              </h3>
                <div style={{display:"flex"}} >
                  <span style={{ marginLeft: 20 }}>
                    {/* <AuthorSelectDropdown
                      selectedValue={authorName}
                      // onChange={(event) =>
                      //   setAuthorName(event.target.value)
                      // }
                    onChange={onAuthorChange}
                    /> */}
                  </span>
                  <span style={{ marginLeft: 4}}>
                    <SportsDropDown
                      selectedValue={sportsId}
                      onChange={onSportsTypeChange}
                    />
                  </span>
                    
                  <span style={{ marginLeft: 4 }}>
                    <FilteredLeagueDropDown 
                        onChange = {handleLeagueChange}
                        sports={sportsId}
                    />
                  </span>
                  <span
                style={{
                  width: "40%",
                  paddingBottom:"2px"
                  // display: "flex",
                  // justifyContent: "flex-end",
                }}
              >
                <input
                  value={searchString}
                  style={{
                    width:"100%",
                    height:"100%",
                    border: "2px solid #acb9ce",
                    borderRadius: 10,
                    marginRight: "3%",
                    padding: "3px",
                    // paddingLeft: '10px',
                  }}
                  placeholder="Search Team"
                  onChange={handleInputChange}
                />
              </span>
              <Button
              style={{marginLeft:"20px", minWidth:"fit-content", background:"#1876d1", padding:"10px", borderRadius:"10px", color:"#FFFFFF"}}
                onClick={()=>{
                  setSportsId("");
                  dispatchSelectedSports({
                    selectedSports: "",
                    selectedSportsFilter: "",
                  });
                  dispatchSelectedLeague({
                         selectedLeague: "",
                         selectedLeagueFilter: "",
                      });
                      setCompId("all")
                      setSearchString("");
                      localStorage.removeItem("teamsSportFilter");
                      localStorage.removeItem("teamLeagueFilter");
                      localStorage.removeItem("teamSearchFilter");
                }}
                
              >
                Reset Filter
              </Button>
                </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
         {teamList?.docs?.length? <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"10%"}}
                >
                  Sr. No.
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Image
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"25%"}}
                >
                  Name
                </th>
                {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Perma Link
                </th> */}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Sport
                </th>
                {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Created By
                </th> */}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Created At
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Update At
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"5%"}}
                >
                  Action / Edit
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"5%"}}
                >
                  Delete
                </th>
               
              </tr>
            </thead>

            <tbody>
              
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
              
              {teamList?.docs?.map((teams, position) => (
                <tr>
                   <td
                    className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ fontWeight: "bolder", width:"10%" }}
                  >
                    {((page-1)*10)+position+1}
                  </td>
                  <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 " style={{width:"20%"}}>
                    
                    <img 
                      src={teams?.logo}
                      style={{
                        
                        width: "80px",
                        height: "80px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </th>
                  <td
                    className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ cursor: "pointer", fontWeight: "bolder", width:"25%" }}
                  >
                    {teams?.name}
                  </td>
                 
                  {/* <td
                    className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    onClick={() => {
                      if (!teams.seo_slug) {

                        alert("Teams does not have seo slug...")
                      }

                    }}
                    style={{ cursor: "pointer", fontWeight: "bolder" }}
                  >
                    <a href={(props?.selectedLanguage?.toLowerCase()=="english"?"https://m.thesportstak.com":"https://m.hindi.thesportstak.com")+"/playerstats/cricket/"+players?.seo_slug}
                      target="_blank">
                      <img src="/LinkIcon.png" style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
                    </a>
                  </td> */}
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"style={{width:"20%"}}>
                    {teams?.sports && teams?.sports?.name ? teams?.sports.name : "Cricket"}
                  </td>
                  {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"20%"}}>
                    {players?.createdBy?players?.createdBy
                      : "N/A"}
                  </td> */}
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"20%"}}>
                  {_dateTimeFormat(teams?.createdAt, "ddd, DD MMM hh:mm A")}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"20%"}}>
                  {_dateTimeFormat(teams?.updatedAt, "ddd, DD MMM hh:mm A")}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"5%"}} onClick={() => {
                                    
                                    onView(teams?.seo_slug);
                                  }}>
                  <i
                                  className="fas fa-edit fa-1x"
                                  style={{
                                    marginTop: 5,
                                    marginBottom: 10,
                                    marginRight: "10px",
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  
                                ></i>
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"5%"}} onClick={() => {
                                    
                                    onDelete(teams);
                                  }}>
                  <i
                              className="fas fa-trash "
                              style={{
                                cursor: 'pointer',
                                // paddingLeft: '15px',
                              }}
                              // onClick={() => deleteUserWithId(users._id)}
                            ></i>
                  </td>
                </tr>
              ))}
             
            </tbody>
          </table>:
          <div style={{textAlign:"center", marginTop:"40px", fontSize:"20px", fontWeight:'700'}}>
             Data to be Updated soon for selected filter....
          </div>}
         
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={page}
                onChange={handlePagination}
                count={teamList?.totalPages}
                variant="outlined"
                color="primary"
              />
            </Stack>
            {/* console.log */}
          </div>
          <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      
      </div>
      
      
      


      
      
    </>
  );
};

TeamsTable.defaultProps = {
  color: "light",
};

TeamsTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
const mapStateToProps = (state) => {
  return {
    totalPages: state.playerProfileReducer.totalPages,
    docs: state.playerProfileReducer.docs,
    page: state.playerProfileReducer.page,
    limit: state.playerProfileReducer.limit,
    leagues: state.leaguesReducer.leagues,
    hasNextPage: state.playerProfileReducer.hasNextPage,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
    selectedSports: state.sportsReducer.selectedSports,
  };
};
export default connect(mapStateToProps)(TeamsTable);
