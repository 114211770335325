import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { RootReducer } from '../reducers';

// convert object to string and store in localStorage

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const configStore = createStore(
  RootReducer /* preloadedState, */,
  composeEnhancers(applyMiddleware(thunk))
);
// // listen for store changes and use saveToLocalStorage to
// // save them to localStorage
// configStore.subscribe(() => saveToLocalStorage(configStore.getState()));
