import { Button, Checkbox } from '@mui/material';
import _ from 'lodash';
import { useHistory } from 'react-router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import Modal from "react-modal";
import { _dateTimeFormat } from '../../../../constants/appDefaults';
import { AUTH_PATH } from '../../../../constants/loginConstants';
import {
  DeleteUser,
  GetAllUsers,
  UpdateUser,
  UpdatePassword
} from '../../../../handlers/user.handlers';
import LoginAppConstants from '../../../../constants/LoginAppConstants';
import { logoutUser } from '../../../../services/auth';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '400px',
    height: '200px',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
};
const UserList = (props) => {
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [password, setPassword] = useState({
    show: false,
    title: '',
    old: '',
    new: '',
  });
  const [checkedInsight, setCheckInsight] = useState([]);
  const [docs, setDocs] = useState([]);
  const [id,setId]=useState("");
  const [old,setOld]=useState("");
  const [newP,setNewP]=useState("");
  const history = useHistory();
  const language = localStorage.getItem('Current_Language');
  let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
  let email = localStorage.getItem('email');
  let lang = language.charAt(0).toUpperCase() + language.slice(1);
  const getAllUsersData = () => {
    GetAllUsers(1, lang).then((res) => {
      if (res.message == 'Request failed with status code 401') {
        // logoutUser();
        // mHistory.push('/');
      } else {
        setDocs(res.data?.data.users);
      }
    });
  };
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  useEffect(() => {
    getAllUsersData();
  }, []);
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onClosePassword = () => {
    setPassword({
      old: '',
      new: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    deleteUserWithId(id);
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccessPassWord = () => {
    UpdatePasswordHanlder();
    
  };
  const deleteUserWithId = (id) => {
    const body = { id, language };
    var config = {
      method: 'delete',
      url: `${AUTH_PATH}cmsUser/deleteUser?id=${id}&language=${language}`,
      headers: { 'cms-access-token': accessToken, source: 'cms' },
    };

    // axios(config)
    DeleteUser(id, language)
      .then(function (response) {
        if (response) {
          getAllUsersData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const UpdateUserHandler = (languages,event, language, id) => {
    var language_allowed = languages;
    if (event.target.checked) {
      if (!language_allowed.includes(language)) {
        language_allowed.push(language);
      }
    } else {
      language_allowed = language_allowed.filter(lang => lang !== language);
    }
    console.log('first', language_allowed)
    const data = {
      id: id,
      languagesAllowed: language_allowed,
      // event.target.checked && language == 'Hindi'
      //   ? 'Hindi'
      //   : !event.target.checked && language == 'Hindi'
      //   ? 'English'
      //   : 'Hindi' || (event.target.checked && language == 'English')
      //   ? 'English'
      //   : !event.target.checked && language == 'English'
      //   ? 'Hindi'
      //   : 'English',
    };
    // if (data.languagesAllowed == 'Hindi') {
    //   alert('User Language Changed From English To Hindi');
    // } else {
    //   alert('User Language Changed From Hindi To English');
    // }
    const recordFound = _.find(checkedInsight, (item) => item === id);

    if (recordFound) {
      const insightList = _.filter(checkedInsight, (item) => item !== id);

      setCheckInsight(insightList);

      UpdateUser(data, { 'cms-access-token': cms_access_token, source: 'cms' })
        .then(function (response) {
          if (response) {
            getAllUsersData();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setCheckInsight([...checkedInsight, id]);
      UpdateUser(data)
        .then(function (response) {
          if (response) {
            getAllUsersData();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const UpdatePasswordHanlder = () => {
    if (old == "") {
      alert("Old password cannot be empty");
      return;
    } else if (newP == "") {
      alert("New password cannot be empty");
      return;
    }
    const data = {
      email: email,
      oldPassword: old,
      newPassword: newP
    };
    UpdatePassword(data, { 'cms-access-token': cms_access_token, source: 'cms' }).then(function (response) {
      if(response.error){
        alert("Please try again later");
        return;
      }
      else if(response.data.error){
        alert(response.data.message);
        return;
      }
      if (response.data.error == false) {
        alert("Password updated successfully");
        setPassword({show:false,old:"",new:""})
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <div className="relative md:ml-64 bg-blueGray-100">
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            {password.show && (
              <Modal isOpen={password.show} onRequestClose={() => { }} style={customStyles}>
                <h6 className="text-xl font-normal leading-normal mt-0 mb-2 text-lightBlue-800">
                  Pleas Update password
                </h6>

                <div >
                  <input type="text" onChange={(e) => { setOld( e.target.value )}} placeholder='Old Password' style={{ marginBottom: "10px" }} />

                  <input type="text" onChange={(e) => { setNewP( e.target.value ) }} placeholder='New Password' />
                </div>

                <div style={{ paddingTop: 20 }}>
                  <button
                    className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onClosePassword}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onSuccessPassWord}
                  >
                    Update
                  </button>
                </div>

              </Modal>
              
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (props.color === 'light'
                  ? 'bg-white'
                  : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-2 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (props.color === 'light'
                          ? 'text-blueGray-700'
                          : 'text-white')
                      }
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {'Manage User'}
                      <div
                        className="relative w-full  max-w-full flex-grow flex-1"
                        style={{ textAlign: 'right' }}
                      >
                        <span style={{ marginLeft: 5 }}>
                          <Link to="/addUser">
                            <Button
                              variant="contained"
                              color="primary"
                              // onClick={() => OnClickAddPolls()}
                              style={{ marginBottom: '1.5%' }}
                            >
                              Add User
                            </Button>
                          </Link>
                        </span>
                        {email && <span style={{ marginLeft: 5 }}>
                            <Button
                              variant="contained"
                            onClick={() => {
                              
                                setPassword({
                                  title: "Please Update your password",
                                  show: true,
                                  old:password.old,
                                  new: password.new,
                                  
                            })}}
                              style={{ marginBottom: '1.5%' ,backgroundColor:"Green"}}
                            >
                              Update PASSWORD
                            </Button>
                        </span>}
                      </div>
                    </h3>
                  </div>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      position: 'relative',
                      left: '3.4%',
                    }}
                  >
                    <div className="wrapper"></div>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (props.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Avatar
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (props.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        User
                      </th>

                      <th
                        className={
                          'px-6 mx-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                          (props.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Role
                      </th>

                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                          (props.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Access
                      </th>

                      <th
                        className={
                          'px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (props.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs &&
                      docs.map((users, index) => (
                        <tr key={index}>
                          <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                            <div
                              style={{
                                background: `url('${users.imageUrl}') 0% 0% / cover no-repeat`,
                                width: '100px',
                                height: '53px',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                              //   onClick={() =>
                              //     onClickOpenMedia(quiz.videoUrl, quiz.videoThumbnail)
                              //   }
                            ></div>
                          </th>
                          <td
                            className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                            // onClick={() => onView(quiz._id)}
                            style={{ cursor: 'pointer', fontWeight: '500' }}
                          >
                            {users.name}
                          </td>

                          <td
                            className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                            // onClick={() => onView(quiz._id)}
                            style={{
                              cursor: 'pointer',
                              fontWeight: 500,
                              textAlign: 'center',
                            }}
                          >
                            {users.roles && users.roles[0]
                              ? users.roles[0].name
                              : 'Role Not Assigned'}
                          </td>
                          <td
                            className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                            // onClick={() => onView(quiz._id)}
                            style={{
                              cursor: 'pointer',
                              fontWeight: 500,
                              // paddingLeft: '50px',
                              textAlign: 'center',
                              width: '40%',
                            }}
                          >
                            <div style={{ display: 'flex',justifyContent:"center"}}>
                              {' '}
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Checkbox
                                  checked={
                                    users.languagesAllowed[0] == 'Hindi' ||
                                    users.languagesAllowed[1] == 'Hindi'
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    UpdateUserHandler(
                                      users.languagesAllowed,
                                      event,
                                      'Hindi',
                                      users._id
                                    );
                                  }}
                                  color="default"
                                  inputProps={{
                                    'aria-label': 'checkbox with default color',
                                  }}
                                />
                                <p>Hindi CMS</p>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Checkbox
                                  checked={
                                    users.languagesAllowed[0] == 'English' ||
                                    users.languagesAllowed[1] == 'English'
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    UpdateUserHandler(
                                      users.languagesAllowed,
                                      event,
                                      'English',
                                      users._id
                                    );
                                  }}
                                  color="default"
                                  inputProps={{
                                    'aria-label': 'checkbox with default color',
                                  }}
                                />

                                <p>English CMS</p>
                              </div>
                            </div>
                          </td>

                          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            
                          <i
                              className="fas fa-edit"
                              style={{
                                marginRight: '30px',
                                cursor: 'pointer',
                              }}
                              onClick={() => { history.push(`/editUser/${users._id}`) }}
                            ></i>
                         

                            <i
                              className="fas fa-trash "
                              style={{
                                cursor: 'pointer',
                                // paddingLeft: '15px',
                              }}
                              onClick={() => {setConfirmationModal({
                                message: "Are you sure you want to delete",
                                show: true,
                                title: '',
                              });setId(users._id)}}
                            ></i>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
