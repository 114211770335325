import { API_AUTH_PATH } from './workerConstants';
export const AUTH_PATH = API_AUTH_PATH;

// export const API_TOKEN_GENERATOR =
//   'https://develop-rest-microservice.thesportstak.mobiletak.com/authentication/api/v2/';
export const API_ROUTES = {
  USER: {
    LOGIN: 'cmsUser/login',
    LOGOUT: 'cmsUser/logout',
    ADD_USER: 'cmsUser',
    ALL_USERS: 'cmsUser/all',
    DELETE_USER: 'cmsUser/deleteUser',
    UPDATE_USER: 'cmsUser/updateUsers',
    UPDATE_PASSWORD: 'cmsUser/updatePassword',
    GET_USER: 'cmsUser/userDetails',
  },
  ROLES: {
    ALL_ROLES: 'cmsRole/all',
  },
};
