import React, { useState, useEffect } from 'react';
import {
  GetVersions,
  UpdateVersion,
  AddVersion,
} from '../../../handlers/versions.handlers';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { Button } from '@material-ui/core';
import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';

const Version = () => {
  const [docs, setDocs] = useState([]);
  const [showEdit, showEditHandler] = useState(false);
  const [showAdd, showAddHandler] = useState(false);
  const [editVersion, setEditVersion] = useState('');
  const [editMessageBody, setEditMessageBody] = useState('');
  const [editAppStoreUrl, setEditAppStoreUrl] = useState('');
  const [editPlayStoreUrl, setEditPlayStoreUrl] = useState('');
  const [editShouldForceUpdate, setEditshouldForceUpdate] = useState('');
  const [editIsLatestVersion, setEditIsLatestVersion] = useState('');
  const [editAppUpdateOrMessage, setEditAppUpdateOrMessage] = useState('');
  const [editFirebaseToken, setEditFirebaseToken] = useState('');
  const [editStrictness, setEditStrictness] = useState('');
  const [isMessageOrAppUpdate, setIsMessageOrAppUpdate] = useState('');
  const [editUpdatedAbout, setEditUpdatedAbout] = useState('');
  const [shouldForceUpdate, setShouldForceUpdate] = useState(false);
  const [version, setVersion] = useState('');
  const [firebaseToken, setFirebaseToken] = useState('');
  const [addAppStoreUrl, setAddAppStoreUrl] = useState('');
  const [addPlayStoreUrl, setAddPlayStoreUrl] = useState('');
  const [addShouldForceUpdate, setAddShouldForceUpdate] = useState(false);
  const [isLatestVersion, setIsLatestVersion] = useState(true);
  const [appUpdateOrMessage, setAppUpdateOrMessage] = useState('AppUpdate');
  const [strictness, setStrictness] = useState('Mandatory');
  const [messageBody, setMessageBody] = useState('');
  const [addUpdatedAbout, setAddUpdatedAbout] = useState('');
  const [selectedOption, setSelectedOption] = useState(false);

  const color = 'light';

  const getVersion = () => {
    GetVersions()
      .then((res) => {
        setDocs(res.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getVersion();
  }, []);
  const OnClickEditVersion = (
    version,
    appStoreUrl,
    playStoreUrl,
    updatedAbout,
    isMessageOrAppUpdate,
    messageBody,
    strictness,
    isLatestVersion,
    firebaseToken
  ) => {
    setEditVersion(version);
    appStoreUrl !== undefined && setEditAppStoreUrl(appStoreUrl);
    playStoreUrl !== undefined && setEditPlayStoreUrl(playStoreUrl);
    messageBody !== undefined && setEditMessageBody(messageBody);
    updatedAbout !== undefined && setEditUpdatedAbout(updatedAbout);
    setEditAppUpdateOrMessage(isMessageOrAppUpdate);
    setEditStrictness(strictness);
    setEditIsLatestVersion(isLatestVersion);
    setEditFirebaseToken(firebaseToken);
    showEditHandler(true);
  };
  const OnClickAddVersion = () => {
    showAddHandler(true);
  };

  const handleChange = () => {
    const body = {
      version: editVersion,
      appStoreUrl: editAppStoreUrl,
      playStoreUrl: editPlayStoreUrl,
      strictness: editStrictness,
      isMessageOrAppUpdate: editAppUpdateOrMessage,
      updatesAbout: editUpdatedAbout,
      isLatestVersion: editIsLatestVersion,
      firebaseToken: editFirebaseToken,
      messageBody: editMessageBody,
    };

    UpdateVersion(body).then((response) => {
      if (response.status) {
        showEditHandler(false);
        alert('Version updated successfully');
        getVersion();
      } else {
        alert('Something went Wrong...');
      }
    });
  };
  const handleAdd = () => {
    let body;
    if (appUpdateOrMessage == 'AppUpdate') {
      body = {
        version: version,
        appStoreUrl: addAppStoreUrl,
        playStoreUrl: addPlayStoreUrl,
        strictness: strictness,
        isMessageOrAppUpdate: appUpdateOrMessage,
        updatesAbout: addUpdatedAbout,
        isLatestVersion: isLatestVersion,
        firebaseToken: firebaseToken,
      };
    } else {
      body = {
        version: version,
        messageBody: messageBody,
        strictness: strictness,
        isMessageOrAppUpdate: appUpdateOrMessage,
        isLatestVersion: isLatestVersion,
        firebaseToken: firebaseToken,
      };
    }
    if (version && version == '') {
      alert('Please enter version.');
    } else {
      AddVersion(body)
        .then((response) => {
          if (response.status) {
            showAddHandler(false);
            alert('Version added successfully');
            getVersion();
          } else {
            alert('Something went Wrong...');
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };
  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '45%' }}
        >
          <div className="edit__popup-inner">
            <div style={{ fontWeight: 'bold' }}> Version- {editVersion}</div>
            <i
              onClick={() => {
                showEditHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <div>
              Firebase Token :{' '}
              <input
                type="text"
                value={editFirebaseToken}
                onChange={(e) => {
                  setEditFirebaseToken(e.target.value);
                }}
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                style={{
                  width: '60%',
                  height: '33px',
                  margin: '1.2%',
                  marginRight: '4%',
                }}
              />
            </div>
            {/* message or app update */}
            <div>
              App-Update OR Message
              <select
                style={{
                  width: '25%',
                  height: '50px',
                  margin: '1.2%',
                  marginRight: '4%',
                }}
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                onChange={(e) => {
                  setEditAppUpdateOrMessage(e.target.value);
                }}
              >
                <option
                  selected={editAppUpdateOrMessage == 'AppUpdate' && 'selected'}
                  value="AppUpdate"
                >
                  App-Update
                </option>
                <option
                  selected={editAppUpdateOrMessage == 'Message' && 'selected'}
                  value="Message"
                >
                  Message
                </option>
              </select>
            </div>
            <div>
              <div>
                Is Latest Version
                <select
                  style={{
                    width: '25%',
                    height: '50px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  onChange={(e) => {
                    setEditIsLatestVersion(e.target.value);
                  }}
                >
                  <option
                    selected={editIsLatestVersion == true && 'selected'}
                    value={true}
                  >
                    Yes
                  </option>
                  <option
                    selected={editIsLatestVersion == false && 'selected'}
                    value={false}
                  >
                    No
                  </option>
                </select>
              </div>
              <div>
                Select strictness
                <select
                  style={{
                    width: '25%',
                    height: '50px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  onChange={(e) => {
                    setEditStrictness(e.target.value);
                  }}
                >
                  <option
                    selected={editStrictness == 'Mandatory' && 'selected'}
                    value="Mandatory"
                  >
                    Mandatory
                  </option>
                  <option
                    selected={editStrictness == 'Optional' && 'selected'}
                    value="Optional"
                  >
                    Optional
                  </option>
                  <option
                    selected={editStrictness == 'Nothing' && 'selected'}
                    value="Nothing"
                  >
                    Nothing
                  </option>
                </select>
              </div>
            </div>
            {/* stirctness end */}
            {/* message or app update end*/}
            {editAppUpdateOrMessage == 'AppUpdate' ? (
              <>
                AppStore Url:{' '}
                <input
                  type="text"
                  value={editAppStoreUrl}
                  onChange={(e) => {
                    setEditAppStoreUrl(e.target.value);
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  style={{
                    width: '60%',
                    height: '33px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                />
                <div>
                  PlayStore Url :{' '}
                  <input
                    type="text"
                    value={editPlayStoreUrl}
                    onChange={(e) => {
                      setEditPlayStoreUrl(e.target.value);
                    }}
                    placeholder="Edit title"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '60%',
                      height: '33px',
                      margin: '1.2%',
                      marginRight: '4%',
                    }}
                  />
                </div>
                {/* <div>
              Should Force Update :{' '}
              <select
                style={{
                  width: '16%',
                  height: '40px',
                  margin: '1.2%',
                  marginRight: '4%',
                }}
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                onChange={(e) => {
                  setShouldForceUpdate(e.target.value);
                }}
              >
                <option
                  selected={
                    selectedOption == shouldForceUpdate ? 'selected' : ''
                  }
                  value={true}
                >
                  True
                </option>
                <option
                  selected={
                    selectedOption == shouldForceUpdate ? 'selected' : ''
                  }
                  value={false}
                >
                  False
                </option>
              </select>
            </div> */}
                <div>
                  Updated About :{' '}
                  <input
                    type="text"
                    value={editUpdatedAbout}
                    onChange={(e) => {
                      setEditUpdatedAbout(e.target.value);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '60%',
                      height: '33px',
                      margin: '1.2%',
                      marginRight: '4%',
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                {' '}
                Message Body:
                <input
                  type="text"
                  value={editMessageBody}
                  onChange={(e) => {
                    setEditMessageBody(e.target.value);
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  style={{
                    width: '60%',
                    height: '33px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                />
              </>
            )}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              {' '}
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleChange()}
                style={{ marginBottom: '1.5%' }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Add version */}
      <div className="edit__popup">
        <div
          onClick={() => showAddHandler(false)}
          className={showAdd ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showAdd ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '45%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showAddHandler(false);

                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <div>
              Version:{' '}
              <input
                type="text"
                //   name=
                value={version}
                onChange={(e) => {
                  setVersion(e.target.value);
                }}
                placeholder="Add Version"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                style={{
                  width: '60%',
                  height: '33px',
                  margin: '1.2%',
                  marginRight: '4%',
                }}
              />
            </div>
            <div>
              Firebase Token:
              <input
                type="text"
                //   name=
                value={firebaseToken}
                onChange={(e) => {
                  setFirebaseToken(e.target.value);
                }}
                placeholder="Add Firebase Token"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                style={{
                  width: '60%',
                  height: '33px',
                  margin: '1.2%',
                  marginRight: '4%',
                }}
              />
            </div>
            {/* message or app update */}
            <div>
              App-Update OR Message
              <select
                style={{
                  width: '25%',
                  height: '50px',
                  margin: '1.2%',
                  marginRight: '4%',
                }}
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                onChange={(e) => {
                  setAppUpdateOrMessage(e.target.value);
                }}
              >
                <option value="AppUpdate">App-Update</option>
                <option value="Message">Message</option>
              </select>
            </div>
            {/* message or app update end*/}

            {appUpdateOrMessage == 'AppUpdate' ? (
              <>
                <div>
                  AppStore Url:{' '}
                  <input
                    type="text"
                    //   name=
                    value={addAppStoreUrl}
                    onChange={(e) => {
                      setAddAppStoreUrl(e.target.value);
                    }}
                    placeholder="Add App Store Url"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '60%',
                      height: '33px',
                      margin: '1.2%',
                      marginRight: '4%',
                    }}
                  />
                </div>
                <div>
                  PlayStore Url :{' '}
                  <input
                    type="text"
                    value={addPlayStoreUrl}
                    onChange={(e) => {
                      setAddPlayStoreUrl(e.target.value);
                    }}
                    placeholder="Add Play Store Url"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '60%',
                      height: '33px',
                      margin: '1.2%',
                      marginRight: '4%',
                    }}
                  />
                </div>
                {/* <div>
              Should Force Update :{' '}
              <select
                style={{
                  width: '16%',
                  height: '40px',
                  margin: '1.2%',
                  marginRight: '4%',
                }}
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                onChange={(e) => setAddShouldForceUpdate(e.target.value)}
              >
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div> */}
                <div>
                  Updates About :{' '}
                  <input
                    type="text"
                    value={addUpdatedAbout}
                    onChange={(e) => {
                      setAddUpdatedAbout(e.target.value);
                    }}
                    placeholder="Add Update About"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '60%',
                      height: '33px',
                      margin: '1.2%',
                      marginRight: '4%',
                    }}
                  />
                </div>
              </>
            ) : (
              <div>
                Message Body:
                <input
                  type="text"
                  value={messageBody}
                  onChange={(e) => {
                    setMessageBody(e.target.value);
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  style={{
                    width: '60%',
                    height: '33px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                />
              </div>
            )}
            {/* strictness */}
            <div>
              <div>
                Is Latest Version
                <select
                  style={{
                    width: '25%',
                    height: '50px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  onChange={(e) => {
                    setIsLatestVersion(e.target.value);
                  }}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div>
                Select strictness
                <select
                  style={{
                    width: '25%',
                    height: '50px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  onChange={(e) => {
                    setStrictness(e.target.value);
                  }}
                >
                  <option value="Mandatory">Mandatory</option>
                  <option value="Optional">Optional</option>
                  <option value="Nothing">Nothing</option>
                </select>
              </div>
            </div>
            {/* stirctness end */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAdd()}
                style={{ marginBottom: '1.5%' }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Version Control
                    </h3>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => OnClickAddVersion()}
                  >
                    Add New Version
                  </Button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <div>
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Version
                        </th>
                        <th
                          className={
                            'px-13 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                          style={{ textAlign: 'center' }}
                        >
                          Message Or AppUpdate
                        </th>
                        <th
                          className={
                            'px-13 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                          style={{ textAlign: 'center' }}
                        >
                          Strictness
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          AppStore Url
                        </th>

                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          PlayStore Url
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Message
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Firebase Token
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Update About
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Created At{' '}
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Edit Version
                        </th>
                      </tr>
                    </thead>
                    {/* <PulseLoader
                      color="#23A5E0"
                      loading={isLoading}
                      css={override}
                      size={10}
                    /> */}
                    <tbody>
                      {docs?.length > 0 &&
                        docs.map((item) => {
                          return (
                            <tr>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.version ? item.version : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.isMessageOrAppUpdate}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.strictness}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.appStoreUrl
                                  ? item.appStoreUrl
                                  : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.playStoreUrl
                                  ? item.playStoreUrl
                                  : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.messageBody
                                  ? item.messageBody
                                  : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.firebaseToken
                                  ? item.firebaseToken
                                  : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item.updatesAbout
                                  ? item.updatesAbout
                                  : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {_dateTimeFormat(
                                  item && item.createdAt
                                    ? item.createdAt
                                    : 'N/A',
                                  'ddd, DD MMM hh:mm A'
                                )}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    OnClickEditVersion(
                                      item.version,
                                      item.appStoreUrl,
                                      item.playStoreUrl,
                                      item.updatesAbout,
                                      item.isMessageOrAppUpdate,
                                      item.messageBody,
                                      item.strictness,
                                      item.isLatestVersion,
                                      item.firebaseToken
                                    );
                                  }}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Version;
