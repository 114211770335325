import { RolesActionTypes } from '../action-types';

const initialState = {
  docs: [],
  selectedRoles: [],
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RolesActionTypes.GET_ALL_ROLES:
      return Object.assign({}, state, {
        ...action.payload,
        docs: action.payload,
      });
    case RolesActionTypes.CHANGE_SELECTED_ROLE:
      return Object.assign({}, state, {
        selectedRoles: action.payload.selectedRoles,
      });
    default:
      return state;
  }
};

export default rolesReducer;
