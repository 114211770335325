import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  AddPoles,
  AddPolls,
  DeletePolls,
  EditPolls,
  GetPollsInfo,
  GetPollsForRank
} from '../../../handlers/polls.handlers';
import SyncLoader from 'react-spinners/SyncLoader';
import AlertModal from '../../../components/AlertModal';
import UnsavedChangesWarning from '../../../components/AlertModal/UnsavedChangesWarning';
import { _dateTimeFormat, _getDateTime } from '../../../constants/appDefaults';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import ShowRank from './ShowRank';
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PollsSortDropDown from "../../../components/Dropdowns/PollsSortDropDown";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal"
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const PollsInfo = (props) => {
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState('false');
  const [alertModal, setAlertModal] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [showAdd, showAddHandler] = useState(false);
  const [editRank, setEditRank] = useState(false);
  const [title, setTitle] = useState('');
  const [isHomeTagged, setIsHomeTagged] = useState(true);
  const [added, setAdded] = useState(false);
  const [expiry, setExpiry] = useState(0);
  const [id, setId] = useState('');
  const [fileImage, setFileImage] = useState();
  const [file, setFile] = useState();
  const [bgImage, setBgImage] = useState('');
  const [deleteItem, setDelete] = useState({});
  const [sort, setSort] = useState("createdAt");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rankList, setRankList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [inputList, setInputList] = useState([
    {
      value: '',
      image: '',
    },
  ]);
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
    file: '',
  });
  const handlePagination = (event, value) => {
    setIsLoading(true);
    GetPollsInfo({
      limit: limit,
      page: value,
      sort: sort,
    })
      .then((res) => {
        if (res.status == true) {
          setIsLoading(false);
          setDocs(res.data.docs);
          setPage(parseInt(res.data.page));
          setLimit(parseInt(res.data.limit));
          setCount(parseInt(res.data.totalPages))
        }
      })
      .catch((error) => error);
  }
  const onSortChange = (event) => {
    setSort(event.target.value);
  };
  const getPollsInfo = () => {
    setIsLoading(true);
    GetPollsInfo(({
      limit: limit,
      page: page,
      sort: sort,
    }))
      .then((res) => {
        if (res.status == true) {
          setIsLoading(false);
          setDocs(res.data.docs);
          setPage(parseInt(res.data.page));
          setLimit(parseInt(res.data.limit));
          setCount(parseInt(res.data.totalPages))
        }
      })
      .catch((error) => error);
  };
  //getPollsForRank
  const getPollsForRank = () => {
    setIsLoading(true);
    GetPollsForRank()
      .then((res) => {
        if (res.status == true) {
          setIsLoading(false);
          setRankList(res.data);
          setAdded(false)
        }
      })
      .catch((error) => error);
  };
  const getDeletePolls = (pollId) => {
    setIsLoading(true);
    const body = {
      poll: pollId,
    };

    DeletePolls(body)
      .then((response) => {
        if (response.status) {
          setAlertModal(true);
          setIsLoading(false);
          getPollsInfo();
        }
      })
      .catch((error) => error);
  };
  const OnClickAddPolls = () => {
    setTitle('');
    setInputList([{ value: '', image: '' }]);
    setIsHomeTagged(true);
    setExpiry(0);
    setBgImage('');
    setPreviewModal({
      imageData: undefined,
      show: false,
      file: null,
    });
    showAddHandler(true);
  };
  const OnClickEditPolls = (
    id,
    title,
    expiry,
    isHomeTagged,
    bgImage,
    options
  ) => {
    // const formattedExpiry = _dateTimeFormat(expiry, 'ddd, DD MMM hh:mm A');
    showEditHandler(true);
    setId(id);
    setTitle(title);
    setExpiry(expiry);
    setIsHomeTagged(isHomeTagged);
    setBgImage(bgImage);
    setInputList(options);
    setPreviewModal({
      imageData: fileImage,
      show: true,
      file: file,
    });
  };

  const addPolls = () => {
    setIsLoading(true);
    setDisable(true);
    if (title == "" || title == undefined) {
      alert("Please add title");
      setDisable(false);
      setIsLoading(false);
    }
    else if (expiry <= 0) {
      alert("Expiry cannot be less than or equal 0");
      setDisable(false);
      setIsLoading(false);
    }
    else if (inputList.length < 2) {
      alert("Please add more than 1 option");
      setDisable(false);
      setIsLoading(false);
    }
    else {
      for(let input of inputList){
        if(input.value == ''){
          alert('Option cannot be empty');
          setDisable(false);
          setIsLoading(false);
          return;
        }
      }
      const body = {
        title: title,
        options: inputList,
        isHomeTagged: isHomeTagged,
        expiryHours: expiry,
        bgImage: bgImage,
        rank: 0,
      };
      AddPolls(body)
        .then((response) => {
          if (response.status == true) {
            showAddHandler(false);
            alert('Polls Added Successfully');
            getPollsInfo();
            setTitle('');
            setInputList([{ value: '', image: '' }]);
            setIsHomeTagged(true);
            setExpiry(0);
            setBgImage('');
            getPollsForRank();
            setAdded(true);
            setDisable(false);
            setIsLoading(false);
            setPreviewModal({
              imageData: undefined,
              show: false,
              file: null,
            });
          } else {
            alert('Something went wrong');
            setDisable(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          error; setDisable(false);
          setIsLoading(false);
        });
    }

  };
  const editPollsInfo = () => {
    setDisable(true);
    setIsLoading(true);
    if (title == "" || title == undefined) {
      alert("Please add title");
      setDisable(false);
      setIsLoading(false);
    }
    else if (expiry <= 0) {
      alert("Expiry cannot be less than or equal to 0");
      setDisable(false);
      setIsLoading(false);
    }
    else if (inputList.length < 2) {
      alert("Please add more than 1 option");
      setDisable(false);
      setIsLoading(false);
    }
    for (let input of inputList) {
      if (input.value == '') {
        alert('Option cannot be empty');
        setDisable(false);
        setIsLoading(false);
        return;
      }
    }
    const body = {
      id: id,
      title: title,
      expiryHours: expiry,
      isHomeTagged: isHomeTagged,
      bgImage: bgImage,
      options: inputList,
    };
    EditPolls(body)
      .then((response) => {
        if (response.status == true) {
          showEditHandler(false);
          alert('Polls Edited Successfully');
          getPollsInfo();
          setDisable(false);
          setIsLoading(false);
          setTitle('');
          setInputList([{ value: '', image: '' }]);
          setIsHomeTagged(true);
          setExpiry(0);
          setBgImage('');
          setPreviewModal({
            imageData: undefined,
            show: false,
            file: null,
          });
        } else {
          alert('Something went wrong');
          setDisable(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        error; setDisable(false);
        setIsLoading(false);
      });
  };
  // useEffect(() => {
  //   setIsLoading(true);
  //   getPollsInfo();
  //   getPollsForRank();
  // }, []);


  useEffect(() => {
    getPollsInfo();
  }, [sort]);

  useEffect(() => {
    getPollsInfo();
    getPollsForRank();
  }, [editRank]);

  useEffect(() => {
    if (added) {

      setEditRank(true);
    }
    getPollsInfo();
  }, [rankList]);

  // useEffect(()=>{
  //   getPollsInfo();
  // }, [rankList])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { value: '', image: '' }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const onBgImageFileSelect = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      let image = new Image();
      image.src = reader.result;
      setFileImage(reader.result);
      setFile(files[0]);
      image.onload = function () {
        setPreviewModal({
          imageData: reader.result,
          show: true,
          file: files[0],
        });
        const form = reader.result;
        setBgImage(form);
      };
    };
    setBgImage(reader.result);
  };
  //

  const onImageFileSelect = (event, index) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    const { name, value } = event.target;
    reader.onload = (e) => {
      let image = new Image();
      image.src = reader.result;
      setFileImage(reader.result);
      setFile(files[0]);
      image.onload = function () {
        setPreviewModal({
          imageData: reader.result,
          show: true,
          file: files[0],
        });
        const formData = reader.result;

        //
        const list = [...inputList];
        list[index][name] = formData;
        setInputList(list);
      };
    };
  };

  const onClose = () => {
    setConfirmationModal({ show: false })
  }
  //
  return (
    <>
      {confirmationModal.show && <ConfirmationModal message={confirmationModal.message}
        title={confirmationModal.title}
        onClose={onClose}
        onSuccess={() => { setConfirmationModal({ show: false }); getDeletePolls(deleteItem) }} />}
      {editRank && rankList && <div>
        <ShowRank setEdit={setEditRank} list={rankList} />
      </div>}
      <div className="relative bg-blueGray-100">
        {/*Add Polls*/}
        <div className="edit__popup">
          <div
            onClick={() => showAddHandler(false)}
            className={showAdd ? 'edit__backdrop active' : 'edit__backdrop'}
          ></div>
          <div
            className={showAdd ? 'edit__popup-body active' : 'edit__popup-body'}
            style={{ width: '60vw', height: '75vh', overflowY: 'scroll' }}
          >
            <div className="edit__popup-inner">
              {/* <div style={{ fontWeight: 'bold' }}> Author Name- {authorName}</div> */}
              <i
                onClick={() => {
                  showAddHandler(false);
                }}
                className="far fa-times-circle edit__popup-close"
              ></i>
              <div>
                Title*{' '}
                <input
                  type="text"
                  value={title}
                  placeholder="Enter Title"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  style={{
                    width: '60%',
                    height: '33px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                />
              </div>
              <div>
                ExpiryHours*{''}
                <input
                  type="number"
                  value={expiry}
                  onChange={(e) => {
                    setExpiry(e.target.value);
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  style={{
                    width: '60%',
                    height: '33px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                />
              </div>
              <div>
                IsHomeTagged:{''}
                <input
                  type="boolean"
                  value={isHomeTagged}
                  onChange={(e) => {
                    setIsHomeTagged(e.target.value);
                  }}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                  style={{
                    width: '60%',
                    height: '33px',
                    margin: '1.2%',
                    marginRight: '4%',
                  }}
                />
              </div>
              <div>
                Select any background Image
                <div style={{ display: 'flex' }}>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => onBgImageFileSelect(e)}
                    style={{
                      backgroundColor: '#000',
                      height: 60,
                      width: 60,
                      position: 'absolute',
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />
                  <i
                    className="fas fa-camera fa-2x"
                    style={{
                      margin: 8,
                      color: '#555',
                      cursor: 'pointer',
                    }}
                  ></i>
                  {previewModal.imageData && (
                    <img
                      src={bgImage}
                      style={{
                        width: 50,
                        height: 30,
                        border: '2px solid gray',
                        marginTop: '10px',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </div>
              </div>

              <div>Option*(Select Atleast 2 options)</div>
              {inputList && inputList?.map((x, i) => {
                return (
                  <div>
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginTop: '10px' }}>Value</span>
                      <input
                        name="value"
                        placeholder="Enter Value"
                        value={x.value}
                        onChange={(e) => handleInputChange(e, i)}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                        style={{
                          width: '60%',
                          height: '33px',
                          margin: '1.2%',
                          marginRight: '4%',
                        }}
                      />
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ display: 'flex' }}>
                            <input
                              type="file"
                              name="image"
                              accept="image/*"
                              onChange={(e) => onImageFileSelect(e, i)}
                              style={{
                                backgroundColor: '#000',
                                height: 60,
                                width: 60,
                                position: 'absolute',
                                opacity: 0,
                                cursor: 'pointer',
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: '#555',
                                cursor: 'pointer',
                              }}
                            ></i>
                            {previewModal.imageData && (
                              <img
                                src={x.image}
                                style={{
                                  width: 50,
                                  height: 30,
                                  border: '2px solid gray',
                                  marginTop: '10px',
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      {inputList?.length !== 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ marginTop: '10px' }}
                          onClick={() => handleRemoveClick(i)}
                        >
                          Remove
                        </Button>
                      )}
                      {inputList?.length - 1 === i && (
                        <Button
                          onClick={handleAddClick}
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            marginLeft: '10px',
                            marginTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          Add More Options
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addPolls()}
                  disabled={disable}
                  size="small"
                  style={{ marginBottom: '1.5%' }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          {/*Edit Polls*/}
        </div>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Poll has been deleted successfully!"
                onSuccess={() => setAlertModal(false)}
              />
            )}
          </div>
        </div>
        <div
          className={
            'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
            (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
          }
        >
          <div className="rounded-t mb-0 px-2 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                <h3
                  className={
                    'font-semibold text-lg ' +
                    (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
                  }
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex', alignItems: "center" }}>

                    {'Polls'}
                    <div style={{ marginLeft: "20px", display: "flex", alignItems: 'center' }}>

                      <label style={{ fontSize: "15px" }}>Sort</label>
                      <div
                        className="relative w-full  max-w-full flex-grow flex-1"
                        style={{ textAlign: 'right', }}
                      >

                        <span style={{ marginLeft: 10 }}>
                          <PollsSortDropDown
                            onChange={onSortChange}
                            value={sort}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>

                    <div
                      className="relative w-full  max-w-full flex-grow flex-1"
                      style={{ textAlign: 'right' }}
                    >
                      <span style={{ marginLeft: 5 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => OnClickAddPolls()}
                          style={{ marginBottom: '1.5%' }}
                        >
                          Add Polls
                        </Button>
                      </span>
                    </div>
                    <div
                      className="relative w-full  max-w-full flex-grow flex-1"
                      style={{ textAlign: 'right' }}
                    >
                      <span style={{ marginLeft: 5 }}>
                        <Button
                          variant="contained"
                          onClick={() => { setEditRank(true) }}
                          style={{ marginBottom: '1.5%', backgroundColor: "#6cbf51" }}
                        >
                          Edit Rank
                        </Button>
                      </span>
                    </div>
                  </div>
                </h3>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  position: 'relative',
                  left: '3.4%',
                }}
              >
                <div className="wrapper"></div>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto">
            {/* Projects table */}
            <table className="table-fixed">
              <thead>
                <tr>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Rank
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Title
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Total Votes
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Created At
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Expiry
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Options
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Background Image
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    STATUS
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    EDIT POLLS
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody id="myTable">
                {/* map */}

                {docs &&
                  docs.map((item, index) => (
                    <>
                      <tr>
                        <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          {item?.rank == -1 ? "Expired" : item.rank}
                        </td>
                        <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          {item?.title}
                        </td>
                        <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                          {item?.totalVotes}
                        </td>
                        <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                          {_dateTimeFormat(item?.createdAt, 'ddd, DD MMM hh:mm A')}
                        </td>
                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {item?.expiry == 0
                            ? 'Never'
                            : _dateTimeFormat(item?.expiry, 'ddd, DD MMM hh:mm A')}
                        </td>
                        <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                          {item?.options.map((option, index) => {
                            return (
                              <table className="table-fixed">
                                <thead>
                                  <tr>
                                    <th
                                      className={
                                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                        (props.color === 'light'
                                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                      }
                                    >
                                      Image
                                    </th>
                                    <th
                                      className={
                                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                        (props.color === 'light'
                                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                      }
                                    >
                                      Value
                                    </th>
                                    <th
                                      className={
                                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                        (props.color === 'light'
                                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                      }
                                    >
                                      Percentage
                                    </th>
                                    <th
                                      className={
                                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                        (props.color === 'light'
                                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                      }
                                    >
                                      Votes
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      <img
                                        src={option.image}
                                        alt={option.image}
                                        style={{
                                          width: 50,
                                          height: 30,
                                          border: '2px solid gray',
                                          cursor: 'pointer',
                                        }}
                                      />
                                      {/* <img
                                            src={option.image}
                                            //alt={option.image}
                                            style={{
                                            width: 50,
                                            height: 30,
                                            border: '2px solid gray',
                                          }}
                                        /> */}
                                    </td>
                                    <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {option.value}
                                    </td>
                                    <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {option.percentage}
                                    </td>
                                    <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {option.votes}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            );
                          })}
                        </td>
                        <td
                          className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          style={{ cursor: 'pointer' }}
                        >
                          <img src={item?.bgImage} />
                        </td>
                        <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {item?.status == true ? (
                            <span
                              style={{
                                color: 'green',
                                fontWeight: 'bold',
                              }}
                            >
                              Published
                            </span>
                          ) : (
                            <span
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                              }}
                            >
                              Draft
                            </span>
                          )}
                        </td>
                        <td
                          className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          style={{ cursor: 'pointer', justifyContent: 'center' }}
                        >
                          {
                            (item?.expiry > new Date() || item?.expiry == 0) ? 
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "rgb(5 150 105)", text: "white" }}
                              onClick={() => {
                                OnClickEditPolls(
                                  item?._id,
                                  item?.title,
                                  item?.expiry == 0 ? 0 : Math.ceil((new Date(item?.expiry) - new Date()) / 3600000),
                                  item?.isHomeTagged,
                                  item?.bgImage,
                                  item?.options
                                )
                              }
                              }
                            >

                                EDIT
                              </Button> : null
                          }
                        </td>

                        <td
                          className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          style={{ cursor: 'pointer' }}
                        >
                          <div
                            onClick={() => { setDelete(item); setConfirmationModal({ show: true, title: "Delete", message: "Are you sure you want to delete." }) }}
                            className="text-center"
                          >
                            <i className="fas fa-trash"></i>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 20,
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  page={page}
                  onChange={handlePagination}
                  count={count}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
              {/* console.log */}
            </div>
            {/*Edit Polls*/}
            <div className="edit__popup">
              <div
                onClick={() => showEditHandler(false)}
                className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
              ></div>
              <div
                className={
                  showEdit ? 'edit__popup-body active' : 'edit__popup-body'
                }
                style={{ width: '60vw', height: '75vh', overflowY: 'scroll' }}
              >
                <div className="edit__popup-inner">
                  {/* <div style={{ fontWeight: 'bold' }}> Author Name- {authorName}</div> */}
                  <i
                    onClick={() => {
                      showEditHandler(false);
                    }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                  <div>
                    Title*{' '}
                    <input
                      type="text"
                      value={title}
                      placeholder="Enter Title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                      style={{
                        width: '60%',
                        height: '33px',
                        margin: '1.2%',
                        marginRight: '4%',
                      }}
                    />
                  </div>
                  <div>
                    ExpiryHours*{''}
                    <input
                      type="number"
                      value={expiry}
                      onChange={(e) => {
                        setExpiry(e.target.value);
                      }}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                      style={{
                        width: '60%',
                        height: '33px',
                        margin: '1.2%',
                        marginRight: '4%',
                      }}
                    />
                  </div>
                  <div>
                    IsHomeTagged:{''}
                    <input
                      type="boolean"
                      value={isHomeTagged}
                      onChange={(e) => {
                        setIsHomeTagged(e.target.value);
                      }}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                      style={{
                        width: '60%',
                        height: '33px',
                        margin: '1.2%',
                        marginRight: '4%',
                      }}
                    />
                  </div>
                  <div>
                    Select any background Image
                    <div style={{ display: 'flex' }}>
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={(e) => onBgImageFileSelect(e)}
                        style={{
                          backgroundColor: '#000',
                          height: 60,
                          width: 60,
                          position: 'absolute',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                      <i
                        className="fas fa-camera fa-2x"
                        style={{
                          margin: 8,
                          color: '#555',
                          cursor: 'pointer',
                        }}
                      ></i>
                      {previewModal.imageData && (
                        <img
                          src={bgImage}
                          style={{
                            width: 50,
                            height: 30,
                            border: '2px solid gray',
                            marginTop: '10px',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div>Option*(Select Atleast 2 options)</div>
                  {inputList?.map((x, i) => {
                    return (
                      <div>
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginTop: '10px' }}>Value</span>
                          <input
                            name="value"
                            placeholder="Enter Value"
                            value={x.value}
                            onChange={(e) => handleInputChange(e, i)}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                            style={{
                              width: '60%',
                              height: '33px',
                              margin: '1.2%',
                              marginRight: '4%',
                            }}
                          />
                          <div className="row">
                            <div className="col-lg-12">
                              <div style={{ display: 'flex' }}>
                                <input
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  onChange={(e) => onImageFileSelect(e, i)}
                                  style={{
                                    backgroundColor: '#000',
                                    height: 60,
                                    width: 60,
                                    position: 'absolute',
                                    opacity: 0,
                                    cursor: 'pointer',
                                  }}
                                />
                                <i
                                  className="fas fa-camera fa-2x"
                                  style={{
                                    margin: 8,
                                    color: '#555',
                                    cursor: 'pointer',
                                  }}
                                ></i>
                                {previewModal.imageData && (
                                  <img
                                    src={x.image}
                                    style={{
                                      width: 50,
                                      height: 30,
                                      border: '2px solid gray',
                                      marginTop: '10px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          {inputList?.length !== 1 && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ marginTop: '10px' }}
                              onClick={() => handleRemoveClick(i)}
                            >
                              Remove
                            </Button>
                          )}
                          {inputList?.length - 1 === i && (
                            <Button
                              onClick={handleAddClick}
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{
                                marginLeft: '10px',
                                marginTop: '10px',
                                textAlign: 'center',
                              }}
                            >
                              Add More Options
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => editPollsInfo()}
                      size="small"
                      disabled={disable}
                      style={{ marginBottom: '1.5%' }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'center', padding: 10 }}>
              <SyncLoader
                color="#23A5E0"
                loading={isLoading}
                css={override}
                size={12}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
  };
};

export default connect(mapStateToProps)(PollsInfo);