import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  dispatchNewsList,
  dispatchNewsListFilter,
} from '../../../redux/action-dispatchers/homeNews.dispatchers';
import _ from 'lodash';
import { connect } from 'react-redux';
import Sidebar from '../../../components/Sidebar/Sidebar';
import '../HomePage/HomeNews';
import { Link, useHistory } from 'react-router-dom';
import {
  //   updateHomeRanking,
  getKabaddiSpecial,
  KabaddiSpecialNews,
  //   tagHome,
} from '../../../handlers/Kabaddi.handlers';
import { _dateTimeFormat, _getDateTime } from '../../../constants/appDefaults';
import AlertModal from '../../../components/AlertModal';
import KabaddiDropDown from '../KabaddiSpecial/KabaddiDropdown';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const KabaddiSpecial = (props) => {
  const [render, setRender] = React.useState(false);
  const [section, setSection] = React.useState('home');
  const [page, setPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [limit, SetLimit] = React.useState(10);
  const [docs, setDocs] = useState([]);
  const [content, setContent] = useState([]);
  const [updateDocs, setUpdateDocs] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [showAction, setShowAction] = React.useState(false);
  const [articleResult, setArticleResult] = useState([]);
  const mHistory = useHistory();
  const [homeType, setHomeType] = useState('');

  const color = 'light';
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(docs, source.index, destination.index);
    const docsUpdated = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        homeTagRanking: index + 1,
      });
    });
    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setDocs(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const removeNewsFromHome = (id) => {
    const body = {
      mediaType: 'article',
      article: id,
      // ranking: '1',
      isTagg: false,
    };
    KabaddiSpecialNews(body)
      .then((response) => {
        if (response.status) {
          getNews();
        }
      })
      .catch((error) => {});
  };
  const getNews = (page, _limit, isLoadMore) => {
    setIsLoading(true);
    getKabaddiSpecial()
      .then((response) => {
        setIsLoading(false);
        setContent(response.data);
        //
        setHasNextPage(response.data.hasNextPage);
        setPage(response.data.page);
        //
        // if (response.message) {
        //   if (isLoadMore) {
        //     setDocs([...docs, response.data.docs]);
        //     setIsLoading(true);
        //   } else {
        //     setDocs(response.data.docs);
        //   }
        // }
        response.data.map((data) => {});
        const resultArticle = response.data.filter(
          (mediaType) => mediaType.mediaType == 'article'
        );

        setArticleResult(resultArticle);
        if (response.status) {
          if (isLoadMore) {
            //
            //dispatchNewsList(response.data);
            setDocs([...docs, ...response.data.docs]);
            //setIsLoading(true);
          } else {
            setDocs(response.data.docs);
          }
        }
      })

      .catch((error) => {
        isLoadMore && setIsLoadingMore(false);
      });
  };

  React.useEffect(() => {
    getNews(parseInt(page), parseInt(limit), false);
  }, []);

  const updateNews = () => {
    // updateHomeRanking(updateDocs, {
    //   Authorization: props.Authorization,
    // })
    //   .then((response) => {
    //     if (response.status) {
    //       setUpdateDocs([]);
    //       setAlertModal(true);
    //       setShowAction(false);
    //       getNews(parseInt(page), parseInt(limit), false);
    //     }
    //   })
    //   .catch((error) => {});
  };

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  const onHomeTypeChange = (event) => {
    setHomeType(event.target.value);
  };
  if (homeType === 'news') {
    mHistory.push(`/home-page-news`);
  } else if (homeType === 'specailVideos') {
    mHistory.push(`/home-page-videos`);
  } else if (homeType === 'live_blog') {
    mHistory.push(`/live_blog`);
  } else {
  }
  //
  const onView = (newsId) => {
    mHistory.push(`/news/${newsId}`);
  };

  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your article has been updated successfully!"
                onSuccess={() => setAlertModal(false)}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                  <div className="flex flex-wrap items-center">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Home Special News
                      {/* <p
                        className={
                          'font-semibold text-lg ' +
                          (color === 'light'
                            ? 'text-blueGray-700'
                            : 'text-white')
                        }
                      >
                        Docs: {content.length}
                      </p> */}
                    </h3>

                    {showAction && (
                      <div className={'save-reset enabled'}>
                        <div onClick={() => updateNews()} className="save btn">
                          Save
                        </div>

                        <div
                          onClick={() =>
                            getNews(parseInt(page), parseInt(limit), false)
                          }
                          className="reset btn"
                        >
                          Reset
                        </div>
                      </div>
                    )}
                    <div
                      className="relative w-full px-10 max-w-full flex-grow flex-1"
                      style={{ textAlign: 'right' }}
                    >
                      <KabaddiDropDown
                        selectedValue={homeType}
                        onChange={onHomeTypeChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Thumb Image
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Headline
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Sports
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Type
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Published Date
                              </th>
                              {/* <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Expiry_Date Time
                              </th> */}
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Status
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Home
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Top
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {!isLoading ? (
                              getNews
                            ) : (
                              <PulseLoader
                                color="#23A5E0"
                                css={override}
                                size={10}
                              />
                            )}

                            {articleResult &&
                              articleResult.map((news, index) => {
                                return (
                                  <Draggable
                                    key={news._id}
                                    draggableId={news._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  ">
                                          {/* <AspectRatio
                                   ratio={16 / 9}
                                   style={{
                                     background : `url('${news.imgUrl}')`,
                                     width:'450px',
                                     height:'253px',
                                     backgroundSize : 'cover',
                                     backgroundRepeat :'no-repeat'
                                   }}
                                 > */}
                                          {/* <img
                                     src={news.imgUrl}
                                     className="h-12 w-12 bg-white"
                                     alt="..."
                                   ></img> */}
                                          {/* </AspectRatio> */}
                                          <div
                                            style={{
                                              background: `url('${news.article.imgUrl}?${news.article._id}') 0% 0% / cover no-repeat`,
                                              width: '100px',
                                              height: '53px',
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                            }}
                                          ></div>
                                        </th>
                                        <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          onClick={() =>
                                            onView(news.article._id)
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            fontWeight: 'bolder',
                                          }}
                                        >
                                          {news.article.headline}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.article.sports &&
                                          news.article.sports.name
                                            ? news.article.sports.name
                                            : 'N/A'}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.article.copyrights}
                                        </td>
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {leagueName(news.article.league)}
                                          {/* {news.article.league ? news.article.league : 'N/A'} */}
                                        </td>

                                        {/* news publish time */}
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {_dateTimeFormat(
                                            news.article.PublishedAt,
                                            'ddd, DD MMM hh:mm A'
                                          )}
                                          {/* {news.article.copyrights === "PTI"
                                            ? props.selectedLanguage ===
                                              "english"
                                              ? _dateTimeFormat(
                                                  news.article.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.article.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}
                                          {news.article.copyrights === "Opta"
                                            ? props.selectedLanguage ===
                                              "english"
                                              ? _dateTimeFormat(
                                                  news.article.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.article.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}
                                          {news.article.copyrights === "PTI_Bhasha"
                                            ? props.selectedLanguage === "hindi"
                                              ? _dateTimeFormat(
                                                  news.article.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                              : _getDateTime(
                                                  news.article.PublishedAt,
                                                  "ddd, DD MMM hh:mm A"
                                                )
                                            : ""}                    */}
                                          {/* {news.article.copyrights === 'PTI'
                                   ? _dateTimeFormat(news.article.PublishedAt, 'ddd, DD MMM hh:mm A')
                                   : _getDateTime(news.article.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                                          {/* {news.article.copyrights === 'PTI_Bhasha'
                                   ? _dateTimeFormat(news.article.PublishedAt, 'ddd, DD MMM hh:mm A')
                                   : _getDateTime(news.article.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                                        </td>
                                        {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {_dateTimeFormat(
                                            news.article.homeTagExpiry,
                                            'ddd, DD MMM hh:mm A'
                                          )}
                                        </td> */}
                                        <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.article.showArticle ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Published
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Unpublished
                                            </span>
                                          )}
                                        </td>
                                        <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.article.tagHome ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Tagged
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Untagged
                                            </span>
                                          )}
                                        </td>
                                        <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                          {news.article.section ==
                                          'TOP_NEWS' ? (
                                            <span
                                              style={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Tagged
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Untagged
                                            </span>
                                          )}
                                        </td>
                                        <td
                                          className="border border-green-800"
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <div
                                            onClick={() =>
                                              removeNewsFromHome(
                                                news.article._id
                                              )
                                            }
                                            className="text-center"
                                          >
                                            <i className="fas fa-trash"></i>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </tbody>
                        </table>

                        {provided.placeholder}
                        {hasNextPage && !isLoadingMore && (
                          <div style={{ textAlign: 'center', padding: 20 }}>
                            <button
                              className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                              type="button"
                              onClick={() => {
                                getNews(
                                  parseInt(page) + 1,
                                  parseInt(limit),
                                  true
                                );
                              }}
                            >
                              Load More
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
    //hasNextPage: state.homeNewsReducer.hasNextPage,
    //page: state.homeNewsReducer.page,
    limit: state.homeNewsReducer.limit,
    //docs: state.newsReducer.docs,
  };
};
export default connect(mapStateToProps)(KabaddiSpecial);
