import React, { useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ConfirmationModal from '../../../components/ConfirmationModal';
import CropperModal from '../../../components/CropperModal';
import PreviewModal from '../../../components/PreviewModal';
import UnsavedChangesWarning from '../../../components/AlertModal/UnsavedChangesWarning';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import {
  addNewArticle,
  draftNewArticle,
} from '../../../handlers/news.handlers';
const AddPoles = (props) => {


    const [selectedImage, setSelectedImage] = useState();
    const [showCropper, setShowCropper] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [previewModal, setPreviewModal] = useState({
      show: false,
      imageData: '',
      file: '',
    });
    const [confirmationModal, setConfirmationModal] = useState({
      show: false,
      title: '',
      message: '',
    });
    const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
    const [formData, setFormData] = useState({
      file: undefined,
      title: '',
      isHomeTagged:'',
      expiryHours:'',
      options:[],
      rank:0,
    });

  const onClose = () =>
    setConfirmationModal({ message: '', show: false, title: '' });
  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) =>
    setShowCropper(false);
  const onSuccess = () =>
    setConfirmationModal({ message: '', show: false, title: '' });
  const onPreviewSuccess = () =>
    setPreviewModal({
      imageData: previewModal.imageData,
      file: previewModal.file,
      show: false,
    });
  const onPreviewClose = () =>
    setPreviewModal({ imageData: '', file: '', show: false });
  const ConfirmationModalWrapper = () => (
    <ConfirmationModal
      message={confirmationModal.message}
      title={confirmationModal.title}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );

  const CropperModalWrapper = () => (
    <CropperModal
      image={selectedImage}
      onClose={onCropperClose}
      closeCropperAndSetData={closeCropperAndSetData}
    />
  );

  const PreviewModalWrapper = () => (
    <PreviewModal
      imageData={previewModal.imageData}
      title="Upload Video Image"
      message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
      onSuccess={onPreviewSuccess}
      onClose={onPreviewClose}
    />
  );

  const onImageFileSelect = (event) => {
    let file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
            file: file,
          });
        } else {
          alert('Image width and height must be 1920x1080 px');
          return false;
        }
      };
    };
    reader.onerror = function (error) { };
    setSelectedImage(reader.result);
    setIsUnsaved();
  };
  const onFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setIsUnsaved();
  };
  const onLeagueChange = (league, uuid) =>
    setFormData({ ...formData, league, uuid });
  const onSportChange = (value) =>
    setFormData({ ...formData, sportsId: value });
  const onAuthorSelected = (e) => {
    setFormData({ ...formData, authorId: e.target.value });
  };
  const history = useHistory();
  const onAddArticle = (showArticleStatus) => {
    if (previewModal && previewModal == null) {
      setConfirmationModal({
        message: 'Please select the image with correct format',
        show: true,
        title: '',
      });
    } else if (formData.headline.length == 0) {
      setConfirmationModal({
        message: 'Headline Can not be empty',
        show: true,
        title: '',
      });
    } else if (formData.rank == 0) {
      setConfirmationModal({
        message: 'Rank Can not be empty or 0',
        show: true,
        title: '',
      });
    } else if (formData.story.length == 0) {
      setConfirmationModal({
        message: 'Descripiton Can not be empty',
        show: true,
        title: '',
      });
    } else if (formData.sportsId.length == 0) {
      setConfirmationModal({
        message: 'Sports is not tagged, please tag sports',
        show: true,
        title: '',
      });
    } else if (formData.league.length == 0) {
      setConfirmationModal({
        message: 'League is not tagged, please tag league',
        show: true,
        title: '',
      });
    } else if (formData.slug.length == 0) {
      setConfirmationModal({
        message: 'Keyword cannot be empty, please enter keywords',
        show: true,
        title: '',
      });
    } else {
      setFormData({ ...formData, showArticle: showArticleStatus });
      setIsLoading(true);
      addNewArticle(
        {
          ...formData,
          showArticle: showArticleStatus,
          image: previewModal.file,
        },
        { Authorization: props.Authorization }
      )
        .then((response) => {
          if (response.status) {
            setIsLoading(false);
            alert(response.message);
            history.push('/news');
          } else {
            alert(response.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert('Eror while adding article', error);
        });
    }
  };
  return (
    <>
      <Sidebar />
      <Link
        className=" pb-4"
        style={{
          cursor: 'pointer',
          marginLeft: '10px',
          marginBottom: '-30px',
          width: '30px',
          fontSize: '15px',
          display: 'flex',
          flexDirection: 'row',
          fontWeight: 'bold',
          color: '#1e1e1e',
        }}
        to="/polls"
      >
        <ArrowBackIcon />
      </Link>
      {confirmationModal.show && <ConfirmationModalWrapper />}
      {/*{showCropper && <CropperModalWrapper />}*/}
      {/*{previewModal.show && <PreviewModalWrapper />}*/}

      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div className="row">
              <div className="col-lg-12">
                <div>*Please upload image in the dimension of 1920x1080</div>
                <div>
                  {previewModal.imageData && (
                    <img
                      src={previewModal.imageData}
                      style={{
                        width: 500,
                        height: 300,
                        border: '2px solid gray',
                      }}
                    />
                  )}
                  <input
                    type="file"
                    name="file"
                    onChange={onImageFileSelect}
                    style={{
                      backgroundColor: '#000',
                      height: 60,
                      width: 60,
                      position: 'absolute',
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />

                  <i
                    className="fas fa-camera fa-2x"
                    style={{
                      margin: 8,
                      color: '#555',
                      cursor: 'pointer',
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div style={{ marginTop: '20px' }}>
                <label>Title*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Headline *"
                  name="headline"
                  value={formData.headline}
                  style={styles.inputBox}
                  onChange={onFormDataChange}
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                <label>Expiry*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Keywords - ( Sports, player name, etc ) *"
                  name="slug"
                  value={formData.slug}
                  onChange={onFormDataChange}
                  style={styles.inputBox}
                />
              </div>
              <div className="col-lg-12">
                <button
                  onClick={() => {
                    onAddArticle(true);
                    setPristine();
                  }}
                  className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Add Polls
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Prompt}
    </>
  )
}
const styles = {
  inputBox: {
    width: '500px',
    height: '30px',
    marginTop: 5,
    borderRadius: '5px',
  },
};

export default AddPoles