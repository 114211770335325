import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DisplayFilterDropdown from '../../../components/Dropdowns/DisplayFilterDropdown';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  validate,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import {
  getAllUser,
  getActionToggled,
  deleteUser,
  getSearch,
  getDateSearch,
} from '../../../handlers/admin.handlers';
import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
import axios from 'axios';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const UserManagerInfo = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allUser, setAllUser] = useState([]);
  const [arr, setArr] = useState([]);
  const [filterType, setFilterType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
  let Current_Language = localStorage.getItem('Current_Language');
  const headers = {
    //Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWZlcmVuY2UiOiI2MWFmNGEwYmZmYmIzMDdlYjM4ZmUwMGYiLCJ0b2tlblR5cGUiOiJBVVRIX1RPS0VOIiwiaWF0IjoxNjM5NTczMzM4LCJleHAiOjE2NDAyNzMzMzh9.QFmZKYiY6Vq_8oC2BPx3ISQqLphuSUEwhErvV6xla_Q5LzEYMwyOLO34uTDv_IxuL3rNZctVsKu3WyYMDFUKCXi6kA10qMOZ5ylwhIqhoSrRT6_eAuTKFY9ddjZQ6AiFT3Z5UupLAQ2eaEwaCuDq-hKAKnc5xleYGUBiEBeGE6nh5ANbnuUW7i1-kffgBwkji9ctLeYVAmnvOatxI3NpYytLj5s3Vy8O49WUwmB0YBPTbFfXvsC-VmLMlZfz7NF_FC9nwmUjN85UIp1wZ6LDT9RZfcNwdxllC_LvxXlKGp9BkrcOCVg51-8wImgX1qcwjhH1yuo0GlFfpoYJwJXMCg',
    Authorization: props.Authorization,
  };

  const checkStartDate = (time) => {
    setStartDate(time);
  };
  const checkEndDate = (time) => {
    setEndDate(time);
  };

  const getAllUserContent = () => {
    getAllUser(headers)
      .then((res) => {
        if (res.status == true) {
          setIsLoading(false);
          setAllUser(res.data);
        }
      })
      .catch((err) => err);
  };

  const actionToggled = (emailId) => {
    getActionToggled(emailId, headers)
      .then((res) => {
        if (res.status == true) {
          setToggle(!toggle);
        }
        getAllUserContent();
      })
      .catch((err) => err);
  };

  const deleteUserContent = (id) => {
    // const body = {
    //   ids: arr,
    // };
    // var config = {
    //   method: 'delete',
    //   url: 'https://develop-rest-microservice.thesportstak.mobiletak.com/authentication/api/cmsUser/deleteUs?id=631837d48e2cfd9feaa9ed4d&language=English',
    //   headers: { }
    // };

    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
    deleteUser(headers, id, Current_Language)
      .then((res) => {
        if (res.status == true) {
          alert('User Deleted successfully');
          getAllUserContent();
        }
      })
      .catch((err) => alert(err));
  };

  const validatioCheck = (event) => {
    if (event.target.checked == 1) {
      setArr((prevArr) => [...prevArr, event.target.value]);
    } else {
      setArr((prevArr) => prevArr.filter((item) => item != event.target.value));
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getAllUserContent();
  }, [props.Authorization]);

  const onFilterChange = (event) => {
    setFilterType(event.target.value);
  };
  const onSearch = (event) => {
    setSearchValue(event.target.value);
    getSearch(filterType, event.target.value, headers)
      .then((res) => {
        if (res.status == true) {
          setAllUser(res.data.data);
        } else if (allUser.length <= 2) {
          getAllUserContent();
        }
      })
      .catch((error) => error);
  };

  {
    /*const onDateSearch=()=>{
    
    const body={
      fromDate:{
        fyear:"",
        fmonth:"",
        fday:"",
      },
      toDate:{
        fyear:"2021",
        fmonth:"12",
        fday:"25",
      }
    }
    getDateSearch(headers,body)
    .then((res)=>{
      setse(res.data)
    })
  }*/
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  const res = () => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', props.Authorization);
    myHeaders.append('Content-Type', 'application/json');

    const stringDateStart = convert(startDate.toString());
    const myArr = stringDateStart.split('-');
    const stringDateEnd = convert(endDate.toString());
    const myArrEnd = stringDateEnd.split('-');

    var raw = JSON.stringify({
      fromDate: {
        fyear: myArr[0],
        fmonth: myArr[1],
        fday: myArr[2],
      },
      toDate: {
        tyear: myArrEnd[0],
        tmonth: myArrEnd[1],
        tday: myArrEnd[2],
      },
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(
      'https://develop-rest-microservice.thesportstak.com/authentication/api/admin/onlyDateSearch',
      requestOptions
    )
      .then((response) => response.json())
      .then((docs) => setAllUser(docs.result))
      .catch((error) => console.log('error', error));
  };

  return (
    <div>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1 py-4">
              <Link
                className=" pb-4"
                style={{
                  cursor: 'pointer',
                  marginLeft: '10px',
                  marginBottom: '-30px',
                  width: '30px',
                  fontSize: '15px',
                  display: 'flex',
                  flexDirection: 'row',
                  fontWeight: 'bold',
                  color: '#1e1e1e',
                }}
                to="/"
              >
                <ArrowBackIcon />
                Home
              </Link>

              <h3
                className={
                  'font-semibold text-lg ' +
                  (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '24px',
                }}
              >
                USER MANAGER
              </h3>
            </div>
            <div>
              <span style={{ marginRight: '20px' }}>
                <DisplayFilterDropdown
                  selectedValue={filterType}
                  onChange={onFilterChange}
                />
              </span>
              <span style={{ marginRight: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={deleteUserContent}
                >
                  Bulk Delete
                </Button>
              </span>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <input
                  style={{
                    border: '2px solid #acb9ce',
                    borderRadius: 10,
                    marginRight: '3%',
                    padding: '3px',
                    // paddingLeft: '10px',
                  }}
                  value={searchValue}
                  placeholder="Search "
                  onChange={onSearch}
                />
              </div>
              <div className="wrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="relative ">
                    <DateTimePicker
                      label="Start Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={startDate}
                      onChange={(e) => checkStartDate(e)}
                      style={{
                        marginRight: '10px',
                        width: '40%',
                        marginLeft: '10px',
                      }}
                    />
                    <DateTimePicker
                      label="End Date"
                      // emptyLabel="End Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={endDate}
                      onChange={(e) => checkEndDate(e)}
                      style={{
                        width: '40%',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              <Button
                onClick={res}
                variant="contained"
                color="primary"
                size="small"
                style={{ paddingLeft: '20px', paddingRight: '20px' }}
              >
                FilterDate
              </Button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  #
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  SELECT
                </th>
                {/* <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Headline
                  </th> */}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  USER ID
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  USER NAME
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  EMAIL ID
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  PHONE NUMBER
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  ROLE
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  PROFILE CREATION DATE
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                getAllUser
              ) : (
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
              )}
              {allUser &&
                allUser.map((user, i) => (
                  <tr>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {i + 1}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      <input
                        type="checkbox"
                        value={user._id}
                        onChange={(event) => validatioCheck(event)}
                      />
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {user._id}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {user.firstName}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {user.emailId}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {user.phoneNumber ? (
                        <span>{user.phoneNumber}</span>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {user.userRole ? <span>{user.userRole}</span> : 'N/A'}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {_dateTimeFormat(user.createdOn, 'ddd, DD MMM hh:mm A')}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => actionToggled(user.emailId)}
                      >
                        <i
                          class={user.action ? 'fas fa-pause' : 'fas fa-play'}
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
  };
};

export default connect(mapStateToProps)(UserManagerInfo);
