import { configStore } from '../store';
import { PlayersProfileActionTypes } from '../action-types';

export const dispatchPlayersList = (payload) => {
  configStore.dispatch({
    payload,
    type: PlayersProfileActionTypes.PLAYERS_LIST,
  });
};
export const dispatchNumberOfPlayersPages = (payload) => {
  configStore.dispatch({
    payload,
    type: PlayersProfileActionTypes.NUMBER_OF_PAGES,
  });
};
export const dispatchCurrnetPlayersPage = (payload) => {
  configStore.dispatch({
    payload,
    type: PlayersProfileActionTypes.CURRENT_PAGE,
  });
};

export const dispatchPlayersListFilter = (payload) => {
  configStore.dispatch({
    payload,
    type: PlayersProfileActionTypes.PLAYERS_LIST_FILTER,
  });
};

export const dispatchResetPlayers = () => {
  configStore.dispatch({
    type: PlayersProfileActionTypes.RESET_PLAYERS_LIST,
  });
};

export const dispatchTogglePlayersStatus = (payload) => {
  configStore.dispatch({
    payload,
    type: PlayersProfileActionTypes.PLAYERS_TOGGLE_STATUS,
  });
};
export const dispatchTopPlayersCount = (payload) => {
  configStore.dispatch({
    payload,
    type: PlayersProfileActionTypes.TOP_PLAYERS_COUNT,
  });
};
