import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const GetBanner = async () => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.BANNERS.GET_BANNER}`
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateBanner = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.BANNERS.UPDATE_BANNER}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
