import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import CropperModal from '../../../../components/CropperModal';
import {
  GetNewsInfo,
  mapArticleToSports,
  mapArticleToLeague,
  toggleArticleStatus,
  uploadArticleImage,
  ChangeNewsUpdate,
} from '../../../../handlers/news.handlers';
import LeaguesDropDown from '../../../../components/Dropdowns/LeaguesDropDown';
import SportsDropdown from '../../../../components/Dropdowns/SportsDropDown';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import {
  dispatchAllLeagues,
  dispatchSelectedLeague,
} from '../../../../redux/action-dispatchers/leagues.dispatchers';
import { GetAllDefaultLeagues } from '../../../../handlers/leagues.handlers';
import { getAllSports } from '../../../../handlers/sports.handler';
import ReactDevicePreview from 'react-device-preview';
import PreviewModal from '../../../../components/PreviewModal';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
import {
  _getDateTime,
  _dateTimeFormat,
} from '../../../../constants/appDefaults';
import {
  dispatchAllSports,
  dispatchSelectedSports,
} from '../../../../redux/action-dispatchers/sports.dispatchers';
import AlertModal from '../../../../components/AlertModal';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const TwitterInfo = (props) => {
  const [newsInfo, setNewsInfo] = useState(undefined);

  const [showCropper, setShowCropper] = useState(false);
  const [taggedLeague, setTaggedLeague] = useState('');
  const [changeLeague, setChangeLeague] = useState(false);
  const [taggedSports, setTaggedSports] = useState(false);
  const [changeSports, setChangeSports] = useState(false);
  const [topNewsChange, setTopNewsChange] = useState(false);
  const [topNewsType, setTopNewsType] = useState('');
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [experyDate, setExperyDate] = React.useState(null);
  const [dateTime, setDateTime] = useState(false);

  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
  });

  const [alertModal, setAlertModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });

  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState();
  let fileInput = React.useRef();

  useEffect(() => {
    // GetAllDefaultLeagues({
    //   Authorization: props.Authorization,
    // }).then((response) => {
    //   if (response.status) {
    //     dispatchAllLeagues(response.data);
    //   }
    // });

    getAllSports({ Authorization: props.Authorization })
      .then((response) => {
        if (response.status) {
          dispatchAllSports(response.data);
        } else {
          dispatchAllSports([]);
        }
      })
      .catch((error) => {
        dispatchAllSports([]);
      });
    getNewsInformation();
  }, []);

  useEffect(() => {
    if (newsInfo) {
      const leagueInfo = props.leagues.find(
        (item) => item.comp_id === newsInfo.league
      );
      if (leagueInfo) {
        setTaggedLeague(leagueInfo.abbr);
      }
    }
    if (newsInfo && newsInfo.sports && newsInfo.sports._id) {
      const sportsInfo = props.sports.find(
        (item) => item._id === newsInfo.sports._id
      );

      if (sportsInfo) {
        setTaggedSports(sportsInfo.name);
      }
    }
  }, [newsInfo, props.leagues, props.sports]);

  const getNewsInformation = () => {
    GetNewsInfo(id, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        setNewsInfo(response.data);
        setTopNewsType(response.data.section);
        setExperyDate(response.data.topNewsExpiry);
      }
    });
  };

  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setNewsInfo({
      ...newsInfo,
      imgUrl: imagePath,
    });
    uploadArticleImage(newsInfo._id, imageData, {
      Authorization: props.Authorization,
    })
      .then((response) => {})
      .catch((error) => {});

    setShowCropper(false);
  };

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
          });
        } else {
          alert('Image width and height must be 1920x1080 px');
          return false;
        }
      };
      setSelectedImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    toggleArticleStatus(newsInfo._id, !newsInfo.showArticle, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        getNewsInformation();
      }
    });
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const showModalForPublish = () => {
    var title = 'Publish/Unpublish Article';
    var message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const changeTopNewsType = async (event) => {
    setTopNewsType(event.target.value);
    if (event.target.value == 'TOP_NEWS') {
      setDateTime(true);
    } else {
      updateTopNewsTime(event.target.value);
    }
  };

  const updateTopNewsTime = (value, time) => {
    const data = {
      _id: newsInfo._id,
      section: value,
      topNewsExpiry: time,
    };
    ChangeNewsUpdate(data, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response.status) {
          setTopNewsChange(false);
          setDateTime(false);
          setAlertModal(true);
          getNewsInformation();
        }
      })
      .catch((error) => {});
  };

  const onLeagueSelected = (value, uuid) => {
    setChangeLeague(false);
    newsInfo &&
      value !== 0 &&
      mapArticleToLeague(newsInfo._id, value, uuid)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };
  const onSportsSelected = (value) => {
    setChangeSports(false);
    newsInfo &&
      value !== 0 &&
      mapArticleToSports(newsInfo._id, value, '')
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            alert(response.message);
          }
        })
        .catch((error) => {});
  };
  const onPreviewClose = () => setPreviewModal({ imageData: '', show: false });
  const onPreviewSuccess = () => {
    setIsImageUploading(true);
    setNewsInfo({
      ...newsInfo,
      imgUrl: previewModal.imageData,
    });
    setPreviewModal({
      imageData: '',
      show: false,
    });
    uploadArticleImage(newsInfo._id, previewModal.imageData, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setIsImageUploading(false);
      })
      .catch((error) => {
        setIsImageUploading(false);
      });
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            {showCropper && newsInfo && (
              <CropperModal
                image={selectedImage}
                onClose={onCropperClose}
                closeCropperAndSetData={closeCropperAndSetData}
              />
            )}
            {previewModal.show && (
              <PreviewModal
                imageData={previewModal.imageData}
                title="Upload Article Image"
                message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                onSuccess={onPreviewSuccess}
                onClose={onPreviewClose}
              />
            )}
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your article has been updated successfully !"
                onSuccess={() => setAlertModal(false)}
              />
            )}

            {newsInfo && (
              <div>
                <div>
                  <div
                    style={{ display: 'inline-block', verticalAlign: 'top' }}
                  >
                    <img src={newsInfo.imgUrl} style={{ width: 500 }} />
                    {isImageUploading ? (
                      <div style={{ textAlign: 'center', padding: 10 }}>
                        <div>Uploading Image Please Wait...</div>
                        <div>
                          <PulseLoader
                            color="#23A5E0"
                            loading={true}
                            css={override}
                            size={10}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>
                          Please upload image in the dimension of 1920x1080
                        </div>
                        <div>
                          <input
                            ref={fileInput}
                            type="file"
                            multiple={false}
                            onChange={onFileSelect}
                            accept="image/x-png,image/jpeg"
                            style={{
                              backgroundColor: '#000',
                              height: 35,
                              width: 40,
                              position: 'absolute',
                              opacity: 0,
                              cursor: 'pointer',
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                              margin: 8,
                              color: '#555',
                              cursor: 'pointer',
                            }}
                          ></i>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                      padding: 20,
                    }}
                  >
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div>
                        {newsInfo.showArticle
                          ? 'Article is published. Click below to unpublish this article'
                          : 'Article is not published. Click below to publish this article'}
                      </div>
                      <button
                        className={`bg-${
                          newsInfo.showArticle ? 'red' : 'emerald'
                        }-500 text-white active:bg-${
                          newsInfo.showArticle ? 'red' : 'emerald'
                        }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        onClick={showModalForPublish}
                      >
                        {newsInfo.showArticle
                          ? 'Unpublish Article'
                          : 'Publish Article'}
                      </button>
                    </div>
                    <div>Tagged League</div>
                    {!changeLeague && (
                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                        {taggedLeague}
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeLeague(true);
                            dispatchSelectedLeague({
                              selectedLeague: newsInfo.league,
                              selectedLeagueFilter: newsInfo.league,
                            });
                          }}
                        ></i>
                      </div>
                    )}
                    {changeLeague && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <LeaguesDropDown onChange={onLeagueSelected} />

                        <i
                          className="fas fa-times fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeLeague(false);
                            dispatchSelectedLeague({
                              selectedLeague: newsInfo.league,
                              selectedLeagueFilter: newsInfo.league,
                            });
                          }}
                        ></i>
                      </div>
                    )}
                    <div>Tagged Sports</div>
                    {!changeSports && (
                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                        {taggedSports}
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeSports(true);
                            dispatchSelectedSports({
                              selectedSports:
                                newsInfo.sports && newsInfo.sports._id
                                  ? newsInfo.sports._id
                                  : 'All',
                              selectedSportsFilter:
                                newsInfo.sports && newsInfo.sports._id
                                  ? newsInfo.sports._id
                                  : 'All',
                            });
                          }}
                        ></i>
                      </div>
                    )}
                    {changeSports && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <SportsDropdown onChange={onSportsSelected} />
                        <i
                          className="fas fa-times fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeSports(false);
                          }}
                        ></i>
                      </div>
                    )}
                    <div>Tagged Match</div>
                    {!changeSports && (
                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                        {taggedSports}
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeSports(true);
                            dispatchSelectedSports({
                              selectedSports:
                                newsInfo.sports && newsInfo.sports._id
                                  ? newsInfo.sports._id
                                  : 'All',
                              selectedSportsFilter:
                                newsInfo.sports && newsInfo.sports._id
                                  ? newsInfo.sports._id
                                  : 'All',
                            });
                          }}
                        ></i>
                      </div>
                    )}
                    {changeSports && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <SportsDropdown onChange={onSportsSelected} />
                        <i
                          className="fas fa-times fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeSports(false);
                          }}
                        ></i>
                      </div>
                    )}
                    <div>Top News</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {!topNewsChange && (
                        <div style={{ marginTop: 5, marginBottom: 5 }}>
                          {topNewsType} {''}
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              margin: 8,
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setTopNewsChange(true);
                              setDateTime(true);
                            }}
                          ></i>
                        </div>
                      )}
                      {topNewsChange && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          {/* <SportsDropdown onChange={onSportsSelected} /> */}
                          <select
                            value={topNewsType}
                            onChange={changeTopNewsType}
                          >
                            <option value="ALL">All</option>
                            <option value="TOP_NEWS">TOP_NEWS</option>
                          </select>
                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              margin: 8,
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setTopNewsChange(false);
                            }}
                          ></i>
                        </div>
                      )}
                      {topNewsType == 'TOP_NEWS' && (
                        <div className="edit__popup-row">
                          <span> Expiry Date:</span>

                          <div
                            className="relative w-full "
                            // onClick={changeTopNewsType}
                          >
                            {dateTime ? (
                              <DateTimePicker
                                // format="ddd, dd MM hh:mm A"
                                value={experyDate}
                                onChange={(e) =>
                                  updateTopNewsTime(topNewsType, e)
                                }
                              />
                            ) : (
                              _dateTimeFormat(experyDate, 'ddd, DD MMM hh:mm A')
                            )}
                          </div>
                        </div>
                      )}
                    </MuiPickersUtilsProvider>

                    <div>
                      {/** Top news option */}

                      <div>Headline</div>
                      <div>{newsInfo.headline}</div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <div>Published At</div>
                      {/* <div>{newsInfo.PublishedAt}</div> */}
                      {_dateTimeFormat(
                        newsInfo.PublishedAt,
                        'ddd, DD MMM hh:mm A'
                      )}
                      {/* {newsInfo.copyrights === "PTI"
                        ? _dateTimeFormat(
                            newsInfo.PublishedAt,
                            "ddd, DD MMM hh:mm A"
                          )
                        : _getDateTime(
                            newsInfo.PublishedAt,
                            "ddd, DD MMM hh:mm A"
                          )} */}
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <div>News Type</div>
                      <div>{newsInfo.copyrights}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ paddingTop: 30 }}
                  dangerouslySetInnerHTML={{ __html: newsInfo.story }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leagues: state.leaguesReducer.leagues,
    sports: state.sportsReducer.sports,
  };
};
export default connect(mapStateToProps)(TwitterInfo);
