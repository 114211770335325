import React, { useState } from "react";
import { connect } from "react-redux";
import { dispatchSelectedDynamicType } from "../../redux/action-dispatchers/dynamicBanner.dispatcher";

const DynamicBannerTypeDropdown = (props) => {
  const [docs, setDocs] = useState("league");
  const onChange = (event) => {
    props.onChange && props.onChange(event.target.value);
    dispatchSelectedDynamicType(event.target.value);
  };

  return (
    <select
      style={{
        // marginLeft: 20,
        border: "2px solid #acb9ce",
        borderRadius: 10,
      }}
      value={props.dynamicBannerReducer.selectedDynamicType}
      onChange={onChange}
    >
      <option value={"league"}>league</option>
      <option value="match">match</option>
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    dynamicBannerReducer: state.dynamicBannerReducer,
  };
};

export default connect(mapStateToProps)(DynamicBannerTypeDropdown);
