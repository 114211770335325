import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const getAddQuiz = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.ADD_QUIZ}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getUpdateQuiz = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.UPDATE_QUIZ}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getUpdateQuizVideo = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.UPDATE_QUIZ_VIDEO}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getQuiz = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.GET_QUIZ}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const deleteQuiz = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.DELETE_QUIZ}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const updateQuizVideoThumbnail = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.UPDATE_QUIZ_VIDEO_THUMBNAIL}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const updateQuizHomeTag = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.UPDATE_QUIZ_HOMETAG}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const getQuizDetail = async (id, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.GET_QUIZ_DETAIL}/${id}`,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const sendNotification = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.QUIZ.NOTIFICATION}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
