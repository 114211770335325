import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { dispatchSelectedLeague } from '../../redux/action-dispatchers/leagues.dispatchers.js';
import { dispatchSelectedSports } from '../../redux/action-dispatchers/sports.dispatchers.js';
import {
  ChangeVideoStatus,
  GetYTShorts,
} from '../../handlers/videos.handlers.js';
import {
  dispatchToggleVideoStatus,
  dispatchVideosList,
  dispatchVideosListFilter,
} from '../../redux/action-dispatchers/videos.dispatcher.js';
import { _getDateTime, _dateTimeFormat } from '../../constants/appDefaults';
import { css } from '@emotion/core';
import SyncLoader from 'react-spinners/SyncLoader';
import YtShortsDropdown from '../Dropdowns/YtShortsDropdown.js';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const STVideosTable = (props) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [videoStatusType, setVideoStatusType] = useState(1);

  const [videoType, setVideoType] = useState('Sports Tak');
  const [getsports, setGetSports] = useState('All');
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [isPlaying, setIsplaying] = useState(false);

  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [togglingStatus, setTogglingStatus] = useState({
    index: 0,
    loading: false,
  });
  const [searchString, setSearchTags] = useState('noTags');
  var searchStringGlobal = 'noTags';
  var lastSports = 'All';
  var lastLeague = 'All';

  const onSportsTypeChange = (value) => {
    setGetSports(value);
  };

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };

  const mHistory = useHistory();
  useEffect(() => {
    dispatchSelectedLeague({
      selectedLeague: 'All',
      selectedLeagueFilter: 'All',
    });
  }, []);

  useEffect(() => {
    dispatchSelectedSports({
      selectedSports: 'All',
      selectedSportsFilter: 'All',
    });
  }, []);

  var page = 1;

  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };
  useEffect(() => {
    page = props.page;

    videoType === 'Sports Tak' &&
      getVideos(
        props.page,
        parseInt(props.limit),
        props.selectedLeague,
        props.selectedSports,
        false,
        videoType,
        props.selectedSports,
        startDate,
        endDate
      );
  }, [
    props.selectedSports,
    props.selectedLeague,
    videoStatusType,
    getsports,
    videoType,
    startDate,
    endDate,
  ]);

  const getVideos = (
    page,
    _limit,
    _selectedLeague,
    _selectedSports,
    _videoStatusType,
    _videoType,
    _id,
    startDate,
    endDate,
    isLoadMore
  ) => {
    setIsLoading(true);

    GetYTShorts(
      videoStatusType,
      //   page,
      //   limit: parseInt(props.limit),
      //   league: props.selectedLeague,
      //   sportId: props.selectedSports,
      //   status: videoStatusType,
      //   copyRight: videoType,
      //   startDate: startDate,
      //   endDate: endDate,
      {
        Authorization: props.Authorization,
      }
    )
      .then((response) => {
        setIsLoading(false);

        if (isLoadMore) {
          dispatchVideosList(response.data);
        } else {
          dispatchVideosListFilter(response.data);
        }
      })
      .catch((error) => {
        isLoadMore && setIsLoadingMore(false);
      });
  };

  const toggleVideoStatus = (index, videoId, status) => {
    setTogglingStatus({
      index,
      loading: true,
    });
    ChangeVideoStatus(videoId, status, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setTogglingStatus({
          index: -1,
          loading: false,
        });
        if (response.status) {
          dispatchToggleVideoStatus({ index, status });
        } else {
          alert(JSON.stringify(response.message));
        }
      })
      .catch((error) => {
        setTogglingStatus({
          index: -1,
          loading: false,
        });
        alert(JSON.stringify(error));
      });
  };
  //Media popup

  const getVideoTableItem = (video, position) => {
    if (video.media.content.length > 0) {
      const videoUrl = video.media.content[1]
        ? video.media.content[1].url
        : video.media.content[0].url;
      const height = video.media.content[1]
        ? video.media.content[1].height
        : video.media.content[0].height;
      const width = video.media.content[1]
        ? video.media.content[1].width
        : video.media.content[0].width;
      const thumbnail =
        video.media.content[video.media.content.length - 1].type === 'image'
          ? video.media.content[video.media.content.length - 1].url
          : video.media.thumbnail[video.media.thumbnail.length - 1].url;
      return (
        <tr>
          <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
            <img
              src={thumbnail}
              style={{
                // maxHeight: 300,
                // minHeight: 253,
                // maxWidth: 450,
                // minWidth: 400,
                maxHeight: 100,
                minHeight: 53,
                maxWidth: 53,
                minWidth: 100,
              }}
              height={300}
              width={400}
              alt="..."
              onClick={() => onClickOpenMedia(videoUrl, thumbnail)}
            ></img>
          </th>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.copyright}
          </td>
          <td
            className="border border-green-800 border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            style={{ fontWeight: '500' }}
          >
            {video.title}
          </td>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.channelTitle}
          </td>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.duration}
          </td>

          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {_dateTimeFormat(video.lastUpdate, 'ddd, DD MMM hh:mm A')}
          </td>

          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.status === 1 ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>
                Published
              </span>
            ) : (
              <span style={{ color: 'red', fontWeight: 'bold' }}>Draft</span>
            )}
          </td>

          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
            {video.status === 1 ? (
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  toggleVideoStatus(position, video._id, 0);
                  // onView(video._id)
                }}
              >
                Hide
              </button>
            ) : (
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => toggleVideoStatus(position, video._id, 1)}
              >
                Unhide
              </button>
            )}
          </td>
        </tr>
      );
    } else {
      return <></>;
    }
  };
  const onView = (videoId) => {
    mHistory.push(`/videos/${videoId}/no`);
  };
  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '55%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showEditHandler(false);
                setIsplaying((isPlaying) => !isPlaying);
                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={isPlaying}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className="rounded-t mb-0 px-2 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  'font-semibold text-lg ' +
                  (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {'YT Shorts'}
                <div
                  className="relative w-full  max-w-full flex-grow flex-1"
                  style={{ textAlign: 'right' }}
                >
                  {/* <span style={{ marginLeft: 20 }}>
                    <VideoTypeDropDown
                      selectedValue={videoType}
                      onChange={onVideoTypeChange}
                    />
                  </span> */}

                  <span style={{ marginLeft: 5 }}>
                    <YtShortsDropdown
                      value={videoStatusType}
                      onChange={(event) =>
                        setVideoStatusType(event.target.value)
                      }
                    />
                  </span>
                </div>
              </h3>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                position: 'relative',
                left: '3.4%',
              }}
            >
              <div className="wrapper"></div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Video
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Copyright
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Title
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Channel
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Duration
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Published Date
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Status
                </th>

                <th
                  className={
                    'border border-green-800 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                ></th>
              </tr>
            </thead>
            {!isLoading ? (
              GetYTShorts
            ) : (
              <SyncLoader color="#23A5E0" css={override} size={12} />
            )}
            <tbody>
              {props.docs.map((video, position) =>
                getVideoTableItem(video, position)
              )}
            </tbody>
          </table>
          {props.hasNextPage && !isLoadingMore && (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  videoType === 'Sports Tak' &&
                    getVideos(
                      parseInt(props.page) + 1,
                      parseInt(props.limit),
                      props.selectedLeague,
                      props.selectedSports,
                      videoStatusType,
                      videoType,
                      props._id,
                      startDate,
                      endDate,
                      true
                    );
                }}
              >
                Load More
              </button>
            </div>
          )}
          <div style={{ textAlign: 'center', padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
    </>
  );
};

STVideosTable.defaultProps = {
  color: 'light',
};

STVideosTable.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};
const mapStateToProps = (state) => {
  return {
    docs: state.videosReducer.docs,
    page: state.videosReducer.page,
    limit: state.videosReducer.limit,
    leagues: state.leaguesReducer.leagues,
    _id: state.videosReducer._id,
    hasNextPage: state.videosReducer.hasNextPage,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedSports: state.sportsReducer.selectedSports,
  };
};
export default connect(mapStateToProps)(STVideosTable);
