import { LeaguesActionTypes } from '../action-types';

const initialState = {
  leagues: [],
  leaguesTag: [],
  selectedLeague: 'All',
  selectedLeagueFilter: 'All',
  selectedLeagueId: '',
  selectedLeagueTag: '',
  selectedLeagueFilterTag: '',
  selectedLeagueIDTag: '',
  leagueSearch: '',
};
const leaguesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LeaguesActionTypes.GET_ALL_LEAGUES:
      return Object.assign({}, state, {
        leagues: action.payload,
      });
    case LeaguesActionTypes.GET_ALL_LEAGUES_TAG:
      return Object.assign({}, state, {
        leaguesTag: action.payload,
      });
    // return Object.assign({}, state, {
    case LeaguesActionTypes.CHANGE_SEARCH_LEAGUE:
      return Object.assign({}, state, {
        leagueSearch: action.payload,
      });
    //   leagues: action.payload,
    //   selectedLeague:
    //     action.payload.length > 0 ? action.payload[0].comp_id : '',
    //   selectedLeagueFilter:
    //     action.payload.length > 0 ? action.payload[0].comp_id : '',
    // });
    case LeaguesActionTypes.CHANGE_SELECTED_LEAGUE:
      return Object.assign({}, state, {
        selectedLeague: action.payload.selectedLeague,
        selectedLeagueFilter: action.payload.selectedLeagueFilter,
        selectedLeagueID: action.payload.selectedLeagueId,
      });
    case LeaguesActionTypes.CHANGE_SELECTED_LEAGUE_TAGGING:
      return Object.assign({}, state, {
        selectedLeagueTag: action.payload.selectedLeagueTag,
        selectedLeagueFilterTag: action.payload.selectedLeagueFilterTag,
        selectedLeagueIDTag: action.payload.selectedLeagueIDTag,
      });
    default:
      return state;
  }
};
export default leaguesReducer;
