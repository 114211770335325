import React, { useState } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import {
  ShowTaggedFeatureCard,
  TagFeatureToHome,
} from '../../../handlers/leagues.handlers';
import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import HomeDropdown from '../../../components/Dropdowns/HomeDropdown';
import AlertModal from '../../../components/AlertModal';
import Sidebar from '../../../components/Sidebar/Sidebar';
import {
  OLD_BASE_PATH,
  API_ROUTES,
  API_BASE_PATH,
} from '../../../constants/workerConstants';
import LoginAppConstants from '../../../constants/LoginAppConstants';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language')
const headers = { 'cms-access-token': accessToken, source: 'cms',language:CURRENT_LANGUAGE };
const HomeFeatureCard = () => {
  const [homeType, setHomeType] = useState('f');
  const [alertModal, setAlertModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [leagueData, setLeagueData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [updateDocs, setUpdateDocs] = useState([]);
  const mHistory = useHistory();
  const [checkedFeatureCards, setCheckFeatureCards] = useState([]);
  const color = 'light';
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(leagueData, source.index, destination.index);

    const docsUpdated = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        ranking: index + 1,
      });
    });

    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setDocs(items);
    setLeagueData(items);
  };

  const sendLeagueData = () => {
    let rankWiseData = docs.map((league, index) => {
      league.ranking = index;
      return league;
    });

    const data = JSON.stringify({
      leagues: rankWiseData,
    });

    Axios.post(
      `${API_BASE_PATH}${API_ROUTES.LEAGUES.CHANGE_HOME_RANKING}`,
      { matchesList: updateDocs },
      {
        headers
      }
    )
      .then((res) => {
        //
        setShowAction(false);
        getTaggedFeatureCard();
      })
      .catch((err) => {});
  };
  const getTaggedFeatureCard = () => {
    setIsLoading(true);
    ShowTaggedFeatureCard()
      .then((response) => {
        setIsLoading(false);
        if (response) setLeagueData(response.data.data.response.items);
        // setUpdateDocs
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getTaggedFeatureCard();
  }, [homeType]);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onHomeTypeChange = (event) => {
    setHomeType(event.target.value);
  };

  if (homeType === 'news') {
    mHistory.push(`/home-page`);
  } else if (homeType === 'videos') {
    mHistory.push(`/HomeVideoComponent`);
  }
  else if (homeType === 'live_blog'){
    mHistory.push('/live_blog');
  }

  const selectHomeFeature = async (
    event,
    match_id,
    abbr,
    title,
    date,
    istagged
  ) => {
    const recordFound = _.find(
      checkedFeatureCards,
      (item) => item === match_id
    );
    const body = { match_id, abbr, title, date, istagged };
    if (recordFound) {
      const newList = _.filter(
        checkedFeatureCards,
        (item) => item !== match_id
      );
      setCheckFeatureCards(newList);

      await TagFeatureToHome(body);
      getTaggedFeatureCard();
      setAlertModal(true);
    } else {
      setCheckFeatureCards([...checkedFeatureCards, match_id]);
      await TagFeatureToHome(body);
      getTaggedFeatureCard();
      setAlertModal(true);
    }
  };

  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {/* {alertModal && (
                <AlertModal
                  title="Thanks"
                  message="Your article has been updated successfully!"
                  onSuccess={() => window.location.reload()}
                />
              )} */}

            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Home/Feature-Card
                      <SyncLoader
                        color="#23A5E0"
                        loading={isLoading}
                        css={override}
                        size={10}
                      />
                    </h3>
                    {showAction && (
                      <div className={'save-reset enabled'}>
                        <div
                          onClick={() => sendLeagueData()}
                          className="save btn"
                        >
                          Save
                        </div>

                        <div
                          onClick={() => {
                            getTaggedFeatureCard();
                            setShowAction(false);
                          }}
                          className="reset btn"
                        >
                          Reset
                        </div>
                      </div>
                    )}
                    <div
                      className="relative w-full px-10 max-w-full flex-grow flex-1"
                      style={{ textAlign: 'right' }}
                    >
                      <HomeDropdown
                        selectedValue={homeType}
                        onChange={onHomeTypeChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr
                              className={
                                'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                (color === 'light'
                                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                  : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                              }
                            >
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Match ID
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Match Detail
                              </th>
                              {/* <th
                                  className={
                                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                    (color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                  }
                                >
                                  Language
                                </th> */}
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Match Date & Time
                              </th>

                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Home
                              </th>
                              {/* <th
                                  className={
                                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                    (color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                  }
                                >
                                  Action
                                </th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {leagueData.length > 0 &&
                              leagueData.map((league, index) => {
                                return (
                                  <Draggable
                                    key={league._id}
                                    draggableId={league._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          style={{
                                            // cursor: "pointer",
                                            fontWeight: 'bolder',
                                          }}
                                        >
                                          {league && league.match_id
                                            ? league.match_id
                                            : 'na'}
                                        </td>
                                        <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          style={{
                                            // cursor: "pointer",
                                            fontWeight: 'bolder',
                                          }}
                                        >
                                          {league && league.title
                                            ? league.title
                                            : 'na'}
                                        </td>
                                        <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          style={{
                                            // cursor: "pointer",
                                            fontWeight: 'bolder',
                                          }}
                                        >
                                          {league && league.competition
                                            ? league.competition.abbr
                                            : 'na'}
                                        </td>
                                        <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          style={{
                                            // cursor: "pointer",
                                            fontWeight: 'bolder',
                                          }}
                                        >
                                          {_dateTimeFormat(
                                            league && league
                                              ? league.date_start
                                              : 'na',
                                            'ddd, DD MMM hh:mm A'
                                          )}
                                         
                                        </td>

                                        {/* <td
                                            className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                            style={{
                                              // cursor: "pointer",
                                              fontWeight: 'bolder',
                                            }}
                                          ></td> */}
                                        <td
                                          className="border border-green-800 text-center"
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <Checkbox
                                            checked={true}
                                            onChange={(event) => {
                                              selectHomeFeature(
                                                event,
                                                league.match_id,
                                                league &&
                                                  league.competition &&
                                                  league.competition.abbr,
                                                league.title,
                                                league.date_start,
                                                league.isTaggedOnHome
                                              );
                                            }}
                                            color="default"
                                            inputProps={{
                                              'aria-label':
                                                'checkbox with default color',
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </tbody>
                        </table>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFeatureCard;
