import AppConstants from '../constants';
import AxiosInstance from './http.handlers';
import AxiosMessageInstance from './http.messageHandlers';

export const sendNotification = async (body, headers) => {
  return await AxiosMessageInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NOTIFICATION.SEND_NOTIFICATION}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};