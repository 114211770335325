import React, { useState, useEffect } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Button, Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import './AddQuiz.css';
import { css } from '@emotion/core';
import { Get_All_S3_Videos } from '../../../handlers/videos.handlers';
import { dispatchS3VideosList } from '../../../redux/action-dispatchers/videos.dispatcher';
import Selects from 'react-select';
import PulseLoader from 'react-spinners/PulseLoader';
import ReactPlayer from 'react-player';
import ConfirmationModal from '../../../components/ConfirmationModal';
import CropperModal from '../../../components/CropperModal';
import UnsavedChangesWarning from '../../../components/AlertModal/UnsavedChangesWarning';
import { getAddQuiz } from '../../../handlers/quiz.handlers';
import PreviewModal from '../../../components/PreviewModal';
import SportsDropDown from '../../../components/Dropdowns/SportsDropDown';
import SyncLoader from 'react-spinners/SyncLoader';
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import DatePicker from "react-datepicker";
import { GetAllAuthor } from "../../../handlers/author.handlers";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AddQuiz = (props) => {
  const [topic, setTopic] = useState('');
  const [difficulty, setDifficulty] = useState(1);
  const [homeNewsChecked, setHomeNewsChecked] = useState(false);
  const [questionList, setQuestionList] = useState([
    {
      question: '',
      screenTime: 20,
      options: [{ value: '', isCorrect: false }],
    },
  ]);

  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [searchVideo, setSearchVideo] = useState('');
  const [selectVideo, setSelectedVideo] = useState('');
  const [authorId, setAuthorId] = useState('');
  const [mediaValue, setMediaValue] = useState({});
  const [docs, setDocs] = useState([]);
  const [taggedSport, setTaggedSport] = useState('');
  const [showMedia, setShowMedia] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [isPlaying, setIsplaying] = useState(false);
  const [videoAdd, setVideoAdd] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  {/**Tag on Live */ }
  const today = new Date();
  const [tagOnLive, setTagOnLive] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [duration, setDuration] = useState(60);
  const [changeSport, setChangeSport] = useState(false);
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  let fileInput = React.useRef();
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
    height: '',
    width: '',
    file: '',
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const history = useHistory();
  // Function to add a new question
  const handleQuestionAdd = () => {
    setQuestionList([
      ...questionList,
      {
        question: '',
        screenTime: 20,
        options: [{ value: '', isCorrect: false }],
      },
    ]);
  };

  //   Function To add an option
  const handleOptionAdd = (index) => {
    const list = [...questionList];
    const optionList = [
      ...list[index].options,
      { value: '', isCorrect: false },
    ];
    list[index].options = optionList;
    setQuestionList(list);
  };

  // Function to remove a question
  const handleQuestionRemove = (index) => {
    const list = [...questionList];
    list.splice(index, 1);
    setQuestionList(list);
  };
  // Function to remove option
  const handleOptionRemove = (index, optionIndex) => {
    const list = [...questionList];
    const optionList = [...list[index].options];
    optionList.splice(optionIndex, 1);
    list[index].options = optionList;
    setQuestionList(list);
  };

  // Function to input a question
  const handleQuestionChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...questionList];
    list[index][name] = value;
    setQuestionList(list);
  };
  // Function to input screen time
  const handleScreenTime = (event, index) => {
    const { name, value } = event.target;
    const numericValue = Number(value);

    if (numericValue >= 0) { // Ensure value is not negative
      const list = [...questionList];
      list[index][name] = numericValue;
      setQuestionList(list);
    }

  };

  //   Function to input an option
  const handleOptionChange = (event, index, optionIndex) => {
    const { name, value } = event.target;
    const list = [...questionList];
    const optionList = [...list[index].options];
    optionList[optionIndex][name] = value;
    list[index].options = optionList;
    setQuestionList(list);
  };
  // Function to change is ture
  const handleOptionCheck = (event, index, optionIndex) => {
    const { name, checked } = event.target;
    const list = [...questionList];
    const optionList = [...list[index].options];
    optionList[optionIndex][name] = checked;
    list[index].options = optionList;
    setQuestionList(list);
  };
  //   Get s3 bucket videos

  const getS3Videos = () => {
    const body = {
      page: 1,
      limit: 10,
      query: searchVideo,
    };
    Get_All_S3_Videos(body)
      .then((res) => {
        dispatchS3VideosList(res.data);
      })
      .catch((err) => err);
  };
  useEffect(() => {
    getS3Videos();
  }, []);
  const getAuthor = () => {
    GetAllAuthor()
      .then((response) => {
        setDocs(response.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAuthor();
    window.scrollTo(0, 0);
  }, []);
  const videosList = state.s3VideosReducer.docs;
  const videos = videosList?.map((video) => {
    return {
      value: video._id,
      label: video.original_file_name,
      url: 'https://' + video.cdn_hostname + video.url,
    };
  });

  const handleSelect = (value) => {
    setSelectedVideo(value);
    setMediaValue(value);
  };

  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };

  const onPreviewClose = () =>
    setPreviewModal({ imageData: '', file: '', show: false });
  const onPreviewSuccess = () => {
    setPreviewModal({
      imageData: previewModal.imageData,
      show: false,
      file: previewModal.file,
    });
  };
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setShowCropper(false);
  };

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
            height: this.height,
            width: this.width,
            file: file,
          });
        } else {
          alert('Image width and height must be 1920x1080 px');
          return false;
        }
      };
    };
    reader.onerror = function (error) {
      //
    };
    setIsUnsaved();
  };
  useEffect(() => {
    if (props.selectedSports) {
      const sportsInfo = props.sports.find(
        (item) => item._id === props.selectedSports
      );
      if (sportsInfo) {
        setTaggedSport(sportsInfo.name);
      }
    }
  }, [props.selectedSports]);

  const onSportSelected = () => {
    setChangeSport(false);
    setIsUnsaved();
  };

  const selectHomeNews = (event) => {
    setHomeNewsChecked(event.target.checked);
  };
  const toggleForLive = (event) => {
    setTagOnLive(event.target.checked);
  };

  // submit button on click

  const handleSubmit = () => {
    console.log('questionList', questionList)
    if (topic == "") {
      alert("Topic should exists...");
    }
    else if (taggedSport == '') {
      alert("Please Select Sports ....")
    }
    else if (selectVideo == '') {
      alert("Please Select Video ....")
    }
    else if (previewModal.imageData?.length == 0) {
      alert("Please Upload Video Thumbnail....")
    }
    else if (questionList.length < 5 || questionList.length >=6) {
      alert("Please add 5 question to quiz....")

    }
    else if (duration < 60) {
      alert("Quiz Live duration must be above 60 seconds...")
    }
    else if (questionList.length == 5) {
      for (const item of questionList) {
        if (item.question === '') {
          alert("Question cannot be empty");
          return;
        }

        if (item.options.length < 2) {
          alert("Please add at least 2 options in questions");
          return;
        }

        let correctOptionCount = 0;
        let optionEmpty = false;

        for (const option of item.options) {
          if (option.isCorrect) {
            correctOptionCount++;
          }

          if (option.value === '') {
            alert("Please Fill all options");
            optionEmpty = true;
            return;
          }
        }

        // The outer loop returns if any option was empty.
        if (optionEmpty) {
          return;
        }

        if (correctOptionCount === 0) {
          alert("Please mark at least one option as correct for each question");
          return;
        }

        if (correctOptionCount > 1) {
          alert("Please mark only one option as correct for each question");
          return;
        }
      }
      if (authorId == "" || authorId == undefined) {
        alert("Please Select author.");
      }
      else {
        setIsLoading(true);
        setDisable(true);
        let body = {
          topic: topic,
          questions: questionList,
          difficulty: difficulty,
          transcodedVideoId: selectVideo.value,
          videoThumbnail: previewModal.imageData,
          isHomeTagged: homeNewsChecked,
          sports: props.selectedSports == 'all' ? '' : props.selectedSports,
          isTaggedOnLive: tagOnLive,
          startDate: selectedDate,
          duration: +duration,
          authorId: authorId,
        };

        getAddQuiz(body).then((response) => {
          if (response.status) {
            setIsLoading(false);
            alert(response?.data);
            history.push('/quiz');
          } else {
            setIsLoading(false);
            alert(`Something went wrong...`);
          }
        });
      }
    }
  };
  return (
    <div>
      <Sidebar />
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '55%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                setIsplaying(false);
                showEditHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={showEdit ? true : false}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div className="relative bg-blueGray-100">
              <div
                className={
                  'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                  'bg-white'
                }
              >
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                      <h3
                        className={
                          'font-semibold text-lg ' + 'text-blueGray-700'
                        }
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {'Add Quiz'}
                        <div
                          className="relative w-full  max-w-full flex-grow flex-1"
                          style={{ textAlign: 'right' }}
                        >
                          <span style={{ marginLeft: 5 }}>
                            <Link to="/quiz">
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginBottom: '1.5%' }}
                              >
                                Quiz Screen
                              </Button>
                            </Link>
                          </span>
                        </div>
                      </h3>
                    </div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        position: 'relative',
                        left: '3.4%',
                      }}
                    >
                      <div className="wrapper"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="block w-full overflow-x-auto addQuizStyle"
                  style={{ padding: '20px' }}
                >
                  <div className="topic">
                    <label className="addArticle_label">Topic*</label>
                    <br></br>
                    <input
                      className="addQuiz-input"
                      type="text"
                      value={topic}
                      onChange={(event) => setTopic(event.target.value)}
                    />
                  </div>
                  <hr className="my-4 md:min-w-full" />
                  {/**Tagg on live */}
                  <div
                    style={{
                      fontWeight: '500',
                      display: 'flex',
                      alignItems: "center"
                    }}
                  >
                    Tag on Live*

                    <GreenSwitch {...label} checked={tagOnLive} disabled={homeNewsChecked}
                      onChange={toggleForLive} />
                      {tagOnLive ? <div style={{color:"red",marginLeft:"20px",fontSize:"13px"}}>*Tagging on Home is not allowed if Tagged on Live</div> : null}
                    {homeNewsChecked ? <div style={{color:"red",marginLeft:"20px",fontSize:"13px"}}>*Tagging on Live is not allowed if Tagged on Home</div> : null}
                  </div>
                  {tagOnLive && <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>Start Date: </div>
                      <DatePicker
                        className="quiz-datePicker"

                        selected={selectedDate}
                        onChange={(date) => {

                          setSelectedDate(date);
                        }}
                        onInvalidChange={() => alert("invalid date")}
                        showTimeInput
                        dateFormat="yyyy-MM-dd h:mm:ss aa"
                        minDate={today}
                        timeZone="Asia/Kolkata"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                      <div>Duration: </div>
                      <input
                        type="number"
                        placeholder="Duration*"
                        value={duration}
                        onChange={(event) => {
                          const numericValue = Number(event.target.value);

                          if (numericValue >= 0) {
                            setDuration(numericValue)
                          }
                        }}
                        style={{ height: '30px', border: "1px solid #111111", borderRadius: "10px", width: "100px", textAlign: "right", marginRight: "10px" }}
                      />
                      <div>seconds</div>
                    </div>
                  </div>}

                  <hr className="my-4 md:min-w-full" />
                  <div className="difficulty-home-sports">
                    <div className="difficulty">
                      <label className="addArticle_label">Difficulty*</label>
                      <br></br>
                      <input
                        className="addQuiz-input difficulty-input"
                        type="number"
                        value={difficulty}
                        min={1}
                        max={5}
                        onChange={(event) => setDifficulty(event.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        fontWeight: '500',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }}
                    >
                      <Checkbox
                        checked={homeNewsChecked}
                        color="primary"
                        disabled={tagOnLive}
                        onChange={selectHomeNews}
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                      />
                      Home Tag
                    </div>
                    {/* Sports dropdown */}
                    <div className="sports-dropdown-container">
                      <span>Sports*</span>
                      <SportsDropDown onChange={onSportSelected} />
                    </div>
                    {/* Sports dropdown end*/}

                  </div>
                  <hr className="my-4 md:min-w-full" />
                  {/* Add Video */}

                  <div className="flex addQuiz-search">
                    <label
                      style={{
                        marginRight: '10px',
                        marginTop: '8px',
                        fontWeight: 600,
                      }}
                    >
                      Search Videos:
                    </label>
                    <input
                      type="text"
                      className="addQuiz-video"
                      name="searchVideoVal"
                      placeholder="Search Videos"
                      onChange={(e) => {
                        setSearchVideo(e.target.value);
                      }}
                    />

                    <button
                      type="button"
                      onClick={() => {
                        getS3Videos();
                      }}
                      className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                    >
                      Search
                    </button>
                  </div>

                  <>
                    <div className="col-lg-8">
                      <label
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Select Video *
                      </label>
                      <Selects
                        name="videoId"
                        className="addQuiz-select"
                        placeholder="Select Video"
                        options={videos}
                        onChange={(values) => {
                          handleSelect(values);
                        }}
                        value={
                          videos
                            ? videos.find(
                              (option) => option.value === selectVideo.value
                            )
                            : ''
                        }
                      ></Selects>
                      {selectVideo && (
                        <div
                          style={{
                            display: 'flex',

                            alignItems: 'center',
                            textAlign: 'center',
                          }}
                        >
                          Click the icon for preview
                          <i
                            onClick={() => onClickOpenMedia(selectVideo.url)}
                            className="fas fa-video "
                            style={{
                              margin: 8,
                              color: '#555',
                              cursor: 'pointer',
                              fontSize: '1.5rem',
                            }}
                          ></i>
                        </div>
                      )}
                    </div>

                    <div
                      className="col-lg-1"
                      style={{
                        margin: 'auto',
                        marginTop: '26px',
                      }}
                    >
                      <a
                        title="Media File"
                        className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                        onClick={() => { }}
                      ></a>
                    </div>
                  </>
                  {/* Upload video end */}

                  {/* upload thumbnail */}
                  <hr className="my-4 md:min-w-full" />
                  {confirmationModal.show && (
                    <ConfirmationModal
                      message={confirmationModal.message}
                      title={confirmationModal.title}
                      onClose={!videoAdd && onClose}
                      onSuccess={!videoAdd && onSuccess}
                    />
                  )}
                  {showCropper && (
                    <CropperModal
                      image={selectedImage}
                      onClose={onCropperClose}
                      closeCropperAndSetData={closeCropperAndSetData}
                    />
                  )}
                  {previewModal.show && (
                    <PreviewModal
                      imageData={previewModal.imageData}
                      title="Upload video image"
                      message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                      onSuccess={onPreviewSuccess}
                      onClose={onPreviewClose}
                    />
                  )}

                  {
                    <div
                      style={{
                        verticalAlign: 'top',
                      }}
                    >
                      {previewModal.imageData && (
                        <img
                          src={previewModal.imageData}
                          style={{
                            width: 210,
                            height: 115,
                            border: '2px solid gray',
                          }}
                        />
                      )}
                      {isImageUploading ? (
                        <div
                          style={{
                            textAlign: 'center',
                            padding: 10,
                            fontWeight: 600,
                          }}
                        >
                          <div>Uploading Image Please Wait...</div>
                          <div>
                            <PulseLoader
                              color="#23A5E0"
                              loading={true}
                              css={override}
                              size={10}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Please upload image in the dimension of 1920x1080*
                          </div>
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              multiple={false}
                              onChange={onFileSelect}
                              accept="image/x-png,image/jpeg"
                              style={{
                                backgroundColor: '#000',
                                height: 35,
                                width: 40,
                                position: 'absolute',
                                opacity: 0,
                                cursor: 'pointer',
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: '#555',
                                cursor: 'pointer',
                              }}
                            ></i>
                          </div>
                        </>
                      )}
                    </div>
                  }
                  {/* notification */}
                  {/* <hr className="my-4 md:min-w-full" /> */}
                  {/* <div>
                    <div
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Notification :
                      <input type="checkbox" checked={isNotification} onChange={(e) => {setIsNotification(e.target.checked);}}/>
                      <label style={{ color: "red",marginLeft:"10px" ,fontSize:"10px"}}>(Please select Checkbox to trigger Notification)</label>
                      <div style={{marginTop:"10px"}}>
                        <input type="text" name="notification" id="textbox" value={notification} onChange={(e)=>{setNotification(e.target.value)}} placeholder='notification' style={{width:"500px"}}/></div>
                    </div>
                  </div> */}
                  {/* Question and options */}

                  <hr className="my-4 md:min-w-full" />
                  <div
                    style={{
                      position: 'fixed',
                      top: '250px',
                      left: '620px',
                    }}
                  >
                    <SyncLoader
                      color="#23A5E0"
                      css={override}
                      size={12}
                      loading={isLoading}
                    />
                  </div>
                  <div className="question-container">
                    <label className="addArticle_label">Questions*</label>

                    {questionList.map((ques, index) => (
                      <div className="questions">
                        <div key={index} className="first-div">
                          <div>
                            <span>{`Ques ${index + 1}: `}</span>
                            <span style={{ marginRight: '5px' }}>
                              Screen Time(in sec.)*
                            </span>
                            <input
                              className="addQuiz-input difficulty-input"
                              type="number"
                              name="screenTime"
                              id="screenTime"
                              value={ques.screenTime}
                              disabled={true}
                            />
                          </div>
                          <input
                            className="addQuiz-input"
                            type="text"
                            id="question"
                            name="question"
                            value={ques.question}
                            onChange={(event) => {
                              handleQuestionChange(event, index);
                            }}
                            required
                          />
                          {ques.options.map((opt, optionIndex) => (
                            <div className="options">
                              <div key={optionIndex} className="first-div">
                                <div className="options-isTrue">
                                  {' '}
                                  <span>{`Option ${optionIndex + 1}: `}</span>
                                  <span>Is Correct?</span>
                                  <Checkbox
                                    checked={opt.isCorrect}
                                    name="isCorrect"
                                    onChange={(event) => {
                                      handleOptionCheck(
                                        event,
                                        index,
                                        optionIndex
                                      );
                                    }}
                                  />
                                </div>
                                <div className="options">
                                  <input
                                    className="addQuiz-input"
                                    type="text"
                                    id="value"
                                    name="value"
                                    value={opt.value}
                                    onChange={(event) =>
                                      handleOptionChange(
                                        event,
                                        index,
                                        optionIndex
                                      )
                                    }
                                  />
                                </div>
                                {ques.options.length - 1 === optionIndex && (
                                  <Button
                                    className="add-btn"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleOptionAdd(index)}
                                  >
                                    Add Option
                                  </Button>
                                )}
                              </div>

                              <div className="second-div">
                                {ques.options.length > 1 && (
                                  <Button
                                    className="remove-btn-opt"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() =>
                                      handleOptionRemove(index, optionIndex)
                                    }
                                  >
                                    Remove Opt.
                                  </Button>
                                )}
                              </div>
                            </div>
                          ))}
                          {questionList.length - 1 === index &&
                            questionList.length < 10 && (
                              <Button
                                className="add-btn"
                                variant="contained"
                                color="primary"
                                onClick={handleQuestionAdd}
                              >
                                Add Ques
                              </Button>
                            )}
                        </div>
                        <div className="second-div" style={{ marginLeft: "10px", marginTop: "4px" }}>
                          {questionList.length > 1 && (
                            <Button
                              className="remove-btn"
                              variant="contained"
                              color="secondary"
                              onClick={() => handleQuestionRemove(index)}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Question container end */}
                  <div style={{ display: "flex" }}>
                    <>
                      <select
                        style={{
                          // marginLeft: 20,
                          border: "2px solid #acb9ce",
                          borderRadius: 10,
                        }}
                        className="mb-5 mt-3"
                        value={authorId}
                        onChange={(e) => setAuthorId(e.target.value)}
                      >
                        <option value="">Select Author*</option>
                        {docs &&
                          docs.map((author) => (
                            <>
                              <option
                                selectedValue={author && author._id}
                                value={author._id}
                              >
                                {author.name}
                              </option>
                            </>
                          ))}
                      </select>
                    </>
                  </div>
                  <div className="submit-btn-container">
                    <button
                      disabled={disable}
                      type="submit"
                      className="submit-btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    sports: state.sportsReducer.sports,
    selectedSports: state.sportsReducer.selectedSports,
  };
};
export default connect(mapStateToProps)(AddQuiz);
