import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import SyncLoader from "react-spinners/SyncLoader";
import { useEffect, useState } from "react";
import { css } from "@emotion/core";
import LoginAppConstants from "../../constants/LoginAppConstants";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { categoryDataFromGA, authorDataFromGA, typeDataFromGA } from "../../handlers/googleAnalytics.handler";
import { _dateTimeFormatinIST } from "../../constants/appDefaults";

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;


const GoogleAnalyticsTable = (props)=>{
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [showTableType, setShowTableType] = useState("typeWise");
    const [categoryData, setCategoryData] = useState([]);
    const [page, setPage] = useState(1);
    const [typeData, setTypeData] = useState([]);
    const [data, setData] = useState([]);
    const [authorData, setAuthorData] = useState([]);
    const [sortBy, setSortBy] = useState("screenPageViews");
    const [searchBy, setSearchBy] = useState("all");
    const [startDate, setStartDate] = useState(new Date((new Date).setFullYear(2016, 0, 1)));
    const [endDate, setEndDate] = useState();
    const updateStartDate = (time) => {
        setStartDate(time);
        const dataRange = {
            startDate:time,
            endDate:endDate
        }
        CategoryWiseData(dataRange);
        AuthorWiseData(dataRange);
        TypeWiseData(dataRange);
      };
      const updateEndDate = (time) => {
        setEndDate(time);
        const dataRange = {
            startDate:startDate,
            endDate:time
        }
        CategoryWiseData(dataRange);
        AuthorWiseData(dataRange);
        TypeWiseData(dataRange);
      };
    
    //   const getYesterday = () => {
    //     const today = new Date();
    //     const yesterday = new Date(today);
    //     yesterday.setDate(today.getDate() - 1);
    //     return yesterday;
    //   };

      useEffect(()=>{
        setIsLoadingMore(true);
        const dateRange = {
            startDate:startDate,
            endDate:endDate
           }
           typeDataFromGA({
            sports: "",
            isDeleted: false,
            page:page,
            limit: 10,
            sortField: sortBy,
            sortOrder: -1,
            titleField: "",
            dateRange:dateRange
        })
        .then((response)=>{setData(response?.data); setIsLoadingMore(false)})
        .catch((error)=>{console.log(error); setIsLoadingMore(false)});
        setShowTableType("typeWise")
        
      },[])


      const CategoryWiseData = (dateRange)=>{
        setIsLoadingMore(true);
        categoryDataFromGA({
            sports: "",
            isDeleted: false,
            page:page,
            limit: 10,
            sortField: sortBy,
            sortOrder: -1,
            titleField: "",
            dateRange:dateRange
        })
        .then((response)=>{setCategoryData(response?.data)
            if(showTableType=="sportsWise"){
                // setData([]);
                setData(response?.data);
            }
            setIsLoadingMore(false)
          })
        .catch((error)=>{console.log(error); setIsLoadingMore(false)});
        
        
      }
      const AuthorWiseData = (dateRange)=>{
        setIsLoadingMore(true)
        authorDataFromGA({
            sports: "",
            isDeleted: false,
            page:page,
            limit: 10,
            sortField: sortBy,
            sortOrder: -1,
            titleField: "",
            dateRange:dateRange
        })
        .then((response)=>{setAuthorData(response?.data)
            if(showTableType=="authorWise"){
                // setData([]);
                setData(response?.data);
            }
            setIsLoadingMore(false)
          })
        .catch((error)=>{console.log(error); setIsLoadingMore(false)});
        
      }
      const TypeWiseData = (dateRange)=>{
        setIsLoadingMore(true)
        typeDataFromGA({
            sports: "",
            isDeleted: false,
            page:page,
            limit: 10,
            sortField: sortBy,
            sortOrder: -1,
            titleField: "",
            dateRange:dateRange
        })
        .then((response)=>{setTypeData(response?.data)
            if(showTableType=="typeWise"){
                // setData([]);
                setData(response?.data);
            }
            setIsLoadingMore(false)
          })
        .catch((error)=>{console.log(error); setIsLoadingMore(false)});
        
      }

      useEffect(()=>{
       const dateRange = {
        startDate:startDate,
        endDate:endDate
       }
        CategoryWiseData(dateRange);
        AuthorWiseData(dateRange);
        TypeWiseData(dateRange);
        if(showTableType=="typeWise"){
            // setData([]);
            setData(typeData);
        }
        if(showTableType=="authorWise"){
            // setData([]);
            setData(authorData);
        }
        if(showTableType=="sportsWise"){
            // setData([]);
            setData(categoryData);
        }
      },[sortBy, page]);

      const onChangeShow = (value)=>{
        setShowTableType(value);
        if(value=="typeWise"){
            // setData([]);
            setData(typeData);
            setPage(1);
        }
        if(value=="authorWise"){
            // setData([]);
            setData(authorData);
            setPage(1);
        }
        if(value=="sportsWise"){
            // setData([]);
            setData(categoryData);
            setPage(1);
        }
      }

      const onChangeSearch = (event)=>{
            setSearchBy(event.target.value);
            if(event.target.value=="all"){
                const today = new Date();
                const date = new Date(today);
                date.setFullYear(2016, 0, 1);
                setStartDate(date)
                const dataRange = {
                    startDate:date,
                    endDate:new Date()
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
            if(event.target.value=="today"){
                const today = new Date();
                const date = new Date(today);
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                setStartDate(date)
                setEndDate(new Date())
                const dataRange = {
                    startDate:date,
                    endDate:new Date()
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
            if(event.target.value=="yesterday"){
                const today = new Date();
                const sdate = new Date(today);
                sdate.setDate(today.getDate()-1);
                sdate.setHours(0);
                sdate.setMinutes(0);
                sdate.setSeconds(0);
                setStartDate(sdate);
                const edate = new Date(today);
                edate.setHours(0);
                edate.setMinutes(0);
                edate.setSeconds(0);
                setEndDate(sdate);
                const dataRange = {
                    startDate:sdate,
                    endDate:edate
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
            if(event.target.value=="last7days"){
                const today = new Date();
                const sdate = new Date(today);
                sdate.setDate(today.getDate()-6);
                sdate.setHours(0);
                sdate.setMinutes(0);
                sdate.setSeconds(0);
                setStartDate(sdate);
                setEndDate(new Date());
                const dataRange = {
                    startDate:sdate,
                    endDate:new Date()
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
            if(event.target.value=="last30days"){
                const today = new Date();
                const sdate = new Date(today);
                sdate.setDate(today.getDate()-29);
                sdate.setHours(0);
                sdate.setMinutes(0);
                sdate.setSeconds(0);
                setStartDate(sdate);
                setEndDate(new Date());
                const dataRange = {
                    startDate:sdate,
                    endDate:new Date()
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
            if(event.target.value=="thisMonth"){
                const today = new Date();
                const sdate = new Date(today);
                sdate.setDate(1);
                sdate.setHours(0);
                sdate.setMinutes(0);
                sdate.setSeconds(0);
                setStartDate(sdate);
                setEndDate(new Date);
                const dataRange = {
                    startDate:sdate,
                    endDate:new Date()
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
            if(event.target.value=="lastMonth"){
                const today = new Date();
                const sdate = new Date(today);
                sdate.setMonth(today.getMonth()-1);
                sdate.setDate(1);
                sdate.setHours(0);
                sdate.setMinutes(0);
                sdate.setSeconds(0);
                setStartDate(sdate);
                const edate = new Date(today.getFullYear(), today.getMonth(), 0);
                setEndDate(edate);
                const dataRange = {
                    startDate:sdate,
                    endDate:edate
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
            if(event.target.value=="lastYear"){
                const today = new Date();
                const sdate = new Date(today.getFullYear()-1, 0, 1);
                setStartDate(sdate);
                sdate.setHours(0);
                sdate.setMinutes(0);
                sdate.setSeconds(0);
                const edate = new Date(today.getFullYear()-1, 11, 31);
                setEndDate(edate);
                const dataRange = {
                    startDate:sdate,
                    endDate:edate
                }
                CategoryWiseData(dataRange);
                AuthorWiseData(dataRange);
                TypeWiseData(dataRange);
            }
      };

      const timeForm = (item)=>{
          const second = Math.round(item);
          
          return Math.floor(second/60)+"m "+second%60+"s";
      };

      const handlePagination = (event, value)=>{
          setPage(value);
      };
    return (
        <>
            <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
        style={{display:'block',}}
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                <div style={{display:'flex', justifyContent:"space-between", alignItems:'center'}}>
              <h3
                className={
                  "font-semibold text-lg " +
                  (props.color === "light" ? "text-blueGray-700" : "text-white")
                }
                style={{marginBottom:"30px"  }}
              >
                Google Analytics Manager
              </h3>
              <div>
                <select
                    style={{
                        width:"250px",
                        borderRadius:"10px"
                    }}
                    value={showTableType}
                    onChange={(event)=>onChangeShow(event.target.value)}
                >
                    <option value="typeWise">Type Wise Data</option>
                    <option value="authorWise">Author Wise Data</option>
                    <option value="sportsWise">Sports Wise Data</option>
                </select>
              </div>
                </div>
                <div style={{display:"flex"}} >
                   <div style={{marginRight:"10px"}}>
                   <h3 style={{fontWeight:"600"}}>Sort By:</h3>
                    <select
                        style={{
                            borderRadius:"10px",
                            width:"200px"
                        }}
                        value={sortBy}
                        onChange={(event)=>setSortBy(event.target.value)}
                    >
                        <option value="screenPageViews">Page Views</option>
                        <option value="totalUsers">Total Users</option>
                        <option value="averageSessionDuration">Avg. Session Time</option>
                    </select>
                   </div>
                   <div style={{marginRight:"10px"}}>
                   <h3 style={{fontWeight:"600"}}>Search By:</h3>
                   <select
                        style={{
                            borderRadius:"10px",
                            width:"200px"
                        }}
                        value={searchBy}
                        onChange={onChangeSearch}
                    >

                        <option value="all">ALL</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="last7days">Last 7 Days</option>
                        <option value="last30days">Last 30 Days</option>
                        <option value="thisMonth">This Month</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="lastYear">Last year</option>
                    </select>
                   </div>
                <div className="wrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="relative ">
                    <DatePicker
                      label="Start Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={startDate}
                      onChange={updateStartDate}
                      style={{
                        marginRight: "10px",
                        width: "40%",
                      }}
                      // disableToolbar
                      maxDate={endDate}
                    />
                    <DatePicker
                      label="End Date"
                      // emptyLabel="End Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={endDate}
                      onChange={(e) => updateEndDate(e)}
                      style={{
                        width: "40%",
                      }}
                      // disableToolbar
                      minDate={startDate}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
                </div>
            </div>
          </div>
        </div>

    <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          {data?.data?.length>0?<table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"10%"}}
                >
                 {showTableType=="typeWise"?"Type":showTableType=="authorWise"?"Author":"Sports"}
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                 Count
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"25%"}}
                >
                  Page Views
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Total Users
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                 Average Session Duration
                </th>
              </tr>
            </thead>

            <tbody>
              
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoadingMore}
                  css={override}
                  size={12}
                />
              

              {data?.data?.map((item, position) => (
                <tr>
                   <td
                    className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ fontWeight: "bolder", width:"10%" }}
                  >
                    {showTableType=="sportsWise"?item?.name:item?._id}
                  </td>
                   <td
                    className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ fontWeight: "bolder", width:"10%" }}
                  >
                    {item?.contentCount}
                  </td>
                   <td
                    className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ fontWeight: "bolder", width:"10%" }}
                  >
                    {item?.screenPageViews}
                  </td>
                   <td
                    className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ fontWeight: "bolder", width:"10%" }}
                  >
                    {item?.totalUsers}
                  </td>
                   <td
                    className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ fontWeight: "bolder", width:"10%" }}
                  >
                    {timeForm(item?.averageSessionDuration)}
                  </td>
                  
                </tr>
              ))}
            </tbody>
            
          </table>:
            <div style={{width:'100%', textAlign:"center", marginTop:"40px", fontSize:"20px", fontWeight:"700"}}>
              Data to be Updated soon.
            </div>}
         
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={page}
                onChange={handlePagination}
                count={Math.round(data?.totalCount/10)+(data?.totalCount%10>0?1:0)}
                variant="outlined"
                color="primary"
              />
            </Stack>
            {/* console.log */}
          </div>
          <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
        
        
      
      </div>
        </>
    );
}
export default GoogleAnalyticsTable;