import React from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';


import OptaShorts from './OptaShorts'
const OptaShortsIndex = (props) => {
  return (
    <>
      <Sidebar/>
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <OptaShorts color="light" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OptaShortsIndex;