import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import {
  dispatchPlayersList,
  dispatchPlayersListFilter,
  dispatchNumberOfPlayersPages,
} from "../../redux/action-dispatchers/playerProfile.dispatcher";
import Axios from "axios";
import { API_BASE_PATH, OLD_BASE_PATH } from "../../constants/workerConstants";
import { useHistory } from "react-router";
import { GetPlayerList } from "../../handlers/playerProfile.handler";
import {
  dispatchAllLeagues,
  dispatchSelectedLeague,
} from "../../redux/action-dispatchers/leagues.dispatchers";

import { _getDateTime, _dateTimeFormat } from "../../constants/appDefaults";
import SyncLoader from "react-spinners/SyncLoader";
import LoginAppConstants from "../../constants/LoginAppConstants";
import { css } from "@emotion/core";
import "../../pages/Dashboard/LeagueManagement/LeagueManage.css"


const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const PlayerTable = (props) => {
  const mHistory = useHistory();
  const [authorName, setAuthorName] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false); // for Modal Open State
  
  const [page, setPage] = useState(1);
  {/**Modal open and close */}
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const [searchString, setSearchString] = useState("");
  // let searchStringGlobal = "";
  let timer = null;
  // useEffect(() => {
  //   dispatchSelectedLeague({
  //     selectedLeague: "",
  //     selectedLeagueFilter: "",
  //   });
  // }, []);

  // useEffect(() => {
  //   dispatchSelectedSports({
  //     selectedSports: "",
  //     selectedSportsFilter: "",
  //   });
  // }, []);

  useEffect(() => {
    getPlayers(
      page,
      parseInt(props.limit),
      searchString,
      false
    );
  }, [
    page,
    // props.selectedSports,
    // props.selectedLeague,
    searchString,
  ]);
  useEffect(() => {
    Axios.get(
      `${API_BASE_PATH}leagues/leagueWatchList`,
      { headers: { "cms-access-token": accessToken, source: "cms" } },
      {
        params: JSON.stringify({
          username: localStorage.getItem("email"),
        }),
      }
    ).then((res) => {
      if (res.data.message == "Error in authorization middleware") {
        alert(`${res.data.message} in League watch list`);
      } else dispatchAllLeagues(res.data.data?.data);
    });
  }, []);
  // const leagueName = (id) => {
  //   const leagueInfo = props.leagues?.find((item) => item.comp_id === id);
  //   if (leagueInfo) {
  //     return leagueInfo.abbr;
  //   }
  //   return "N/A";
  // };
  // const onSportsTypeChange = (value) => {
  //   setGetSports(value);
  // };
  // const onAuthorChange = (event) => {
  //   setAuthorName(event.target.value);
  //   getPlayers(
  //     page,
  //     parseInt(props.limit),
  //     // props.selectedLeague,
  //     // props.selectedSports,
  //     searchStringGlobal,
  //     event.target.value,
  //     false
  //   );
  // };

  const getPlayers = (
    _page,
    _limit,
    // _selectedLeague,
    // _selectedSports,
    _name,
    _author,
    isLoadMore
  ) => {
    setIsLoading(true);
    GetPlayerList({
      page: page,
      limit: parseInt(props.limit),
      // league: props.selectedLeague,
      // sportId: props.selectedSports,
      name: searchString,
      author:authorName
    })
      .then((response) => {
        setIsLoading(false);
        // if (response.statusCode == 401) {
        //   alert('Token Expired please log in again!!!');
        //   logoutUser();
        //   mHistory.push('/');
        // }
        // else if (response.message == 'Request failed with status code 403') {
        //   window.alert(
        //     `You don't have permission to perform this action in this module`
        //   );
        // }
        // else
        if (response.status) {
          dispatchNumberOfPlayersPages(response.data.totalPages);
          if (isLoadMore) {
            dispatchPlayersList(response.data);
          } else {
            dispatchPlayersListFilter(response.data);
          }
        }
      })

      .catch((error) => {
        // console.log('error 401 catch', error);

        isLoadMore && setIsLoadingMore(false);
      });
  };

  const onView = (playersId) => {
    mHistory.push(`/player-profile/${playersId}`);
  };
  // const onSportsTypeChange = (value) => {
  //   setGetSports(value);
  // };
  const handlePagination = (event, value) => {
    setPage(value);
    getPlayers(
      value,
      parseInt(props.limit),
      // props.selectedLeague,
      // props.selectedSports,
      // searchStringGlobal,
      authorName,
      false
    );
  };
  const handleInputChange = (event) => {
   
    
      setSearchString(event.target.value);
    
  };
  // useEffect(() => {
  //   searchedData();
  // }, [searchString]);

  const searchedData = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      searchStringGlobal = searchString;

      getPlayers(
        page,
        parseInt(props.limit),
        // props.selectedLeague,
        // props.selectedSports,
        searchString,
        authorName,
        false
      );
    }, 500);
  };

    
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
        style={{display:open?'none':'block',}}
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (props.color === "light" ? "text-blueGray-700" : "text-white")
                }
                style={{marginBottom:"30px"  }}
              >
                Player Profile
              </h3>
                <div style={{display:"flex"}} >
                  {/* <span style={{ marginLeft: 20 }}>
                    <AuthorSelectDropdown
                      selectedValue={authorName}
                      // onChange={(event) =>
                      //   setAuthorName(event.target.value)
                      // }
                    onChange={onAuthorChange}
                    />
                  </span>
                  <span style={{ marginLeft: 4}}>
                    <SportsDropDown
                      selectedValue={getsports}
                      onChange={onSportsTypeChange}
                    />
                  </span>

                  <span style={{ marginLeft: 4 }}>
                    <LeaguesDropDown />
                  </span> */}
                  <span
                style={{
                  width: "40%",
                  paddingBottom:"2px"
                  // display: "flex",
                  // justifyContent: "flex-end",
                }}
              >
                <input
                  value={searchString}
                  style={{
                    width:"100%",
                    height:"100%",
                    border: "2px solid #acb9ce",
                    borderRadius: 10,
                    marginRight: "3%",
                    padding: "3px",
                    // paddingLeft: '10px',
                  }}
                  placeholder="Search Player"
                  onChange={handleInputChange}
                />
              </span>
                </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"10%"}}
                >
                  Sr. No.
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Player Image
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"25%"}}
                >
                  Player Name
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Perma Link
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Associated Sport
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Created By
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Created At
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Update At
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"5%"}}
                >
                  Action / Edit
                </th>
               
              </tr>
            </thead>

            <tbody>
              
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
              

              {props.docs.map((players, position) => (
                <tr>
                   <td
                    className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ fontWeight: "bolder", width:"10%" }}
                  >
                    {position+1}
                  </td>
                  <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 " style={{width:"20%"}}>
                    
                    <img 
                      src={players?.player?.player_img}
                      style={{
                        // background: `url('${players?.player?.player_img}?${players?._id}') 0% 0% / cover no-repeat`,
                        width: "80px",
                        height: "80px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </th>
                  <td
                    className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    style={{ cursor: "pointer", fontWeight: "bolder", width:"25%" }}
                  >
                    {players?.player?.title}
                  </td>
                  
                  <td
                    className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                    onClick={() => {
                      if (!players.seo_slug) {

                        alert("Player does not have seo slug...")
                      }

                    }}
                    style={{ cursor: "pointer", fontWeight: "bolder" }}
                  >
                    <a href={(props?.selectedLanguage?.toLowerCase()=="english"?"https://m.thesportstak.com":"https://m.hindi.thesportstak.com")+"/playerstats/cricket/"+players?.seo_slug}
                      target="_blank">
                      <img src="/LinkIcon.png" style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
                    </a>
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"style={{width:"20%"}}>
                    {players?.sports && players?.sports.name ? players?.sports.name : "Cricket"}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"20%"}}>
                    {players?.createdBy?players?.createdBy
                      : "N/A"}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"20%"}}>
                  {_dateTimeFormat(players?.createdAt, "ddd, DD MMM hh:mm A")}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"20%"}}>
                  {_dateTimeFormat(players?.updatedAt, "ddd, DD MMM hh:mm A")}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{width:"5%"}} onClick={() => {
                                    
                                    onView(players?.playerId);
                                  }}>
                  <i
                                  className="fas fa-edit fa-1x"
                                  style={{
                                    marginTop: 5,
                                    marginBottom: 10,
                                    marginRight: "10px",
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    
                                    onView(players?.playerId);
                                  }}
                                ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
         
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={page}
                onChange={handlePagination}
                count={props?.totalPages}
                variant="outlined"
                color="primary"
              />
            </Stack>
            {/* console.log */}
          </div>
          <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      
      </div>
      
      
      


      
      
    </>
  );
};

PlayerTable.defaultProps = {
  color: "light",
};

PlayerTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
const mapStateToProps = (state) => {
  return {
    totalPages: state.playerProfileReducer.totalPages,
    docs: state.playerProfileReducer.docs,
    page: state.playerProfileReducer.page,
    limit: state.playerProfileReducer.limit,
    leagues: state.leaguesReducer.leagues,
    hasNextPage: state.playerProfileReducer.hasNextPage,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
    selectedSports: state.sportsReducer.selectedSports,
  };
};
export default connect(mapStateToProps)(PlayerTable);
