import React, { useEffect, useState } from 'react';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import './user.css';
import RolesDropdown from '../Roles/RolesDropdown';
import UnsavedChangesWarning from '../../../../components/AlertModal/UnsavedChangesWarning';
import { AddUserHandler } from '../../../../handlers/user.handlers';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import UserLangDropdown from '../UserLangDropdown/UserLangMultiSelect';
import UserLangMultiSelect from '../UserLangDropdown/UserLangMultiSelect';
import UserLanguageDropdown from '../UserLangDropdown/UserLanguageDropdown';
import axios from 'axios';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const AddUser = (props) => {
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    image: undefined,
    email: '',
    password: '',
    name: '',
    languagesAllowed: '',
    roles: [],
  });
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
    file: '',
  });
  let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
  let Languages_Allowed = localStorage.getItem('Languages Allowed');
  let Current_Language = localStorage.getItem('Current_Language');

  const onImageFileSelect = (event) => {
    let file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;

        setPreviewModal({
          imageData: reader.result,
          show: true,
          file: file,
        });
      };
    };
    reader.onerror = function (error) {};

    setIsUnsaved();
  };
  const onRolesChange = (value) => {
    const roleArray = [];
    roleArray.push(value);

    setFormData({ ...formData, roles: roleArray });
  };
  const onLangChange = (value) => {
    setFormData({ ...formData, languagesAllowed: value });
  };
  // console.log('formdata.languagesAllowed',formData.languagesAllowed)
  // useEffect(()=>{
  //   if(Languages_Allowed !== 'Hindi,English' || Languages_Allowed !== 'Englihs,Hindi')
  //   {
  //     setFormData({ ...formData, languagesAllowed: Current_Language });
  //   }
  // },[])
  const onFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setIsUnsaved();
  };

  const AddNewUser = () => {
    if (formData.name == "") {
      alert("Name is required");
      return;
    } else if (formData.email == "") {
      alert("Email is required");
      return;
    } else if (formData.password == "") {
      alert("Password is required");
      return;
    } else if (formData.languagesAllowed == "") {
      alert("Language is required");
      return;
    } else if (formData.roles.length <= 0) {
      alert("Role is required");
      return;
    }
    setIsLoading(true);
    // const body = { ...formData, image: previewModal.file };
    const body = {
      ...formData,
      image: previewModal.imageData,
      languagesAllowed:
        Languages_Allowed !== 'Hindi,English'
          ? Current_Language
          : formData.languagesAllowed,
    };

    AddUserHandler(body, {
      'cms-access-token': cms_access_token,
      source: 'cms',
    })
      .then((res) => {
        if (res.data.code == 100) {
          setIsLoading(false);
          alert("Email already exists");
        } else if (res.error) {
          setIsLoading(false);
          alert(res.message)
        }
        if (res.data.error == false) {
          setFormData({
            image: undefined,
            email: '',
            password: '',
            name: '',
            languagesAllowed: '',
            roles: [],
          });
          alert("User created successfully");
          setIsLoading(false);
          history.push('/cms-users');
        }
      })
      .catch((error) => console.log('error', error));
  };

  const handleBack = () => {
    history.goBack();
  };
  const userLanguage = ['Hindi', 'English'];
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <div
          className="pb-4"
          style={{
            cursor: 'pointer',
            paddingTop: '30px',
            marginLeft: '20px',
            width: '30px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleBack}
        >
          <ArrowBackIcon /> <p className="px-2">Back</p>
        </div>
      </div>
      <div className="relative md:ml-64 ">
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0">
            <div className="bg-blueGray-100 py-2 px-4">Add user</div>
            <div className="user-info-container">
              <div>
                {previewModal.imageData && (
                  <img
                    src={previewModal.imageData}
                    style={{
                      width: 500,
                      height: 300,
                      border: '2px solid gray',
                    }}
                  />
                )}
                <input
                  type="file"
                  name="file"
                  onChange={onImageFileSelect}
                  style={{
                    backgroundColor: '#000',
                    height: 60,
                    width: 60,
                    position: 'absolute',
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                />

                <i
                  className="fas fa-camera fa-2x"
                  style={{
                    margin: 8,
                    color: '#555',
                    cursor: 'pointer',
                  }}
                ></i>
              </div>
            </div>
            <SyncLoader
              color="#23A5E0"
              css={override}
              size={12}
              loading={isLoading}
            />
            <div className="user-info-container">
              <h3>User Name</h3>{' '}
              <input
                type="text"
                name="name"
                placeholder="Enter Name Here"
                value={formData.name}
                onChange={onFormDataChange}
              />
            </div>
            <div className="user-info-container">
              <h3>Email Address</h3>{' '}
              <input
                type="text"
                name="email"
                placeholder="Enter Email-Id Here"
                value={formData.email}
                onChange={onFormDataChange}
              />
            </div>
            <div className="user-info-container">
              <h3>Password</h3>{' '}
              <input
                type="password"
                name="password"
                placeholder="Enter password Here"
                value={formData.password}
                onChange={onFormDataChange}
              />
            </div>
            <div className="user-info-container">
              {' '}
              {/* <input
                type="text"
                name="languagesAllowed"
                placeholder="Enter languagesAllowed"
                value={formData.languagesAllowed}
                onChange={onFormDataChange}
              /> */}
              {/* <UserLangMultiSelect docs={userLanguage} /> */}
              <>
                {' '}
                <h3>Language Allowed</h3>
                <UserLanguageDropdown
                  current_lang={Current_Language}
                  onChange={onLangChange}
                />
              </>
            </div>
            <div className="user-info-container">
              {formData.languagesAllowed !== '' && (
                <>
                  {' '}
                  <h3>Roles</h3>{' '}
                  <RolesDropdown
                    onChange={onRolesChange}
                    language={formData?.languagesAllowed}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            AddNewUser();
            setPristine();
          }}
          className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ml-4 mb-4 ease-linear transition-all duration-150`}
        >
          Add User
        </button>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    userLanguage: state.authReducer.userLanguage,
  };
};
export default connect(mapStateToProps)(AddUser);
