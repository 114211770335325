import AppConstants from '../constants';
import AxiosInstance from './http.handlers';
import AxiosInstanceV2Call from './http.v2Call.handlers';

// export const GetPaginatedNews = async (
//   pageNumber,
//   noOfRecords,
//   selectedLeagues,
//   newsType,
//   showPublishedNews,
//   headers
// ) => {
//   return await AxiosInstance.get(
//     `${AppConstants.WorkerConstants.API_ROUTES.NEWS.GET_PAGINATED}/${pageNumber}/${noOfRecords}/${selectedLeagues}/${showPublishedNews}/${newsType}`,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };
// {page,
//   limit,
//   entity,
//   showArticle,
//   copyRight,
//   league,
//   sportId,
//   startDate,
//   endDate}
export const GetPaginatedNews = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.GET_PAGINATED}`,
    body
  )
    .then((response) => response)
    .catch((error) => {
      error;
    });
};
export const GetCopyHyperlink = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.COPY_HYPERLINK}`,
    body
  )
    .then((response) => response)
    .catch((error) => {
      error;
    });
};

export const GetNewsInfo = async (id, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.NEWS_INFO}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const mapArticleToLeague = async (_id, league, uuid, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.MAP_ARTICLE}`,
    {
      _id,
      league,
      uuid,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const mapArticleToMatches = async (match_id, _id, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.MAP_ARTICLE_TO_MATCHES}`,
    {
      match_id,
      _id,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const mapArticleToTeams = async (_id, league, uuid, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.MAP_ARTICLE}`,
    {
      _id,
      league,
      uuid,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const mapArticleToSports = async (_id, sportsId, uuid, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.UPDATE_SPORTS}`,
    {
      _id,
      sportsId,
      uuid,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const toggleArticleStatus = async (_id, showArticle, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.TOGGLE_ARTICLE_STATUS}`,
    {
      _id,
      showArticle,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const uploadArticleImage = async (_id, imageData, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.UPLOAD_ARTICLE_IMAGE}`,
    {
      _id,
      file: imageData,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const addNewArticle = async (params, headers) => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.ADD_NEWS}`,
    formData,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ChangeNewsStatus = async (id, status, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.TOGGLE_ARTICLE_STATUS}`,
    {
      id,
      status,
    },
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ChangeNewsUpdate = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.UPDATE_NEWS}`,
    body,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetSectionNews = async (
  section,
  pageNumber,
  noOfRecords,
  headers
) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.GET_SECTION}/${section}/${pageNumber}/${noOfRecords}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const tagHome = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.TAG_NEWS_TO_HOME}`,

    body
  );
};
export const tagTeams = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.TAG_NEWS_TO_TEAMS}`,

    body
  );
};
export const tagTeamsSpoerWise = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.TAG_NEWS_TO_TEAMS_SPORTSWISE}`,

    body
  );
};

export const removeTeams = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.REMOVE_TAGGED_TEAMS}`,
    body
  );
};
export const getHomeNews = async (pageNumber, noOfRecords, selectedSports) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.GET_HOME_NEWS}`
  )
    .then((response) => response)
    .catch((error) => error);
};
export const updateNewsRanking = async (newsList, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.UPDATE_NEWS_RANKING}`,
    {
      newsList,
    },
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const updateHomeRanking = async (newsList, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.UPDATE_HOME_RANKING}`,
    {
      newsList,
    },
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

// Tag News to Twitter Trends
export const tagTwitterTrends = async (body,headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.TAG_NEWS_TO_TRENDING}`,
    
    body, {headers}
  )
  .then((res) => res)
  .catch((error) => error);
};

// Chat GPT Clickbait Title
export const generateClickbaitHeadline = async (body,headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.CLICKBAIT_HEADLINE}`,
    
    body, {headers}
  )
  .then((res) => res)
  .catch((error) => error);
};

export const addNewArticleWithoutToken = async (params, headers) => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return await AxiosInstanceV2Call.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.ADD_NEWS}`,
    formData,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};


export const toggleArticleStatusWithoutToken = async (_id, showArticle, headers) => {
  return await AxiosInstanceV2Call.post(
    `${AppConstants.WorkerConstants.API_ROUTES.NEWS.TOGGLE_ARTICLE_STATUS}`,
    {
      _id,
      showArticle,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};