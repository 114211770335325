import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  GetCMSAllVimeoLiveStream,
  PublishVimeoLiveStream,
  EditTitleVimeo,
  UnPublishVimeoLiveStream,
  TagHomeFeature,
  TagFeature,
  AddVimeoURL,
  AddVimeoImages,
  GetVimeoURL,
} from '../../../handlers/live.handlers';
import _ from 'lodash';
import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
import { Button, Checkbox } from '@material-ui/core';

import Sidebar from '../../../components/Sidebar/Sidebar';
import { logoutUser } from '../../../services/auth';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const Vimeo = () => {
  const mHistory = useHistory();
  const [docs, setDocs] = useState([]);
  const [showEdit, showEditHandler] = useState(false);
  const [showURLEdit, showEditURLHandler] = useState(false);
  const [isEditVimeoURL, setIsEditVimeoURL] = useState(false);
  const [title, setTitle] = useState('');
  const [itemId, setItemId] = useState('');
  const [eventId, setEventId] = useState('');
  const [vimeoURL, setVimeoURL] = useState('');
  const [vimeoURLData, setVimeoURLData] = useState('');
  const [isPublished, setIsPublished] = useState(false);
  const [vimeoImage, setViemoImage] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const color = 'light';
  const [isLoading, setIsLoading] = useState(false);
  const [tagHome, setTagHome] = useState(false);
  const [checkedLive, setCheckedLive] = useState([]);
  const [checkedLiveFeature, setCheckedFeatureLive] = useState([]);
  const [publishDisable, setPublishDisable] = useState(false);
  let fileInput = React.useRef();


  const getLiveData = () => {
    GetCMSAllVimeoLiveStream()
      .then((response) => {
        if (response.status == false) {
          // logoutUser();
          // mHistory.push('/');
        }
        setDocs(response.data);
        setIsLoading(false);
        setIsPublished(response.data.some((obj) => obj.isLive == 2));
      })
      .catch((error) => console.log(error));
  };
  const tagToHome = (event, event_id, istagged) => {
    const recordFound = _.find(checkedLive, (items) => items === event_id);

    if (recordFound) {
      const newList = _.filter(checkedLive, (item) => item !== event_id);
      setCheckedLive(newList);

      const body = {
        tagHome: event.target.checked,

        event_id: event_id,
      };
      TagHomeFeature(body)
        .then((response) => {
          if (response) {
            getLiveData();
            alert('Tagging success !');
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });
    } else {
      const body = {
        tagHome: event.target.checked,

        event_id: event_id,
      };
      setCheckedLive([...checkedLive, event_id]);
      TagHomeFeature(body)
        .then((response) => {
          if (response) {
            getLiveData();
            alert('Tagging success !');
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });
    }
  };
  const tagToFeature = (event, event_id, istagged) => {
    const recordFound = _.find(
      checkedLiveFeature,
      (items) => items === event_id
    );

    if (recordFound) {
      const newList = _.filter(checkedLiveFeature, (item) => item !== event_id);
      setCheckedFeatureLive(newList);

      const body = {
        tagFeatureCard: event.target.checked,
        event_id: event_id,
      };
      TagHomeFeature(body)
        .then((response) => {
          if (response) {
            getLiveData();
            alert('Tagging success !');
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });
    } else {
      const body = {
        tagFeatureCard: event.target.checked,
        event_id: event_id,
      };
      setCheckedFeatureLive([...checkedLiveFeature, event_id]);
      TagHomeFeature(body)
        .then((response) => {
          if (response) {
            getLiveData();
            alert('Tagging success !');
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getLiveData();
  }, []);
  const handleClick = (event_id) => {
    const body = { event_id: event_id };
    setPublishDisable(true);
    PublishVimeoLiveStream(body).then((response) => {
      alert('Vimeo updated successfully');
      getLiveData();
      setPublishDisable(false);
    });
  };

  const handleClickUnpublish = (event_id) => {
    const body = { event_id: event_id };
    setPublishDisable(true);
    UnPublishVimeoLiveStream(body).then((response) => {
      alert('Vimeo updated successfully');
      getLiveData();
      setPublishDisable(false);
    });
  };

  const handleChange = () => {
    const body = { event_id: eventId, event_title: title };
    EditTitleVimeo(body).then((response) => {
      showEditHandler(false);
      alert('Vimeo Title updated successfully');
      getLiveData();
    });
  };

  const addVimeoURLHandler = () => {
    const body = { vimeoEventId: eventId, videourl: vimeoURL };
    AddVimeoURL(body).then((response) => {
      showEditURLHandler(false);
      alert('Vimeo URL updated successfully');
      getLiveData();
    });
  };
  const addVimeoImage = () =>{
    if(vimeoImage)
    {
      const data = {
          _id : itemId,
          image: vimeoImage
      };
      const formData = new FormData();
  
      Object?.keys(data)?.forEach(function (key) {
        formData?.append(key, data[key]);
      });
      AddVimeoImages(formData).then((response)=>{
       
          alert('Vimeo Image uploaded Successfully!');
          setViemoImage('');
          setUpdateImage('');
          window.location.reload(false);
      })
    }
    else{
      alert('Please upload a Image in given Ratio')
    }
    
  };
  const getVimeoURLHandler = (event_id) => {
    GetVimeoURL(event_id).then((response) => {
      response.data !== null &&
        setVimeoURL(response.data && response.data.videourl);
    });
  };
  useEffect(() => {
    getVimeoURLHandler();
  }, []);
  const handleVimeoURL = (item) => {
    showEditURLHandler(true);
    setEventId(item.event_id);
    setVimeoURL(vimeoURLData);
    getVimeoURLHandler(item.event_id);
    setItemId(item?._id);
    setUpdateImage(item?.thumbnail)
  };
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = '';

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (width !==(4*height)/3) {
          alert('Image width and height must be in ratio of 4:3 ');
          return false;
        }
      };
      setViemoImage(file);
      setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
    
  };
  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '45%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                showEditHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            Edit Title:{' '}
            <input
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Edit title"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
              style={{
                width: '60%',
                height: '33px',
                margin: '1.2%',
                marginRight: '4%',
              }}
            />
            <Button
              variant="contained"
              onClick={() => handleChange()}
              style={{ marginBottom: '1.5%' }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="edit__popup">
        <div
          onClick={() => showEditURLHandler(false)}
          className={showURLEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={
            showURLEdit ? 'edit__popup-body active' : 'edit__popup-body'
          }
          style={{ width: '45%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                showEditURLHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            Vimeo URL:{' '}
            <input
              type="text"
              value={vimeoURL}
              onChange={(e) => {
                setVimeoURL(e.target.value);
              }}
              placeholder="Enter Vimeo URL"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
              style={{
                width: '60%',
                height: '33px',
                margin: '1.2%',
                marginRight: '4%',
              }}
            />
            <Button
              variant="contained"
              onClick={() => addVimeoURLHandler()}
              style={{ marginBottom: '1.5%', backgroundColor:"#50C878", color:"#fff" }}
            >
              Save
            </Button>

            <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"30px"}}>
              
              <div style={{width: 284,
                            height: 213,
                            border: '2px solid gray',marginBottom:"10px"}}>
                  {updateImage?<img src={updateImage} style={{ height:210, maxWidth:280}}/>:null}
                  </div>  
                  <div style={{fontWeight:"600", color:"gray"}}>Please upload the image in ratio of 4:3 *</div>
              <div>
                 <input
                          ref={fileInput}
                          type="file"
                          name="Viemo Image"
                          onChange={onFileSelect}
                          style={{
                            backgroundColor: "#000",
                            borderRadius:"1px solid #111",
                            height: 35,
                            width: 40,
                            position: "absolute",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                        <i
                          className="fas fa-camera fa-2x"
                          style={{
                            margin: 8,
                            color: "#555",
                            cursor: "pointer",
                          }}
                        ></i>
              </div>
              <Button
              variant="contained"
              onClick={() => addVimeoImage()}
              style={{ marginBottom: '1.5%',  }}
            >
              Update Image
            </Button>
            </div>
                    
          </div>
        </div>
      </div>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Vimeo
                    </h3>
                    <h2>* Please publish one video at a time</h2>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Title
                      </th>

                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Publish / Unpublish
                      </th>
                      {/* <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Tag to Home
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Tag to Feature Card
                      </th> */}
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Edit
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs &&
                      docs.map((item) => {
                        return (
                          <tr>
                            <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: 'bolder',
                              }}
                            >
                              {item.title}
                              {item.isLive == true && (
                                <i
                                  className="fas fa-edit fa-1x"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '5px',
                                    color: '#c3c3c3',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    showEditHandler(true);
                                    setTitle(item.title);
                                    setEventId(item.event_id);
                                  }}
                                ></i>
                              )}
                            </td>

                            <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: 'bolder',
                              }}
                            >
                              <Button
                                variant="contained"
                                disabled={(item.isLive==false || publishDisable)? true : false}
                                onClick={() => {
                                  item.isPublished
                                    ? handleClickUnpublish(item.event_id)
                                    : handleClick(item.event_id);
                                }}
                              >
                                {item.isPublished == true
                                  ? 'Unpublish'
                                  : 'Publish'}
                              </Button>
                            </td>
                            {/* <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: 'bolder',
                              }}
                            >
                             
                              <Checkbox
                                checked={
                                  item.tagHome === true
                                    ? true
                                    : false &&
                                      _.find(
                                        checkedLive,
                                        (items) => items === item.event_id
                                      ) !== undefined
                                }
                                onChange={(event) =>
                                  tagToHome(event, item.event_id, item.tagHome)
                                }
                                color="default"
                                inputProps={{
                                  'aria-label': 'checkbox with default color',
                                }}
                              />
                            </td>
                            <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: 'bolder',
                              }}
                            >
                              <Checkbox
                                checked={
                                  item.tagFeatureCard === true
                                    ? true
                                    : false &&
                                      _.find(
                                        checkedLiveFeature,
                                        (items) => items === item.event_id
                                      ) !== undefined
                                }
                                onChange={(event) =>
                                  tagToFeature(
                                    event,
                                    item.event_id,
                                    item.tagFeatureCard
                                  )
                                }
                                color="default"
                                inputProps={{
                                  'aria-label': 'checkbox with default color',
                                }}
                              />
                            </td> */}
                            {
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  // cursor: "pointer",
                                  fontWeight: 'bolder',
                                }}
                              >
                                <i
                                  className="fas fa-edit fa-1x"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '5px',
                                    color: '#c3c3c3',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleVimeoURL(item)}
                                ></i>
                              </td>
                            }
                            {/* <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: 'bolder',
                              }}
                            >
                              {isEditVimeoURL ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                >
                                  <input
                                    type="text"
                                    value={vimeoURL}
                                    style={{
                                      margin: '2px 5px',
                                      height: '40px',

                                      borderRadius: '5px',
                                    }}
                                    onChange={(e) =>
                                      setVimeoURL(e.target.value)
                                    }
                                  />
                                  <button
                                    style={{
                                      color: 'white',
                                      padding: '4px 10px',
                                      backgroundColor: 'blue',
                                      borderRadius: '4px',
                                      width: '50px',
                                    }}
                                    onClick={addVimeoURLHandler}
                                  >
                                    OK
                                  </button>
                                </div>
                              ) : (
                                <>
                                  {isEditVimeoURL ? (
                                    <i
                                      onClick={() => setIsEditVimeoURL(false)}
                                      className="far fa-times-circle secondary btn quizDetail-topic-editButtonCancel"
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                    ></i>
                                  ) : (
                                    <i
                                      onClick={() => setIsEditVimeoURL(true)}
                                      className="far fa-edit px-3 quizDetail-topic-editButton"
                                    ></i>
                                  )}
                                </>
                              )}
                            </td> */}
                            <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: 'bolder',
                              }}
                            >
                              {item.isLive == true ? 'Live' : 'Not Live'}
                              {/* {item.isLive == 2
                                ? "Live and published"
                                : item.isLive == 0
                                ? "Not Live"
                                : "Live but not published"} */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vimeo;
