import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import CropperModal from '../../../components/CropperModal';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
import { updateBanner, GetBanner } from '../../../handlers/banner.handlers';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const Banner = () => {
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [bannerId, setBannerId] = useState('');
  const [banner, setBanner] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const [moveToPrefrences, setMoveToPrefrences] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  let fileInput = React.useRef();

  useEffect(() => {
    // setIsImageUploading(true);
    getBanner();
  }, []);
  const getBanner = () => {
    GetBanner()
      .then((response) => {
        setBannerId(response.data._id);
        setBanner(response.data.banner);
        setIsImageUploading(false);
        setMoveToPrefrences(response.data.showPrefrences);
        setShowBanner(response.data.showBanner);
      })
      .catch((error) => error);
  };
  const onFileSelect = (event) => {
    let file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;

        if (height === 1080 && width === 1080) {
          setBanner(reader.result);
        } else {
          alert('Image width and height must be 1080x1080 px');
          return false;
        }
      };

      setShowCropper(true);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };
  const body = {
    _id: bannerId,
    banner: banner,
    showPrefrences: moveToPrefrences,
    showBanner: showBanner,
  };

  updateBanner(body)
    .then((response) => {
      // if (response) {
      //   getBanner();
      // }
    })
    .catch((error) => {
      setIsImageUploading(false);
    });
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white'
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3 className={'font-bold text-lg text-blueGray-700'}>
                      Banner
                    </h3>
                  </div>
                </div>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                  <div className="px-4 py-5 flex-auto">
                    <div
                      style={{ display: 'inline-block', verticalAlign: 'top' }}
                    >
                      <img src={banner} style={{ width: 200 }} />
                      {isImageUploading ? (
                        <div style={{ textAlign: 'center', padding: 10 }}>
                          <div>Uploading Image Please Wait...</div>
                          <div>
                            <PulseLoader
                              color="#23A5E0"
                              loading={true}
                              css={override}
                              size={10}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              multiple={false}
                              onChange={onFileSelect}
                              accept="image/x-png,image/jpeg"
                              style={{
                                backgroundColor: '#000',
                                height: 35,
                                width: 40,
                                position: 'absolute',
                                opacity: 0,
                                cursor: 'pointer',
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: '#555',
                                cursor: 'pointer',
                              }}
                            ></i>
                            Please upload image in the dimension of 1080x1080
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        marginLeft: '30px',
                        fontWeight: 'bold',
                        width: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <p style={{ marginRight: '20px' }}>Move to prefrences:</p>
                      <select
                        className="ml-5 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                        style={{
                          width: '20%',
                          border: '1px solid #acb9ce',
                          borderRadius: 10,
                        }}
                        onChange={(e) => {
                          setMoveToPrefrences(e.target.value);
                        }}
                      >
                        <option
                          selected={moveToPrefrences ? 'selected' : ''}
                          value={true}
                        >
                          Yes
                        </option>
                        <option
                          selected={!moveToPrefrences ? 'selected' : ''}
                          value={false}
                        >
                          No
                        </option>
                      </select>
                    </div>
                    <div
                      style={{
                        marginLeft: '30px',
                        fontWeight: 'bold',
                        display: 'flex',
                        width: '50%',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <p style={{ marginRight: '20px' }}>Show Banner:</p>
                      <select
                        className="ml-5 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                        style={{
                          width: '20%',
                          border: '1px solid #acb9ce',
                          borderRadius: 10,
                        }}
                        onChange={(e) => {
                          setShowBanner(e.target.value);
                        }}
                      >
                        <option
                          selected={showBanner ? 'selected' : ''}
                          value={true}
                        >
                          Yes
                        </option>
                        <option
                          selected={!showBanner ? 'selected' : ''}
                          value={false}
                        >
                          No
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
