import React, { useEffect, useState } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import CropperModal from "../../../components/CropperModal";
import { ProgressBar } from "react-bootstrap";
import UnsavedChangesWarning from "../../../components/AlertModal/UnsavedChangesWarning";
import {
  uploadVideo,
  Upload_S3_Videos,
} from "../../../handlers/videos.handlers";
import ReactPlayer from "react-player";
import LeaguesDropDown from "../../../components/Dropdowns/LeaguesDropDown";
import SportsDropDown from "../../../components/Dropdowns/SportsDropDown";
import ConfirmationModal from "../../../components/ConfirmationModal";
import PreviewModal from "../../../components/PreviewModal";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";
import { _getDateTime, _dateTimeFormat } from "../../../constants/appDefaults";
import Selects from "react-select";
import SVG from "react-inlinesvg";
import { Get_All_S3_Videos } from "../../../handlers/videos.handlers";
import { toAbsoluteUrl } from "../../../_helpers/AssistsHelpers";
import {
  dispatchS3VideosList,
  dispatchVideosList,
  dispatchVideosListFilter,
} from "../../../redux/action-dispatchers/videos.dispatcher";
import SportsTaggingDropdown from "../../../components/Dropdowns/SportsTaggingDropdown";
import LeagueDropdownTagging from "../../../components/Dropdowns/LeagueDropdownTagging";
import {
  dispatchAllSports,
  dispatchSelectedSportsTagging,
} from "../../../redux/action-dispatchers/sports.dispatchers";
import { GetAllTagLeagues } from "../../../handlers/leagues.handlers";
import { GetMatchesForTagging } from "../../../handlers/matches.handlers";
import {
  dispatchAllMatchesTag,
  dispatchNumberOfMatchesPages,
} from "../../../redux/action-dispatchers/matches.dispatcher";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeagueTagging,
} from "../../../redux/action-dispatchers/leagues.dispatchers";
import { GetTaggedLocation } from "../../../handlers/twitter.handlers";// Get Tagged Trending Locations
{/**Modal Css */ }
import TrendsAllLocations from "../../../components/Cards/TrendsAllLocations";
import Fab from "@mui/material/Fab";

import MatchesDropdownTagging from "../../../components/Dropdowns/MatchesDropdownTagging";
import { logoutUser } from "../../../services/auth";
import { GetAllAuthor } from "../../../handlers/author.handlers";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const UploadVideos = (props) => {
  const [status, setStatus] = useState(true);
  const [showCropper, setShowCropper] = useState(false);
  const [taggedLeague, setTaggedLeague] = useState("");
  const [changeLeague, setChangeLeague] = useState(false);
  const [taggedSport, setTaggedSport] = useState(""); // Sports
  const [changeSport, setChangeSport] = useState(false); // Sports
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [isPlaying, setIsplaying] = useState(false);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [mediaValue, setMediaValue] = useState({});
  const [videoData, setVideoData] = useState();
  const [keyWord, setKeyword] = useState("");
  const [headline, setHeadLine] = useState("");
  const [videoType, setVideoType] = useState("Sports Tak");
  const [description, setDescription] = useState("");
  const [docs, setDocs] = useState([]);
  const [authorId, setAuthorId] = useState("") // for author tagging
  const [tagTrends, setTagTrends] = useState(false); // Tag Trends
  const [open, setOpen] = useState(false); // for Modal Open State
  const [twitterTaggedLocations, setTwitterTaggedLocations] = useState([]) // All trending places tagged from Trends api
  const [seo_slug, setSeoSlug] = useState("");
  const [seo_title, setSeoTitle] = useState("");
  const [seo_description, setSeoDescription] = useState("");
  const [uuid, setUuid] = useState("");
  const [videoPreview, setVideoPreview] = useState("");
  const [videoAdd, setVideoAdd] = useState(false);
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const history = useHistory();
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const [selectVideo, setSelectedVideo] = useState("");
  const [searchVideo, setSearchVideo] = useState("");
  const [matchId, setMatchId] = useState("");
  const [showUploading, setShowUploading] = useState('false')
  //if SEO Slug is Invalid characters
  const [invalidSeoSlug, setInvalidSeoSlug] = useState(false);
  const [sports, setSports] = useState("");
  const [league, setLeague] = useState("");
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: "",
    height: "",
    width: "",
    file: "",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });
  const [progress, setProgress] = useState();
  const [shortTitle, setShortTitle] = useState("");
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState();
  let fileInput = React.useRef();

  // useEffect(() => {
  //   if (showEdit == false) {
  //
  //     setIsplaying(false);
  //   }
  // }, [showEdit, isPlaying]);

  useEffect(() => {
    if (props.selectedLeague) {
      const leagueInfo = props.leagues.find(
        (item) => item.comp_id == props.selectedLeague
      );

      if (leagueInfo) {
        setTaggedLeague(leagueInfo.abbr);
        setUuid(leagueInfo.optaUUID);
      }
    }

    if (props.selectSports) {
      const sportsInfo = props.sports.find(
        (item) => item._id === props.selectSports
      );
      if (sportsInfo) {
        setTaggedSport(sportsInfo.name);
      }
    }
  }, [props.selectSports, props.leagues, props.selectedLeague]);

  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setShowCropper(false);
  };

  const getAuthor = () => {
    GetAllAuthor()
      .then((response) => {
        setDocs(response.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAuthor();
    window.scrollTo(0, 0);
  }, []);

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
            height: this.height,
            width: this.width,
            file: file,
          });
        } else {
          alert("Image width and height must be 1920x1080 px");
          return false;
        }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
    setIsUnsaved();
  };

  const onVideoFileSelect = (event) => {
    const file = event.target.files[0];
    let blobURL = URL.createObjectURL(file);
    setVideoPreview(blobURL);
    setVideoData(file);
    setIsUnsaved();
  };

  const onClose = () => {
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };
  const onSuccess = () => {
    //   var publishedStatus = VideosInfo.status;
    //   publishedStatus === 1 ? (publishedStatus = 0) : (publishedStatus = 1);
    //   ChangeVideoStatus(VideosInfo._id, publishedStatus, {
    //     Authorization: props.Authorization,
    //   }).then((response) => {
    //     if (response.status) {
    //       getVideosInformation();
    //     }
    //   });
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };

  const showModalForPublish = () => {
    var title = "Publish/Unpublish Video";
    var message = "Are you sure to continue?";

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onLeagueSelected = (value) => {
    setChangeLeague(false);
    setLeague(value);
    setIsUnsaved();
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: value,
      selectedLeagueFilterTag: value,
    });
    // getAllTaggedMatches();
  };
  const getAllTaggedLeague = () => {
    GetAllTagLeagues(
      props.selectSports,
      props.leagueSearch == "" ? "search" : props.leagueSearch
    )
      .then((response) => {
        if (
          response.statusCode == 401 &&
          response.message.message == "Invalid cms-access-token"
        ) {
          alert(`Access token Expired!!! Please Login Again!!!`);
          // logoutUser();
          history.push("/");
        } else if (response.status) {
          dispatchAllLeaguesTag(response.data);
        } else {
          dispatchAllLeaguesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllLeaguesTag([]);
      });
  };
  useEffect(() => {
    dispatchAllMatchesTag([]);
    dispatchAllSports([]);
    dispatchSelectedSportsTagging("All");
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getAllTaggedLeague();
  }, [props.leagueSearch, props.selectSports]);
  // matches
  const onMatchesChange = (value) => {
    setMatchId(value);
  };
  const getAllTaggedMatches = () => {
    GetMatchesForTagging(
      props.selectedLeagueTag,
      props.selectSports,
      props.matchesSearch == "" ? "search" : props.matchesSearch,
      props.matchesPage,
      20
    )
      .then((response) => {
        if (response.status) {
          dispatchNumberOfMatchesPages(response.data.totalPages);
          dispatchAllMatchesTag(response.data.docs);
        } else {
          dispatchAllMatchesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllMatchesTag([]);
      });
  };
  useEffect(() => {
    getAllTaggedMatches();
  }, [props.selectedLeagueTag, props.matchesPage, props.matchesSearch]);
  // matches end
  // Sport Start
  const onSportSelected = (value) => {
    setChangeSport(false);
    setSports(value);
    setIsUnsaved();
    dispatchSelectedSportsTagging({
      selectSports: value,
      selectedSportsTagging: value,
    });
    dispatchAllLeaguesTag([]);
    dispatchSelectedLeagueTagging({ selectedLeagueTag: "" });
    // getAllTaggedLeague();
  };

  // Sport End

  const onPreviewClose = () =>
    setPreviewModal({ imageData: "", file: "", show: false });
  const onPreviewSuccess = () => {
    setPreviewModal({
      imageData: previewModal.imageData,
      show: false,
      file: previewModal.file,
    });
  };

  const uploadVideoHandler = (event) => {
    event.preventDefault();
    if (sports == "") {
      setConfirmationModal({
        message: "Please select sports first",
        show: true,
        title: "",
      });
      return;
    }
    if (league == "") {
      setConfirmationModal({
        message: "Please select league",
        show: true,
        title: "",
      });
      return;
    }
    if (mediaValue == "" || selectVideo=="") {
      setConfirmationModal({
        message: "Please select your video file",
        show: true,
        title: "",
      });
      return;
    }
    if (previewModal.file == "" || previewModal.imageData == "") {
      setConfirmationModal({
        message: "Please select your thumbnail",
        show: true,
        title: "",
      });
      return;
    }
    // else if (taggedLeague.length == 0) {
    //   setConfirmationModal({
    //     message: 'League is not tagged, please tag league',
    //     show: true,
    //     title: '',
    //   });
    // }
    if (headline?.length == 0) {
      setConfirmationModal({
        message: "Headline can not be empty",
        show: true,
        title: "",
      });
      setProgress();
      return;
    }  if ( shortTitle!="" && shortTitle?.length < 100) {
      setConfirmationModal({
        message: "Short Title can not be less than 100",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (shortTitle != "" && shortTitle?.length > 160) {
      setConfirmationModal({
        message: "Short Title can not be greater than 160",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (seo_title?.length == 0) {
      setConfirmationModal({
        message: "SEO title can not be empty",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (seo_title?.length < 50) {
      setConfirmationModal({
        message: "Please update your SEO title to minimum 50 Characters.",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (seo_title?.length > 140) {
      setConfirmationModal({
        message: "Please update your SEO title to maximum 140 Characters.",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (seo_slug?.length == 0) {
      setConfirmationModal({
        message: "SEO Slug can not be empty",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (!(/^[A-Za-z0-9-\s]*$/.test(seo_slug)) || seo_slug?.charAt(0) == '-') {
      setConfirmationModal({
        message: "Slug is Invalid, It must contain only characters between a-z, A-Z, 0-9 or -.",
        show: true,
        title: "",
      });
      setInvalidSeoSlug(true);
      setProgress();
      return;
    } if (seo_description?.length == 0) {
      setConfirmationModal({
        message: "SEO Description can not be empty",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (seo_description?.length < 180) {
      setConfirmationModal({
        message: "Please update your Meta Description to minimum 180 Characters.",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (seo_description?.length > 220) {
      setConfirmationModal({
        message: "Please update your Meta Description to maximum 220 Characters.",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (videoType == 0) {
      setConfirmationModal({
        message: "Video type can not be empty",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (description?.length == 0) {
      setConfirmationModal({
        message: "Video description can not be empty",
        show: true,
        title: "",
      });
      setProgress();
      return;
    }
    if (keyWord?.length == 0) {
      setConfirmationModal({
        message: "Key words can not be empty",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } 
    
    if (taggedSport?.length == 0) {
      setConfirmationModal({
        message: "Sports is not tagged, please tag sports",
        show: true,
        title: "",
      });
      setProgress();
      return;
    } if (authorId == "") {
      setConfirmationModal({
        message: "Author is not Tagged, Please add the Author",
        show: true,
        title: "",
      });
      setProgress();
      return
    } else {
      setShowUploading('true')
      const data = {
        thumbnail: previewModal?.file,
        slug: keyWord,
        // video: mediaValue,
        description: description,
        title: headline,
        shortTitle:shortTitle,
        copyright: videoType,
        league: props.selectedLeagueTag,
        sportsId: props.selectSports,
        thumbnailHeight: 1080,
        thumbnailWidth: 1092,
        status: status == true ? 1 : 0,
        uuid: uuid,
        transcodedVideoId: selectVideo.value,
        seo_slug: seo_slug.trim(),
        seo_title: seo_title,
        seo_description: seo_description,
        match_id: matchId,
        authorId: authorId, // Author Name
        isTrending: tagTrends, // For Tagging to Trends in Twitter
        selfSeoSlugCreated: true,
        translatedTitleExists: true
      };
      const formData = new FormData();
      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });

      Upload_S3_Videos(formData, {
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
        .then((response) => {
          if (response.status) {
            setVideoAdd(true);
            setPreviewModal({
              imageData: "",
              show: false,
              height: "",
              width: "",
              file: "",
            });
            setConfirmationModal({
              message: "Video uploaded successfully",
              show: true,
              title: "Success!",
            });
            setShowUploading('false')
            history.push(`/videos`);
          } else {
            setConfirmationModal({
              message: "Failed, please try again!",
              show: true,
              title: "Failed!",
            });
            setProgress();
            setShowUploading('false')
          }
        })
        .catch((error) => alert("catch error: ", error));
    }
  };
  const getS3Videos = () => {
    const body = {
      page: 1,
      limit: 10,
      query: searchVideo,
    };
    Get_All_S3_Videos(body)
      .then((res) => {
        dispatchS3VideosList(res.data);
      })
      .catch((err) => err);
  };
  useEffect(() => {
    getS3Videos();
  }, []);
  const videosList = state.s3VideosReducer.docs;

  const videos = videosList?.map((video) => {
    return {
      value: video._id,
      label: video.original_file_name,
      url: "https://" + video.cdn_hostname + video.url,
    };
  });

  const handleSelect = (value) => {
    setSelectedVideo(value);
    setMediaValue(value);
  };

  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };

  // seo changes
  const changeTitle = () => {
    let new_seo_title = seo_title;

    setSeoTitle(new_seo_title.toLowerCase());
  };
  const changeSlug = () => {
    let new_seo_slug = seo_title;
    new_seo_slug = new_seo_slug.replaceAll(" ", "-");
    setSeoSlug(new_seo_slug.toLowerCase());
  };

  // Sending is Trending to Form Data
  const changeTrending = () => {
    // let trendingKeyword = formData?.isTrending;
    setTagTrends(tagTrends);
  };
  const onAuthorSelected = (e) => {
    setAuthorId(e.target.value)
  };

  {/**Modal open and close */ }
  const handleOpen = () => {
    setOpen(true);
  };
  // Tagged Locations from twitter Trending part
  useEffect(() => {
    const body = 'section=twitter'
    if (open) {
      GetTaggedLocation(body)
        .then((response) => {
          setTwitterTaggedLocations(response?.data)
        })
        .catch((error) => error)
    }
  }, [open])

  const changeDescription = () => {
    let metaDescription = description;

    metaDescription =
      metaDescription.substring(0, metaDescription.indexOf(".") + 1) ||
      metaDescription.substring(0, metaDescription.indexOf("।") + 1);

    setSeoDescription(metaDescription);
  };

  useEffect(() => {
    changeTitle();
  }, [headline]);

  useEffect(() => {
    changeSlug();
  }, [seo_title]);

  // Changing form data depending upon 
  useEffect(() => {
    // console.log('asd', tagTrends)
    changeTrending();
  }, [tagTrends])
  // useEffect(()=>{
  //   getAuthor();
  // }, [authorId])

  // useEffect(() => {
  //   changeDescription();
  // }, [description]);
  // seo change end
  return (
    <>
      <Sidebar />
      <div className="edit__popup"
        style={{ display: open ? 'none' : 'block' }}>
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? "edit__backdrop active" : "edit__backdrop"}
        ></div>

        <div
          className={showEdit ? "edit__popup-body active" : "edit__popup-body"}
          style={{ width: "55%" }}
        >
          {" "}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);
                setIsplaying(false);
                showEditHandler(false);

                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={showEdit ? true : false}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div className="relative md:ml-64" style={{ padding: 50, display: open ? 'none' : 'block' }}>
        <div className="flex flex-wrap">
          <form onSubmit={uploadVideoHandler}>
            {/* Upload video */}
            <div>
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "1.3rem",
                  marginBottom: "30px",
                }}
              >
                All fields are mandatory:
              </h1>
              <hr className="my-0 md:min-w-full" />
              <h1
                style={{
                  fontWeight: 800,
                  color: "tomato",
                }}
              >
                Please Select Sports First:
              </h1>
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <SportsTaggingDropdown onChange={onSportSelected} />
              </div>
              {props.selectSports !== undefined && (
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <LeagueDropdownTagging onChange={onLeagueSelected} />
                </div>
              )}
              {
                <>
                  {props.selectSports !== "undefined" &&
                    props.selectedLeagueTag !== "" &&
                    props.matchesTag.length !== 0 && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <MatchesDropdownTagging onChange={onMatchesChange} />
                      </div>
                    )}
                </>
              }
              <hr className="my-0 md:min-w-full" />
              {/* <div> */}
              <div className="flex mt-4">
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "8px",
                    fontWeight: 600,
                  }}
                >
                  Search Videos:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="searchVideoVal"
                  placeholder="Search Videos"
                  style={styles.searchBox}
                  onChange={(e) => {
                    setSearchVideo(e.target.value);
                  }}
                />

                <button
                  type="button"
                  onClick={() => {
                    getS3Videos();
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Search
                </button>
              </div>

              {/* </div> */}
              {/* Add Video */}

              <>
                <div className="col-lg-8" style={{ marginTop: "25px" }}>
                  <label
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Select Video *
                  </label>
                  <Selects
                    name="videoId"
                    placeholder="Select Video"
                    options={videos}
                    style={styles.inputBox}
                    onChange={(values) => {
                      handleSelect(values);
                    }}
                    value={
                      videos
                        ? videos.find(
                          (option) => option.value === selectVideo.value
                        )
                        : ""
                    }
                  // loadOptions={fetchVideosBySearch.bind(this)}
                  ></Selects>
                  {selectVideo && (
                    <div
                      style={{
                        display: "flex",

                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      Click the icon for preview
                      <i
                        onClick={() => onClickOpenMedia(selectVideo.url)}
                        className="fas fa-video "
                        style={{
                          margin: 8,
                          color: "#555",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                        }}
                      ></i>
                    </div>
                  )}
                </div>

                <div
                  className="col-lg-1"
                  style={{
                    margin: "auto",
                    marginTop: "26px",
                  }}
                >
                  {/* {values.videoId && ( */}
                  <a
                    title="Media File"
                    className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                    onClick={() => {
                      // handleMediaPreview(mediaValue?.url);
                      //
                      //  openMediaContentDialog(row._id, videoField);
                    }}
                  ></a>
                  {/* <Button
                  //     onClick={() => {
                  //       handleMediaPreview(mediaValue?.url);
                  //       
                  //     }}
                  //     width="100px"
                  //     height="100px"
                  //   >
                  //     Video
                  //   </Button> */}
                  {/* // )} */}
                </div>
              </>
              {/* )} */}
            </div>
            {/* Upload video end */}
            <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
              {/* <div>Upload video file*</div>
              {videoPreview && (
                <div>
                  <video
                    src={videoPreview}
                    height="500"
                    autoPlay={true}
                    muted
                    controls={true}
                  />
                </div>
              )}
              <input
                ref={fileInput}
                type="file"
                name="file"
                multiple={false}
                onChange={onVideoFileSelect}
                accept=" video/*"
                style={{
                  backgroundColor: '#000',
                  height: 35,
                  width: 40,
                  position: 'absolute',
                  opacity: 0,
                  cursor: 'pointer',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <i
                  className="fas fa-video fa-2x"
                  style={{
                    margin: 8,
                    color: '#555',
                    cursor: 'pointer',
                  }}
                ></i>
                <div
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  {videoData && videoData.name}
                </div>
              </div> */}

              {/* Video Button End */}

              {confirmationModal.show && (
                <ConfirmationModal
                  message={confirmationModal.message}
                  title={confirmationModal.title}
                  onClose={!videoAdd && onClose}
                  onSuccess={!videoAdd && onSuccess}
                />
              )}
              {showCropper && (
                <CropperModal
                  image={selectedImage}
                  onClose={onCropperClose}
                  closeCropperAndSetData={closeCropperAndSetData}
                />
              )}
              {previewModal.show && (
                <PreviewModal
                  imageData={previewModal.imageData}
                  title="Upload video image"
                  message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                  onSuccess={onPreviewSuccess}
                  onClose={onPreviewClose}
                />
              )}
              {
                <div>
                  <div>
                    <div
                      style={{
                        // display: 'inline-block',
                        verticalAlign: "top",
                      }}
                    >
                      {previewModal.imageData && (
                        <img
                          src={previewModal.imageData}
                          style={{
                            width: 210,
                            height: 115,
                            border: "2px solid gray",
                          }} // yha pehla Sir width thi vo bhi 500px for knowledge
                        />
                      )}
                      {isImageUploading ? (
                        <div
                          style={{
                            textAlign: "center",
                            padding: 10,
                            fontWeight: 600,
                          }}
                        >
                          <div>Uploading Image Please Wait...</div>
                          <div>
                            <PulseLoader
                              color="#23A5E0"
                              loading={true}
                              css={override}
                              size={10}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Please upload image in the dimension of 1920x1080*
                          </div>
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              multiple={false}
                              onChange={onFileSelect}
                              accept="image/x-png,image/jpeg"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                        padding: "0px 0",
                      }}
                    >
                      <div>
                        {/* <div>Headline</div> */}
                        <div style={{ marginTop: "20px", fontWeight: 600 }}>
                          {/* {VideosInfo.title} */}
                          <label>Headline*</label>
                          <br></br>
                          <input
                            type="text"
                            placeholder="Headline *"
                            style={styles.inputBox}
                            onChange={(e) => {
                              setHeadLine(e.target.value);
                              setIsUnsaved();
                            }}
                          />
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${headline?.length}`}
                          </span>
                        </div>
                      </div>
                      <div>
                        {/* <div>Headline</div> */}
                        <div style={{ marginTop: "20px", fontWeight: 600 }}>
                          {/* {VideosInfo.title} */}
                          <label>Short Title</label>
                          <br></br>
                          <input
                            type="text"
                            placeholder="Short Title "
                            style={styles.inputBox}
                            onChange={(e) => {
                              setShortTitle(e.target.value);
                              setIsUnsaved();
                            }}
                          />
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${shortTitle?.length}`}
                          </span>
                        </div>
                      </div>
                      
                      <div style={{ marginTop: "20px", fontWeight: 600 }}>
                        <label>SEO Title*</label>
                        <br></br>
                        <input
                          type="text"
                          placeholder="SEO Title.. "
                          style={styles.inputBox}
                          value={seo_title}
                          onChange={(e) => {
                            setSeoTitle(e.target.value);
                            setIsUnsaved();
                          }}
                        />
                        <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${seo_title?.length}`}
                </span>
                        <br></br>
                      </div>
                      <div style={{ marginTop: "20px", fontWeight: 600, color: invalidSeoSlug ? "red" : "" }}>
                        <label>SEO Slug*</label>
                        <br></br>
                        <input
                          type="text"
                          placeholder="SEO Slug.. "
                          style={{
                            width: "500px",
                            height: "30px",
                            marginTop: 5,
                            borderRadius: "5px", borderColor: invalidSeoSlug ? "red" : "", color: invalidSeoSlug ? "red" : ""
                          }}
                          value={seo_slug}
                          onInput={(e) => setInvalidSeoSlug(false)}
                          onChange={(e) => {
                            setSeoSlug(e.target.value);
                            setIsUnsaved();
                          }}
                        />
                        <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${seo_slug?.length}`}
                </span>
                        {invalidSeoSlug && <span style={{ fontSize: "12px", color: "#ff0000" }}>{`(*SEO Slug must contain character in between a-z, A-Z, 0-9 or -.)`}</span>}
                        <br></br>
                      </div>

                      <div style={{ marginTop: "20px", fontWeight: 600 }}>
                        <label>Key Words*</label>
                        <br></br>
                        <input
                          type="text"
                          // onPaste={(e) => {
                          //   e.preventDefault();
                          //   alert("Copy Paste is not Allowed!!!!");
                          //   return false;
                          // }}
                          placeholder="Sports, player name, event etc.. "
                          style={styles.inputBox}
                          onChange={(e) => {
                            setKeyword(e.target.value);
                            setIsUnsaved();
                          }}
                        />
                        <br></br>
                      </div>

                      



                      <div style={{ marginTop: "20px", fontWeight: 600 }}>
                        <label>SEO Description*</label>
                        <br></br>
                        <textarea
                          name="Address"
                          rows="3"
                          cols="100"
                          placeholder="SEO description here..."
                          onChange={(e) => {
                            setSeoDescription(e.target.value);
                            setIsUnsaved();
                          }}
                          style={{ borderRadius: "4px" }}
                        ></textarea>
                        
                      </div>
                      <span
                          style={{
                            background: "#50C878",
                            height: "auto",
                            width: "auto",
                            color: "#fff",
                            fontWeight: "600",
                            padding: "5px",
                            borderRadius: "8px",
                            marginBottom: "0.1px",
                          }}
                        >
                          {`Characters: ${seo_description?.length}`}
                        </span>


                        <div style={{ marginTop: "20px", fontWeight: 600 }}>
                        <label>Video Description*</label>
                        <br></br>
                        <textarea
                          name="Address"
                          rows="3"
                          cols="100"
                          placeholder="Video description here..."
                          onChange={(e) => {
                            setDescription(e.target.value);
                            setIsUnsaved();
                          }}
                          style={{ borderRadius: "4px" }}
                        ></textarea>
                        
                      </div>
                      <span
                          style={{
                            background: "#50C878",
                            height: "auto",
                            width: "auto",
                            color: "#fff",
                            fontWeight: "600",
                            padding: "5px",
                            borderRadius: "8px",
                            marginBottom: "0.1px",
                          }}
                        >
                          {`Characters: ${description?.length}`}
                        </span>

                      <div style={{ display: 'flex' }}>
                        <>
                          <div style={{ marginTop: "10px", fontWeight: 600 }}>
                            <div>
                              <label> Videos Type*</label>
                              <br></br>
                              <input
                                type="text"
                                placeholder="Sports Tak "
                                style={styles.inputBox}
                                //  onChange={(e) => setVideoType(e.target.value)}
                                value="Sports Tak"
                              />
                            </div>
                          </div>
                        </>

                        <>
                          {/*Trending*/}
                          <div
                            style={{
                              fontWeight: "500", marginBottom: '5px', marginLeft: '4rem', marginTop: '2.5rem'
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={tagTrends}
                              onChange={
                                () => setTagTrends(!tagTrends)
                              }
                              style={{ marginRight: '10px', borderRadius: '2px', border: '2px solid grey', cursor: 'pointer' }}
                              color="default"
                              inputProps={{
                                "aria-label": "checkbox with default color",
                              }}
                            />
                            Trending
                          </div>
                        </>
                      </div>

                      <br></br>

                      <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginTop: '-12px', marginRight: '40px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            Select Author* :
                          </div>
                          <div>
                            <select
                              style={{
                                // marginLeft: 20,
                                border: "2px solid #acb9ce",
                                borderRadius: 10,
                              }}
                              className="mb-5 mt-3"
                              value={authorId}
                              onChange={onAuthorSelected}
                            >
                              <option value="">Select Author</option>
                              {docs &&
                                docs.map((author) => (
                                  <>
                                    <option
                                      selectedValue={authorId}
                                      value={author._id}
                                    >
                                      {author.name}
                                    </option>
                                  </>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <br></br>

                      {!progress && (
                        <>
                          <div
                            style={{
                              margin: "0px 0px 10px 0px",
                              fontWeight: 600,
                            }}
                          >
                            <div>
                              <label>Status*</label>
                            </div>
                            <select
                              onChange={(e) => setStatus(e.target.value)}
                              class="form-select"
                              aria-label="Status"
                              style={{ borderRadius: "4px" }}
                            >
                              <option value={true} selected>
                                Published
                              </option>
                              <option value={false}>Draft</option>
                            </select>
                          </div>
                          {status != "false" && (
                            <button
                              className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                              type="submit"
                              onClick={() => {
                                setPristine();
                              }}
                            >
                              {"Publish Video"}
                            </button>
                          )}
                          {status == "false" && (
                            <button
                              style={{ backgroundColor: "red" }}
                              className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                              type="submit"
                              onClick={() => {
                                setPristine();
                              }}
                            >
                              {"Draft Video"}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div style={{ paddingTop: 30 }}></div>
                </div>
              }
            </div>
            {showUploading == 'true' && (
              <div
                style={{
                  fontWeight: 600,
                  marginBottom: '2rem'
                }}
              >
                Please wait while the video is getting uploaded ...
              </div>
            )}
          </form>
          {Prompt}
        </div>
      </div>
      {/*Trending Keyword List*/}
      <div style={{ position: 'fixed', bottom: '30px', right: '20px' }}>
        <Fab variant="extended" size="medium" color="primary" aria-label="add"
          onClick={() => setOpen(true)}
          style={{ marginRight: 5 }}>
          Trending Keywords
        </Fab>
      </div>
      <>
        <div className="edit__popup" style={{ display: open ? 'block' : 'none', }}>
          <div
            onClick={() => setOpen(false)}
            className={
              open ? "edit__backdrop active" : "edit__backdrop"
            }
          ></div>

          <div
            className={
              open ? "edit__popup-body active" : "edit__popup-body"
            }
            style={{
              zIndex: 1000, maxWidth: '1000px', width: '1000px', height: '80%',
              position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="edit__popup-heading">Top Trends</div>
              <div className="edit__popup-inner"
                style={{ marginTop: '7px' }}>
                <i
                  onClick={() => setOpen(false)}
                  className="far fa-times-circle edit__popup-close"
                ></i>

              </div>
            </div>
            <TrendsAllLocations twitterTaggedLocations={twitterTaggedLocations} />
          </div>
        </div>
      </>
    </>
  );
};

const styles = {
  inputBox: {
    width: "500px",
    height: "30px",
    marginTop: 5,
    borderRadius: "5px",
    padding: "15px 10px",
  },
  searchBox: {
    width: "140px",
    height: "30px",
    marginTop: 5,
    borderRadius: "5px",
    padding: "15px 10px",
    marginRight: "20px",
  },
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leaguesTag: state.leaguesReducer.leaguesTag,
    matchesTag: state.matchesReducer.matchesTag,
    leagues: state.leaguesReducer.leagues,
    leagueSearch: state.leaguesReducer.leagueSearch,
    matchesSearch: state.matchesReducer.matchesSearch,
    teamsSearch: state.teamsReducer.teamsSearch,
    matchesPage: state.matchesReducer.matchesPage,
    sports: state.sportsReducer.sports,
    selectSports: state.sportsReducer.selectSports,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
    selectedSports: state.sportsReducer.selectedSports,
    selectedLeague: state.leaguesReducer.selectedLeague,
  };
};
export default connect(mapStateToProps)(UploadVideos);
