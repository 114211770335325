import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";
import LoginAppConstants from '../constants/LoginAppConstants';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = {
  'cms-access-token': accessToken,
  source: 'cms',
  language: CURRENT_LANGUAGE,
};

export const GetPlayerList = async (body) => {
    return await AxiosInstance.get(
      `${AppConstants.WorkerConstants.API_ROUTES.PLAYER_PROFILE.GET_PLAYER_LIST}`,
      { params: body },
      { headers }
    )
      .then((response) => response)
      
      .catch((error) => error);
  };

export const GetPlayerInfo = async (body) => {
    return await AxiosInstance.get(
      `${AppConstants.WorkerConstants.API_ROUTES.PLAYER_PROFILE.GET_PLAYER_INFO}/${body}`,
      { headers }
    )
      .then((response) => response)
      
      .catch((error) => error);
  };

  export const updatePlayer = async (formData, headers) => {
    // const formData = new FormData();
  
    // Object.keys(params).forEach((key) => {
    //   formData.append(key, params[key]);
    // });
    return await AxiosInstance.put(
      `${AppConstants.WorkerConstants.API_ROUTES.PLAYER_PROFILE.UPDATE_PLAYER_PROFILE}`,
      formData,
      {
        headers,
      }
    )
      .then((response) => response)
      .catch((error) => error);
  };

  export const UpdatePlayerImages = async (body, headers) => {
    return await AxiosInstance.post(
      AppConstants.WorkerConstants.API_ROUTES.PLAYER_PROFILE.UPDATE_PLAYER_IMAGE,
      body,
      {headers}
    )
      .then((response) => response)
      .catch((error) => error);
  };
