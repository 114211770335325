import React, { useState, useEffect } from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DisplayFilterDropdown from "../../../components/Dropdowns/DisplayFilterDropdown";
import {
  getAddedRequest,
  getRequestList,
  getSearch,
  getSearchRequest,
  cancelRequest,
  approveRequest,
  getActionToggled,
  getAllRoles,
} from "../../../handlers/admin.handlers";
import { _getDateTime, _dateTimeFormat } from "../../../constants/appDefaults";
import { head } from "lodash";
import DisplayFilterRequest from "../../../components/Dropdowns/DisplayFilterRequest";
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const ViewRequestInfo = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reqList, setReqList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesSelect, setRolesSelect] = useState("");
  const [index, setIndex] = useState(0);
  const [filterType, setFilterType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const cancelRequestItem = (emailId) => {
    cancelRequest(emailId)
      .then((res) => res)
      .catch((error) => error);
  };

  const checkStartDate=(time)=>{
    setStartDate(time)
  }
  const checkEndDate=(time)=>{
    setEndDate(time)
  }

  const headers = {
    Authorization: props.Authorization,
  };

  const requestList = () => {
    getRequestList(headers)
      .then((res) => {
        if (res.status == true) {
          setIsLoading(false)
          setReqList(res.data);
        }
      })
      .catch((error) => error);
  };
  const getRoles = () => {
    getAllRoles(headers)
      .then((res) => {
        if (res.status == true) {
          setRoles(res.data);
        }
      })
      .catch((error) => error);
  };

  useEffect(() => {
    setIsLoading(true)
    requestList();
    getRoles();
  }, [props.Authorization]);
  const onRolesSelect = (e) => {
    let idx = e.target.selectedIndex;
    let dataset = e.target.options[idx].value;
    setIndex(idx);
    setRolesSelect(dataset);
  };

  const onCancelRequest = (emailId) => {
    const body = {
      userRole: rolesSelect,
    };
    cancelRequest(emailId, body, headers).then((res) => {
      alert(JSON.stringify(res.message));
      requestList();
    });
  };

  const onAcceptRequest = (emailId) => {
    const body = {
      userRole: rolesSelect,
    };
    approveRequest(emailId, body, headers).then((res) => {
      alert(JSON.stringify(res.message));
      requestList();
    });
  };
  const onFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const onSearch = (event) => {
    setSearchValue(event.target.value);
    getSearchRequest(filterType, event.target.value, headers)
      .then((res) => {
        if (res.status == true) {
          setReqList(res.data.data);
        }
      })
      .catch((error) => error);
  };
  const date = (date) => {
    const DateTime = new Date(date).toLocaleString();
    return DateTime;
  };


  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  


  const res=()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", props.Authorization);
    myHeaders.append("Content-Type", "application/json");
  
    const stringDateStart=convert(startDate.toString());
    const myArr=stringDateStart.split("-");
    const stringDateEnd=convert(endDate.toString());
    const myArrEnd=stringDateEnd.split("-");
    
    var raw = JSON.stringify({
      "fromDate": {
        "fyear": myArr[0],
        "fmonth": myArr[1],
        "fday": myArr[2]
      },
      "toDate": {
        "tyear": myArrEnd[0],
        "tmonth": myArrEnd[1],
        "tday": myArrEnd[2]
      }
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://develop-rest-microservice.thesportstak.com/authentication/api/admin/onlyDateRequestSearch", requestOptions)
      .then(response => response.json())
      .then(docs =>setReqList(docs.result))
      .catch(error => console.log('error', error));
    
    }
  
  return (
    <div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1 py-4">
              <Link
                className=" pb-4"
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  marginBottom: "-30px",
                  width: "30px",
                  fontSize: "15px",
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: "bold",
                  color: "#1e1e1e",
                }}
                to="/"
              >
                <ArrowBackIcon />
                Home
              </Link>

              <h3
                className={
                  "font-semibold text-lg " +
                  (props.color === "light" ? "text-blueGray-700" : "text-white")
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "24px",
                }}
              >
                VIEW REQUEST
              </h3>
            </div>
            <div>
              <span style={{ marginLeft: 20 }}>
                <DisplayFilterRequest
                  selectedValue={filterType}
                  onChange={onFilterChange}
                />
              </span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <input
                  style={{
                    border: "2px solid #acb9ce",
                    borderRadius: 10,
                    marginRight: "3%",
                    padding: "3px",
                    // paddingLeft: '10px',
                  }}
                  type="text"
                  value={searchValue}
                  placeholder="Search "
                  onChange={onSearch}
                />
              </div>
              {/* <DateFilter startDate={startDate} endDate={endDate} /> */}
              <div className="wrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="relative ">
                    <DateTimePicker
                      label="Start Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={startDate}
                      onChange={(e)=>checkStartDate(e)}
                      style={{
                        marginRight: "10px",
                        width: "40%",
                        marginLeft: "0px",
                      }}
                    />
                    <DateTimePicker
                      label="End Date"
                      // emptyLabel="End Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy"
                      value={endDate}
                      onChange={(e)=>checkEndDate(e)}
                      style={{
                        width: "40%",
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              <Button 
                    onClick={res} 
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{paddingLeft:'20px',paddingRight:'20px'}}
                    
                    
                  >
                    FilterDate
                  </Button>
            </div>
           
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="table-fixed">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  #
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  FIRST NAME
                </th>
                {/* <th
               className={
                 'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                 (props.color === 'light'
                   ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                   : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
               }
             >
               Headline
             </th> */}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  LAST NAME
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  EMAIL ID
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  PHONE NUMBER
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  REQUEST CREATION DATE
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  ROLE
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-13 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {/*table body */}
              {!isLoading ? (
                    getRequestList
                  ) : (
                  <SyncLoader
                    color="#23A5E0"
                    loading={isLoading}
                    css={override}
                    size={12}
                  />
                  )}
              {reqList &&
                reqList.map((row, index) => (
                  <tr>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {index + 1}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {row.firstName}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {row.lastName}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {row.emailId}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {row.phoneNumber ? <span>{row.phoneNumber}</span> : "N/A"}
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {date(row.createdOn)}
                    </td>
                    <td>
                      <select
                        onChange={onRolesSelect}
                        style={{ width: "120px" }}
                      >
                        {roles &&
                          roles.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      <Button
                        variant="contained"
                        color="gray"
                        onClick={() => onAcceptRequest(row.emailId)}
                        style={{ marginRight: "10px" }}
                        size="small"
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        color="gray"
                        onClick={() => onCancelRequest(row.emailId)}
                        size="small"
                      >
                        Reject
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
  };
};

export default connect(mapStateToProps)(ViewRequestInfo);
