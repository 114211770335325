import React, { useState, useEffect } from 'react';
import { GetAuthor } from '../../../handlers/author.handlers';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { isBuffer } from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import './AuthorInfo.css';
import { Button } from '@material-ui/core';
const AuthorInfo = () => {
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState([]);
  const history = useHistory();

  useEffect(() => {
    GetAuthor(id).then((response) => {
      if (response.message == 'success') {
        setUserInfo(response.data);
      }
    });
  }, []);

  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {userInfo && (
              <>
                <div
                  className=" pb-4"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '-20px',
                    width: '30px',
                  }}
                  onClick={handleBack}
                >
                  <ArrowBackIcon />
                </div>
                <div className="author-image-container">
                  <img src={userInfo.avatarUrl} className="author-image" />
                </div>
                <div className="author-name-container">
                  <p>{userInfo.name}</p>
                </div>
                <div className="author-profile-container">
                  <p>SportsTak Editor/Author</p>
                  {userInfo.twitterHandle && (
                    <div style={{ display: 'flex' }}>
                      Twitter Handle:
                      <p className="author-twitter-handle">
                        {userInfo.twitterHandle}
                      </p>
                    </div>
                  )}
                </div>
                {/* <div className="author-edit-button-container">
                  <button className="author-edit-button"> Edit Profile</button>
                </div> */}
                {userInfo.bio && userInfo.bio.length > 0 && (
                  <div className="author-bio-container">
                    <p>{userInfo.bio}</p>
                  </div>
                )}
                <div className="author-social-container">
                  <a href={`mailto:${userInfo.email}`} target="_blank">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/email.png`}
                    />
                  </a>
                  {userInfo.social && userInfo.social.instagram && (
                    <a
                      href={userInfo.social && userInfo.social.instagram}
                      target="_blank"
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/instagram.svg`}
                      />
                    </a>
                  )}
                  {userInfo.social && userInfo.social.facebook && (
                    <a
                      href={userInfo.social && userInfo.social.facebook}
                      target="_blank"
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/facebook.svg`}
                      />
                    </a>
                  )}
                  {userInfo.social && userInfo.social.twitter && (
                    <a
                      href={userInfo.social && userInfo.social.twitter}
                      target="_blank"
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/twitter.svg`}
                      />
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorInfo;
