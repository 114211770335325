import { configStore } from '../store';
import { SportsActionTypes } from '../action-types';

export const dispatchAllSports = (payload) => {
  configStore.dispatch({
    payload,
    type: SportsActionTypes.GET_ALL_SPORTS,
  });
};

export const dispatchSelectedSports = (payload) => {
  configStore.dispatch({
    payload,
    type: SportsActionTypes.CHANGE_SELECTED_SPORTS,
  });
};
export const dispatchAllTagSports = (payload) => {
  configStore.dispatch({
    payload,
    type: SportsActionTypes.GET_ALL_SPORTS_TAG,
  });
};
export const dispatchSelectedSportsTagging = (payload) => {
  configStore.dispatch({
    payload,
    type: SportsActionTypes.CHANGE_SELECTED_SPORTS_TAGGING,
  });
};
