import { configStore } from '../store';
import { MatchesActionTypes } from '../action-types';

export const dispatchAllMatchesTag = (payload) => {
  configStore.dispatch({
    payload,
    type: MatchesActionTypes.GET_ALL_MATCHES_TAG,
  });
};
export const dispatchNumberOfMatchesPages = (payload) => {
  configStore.dispatch({
    payload,
    type: MatchesActionTypes.NUMBER_OF_PAGES,
  });
};
export const dispatchSearchMatches = (payload) => {
  configStore.dispatch({
    payload,
    type: MatchesActionTypes.CHANGE_SEARCH_MATCHES,
  });
};
export const dispatchCurrnetMatchesPage = (payload) => {
  configStore.dispatch({
    payload,
    type: MatchesActionTypes.MATCHES_CURRENT_PAGE,
  });
};
export const dispatchSelectedMatchesTagging = (payload) => {
  configStore.dispatch({
    payload,
    type: MatchesActionTypes.CHANGE_SELECTED_MATCHES_TAGGING,
  });
};
