import React from "react";
import Modal from "react-modal";
import "./style.css";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const ConfirmationModal = (props) => {
  return (
    <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
      <h6 className="text-xl font-normal leading-normal mt-0 mb-2 text-lightBlue-800">
        {props.title}
      </h6>
      <p className="text-base font-light leading-relaxed mt-0 mb-4 text-lightBlue-800">
        {props.message}
      </p>
      <div style={{ paddingTop: 30 }}>
        {props.onClose && (
          <button
            className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            onClick={props.onClose}
          >
            Cancel
          </button>
        )}
        {props.onSuccess && (
          <button
            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            onClick={props.onSuccess}
          >
            Ok
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
