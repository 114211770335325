import { NewsHyperlinkActionTypes } from '../action-types';

const initialState = {
  selectedNewsHyperLink: '',
};

const newsHyperLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case NewsHyperlinkActionTypes.CHANGE_SELECTED_NEWS_HYPERLINK:
      return Object.assign({}, state, {
        ...action.payload,
        selectedNewsHyperLink: action.payload.selectedNewsHyperLink,
        selectedNewsHeadlineLink: action.payload.selectedNewsHeadlineLink,
      });
    default:
      return state;
  }
};

export default newsHyperLinkReducer;
