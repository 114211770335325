import React from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Axios from "axios";
import { API_BASE_PATH } from "../../constants/workerConstants";
import LoginAppConstants from "../../constants/LoginAppConstants";
const API_URL = API_BASE_PATH;
const UPLOAD_ENDPOINT = "news/uploadArticleStoryImage";
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const TextEditor = ({ onChange, data }) => {
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
              Axios.post(
                `${API_URL}/${UPLOAD_ENDPOINT}`,
                { file: reader.result },
                {
                  headers: { "cms-access-token": accessToken, source: "cms" },
                }
              )
                // .then((res) => res.json())
                .then((res) => {
                  resolve({
                    default: `${res.data.data}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            };
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      editor={Editor}
      data={data}
      config={{
        extraPlugins: [uploadPlugin],
        height: 500,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
            openInNewTab: {
              mode: "manual",
              label: "Open in a new tab",
              defaultValue: true,
              attributes: {
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
          },
        },
        heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
              { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
              { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
          ]
      }
      }}
      onChange={(event, editor) => {
        // setSummary(editor.getData());
        const wordCountPlugin = editor.plugins.get("WordCount");

        const wordCountWrapper =
          document.getElementById("word-count1");

        wordCountWrapper.appendChild(
          wordCountPlugin.wordCountContainer
        );
        onChange(event, editor)
      }}
      onReady={(editor) => {
        const wordCountPlugin = editor?.plugins?.get("WordCount");

        const wordCountWrapper =
          document.getElementById("word-count1");

        if (wordCountWrapper)
          wordCountWrapper.appendChild(
            wordCountPlugin.wordCountContainer
          );

        // Insert the toolbar before the editable area.

        // this.set( 'viewportTopOffset', 0 );

        // this.editor = editor;
      }}
      // onChange={(event, editor) => onChange(event, editor)}
    />
  );
};

export default React.memo(TextEditor);
