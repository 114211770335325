import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { getPublishedNews, getPublishedShorts, getPublishedVideos, getPublishedWebstories, generateReport } from "../../../handlers/report.handler";
import { _dateTimeFormat } from "../../../constants/appDefaults";
import SportsDropDown from "../../../components/Dropdowns/SportsDropDown";
import LoginAppConstants from "../../../constants/LoginAppConstants";
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment";
import AppConstants from '../../../constants';
import axios from 'axios';

const ReportScreen = () => {
    const accessToken = localStorage.getItem(
        LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
    );
    const headers = { "cms-access-token": accessToken, source: "cms" };
    const [showNews, setShowNews] = useState(false);
    const [showVideos, setShowVideos] = useState(false);
    const [showShorts, setShowShorts] = useState(false);
    const [showWebStories, setShowWebStories] = useState(false);
    const [getsports, setGetSports] = useState("");
    const [showModal, setShowModal] = useState(false);
    const now = new Date();
    const [startDate, setStartDate] = useState(now);
    const [endDate, setEndDate] = useState(now);
    const [modalData, setModalData] = useState({ fun: null, data: 0, type: "" })
    const mHistory = useHistory();

    const getNews = () => {
        if (!showNews) {

            if(endDate < startDate){
                alert("start date greater than end Date");
                return;
            }
            let params = { startDate: moment(startDate).add(330, 'minutes').toISOString(), endDate: moment(endDate).add(330, 'minutes').toISOString() }
            if (getsports != "") {
                params.sports = getsports
            }
            if (getsports == "All") {
                delete params.sports
            }
            getPublishedNews(params, headers).then((res) => {
                try {
                    if (res.status) {
                        setShowNews(true);
                        setShowModal(true);
                        setModalData({ fun: setShowNews, data: res.data, type: "News" });
                    } else {
                        alert("no data found");
                        setShowNews(false);
                    }
                } catch (err) {
                    () => { console.log(err) }
                }

            })
        } else {
            setShowNews(false);
        }
    }
    const getVideos = () => {
        if (!showVideos) {
            if (endDate < startDate) {
                alert("start date greater than end Date");
                return;
            }
            let params = { startDate: moment(startDate).add(330, 'minutes').toISOString(), endDate: moment(endDate).add(330, 'minutes').toISOString() }
            if (getsports != "") {
                params.sports = getsports
            }
            if (getsports == "All") {
                delete params.sports
            }
            getPublishedVideos(params, headers).then((res) => {
                try {
                    if (res.status) {
                        setShowVideos(true);
                        setShowModal(true);
                        setModalData({ fun: setShowVideos, data: res.data, type: "Videos" });
                    } else {
                        alert("no data found");
                        setShowVideos(false);
                    }
                } catch (err) {
                    () => { console.log(err) }
                }

            })
        } else {
            setShowVideos(false);
        }
    }
    const getShorts = () => {
        if (!showShorts) {
            if (endDate < startDate) {
                alert("start date greater than end Date");
                return;
            }
            let params = { startDate: moment(startDate).add(330, 'minutes').toISOString(), endDate: moment(endDate).add(330, 'minutes').toISOString() }
            if (getsports != "") {
                params.sports = getsports
            }
            if (getsports == "All") {
                delete params.sports
            }
            getPublishedShorts(params, headers).then((res) => {
                try {
                    if (res.status) {
                        setShowShorts(true);
                        setShowModal(true);
                        setModalData({ fun: setShowShorts, data: res.data, type: "Shorts" });
                    } else {
                        alert("no data found");
                        setShowShorts(false);
                    }
                } catch (err) {
                    () => { console.log(err) }
                }

            })
        } else {
            setShowShorts(false);
        }
    }
    const getWebStories = () => {
        if (!showWebStories) {
            if (endDate < startDate) {
                alert("start date greater than end Date");
                return;
            }
            let params = { startDate: moment(startDate).add(330, 'minutes').toISOString(), endDate: moment(endDate).add(330, 'minutes').toISOString() }
            if (getsports != "") {
                params.sports = getsports
            }
            if (getsports == "All") {
                delete params.sports
            }
            getPublishedWebstories(params, headers).then((res) => {
                try {
                    if (res.status) {
                        setShowWebStories(true);
                        setShowModal(true);
                        setModalData({ fun: setShowWebStories, data: res.data, type: "WebStories" });
                    } else {
                        alert("no data found");
                        setShowWebStories(false);
                    }
                } catch (err) {
                    () => { console.log(err) }
                }

            })
        } else {
            setShowWebStories(false);
        }
    }
    const generateCSV = () =>{
        if (endDate < startDate) {
            alert("start date greater than end Date");
            return;
        }
        let params = { startDate: moment(startDate).add(330, 'minutes').toISOString(), endDate: moment(endDate).add(330, 'minutes').toISOString() }
        if (getsports != "") {
            params.sports = getsports
        }
        if (getsports == "All") {
            delete params.sports
        }
        axios({
            url: AppConstants.WorkerConstants.API_BASE_PATH + "generate/admin/generateReport",
            method: 'GET',
            responseType: 'blob', // Important
            headers: {
                'Content-Type': 'application/json',
                'cms-access-token': accessToken,
                source: 'cms',
                // Authorization: props.Authorization,
            },
            params:params
        })
            .then((response) => {
                // Create a new Blob object using the response data of the file
                const file = new Blob(
                    [response.data],
                    { type: response.headers['content-type'] } // Use the content type from the response header
                );
                console.log('file', file)
                // Create a link element, set the href and download attributes for the file
                const fileURL = window.URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = fileURL;
                a.download = 'sportstak-report.csv'; // Set the default file name if not provided
                document.body.appendChild(a);
                a.click();

                // Clean up
                setTimeout(() => {
                    window.URL.revokeObjectURL(fileURL);
                    document.body.removeChild(a);
                }, 0);
            })
            .catch((error) => {
                console.error('Error during file download', error);
            });

    }
    const onSportsTypeChange = (value) => {
        setGetSports(value);
    };
    const onClose = () => {
        setShowModal(false);
        modalData.fun(false);
    };
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            height: "150px",
        },
    };
    return (
        <>
            {/* <div style={{display:"flex",justifyContent:"center",width:"500px"}}> */}

            <Modal isOpen={showModal} style={customStyles}>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', marginBottom: "5px" }}>
                        <span style={{ marginRight: "5x", fontWeight: "500" }}>{`Total Published ${modalData.type} : `}</span>
                        <span style={{ padding: "5px", fontWeight: "500" }}>{modalData.data}</span>
                    </div>
                    <button
                        className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={onClose}
                        style={{ width: "70px" }}
                    >
                        Close
                    </button>
                </div>
            </Modal>
            {/* </div> */}

            <div
                className={
                    'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                    'bg-white'
                }
                style={{ height: '100%' }}
            >
                <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <div style={{display:'flex',justifyContent:"space-between"}}>

                        <h3
                            className={
                                'font-semibold text-lg' +
                                'text-blueGray-700 productivity_title'
                            }
                        >
                            Report Manager
                        </h3>
                        <div style={{display:"flex",alignItems:"center",marginRight:"100px"}}>
                            
                        <Button style={{ background: "#61f684", color: "black", cursor: "pointer" }} onClick={generateCSV}>Generate</Button>
                        </div>
                    </div>


                </div>

                <div style={{ padding: "10px", display: "flex", justifyContent: "space-evenly", alignContent: "center", }} >
                    <div>
                        <label>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            style={{ width: "200px" }}
                            onInvalidChange={() => alert("invalid date")}
                            showTimeInput
                            dateFormat="dd/MM/yyyy HH:mm"
                        />
                    </div>
                    <div >
                        <label>End Date</label>

                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date);
                            }}
                            onInvalidChange={() => alert("invalid date")}
                            showTimeInput
                            dateFormat="dd/MM/yyyy HH:mm"
                            
                        />
                    </div>
                    <SportsDropDown selectedValue={getsports} onChange={onSportsTypeChange} />
                </div>

                <div style={{ padding: "10px", display: "flex", justifyContent: "space-evenly", alignContent: "center" }}>

                    <h1>Published News</h1>

                    <Button style={showNews ? { background: "#f64e60", color: "white", cursor: "pointer" } : { background: "#61f684", color: "black", cursor: "pointer" }} onClick={getNews}>{!showNews ? "Show" : "Close"}</Button>
                </div>

                <div style={{ padding: "10px", display: "flex", justifyContent: "space-evenly", alignContent: "center" }}>

                    <h1>Published Shorts</h1>

                    <Button style={showShorts ? { background: "#f64e60", color: "white", cursor: "pointer" } : { background: "#61f684", color: "black", cursor: "pointer" }} onClick={getShorts}>{!showShorts ? "Show" : "Close"}</Button>
                </div>
                <div style={{ padding: "10px", display: "flex", justifyContent: "space-evenly", alignContent: "center" }}>

                    <h1>Published Stories</h1>

                    <Button style={showWebStories ? { background: "#f64e60", color: "white", cursor: "pointer" } : { background: "#61f684", color: "black", cursor: "pointer" }} onClick={getWebStories}>{!showWebStories ? "Show" : "Close"}</Button>
                </div>
                <div style={{ padding: "10px", display: "flex", justifyContent: "space-evenly", alignContent: "center" }}>

                    <h1>Published Videos</h1>

                    <Button style={showVideos ? { background: "#f64e60", color: "white", cursor: "pointer" } : { background: "#61f684", color: "black", cursor: "pointer" }} onClick={getVideos}>{!showVideos ? "Show" : "Close"}</Button>
                </div>
            </div>
            {/* </div> */}
        </>

    );
};
export default ReportScreen;
