import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import CropperModal from "../../../../components/CropperModal";
import HomeSpecialDropdown from "../../../../components/Dropdowns/HomeSpecialDropdown";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import { Button } from "@material-ui/core";
import TeamSelectDropdown from "../../../../components/Dropdowns/TeamSelectDropdown";
import { GetAllAuthor } from "../../../../handlers/author.handlers";
import AuthorSelectDropdown from "../../../../components/Dropdowns/AuthorSelectDropdown";
import { KabaddiSpecialNews } from "../../../../handlers/Kabaddi.handlers";
import { DatePicker } from "@material-ui/pickers";
import { Tooltip, Overlay } from "react-bootstrap";

import {
  GetNewsInfo,
  mapArticleToSports,
  mapArticleToLeague,
  toggleArticleStatus,
  uploadArticleImage,
  ChangeNewsUpdate,
  tagHome,
  removeTeams,
  updateKeyword,
  mapArticleToAllLeague,
  mapArticleToMatches,
  mapArticleToTeams,
  tagTeamsSpoerWise,
  GetCopyHyperlink,
  tagTwitterTrends, // Trends
  generateClickbaitHeadline // Clickbait Title
} from "../../../../handlers/news.handlers";
import { GetTaggedLocation } from "../../../../handlers/twitter.handlers"; // Trending Twitter Location
import {
  sendArticleNotification,
  sendArticleNotificationSpecificUsers,
} from "../../../../handlers/team.handlers";
import {
  getAllSports,
  getAllSportsTag,
} from "../../../../handlers/sports.handler";
import LeaguesDropDown from "../../../../components/Dropdowns/LeaguesDropDown";
import AllLeagueDropDown from "../../../../components/Dropdowns/AllLeagueDropDown";
import SportsDropdown from "../../../../components/Dropdowns/SportsDropDown";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeague,
  dispatchSelectedLeagueTagging,
} from "../../../../redux/action-dispatchers/leagues.dispatchers";
import PreviewModal from "../../../../components/PreviewModal";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {
  _getDateTime,
  _dateTimeFormat,
  _dateTimeFormatinIST,
} from "../../../../constants/appDefaults";
import {
  dispatchAllSports,
  dispatchAllTagSports,
  dispatchSelectedSports,
  dispatchSelectedSportsTagging,
} from "../../../../redux/action-dispatchers/sports.dispatchers";
import Axios from "axios";
import {
  API_BASE_PATH,
  OLD_BASE_PATH,
} from "../../../../constants/workerConstants";
import AlertModal from "../../../../components/AlertModal";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AddOrRemoveHomeSpecial } from "../../../../handlers/homeSpecial.handlers";
import ReactQuill from "react-quill";
import LoginAppConstants from "../../../../constants/LoginAppConstants";
import axios from "axios";
import SportsTaggingDropdown from "../../../../components/Dropdowns/SportsTaggingDropdown";
import LeagueDropdownTagging from "../../../../components/Dropdowns/LeagueDropdownTagging";
import { GetAllTagLeagues } from "../../../../handlers/leagues.handlers";
import MatchesDropdownTagging from "../../../../components/Dropdowns/MatchesDropdownTagging";
import {
  dispatchAllMatchesTag,
  dispatchNumberOfMatchesPages,
  dispatchSelectedMatchesTagging,
} from "../../../../redux/action-dispatchers/matches.dispatcher";
import { GetPlayersList } from "../../../../handlers/players.handler";
import { GetMatchesForTagging } from "../../../../handlers/matches.handlers";
import { GetTeamsForTagging } from "../../../../handlers/teamsOrPlayer";
import { dispatchSelectedTeamsTagging } from "../../../../redux/action-dispatchers/teamsTagging.dispatcher";
import TeamsDropdownForTagging from "../../../../components/Dropdowns/TeamsDropdownForTagging";
import {
  dispatchTeamsForTagging,
  dispatchTeamsTagged,
} from "../../../../redux/action-dispatchers/teams.dispatcher";
import TextEditor from "../../../../components/TextEditor";
import NewsHyperlinkDropDown from "../../../../components/Dropdowns/NewsHyperlinkDropDown";
{/**Modal Css */ }
import TrendsAllLocations from "../../../../components/Cards/TrendsAllLocations";
import Fab from "@mui/material/Fab";
import PlayerListDropDown from "../../../../components/Dropdowns/PlayersListDropDown";
const API_URL = API_BASE_PATH;
const UPLOAD_ENDPOINT = "news/uploadArticleStoryImage";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);

const NewsInfo = (props) => {
  const [newsInfo, setNewsInfo] = useState(undefined);
  const [teamDocs, setTeamDocs] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [homeSpecialType, setHomeSpecialType] = useState("ALL");
  const [taggedLeague, setTaggedLeague] = useState(false);
  const [taggedMatches, setTaggedMatches] = useState(false);
  const [taggedTeams, setTaggedTeams] = useState([]);
  const [taggedAllLeague, setTaggedAllLeague] = useState(false);
  const [changeLeague, setChangeLeague] = useState(false);
  const [changeMatches, setChangeMatches] = useState(false);
  const [changeTeams, setChangeTeams] = useState(false);
  const [changeAllLeague, setChangeAllLeague] = useState(false);
  const [taggedSports, setTaggedSports] = useState(false);
  const [changeSports, setChangeSports] = useState(false);
  const [inputPlayerName, setInputPlayerName] = useState("");
  const [changeAuthor, setChangeAuthor] = useState(false);
  const [topNewsChange, setTopNewsChange] = useState(false);
  const [homeNewsChange, setHomeNewsChange] = useState(false);
  const [specialHomeNewsChange, setSpecialHomeNewsChange] = useState(false);
  const [topNewsType, setTopNewsType] = useState();
  const [headline, setHeadline] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [seoSlug, setSeoSlug] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [story, setStory] = useState({});
  const [homeNewsType, setHomeNewsType] = useState();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [playersList, setPlayersList] = useState([]);
  const [notifyTeams, setNotifyTeams] = useState([]);
  const [sports_id, setSports_id] = useState("");
  const [segments, setSegments] = useState([]);
  const [homeExpiryDate, setHomeExpiryDate] = useState(null);
  const [dateTime, setDateTime] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [editKeyword, setEditKeyword] = useState(false);
  const [open, setOpen] = useState(false); // for Modal Open State
  const [twitterTaggedLocations, setTwitterTaggedLocations] = useState([]) // All trending places tagged from Trends api
  const [playersData, setPlayersData] = useState([]);
  const [editHeading, setEditHeading] = useState(false);
  const [editStory, setEditStory] = useState(false);
  const [searchHyperLink, setSearchHyperLink] = useState("");
  const [copyText, setCopyText] = useState(false);
  const [textToCopy, setTextToCopy] = useState("");
  const [copyHyperLink, setCopyHyperLink] = useState(false);
  const [hyperLinkChange, setHyperLinkChange] = useState(false);
  const [getHyperLink, setGetHyperLink] = useState("");
  const [editStorySourceCode, setEditStorySourceCode] = useState(false);
  const [editSEOTitle, setEditSEOTitle] = useState(false);
  const [editImageTitle, setEditImageTitle] = useState(false);
  const [editSEOSlug, setEditSEOSlug] = useState(false);
  const [editSEODescription, setEditSEODescription] = useState(false);
  const [homeNewsChecked, setHomeNewsChecked] = useState(false);
  const [tagTrends, setTagTrends] = useState(); // Tag Trends
  const [homeSpecialNewsChecked, setHomeSpecialNewsChecked] = useState(false);
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: "",
  });
  const [showEdit, showEditHandler] = React.useState(false);
  const [authorName, setAuthorName] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [disable, setDisable] = useState(false);
  // const [isSpecificUserDisabled, setIsSpecificUserDisabled] = useState(false);
  const [titleDisabled, setTitleDisabled] = useState(3) // For ChatGpt Attempts
  // const [clickbaitTitle, setClickbaitTitle] = useState('')  // Fot ChatGpt Title
  const [selectedOption, setSelectedOption] = useState("");
  const [url, setURl] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });
  const { id } = useParams();
  const [docs, setDocs] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [titleInput, setTitleInput] = useState("");
  const [summary, setSummary] = useState("");
  //if SEO Slug is Invalid characters
  const [invalidSeoSlug, setInvalidSeoSlug] = useState(false);
  const [taggedPlayer, setTaggedPlayer] = useState();
  const [seoDescriptionLength, setSeoDescriptionLength] = useState(0) // length of meta/seo descriptoin
  const [shortTitle,setShortTitle]=useState("");
  const [editShortTitle,setEditShortTitle]=useState("");
  const [notificationDisable, setNotificationDisable]=useState(false);

  let fileInput = React.useRef();
  const history = useHistory();
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );

  {/**Modal open and close */ }
  const handleOpen = () => {
    setOpen(true);
  };

  // search hyperlink
  const onHyperLinkChange = (value) => {
    setGetHyperLink(value);
    setHyperLinkChange(true);
    setCopyHyperLink(false);
    setCopyText(false);
  };
  const onHyperLinkSearch = (event) => {
    setSearchHyperLink(event.target.value);
  };

  const onCopyHyperLink = () => {
    const body = {
      _id: props.selectedNewsHyperLink,
      type: "news",
    };
    setCopyHyperLink(true);
    setHyperLinkChange(false);
    // GetCopyHyperlink(body)
    Axios.post(
      `${API_BASE_PATH}/content/admin/generateURL`,
      {
        _id: props.selectedNewsHyperLink,
        type: "news",
      },
      {
        headers: { "cms-access-token": accessToken, source: "cms" },
      }
    )
      .then((response) => {
        setTextToCopy(response.data);
      })
      .catch((error) => error);
  };
  // search hyperlink end
  // CKEditor image upload

  // Tagged Locations from twitter Trending part
  useEffect(() => {
    const body = 'section=twitter'
    if (open) {
      GetTaggedLocation(body)
        .then((response) => {
          setTwitterTaggedLocations(response?.data)
        })
        .catch((error) => error)
    }
  }, [open])

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
              Axios.post(
                `${API_URL}/${UPLOAD_ENDPOINT}`,
                { file: reader.result },
                {
                  headers: { "cms-access-token": accessToken, source: "cms" },
                }
              )
                // .then((res) => res.json())
                .then((res) => {
                  resolve({
                    default: `${res.data.data}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            };
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  // ckeditor image upload end

  useEffect(() => {
    // GetAllDefaultLeagues({
    //   Authorization: props.Authorization,
    // }).then((response) => {
    //   if (response.status) {
    //     dispatchAllLeagues(response.data);
    //   }
    // });
    // const getAllTeamData = () => {
    //   A.get(`${OLD_BASE_PATH}teams/getAllTeams`)
    //     .then((res) => {
    //       setSegmentData(...segmentData, res.data.result.data);
    //     })
    //     .catch((error) => error);
    // };

    const getAllTeamData = () => {
      Axios.get(`${OLD_BASE_PATH}teams/getAllTeams`, {
        headers: { "cms-access-token": accessToken, source: "cms" },
      })
        .then((res) => {
          setNotifyTeams(res.data.result.data);
        })
        .catch((error) => error);
    };
    getAllTeamData();
    getAllSportsTag({ Authorization: props.Authorization })
      .then((response) => {
        if (response.status) {
          // console.log('response of sports', response);
          dispatchAllTagSports(response?.data);
          // dispatchSelectedSportsTagging({
          //   selectedSportsTagging: taggedSports,
          // });
        } else {
          dispatchAllTagSports([]);
        }
      })
      .catch((error) => {
        dispatchAllTagSports([]);
      });

    // getAllSports({ Authorization: props.Authorization })
    //   .then((response) => {
    //     if (response.status) {
    //       dispatchAllSports(response.data);
    //     } else {
    //       dispatchAllSports([]);
    //     }
    //   })
    //   .catch((error) => {
    //     dispatchAllSports([]);
    //   });
    getNewsInformation();
  }, [props.sports, taggedSports, taggedLeague, props.leaguesTag]);

  useEffect(() => {
    getAllTaggedMatches();
  }, [props.matchesSearch, taggedLeague, taggedSports, props.matchesPage]);
  const getAllTaggedLeague = () => {
    if (props.selectedSportsTagging !== "") {
      GetAllTagLeagues(
        props.selectedSportsTagging,
        props.leagueSearch == "" ? "search" : props.leagueSearch
      )
        .then((response) => {
          if (response.status) {
            dispatchAllLeaguesTag(response.data);
          } else {
            dispatchAllLeaguesTag([]);
          }
        })
        .catch((error) => {
          dispatchAllLeaguesTag([]);
        });
    }
  };

  //See if Trend is Tagger or Not

  const TagNewsToTrends = (trends) => {
    // setTagTrends(!tagTrends)
    // console.log('asdddddd', newsInfo)
    setTagTrends(trends)
    const payload = {
      "contentId": newsInfo?.contentId,
      "isTrending": trends,
    }
    tagTwitterTrends(payload)
      .then((response) => {
      })
      .catch((error) => error)
  }
  

  useEffect(() => {
    getAllTaggedLeague();
  }, [props.leagueSearch, taggedSports]);
  const getAllTaggedMatches = () => {
    if (props.selectedLeagueTag !== "" && props.selectedSportsTagging !== "") {
      GetMatchesForTagging(
        props.selectedLeagueTag,
        props.selectedSportsTagging,
        props.matchesSearch == "" ? "search" : props.matchesSearch,
        props.matchesPage,
        20
      )
        .then((response) => {
          if (response.status) {
            dispatchNumberOfMatchesPages(response.data.totalPages);
            dispatchAllMatchesTag(response.data.docs);
          } else {
            dispatchAllMatchesTag([]);
          }
        })
        .catch((error) => {
          dispatchAllMatchesTag([]);
        });
    }
  };
  const getAllTaggedTeams = () => {
    if (props.selectedLeagueTag !== "" && props.selectedSportsTagging !== "") {
      GetTeamsForTagging(
        props.selectedLeagueTag,
        props.selectedSportsTagging,
        props.teamsSearch == "" ? "search" : props.teamsSearch
      )
        .then((response) => {
          if (response.status) {
            dispatchTeamsForTagging(response.data);
          } else {
            dispatchTeamsForTagging([]);
          }
        })
        .catch((error) => {
          dispatchTeamsForTagging([]);
        });
    }
  };
  useEffect(() => {
    getAllTaggedTeams();
  }, [props.selectedSportsTagging, props.selectedLeagueTag]);
  useEffect(() => {
    if (newsInfo && newsInfo.league) {
      const leagueInfo = props.leaguesTag?.find(
        (item) => item.comp_id === newsInfo.league
      );

      setTaggedLeague(leagueInfo?.comp_name);
    }
    if (newsInfo?.matchId) {
      const matchesInfo = props.matchesTag?.find(
        (item) => item.match_id == newsInfo.matchId
      );

      if (matchesInfo) {
        setTaggedMatches(matchesInfo?.title);
      }
    }
    if (newsInfo && newsInfo.AllLeague) {
      setTaggedAllLeague(newsInfo.AllLeague);
    }
    if (newsInfo && newsInfo.sports && newsInfo.sports._id) {
      const sportsInfo = props.sports?.find(
        (item) => item._id === newsInfo.sports._id
      );

      if (sportsInfo) {
        dispatchSelectedSportsTagging({
          selectSports: sportsInfo?._id ? sportsInfo._id : "All",
          selectedSportsTagging: sportsInfo?._id ? sportsInfo._id : "All",
        });

        setTaggedSports(sportsInfo.name);
        // setSports_id(sportsInfo?._id);
      }
    }
    if (newsInfo && !newsInfo.showArticle) {
      setHomeSpecialType("All");
      const body = {
        mediaType: "article",
        section: "ALL",
        article: newsInfo?._id,
        copyright: newsInfo?.copyrights
      };
      AddOrRemoveHomeSpecial(body, {
        Authorization: props.Authorization,
      })
        .then((response) => { })
        .catch((error) => error);
    }
  }, [
    newsInfo,
    homeSpecialType,
    props.leaguesTag,
    props.sports,
    props.matchesTag,
  ]);
  const getNewsInformation = () => {
    GetNewsInfo(id, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (
        response.data?.taggedHomeSpecial &&
        response.data.taggedHomeSpecial == undefined
      ) {
        setHomeSpecialType("ALL");
      }
      let taggedTeamName = [];
      if (response.status) {
        setTeamDocs(response.data.teams);
        setNewsInfo(response.data);
        setTopNewsType(response.data.section);
        setExpiryDate(response.data.topNewsExpiry);
        setTagTrends(response.data.isTrending)
        setHomeExpiryDate(response.data.homeTagExpiry);
        setHomeNewsChecked(response.data.tagHome);
        setHomeSpecialNewsChecked(response.data.isTaggedSpecial);
        setHeadline(response.data.headline);
        setSports_id(response?.data?.sports?._id)
        setTitleInput(response.data.notification?.title)
        setKeyword(
          response.data.slug
            ? response.data.slug !== ""
              ? response.data.slug
              : response.data.keyword
            : response.data.keyword
        );
        setStory(response.data.story);
        setSummary(response.data.summary);
        setSeoTitle(response.data.seo_title);
        setImageTitle(response.data.imgTitle ? response.data.imgTitle : ""); // It must be changed after recieving
        setSeoSlug(response.data.seo_slug);
        setSeoDescription(response.data.seo_description);
        setSeoDescriptionLength(response.data.seo_description?.length) // Length of seo_description
        setAuthorName(response.data.author && response.data.author.name);
        setHomeSpecialType(response.data.taggedHomeSpecial);
        setTaggedAllLeague(response.data.AllLeague);
        setShortTitle(response.data.shortTitle);
        setTitleInput(response?.data?.openAISuggestions?.clickbait_headline?.text)// GPT title from api initially
        setTitleDisabled(response?.data?.openAISuggestions?.clickbait_headline?.apiAttemptsLeft) // GPT attempts left
        dispatchTeamsTagged(response.data.teams);
        if (response.data.teams) {
          response.data.teams?.map((team) => {
            taggedTeamName.push(team.name);
          });
          setTaggedTeams(taggedTeamName);
        }
        dispatchSelectedLeagueTagging({
          selectedLeagueTag: response.data?.league,
          selectedLeagueFilterTag: response.data?.league,
        });
      }
    });
  };
  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setNewsInfo({
      ...newsInfo,
      imgUrl: imagePath,
    });
    uploadArticleImage(newsInfo._id, imageData, {
      Authorization: props.Authorization,
    })
      .then((response) => { })
      .catch((error) => { });

    setShowCropper(false);
  };

  const onFileSelect = (event) => {
    let file = event.target.files[0];
    event.target.value = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
          });
        } else {
          alert("Image width and height must be 1920x1080 px");
          return false;
        }
      };
      setSelectedImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) { };
  };

  const onClose = () => {
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };
  const onSuccess = () => {
    toggleArticleStatus(newsInfo._id, !newsInfo.showArticle, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        getNewsInformation();
      }
    });
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };

  const showModalForPublish = () => {
    let title = "Publish/Unpublish Article";
    let message = "Are you sure to continue?";

    if (/^[A-Za-z0-9-\s]*$/.test(seoSlug) && seoSlug.charAt(0) != '-' || newsInfo?.showArticle) {
      setConfirmationModal({
        message: message,
        show: true,
        title: title,
      });
    }
    else {
      setInvalidSeoSlug(true);
      alert("SEO Slug is INVALID. It must conatin only letters between a-z, A-Z, 0-9 or -.");
    }
  };
  // top news start
  const changeTopNewsType = async (event) => {
    if (event.target.checked && newsInfo.showArticle) {
      setDateTime(true);
      updateTopNewsTime("TOP_NEWS");
      setTopNewsType("TOP_NEWS");
    } else {
      updateTopNewsTime("ALL");
      setTopNewsType("ALL");
    }
  };

  const updateTopNewsTime = (value) => {
    if (newsInfo.showArticle) {
      const data = {
        _id: newsInfo._id,
        section: value,
        // topNewsExpiry: time,
      };
      ChangeNewsUpdate(data, {
        Authorization: props.Authorization,
      })
        .then((response) => {
          // debugger
          if (response.status) {
            if (response.data.statusCode && response.data.statusCode == 522) {
              alert(response.message);
              setTopNewsType("ALL");
            } else {
              setTopNewsChange(false);
              setDateTime(false);
              setAlertModal(true);
              getNewsInformation();
            }
          }
          // else {
          //
          // }
        })
        .catch((error) => error);
    } else {
      alert(
        "This article is not published. Please publish the article first, Thank You."
      );
    }
  };
  // top news end

  // home news start
  const selectHomeNews = (event) => {
    const data = {
      _id: newsInfo._id,
      tagHome: event.target.checked,
    };
    //event.target.checked === 'home'
    // if (event.target.checked && !newsInfo.showArticle) {
    //   setDateTime(true);
    //   setHomeNewsType('home');
    // } else {
    //   // data.tagHome=false;
    //   // const body = { _id: id, tagHome: false };
    //   // tagHome(body);

    // }
    if (event.target.checked && newsInfo.showArticle) {
      setDateTime(true);
      setHomeNewsType("home");
      setHomeNewsChecked(true);
    } else {
      setHomeNewsChecked(false);
    }

    updateHomeNewsTime(data);
    // setHomeNewsChecked(event.target.checked);
  };
  const updateHomeNewsTime = (data) => {
    if (newsInfo.showArticle) {
      // const data = {
      //   _id: newsInfo._id,
      //   tagHome: homeNewsChecked,
      //   homeTagExpiry: time,
      // };
      tagHome(data)
        .then((response) => {
          if (response.status) {
            if (response.data.statusCode && response.data.statusCode == 522) {
              // homeNewsChecked

              alert(response.message);
              setHomeNewsChecked(false);
            } else {
              setDateTime(false);
              setHomeNewsChange(false);

              setAlertModal(true);
              getNewsInformation();
            }
          }
        })
        .catch((error) => { });
    } else {
      // setHomeNewsChecked(false);
      alert(
        "This article is not published. Please publish the article first, Thank You."
      );
    }
  };
  // home news end
  // home special start

  const selectHomeSpecialNews = (event) => {
    const body = {
      mediaType: "article",
      article: newsInfo._id,
      // ranking: '1',
      isTagg: !homeSpecialNewsChecked,
    };
    //event.target.checked === 'home'
    // if (event.target.checked && !newsInfo.showArticle) {
    //   setDateTime(true);
    //   setHomeNewsType('home');
    // } else {
    //   // data.tagHome=false;
    //   // const body = { _id: id, tagHome: false };
    //   // tagHome(body);

    // }
    if (event.target.checked && newsInfo.isTaggedSpecial) {
      setDateTime(true);

      setHomeSpecialNewsChecked(true);
    } else {
      setHomeSpecialNewsChecked(false);
    }

    updateSpecialHomeNewsTime(body);
    // setHomeSpecialNewsChecked(event.target.checked);
  };
  const updateSpecialHomeNewsTime = (body) => {
    if (newsInfo.showArticle) {
      // const data = {
      //   _id: newsInfo._id,
      //   tagHome: homeNewsChecked,
      //   homeTagExpiry: time,
      // };
      KabaddiSpecialNews(body)
        .then((response) => {
          if (response.status) {
            // if(response.data.statusCode && response.data.statusCode==522)
            // {
            // setSpecialHomeNewsChange(false);
            //   alert(response.message)
            // }
            // else{
            setDateTime(false);
            setSpecialHomeNewsChange(false);
            setAlertModal(true);
            getNewsInformation();
            // }
          }
        })
        .catch((error) => { });
    } else {
      // setHomeSpecialNewsChecked(false);
      alert(
        "This article is not published. Please publish the article first, Thank You."
      );
    }
  };
  // home special end

  //Remove tagged teams

  const removeTaggedTeams = (team_id, news_id) => {
    const body = { teamIds: [team_id], _id: news_id };
    removeTeams(body)
      .then((response) => {
        if (response.status) {
          // window.location.reload();
          getNewsInformation();
        }
      })
      .catch((error) => error);
  };
  const sendNotification = () => {
    if (titleInput?.trim() != "") {
      var array = "";
      const lang = localStorage.getItem("Current_Language");

      // segmentData.map((item) => {
      //   let isItemFound = teamDocs.some((val) => val._id === item._id);
      //   if (
      //     isItemFound &&
      //     item.segments.length > 0 &&
      //     item.segments[0].event == 'articles'
      //   ) {
      //     // array.push(item);
      //     array += item.segments[0].segmentId + ', ';
      //   }
      // });

      // setTeamName(array);

      const body = {
        title: titleInput,
        articleId: newsInfo?._id,
        _id: newsInfo?._id,
        lang: lang === "English" ? "english" : "hindi",
        thumbnail: newsInfo?.imgUrl,
        description: newsInfo?.headline,
        // segments: array.replace(/,\s*$/, ''),
        sportsId: newsInfo?.sports && newsInfo?.sports._id,
        teams: newsInfo?.teams,
        seo_slug: newsInfo?.seo_slug
      };
      // thumbnail is to be sent

      sendArticleNotification(body)
        .then((response) => {
          if (response.status) {
            alert("Notification sent successfully...");
            setDisable(true);
          } else if (
            response.message == "Request failed with status code 403"
          ) {
            alert(
              `You don't have permission to perform this action in this module`
            );
          } else {
            alert("An error occoured...");
          }
        })
        .catch((error) => error);
    } else {
      alert("Enter a valid notification title");
    }
  };

  // specific user notification
  const sendNotificationUserSpecific = (title) => {
    if (title?.trim() != "" ) {
      var array = "";
      setNotificationDisable(true);
      const lang = localStorage.getItem("Current_Language");
      const body = {
        title,
        articleId: newsInfo._id,
        lang: lang === "English" ? "english" : "hindi",
        thumbnail: newsInfo.imgUrl,
        description: newsInfo.headline,
        sportsId: newsInfo.sports?._id,
        comp_id: newsInfo.league,
      };
      if(!title||title.trim()==""){
        alert("Enter another notification title");
        setNotificationDisable(false);
        return;
      }
      sendArticleNotificationSpecificUsers(body)
        .then((response) => {
          if (response.status) {
            alert(response.message);
            // setIsSpecificUserDisabled(true);
          } else {
            alert("An error occurred...");
          }
          setNotificationDisable(false);    
        })
        .catch((error) => error);
    } else {
      alert("Enter Title for notification");
    }
  };

  {/**Chat GPT Clickbait Title */ }
  const clickbaitHeadline = (headline) => {
    const body = {
      "title": headline?.headline,
      "contentId": headline?.contentId,
      "type": "news"
    }
    generateClickbaitHeadline(body)
      .then((res) => {
        setTitleInput(res?.data?.openAISuggestions?.clickbait_headline?.text)// GPT title
        setTitleDisabled(res?.data?.openAISuggestions?.clickbait_headline?.apiAttemptsLeft) // GPT attempts left
      })
      .catch((error) => error)
  }

  // League dropdown selection
  const onLeagueSelected = (value, uuid) => {
    setChangeLeague(false);
    newsInfo &&
      value !== 0 &&
      mapArticleToLeague(newsInfo._id, value, uuid)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
            // window.location.reload();
          }
        })
        .catch((error) => { });
  };
  // All league selection dropdown
  const onAllLeagueSelected = (value, uuid) => {
    setChangeAllLeague(false);
    newsInfo &&
      value !== 0 &&
      mapArticleToLeague(newsInfo._id, value, uuid)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => { });
  };
  // All league selection dropdown end
  // Matches select logic
  const onMatchesSelected = (value) => {
    setChangeMatches(false);
    newsInfo &&
      value !== 0 &&
      mapArticleToMatches(value, newsInfo._id)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => { });
  };
  // Matches select logic end
  // Teams select logic
  const onTeamsSelected = (isSuccess) => {
    if (isSuccess) {
      setChangeTeams(false);
      getNewsInformation();
    }
  };


  const tagTeamToNews = () => {
    if (props.teamsTag.length < 1) {
      alert("No team to tag.");
    } else {
      let teamIdData = [];
      props.teamName?.map((item) => teamIdData.push(`${item._id}`));
      const data = {
        teams: teamIdData,
        _id: newsInfo._id,
        // players: teamIdData,
      };
      tagTeamsSpoerWise(data)
        .then((response) => {
          if (response.status) {
            setAlertModal(true);
            // window.location.reload();
            onTeamsSelected(true);
          }
        })
        .catch((error) => error);
    }
  };
  // teams select logic end
  const onSportsSelected = (value) => {
    setChangeSports(false);
    newsInfo &&
      value !== 0 &&
      mapArticleToSports(newsInfo._id, value, "")
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            // alert(response.message);
            // window.location.reload();
            setTaggedLeague(false);
            setTaggedMatches(false);
          }
        })
        .catch((error) => { });
  };

  const onPreviewClose = () => setPreviewModal({ imageData: "", show: false });
  const onPreviewSuccess = () => {
    setIsImageUploading(true);
    setNewsInfo({
      ...newsInfo,
      imgUrl: previewModal.imageData,
    });
    setPreviewModal({
      imageData: "",
      show: false,
    });
    uploadArticleImage(newsInfo._id, previewModal.imageData, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setIsImageUploading(false);
      })
      .catch((error) => {
        setIsImageUploading(false);
      });
  };
  const handleBack = () => {
    // dispatchSelectedSports({
    //   selectedSports: "All",
    //   selectedSportsFilter: "All",
    // });
    // dispatchSelectedLeague({
    //   selectedLeague: "All",
    //   selectedLeagueFilter: "All",
    // });
    history.goBack();
  };
  //
  const updateHeadline = () => {
    const body = {
      _id: newsInfo._id,
      headline: headline,
    };
    if (body.headline == "") {
      alert("Headline cannot be empty.Please enter Headline.")
      return
    }
    ChangeNewsUpdate(body)
      .then((response) => {
        if (response.status) {
          getNewsInformation();
          setAlertModal(true);
          setEditHeading(false);
        } else {
          alert("Sorry your article is not updated");
        }
      })
      .catch((error) => error);
  };
  const updateShortTitle = () => {
    const body = {
      _id: newsInfo._id,
      shortTitle: shortTitle,
    };
    console.log('shortTitle', shortTitle.length , body.shortTitle.length);
   
  if ((shortTitle?.length > 0 || body.shortTitle?.length > 0) && (body.shortTitle.length < 100 || shortTitle.length < 100)) {
      alert("ShortTitle cannot be less than 100")
      return;
    }
  else if ((shortTitle?.length > 160) || (body.shortTitle?.length > 160)) {
      alert("ShortTitle cannot be greater than 160")
      return;
    }
    else{

      ChangeNewsUpdate(body)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
            setEditShortTitle(false);
          } else {
            alert("Sorry your article is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  const updateKeyword = () => {
    const body = {
      _id: newsInfo._id,
      slug: keyword,
      keyword: keyword,
    };
    console.log('body.keyword', typeof body.keyword);
    if (body.keyword == undefined || body.keyword == "") {
      alert("keywords can not be empty.Please enter keyword");
      return;
    }
    else {

      ChangeNewsUpdate(body)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
            setEditKeyword(false);
          } else {
            alert("Sorry your article is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  const updateImageTitle = () => {
    const body = {
      _id: newsInfo._id,
      imgTitle: imageTitle,
    };
    if (imageTitle == undefined) {
      alert("Image Title can not be empty. Please enter Image Title.");
    } else {
      ChangeNewsUpdate(body)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
            setEditImageTitle(false);
          } else {
            alert("Sorry your article is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  const updateSeoTitle = () => {
    const body = {
      _id: newsInfo._id,
      seo_title: seoTitle,
    };
    if (seoTitle == undefined || body.seo_title == "") {
      alert("SEO Title can not be empty. Please enter SEO Title.");
      return;
    } if (body.seo_title.length < 50) {
      alert("Please update SEO Title to minimum 50 character");
      return;
    } if (body.seo_title.length > 140) {
      alert("Please update SEO Title to maximum 140 character");
      return;
    } else {
      ChangeNewsUpdate(body)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
            setEditSEOTitle(false);
            // setEditImageTitle(false);
          } else {
            alert("Sorry your article is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  const updateSeoSlug = () => {
    const body = {
      _id: newsInfo._id,
      seo_slug: seoSlug.trim(),
      selfSeoSlugCreated: true,
      translatedHeadlineExists: true
    };

    if (seoSlug == undefined || body.seo_slug == "") {
      alert("SEO slug can not be empty. Please enter SEO slug.");
      return;
    } if (body.seo_slug.length < 50) {
      alert("Please update SEO slug to minimum 50 character");
      return;
    } if (body.seo_slug.length > 120) {
      alert("Please update SEO slug to maximum 120 character");
      return;
    } else {
      if (/^[A-Za-z0-9-\s]*$/.test(seoSlug) && seoSlug.charAt(0) != '-') {
        ChangeNewsUpdate(body)
          .then((response) => {
            if (response.status) {
              getNewsInformation();
              setAlertModal(true);
              setEditSEOSlug(false);
            } else {
              alert("Sorry your article is not updated");
            }
          })
          .catch((error) => error);
      }
      else {
        setInvalidSeoSlug(true);
        alert("SEO Slug is INVALID. It must conatin only letters between a-z, A-Z, 0-9 or -.");
      }
    }
  };
  const updateSummary = () => {
    const body = {
      _id: newsInfo._id,
      summary: summary == undefined ? "" : summary,
    };
    if (summary == undefined || body?.summary == "") {
      alert("Bullet Points can not be empty. Please enter the Bullet Points.");
    } else {
      ChangeNewsUpdate(body)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
            // setEditSEODescription(false);
          } else {
            alert("Sorry your article is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  const updateSeoDescription = () => {
    const body = {
      _id: newsInfo._id,
      seo_description: seoDescription == undefined ? "" : seoDescription,
    };
    if (seoDescription == undefined || body.seo_description == "") {
      alert("SEO description can not be empty. Please enter SEO description.");
      return;
    } if (body.seo_description.length < 146) {
      alert("Please update SEO description to minimum 140 character");
      return;
    } if (body.seo_description.length > 206) {
      alert("Please update SEO description to maximum 200 character");
      return;
    } else {
      ChangeNewsUpdate(body)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            setAlertModal(true);
            setEditSEODescription(false);
          } else {
            alert("Sorry your article is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  let startUrl = newsInfo && newsInfo.story.indexOf("<oembed");
  let endUrl = newsInfo && newsInfo.story.indexOf("</oembed>");

  const getUrl = () => {
    let embedUrl = newsInfo && newsInfo.story.slice(startUrl + 13, endUrl - 2);
    setURl(embedUrl);
  };
  useEffect(() => {
    getUrl();
  }, [url]);
  const updateStory = () => {
    // let editStart = story.replaceAll('<figure class="media">', '');
    // let editEnd = editStart.replaceAll('</figure>', '');
    const body = {
      _id: newsInfo._id,

      story: story,
    };
    //
    if (body.story == "") {
      alert("Description cannot be empty. Please enter description");
      return;
    }
    ChangeNewsUpdate(body)
      .then((response) => {
        if (response.status) {
          getNewsInformation();
          setAlertModal(true);
          setEditStory(false);
          setEditStorySourceCode(false);
        } else {
          alert("Sorry your article is not updated");
        }
      })
      .catch((error) => error);
  };

  const getAuthor = () => {
    GetAllAuthor()
      .then((response) => {
        setDocs(response.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAuthor();
  }, []);

  const onAuthorSelected = (event, value) => {
    setChangeAuthor(false);
    const body = { authorId: event.target.value, _id: newsInfo._id };
    newsInfo &&
      value !== 0 &&
      ChangeNewsUpdate(body)
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            // alert(response.message);
          } else {
            alert("Sorry your author is not updated");
          }
        })
        .catch((error) => error);
  };

  const onHomeSpecialTypeChange = (event) => {
    if (newsInfo.showArticle) {
      setHomeSpecialType(event.target.value);
      const body = {
        mediaType: "article",
        section: event.target?.value,
        article: newsInfo?._id,
        copyright: newsInfo?.copyrights
      };
      AddOrRemoveHomeSpecial(body, {
        Authorization: props.Authorization,
      })
        .then((response) => {
          if (response.status === true) {
            alert("Tagged successfully");
          } else {
            alert("Oops something went wrong.. Please try again later.");
            setHomeSpecialType("All");
          }
        })
        .catch((error) => error);
    } else {
      alert("Publish the article first");
    }
  };
  const handleAddOption = (option) => {
    if (!option) return playersList;
    var tempArr = playersList;
    tempArr.push(option);
    var tempOptions = new Set(tempArr);
    setPlayersList([...tempOptions]);
    setInputPlayerName("");
  };
  const getPlayers = (id, arr)=>{
    const body = {
      sportId : id,
      limit : 10 ,
      page : 1,
      searchKey:"",
      selectedPlayers:arr
    }
    GetPlayersList(body)
    .then((response) => {
      setPlayersData(response?.data);
    })
    .catch((error) => error);
}
useEffect(()=>{
  setSports_id(newsInfo?.sports?._id)
  getPlayers(newsInfo?.sports?._id, newsInfo?.players);
},[newsInfo?.sports?._id]);

  function CopyButton({ row }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleClick = async () => {
      if (row?.permalink) {
        if ("clipboard" in navigator) {
          await navigator.clipboard.writeText(row.permalink);
          setShow(true);
          setTimeout(() => setShow(false), 500); // Hide the tooltip after 2 seconds
        }
      }
    };

    return (
      <>
        <Button
          ref={target}
          onClick={handleClick}
          disabled={!row?.permalink}
          title="Click to copy permalink"
          style={{ outline: "none" }}
          className={`btn btn-icon btn-light btn-sm mx-3`}
        >
          <img
            src="/copy-1.png"
            alt="copy to clipboard"
            style={{ width: "25px", height: "25px", marginLeft: "10px" }}
          />
        </Button>

        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Copied!
            </Tooltip>
          )}
        </Overlay>
      </>
    );
  }
console.log(tagTrends)
  return (
    <>
      <Sidebar />

      <div
        className="relative md:ml-64"
        style={{ padding: 50, display: open ? "none" : "block" }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            {showCropper && newsInfo && (
              <CropperModal
                image={selectedImage}
                onClose={onCropperClose}
                closeCropperAndSetData={closeCropperAndSetData}
              />
            )}
            {previewModal.show && (
              <PreviewModal
                imageData={previewModal.imageData}
                title="Upload Article Image"
                message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                onSuccess={onPreviewSuccess}
                onClose={onPreviewClose}
              />
            )}
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your article has been updated successfully !"
                onSuccess={() => setAlertModal(false)}
              />
            )}

            {newsInfo && (
              <div>
                <div>
                  <div
                    className=" pb-4"
                    style={{
                      cursor: "pointer",
                      marginLeft: "-20px",
                      width: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIcon />
                  </div>

                  <div
                    style={{ display: "inline-block", verticalAlign: "top" }}
                  >
                    <img src={newsInfo.imgUrl} style={{ width: 500 }} />
                    {isImageUploading ? (
                      <div style={{ textAlign: "center", padding: 10 }}>
                        <div>Uploading Image Please Wait...</div>
                        <div>
                          <PulseLoader
                            color="#23A5E0"
                            loading={true}
                            css={override}
                            size={10}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>
                          <input
                            ref={fileInput}
                            type="file"
                            multiple={false}
                            onChange={onFileSelect}
                            accept="image/x-png,image/jpeg"
                            style={{
                              backgroundColor: "#111",
                              height: 50,
                              width: 100,
                              position: "absolute",
                              opacity: 0,
                              cursor: "pointer",
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                              margin: 8,
                              color: "#555",
                              cursor: "pointer",
                            }}
                          ></i>
                          Please upload image in the dimension of 1920x1080
                        </div>
                      </>
                    )}
                    <div
                      style={{ fontWeight: "500" }}
                      onClick={() => setEditImageTitle(true)}
                    >
                      Image Title:{" "}
                      <i
                        style={{
                          color: "black",
                          cursor: "pointer",
                          marginLeft: "6px",
                        }}
                        className="fas fa-edit fa-1x"
                      ></i>
                    </div>
                    {editImageTitle ? (
                      <div className="py-2 px-2">
                        {" "}
                        <textarea
                          name="story"
                          rows="2"
                          cols="80"
                          id="textbox"
                          value={imageTitle}
                          onChange={(e) => setImageTitle(e.target.value)}
                          style={{ marginTop: 20, borderRadius: "5px" }}
                        ></textarea>{" "}
                        <div
                          className={"edit__popup-row button-row"}
                          style={{ marginTop: "10px" }}
                        >
                          <div
                            onClick={() => updateImageTitle()}
                            className={"save btn"}
                          >
                            Update
                          </div>
                          <i
                            onClick={() => setEditImageTitle(false)}
                            className="far fa-times-circle secondary btn "
                            style={{
                              padding: "9px 10px",
                              background: "#f34a17",
                            }}
                          >
                            Cancel
                          </i>
                        </div>
                      </div>
                    ) : (
                      <div>{newsInfo.imgTitle}</div>
                    )}

                    <hr className="my-4 md:min-w-full" />
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      padding: 20,
                    }}
                  >
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ marginBottom: 15 }}>
                        {newsInfo.showArticle
                          ? "Article is published. Click below to unpublish this article"
                          : "Article is not published. Click below to publish this article"}
                      </div>

                      <div style={{ display: "flex" }}>
                        <div style={{ paddingBottom: "5px" }}>
                          <button
                            className={`bg-${
                              newsInfo.showArticle ? "red" : "emerald"
                            }-500 text-white active:bg-${
                              newsInfo.showArticle ? "red" : "emerald"
                            }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                            type="button"
                            onClick={showModalForPublish}
                          >
                            {newsInfo.showArticle
                              ? "Unpublish Article"
                              : "Publish Article"}
                          </button>
                        </div>
                        {newsInfo?.showArticle && (
                          <div
                            style={{
                              marginTop: 5,
                              marginBottom: 10,
                              fontWeight: "500",
                              marginRight: "80px",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            {`Permalink: `}
                            <a href={newsInfo?.permalink} target="_blank">
                              <img
                                src="/LinkIcon.png"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "10px",
                                }}
                              />
                            </a>
                              <CopyButton row={newsInfo} />
                          </div>
                        )}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {newsInfo?.showArticle == true &&
                          (disable == false) && (
                            <input
                              type="text"

                              style={{
                                margin: "2px",
                                marginBottom: "10px",
                                marginRight: "5px",
                                height: "40px",
                                width: "600px",
                                borderRadius: "5px",
                              }}
                              placeholder="Please Enter Title here"
                              value={titleInput}
                              onChange={(e) => setTitleInput(e.target.value)}
                            />
                          )}

                        {/**CLickbait Headline */}
                        <div >
                          {/* <button
                            // variant="outlined"
                            // color="secondary"
                            className={`bg-${newsInfo?.showArticle && !disable && "emerald"
                              }-500 text-white active:bg-${newsInfo?.showArticle && !disable && "emerald"
                              }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                            style={{
                              marginLeft: "5px",
                              // height: '30px',
                              // width: 'auto',

                              pointerEvents: !newsInfo.showArticle && "none",
                              color: titleDisabled < 1 && "grey",
                              borderColor: titleDisabled < 1 && "grey",
                              // color:newsInfo.showArticle && 'white'
                            }}
                            onClick={() => clickbaitHeadline(newsInfo)}
                            disabled={titleDisabled < 1}
                          >
                            Generate Headline
                          </button> */}

                          {/* <button
                            // variant="outlined"
                            // color="secondary"
                            className={`bg-${newsInfo?.showArticle && !disable && "emerald"
                              }-500 text-white active:bg-${newsInfo?.showArticle && !disable && "emerald"
                              }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                            style={{
                              marginLeft: "5px",
                              // height: '30px',
                              // width: 'auto',

                              pointerEvents:
                                (!newsInfo.showArticle || disable) && "none",
                              color: (!newsInfo.showArticle || disable) && "grey",
                              borderColor:
                                (!newsInfo.showArticle || disable) && "grey",
                              // color:newsInfo.showArticle && 'white'
                            }}
                            onClick={() => sendNotification()}
                            disabled={disable}
                          >
                            Notify All Users
                          </button> */}
                          <button
                            // variant="outlined"
                            // color="secondary"
                            className={`bg-${newsInfo?.showArticle &&
                              "emerald"
                              }-500 text-white active:bg-${newsInfo?.showArticle &&
                              "emerald"
                              }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                            style={{
                              marginLeft: "5px",
                              // height: '30px',
                              // width: 'auto',

                              pointerEvents:
                                (!newsInfo.showArticle || notificationDisable) &&
                                "none",
                              color:
                                (!newsInfo.showArticle || notificationDisable) &&
                                "lightgray",
                              borderColor:
                                (!newsInfo.showArticle || notificationDisable) &&
                                "grey",
                              // color:newsInfo.showArticle && 'white'
                            }}
                            onClick={() => sendNotificationUserSpecific(titleInput)}
                            disabled= {notificationDisable}
                          >
                            Notify All Users
                          </button>
                        </div>
                      </div>
                    </div>

                    <hr className="my-4 md:min-w-full" />
                    <div className="flex">
                      {/* Sports */}
                      {!changeSports && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                            marginRight: "80px",
                          }}
                        >
                          Tagged Sports:{" "}
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: "10px",
                              marginRight: "5px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setChangeSports(true);
                              // dispatchSelectedSportsTagging({
                              //   selectSports: newsInfo?.sports._id
                              //     ? newsInfo.sports._id
                              //     : 'All',
                              //   selectedSportsTagging: newsInfo.sports?._id
                              //     ? newsInfo.sports._id
                              //     : 'All',
                              // });
                            }}
                          ></i>
                          {taggedSports}
                          {taggedSports && (
                            <i
                              onClick={() => {
                                mapArticleToSports(newsInfo._id, "", "")
                                  .then((response) => {
                                    if (response.status) {
                                      getNewsInformation();
                                      setTaggedSports(false);
                                      setTaggedLeague(false);
                                      setTaggedMatches(false);
                                    } else alert("Opps! Something Went Wrong.");
                                  })
                                  .catch((error) => {});
                              }}
                              className="fas fa-trash "
                              style={{
                                cursor: "pointer",
                                paddingLeft: "15px",
                                color: "red",
                              }}
                            >
                              {" "}
                              (Untag Sports)
                            </i>
                          )}
                        </div>
                      )}

                      {changeSports && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                            marginRight: "80px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // width: '300px',
                          }}
                        >
                          Tagged Sports:{" "}
                          <div
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              marginRight: "30px",
                              marginLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <SportsTaggingDropdown
                              onChange={onSportsSelected}
                            />
                            <i
                              className="fas fa-times fa-1x"
                              style={{
                                marginLeft: "20px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChangeSports(false);
                              }}
                            ></i>
                          </div>
                        </div>
                      )}
                      {/* Sports End */}

                      {/* Author tagging */}

                      {newsInfo.copyrights === "SportsTak" && !changeAuthor && (
                        <div
                          style={{
                            marginLeft: 80,
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                          }}
                        >
                          <div>
                            Tagged Author:{" "}
                            <i
                              className="fas fa-edit fa-1x"
                              style={{
                                marginLeft: "10px",
                                marginRight: "5px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChangeAuthor(true);
                              }}
                            ></i>
                            {authorName}
                          </div>
                        </div>
                      )}

                      {changeAuthor && (
                        <>
                          <div
                            style={{
                              marginTop: 5,
                              marginBottom: 10,
                              fontWeight: "500",
                              marginRight: "80px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // width: '300px',
                            }}
                          >
                            Tagged Author:{" "}
                            <select
                              style={{
                                // marginLeft: 20,
                                border: "2px solid #acb9ce",
                                borderRadius: 10,
                              }}
                              value={authorName}
                              onChange={onAuthorSelected}
                            >
                              <option value="">Select Author</option>
                              {docs?.map((author) => (
                                <>
                                  <option
                                    selectedValue={author && author._id}
                                    value={author._id}
                                  >
                                    {author.name}
                                  </option>
                                </>
                              ))}
                            </select>
                            <i
                              className="fas fa-times fa-1x"
                              style={{
                                margin: 8,
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChangeAuthor(false);
                              }}
                            ></i>
                          </div>
                        </>
                      )}
                    </div>
                    {taggedLeague == "T20 World Cup" && (
                      <>
                        <hr className="my-4 md:min-w-full" />
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                            marginRight: "80px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // width: '300px',
                          }}
                        >
                          Tagged T-20 WorldCup Teams:{" "}
                          <TeamSelectDropdown
                            selected={teamDocs}
                            newsID={newsInfo._id}
                          />
                        </div>
                      </>
                    )}

                    {/* {taggedLeague == 'T20 World Cup' && teamDocs.length > 0 && ( */}
                    {/* <Button
                        variant="outlined"
                        color="secondary"
                        style={{
                          marginLeft: '5px',
                          // height: '30px',
                          // width: 'auto',
                        }}
                        onClick={() => sendNotification()}
                        disabled={disable}
                      >
                        Notify Users
                      </Button> */}
                    {/* )} */}

                    <hr className="my-4 md:min-w-full" />
                    {/* League */}
                    {!changeLeague && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 10,
                          marginRight: "8px",
                          fontWeight: "500",
                        }}
                      >
                        Tagged League:{" "}
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            marginLeft: "10px",
                            marginRight: "5px",
                            color: "black",
                            cursor: "pointer",
                            marginLeft: "6px",
                          }}
                          onClick={() => {
                            setChangeLeague(true);
                            getAllTaggedLeague();
                            dispatchSelectedLeagueTagging({
                              selectedLeagueTag: newsInfo.league,
                              selectedLeagueFilterTag: newsInfo.league,
                            });
                          }}
                        ></i>
                        {taggedLeague}
                        {taggedLeague && (
                          <i
                            onClick={() => {
                              mapArticleToLeague(newsInfo._id, "", "")
                                .then((response) => {
                                  if (response.status) {
                                    getNewsInformation();
                                    setAlertModal(true);
                                    // window.location.reload();
                                    setTaggedLeague(false);
                                  }
                                })
                                .catch((error) => {});
                            }}
                            className="fas fa-trash "
                            style={{
                              cursor: "pointer",
                              paddingLeft: "15px",
                              color: "red",
                            }}
                          >
                            {" "}
                            (Untag League)
                          </i>
                        )}
                      </div>
                    )}

                    {changeLeague && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 10,
                          fontWeight: "500",
                          display: "flex",
                          // justifyContent: 'space-between',
                          alignItems: "center",
                          // width: '300px',
                        }}
                      >
                        Tagged League:{" "}
                        <div
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            marginLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <LeagueDropdownTagging onChange={onLeagueSelected} />

                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              marginLeft: "20px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setChangeLeague(false);
                              dispatchSelectedLeague({
                                selectedLeague: newsInfo.league,
                                selectedLeagueFilter: newsInfo.league,
                              });
                            }}
                          ></i>
                        </div>
                      </div>
                    )}
                    {/* League End*/}
                    <hr className="my-4 md:min-w-full" />
                    {/* All league dropdown */}
                    {<>
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 10,
                          marginRight: "8px",
                          fontWeight: "500",
                          
                          alignItems: "center",
                        }}
                      >
                       
                        <PlayerListDropDown
                          data={playersData}
                          sportsId={newsInfo?.sports?._id}
                          newsId={newsInfo?._id}
                          taggedPlayers={newsInfo?.players}
                        />
                      </div>
                    </>
                    }
                    <hr className="my-4 md:min-w-full" />
                    <div>
                      {!changeAllLeague && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            marginRight: "80px",
                            fontWeight: "500",
                          }}
                        >
                          Tagged All League:{" "}
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: "10px",
                              marginRight: "5px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setChangeAllLeague(true);
                              dispatchSelectedLeague({
                                selectedLeague: newsInfo.league,
                                selectedLeagueFilter: newsInfo.league,
                              });
                            }}
                          ></i>
                          {taggedAllLeague}
                          {taggedAllLeague && (
                            <i
                              onClick={() => {
                                mapArticleToLeague(newsInfo._id, "", "")
                                  .then((response) => {
                                    if (response.status) {
                                      getNewsInformation();
                                      setAlertModal(true);
                                      // window.location.reload();
                                    }
                                  })
                                  .catch((error) => {});
                              }}
                              className="fas fa-trash "
                              style={{
                                cursor: "pointer",
                                paddingLeft: "15px",
                                color: "red",
                              }}
                            >
                              {" "}
                              (Untag League)
                            </i>
                          )}
                        </div>
                      )}

                      {changeAllLeague && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                            display: "flex",
                            // justifyContent: 'space-between',

                            alignItems: "center",
                            // width: '300px',
                          }}
                        >
                          Tagged All League:{" "}
                          <div
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              marginLeft: "20px",
                            }}
                          >
                            {/* Tagged All League */}
                            <AllLeagueDropDown onChange={onAllLeagueSelected} />

                            <i
                              className="fas fa-times fa-1x"
                              style={{
                                margin: 8,
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChangeAllLeague(false);
                                dispatchSelectedLeague({
                                  selectedLeague: newsInfo.league,
                                  selectedLeagueFilter: newsInfo.league,
                                });
                              }}
                            ></i>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* All league dropdown end*/}
                    {taggedLeague !== false &&
                      taggedSports !== false &&
                      props.teamsTag.length !== 0 && (
                        <>
                          <hr className="my-4 md:min-w-full" />
                          {/* Teams Tagging */}
                          {!changeTeams && (
                            <div
                              style={{
                                marginTop: 5,
                                marginBottom: 10,
                                marginRight: "8px",
                                fontWeight: "500",
                              }}
                            >
                              Tagged Teams:{" "}
                              <i
                                className="fas fa-edit fa-1x"
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "5px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setChangeTeams(true);
                                  getAllTaggedTeams();
                                  dispatchSelectedTeamsTagging({
                                    selectedTeamsTag: newsInfo.teams,
                                  });
                                  dispatchTeamsTagged(newsInfo.teams);
                                }}
                              ></i>
                              {/* {taggedTeams} */}
                              {newsInfo?.teams
                                ?.map((items) => {
                                  return items.name;
                                })
                                .join(", ")
                                ? newsInfo.teams
                                    ?.map((items) => {
                                      return items.name;
                                    })
                                    .join(", ")
                                : "N/A"}
                            </div>
                          )}

                          {changeTeams && (
                            <div
                              style={{
                                marginTop: 5,
                                marginBottom: 10,
                                fontWeight: "500",
                                display: "flex",
                                // justifyContent: 'space-between',

                                alignItems: "center",
                                // width: '300px',
                              }}
                            >
                              Tagged Teams:{" "}
                              <div
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                  marginLeft: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TeamsDropdownForTagging
                                  onChange={onTeamsSelected}
                                  newsID={newsInfo._id}
                                  onClick={tagTeamToNews}
                                />

                                <i
                                  className="fas fa-times fa-1x"
                                  style={{
                                    marginLeft: "30px",
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setChangeTeams(false);
                                    dispatchSelectedTeamsTagging({
                                      selectedTeamsTag: newsInfo.teams,
                                    });
                                  }}
                                ></i>
                              </div>
                            </div>
                          )}
                          {/* Teams End*/}
                        </>
                      )}
                    <hr className="my-4 md:min-w-full" />

                    {taggedLeague !== false &&
                      taggedSports !== false &&
                      props.matchesTag.length !== 0 && (
                        <>
                          <div className="flex">
                            {/* Matches */}
                            {!changeMatches && (
                              <div
                                style={{
                                  marginTop: 5,
                                  marginBottom: 10,
                                  marginRight: "80px",
                                  fontWeight: "500",
                                }}
                              >
                                Tagged Matches:{" "}
                                <i
                                  className="fas fa-edit fa-1x"
                                  style={{
                                    marginTop: 5,
                                    marginBottom: 10,
                                    marginRight: "10px",
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setChangeMatches(true);
                                    getAllTaggedMatches();
                                    dispatchSelectedMatchesTagging({
                                      selectedMatchesTag: newsInfo.matchId,
                                      selectedMatchesFilterTag:
                                        newsInfo.matchId,
                                    });
                                  }}
                                ></i>
                                {taggedMatches}
                                {taggedMatches && (
                                  <i
                                    onClick={() => {
                                      mapArticleToMatches("", newsInfo._id)
                                        .then((response) => {
                                          if (response.status) {
                                            setAlertModal(true);
                                            setTaggedMatches(false);
                                            getNewsInformation();
                                          }
                                        })
                                        .catch((error) => {});
                                    }}
                                    className="fas fa-trash "
                                    style={{
                                      cursor: "pointer",
                                      paddingLeft: "15px",
                                      color: "red",
                                    }}
                                  >
                                    {"  "}
                                    (Untag Match)
                                  </i>
                                )}
                              </div>
                            )}

                            {changeMatches && (
                              <div
                                style={{
                                  marginTop: 5,
                                  marginBottom: 10,
                                  fontWeight: "500",
                                  display: "flex",
                                  // justifyContent: 'space-between',

                                  alignItems: "center",
                                  // width: '300px',
                                }}
                              >
                                Tagged Matches:{" "}
                                <div
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    marginRight: "30px",
                                    marginRight: "30px",
                                    marginLeft: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <MatchesDropdownTagging
                                    onChange={onMatchesSelected}
                                  />

                                  <i
                                    className="fas fa-times fa-1x"
                                    style={{
                                      marginLeft: "20px",
                                      marginTop: "-70px",
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setChangeMatches(false);
                                      dispatchSelectedMatchesTagging({
                                        selectedMatchesTag: newsInfo.matchId,
                                        selectedMatchesFilterTag:
                                          newsInfo.matchId,
                                      });
                                    }}
                                  ></i>
                                </div>
                              </div>
                            )}
                            {/* Matches End*/}
                          </div>
                          <hr className="my-4 md:min-w-full" />
                        </>
                      )}

                    <div
                      className="flex justify-between items-center"
                      style={{ width: "50%" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          onClick={() => {
                            setTopNewsChange(true);
                            setDateTime(true);
                          }}
                          style={{ fontWeight: "500" }}
                        >
                          <Checkbox
                            checked={topNewsType === "TOP_NEWS" ? true : false}
                            onChange={changeTopNewsType}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          Top News
                          {/* <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the News)
                        </span> */}
                        </div>
                        {/* {topNewsType == 'TOP_NEWS' && (
                        <div className="edit__popup-row">
                          <span>Top News Expiry:</span>

                          <div className="relative w-full ">
                            {dateTime ? (
                              <DateTimePicker
                                // format="ddd, dd MM hh:mm A"
                                autoOk
                                hideTabs
                                minDateMessage=""
                                minDate={_dateTimeFormat(
                                  new Date(),
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                value={_dateTimeFormat(
                                  new Date().getTime() + 6 * 60 * 60 * 1000,
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                onChange={(e) =>
                                  updateTopNewsTime(topNewsType, e)
                                }
                              />
                            ) : (
                              _dateTimeFormat(expiryDate, 'ddd, DD MMM hh:mm A')
                            )}
                          </div>
                        </div>
                      )} */}
                      </MuiPickersUtilsProvider>
                      {/* <hr className="my-4 md:min-w-full" /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          onClick={() => {
                            setHomeNewsChange(true);
                            setHomeNewsChange(true);
                            setDateTime(true);
                          }}
                          style={{ fontWeight: "500" }}
                        >
                          <Checkbox
                            checked={homeNewsChecked}
                            onChange={selectHomeNews}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          Home
                          {/* <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the News)
                        </span> */}
                        </div>
                        {/* {homeNewsChecked && (
                        <div className="edit__popup-row">
                          <span>Home Expiry Date:</span>

                          <div className="relative w-full ">
                            {dateTime ? (
                              <DateTimePicker
                                autoOk
                                hideTabs
                                minDate={_dateTimeFormat(
                                  new Date(),
                                  'ddd,YYYY DD MMM hh:mm A'
                                )}
                                value={_dateTimeFormat(
                                  new Date().getTime() + 6 * 60 * 60 * 1000,
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                onChange={(e) => updateHomeNewsTime(e)}
                              />
                            ) : (
                              _dateTimeFormat(
                                homeExpiryDate,
                                'ddd, DD MMM hh:mm A'
                              )
                            )}
                          </div>
                        </div>
                      )} */}
                      </MuiPickersUtilsProvider>
                      {/* <div
                        onClick={() => {
                          setSpecialHomeNewsChange(true);
                          setDateTime(true);
                        }}
                        style={{ fontWeight: '500' }}
                      >
                        <Checkbox
                          checked={homeSpecialNewsChecked}
                          onChange={selectHomeSpecialNews}
                          color="default"
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                        Home Special
                         <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the News)
                        </span> 
                      </div> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          // onClick={() => {
                          //   (true);
                          //   setDateTime(true);
                          // }}
                          style={{ fontWeight: "500" }}
                        >
                          <input
                            type="checkbox"
                            checked={tagTrends}
                            onChange={() => {
                              TagNewsToTrends(!tagTrends);
                            }}
                            style={{
                              marginRight: "10px",
                              borderRadius: "2px",
                              border: "2px solid grey",
                              cursor: "pointer",
                            }}
                            color="default"
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                          Trending
                          {/* <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {' '}
                          (*Please set the expire date & time for the News)
                        </span> */}
                        </div>
                        {/* {topNewsType == 'TOP_NEWS' && (
                        <div className="edit__popup-row">
                          <span>Top News Expiry:</span>

                          <div className="relative w-full ">
                            {dateTime ? (
                              <DateTimePicker
                                // format="ddd, dd MM hh:mm A"
                                autoOk
                                hideTabs
                                minDateMessage=""
                                minDate={_dateTimeFormat(
                                  new Date(),
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                value={_dateTimeFormat(
                                  new Date().getTime() + 6 * 60 * 60 * 1000,
                                  'ddd, YYYY DD MMM hh:mm A'
                                )}
                                onChange={(e) =>
                                  updateTopNewsTime(topNewsType, e)
                                }
                              />
                            ) : (
                              _dateTimeFormat(expiryDate, 'ddd, DD MMM hh:mm A')
                            )}
                          </div>
                        </div>
                      )} */}
                      </MuiPickersUtilsProvider>
                    </div>
                    <hr className="my-4 md:min-w-full" />
                    <div style={{ fontWeight: "bold" }}>
                      Home Special:{" "}
                      <HomeSpecialDropdown
                        selectedValue={
                          homeSpecialType == undefined ? "ALL" : homeSpecialType
                        }
                        onChange={onHomeSpecialTypeChange}
                      />
                    </div>
                    <hr className="my-4 md:min-w-full" />

                    <div>
                      {/** Top news option */}
                      {/* <div
                        onClick={() => OnClickEditArticle(newsInfo)}
                        // className={
                        //   newsInfo.copyrights !== 'SportsTak'
                        //     ? 'icon-disabled'
                        //     : ''
                        // }
                      >
                        <div
                          style={{
                            fontWeight: 'bolder',
                            color: 'tomato',
                            cursor: 'pointer',
                          }}
                        >
                          Edit News: <i style={{
                            color: 'black',
                            cursor: 'pointer',
                            marginLeft:'6px',
                          }} className="far fa-edit"></i>
                        </div>
                        <hr className="my-4 md:min-w-full" />
                      </div> */}
                      <div
                        style={{ fontWeight: "500" }}
                        onClick={() => setEditHeading(true)}
                      >
                        Headline*:{" "}
                        <i
                          style={{
                            color: "black",
                            cursor: "pointer",
                            marginLeft: "6px",
                          }}
                          className="fas fa-edit fa-1x"
                        ></i>
                      </div>

                      {editHeading ? (
                        <div className="py-2 px-2">
                          {" "}
                          <textarea
                            name="story"
                            rows="2"
                            cols="80"
                            id="textbox"
                            value={headline}
                            onChange={(e) => setHeadline(e.target.value)}
                            style={{ marginTop: 20, borderRadius: "5px" }}
                          ></textarea>{" "}
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${headline?.length}`}
                          </span>
                          <div
                            className={"edit__popup-row button-row"}
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              onClick={() => updateHeadline()}
                              className={"save btn"}
                            >
                              Update
                            </div>
                            <i
                              onClick={() => setEditHeading(false)}
                              className="far fa-times-circle secondary btn "
                              style={{
                                padding: "9px 10px",
                                background: "#f34a17",
                              }}
                            >
                              Cancel
                            </i>
                          </div>
                        </div>
                      ) : (
                        <div>{newsInfo.headline}</div>
                      )}


                    </div>
                    <hr className="my-4 md:min-w-full" />
                    <div>
                      
                      <div
                        style={{ fontWeight: "500" ,marginTop:"10px"}}
                        onClick={() => setEditShortTitle(true)}
                      >
                        Short Title:{" "}
                        <i
                          style={{
                            color: "black",
                            cursor: "pointer",
                            marginLeft: "6px",
                          }}
                          className="fas fa-edit fa-1x"
                        ></i>
                      </div>

                      {editShortTitle ? (
                        <div className="py-2 px-2">
                          {" "}
                          <textarea
                            name="story"
                            rows="2"
                            cols="80"
                            id="textbox"
                            value={shortTitle}
                            onChange={(e) => setShortTitle(e.target.value)}
                            style={{ marginTop: 20, borderRadius: "5px" }}
                          ></textarea>{" "}
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${shortTitle?.length ?? 0}`}
                          </span>
                          <div
                            className={"edit__popup-row button-row"}
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              onClick={() => updateShortTitle()}
                              className={"save btn"}
                            >
                              Update
                            </div>
                            <i
                              onClick={() => setEditShortTitle(false)}
                              className="far fa-times-circle secondary btn "
                              style={{
                                padding: "9px 10px",
                                background: "#f34a17",
                              }}
                            >
                              Cancel
                            </i>
                          </div>
                        </div>
                      ) : (
                        <div>{newsInfo.shortTitle}</div>
                      )}


                    </div>
                    <div>

                      <hr className="my-4 md:min-w-full" />
                      {/* seo */}
                      <div
                        style={{ fontWeight: "500" }}
                        onClick={() => setEditSEOTitle(true)}
                      >
                        SEO Title*:{" "}
                        <i
                          style={{
                            color: "black",
                            cursor: "pointer",
                            marginLeft: "6px",
                          }}
                          className="fas fa-edit fa-1x"
                        ></i>
                      </div>

                      {editSEOTitle ? (
                        <div className="py-2 px-2">
                          {" "}
                          <textarea
                            name="story"
                            rows="2"
                            cols="80"
                            id="textbox"
                            value={seoTitle}
                            onChange={(e) => setSeoTitle(e.target.value)}
                            style={{ marginTop: 20, borderRadius: "5px" }}
                          ></textarea>{" "}
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${seoTitle?.length}`}
                          </span>
                          <div
                            className={"edit__popup-row button-row"}
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              onClick={() => updateSeoTitle()}
                              className={"save btn"}
                            >
                              Update
                            </div>
                            <i
                              onClick={() => setEditSEOTitle(false)}
                              className="far fa-times-circle secondary btn "
                              style={{
                                padding: "9px 10px",
                                background: "#f34a17",
                              }}
                            >
                              Cancel
                            </i>
                          </div>
                        </div>
                      ) : (
                        <div>{newsInfo.seo_title}</div>
                      )}

                      <hr className="my-4 md:min-w-full" />
                      <div
                        style={{
                          fontWeight: "500",
                          color: invalidSeoSlug ? "red" : "",
                        }}
                        onClick={() => setEditSEOSlug(true)}
                      >
                        SEO Slug: *
                        <i
                          style={{
                            color: invalidSeoSlug ? "red" : "black",
                            cursor: "pointer",
                            marginLeft: "6px",
                          }}
                          className="fas fa-edit fa-1x"
                        ></i>
                        {invalidSeoSlug && (
                          <span
                            style={{ fontSize: "12px", color: "#ff0000" }}
                          >{` (*SEO Slug must contain character in between a-z, A-Z, 0-9 or -.)`}</span>
                        )}
                      </div>

                      {editSEOSlug ? (
                        <div className="py-2 px-2">
                          {" "}
                          <textarea
                            name="story"
                            rows="2"
                            cols="80"
                            id="textbox"
                            value={seoSlug}
                            onInput={(e) => setInvalidSeoSlug(false)}
                            onChange={(e) => setSeoSlug(e.target.value)}
                            style={{
                              borderRadius: "5px",
                              borderColor: invalidSeoSlug ? "red" : "",
                              color: invalidSeoSlug ? "red" : "",
                            }}
                          ></textarea>{" "}
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                              marginLeft: "2rem",
                              marginBottom: "0.1px",
                            }}
                          >
                            {`Characters: ${seoSlug?.length}`}
                          </span>
                          <div
                            className={"edit__popup-row button-row"}
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              onClick={() => updateSeoSlug()}
                              className={"save btn"}
                            >
                              Update
                            </div>
                            <i
                              onClick={() => {
                                setEditSEOSlug(false);
                                setInvalidSeoSlug(false);
                              }}
                              className="far fa-times-circle secondary btn "
                              style={{
                                padding: "9px 10px",
                                background: "#f34a17",
                              }}
                            >
                              Cancel
                            </i>
                          </div>
                        </div>
                      ) : (
                        <div style={{ color: invalidSeoSlug ? "red" : "" }}>
                          {newsInfo.seo_slug}
                        </div>
                      )}

                      <hr className="my-4 md:min-w-full" />
                      <div
                        style={{ fontWeight: "500" }}
                        onClick={() => setEditKeyword(true)}
                      >
                        Keyword*:{" "}
                        <i
                          style={{
                            color: "black",
                            cursor: "pointer",
                            marginLeft: "6px",
                          }}
                          className="fas fa-edit fa-1x"
                        ></i>
                      </div>

                      {editKeyword ? (
                        <div className="py-2 px-2">
                          <textarea
                            name="keyword"
                            // onPaste={(e) => {
                            //   e.preventDefault();
                            //   alert("Copy Paste is not Allowed!!!!");
                            //   return false;
                            // }}
                            rows="2"
                            cols="80"
                            id="textbox"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            style={{ marginTop: 20, borderRadius: "5px" }}
                          ></textarea>{" "}
                          <div
                            className={"edit__popup-row button-row"}
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              onClick={() => updateKeyword()}
                              className={"save btn"}
                            >
                              Update
                            </div>
                            <i
                              onClick={() => setEditKeyword(false)}
                              className="far fa-times-circle secondary btn "
                              style={{
                                padding: "9px 10px",
                                background: "#f34a17",
                              }}
                            >
                              Cancel
                            </i>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {newsInfo.slug ? newsInfo.slug : newsInfo.keyword}
                        </div>
                      )}

                      <hr className="my-4 md:min-w-full" />
                      <div
                        style={{ fontWeight: "500" }}
                        onClick={() => setEditSEODescription(true)}
                      >
                        SEO Description*:
                        <i
                          style={{
                            color: "black",
                            cursor: "pointer",
                            marginLeft: "6px",
                          }}
                          className="fas fa-edit fa-1x"
                        ></i>
                      </div>
                      {editSEODescription ? (
                        <>
                          {/* <FroalaEditorComponent /> */}
                          <ReactQuill
                            className="shadow-sm"
                            theme="snow"
                            name="description"
                            style={{
                              minHeight: 50,
                              marginTop: "1rem",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            value={seoDescription}
                            modules={{
                              toolbar: [
                                [
                                  // { header: "1" },
                                  // { header: "2" },
                                  { header: [1, 2, 3, 4, 5, 6] },
                                  { font: [] },
                                ],
                                [{ size: [] }],
                                [
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                ],
                                [{ align: [] }],
                                [{ color: [] }, { background: [] }],
                                [
                                  { list: "ordered" },
                                  { list: "bullet" },
                                  { indent: "-1" },
                                  { indent: "+1" },
                                ],
                                // ["link", "image"],
                                ["link", "video", "image"],
                                // ["link", "video", "image", "code-block"],
                                // ['clean'],
                                ["embed"],
                              ],
                            }}
                            formats={[
                              "header",
                              "font",
                              "size",
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              "color",
                              "background",
                              "list",
                              "bullet",
                              "indent",
                              "link",
                              "video",
                              "image",
                              "code-block",
                              "align",
                            ]}
                            onChange={(val, delta, source, editor) => {
                              setSeoDescription(val);
                              setSeoDescriptionLength(editor.getText()?.length);
                            }}
                          />
                          <br></br>
                          <span
                            style={{
                              background: "#50C878",
                              height: "auto",
                              width: "auto",
                              color: "#fff",
                              fontWeight: "600",
                              padding: "5px",
                              borderRadius: "8px",
                            }}
                          >
                            {`Characters: ${
                              seoDescriptionLength - 1 < 1
                                ? 0
                                : seoDescriptionLength - 1
                            }`}
                          </span>

                          {/* <CKEditor editor={Editor} data="<p>hello world</p>" /> */}
                          <div className={"edit__popup-row button-row"}>
                            <div
                              onClick={() => updateSeoDescription()}
                              className={"save btn"}
                            >
                              Update
                            </div>
                            <i
                              onClick={() => setEditSEODescription(false)}
                              className="far fa-times-circle secondary btn "
                              style={{
                                padding: "9px 10px",
                                background: "#f34a17",
                              }}
                            >
                              Cancel
                            </i>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div
                            style={{ paddingTop: 10 }}
                            dangerouslySetInnerHTML={{
                              __html: newsInfo.seo_description,
                            }}
                          ></div>
                        </>
                      )}
                      <hr className="my-4 md:min-w-full" />
                      {/* seo end */}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <div style={{ fontWeight: "500" }}>Published At:</div>
                        {/* <div>{newsInfo.PublishedAt}</div> */}
                        {_dateTimeFormatinIST(
                          newsInfo.PublishedAt,
                          "ddd, DD MMM hh:mm A"
                        )}
                        {/* {newsInfo.copyrights === "PTI"
                        ? _dateTimeFormat(
                            newsInfo.PublishedAt,
                            "ddd, DD MMM hh:mm A"
                          )
                        : _getDateTime(
                            newsInfo.PublishedAt,
                            "ddd, DD MMM hh:mm A"
                          )} */}
                      </div>

                      <div style={{ marginLeft: 100 }}>
                        <div style={{ fontWeight: "500" }}>News Type:</div>
                        <div>{newsInfo.copyrights}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-0 md:min-w-full" />
                <div className="my-4">
                  <input
                    type="text"
                    value={searchHyperLink}
                    onChange={(event) => onHyperLinkSearch(event)}
                    style={{
                      border: "2px solid grey",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                    placeholder="Search for articles to add"
                  />

                  <NewsHyperlinkDropDown
                    searchKey={searchHyperLink}
                    selectedValue={getHyperLink}
                    onChange={onHyperLinkChange}
                  />
                </div>
                {hyperLinkChange ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onCopyHyperLink()}
                    style={{ marginBottom: "20px" }}
                  >
                    Select This To Link
                  </Button>
                ) : (
                  ""
                )}
                {copyHyperLink ? (
                  <CopyToClipboard
                    text={textToCopy}
                    onCopy={() => setCopyText(true)}
                  >
                    <i
                      className=" fas fa-clipboard-list"
                      style={{
                        padding: "9px 10px",
                        color: "#f34a17",
                        width: "",
                        height: "55px",
                        cursor: "pointer",
                      }}
                    >
                      Copy
                    </i>
                  </CopyToClipboard>
                ) : (
                  ""
                )}
                {copyText ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "green" }}>
                      Copied... Click Link below to see the article detail page.
                    </span>
                    <a
                      href={textToCopy}
                      target="_blank"
                      style={{ color: "blue", marginBottom: "10px" }}
                    >
                      {textToCopy}
                    </a>
                  </div>
                ) : (
                  ""
                )}

                <hr className="my-0 md:min-w-full" />
                <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Bullet Points*</label>
                  <br></br>
                  <CKEditor
                    onChange={(event, editor) => {
                      setSummary(editor.getData());
                      const wordCountPlugin = editor.plugins.get("WordCount");

                      const wordCountWrapper =
                        document.getElementById("word-count");

                      wordCountWrapper.appendChild(
                        wordCountPlugin.wordCountContainer
                      );
                    }}
                    onReady={(editor) => {
                      const wordCountPlugin = editor?.plugins?.get("WordCount");

                      const wordCountWrapper =
                        document.getElementById("word-count");

                      if (wordCountWrapper)
                        wordCountWrapper.appendChild(
                          wordCountPlugin.wordCountContainer
                        );

                      // Insert the toolbar before the editable area.

                      // this.set( 'viewportTopOffset', 0 );

                      // this.editor = editor;
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={summary}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                      customStyles: {
                        ul: {
                            marginLeft: '20px', // Adjust the padding value as needed
                        },
                    },
                    heading: {
                      options: [
                          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                          { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                          { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                      ]
                  }
                    }}
                    // config={{
                    //   toolbar: [
                    //     'heading',
                    //     '|',
                    //     'bold',
                    //     'italic',
                    //     'blockQuote',
                    //     'link',
                    //     'numberedList',
                    //     'bulletedList',
                    //     'imageUpload',
                    //     'insertTable',
                    //     'tableColumn',
                    //     'tableRow',
                    //     'mergeTableCells',

                    //     'htmlEmbed',
                    //     '|',
                    //     'undo',
                    //     'redo',
                    //   ],
                    // }}
                  />
                  {/* <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  /> */}

                  <span id="word-count"></span>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => updateSummary()}
                    style={{ marginBottom: "20px" }}
                  >
                    Update Bullet Points
                  </Button>
                </div>

                <div
                  style={{ fontWeight: "500" }}
                  onClick={() => setEditStory(true)}
                >
                  Description*:
                  <i
                    style={{
                      color: "black",
                      cursor: "pointer",
                      marginLeft: "6px",
                    }}
                    className="fas fa-edit fa-1x"
                  ></i>
                </div>
                {editStory ? (
                  <>
                    {/* <FroalaEditorComponent /> 
                    {/* <ReactQuill
                      className="shadow-sm"
                      theme="snow"
                      name="description"
                      style={{
                        minHeight: 50,
                        marginTop: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      value={story}
                      modules={{
                        toolbar: [
                          [
                            // { header: "1" },
                            // { header: "2" },
                            { header: [1, 2, 3, 4, 5, 6] },
                            { font: [] },
                          ],
                          [{ size: [] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                          ],
                          [{ align: [] }],
                          [{ color: [] }, { background: [] }],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' },
                          ],
                          // ["link", "image"],
                          ['link', 'video', 'image', 'embed', 'code-block'],
                          // ['link', 'video', 'image', 'code-block'],
                          // ['clean'],
                          // [],
                        ],
                      }}
                      formats={[
                        'header',
                        'font',
                        'size',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'color',
                        'background',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'video',
                        'image',
                        'code-block',
                        'align',
                      ]}
                      onChange={(val) => setStory(val)}
                    />*/}
                    <TextEditor
                      data={story}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        
                        setStory(data);
                      }}
                    />
                    {/* <CKEditor
                      editor={Editor}
                      data={story}
                      style={{ overflowY: 'scorll' }}
                      onChange={(e, editor) => setStory(editor.getData())}
                      config={{
                        extraPlugins: [uploadPlugin],
                        // height: 500,
                        // width:100,
                      }}
                      // config={{
                      //   plugins: [HtmlEmbed],
                      //   toolbar: [
                      //     'heading',
                      //     '|',
                      //     'bold',
                      //     'italic',
                      //     'blockQuote',
                      //     'link',
                      //     'numberedList',
                      //     'bulletedList',
                      //     'imageUpload',
                      //     'insertTable',
                      //     'tableColumn',
                      //     'tableRow',
                      //     'mergeTableCells',

                      //     'htmlEmbed',

                      //     '|',
                      //     'undo',
                      //     'redo',
                      //   ],
                      // }}
                    /> */}
                    <span id="word-count1"></span>
                    <div className={"edit__popup-row button-row"}>
                      <div onClick={() => updateStory()} className={"save btn"}>
                        Update
                      </div>
                      <i
                        onClick={() => setEditStory(false)}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "#f34a17",
                        }}
                      >
                        Cancel
                      </i>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="newsInfo_story"
                      style={{ paddingTop: 10 }}
                      dangerouslySetInnerHTML={{ __html: newsInfo.story }}
                    ></div>
                    {/* <div style={{ paddingTop: 10 }}>{newsInfo.story}</div> */}
                    {/* <iframe
                      className="newsInfo-iframe"
                      name="news-frame"
                      id="news-frame"
                      width="1px"
                      min-width="100%"
                      title="news"
                      src={url && url}
                      allow="autoplay"
                      allow="encrypted-media"
                      allowfullscreen=""
                      width="100%"
                      frameBorder="0"
                    /> */}
                    {/* <embed type="video/webm"
       src="/media/cc0-videos/flower.mp4"
       width="250"
       height="200"> */}
                  </>
                )}
                <div
                  style={{ fontWeight: "500" }}
                  onClick={() => setEditStorySourceCode(true)}
                >
                  Edit Description source code:
                  <i
                    style={{
                      color: "black",
                      cursor: "pointer",
                      marginLeft: "6px",
                    }}
                    className="fas fa-edit fa-1x"
                  ></i>
                </div>
                {editStorySourceCode && (
                  <>
                    <textarea
                      name="story"
                      rows="27"
                      cols="112"
                      id="textbox"
                      value={story}
                      onChange={(e) => setStory(e.target.value)}
                    />
                    <div className={"edit__popup-row button-row"}>
                      <div onClick={() => updateStory()} className={"save btn"}>
                        Update
                      </div>
                      <i
                        onClick={() => setEditStorySourceCode(false)}
                        className="far fa-times-circle secondary btn "
                        style={{
                          padding: "9px 10px",
                          background: "#f34a17",
                        }}
                      >
                        Cancel
                      </i>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/*Trending Keyword List*/}
      <>
        <div style={{ position: "fixed", bottom: "30px", right: "20px" }}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={() => setOpen(true)}
            style={{ marginRight: 5 }}
          >
            Trending Keywords
          </Fab>
        </div>
        <>
          <div
            className="edit__popup"
            style={{ display: open ? "block" : "none" }}
          >
            <div
              onClick={() => setOpen(false)}
              className={open ? "edit__backdrop active" : "edit__backdrop"}
            ></div>

            <div
              className={open ? "edit__popup-body active" : "edit__popup-body"}
              style={{
                zIndex: 1000,
                maxWidth: "1000px",
                width: "1000px",
                height: "80%",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="edit__popup-heading">Top Trends</div>
                <div className="edit__popup-inner" style={{ marginTop: "7px" }}>
                  <i
                    onClick={() => setOpen(false)}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                </div>
              </div>
              <TrendsAllLocations
                twitterTaggedLocations={twitterTaggedLocations}
              />
            </div>
          </div>
        </>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leaguesTag: state.leaguesReducer.leaguesTag,
    matchesTag: state.matchesReducer.matchesTag,
    teamsTag: state.teamsReducer.teamsTag,
    leagues: state.leaguesReducer.leagues,
    leagueSearch: state.leaguesReducer.leagueSearch,
    matchesSearch: state.matchesReducer.matchesSearch,
    teamsSearch: state.teamsReducer.teamsSearch,
    teamName: state.teamsReducer.teamName,
    isTeamsTagginSuccess: state.teamsReducer.isTeamsTagginSuccess,
    matchesPage: state.matchesReducer.matchesPage,
    sports: state.sportsReducer.sports,
    selectedSportsTagging: state.sportsReducer.selectedSportsTagging,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
    selectedSports: state.sportsReducer.selectedSports,
    selectedNewsHyperLink: state.newsHyperLinkReducer.selectedNewsHyperLink,
    selectedNewsHeadlineLink:
      state.newsHyperLinkReducer.selectedNewsHeadlineLink,
  };
};
export default connect(mapStateToProps)(NewsInfo);
