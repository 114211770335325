import { ProductivityActionType } from '../action-types';
const current = new Date();
const currnet_date = `${current.getDate()}-${
  current.getMonth() + 1
}-${current.getFullYear()}`;

var date = new Date();
// Set it to one month ago
date.setMonth(date.getMonth() - 1);
// Zero the time component
date.setHours(0, 0, 0, 0);
var startDateFormat = new Date(date),
  mnth = ('0' + (startDateFormat.getMonth() + 1)).slice(-2),
  day = ('0' + startDateFormat.getDate()).slice(-2);
let formattedStartDate = [startDateFormat.getFullYear(), mnth, day].join('-');

var endDateToFormat = new Date();

var endDateFormat = new Date(endDateToFormat);
(mnth = ('0' + (endDateFormat.getMonth() + 1)).slice(-2)),
  (day = ('0' + endDateFormat.getDate()).slice(-2));
let formattedEndDate = [endDateFormat.getFullYear(), mnth, day].join('-');

const initialState = {
  newsStartDate: formattedStartDate,
  newsEndDate: formattedEndDate,
  videosStartDate: formattedStartDate,
  videosEndDate: formattedEndDate,
};

const productivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductivityActionType.NEWS_START_DATE:
      return Object.assign({}, state, {
        ...action.payload,
        newsStartDate: action.payload,
      });
    case ProductivityActionType.NEWS_END_DATE:
      return Object.assign({}, state, {
        ...action.payload,
        newsEndDate: action.payload,
      });
    case ProductivityActionType.VIDEOS_START_DATE:
      return Object.assign({}, state, {
        ...action.payload,
        videosStartDate: action.payload,
      });
    case ProductivityActionType.VIDEOS_END_DATE:
      return Object.assign({}, state, {
        ...action.payload,
        videosEndDate: action.payload,
      });

    default:
      return state;
  }
};

export default productivityReducer;
