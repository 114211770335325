import React, { useEffect, useState, useCallback, useMemo } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import TableRow from "./TableRow";
import arrayMove from "./ArrayMove";
import {useHistory} from "react-router-dom"
import {
    GetPollsInfo,
    ReorderPolls
} from '../../../handlers/polls.handlers';
const MyTableWrapper = styled.div`
  padding: 10px;

  .table tbody {
    color: #white;
    max-width:900px;
  }

  .table tbody {
    counter-reset: rowNumber;
  }

  .table tbody tr::before {
    display: table-cell;
    // padding-left: 10px;
    // padding-right: 10px;
    text-align: center;
    vertical-align: middle;
    align-self: center;
    color: black;
  }

  .fixed_header {
    // width: 800px;
    table-layout: fixed;
    border-collapse: collapse;

    & > tbody {
      display: block;
      //   width: 807px;
      overflow: auto;
      height: 400px;
      cursor: grabbing;
      //   background: grey;

      & > tr {
        width: 80%;
      }
    }

    & > thead {
      background: #ebecf0;
      color: black;
      display: block;
        padding:5px;
      & > tr {
        // display: block;
        width: 793px;
      }
    }

    & > thead th,
    & > tbody td {
    //   padding: 5px;
      text-align: center;
      width: 100%;
      //   border: 1px solid #000;
    //   padding-right: 20px;
      vertical-align: middle;
      align-self: center;
    }

    & > thead th {
      padding: 5px;
      padding-right:10px;
    }
  }
`;

export default function ShowRank({setEdit,list}) {
    const mhis = useHistory();
    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        setItems((oldItems) => {
            const authorRankArr = arrayMove(oldItems, oldIndex, newIndex);

            return authorRankArr;
        });
    }, []);


    const update = ()=>{
        ReorderPolls(items).then(
            (response) => {
                if (response) {

                    setEdit(false);
                }
            }
        ).catch((err) => console.log(err));
    }
    const SortableCont = SortableContainer(({ children }) => {
        return <tbody>{children}</tbody>;
    });
    const ITEMS = [];
    for (let i = 0; i < list.length; i++) {
        const itemObj = {
            title: list[i].title,
            rank: list[i].rank,
            _id: list[i]._id,
        };
        ITEMS.push(itemObj);
    }
    const [items, setItems] = useState(ITEMS);
    const SortableItem = SortableElement((props) => <TableRow {...props} />);
  return (
    <>
          <>
              <div
                  className="edit__popup"
                  style={{ display: open ? "block" : "none" }}
              >
                  <div
                      onClick={() => setEdit(false)}
                      className={open ? "edit__backdrop active" : "edit__backdrop"}
                  ></div>

                  <div
                      className={open ? "edit__popup-body active" : "edit__popup-body"}
                      style={{
                          zIndex: 1000,
                          maxWidth: "1000px",
                          width: "1000px",
                          height: "80%",
                          position: "relative",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                      }}
                  >
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="edit__popup-heading">Rankings</div>
                          <div style={{display:"flex",justifyContent:"space-between"}}>

                              <button style={{ borderRadius:"10px",padding:"10px",marginTop: "10px", marginRight: "35px", color: "white", backgroundColor: "rgb(0 194 26)"}} onClick={update}>UPDATE</button>
                            <div className="edit__popup-inner" style={{ marginTop: "7px" }}>
                                

                                
                                <i
                                    onClick={() => setEdit(false)}
                                    className="far fa-times-circle edit__popup-close"
                                ></i>
                                
                            </div>
                          </div>
                      </div>
                      <div >

                          <div
                              style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  color:"black",
                                  backgroundColor:"none"
                              }}
                          >
                              <MyTableWrapper style={{maxWidth:"900px"}}>
                                  <table className="table fixed_header" style={{ maxWidth: "900px",width:"900px" }}>
                                      <thead>
                                          <tr>
                                              <th>Title</th>
                                              <th>Rank</th>
                                          </tr>
                                      </thead>
                                      <SortableCont
                                          onSortEnd={onSortEnd}
                                          axis="y"
                                          lockAxis="y"
                                          lockToContainerEdges={true}
                                          lockOffset={["30%", "50%"]}
                                          helperClass="helperContainerClass"
                                          useDragHandle={true}
                                      >
                                          {items?.map((value, index) => (
                                              <SortableItem
                                                  key={`item-${index}`}
                                                  index={index}
                                                  title={value.title}
                                                  rank={index+1}
                                                  _id={value._id}
                                              />
                                          ))}
                                      </SortableCont>
                                  </table>
                              </MyTableWrapper>
                          </div>
                      </div>
                  </div>
              </div>
          </>
               
    </>
  )
}
