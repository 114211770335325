import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import Sidebar from '../../../components/Sidebar/Sidebar';
import './FeaturedNews.css';
import { Link, useHistory } from 'react-router-dom';
import {
  GetFeaturedContent,
  ToggleVideoFeatured,
} from '../../../handlers/featuredContent.handler';
import { _dateTimeFormat, _getDateTime } from '../../../constants/appDefaults';
import AlertModal from '../../../components/AlertModal';
import FeaturedDropdown from '../../../components/Dropdowns/FeaturedDropdown';
import { Button, Checkbox } from '@material-ui/core';

const FeaturedNews = (props) => {
  const [docs, setDocs] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const mHistory = useHistory();
  const [featureType, setFeatureType] = useState('Videos');
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const color = 'light';
  let fileInput = React.useRef();
  const [feature_Id, setFeature_Id] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [featureStatus, setFeatureStatus] = useState("");
  const [featureImage, setFeatureImage] = useState("");

  const updateFeatureStatus = (id, status) => {
    const data = {
      _id : id,
      image: featureImage,
      isFeatured: !status
  };
  const formData = new FormData();


  Object?.keys(data)?.forEach(function (key) {
    formData?.append(key, data[key]);
  });
  if(featureType==='Videos')
  {
    ToggleVideoFeatured(formData).then((response)=>{
     
        alert(response?.message)
        
        window.location.reload(false);
    })
  }
  else
  {
    console.log("Image API is left to integrate here.")
  }
  };

  useEffect(() => {
    getContent('video')
  }, []);

  const onClickOpenMedia = (item) => {
    showEditHandler(true);
    setFeature_Id(item?._id);
    setFeatureStatus(item?.isFeatured);
    setUpdateImage(item?.thumbnail?.aspectRatio_43?.url)
  };

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  const onFeatureTypeChange = (event) => {
    setFeatureType(event.target.value);
    getContent(event.target.value.toLowerCase());
  };
  const getContent = (value)=>{
      const body = '?type='+value;
      GetFeaturedContent(body)
      .then((response) => {     
        setDocs(response.data);
      })
      .catch((error) => {
        console.log(error)
      });
  };
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = '';

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (width !==(4*height)/3) {
          alert('Image width and height must be in ratio of 4:3 ');
          return false;
        }
      };
      setFeatureImage(file);
      setUpdateImage(reader.result);
      
    };
    reader.onerror = function (error) {
      //
    };
  };
  const handleChange = () => {
    if(featureImage)
    {
      updateFeatureStatus(feature_Id, !featureStatus);
    }
    else{
      alert('Please upload a Image in the Ratio of 4:3')
    }
};
  const onView = (feature) => {
    feature?.type=="video"?
    (props.isYoutube === true
    ? mHistory.push(`/videos/${feature?._id}/yes`)
    : mHistory.push(`/videos/${feature?._id}/no`)):
    (console.log("Image data is missing"))
  };
  return (
    <>
      <Sidebar />
      <div className="edit__popup" style={{}}>
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '45%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                showEditHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            Upload Image:{' '}
            
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"30px"}}>
              
              <div style={{width: 284,
                            height: 213,
                            border: '2px solid gray',marginBottom:"10px"}}>
                  {updateImage?<img src={updateImage} style={{ height:210, maxWidth:280}}/>:null}
                  </div>  
                  <div style={{fontWeight:"600", color:"gray"}}>Please upload the image in the ratio of 4:3 *</div>
              <div>
                 <input
                          ref={fileInput}
                          type="file"
                          name="thumbnail Image"
                          onChange={onFileSelect}
                          style={{
                            backgroundColor: "#000",
                            borderRadius:"1px solid #111",
                            height: 35,
                            width: 40,
                            position: "absolute",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                        <i
                          className="fas fa-camera fa-2x"
                          style={{
                            margin: 8,
                            color: "#555",
                            cursor: "pointer",
                          }}
                        ></i>
              </div>
              <Button
              variant="contained"
              onClick={() => handleChange()}
              style={{ marginBottom: '1.5%', backgroundColor:"#50C878", color:"#fff" }}
            >
              Update
            </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your article has been updated successfully!"
                onSuccess={() => setAlertModal(false)}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                  <div className="flex flex-wrap items-center">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      {`Featured/${featureType}`}
                      
                    </h3>

                   
                    <div
                      className="relative w-full px-10 max-w-full flex-grow flex-1"
                      style={{ textAlign: 'right' }}
                    >
                      <FeaturedDropdown
                        selectedValue={featureType}
                        onChange={onFeatureTypeChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <DragDropContext >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Thumb Image
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Title
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Sports
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Copyright
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Published Date
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Expiry_Date Time
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Status
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            

                            {docs.length > 0 &&
                              docs.map((feature, index) => 
                               (
                                    <tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  ">
                                       
                                        <img
                                   src={feature?.thumbnail?.aspectRatio_43?.url}
                                   style={{
                                    // maxHeight: 300,
                                    // minHeight: 253,
                                    // maxWidth: 450,
                                    // minWidth: 400,
                                    maxHeight: 100,
                                    minHeight: 53,
                                    maxWidth: 53,
                                    minWidth: 100,
                                  }}
                                  height={300}
                                  width={400}
                                  alt="..."
                                  onClick={() => onClickOpenMedia(feature)}
                                 ></img>
                                      </th>
                                      <td
                                        className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                        onClick={() => onView(feature)}
                                        style={{
                                          cursor: 'pointer',
                                          fontWeight: 'bolder',
                                        }}
                                      >
                                        {feature.title}
                                      </td>
                                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {feature.sports && feature.sports.name
                                          ? feature.sports.name
                                          : 'N/A'}
                                      </td>
                                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {feature.copyright}
                                      </td>
                                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {leagueName(feature.league)}
                                        {/* {feature.league ? feature.league : 'N/A'} */}
                                      </td>

                                      {/* feature publish time */}
                                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {_dateTimeFormat(
                                          feature.createdAt,
                                          'ddd, DD MMM hh:mm A'
                                        )}
                                        
                                      </td>
                                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {_dateTimeFormat(
                                          feature?.homeFeaturedExpiry,
                                          'ddd, DD MMM hh:mm A'
                                        )}
                                      </td>
                                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {feature.status==1 ? (
                                          <span
                                            style={{
                                              color: 'green',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Published
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              color: 'red',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Unpublished
                                          </span>
                                        )}
                                      </td>
                                      <td
                                        className="border border-green-800"
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <div
                                          onClick={() =>
                                            updateFeatureStatus(feature._id, feature?.isFeatured)
                                          }
                                          className="text-center"
                                        >
                                          <i className="fas fa-trash"></i>
                                        </div>
                                      </td>
                                    </tr>
                                )
                              )}
                          </tbody>
                        </table>

                        
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
    //hasNextPage: state.homeNewsReducer.hasNextPage,
    //page: state.homeNewsReducer.page,
    limit: state.homeNewsReducer.limit,
    //docs: state.newsReducer.docs,
  };
};
export default connect(mapStateToProps)(FeaturedNews);
