import AppConstants from '../constants';
import LoginAppConstants from '../constants/LoginAppConstants';
import AxiosInstance from './http.handlers';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = {
  'cms-access-token': accessToken,
  source: 'cms',
  language: CURRENT_LANGUAGE,
};
export const GetUsersProductivityReportNews = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.USER.GET_PRODUCTIVITY_REPORT_NEWS}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetUsersProductivityDetailNews = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.USER.GET_PRODUCTIVITY_DETAIL_NEWS}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetUsersProductivityReportVideos = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.USER.GET_PRODUCTIVITY_REPORT_VIDEOS}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetUsersProductivityDetailVideos = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.USER.GET_PRODUCTIVITY_DETAIL_VIDEOS}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
