import React, { useState, useEffect } from "react";
import {
  GetAllLiveStream,
  PublishLiveStream,
  EditTitle,
} from "../../../handlers/live.handlers";

import { _getDateTime, _dateTimeFormat } from "../../../constants/appDefaults";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";

import { Button, Checkbox } from "@material-ui/core";
import HomeDropdown from "../../../components/Dropdowns/HomeDropdown";
import AlertModal from "../../../components/AlertModal";
import Sidebar from "../../../components/Sidebar/Sidebar";
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
// var shouldPublish;
const ManageLive = () => {
  const [docs, setDocs] = useState([]);
  const [showEdit, showEditHandler] = useState(false);
  const [title, setTitle] = useState("");
  const [eventId, setEventId] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const color = "light";
  const [isLoading, setIsLoading] = useState(false);

  const getLiveData = () => {
    GetAllLiveStream()
      .then((response) => {
        setDocs(response.data);
        setIsLoading(false);
        setIsPublished(response.data.some((obj) => obj.isLiveOnApp == 2));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setIsLoading(true);
    getLiveData();
  }, []);
  const handleClick = (event_id, isLive) => {
    const body = { event_id: event_id, isLiveOnApp: isLive };

    PublishLiveStream(body).then((response) => {
      alert("Livestream updated successfully");
      getLiveData();
    });
  };
  const handleChange = () => {
    const body = { event_id: eventId, event_title: title };
    EditTitle(body).then((response) => {
      showEditHandler(false);
      alert("Livestream updated successfully");
      getLiveData();
    });
  };
  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? "edit__backdrop active" : "edit__backdrop"}
        ></div>

        <div
          className={showEdit ? "edit__popup-body active" : "edit__popup-body"}
          style={{ width: "45%" }}
        >
          {" "}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showEditHandler(false);

                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            Edit Title:{" "}
            <input
              type="text"
              //   name=
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Edit title"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
              style={{
                width: "60%",
                height: "33px",
                margin: "1.2%",
                marginRight: "4%",
              }}
            />
            <Button
              variant="contained"
              onClick={() => handleChange()}
              style={{ marginBottom: "1.5%" }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        "font-semibold text-lg " +
                        (color === "light" ? "text-blueGray-700" : "text-white")
                      }
                    >
                      Manage Live
                    </h3>
                    <h2>* Please publish one video at a time</h2>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Title
                      </th>

                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Add
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs &&
                      docs.map((item) => {
                        return (
                          <tr>
                            <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: "bolder",
                              }}
                            >
                              {item.fullName}
                              {item.isLiveOnApp != 0 && (
                                <i
                                  className="fas fa-edit fa-1x"
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "5px",
                                    color: "#c3c3c3",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    showEditHandler(true);
                                    setTitle(item.fullName);
                                    setEventId(item.event_id);
                                  }}
                                ></i>
                              )}
                            </td>
                            <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: "bolder",
                              }}
                            >
                              <Button
                                variant="contained"
                                disabled={item.isLiveOnApp == 0 ? true : false}
                                onClick={() => {
                                  item.isLiveOnApp == 1 && isPublished
                                    ? alert(
                                        `Already published one video, can-not publish multiple videos, please unpublish first.`
                                      )
                                    : handleClick(
                                        item.event_id,
                                        item.isLiveOnApp == 1 ? 2 : 1
                                      );
                                }}
                              >
                                {item.isLiveOnApp == 2
                                  ? "Unpublish"
                                  : item.isLiveOnApp == 0
                                  ? "Live"
                                  : "Publish"}
                              </Button>
                            </td>
                            <td
                              className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              style={{
                                // cursor: "pointer",
                                fontWeight: "bolder",
                              }}
                            >
                              {item.isLiveOnApp == 2
                                ? "Live and published"
                                : item.isLiveOnApp == 0
                                ? "Not Live"
                                : "Live but not published"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageLive;
