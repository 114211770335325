import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
const headers = { 'cms-access-token': cms_access_token, source: 'cms' };

export const GetMatchesForTagging = async (
  compId,
  sportsId,
  searchKey,
  page,
  limit
) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.MATCHES.TAG_MATCHES}/${compId}/${sportsId}/${searchKey}/${page}/${limit}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
