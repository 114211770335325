import React, { useEffect } from 'react';
import NewsTable from '../../../components/Cards/NewsTable';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { connect } from 'react-redux';
const News = (props) => {
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <NewsTable color="light" />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    userType: state.authReducer.userType,
    userData: state.authReducer.userData,
  };
};
export default connect(mapStateToProps)(News);
