import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const addPurgeURL = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.PURGING.ADD_URL_TO_PURGE}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};