import { Button, Checkbox } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { AddInsights } from '../../../handlers/optaGraphics.handlers';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import LeaguesDropDown from '../../../components/Dropdowns/LeaguesDropDown';
import FilteredLeagueDropDown from "../../../components/Dropdowns/FilteredLeagueDropDown";
import SportsDropDown from '../../../components/Dropdowns/SportsDropDown';
import { connect } from 'react-redux';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
import { useHistory } from 'react-router';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const UploadGraphics = (props) => {
  const mHistory = useHistory();
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [selectedLeague, setSelectedLeague] = useState('');
  const [isHomeChecked, setIsHomeChecked] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [selectedUUID, setSelectedUUID] = useState('');
  const [selectedSports, setSelectedSports] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  let fileInput = useRef();
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (
          (height === 1080 && width === 1080) ||
          (height === 1200 && width === 1200)
        ) {
          // setSelectedImage(file);
          setSelectedImage(reader.result);
          setConfirmationModal({
            message: 'Are you sure you want to upload this image?',
            show: true,
            title: 'Upload Match Graphic',
          });
        } else {
          alert('Image width and height must be 1080x1080 or 1200*1200 px');
          return false;
        }
      };
    };
    reader.onerror = function (error) {};
  };
  const onLeagueChange = (league, uuid, _id) => {
    setSelectedLeague(_id);
    setSelectedUUID(uuid);
  };

  const onSportChange = (value) => setSelectedSports(value);

  const homeCheckHandler = (event) => {
    if (event.target.checked) {
      setIsHomeChecked(true);
    } else setIsHomeChecked(false);
  };
  const isPublishedHandler = (event) => {
    if (event.target.checked) {
      setIsPublished(true);
    } else setIsPublished(false);
  };
  const uploadInsightHandler = () => {
    setIsLoading(true);
    const body = {
      isHomeTagged: isHomeChecked,
      isPublished: isPublished,
      comp_id: selectedLeague,
      sports: selectedSports,
      file: selectedImage,
    };

    if (body.sports == '') {
      alert('Please Tag sports');
      setIsLoading(false);
    } else if (body.comp_id == '') {
      alert('Please Tag League');
      setIsLoading(false);
    } else {
      AddInsights(body).then((response) => {
        if (response.status == true) {
          setIsLoading(false);
          alert('Graphics added successfully');

          mHistory.push('/opta-graphics');
        } else alert('Please try again.');
      });
    }
  };
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div className="relative bg-blueGray-100">
              <div
                className={
                  'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                  'bg-white'
                }
              >
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                      <h3
                        className={
                          'font-semibold text-lg ' + 'text-blueGray-700'
                        }
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {'Add Insight'}
                        <div
                          className="relative w-full  max-w-full flex-grow flex-1"
                          style={{ textAlign: 'right' }}
                        >
                          <span style={{ marginLeft: 5 }}>
                            <Link to="/opta-graphics">
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginBottom: '1.5%' }}
                              >
                                Opta Graphics
                              </Button>
                            </Link>
                          </span>
                        </div>
                      </h3>
                    </div>
                    <SyncLoader
                      color="#23A5E0"
                      css={override}
                      size={12}
                      loading={isLoading}
                    />
                    <div
                      className="block w-full overflow-x-auto addQuizStyle"
                      style={{ padding: '20px' }}
                    >
                      <div className="topic">
                        <label className="addArticle_label">Image*</label>
                        <br></br>
                      </div>
                      {/* {selectedImage == undefined ?'': */}
                      <img
                        src={selectedImage}
                        style={{ height: 300, width: 220, marginTop: '10px' }}
                      />
                      {/* } */}
                      <div style={{ display: 'inline-block' }}>
                        <input
                          ref={fileInput}
                          type="file"
                          multiple={false}
                          onChange={onFileSelect}
                          accept="image/x-png,image/jpeg"
                          style={{
                            backgroundColor: '#000',
                            height: 35,
                            width: 148,
                            position: 'absolute',
                            opacity: 0,
                            cursor: 'pointer',
                          }}
                        />
                        <button
                          className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150"
                          type="button"
                        >
                          <i className="fas fa-camera"></i>
                          &nbsp;&nbsp;&nbsp;Upload Image
                        </button>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '20%',
                        }}
                      >
                        <div style={{ marginBottom: '10px' }}>
                          <SportsDropDown onChange={onSportChange} />
                        </div>
                        <div style={{ margin: '10px 0' }}>
                          <FilteredLeagueDropDown onChange={onLeagueChange} sports={selectedSports}/>
                        </div>
                        
                      </div>
                      <div>
                        <span>Is Home Tagged</span>
                        <Checkbox
                          checked={isHomeChecked}
                          onChange={homeCheckHandler}
                          color="default"
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                      </div>
                      <div>
                        <span>Is Published</span>
                        <Checkbox
                          checked={isPublished}
                          onChange={isPublishedHandler}
                          color="default"
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => uploadInsightHandler()}
                        style={{ marginTop: '30px' }}
                      >
                        Submit
                      </Button>
                      <hr className="my-4 md:min-w-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedLeague: state.leaguesReducer.selectedLeague,
  };
};
export default connect(mapStateToProps)(UploadGraphics);
