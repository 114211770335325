import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { GetUsersProductivityDetailVideos } from '../../../handlers/productivity.handlers';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { _dateTimeFormat } from '../../../constants/appDefaults';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import {
  dispatchEndDateForVideos,
  dispatchStartDateForVideos,
} from '../../../redux/action-dispatchers/productivity.dispatchers';
const ProductivityDetailVideos = (props) => {
  const history = useHistory();
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { email } = useParams();
  var date = new Date();
  date.setMonth(date.getMonth() - 1);
  // Zero the time component
  date.setHours(0, 0, 0, 0);
  const getProductivityReport = () => {
    if (props.videosEndDate < props.videosStartDate) {
      alert('Start Date should be less than end date');
      dispatchStartDateForVideos(date);
      dispatchEndDateForVideos(new Date());
    } else {
      const body = {
        email: email,
        page: page,
        limit: 10,
        start_date: props.videosStartDate,
        end_date: props.videosEndDate,
      };

      GetUsersProductivityDetailVideos(body)
        .then((response) => {
          setDocs(response.data.docs);
          setTotalPages(response.data.totalPages);
        })
        .catch((error) => error);
    }
  };
  useEffect(() => {
    getProductivityReport();
  }, [props.videosStartDate, props.videosEndDate, page]);
  const updateStartDate = (time) => {
    var date = new Date(time),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let formattedDate = [date.getFullYear(), mnth, day].join('-');
    dispatchStartDateForVideos(formattedDate);
  };
  const updateEndDate = (time) => {
    var date = new Date(time),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let formattedDate = [date.getFullYear(), mnth, day].join('-');
    dispatchEndDateForVideos(formattedDate);
  };
  const handleBack = () => {
    history.goBack();
  };
  const handlePagination = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                'bg-white'
              }
              style={{ height: '100%' }}
            >
              <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                <h3
                  className={'font-semibold text-lg' + 'text-blueGray-700'}
                  style={{
                    display: 'flex',
                    paddingTop: '10px',
                  }}
                >
                  Productivity Detail of{' '}
                  <span
                    style={{ color: '#21cc21', padding: '0 10px' }}
                  >{` ${email} `}</span>
                  for Videos{' '}
                </h3>
                <hr className="my-4 md:min-w-full" />
                <div className="wrapper">
                  <div
                    style={{
                      cursor: 'pointer',
                      marginLeft: '20px',
                      width: '100px',
                      display: 'flex',
                      alignItems: 'center',
                      jusifyContent: 'center',
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIcon />
                    Back
                  </div>

                  <div className="author_name">{email}</div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="relative" style={{ paddingLeft: '50%' }}>
                      <DatePicker
                        label="Start Date"
                        autoOk
                        hideTabs
                        format="dd/MM/yyyy"
                        disableFuture={true}
                        value={props.videosStartDate}
                        onChange={updateStartDate}
                        style={{
                          paddingLeft: '5px',
                          marginRight: '10px',
                          width: '40%',
                          border: '2px solid grey',
                          borderBottom: '1.5px solid gray',
                          borderRadius: '5px',
                        }}
                      />

                      <DatePicker
                        label="End Date"
                        // emptyLabel="End Date"
                        autoOk
                        hideTabs
                        format="dd/MM/yyyy"
                        disableFuture={true}
                        value={props.videosEndDate}
                        onChange={(e) => updateEndDate(e)}
                        style={{
                          paddingLeft: '5px',
                          marginRight: '10px',
                          width: '40%',
                          border: '2px solid grey',
                          borderBottom: '1.5px solid gray',
                          borderRadius: '5px',
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex justify-center w-full overflow-x-auto py-4 ">
                  {/* Projects table */}
                  <table
                    className="table-fixed"
                    style={{ background: '#6cf97d52' }}
                  >
                    <thead>
                      <tr>
                        <th
                          className={
                            'px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ' +
                            'bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading'
                          }
                        >
                          S No.
                        </th>
                        <th
                          className={
                            'px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ' +
                            'bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading'
                          }
                        >
                          Video Title
                        </th>
                        <th
                          className={
                            'px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ' +
                            'bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading'
                          }
                        >
                          Views
                        </th>

                        <th
                          className={
                            'px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ' +
                            'bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading'
                          }
                        >
                          Published At
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {docs?.map((item, index) => (
                        <tr>
                          <td className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data_title">
                            {index + 1}
                          </td>
                          <td
                            className={
                              'border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ' +
                              'viewCount_item'
                            }
                          >
                            {item.title}
                          </td>
                          <td
                            className={
                              'border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ' +
                              'viewCount_container'
                            }
                          >
                            <td className="viewCount_wrapper">
                              App{' '}
                              <span className="viewCount">
                                {item.viewCount.app}
                              </span>
                            </td>
                            <td className="viewCount_wrapper">
                              Msite{' '}
                              <span className="viewCount">
                                {item.viewCount.msite}
                              </span>
                            </td>
                            <td className="viewCount_wrapper">
                              Website{' '}
                              <span className="viewCount">
                                {item.viewCount.website}
                              </span>
                            </td>
                            <td className="viewCount_wrapper">
                              Others{' '}
                              <span className="viewCount">
                                {item.viewCount.other}
                              </span>
                            </td>
                            <td className="viewCount_wrapper">
                              CMS{' '}
                              <span className="viewCount">
                                {item.viewCount.cms}
                              </span>
                            </td>
                            <td className="viewCount_wrapper">
                              Total{' '}
                              <span className="viewCount">
                                {item.viewCount.total}
                              </span>
                            </td>
                          </td>
                          <td className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data">
                            {_dateTimeFormat(item.date, 'ddd, DD MMM hh:mm A')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: 20,
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    page={page}
                    onChange={handlePagination}
                    count={totalPages}
                    variant="outlined"
                    color="primary"
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    videosStartDate: state.productivityReducer.videosStartDate,
    videosEndDate: state.productivityReducer.videosEndDate,
  };
};
export default connect(mapStateToProps)(ProductivityDetailVideos);
