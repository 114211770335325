import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { API_BASE_PATH, OLD_BASE_PATH } from '../../constants/workerConstants';

import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatchSelectedLeague } from '../../redux/action-dispatchers/leagues.dispatchers';
import LoginAppConstants from '../../constants/LoginAppConstants';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const LeaguesDropDown = (props) => {
  const [docs, setDocs] = useState([]);

  const getTopLeagues = () => {
    Axios.get(`${API_BASE_PATH}leagues/leagueWatchList`, {
      headers: { 'cms-access-token': accessToken, source: 'cms' },
    })
      .then(async (response) => {
        let leagueData = await response.data.data.data;
        setDocs(leagueData);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getTopLeagues();
  }, []);
  const onChange = (event) => {
    const foundObject = _.find(docs, ['comp_id', event.target.value]);

    props.onChange &&
      props.onChange(event.target.value, foundObject.optaUUID, foundObject._id);
    getTopLeagues();
    dispatchSelectedLeague({
      selectedLeague: event.target.value,
      selectedLeagueFilter: event.target.value,
      selectedLeagueId: foundObject._id,
    });
  };
  //
  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      value={props.leaguesReducer.selectedLeague}
      onChange={onChange}
    >
      <option value="All">Select League</option>
      {docs.map((league) => (
        <>
          <option value={league.comp_id}>
            {league.abbr == 'All' ? league.comp_id : league.abbr}
          </option>
        </>
      ))}

      {/* {props.leaguesReducer.leagues.map((league) => (
       league.abbr === "All" ? <></> : <option value={league.comp_id}>{league.abbr}</option>
      ))} */}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    leaguesReducer: state.leaguesReducer,
    selectedLeagueFilter: state.leaguesReducer.selectedLeagueFilter,
  };
};

export default connect(mapStateToProps)(LeaguesDropDown);
