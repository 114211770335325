import React, { useEffect, useState } from 'react';
import LeaguesDropDown from '../../../components/Dropdowns/LeaguesDropDown';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';
import { css } from '@emotion/core';
import SyncLoader from 'react-spinners/SyncLoader';
import { dispatchAllLeagues } from '../../../redux/action-dispatchers/leagues.dispatchers.js';
import {
  // GetAllShortsVideos,
  Change_OptaShorts_Status,
} from '../../../handlers/optashortshandlers';
import {
  ChangeShortsStatus
} from "../../../handlers/videos.handlers";
import VideoStatusDropDown from '../../../components/Dropdowns/VideoStatusDropdown';
import { GetAllShortsVideos } from '../../../handlers/videos.handlers';
import Axios from 'axios';
import { API_BASE_PATH } from '../../../constants/workerConstants';
import { Stack } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {
  dispatchCurrnetVideosPage,
  dispatchCurrnetVideosPageShorts,
  dispatchNumberOfVideosPages,
  dispatchNumberOfVideosPagesShorts,
} from '../../../redux/action-dispatchers/videos.dispatcher';
import LoginAppConstants from '../../../constants/LoginAppConstants';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const OptaShorts = (props) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [isPlaying, setIsplaying] = useState(false);
  const [docs, setDocs] = useState([]);
  const [page, setPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [limit, SetLimit] = React.useState(10);
  const [videoStatusType, setVideoStatusType] = useState('all');
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [togglingStatus, setTogglingStatus] = useState({
    index: 0,
    loading: false,
  });
  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  const mHistory = useHistory();
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };
  useEffect(() => {
    getOptaShorts(parseInt(page), parseInt(limit), videoStatusType);
  }, [props.pageShorts, videoStatusType, props.selectedLeague]);
  //console.log(props.page)
  //let userInfo=JSON.parse(localStorage.getItem('userData'));
  const getOptaShorts = (page, _limit, _selectedLeague, isLoadMore) => {
    //console.log('page', page, isLoadMore);
    setIsLoading(true);
    const body = {
      page: props.pageShorts,
      limit: limit,
      league: props.selectedLeague,
      status:videoStatusType=="all"?"all":(videoStatusType=="true"?true:false),
    };
    GetAllShortsVideos(body, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setIsLoading(false);
        setDocs(response.data.docs);
        setPage(response.data.page);
        setHasNextPage(response.data.hasNextPage);
        dispatchNumberOfVideosPagesShorts(response.data.totalPages);
        if (isLoadingMore) {
          setDocs([...docs, ...response.data.docs]);
        } else {
          setDocs(response.data.docs);
        }
      })
      .catch((error) => {
        isLoadingMore && setIsLoadingMore(false);
      });
  };

  const toggleVideoStatus = (index, id) => {
    setTogglingStatus({
      index,
      loading: true,
    });
    ChangeShortsStatus(id,0, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setTogglingStatus({
          index: -1,
          loading: false,
        });
        if (response.status) {
          getOptaShorts(1, 10, 1);
        } else {
          alert(JSON.stringify(response.message));
        }
      })
      .catch((error) => {
        //console.log(error)
        alert(JSON.stringify(error));
      });
  };

  const handlePagination = (event, value) => {
    dispatchCurrnetVideosPageShorts(value);
  };
  useEffect(() => {
    Axios.get(
      `${API_BASE_PATH}leagues/leagueWatchList`,
      { headers: { 'cms-access-token': accessToken, source: 'cms' } },
      {
        params: JSON.stringify({
          username: localStorage.getItem('email'),
        }),
      }
    ).then((res) => {
      //
      dispatchAllLeagues(res.data.data.data);
    });
  }, []);
  //Media popup

  const getVideoTableItem = (video, position) => {
    if (video.media.content.length > 0) {
      const videoUrl = video.media.content[0].url;

      const height = video.media.content[1]
        ? video.media.content[1].height
        : video.media.content[0].height;
      const width = video.media.content[1]
        ? video.media.content[1].width
        : video.media.content[0].width;
      const thumbnail =
        video.media.thumbnail && video.media.thumbnail[0]
          ? video.media.thumbnail[0].url
          : '';
      // video.media.thumbnail[video.media.thumbnail.length - 1].url;

      return (
        <tr>
          <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
            <img
              src={thumbnail}
              style={{
                maxHeight: 100,
                minHeight: 53,
                maxWidth: 53,
                minWidth: 100,
              }}
              height={300}
              width={400}
              alt="..."
              onClick={() => onClickOpenMedia(videoUrl, thumbnail)}
            ></img>
          </th>
          {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.copyright}
          </td>
          <td
            className="border border-green-800 border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            style={{ fontWeight: '500' }}
          >
            {video.title}
          </td>

          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.duration}
          </td> */}
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {leagueName(video.league[0])}
          </td>

          <td
            onClick={() => onView(video._id)}
            style={{ cursor: 'pointer', fontWeight: '500' }}
            className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {_dateTimeFormat(video.lastUpdate, 'ddd, DD MMM hh:mm A')}
          </td>

          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.status == 1 ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>
                Published
              </span>
            ) : (
              <span style={{ color: 'red', fontWeight: 'bold' }}>Draft</span>
            )}
          </td>

          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
            <div className="flex">
              {video.status == 1 ? (
                <button
                  className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  onClick={() => {
                    toggleVideoStatus(position, video._id);
                    // onView(video._id)
                  }}
                >
                  Disable
                </button>
              ) : (
                <button
                  className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  onClick={() => toggleVideoStatus(position, video._id)}
                >
                  Publish
                </button>
              )}
                <button
                  className={`bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  onClick={() => onView(video._id)}
                >
                  Edit
                </button>
            </div>
          </td>
          
        </tr>
      );
    } else {
      return <></>;
    }
  };
  const onView = (videoId) => {
    mHistory.push(`/shorts/${videoId}`);
  };
  //console.log(props.ytdocs)
  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '55%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showEditHandler(false);
                setIsplaying((isPlaying) => !isPlaying);
                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={isPlaying}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className="rounded-t mb-0 px-2 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  'font-semibold text-lg ' +
                  (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {'Opta Shorts'}
                <div
                  className="relative w-full  max-w-full flex-grow flex-1"
                  style={{ textAlign: 'right' }}
                >
                  {/* <span style={{ marginLeft: 5 }}>
                    <LeaguesDropDown />
                  </span> */}
                  <span style={{ marginLeft: 5 }}>
                    <VideoStatusDropDown
                      value={videoStatusType}
                      onChange={(event) =>
                        setVideoStatusType(event.target.value)
                      }
                    />
                  </span>
                </div>
              </h3>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                position: 'relative',
                left: '3.4%',
              }}
            >
              <div className="wrapper"></div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Shorts Video
                </th>
                {/* <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Copyright
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Title
                </th>
                
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Duration
                </th> */}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Leagues
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Published Date
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Status
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            {!isLoading ? (
              GetAllShortsVideos
            ) : (
              <SyncLoader color="#23A5E0" css={override} size={12} />
            )}
            <tbody>
              {docs.map((video, position) =>
                getVideoTableItem(video, position)
              )}
            </tbody>
          </table>
          {/* {hasNextPage && !isLoadingMore && (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  getOptaShorts(parseInt(page) + 1, parseInt(limit), true);
                }}
              >
                Load More
              </button>
            </div>
          )} */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={props.pageShorts}
                onChange={handlePagination}
                count={props.totalPagesShorts}
                variant="outlined"
                color="primary"
              />
            </Stack>
          </div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageShorts: state.videosReducer.pageShorts,
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedSports: state.sportsReducer.selectedSports,
    totalPagesShorts: state.videosReducer.totalPagesShorts,
  };
};
export default connect(mapStateToProps)(OptaShorts);
