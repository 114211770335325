import React from 'react';

const NewsTypeDropDown = (props) => {
  // if (localStorage.getItem('lang') === 'hindi') {

  // } else {

  // }
  if (localStorage.getItem('Current_Language')?.toLowerCase() === 'hindi') {
    return (
      <select
        style={{
          // marginLeft: 20,
          border: '2px solid #acb9ce',
          borderRadius: 10,
        }}
        onChange={props.onChange}
        value={props.selectedValue}
      >
        <option>Select News Type</option>
        <option value="All">All</option>
        <option vlaue="SportsTak">SportsTak</option>
        <option value="PTI_Bhasha">PTI Bhasha</option>
        <option value="PTI">PTI NEWS</option>
        <option value="AajTak">AajTak</option>
      </select>
    );
  } else {
    return (
      <select
        style={{
          // marginLeft: 20,
          border: '2px solid #acb9ce',
          borderRadius: 10,
        }}
        onChange={props.onChange}
        value={props.selectedValue}
      >
        <option value="All">Select News Type</option>
        <option value="All">All</option>
        <option value="PTI">PTI NEWS</option>
        <option vlaue="SportsTak">SportsTak</option>
        <option value="Opta">Opta news</option>
        <option value="India Today">India Today</option>
      </select>
    );
  }
  // return (
  //   <select
  //     onChange={props.onChange}
  //     value={props.selectedValue}
  //     id="select_lang"
  //   >
  //     <option value="0">Select News Type</option>
  //     <option value="All">All</option>
  //     <option value="PTI_Bhasha">PTI Bhasha</option>
  //     <option value="PTI">PTI NEWS</option>
  //     <option value="Opta">Opta news</option>
  //   </select>
  // );
};

export default NewsTypeDropDown;
