import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SportsTaggingDropdown from "../../../components/Dropdowns/SportsTaggingDropdown";

import LeagueDropdownTagging from "../../../components/Dropdowns/LeagueDropdownTagging";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeagueTagging,
} from "../../../redux/action-dispatchers/leagues.dispatchers";
import { dispatchSelectedSportsTagging } from "../../../redux/action-dispatchers/sports.dispatchers";
import { WithContext as ReactTags } from "react-tag-input";
import Multiselect from "multiselect-react-dropdown";
import UnsavedChangesWarning from "../../../components/AlertModal/UnsavedChangesWarning";
import { connect } from "react-redux";
import { GetAllTagLeagues } from "../../../handlers/leagues.handlers";
import { Button } from "@material-ui/core";
import {
  AddOrEditTwitterContent,
  GetTwitterSuggestions,
} from "../../../handlers/twitter.handlers";
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const CreateTwitterContent = (props) => {
  const history = useHistory();
  // Tag manual or automatic
  const[showTag, setShowTag] = useState('');

  const [changeLeague, setChangeLeague] = useState(false);
  const [twitterHandlesTag, setTwitterHandlesTag] = useState([]);
  const [orKeywordsTag, setorKeywordsTag] = useState([]);
  const [ANDKeywordsTag, setANDKeywordsTag] = useState([]);
  const [NOTKeywordsTag, setNOTKeywordsTag] = useState([]);
  const [changeSport, setChangeSport] = useState(false);
  const [sportsName, setSportsName] = useState("");
  const [twitterHandles, setTwitterHandles] = useState([]);
  const [ORkeywords, setORkeywords] = useState([]);
  const [ANDkeywords, setANDkeywords] = useState([]);
  const [NOTkeywords, setNOTkeywords] = useState([]);
  // multiselect
  const [options, setOptions] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [twitterSuggestion, setTwitterSuggestion] = useState([]);
  const [twitterSuggestionTag, setTwitterSuggestionTag] = useState([]);
  const [addedTwitterHandles, setAddedTwitterHandles] = useState([]);
  // multiselect end
  const [formData, setFormData] = useState({
    title: "",
    comp_id: "",
    sportName: "",
    tag: "",
    id: "",
  });

  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const handleBack = () => {
    history.goBack();
  };
  const onSportSelected = (value) => {
    const foundObject = _.find(props.sportsReducer.sports, ["_id", value]);

    setFormData({ ...formData, sportName: value });
    setChangeSport(false);
    setIsUnsaved();
    setSportsName(foundObject.name);
    dispatchSelectedSportsTagging({
      selectSports: value,
      selectedSportsTagging: value,
    });
    dispatchAllLeaguesTag([]);
    dispatchSelectedLeagueTagging({selectedLeagueTag: "" });
    // getAllTaggedLeague();
  };
  const onLeagueSelected = (value) => {
    setFormData({ ...formData, comp_id: value });
    setChangeLeague(false);
    setIsUnsaved();
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: value,
      selectedLeagueFilterTag: value,
    });
  };
  const getAllTaggedLeague = () => {
    GetAllTagLeagues(
      props.selectSports,
      props.leagueSearch == "" ? "search" : props?.leagueSearch
    )
      .then((response) => {
        if (response.status) {
          dispatchAllLeaguesTag(response?.data);
        } else {
          dispatchAllLeaguesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllLeaguesTag([]);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getAllTaggedLeague();
  }, [props.leagueSearch, props.selectSports]);
  const onFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event?.target?.value });
    setIsUnsaved();
  };
  const handleSubmit = () => {
    const body = {
      title: formData?.title,
      twitterHandles: addedTwitterHandles,
      // twitterHandles: twitterHandles,
      ORkeywords: ORkeywords,
      ANDkeywords: ANDkeywords,
      NOTkeywords: NOTkeywords,
      comp_id: formData?.comp_id,
      sportName: sportsName,
      tag: formData?.tag,
      id: "",
    };

    if (formData.title == "") {
      alert("Please enter Title");
    } else if (
      addedTwitterHandles?.length == 0 &&
      ANDkeywords?.length == 0 &&
      NOTkeywords?.length == 0
    ) {
      alert(
        "Please add either Accounts or AND contain field or Not contain feild"
      );
    } else {
      AddOrEditTwitterContent(body)
        .then((response) => {
          console.log("r", response);
          
          if (
            response?.data?.message ==
            "Length of twitterRule created is greater that 512 . Please make changes"
          ) {
            alert(response.data.message);
            // return;
          } else if (response?.status) {
            alert("Success");
            history.push("/twitterList");
            
          } 
          else if (response?.message?.message == "Internal server error"){
             if(response?.message?.errors?.keyPattern?.tag == 1){
              alert("Don't use the same tag")
            }
            else if (response?.message?.errors?.keyPattern?.searchTxt == 1){
              alert("Selected Combination of Accounts is incorrect");
            }
          }
          
            else{
            alert("Do not repeate already created wall accounts/handles..");
          }
        })
        .catch((error) => error);
    }
  };
  //Account tags
  const handleDelete = (i) => {
    setTwitterHandles(twitterHandles.filter((tag, index) => index !== i));
    setTwitterHandlesTag(twitterHandlesTag.filter((tag, index) => index !== i));
  };
  const handleAddition = (tag) => {
    setTwitterHandles([...twitterHandles, tag?.text]);
    setTwitterHandlesTag([...twitterHandlesTag, tag]);
  };
  // multiSelect
  const handleDeleteSuggestion = (i) => {
    setTwitterSuggestion(twitterSuggestion.filter((tag, index) => index !== i));
    setTwitterSuggestionTag(
      twitterSuggestionTag.filter((tag, index) => index !== i)
    );
  };
  const handleAdditionSuggestion = (tag) => {
    setSearchKey("");
    setTwitterSuggestion(tag.map((name) => name?.name));
    setTwitterSuggestionTag([...twitterSuggestionTag, tag]);
  };
  // multiSelect end
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = twitterHandlesTag.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {};

  //Account tags end

  //Hashtags or keywords tags
  const handleDeleteORKeywords = (i) => {
    setORkeywords(ORkeywords.filter((tag, index) => index !== i));
    setorKeywordsTag(orKeywordsTag.filter((tag, index) => index !== i));
  };
  const handleAdditionORKeywords = (tag) => {
    setORkeywords([...ORkeywords, tag?.text]);
    setorKeywordsTag([...orKeywordsTag, tag]);
  };
  const handleDragORKeywords = (tag, currPos, newPos) => {
    const newTags = orKeywordsTag.slice();

    newTags?.splice(currPos, 1);
    newTags?.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClickORKeywords = (index) => {};

  //Hashtags or keywords end

  //ANDKeywords tags
  const handleDeleteANDKeywords = (i) => {
    setANDkeywords(ANDkeywords.filter((tag, index) => index !== i));
    setANDKeywordsTag(ANDKeywordsTag.filter((tag, index) => index !== i));
  };
  const handleAdditionANDKeywords = (tag) => {
    setANDkeywords([...ANDkeywords, tag.text]);
    setANDKeywordsTag([...ANDKeywordsTag, tag]);
  };
  const handleDragANDKeywords = (tag, currPos, newPos) => {
    const newTags = ANDKeywordsTag.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClickANDKeywords = (index) => {};

  //ANDKeywords tags end

  //NOTKeywords tags
  const handleDeleteNOTKeywords = (i) => {
    setNOTkeywords(NOTkeywords.filter((tag, index) => index !== i));
    setNOTKeywordsTag(NOTKeywordsTag.filter((tag, index) => index !== i));
  };
  const handleAdditionNOTKeywords = (tag) => {
    setNOTkeywords([...NOTkeywords, tag.text]);
    setNOTKeywordsTag([...NOTKeywordsTag, tag]);
  };
  const handleDragNOTKeywords = (tag, currPos, newPos) => {
    const newTags = NOTKeywordsTag.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClickNOTKeywords = (index) => {};
  //NOTKeywords tags end
  // ṃultiSelect Suggestion API
  const getSuggestionForTwtter = () => {
    GetTwitterSuggestions(1, searchKey).then((response) => {
      setSuggestion(response.data.docs);
    });
  };
  useEffect(() => {
    getSuggestionForTwtter();
  }, [searchKey]);
  useEffect(() => {
    let suggestionArr = suggestion;
    suggestionArr = suggestionArr.map((str) => ({
      name: str.username,
      id: str._id,
    }));
    setOptions(suggestionArr);
  }, [suggestion]);
  useEffect(() => {
    let totalHandlesArr = [];

    setAddedTwitterHandles([...twitterHandles, ...twitterSuggestion]);
  }, [twitterSuggestion, twitterHandles]);
  // ṃultiSelect Suggestion API end

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 20 }}>
        <div className="flex flex-wrap">
          <div
            className=" pb-4"
            style={{
              cursor: "pointer",
              marginLeft: "-20px",
              width: "140px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleBack}
          >
            <ArrowBackIcon />
            <h1>Twitter List</h1>
          </div>
          <div className="w-full xl-12 mb-12 xl:mb-0 px-1">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                "bg-white"
              }
              style={{ height: "100%", width: "70vw" }}
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap flex-col">
                  <h1>Add Twitter Content</h1>
                  <hr className="my-4 md:min-w-full" />
                  <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>*Title:</h1>
                      <input
                        type="text"
                        placeholder="Title"
                        value={formData.title}
                        name="title"
                        onChange={onFormDataChange}
                      />
                    </div>
                  </div>
                  <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>Accounts:</h1>

                      <ReactTags
                        tags={twitterHandlesTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        handleTagClick={handleTagClick}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                      <div style={{ marginTop: "20px" }}>
                        <Multiselect
                          onSearch={(e) => setSearchKey(e)}
                          options={options} // Options to display in the dropdown
                          selectedValues={selectedValue} // Preselected value to persist in dropdown
                          onSelect={handleAdditionSuggestion} // Function will trigger on select event
                          onRemove={handleDeleteSuggestion} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                    </div>
                    {/* <div className="createTwitterContent-input-container">
                      {' '}
                      <h1>HashTags or Keywords:"</h1>
                      <ReactTags
                        tags={orKeywordsTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDeleteORKeywords}
                        handleAddition={handleAdditionORKeywords}
                        handleDrag={handleDragORKeywords}
                        handleTagClick={handleTagClickORKeywords}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                    </div> */}
                  </div>
                  <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>And contains these items:</h1>
                      <ReactTags
                        tags={ANDKeywordsTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDeleteANDKeywords}
                        handleAddition={handleAdditionANDKeywords}
                        handleDrag={handleDragANDKeywords}
                        handleTagClick={handleTagClickANDKeywords}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                    </div>
                    <div className="createTwitterContent-input-container">
                      {" "}
                      <h1>Does not contain these items:</h1>
                      <ReactTags
                        tags={NOTKeywordsTag}
                        // suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDeleteNOTKeywords}
                        handleAddition={handleAdditionNOTKeywords}
                        handleDrag={handleDragNOTKeywords}
                        handleTagClick={handleTagClickNOTKeywords}
                        inputFieldPosition="bottom"
                        autocomplete
                      />
                    </div>
                  </div>

              <div className="createTwitterContent-embed-container">
              <div className="createTwitterContent-input-container">
              <div className="col-lg-12" >
                <h1>Want to add Tags manually</h1>
                <button
                style={{marginLeft:'10px'}}
                  onClick={() => {
                    setShowTag('True')
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setShowTag('False')
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  style={{ backgroundColor: "red", marginLeft: "10px" }}
                >
                  No
                </button>
              </div>
              </div>
              </div>

              {/** To show automatic tagging or manual tagging*/}
                  {showTag == 'True' && 
                  <div className="createTwitterContent-embed-container">
                    <div className="createTwitterContent-input-container">
                      <h1>Tag</h1>
                      <input
                        type="text"
                        placeholder="Add Tag"
                        value={formData?.tag}
                        name="tag"
                        onChange={onFormDataChange}
                      />
                    </div>
                  </div>
                  }
                  {showTag == 'False' && 
                  <div style={{ marginLeft: "30px" }}>
                  <h1>Sports and League Tag</h1>
                  <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <SportsTaggingDropdown onChange={onSportSelected} />
                  </div>
                  {props.selectSports && (
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <LeagueDropdownTagging onChange={onLeagueSelected} />
                    </div>
                  )}
                </div>}
                  
                  {/* <hr className="my-4 md:min-w-full" /> */}
                  


                  <hr className="my-4 md:min-w-full" />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "100px", justifySelf: "center" }}
                      onClick={handleSubmit}
                    >
                      submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectSports: state.sportsReducer.selectSports,
    leagueSearch: state.leaguesReducer.leagueSearch,
    sportsReducer: state.sportsReducer,
  };
};
export default connect(mapStateToProps)(CreateTwitterContent);
