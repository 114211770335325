import { useState } from 'react';
import { addWhatsNews } from "../../../../handlers/whatsNew.handler"
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { API_BASE_PATH } from "../../../../constants/workerConstants";
const API_URL = API_BASE_PATH;
const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
import LoginAppConstants from "../../../../constants/LoginAppConstants";
const UPLOAD_ENDPOINT = "whatsNew/uploadArticleStoryImage";
import {  useHistory } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import Axios from "axios";
import { css } from "@emotion/core";
const AddWhatsNew = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [version, setVersion] = useState("");
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [previewModal, setPreviewModal] = useState({
        show: false,
        imageData: "",
        file: "",
    });
    const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
    const history = useHistory();
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        var reader = new FileReader();
                        reader.readAsDataURL(file);

                        reader.onload = () => {
                            Axios.post(
                                `${API_URL}/${UPLOAD_ENDPOINT}`,
                                { file: reader.result },
                                {
                                    headers: { "cms-access-token": accessToken, source: "cms" },
                                }
                            )
                                
                                .then((res) => {
                                    resolve({
                                        default: `${res.data.data}`,
                                    });
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        };
                    });
                });
            },
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }
    const onImageFileSelect = (event) => {
        let file = event.target.files[0];
        event.target.value = "";
        let reader = new FileReader();

        // Convert the file to a data URL and process it after loading
        reader.readAsDataURL(file);
        reader.onload = () => {
            let image = new Image();
            image.src = reader.result; // This is now guaranteed to have a value

            image.onload = function () {
                let height = this.height;
                let width = this.width;
                if (height === 960 && width === 540) {
                    setPreviewModal({
                        imageData: reader.result,
                        show: true,
                        file: file,
                    });
                } else {
                    alert("Image width and height must be 540X960 px");
                    return false;
                }
            };
            setImage(reader.result);
        };

        reader.onerror = function (error) {
            console.error('Error occurred while reading file:', error);
        };
    };


    const sendData = ()=>{
        
        if(title == ""){
            alert("Please enter title");
            return;
        }
        if (description == "") {
            alert("Please enter description");
            return;
        }
        if (image == null) {
            alert("Please enter image");
            return;
        }
        if (version == "") {
            alert("Please enter version");
            return;
        }
        let obj = {
            title:title,
            description:description,
            thumbnail:image,
            version:version
        }
        setIsLoadingMore(true);
        addWhatsNews(obj)
            .then((response) => { 
                
                if(response){
                    
                    alert("Whats New Added Successfully");
                    history.push("/whatsNew");
                    setIsLoadingMore(false);
                }
            })
            .catch((error) => { console.log(error); setIsLoadingMore(false); });
        
    }
    return (
        <>
            <Sidebar />
            
            <div className="relative md:ml-64" style={{ padding: 50, display: 'block' }}>
                <div >
                    <label style={{ fontSize: "24px" }}>All Fields are mandatory:</label>
                </div>
                <hr style={{ margin: "10px", borderTop: "1px solid black" }}/>
                <div className="flex flex-wrap">
                    <div className="w-full xl-12 mb-8 px-4">
                        <label className="addArticle_label">Release Title *</label>
                        <br></br>
                        <input style={{ marginTop: "10px" }} value={title} onChange={(e) => {
                            const inputValue = e.target.value;
                            const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\s |:;,\.!?'"“()]/g,
                                ""
                            );
                            setTitle(formattedValue);
                        }} type="text" placeholder='Title'/>
                    </div>
                    <div className="w-full xl-12 mb-8 px-4">
                        <label style={{fontWeight:"800",marginBottom:"10px"}}>What's New Content *</label>
                        <br></br>
                        <CKEditor 
                            
                            onChange={(event, editor) => {
                                setDescription(editor?.getData());
                            }}
                            editor={Editor}
                            config={{
                                extraPlugins: [uploadPlugin],
                                height: 500,
                                link: {
                                    addTargetToExternalLinks: true,
                                    defaultProtocol: "https://",
                                    decorators: {
                                        toggleDownloadable: {
                                            mode: "manual",
                                            label: "Downloadable",
                                            attributes: {
                                                download: "file",
                                            },
                                        },
                                        openInNewTab: {
                                            mode: "manual",
                                            label: "Open in a new tab",
                                            defaultValue: true,
                                            attributes: {
                                                target: "_blank",
                                                rel: "noopener noreferrer",
                                            },
                                        },
                                    },
                                },
                                customStyles: {
                                    ul: {
                                        marginLeft: '20px', // Adjust the padding value as needed
                                    },
                                },
                            }}
                            />
                    </div>
                    <div className="w-full xl-12 mb-8 px-4">
                        <label className="addArticle_label">Please upload image in the dimension of 540x960 *</label>
                        <br></br>
                        <div>
                            {previewModal.imageData && (
                                <img
                                    src={previewModal.imageData}
                                    style={{
                                        width: 300,
                                        height: 500,
                                        border: "2px solid gray",
                                    }}
                                />
                            )}
                            <input
                                type="file"
                                multiple={false}
                                accept="image/x-png,image/jpeg"
                                name="file"
                                onChange={onImageFileSelect}
                                style={{
                                    backgroundColor: "#000",
                                    height: 60,
                                    width: 60,
                                    position: "absolute",
                                    opacity: 0,
                                    cursor: "pointer",
                                }}
                            />

                            <i
                                className="fas fa-camera fa-2x"
                                style={{
                                    margin: 8,
                                    color: "#555",
                                    cursor: "pointer",
                                }}
                            ></i>
                        </div>
                    </div>
                    <div className="w-full xl-12 mb-8 px-4">
                        <label className="addArticle_label" >App Version *</label>
                        <br></br>
                        <input style={{ marginTop: "10px" }} value={version} onChange={(e) => {
                            const inputValue = e.target.value;
                            const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\s .]/g,
                                ""
                            ); setVersion(formattedValue)}} type="text" placeholder='version' />
                    </div>
                    <div className="w-full xl-12 mb-8 px-4">
                        <button style={{ marginBottom: '1.5%', height: "32px", cursor: "pointer", width: "100px", borderRadius: "10px", backgroundColor: "#50C878", color: "white" }} onClick={sendData}>Submit</button>
                    </div>
                </div>
                <div style={{ textAlign: "center", padding: 10 }}>
                    <SyncLoader
                        color="#23A5E0"
                        loading={isLoadingMore}
                        css={override}
                        size={12}
                    />
                </div>
            </div>
        </>
        )
}

export default AddWhatsNew;