import AppConstants from '../constants';
import LoginAppConstants from '../constants/LoginAppConstants';
import AxiosInstance from './http.handlers';

const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = {
  'cms-access-token': accessToken,
  source: 'cms',
  language: CURRENT_LANGUAGE,
};
// export const GetAllVideos = async (
//   pageNumber,
//   noOfRecords,
//   selectedLeagues,
//   showVideo,
//   sprotsId,
//   headers
// ) => {
//   return await AxiosInstance.get(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_PAGINATED}/${pageNumber}/${noOfRecords}/${selectedLeagues}/${showVideo}/${sprotsId}`,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

export const GetAllVideos = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_PAGINATED}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetAllYoutubeVideos = async (
  pageNumber,
  noOfRecords,
  selectedLeagues,
  copyright,
  search,
  newsStatus,
  sports,
  headers
) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_YOUTUBE_PAGINATED}/${pageNumber}/${noOfRecords}/${selectedLeagues}/${copyright}/${search}/${newsStatus}/${sports}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const mapVideosToMatches = async (match_id, _id, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.MAP_VIDEOS_TO_MATCHES}`,
    {
      match_id,
      _id,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const mapVideosToTeams = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.MAP_VIDEOS_TO_TEAMS}`,

    body,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetAllShortsVideos = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_SHORT_TABLE}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetVideosInfo = async (id, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.VIDEOS_INFO}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetShortsVideosInfo = async (id, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.SHORTS_VIDEO_INFO}/${id}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetYTShorts = async (status, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_YT_SHORTS}/1/10/${status}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const Get_All_S3_Videos = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_TRANSCODED_VIDEOS_TO_UPLOAD}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const Get_All_Shorts_Videos = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_SHORTS_LIST_TO_UPLOAD}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const updateInfo = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPDATE_INFO}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const uploadShortsVideoThumbnail = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPDATE_SHORTS_THUMBNAIL}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const Upload_S3_Videos = async (body, onUploadProgress, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPLOAD_TRANSCODED_VIDEO}`,
    body,
    onUploadProgress,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const Upload_Shorts_Videos = async (body, onUploadProgress, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPLOAD_SHORTS_VIDEOS}`,
    body,
    onUploadProgress,
    {
      'Content-Type': 'multipart/form-data',
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const Change_YTShorts_Status = async (id, status, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_STATUS}`,
    {
      id,
      status,
    },
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const mapVideosToLeague = async (_id, league, uuid,isAll, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.MAP_VIDEOS}`,
    {
      _id,
      league,
      uuid,
      isAll,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const mapShortsVideosToLeague = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TAG_SHORT_LEAGUE}`,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const tagHome = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TAG_VIDEO_TO_HOME}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getHomeVideo = async (pageNumber, noOfRecords) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_HOME_VIDEO}`
  )
    .then((response) => response)
    .catch((error) => error);
};
export const tagHighlight = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TAG_HIGHLIGHTS}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetHighlights = async (page) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_HIGHLIGHTS}/${page}/35`
  )
    .then((response) => response)
    .catch((error) => error);
};
export const tagVideoToTop = async (data) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TAG_VIDEO_TO_TOP}`,
    data,

    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const tagTeams = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TAG_VIDEO_TO_TEAMS}`,

    body,
    { headers }
  );
};
export const removeTeams = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.REMOVE_TAGGED_TEAMS}`,
    body,
    { headers }
  );
};
export const mapVideosToSports = async (_id, sportsId, uuid) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPDATE_SPORTS}`,
    {
      _id,
      sportsId,
      uuid,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const mapShortsToSports = async (_id, sportsId) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TAG_SHORT_SPORTS}`,
    {
      _id,
      sportsId,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

//sports Start
export const GetallSports = async () => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.SPORTS_VIDEOS}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const sports = async (_id, name) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.SPORTS_VIDEOS}`,
    {
      _id,
      name,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetTopVideos = async (section, pageNumber, noOfRecords) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_TOP_VIDEOS}/${section}/${pageNumber}/${noOfRecords}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const updateVideosRanking = async (videoList) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPDATE_VIDEOS_RANKING}`,
    {
      videoList,
    },
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
//sports end

export const toggleVideosStatus = async (_id, showArticle, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_STATUS}`,
    {
      _id,
      showArticle,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const uploadVideoThumbnail = async (
  _id,
  thumbId,
  imageData,
  headers
) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPLOAD_VIDEO_THUMBNAIL}`,
    {
      _id,
      thumbId,
      file: imageData,
    },
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ChangeVideoStatus = async (id, status, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_STATUS}`,
    {
      id,
      status,
    },
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ChangeShortsStatus = async (id, status, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_OPTA_SHORTS}`,
    {
      id,
      status,
    },
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
// Tag News to Twitter Trends
export const tagTwitterTrends = async (body,headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TAG_VIDEOS_TO_TRENDING}`,
    
    body, {headers}
  )
  .then((res) => res)
  .catch((error) => error);
};

// Chat GPT Clickbait Title
export const generateClickbaitHeadline = async (body,headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.CLICKBAIT_HEADLINE}`,
    
    body, {headers}
  )
  .then((res) => res)
  .catch((error) => error);
};

//upload video

// video: videoData,
// thumbnail: previewModal.imageData,
// slug: keyWord,
// description: description,
// title: headline,
// copyright: videoType,
// league: props.selectedLeague,
// sportsId: props.selectedSports,
// thumbnailHeight: 1080,
// thumbnailWidth: 1092,
// status:status,
// uuid:uuid

export const uploadVideo = async (data, onUploadProgress, headers) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  // for (var key of formData.entries()) {
  // }
  // return;
  // const {
  //   thumbnail,
  //   slug,
  //   description,
  //   title,
  //   copyright,
  //   league,
  //   sportsId,
  //   thumbnailHeight,
  //   thumbnailWidth,
  //   status,
  //   uuid,
  // } = data;

  // upload video

  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPLOAD_VIDEO}`,
    formData,
    onUploadProgress,
    { headers, timeout: -1 }
  )
    .then((response) => response)
    .catch((error) => error);
};

// export const uploadVideo = async (data, onUploadProgress, headers) => {
//   return new Promise((resolve,reject)=>
//   {
//       const formData = new FormData();

//       Object.keys(data).forEach((key)

//      => {
//         formData.append(key, data[key]);
//       });

//       let response = await AxiosInstance.post(
//         `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPLOAD_VIDEO}`,
//         formData,
//         onUploadProgress,
//         { headers }
//       )
//         .then((response) => response)
//         .catch((error) => error);

//         resolve(response);
//         return;
//   })
// };
// import AppConstants from '../constants';
// import AxiosInstance from './http.handlers';

// export const GetAllVideos = async (
//   pageNumber,
//   noOfRecords,
//   selectedLeagues,
//   headers
// ) => {
//   return await AxiosInstance.get(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_PAGINATED}/${pageNumber}/${noOfRecords}/${selectedLeagues}`,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const GetVideosInfo = async (id, headers) => {
//   return await AxiosInstance.get(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.VIDEOS_INFO}/${id}`,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const mapVideosToLeague = async (_id, league, uuid, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.MAP_VIDEOS}`,
//     {
//       _id,
//       league,
//       uuid,
//     },
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const toggleVideosStatus = async (_id, showArticle, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_STATUS}`,
//     {
//       _id,
//       showArticle,
//     },
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const uploadArticleImage = async (_id, imageData, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPLOAD_ARTICLE_VIDEOS}`,
//     {
//       _id,
//       file: imageData,
//     },
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const ChangeVideoStatus = async (id, status, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_STATUS}`,
//     {
//       id,
//       status,
//     },
//     { headers }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// import AppConstants from '../constants';
// import AxiosInstance from './http.handlers';

// export const GetAllVideos = async (
//   pageNumber,
//   noOfRecords,
//   selectedLeagues,
//   headers
// ) => {
//   return await AxiosInstance.get(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_PAGINATED}/${pageNumber}/${noOfRecords}/${selectedLeagues}`,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const GetVideosInfo = async (id, headers) => {
//   return await AxiosInstance.get(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.VIDEOS_INFO}/${id}`,
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const mapVideosToLeague = async (_id, league, uuid, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.MAP_VIDEOS}`,
//     {
//       _id,
//       league,
//       uuid,
//     },
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const toggleVideosStatus = async (_id, showArticle, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_STATUS}`,
//     {
//       _id,
//       showArticle,
//     },
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const uploadArticleImage = async (_id, imageData, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.UPLOAD_ARTICLE_VIDEOS}`,
//     {
//       _id,
//       file: imageData,
//     },
//     {
//       headers,
//     }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };

// export const ChangeVideoStatus = async (id, status, headers) => {
//   return await AxiosInstance.post(
//     `${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.TOGGLE_STATUS}`,
//     {
//       id,
//       status,
//     },
//     { headers }
//   )
//     .then((response) => response)
//     .catch((error) => error);
// };
