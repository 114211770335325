import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _, { flatMap } from "lodash";
import PropTypes from "prop-types";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./LeagueManage.css";
import Axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox } from "@material-ui/core";
import moment from "moment";
import {
  _getDateTime,
  _dateTimeFormat,
} from "../../../../src/constants/appDefaults";
import {
  API_BASE_PATH,
} from "../../../constants/workerConstants";
import { GetAllComps, GetSuggestedLeagueList, UpdateLeagueType, UpdateRankOfSuggestedLeague } from "../../../handlers/leagues.handlers";
import LoginAppConstants from "../../../constants/LoginAppConstants";
import SportsDropDown from "../../../components/Dropdowns/SportsDropDown";
import { dispatchSelectedSports } from "../../../redux/action-dispatchers/sports.dispatchers";
import GptSection from "./GptSection";
import { Pagination } from "@mui/material";
import SportsWithoutReduxDropDown from "../../../components/Dropdowns/SportsWithoutReduxDropDown";
// import CancelIcon from '@mui/icons-material/Cancel';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);

let CURRENT_LANGUAGE = localStorage.getItem("Current_Language");
const headers = {
  "cms-access-token": accessToken,
  source: "cms",
  language: CURRENT_LANGUAGE,
};
const LeagueManagement = (props) => {
  const [leagueList, setLeagueList] = useState();
  const [page, setPage] = useState(1);
  const [suggestedLeagueList, setSuggestedLeagueList] = useState([]);
  const [showLeagueAdd, setShowLeagueAdd] = React.useState(false);
  const [showRankUpdate, setShowRankeUpdate] = useState(false);
  const [updatedDocs, setUpdatedDocs] = useState();
  const [updatedRank, setUpdatedRank] = useState([]);
  const [sportsFilter, setSportsFilter] = useState("all");
  const [listFilter, setListFilter] = useState("all");
  const [displayFilter, setDisplayFilter] = useState("all");
  const [sortByFilter, setSortByFilter] = useState("all");
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [showEdit, showEditHandler] = React.useState(false);
  const [showEditLeague, showEditLeagueBank] = React.useState(false);
  const [showLeagueEdit, setShowLeagueEdit] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [featuredLeague, setFeaturedLeague] = useState([]); // To show the featured League's banner on article details page
  const [toggleEdit, setToggleEdit] = useState(true);
  const [gptKeyword, setGptKeyword] = useState("");
  const [showGPT, setShowGPT] = useState(false);
  const [gptData, setGptData] = useState("");
  const [gptLoading, setGptLoading] = useState(false);

  // suggested league edit states
  const [leagueName, setLeagueName] = useState("");
  const [banerImageMain, setBanerImageMain] = useState("");
  const [compName, setCompName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startEditDate, setEditStartDate] = useState(null);
  const [endEditDate, setEditEndDate] = useState(null);
  const [startDateLeague, setLeagueStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDateLeague, setLeagueEndDate] = useState(null);
  const [abbr, setAbbr] = useState("");
  const [twitterFeed, setTwitterFeed] = useState("");
  const [liveBlogId, setLiveBlogId] = useState("");
  const [activeLeagueData, setActiveLeagueData] = useState(null);
  const [imgURL, setImgURL] = useState("");
  const [imgURLUN, setImgURLUN] = useState("");
  // league bank edit states
  const [leagueNameLB, setLBLeagueName] = useState("");
  const [leagueNameLeague, setLeagueNameLeague] = useState("");
  const [vendorName, setVendorName] = useState(""); // Vendor Name
  const [addSeason, setAddSeason] = useState("");
  const [season, setSeason] = useState("");
  const [addLeagueId, setAddLeagueId] = useState("");
  // Vendor Id
  const [addVendorId, setAddVendorId] = useState("");
  const [startDateLB, setLBStartDate] = useState(null);
  const [endDateLB, setLBEndDate] = useState(null);
  const [abbrLB, setLBAbbr] = useState("");
  const [abbrLeague, setLeagueAbbr] = useState("");
  const [sportsSelected, setSportsSelected] = useState("");
  //  const [arenaTlLB, setLBArenaTl] = useState("");
  const [twitterFeedLB, setLBTwitterFeed] = useState("");
  const [liveBlogIdLB, setLiveBlogIdLB] = useState("");
  const [imgUnselectedLB, setLBImgUnselected] = useState("");
  const [imgselectedLB, setLBImgSelected] = useState("");
  const [imgURLLB, setLBImgURL] = useState("");
  const [imgURLUNLB, setLBImgURLUN] = useState("");
  const [bannerImageLB, setBannerImageLB] = useState(""); // for banner image in edit league bank
  const [selectBannerImageLB, setSelectBannerImageLB] = useState("");
  const [bannerImage, setBannerImage] = useState(""); // for banner image in edit league(not league bank)
  const [updateLeagueBank, setUpdateLeagueBank] = useState(false);
  const [updateSuggestedLeague, setUpdateSuggestedLeague] = useState(false);
  const [isLoadingSuggested, setIsLoadingSuggested] = useState(false);

  //league add
  const [sportsAddName, setSportsAddName] = useState("");
  const [sportsAddId, setSportsAddId] = useState("");
  const [description, setDescription] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [twitterDescription, setTwitterDescription] = useState("");
  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState("");
  const [H1Tag, setH1Tag] = useState("");
  const [ogImage, setOgImage] = useState("");
  const [twitterImage, setTwitterImage] = useState("");
  const [comp_id, setCompId] = useState("");
  const [vendorId, setEditVendorId] = useState("");
  const [updateSuggestedButtonTap, setUpdateSuggestedButtonTap] = useState(false);
  const [rankChanged, setRankChanged] = useState(false);
  let fileInput = React.useRef();


  const handlePagination = (event, value) => {
    setPage(value);
  };

  const getAllLeagues = () => {
    setIsLoading(true);
    const body = {
      page: page,
      limit: 30,
      sports: sportsFilter.toLowerCase() != "all" ? sportsFilter : "",
      search: searchString,
      startDate: startDateFilter,
      endDate: endDateFilter,
      leaguePresentAt: listFilter.toLowerCase() !== "all" ? listFilter : "",
      displayType: displayFilter.toLowerCase() !== "all" ? displayFilter : "",
      sort: sortByFilter.toLowerCase() !== "all" ? sortByFilter : ""
    }


    GetAllComps(body)
      .then((res) => {
        setLeagueList(res?.data?.response);
        fetchFeauredLeague();
        setIsLoading(false)
      })
      .catch((err) => setIsLoading(false));

    // await Axios.get(
    //   `${API_BASE_PATH}leagues/leagueWatchList`,
    //   { headers: { "cms-access-token": accessToken, source: "cms" } }
    //   // {
    //   //   params: JSON.stringify({
    //   //     username: localStorage.getItem('email'),
    //   //   }),
    //   // }
    // )
    //   .then((res) => {
    //     //
    //     // dispatchAllLeagues(res.data.data.data);

    //   })
    //   .catch((err) => setIsLoading(false));


    // setIsLoading(false);
    // setRender(true);
  };

  const SuggestedLeagueData = () => {
    setIsLoadingSuggested(true);
    GetSuggestedLeagueList()
      .then((res) => {
        setSuggestedLeagueList(res?.data);
        setUpdatedDocs(res?.data);
        //  console.log("wow",res)
        // setShowRankeUpdate(true);
        const docsUpdated = [];
        res?.data.map((data, index) => {
          docsUpdated.push({
            _id: data?._id,
            comp_id: data?.comp_id,
            sports: data?.sports?._id
          });
        });
        setUpdatedRank(docsUpdated)
        setIsLoadingSuggested(false);
      })
      .catch((err) => setIsLoading(false));
  };

  const generateGpt = async () => {
    if (gptKeyword == "") {
      alert("please add league name to generate GPT");
      return;
    }
    else {

      setGptLoading(true);
      await Axios.get(`${API_BASE_PATH}leagues/GPTContent`, {
        params: { language: CURRENT_LANGUAGE, keyword: gptKeyword },
        headers: { "cms-access-token": accessToken, source: "cms" }
      },
      )
        .then((res) => {
          if (res.status == 200) {
            // alert('GPT Content Fetched Successfully')
            setGptLoading(false);
            // console.log('res.data.result', res.data.result);
            setGptData(res.data.result);
            // console.log('gptData', gptData);
            setShowGPT(true)
          }
        })
        .catch((err) => { alert(err); setGptLoading(false); setShowGPT(false); setIsLoading(false) });
    }
  }

  useEffect(() => {
    getAllLeagues();
  }, [sportsFilter, startDateFilter, endDateFilter, listFilter, sortByFilter, displayFilter, page, searchString])

  const tagFeaturedLeague = (item) => {
    if (item?.isFeaturedLeague) {
      const body = {
        _id: item?._id,
        isFeaturedLeague: false,
      };
      Axios.post(`${API_BASE_PATH}leagues/admin/tagFeaturedLeague`, body, {
        headers,
      })
        .then((res) => {
          // console.log(res?.data?.message);
          if (res?.data?.message?.toLowerCase() !== "success") {
            alert(res?.data?.message);
          } else {
            getAllLeagues();
            fetchFeauredLeague();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      if (featuredLeague?.length > 1) {
        alert(`${featuredLeague} is already tagged. You can add only 1 league as a featured one...`)

      }
      else {
        if (item?.bannerImage?.length > 1) {
          const body = {
            _id: item?._id,
            isFeaturedLeague: true,
          };
          Axios.post(`${API_BASE_PATH}leagues/admin/tagFeaturedLeague`, body, {
            headers,
          })
            .then((res) => {
              // console.log(res?.data?.message);

              alert(res?.data?.message);

              getAllLeagues();
              fetchFeauredLeague();

            })
            .catch((err) => {
              console.log(err);
            });
        }
        else {
          alert('Please Add Banner before Proceeding through Edit Button!!!')
          OnClickEditCompetition(item);
        }
      }
    }



  };

  const OnClickSuggestedLeague = (league) => {

    if (league?.presentInDefaultLeague) {
      // console.log("asd5", league); 

      const data =
      {
        _id: league?._id,
        comp: {
          ...league,
          comp_name: league?.comp_name,
          dateStart: moment(league?.dateStart && new Date(league?.dateStart)).format("YYYY-MM-DD"),
          dateEnd: moment(league?.dateEnd && new Date(league?.dateEnd)).format("YYYY-MM-DD"),
          abbr: league?.abbr ?? "",
          // segmentID: league?.segmentID ?? 0,
          // optaUUID: league?.optaUUID??"",
          // arenaTl: league?.arenaTl??"",
          liveBlogId: league?.liveBlogId ?? "",
          twitterTag: league?.twitterTag ?? "",
          leagueImage: {
            selected: league?.leagueImage?.selected ?? "",
            unselected: league?.leagueImage?.unselected ?? "",
          },

          bannerImage: league?.bannerImage ?? "", // banner image

          updateInLeagueBank:
            league?.presentInLeagueBank == undefined ? league?.presentInLeagueBank : false,
          metaContent: {
            description: league?.metaContent?.description ?? "",
            ogDescription: league?.metaContent?.ogDescription ?? "",
            twitterDescription: league?.metaContent?.twitterDescription ?? "",
            title: league?.metaContent?.title ?? "",
            keywords: league?.metaContent?.keywords,
            H1Tag: league?.metaContent?.H1Tag ?? "",
            ogImage: league?.metaContent?.ogImage ?? "",
            twitterImage: league?.metaContent?.twitterImage ?? "",
          },
        },
        leagueBank: league?.presentInLeagueBank,
        suggest: false,
        featured: league?.isFeaturedLeague ?? false,
      };
      setIsLoading(true)
      UpdateLeagueType(data)
        .then((res) => {
          showEditHandler(false);
          setImgURL("");
          setImgURLUN("");
          setIsLoading(false);
          getAllLeagues();
          alert("League Updated Successfully");
          // setShowRankeUpdate(true);
          arrangingRanking();
          setUpdateSuggestedButtonTap(true);
        })
        .catch((err) => {
          // alert(`Error on updating league. Please remove banner and update again. ${err}`);
          setToggleEdit(true)
          console.log(err);
        });
    }
    else {

      setActiveLeagueData(league ?? "");
      setGptKeyword(league?.comp_name ?? "");
      setLeagueName(league?.comp_name ?? "");
      setStartDate(league?.dateStart && new Date(league?.dateStart));
      setEndDate(league?.dateEnd && new Date(league?.dateEnd));
      setAbbr(league?.abbr ?? "");
      // setArenaTl(league?.suggesstedLeague?.arenaTl??"");
      setTwitterFeed(league?.twitterTag ?? "");
      setLiveBlogId(league?.liveBlogId ?? "");
      // setUUID(league?.optaUUID??"");
      showEditHandler(true);
      setUpdateLeagueBank(league?.presentInLeagueBank ?? false);
      // setSegmentId(league?.suggesstedLeague?.segmentID??0);
      // setSelectedOption(league?.updateInLeagueBank);
      setImgURL(league?.leagueImage?.selected ?? "");
      setImgURLUN(league?.leagueImage?.unselected ?? "");
      setBannerImage(league?.bannerImage ?? ""); // set normal image
      setOgDescription(league?.metaContent?.ogDescription ?? "");
      setDescription(league?.metaContent?.description ?? "");
      setTwitterDescription(league?.metaContent?.twitterDescription ?? "");
      setTitle(league?.metaContent?.title ?? "");
      let key = league?.metaContent?.keywords ?? [""];
      key = key ? key.join(',') : key;
      setKeywords(key);
      setH1Tag(league?.metaContent?.H1Tag ?? "");
      setOgImage(league?.metaContent?.ogImage ?? "");
      setTwitterImage(league?.metaContent?.twitterImage ?? "");
    }
  };

  const OnClickEditCompetition = (competition) => {
    // dispatchSelectedSports({
    //   selectedSports: competition?.sports?._id,
    // });
    // console.log("competition", competition);
    setAbbr(competition?.abbr ?? "");
    setCompId(competition?.comp_id ?? "");
    setActiveLeagueData(competition)
    setEditStartDate(
      competition?.dateStart && new Date(competition?.dateStart)
    );
    setEditEndDate(competition?.dateEnd && new Date(competition?.dateEnd));

    setEditVendorId(competition?.vendorId ?? "");
    setVendorName(competition?.vendorName ?? "");
    setCompName(competition?.comp_name ?? "");
    setAddSeason(competition?.season ?? "");
    setSportsAddName(competition?.sports?.name ?? "");
    setSportsAddId(competition?.sports?._id ?? "");
    setDescription(competition?.metaContent?.description ?? "");
    setOgDescription(competition?.metaContent?.ogDescription ?? "");
    setTwitterDescription(competition?.metaContent?.twitterDescription ?? "");
    setImgURL(competition?.leagueImage?.selected ?? "");
    setImgURLUN(competition?.leagueImage?.unselected ?? "");
    setTitle(competition?.metaContent?.title ?? "");
    let data = competition?.metaContent?.keywords ?? [''];
    // console.log('data', data)
    data = data ? data.join(',') : "";
    setKeywords(data);
    setH1Tag(competition?.metaContent?.H1Tag ?? "");
    setOgImage(competition?.metaContent?.ogImage ?? "");
    setTwitterImage(competition?.metaContent?.twitterImage ?? "");
    setBanerImageMain(competition?.bannerImage ?? "");
    setGptKeyword(competition?.comp_name ?? "");
    setShowLeagueEdit(true);
  };
  const OnClickEditLeagueBank = (league) => {
    if (league?.presentInLeagueBank) {

      const data =
      {
        _id: league?._id,
        comp: {
          ...league,
          comp_name: league?.comp_name,
          dateStart: moment(league?.dateStart && new Date(league?.dateStart)).format("YYYY-MM-DD"),
          dateEnd: moment(league?.dateEnd && new Date(league?.dateEnd)).format("YYYY-MM-DD"),
          abbr: league?.abbr ?? "",
          // segmentID: league?.segmentID ?? 0,
          // optaUUID: league?.optaUUID??"",
          // arenaTl: league?.arenaTl??"",
          liveBlogId: league?.liveBlogId ? league?.liveBlogId : "",
          twitterTag: league?.twitterTag ? league?.twitterTag : "",
          leagueImage: {
            selected: league?.leagueImage?.selected ?? "",
            unselected: league?.leagueImage?.unselected ?? "",
          },

          bannerImage: league?.bannerImage ?? "", // banner image

          updateInLeagueBank:
            !league?.presentInLeagueBank,
          metaContent: {
            description: league?.metaContent?.description ?? "",
            ogDescription: league?.metaContent?.ogDescription ?? "",
            twitterDescription: league?.metaContent?.twitterDescription ?? "",
            title: league?.metaContent?.title ?? "",
            keywords: league?.metaContent?.keywords ?? [""],
            H1Tag: league?.metaContent?.H1Tag ?? "",
            ogImage: league?.metaContent?.ogImage ?? "",
            twitterImage: league?.metaContent?.twitterImage ?? "",
          },
        },
        leagueBank: !league?.presentInLeagueBank,
        suggest: false,
        featured: league?.isFeaturedLeague ?? false,
      };
      setIsLoading(true);
      // Axios.post(`${API_BASE_PATH}leagues/updateLeagueWatchList`, data, {
      //   headers,
      // })
      UpdateLeagueType(data)
        .then((res) => {
          showEditLeagueBank(false);
          setLBImgURL("");
          setLBImgURLUN("");
          setIsLoading(false);
          getAllLeagues()
        })
        .catch((err) => {
          alert(`Error in League Updated.Please remove banner and update again. ${err}`);

          console.log(err);
        });
    }
    else {

      setLBLeagueName(league?.comp_name ?? "");
      setGptKeyword(league?.comp_name ?? "");
      setLBStartDate(league?.dateStart && new Date(league?.dateStart));
      setLBEndDate(league?.dateEnd && new Date(league?.dateEnd));
      setLBAbbr(league?.abbr ?? "");
      // setLBUUID(league?.optaUUID??"");
      setLBImgURL(league?.leagueImage?.selected ?? "");
      setLBImgURLUN(league?.leagueImage?.unselected ?? "");
      setBannerImageLB(league?.bannerImage ?? ""); // in league? bank
      setActiveLeagueData(league ?? "");
      showEditLeagueBank(true);
      // setLBSegmentId(league?.leagueBank?.segmentID??0);
      // setLBArenaTl(league?.leagueBank?.arenaTl ? league?.leagueBank?.arenaTl : "");
      setLBTwitterFeed(league?.twitterTag ? league?.twitterTag : "");
      setLiveBlogIdLB(league?.liveBlogId ? league?.liveBlogId : "");
      setUpdateSuggestedLeague(league?.updateInDefaultLeagues ?? "");
      setDescription(league?.metaContent?.description ?? "");
      setOgDescription(league?.metaContent?.ogDescription ?? "");
      setTwitterDescription(league?.metaContent?.twitterDescription ?? "");
      setTitle(league?.metaContent?.title ?? "");
      let data = league?.metaContent?.keywords ?? [""];
      data = data ? data.join(',') : "";
      setKeywords(data);
      setH1Tag(league?.metaContent?.H1Tag ?? "");
      setOgImage(league?.metaContent?.ogImage ?? "");
      setTwitterImage(league?.metaContent?.twitterImage ?? "");
    }
  };

  const onUpdateLeague = () => {
    if (leagueName == "") {
      alert("league Name cannot be empty");
      return;
    }
    if (startDate == null) {
      alert("Start Date cannot be empty");
      return;
    }
    if (endDate == null) {
      alert("End Date cannot be empty");
      return;
    }
    if (startDate > endDate) {
      alert("start Date cannot be greater than end date");
      return;
    }
    // if (segmentId == undefined || segmentId <= 0) {
    //   alert("SegmentId should be greater than 0");
    //   return;
    // }
    if (!title || title.length == 0) {
      alert("title cannot be empty");
      return;
    }
    if (title?.length > 60) {
      alert("title max length is 60");
      return;
    }
    if (!description || description.length == 0) {
      alert("description cannot be empty");
      return;
    }
    if (description?.length > 200) {
      alert("description max length is 200");
      return;
    }
    if (!keywords || keywords.length == 0) {
      alert("keywords cannot be empty");
      return;
    }
    if (keywords?.length > 200) {
      alert("keywords max length is 200");
      return;
    }
    if (!ogDescription || ogDescription.length == 0) {
      alert("ogDescription cannot be empty");
      return;
    }
    if (ogDescription?.length > 200) {
      alert("ogDescription max length is 200");
      return;
    } else {
      const arr = keywords.split(",");
      const data =
      {
        _id: activeLeagueData?._id,
        comp: {
          ...activeLeagueData,
          comp_name: leagueName,
          dateStart: moment(startDate).format("YYYY-MM-DD"),
          dateEnd: moment(endDate).format("YYYY-MM-DD"),
          abbr: abbr,
          liveBlogId: liveBlogId,
          twitterTag: twitterFeed,
          leagueImage: {
            selected: imgURL,
            unselected: imgURLUN,
          },
          ranking: 0,
          bannerImage: bannerImage ?? "", // banner image
          updateInLeagueBank:
            updateLeagueBank == undefined ? true : updateLeagueBank,
          metaContent: {
            description: description,
            ogDescription: ogDescription,
            twitterDescription: twitterDescription,
            title: title,
            keywords: arr,
            H1Tag: H1Tag,
            ogImage: ogImage,
            twitterImage: twitterImage,
          },
        },
        leagueBank: true,
        suggest: !activeLeagueData?.presentInDefaultLeague,
        featured: activeLeagueData?.isFeaturedLeague ?? false,
      };
      // const formData = new FormData();

      // Object.keys(data).forEach(function (key) {
      //   formData.append(key, data[key]);
      // });
      setIsLoading(true)
      UpdateLeagueType(data)
        .then((res) => {
          if (res?.status != false) {
            showEditHandler(false);
            setImgURL("");
            setImgURLUN("");
            setIsLoading(false);
            alert("League Updated Successfully");
            getAllLeagues();
            setShowGPT(false);
            arrangingRanking();
            setUpdateSuggestedButtonTap(true);
            setShowGPT(false);
          }
          else {
            alert("League Not Updated");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          // alert(`Error on updating league. Please remove banner and update again. ${err}`);
          setToggleEdit(true)
          console.log(err);
        });
    }
  };
  const onUpdateLeagueBank = () => {
    if (leagueNameLB == "") {
      alert("league Name cannot be empty");
      return;
    }
    if (startDateLB > endDateLB) {
      alert("start date cannot be greater than end date");
      return;
    }

    if (!title || title.length == 0) {
      alert("title cannot be empty");
      return;
    }
    if (title?.length > 60) {
      alert("title max length is 60");
      return;
    }
    if (!description || description.length == 0) {
      alert("description cannot be empty");
      return;
    }
    if (description?.length > 200) {
      alert("description max length is 200");
      return;
    }
    if (!keywords || keywords.length == 0) {
      alert("keywords cannot be empty");
      return;
    }
    if (keywords?.length > 200) {
      alert("keywords max length is 200");
      return;
    }
    if (!ogDescription || ogDescription.length == 0) {
      alert("ogDescription cannot be empty");
      return;
    }
    if (ogDescription?.length > 200) {
      alert("ogDescription max length is 200");
      return;
    } else {
      const arr = keywords.split(",");
      const data = {
        comp: {
          ...activeLeagueData,
          comp_name: leagueNameLB,
          dateStart: moment(startDateLB).format("YYYY-MM-DD"),
          dateEnd: moment(endDateLB).format("YYYY-MM-DD"),
          abbr: abbrLB,
          leagueBank: true,
          suggest: updateSuggestedLeague == undefined ? true : updateSuggestedLeague,
          // segmentID: segmentIdLB ? segmentIdLB : 0,
          // optaUUID: uuidLB ? uuidLB : "",
          // arenaTl: arenaTlLB,
          liveBlogId: liveBlogIdLB,
          twitterTag: twitterFeedLB,
          leagueImage: {
            selected: imgselectedLB == "" ? imgURLLB : imgselectedLB,
            unselected: imgUnselectedLB == "" ? imgURLUNLB : imgUnselectedLB,
          },
          // selected: imgselectedLB == "" ? imgURLLB : imgselectedLB,
          // unselected: imgUnselectedLB == "" ? imgURLUNLB : imgUnselectedLB,
          bannerImage:
            selectBannerImageLB == "" ? bannerImage : selectBannerImageLB, // banner image in LeagueBank
          updateInDefaultLeagues:
            updateSuggestedLeague == undefined ? true : updateSuggestedLeague,
          metaContent: {
            description: description,
            ogDescription: ogDescription,
            twitterDescription: twitterDescription,
            title: title,
            keywords: arr,
            H1Tag: H1Tag,
            ogImage: ogImage,
            twitterImage: twitterImage,
          },
        },
        _id: activeLeagueData?._id,
        leagueBank: true,
        suggest: updateSuggestedLeague == undefined ? true : updateSuggestedLeague,
        featured: activeLeagueData?.isFeaturedLeague ?? false,
      };
      // const formData = new FormData();
      // Object.keys(data).forEach(function (key) {
      //   formData.append(key, data[key]);
      // });
      setIsLoading(true);
      // Axios.post(`${API_BASE_PATH}leagues/updateLeagueWatchList`, data, {
      //   headers,
      // })
      UpdateLeagueType(data)
        .then((res) => {
          showEditLeagueBank(false);
          alert("League Updated Successfully");
          setLBImgURL("");
          setLBImgURLUN("");
          setIsLoading(false);
          getAllLeagues();
          setShowGPT(false);
          alert("League Updated Successfully");
        })
        .catch((err) => {
          alert(`Error in League Updated.Please remove banner and update again. ${err}`);

          console.log(err);
        });
    }
  };

  const onSportsSelected = (value) => {
    setSportsSelected(value);
  };
  const onLeagueAdd = () => {
    if (leagueNameLeague == "") {
      alert("league Name cannot be empty");
      return;
    }
    if (addLeagueId == "") {
      alert("league id cannot be empty");
      return;
    }
    if (season == "") {
      alert("season cannot be empty");
      return;
    }
    if (Number(season) <= 0) {
      alert("season cannot be less than 0");
      return;
    }
    if (sportsSelected == "") {
      alert("sports cannot be empty");
      return;
    }
    if (startDateLeague == null) {
      alert("start date cannot be empty");
      return;
    }
    if (endDateLeague == null) {
      alert("end date cannot be empty");
      return;
    }
    if (endDateLeague < startDateLeague) {
      alert("end date cannot be less than start Date");
      return;
    }
    if (title == "") {
      alert("title cannot be empty");
      return;
    }
    if (title?.length > 60) {
      alert("title max length is 60");
      return;
    }
    if (description == "") {
      alert("description cannot be empty");
      return;
    }
    if (description?.length > 200) {
      alert("description max length is 200");
      return;
    }
    if (keywords == "") {
      alert("keywords cannot be empty");
      return;
    }
    if (keywords?.length > 200) {
      alert("keywords max length is 200");
      return;
    }
    if (ogDescription == "") {
      alert("ogDescription cannot be empty");
      return;
    }
    if (ogDescription?.length > 200) {
      alert("ogDescription max length is 200");
      return;
    } else {
      setIsLoading(true);

      const arr = keywords.split(",");
      const data = {
        comp_id: addLeagueId,
        comp_name: leagueNameLeague,
        season: season,
        abbr: abbrLeague,
        vendorId: addVendorId,
        vendorName: vendorName,
        sports: sportsSelected,
        dateStart: moment(startDateLeague).format("YYYY-MM-DD"),
        dateEnd: moment(endDateLeague).format("YYYY-MM-DD"),
        Rank: 0,
        metaContent: {
          description: description,
          ogDescription: ogDescription,
          twitterDescription: twitterDescription,
          title: title,
          keywords: arr,
          H1Tag: H1Tag,
          ogImage: ogImage,
          twitterImage: twitterImage,
        },
        bannerImage: banerImageMain,
        key: "add",
      };

      //  console.log('data', data)
      const formData = new FormData();
      // Object.keys(data).forEach(function (key) {
      //   formData.append(key, data[key]);
      // });
      setIsLoading(true);
      Axios.post(`${API_BASE_PATH}leagues/addCompetitionMetaContent`, data, {
        headers,
      })
        .then((res) => {
          setShowLeagueAdd(false);
          alert("League Added Successfully");
          setIsLoading(false);
          getAllLeagues()
          // alert("League Added Successfully");
          setShowLeagueAdd(false)
          setShowGPT(false);
        })
        .catch((err) => {
          alert(`Error in League Adding. Please remove banner and update again ${err}`);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!showLeagueAdd) {
      setShowGPT(false);
      setTwitterDescription("");
      setOgDescription("");
      setKeywords("");
      setDescription("");
      setH1Tag("");
      setTitle("");
      setAddVendorId("");
      setVendorName("");
      setSeason("");
      setLeagueAbbr("");
      setAddLeagueId("");
      setLeagueNameLeague("");
      setSportsSelected("all");
      setLeagueStartDate(null);
      setLeagueEndDate(null);
      setTwitterImage("");
      setOgImage("");
      setBanerImageMain("");
      setGptData("");
      setGptKeyword("");
    }
  }, [showLeagueAdd])


  const onUpdateLeagueEdit = () => {

    setIsLoading(true);
    if (compName == "") {
      alert("league Name cannot be empty");
    }
    else if (typeof comp_id === "undefined") {
      alert("league id cannot be updated");

    }
    else if (addSeason == "" || addSeason == undefined) {
      alert("season cannot be empty");
    }
    else if (Number(addSeason) <= 0) {
      alert("season cannot be less than 0");
    }
    else if (sportsAddName == "") {
      alert("sports cannot be empty");
    }
    else if (startEditDate == null) {
      alert("start date cannot be empty");
    }
    else if (endEditDate == null) {
      alert("end date cannot be empty");
    }
    else if (startEditDate > endEditDate) {
      alert("end date cannot be less than start Date");
    }
    else if (!title || title.length == 0) {
      alert("title cannot be empty");
    }
    else if (title?.length > 60) {
      alert("title max length is 60");
    }
    else if (!description || description.length == 0) {
      alert("description cannot be empty");
    }
    else if (description?.length > 200) {
      alert("description max length is 200");
    }
    else if (!keywords || keywords.length == 0) {
      alert("keywords cannot be empty");
    }
    else if (keywords?.length > 200) {
      alert("keywords max length is 200");
    }
    else if (!ogDescription || ogDescription.length == 0) {
      alert("ogDescription cannot be empty");
    }
    else if (ogDescription?.length > 200) {
      alert("ogDescription max length is 200");
    } else {
      setShowLeagueEdit(false);

      setIsLoading(true);
      const arr = keywords.split(",");
      const data = {
        comp: {
          ...activeLeagueData,
          comp_id: comp_id,
          comp_name: compName,
          season: addSeason,
          abbr: abbr,
          vendorId: vendorId,
          vendorName: vendorName,
          sports: sportsAddId,
          leagueImage: {
            selected: imgURL,
            unselected: imgURLUN,
          },

          dateStart: moment(startEditDate).format("YYYY-MM-DD"),
          dateEnd: moment(endEditDate).format("YYYY-MM-DD"),
          metaContent: {
            description: description,
            ogDescription: ogDescription,
            twitterDescription: twitterDescription,
            title: title,
            keywords: arr,
            H1Tag: H1Tag,
            ogImage: ogImage,
            twitterImage: twitterImage,
          },
          bannerImage: banerImageMain,
          key: "edit",
        },
        _id: activeLeagueData?._id,
        leagueBank: activeLeagueData?.presentInLeagueBank,
        suggest: activeLeagueData?.presentInDefaultLeague,
        featured: activeLeagueData?.isFeaturedLeague,
      };

      UpdateLeagueType(data)
        .then((res) => {
          showEditHandler(false);
          setImgURL("");
          setImgURLUN("");
          alert("League Updated Successfully");
          setIsLoading(false);
          getAllLeagues();
          setShowGPT(false);
        })
        .catch((err) => {
          // alert(`Error on updating league. Please remove banner and update again. ${err}`);
          setToggleEdit(true)
          console.log(err);
        });
    }
  };

  // const changeShowOnWeb = async (event, _id, showOnWeb) => {
  //   const body = {
  //     _id: _id,
  //     showOnWeb: event.target.checked,
  //   };
  //   const recordFound = _.find(checkedShowWeb, (item) => item === _id);

  //   if (recordFound) {
  //     setShowOnWeb(true);
  //     const newList = _.filter(checkedShowWeb, (item) => item !== _id);

  //     setCheckShowWeb(newList);
  //     TagLeagueOnWeb(body)
  //       .then((response) => {
  //         if (response) {
  //           getAllLeagues();
  //         }
  //       })
  //       .catch((error) => {
  //         if (error) {
  //           alert(error);
  //         }
  //       });
  //   } else {
  //     setCheckShowWeb([...checkedShowWeb, _id]);
  //     TagLeagueOnWeb(body)
  //       .then((response) => {
  //         if (response) {
  //           getAllLeagues();
  //         }
  //       })
  //       .catch((error) => {
  //         if (error) {
  //           alert(error);
  //         }
  //       });
  //   }
  // };

  const onFileSelectTwitter = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = "";

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      // image.onload = function () {
      //   var height = this.height;
      //   var width = this.width;

      //   if (width !== (4 * height) / 3) {
      //     alert('Image width and height must be in ratio of 4:3 ');
      //     return false;
      //   }
      // };
      setTwitterImage(reader.result);
      // setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
  };
  const onFileSelectOg = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = "";

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      // image.onload = function () {
      //   var height = this.height;
      //   var width = this.width;

      //   if (width !== (4 * height) / 3) {
      //     alert('Image width and height must be in ratio of 4:3 ');
      //     return false;
      //   }
      // };
      setOgImage(reader.result);
      // setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
  };
  const onFileSelected = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = "";

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (width == height) {

          setLBImgURL(reader.result);
          setImgURL(reader.result);
          setLBImgSelected(reader.result);
        }
        else {
          alert('Image width and height must be in ratio of 1:1 ');
        }
      };
      // setLBImgURL(reader.result);
      // setImgURL(reader.result);
      // setLBImgSelected(reader.result);

      // setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
  };
  const onFileUnSelected = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = "";

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (width == height) {

          setLBImgUnselected(reader.result);
          setLBImgURLUN(reader.result);
          setImgURLUN(reader.result);
        }
        else {
          alert('Image width and height must be in ratio of 1:1 ');
        }
      };
      // setLBImgUnselected(reader.result);
      // setLBImgURLUN(reader.result);
      // setImgURLUN(reader.result);
      // setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
  };
  //Check if any featured leagued is already tagged
  const fetchFeauredLeague = async () => {
    await Axios.get(`${API_BASE_PATH}leagues/admin/fetchFeaturedLeague`, {
      headers,
    })
      .then((res) => {
        console.log(res?.data?.data);
        setFeaturedLeague(res?.data?.data?.comp_name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchFeauredLeague();
  }, []);

  const removeBannerLB = () => {
    {
      selectBannerImageLB != "" ? setSelectBannerImageLB("") :
        setBannerImage("")
    }
    { bannerImageLB != "" ? setBannerImageLB("") : setSelectBannerImageLB("") }
  }
  const onFileBannerLB = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = "";
    if (file.type !== "image/png") {
      alert("Please select a PNG image");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 368 && width === 1616) {
          setSelectBannerImageLB(reader.result);
        } else {
          alert(
            `Image width and height must be 1616 x 368 px. Your Image width and height is ${width} x  ${height} px `
          );
          return false;
        }
      };
      // setSelectBannerImageLB(reader.result);
      // setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      alert("image not uploaded");
    };

  };
  const removeMain = () => {
    setBanerImageMain("");
  }
  const onFileMainBanner = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = "";
    if (file.type !== "image/png") {
      alert("Please select a PNG image");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 368 && width === 1616) {
          setBanerImageMain(reader.result);
        } else {
          alert(
            `Image width and height must be 1616 x 368 px. Your Image width and height is ${width} x  ${height} px `
          );
          return false;
        }
      };
      // setBanerImageMain(reader.result);
      // setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      alert("image not uploaded");
    };

  };
  const removeSuggLb = () => {
    setBannerImage("");
  }
  const onFileSuggBanner = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = "";
    if (file.type !== "image/png") {
      alert("Please select a PNG image");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 368 && width === 1616) {
          setBannerImage(reader.result);
        } else {
          alert(
            `Image width and height must be 1616 x 368 px. Your Image width and height is ${width} x  ${height} px `
          );
          return false;
        }
      };
      // setBannerImage(reader.result);
      // setUpdateImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      alert("image not uploaded");
    };

  };


  {/**Dragging concept start*/ }
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }
    setRankChanged(true);

    const items = reorder(updatedDocs, source.index, destination.index);
    console.log(items)
    const docsUpdated = [];
    // const arr = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data?._id,
        comp_id: data?.comp_id,
        sports: data?.sports?._id
      });
    });
    setUpdatedRank(docsUpdated)
    setUpdatedDocs(items);
    // setSuggestedLeagueList(items);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  {/**Dragging concept end*/ }


  const arrangingRanking = () => {
    SuggestedLeagueData();
    setShowRankeUpdate(true);
  };

  {/** function to update Rank */ }
  const UpdateRankOfLeague = () => {
    UpdateRankOfSuggestedLeague(updatedRank)
      .then((response) => {
        console.log(response);
        alert("Rank Updated Succesfully");
        SuggestedLeagueData();
        setShowRankeUpdate(false);
        setUpdateSuggestedButtonTap(false);
        setRankChanged(false);
      })
      .catch((error) => console.log(error))
  };



  const sportsFilterChange = (event) => {
    setSportsFilter(event)
  };


  return (
    <>
      <div className="wrapper">

        {/** Add to Suggested League Pop_up */}
        <div className="edit__popup">
          <div
            onClick={() => {
              showEditHandler(false); setImgURL("");
              setImgURLUN("");
            }}
            className={showEdit ? "edit__backdrop active" : "edit__backdrop"}
          ></div>

          <div
            className={
              showEdit ? (showGPT ? "edit__popup-body-gpt active" : "edit__popup-body active") : "edit__popup-body"
            }
            style={{ zIndex: 1000 }}
          >
            <div style={{ width: "100%" }}>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h1 className="edit__popup-heading">Suggested League</h1>
                  <h3 className="edit__popup-mandatory" >
                    Mandatory Fields are marked with *
                  </h3>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>

                  {gptLoading ? <div className="loader"></div> : ""}
                  {/* {showGPT ? <span>Scroll down for content</span>:""} */}
                  <Button
                    variant="contained"
                    style={{ margin: "10px", marginRight: "50px", backgroundColor: "#28faae" }}
                    onClick={() => { generateGpt() }}>Generate GPT META CONTENT</Button>
                  <i
                    onClick={() => {
                      showEditHandler(false); setShowGPT(false); setImgURL("");
                      setImgURLUN("");
                    }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                </div>
              </div>
              <div className={!showGPT ? "edit__popup-inner" : "edit__popup-inner-gpt"}>

                <div style={{ marginRight: "5px" }}>
                  <div className="edit__popup--form">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>League Name:</span>

                          {leagueName == "All" ? (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                disabled
                                value={leagueName}
                                onChange={() =>
                                  alert(`Can not change league name of 'All'`)
                                }
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          ) : (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                disabled
                                value={leagueName}
                                onChange={(e) => setLeagueName(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className="edit__popup-row"
                        // style={{ padding: '0px 45px' }}
                        >
                          <span> ABBR:</span>

                          {leagueName == "All" ? (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbr}
                                onChange={() =>
                                  alert(`Can not change league abbr of 'All'`)
                                }
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          ) : (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbr}
                                onChange={(e) => setAbbr(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                        {/* dropdown */}
                        {/* <div className="edit__popup-row edit__popup-row-colored">
                          <span style={{ marginRight: "43px" }}>
                            Make changes in League bank?
                          </span>
                          <select
                            style={{
                              // width: '30%',
                              height: "40px",
                              // margin: '1.2%',
                              // marginRight: '4%',
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                            onChange={(e) => setUpdateLeagueBank(e.target.value)}
                          >
                            <option
                              selected={
                                selectedOption == updateLeagueBank ? "selected" : ""
                              }
                              value={true}
                            >
                              Yes
                            </option>
                            <option
                              selected={
                                selectedOption == updateLeagueBank ? "selected" : ""
                              }
                              value={false}
                            >
                              No
                            </option>
                          </select>
                        </div> */}
                        {/* dropdown end */}
                      </div>
                      {/* <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> optaUUID:</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={uuid}
                              onChange={(e) => setUUID(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                        <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>Set arena TL :</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={arenaTl}
                              onChange={(e) => setArenaTl(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="place the arena time line identifier"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="edit__popup-rows">
                        <div
                          className="edit__popup-row"
                        // style={{ width: '70%', marginLeft: '50px' }}
                        >
                          <span>Live Blog Id :</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={liveBlogId}
                              onChange={(e) => setLiveBlogId(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="place the arena time line identifier"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span>Set Twitter :</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={twitterFeed}
                              onChange={(e) => setTwitterFeed(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Twitter"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                        <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Segment Id:</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="number"
                              name="text"
                              value={segmentId}
                              onChange={(e) => setSegmentId(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div
                          className="edit__popup-row"
                        // style={{ padding: '0px 45px' }}
                        >
                          <span> ABBR:</span>

                          {leagueName == "All" ? (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbr}
                                onChange={() =>
                                  alert(`Can not change league abbr of 'All'`)
                                }
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          ) : (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbr}
                                onChange={(e) => setAbbr(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                      </div> */}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Start Date:</span>

                          <div
                            className="relative w-full edit__popup-row"
                            style={{ position: "relative", zIndex: "1000000" }}
                          >
                            <DatePicker
                              autoOk
                              hideTabs
                              label="Start Date"
                              disabled
                              minDateMessage=""
                              // minDate={_dateTimeFormat(
                              //   new Date(),
                              //   'ddd, YYYY DD MMM hh:mm A'
                              // )}
                              format="yyyy/MM/dd"
                              value={startDate}
                              onChange={setStartDate}
                            />
                          </div>
                        </div>

                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>End Date:</span>

                          <div
                            className="relative w-full"
                            style={{ position: "relative", zIndex: "100" }}
                          >
                            <DatePicker
                              autoOk
                              hideTabs
                              minDateMessage=""
                              disabled
                              // minDate={_dateTimeFormat(
                              //   new Date(),
                              //   'ddd, YYYY DD MMM hh:mm A'
                              // )}
                              format="yyyy/MM/dd"
                              value={endDate}
                              onChange={setEndDate}
                              popperProps={{ strategy: "fixed" }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Meta Content */}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Meta Title:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={title}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );
                                setTitle(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Meta Title"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span>H1 Tag:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={H1Tag}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );

                                setH1Tag(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter h1 tag"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Meta Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={description}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Meta Description"
                          />
                        </div>
                      </div>

                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Meta keywords:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={keywords}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setKeywords(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter keywords"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Og Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={ogDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setOgDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Og Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <span> Twitter Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={twitterDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              ); setTwitterDescription(formattedValue)
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Twitter Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Twitter Image:</span>
                          <img src={twitterImage} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectTwitter}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> Og Image:</span>
                          <img src={ogImage} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectOg}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Selected:</span>
                          <img src={imgURL} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="selected"
                              onChange={onFileSelected}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> Un-Selected:</span>

                          <img src={imgURLUN} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileUnSelected}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      {/**Banner Image */}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span>Banner Image Upload: </span>

                          <img src={bannerImage == "" ? selectBannerImageLB : bannerImage} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSuggBanner}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                          <div className="edit__popup-inner"><i

                            onClick={removeSuggLb}

                            className="far fa-times-circle edit__popup-close"
                          ></i></div>
                          {/* <CancelIcon style={{ cursor: "pointer" }} onClick={removeSuggLb} /> */}
                        </div>
                      </div>
                      <div className={"edit__popup-row button-row"}>
                        <div
                          onClick={() => { !isLoading && onUpdateLeague(), !isLoading && setIsLoading(true) }}
                          className={"save btn"}
                          style={{ width: "fit-content" }}
                        >
                          Add To Suggested League
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                {showGPT ?

                  <div style={{ maxWidth: "600px", marginLeft: "5px", borderLeft: "2px solid" }}>
                    <GptSection data={gptData} show={setShowGPT} />

                  </div>
                  : ""}
              </div>
            </div>
          </div>
        </div>

        {/* Competition edit  Pop_up*/}
        <div className="edit__popup">
          <div
            onClick={() => {
              setShowLeagueEdit(false), setImgURL("");
              setImgURLUN("");
            }} //empty previous league image
            className={
              showLeagueEdit ? "edit__backdrop active" : "edit__backdrop"
            }
          ></div>
          <div
            className={
              showLeagueEdit ? (showGPT ? "edit__popup-body-gpt active" : "edit__popup-body active") : "edit__popup-body"
            }
            style={{ zIndex: 1000, display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "100%" }}>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h1 className="edit__popup-heading">Edit Competition</h1>
                  <h3 className="edit__popup-mandatory" >
                    Mandatory Fields are marked with *
                  </h3>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {gptLoading ? <div className="loader"></div> : ""}
                  <Button
                    variant="contained"
                    style={{ margin: "10px", marginRight: "50px", backgroundColor: "#28faae" }}
                    onClick={() => { generateGpt() }}>Generate GPT META CONTENT</Button>
                  <i
                    onClick={() => {
                      setShowLeagueEdit(false), setShowGPT(false); setImgURL("");
                      setImgURLUN("");
                    }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                </div>
              </div>
              <div className={!showGPT ? "edit__popup-inner" : "edit__popup-inner-gpt"}>
                <div style={{ marginRight: "5px" }}>
                  <div className="edit__popup--form">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>League Name:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              disabled
                              value={compName}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9 ]/g,
                                  ""
                                );
                                setCompName(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter League Name"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>League Id:</span>
                          <div className="relative w-full ">
                            <input
                              disabled
                              type="text"
                              name="text"
                              value={comp_id}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9 ]/g,
                                  ""
                                );
                                setCompId(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter League ID"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> ABBR:</span>

                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={abbr}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9 ]/g,
                                  ""
                                ); setAbbr(formattedValue)
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Abbreviation"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Season:</span>
                          <div className="relative w-full ">
                            <input
                              type="number"
                              name="text"
                              value={addSeason}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setAddSeason(inputValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Season"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Vendor*/}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">

                          <span>Vendor Name:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={vendorName}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9 ]/g,
                                  ""
                                );
                                setVendorName(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Vendor Name"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span>Vendor Id:</span>
                          <div className="relative w-full ">
                            <input

                              type="text"
                              name="text"
                              value={vendorId}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9 ]/g,
                                  ""
                                );
                                setEditVendorId(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Vendor ID"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="edit__popup-row"
                        style={{ marginLeft: "50px" }}
                      >
                        {" "}
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Select Sports Again: </span>
                        {/* <div className="relative w-full "> */}
                        <input
                          disabled
                          type="text"
                          name="text"
                          value={sportsAddName}
                          style={{ width: "150px", borderRadius: "7px" }}
                        // className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                        {/* </div> */}
                        {/* <SportsDropDown
                      // onChange={onSportAddUpdate}
                      selected={sportsAddName}
                      
                    /> */}
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Start Date:</span>
                          <div
                            className="relative w-full edit__popup-row"
                            style={{ position: "relative", zIndex: "1000000" }}
                          >
                            <DatePicker
                              autoOk
                              disabled
                              hideTabs
                              minDateMessage=""
                              format="yyyy/MM/dd"
                              value={startEditDate != null ? startEditDate : null}
                              onChange={setEditStartDate}
                            />
                          </div>
                        </div>

                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>End Date:</span>

                          <div
                            className="relative w-full"
                            style={{ position: "relative", zIndex: "100" }}
                          >
                            <DatePicker
                              autoOk
                              disabled
                              hideTabs
                              minDateMessage=""
                              format="yyyy/MM/dd"
                              value={endEditDate != null ? endEditDate : null}
                              onChange={setEditEndDate}
                              popperProps={{ strategy: "fixed" }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Meta Content */}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Meta Title:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={title}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );
                                setTitle(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Meta Title"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> H1 Tag:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={H1Tag}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );
                                setH1Tag(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter H1 Tag"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span> Meta Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={description}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Meta Description"
                          />
                        </div>
                      </div>

                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span> Meta keywords:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={keywords}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setKeywords(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span> Og Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={ogDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setOgDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Og Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <span> Twitter Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={twitterDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setTwitterDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Twitter Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Twitter Image:</span>
                          <img src={twitterImage} style={{ width: 40 }} />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectTwitter}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>

                        </div>
                        <div className="edit__popup-row">
                          <span> Og Image:</span>
                          <img src={ogImage} style={{ width: 40 }} />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectOg}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>

                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Selected:</span>
                          <img src={imgURL} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="selected"
                              onChange={onFileSelected}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> Un-Selected:</span>

                          <img src={imgURLUN} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileUnSelected}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Banner Image:</span>
                          <img src={banerImageMain} style={{ width: 40 }} />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileMainBanner}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                          <div className="edit__popup-inner"><i

                            onClick={removeMain}
                            className="far fa-times-circle edit__popup-close"
                          ></i></div>
                          {/* <CancelIcon style={{ cursor: "pointer" }} onClick={removeMain} /> */}
                        </div>
                      </div>
                      {toggleEdit && <div className={"edit__popup-row button-row"}>
                        <div
                          onClick={() => !isLoading && onUpdateLeagueEdit()
                          }
                          className={"save btn"}
                        >
                          Update
                        </div>
                      </div>}
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                {showGPT ?

                  <div style={{ maxWidth: "600px", marginLeft: "5px", borderLeft: "2px solid" }}>
                    <GptSection data={gptData} show={setShowGPT} />
                  </div>
                  : ""}
              </div>
            </div>

          </div>
        </div>

        {/* Add to League bank */}
        <div className="edit__popup">
          <div
            onClick={() => {
              showEditLeagueBank(false);
              setLBImgURL("");
              setLBImgURLUN("");
            }}
            className={
              showEditLeague ? "edit__backdrop active" : "edit__backdrop"
            }
          ></div>

          <div
            className={
              showEditLeague ? (showGPT ? "edit__popup-body-gpt active" : "edit__popup-body active") : "edit__popup-body"
            }
            style={{ zIndex: 1000, display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "100%" }}>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h1 className="edit__popup-heading">League Bank</h1>
                  <h3 className="edit__popup-mandatory" >
                    Mandatory Fields are marked with *
                  </h3>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  {gptLoading ? <div className="loader"></div> : ""}
                  {/* {showGPT ? <span>Scroll down for content</span>:""} */}
                  <Button
                    variant="contained"
                    style={{ margin: "10px", marginRight: "50px", backgroundColor: "#28faae" }}
                    onClick={() => { generateGpt() }}>Generate GPT META CONTENT</Button>
                  <i
                    onClick={() => {
                      showEditLeagueBank(false), setShowGPT(false)

                      setLBImgURL("");
                      setLBImgURLUN("");
                    }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                </div>
              </div>


              <div className={!showGPT ? "edit__popup-inner" : "edit__popup-inner-gpt"}>
                <div style={{ marginRight: "5px" }}>
                  <div className="edit__popup--form">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>League Name:</span>

                          {leagueNameLB == "All" ? (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={leagueNameLB}
                                disabled
                                onChange={() =>
                                  alert(`Can not change league name of 'All'`)
                                }
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          ) : (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                disabled
                                value={leagueNameLB}
                                onChange={(e) => setLBLeagueName(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                        <div className="edit__popup-row">
                          <span> ABBR:</span>
                          {leagueNameLB == "All" ? (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbrLB}
                                onChange={() =>
                                  alert(`Can not change league abbr of 'All'`)
                                }
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          ) : (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbrLB}
                                onChange={(e) => setLBAbbr(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                        {/* dropdown */}
                        {/* <div className="edit__popup-row edit__popup-row-colored">
                          <span style={{ marginRight: "30px" }}>
                            Make changes in Suggested League?
                          </span>
                          <select
                            style={{
                              // width: '16%',
                              height: "40px",
                              // margin: '1.2%',
                              // marginRight: '4%',
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                            onChange={(e) =>
                              setUpdateSuggestedLeague(e.target.value)
                            }
                          >
                            <option
                              selected={
                                selectedOption == updateSuggestedLeague
                                  ? "selected"
                                  : ""
                              }
                              value={true}
                            >
                              Yes
                            </option>
                            <option
                              selected={
                                selectedOption == updateSuggestedLeague
                                  ? "selected"
                                  : ""
                              }
                              value={false}
                            >
                              No
                            </option>
                          </select>
                        </div> */}
                        {/* dropdown end */}
                      </div>
                      {/* <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> optaUUID:</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={uuidLB}
                              onChange={(e) => setLBUUID(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                        <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>Set arena TL :</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={arenaTlLB}
                              onChange={(e) => setLBArenaTl(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="place the arena time line identifier"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="edit__popup-rows">
                        <div
                          className="edit__popup-row"
                        // style={{ width: '70%', marginLeft: '50px' }}
                        >
                          <span>Live Blog Id :</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={liveBlogIdLB}
                              onChange={(e) => setLiveBlogIdLB(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Place the Live blog identifier"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span>Set Twitter :</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="text"
                              name="text"
                              value={twitterFeedLB}
                              onChange={(e) => setLBTwitterFeed(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Twitter"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                        <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Segment Id:</span>

                          <div className="relative w-full mb-3">
                            <input
                              type="number"
                              name="text"
                              value={segmentIdLB}
                              onChange={(e) => setLBSegmentId(e.target.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> ABBR:</span>
                          {leagueNameLB == "All" ? (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbrLB}
                                onChange={() =>
                                  alert(`Can not change league abbr of 'All'`)
                                }
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          ) : (
                            <div className="relative w-full ">
                              <input
                                type="text"
                                name="text"
                                value={abbrLB}
                                onChange={(e) => setLBAbbr(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=""
                              />
                            </div>
                          )}
                        </div>
                      </div> */}

                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Start Date:</span>

                          <div
                            className="relative w-full edit__popup-row"
                            style={{ position: "relative", zIndex: "1000000" }}
                          >
                            <DatePicker
                              autoOk
                              hideTabs
                              disabled
                              minDateMessage=""
                              // minDate={_dateTimeFormat(
                              //   new Date(),
                              //   'ddd, YYYY DD MMM hh:mm A'
                              // )}
                              format="yyyy/MM/dd"
                              value={startDateLB}
                              onChange={setLBStartDate}
                            />
                          </div>
                        </div>

                        <div className="edit__popup-row">
                          <span>End Date:</span>

                          <div
                            className="relative w-full"
                            style={{ position: "relative", zIndex: "100" }}
                          >
                            <DatePicker
                              autoOk
                              hideTabs
                              disabled
                              minDateMessage=""
                              // minDate={_dateTimeFormat(
                              //   new Date(),
                              //   'ddd, YYYY DD MMM hh:mm A'
                              // )}
                              format="yyyy/MM/dd"
                              value={endDateLB}
                              onChange={setLBEndDate}
                              popperProps={{ strategy: "fixed" }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Meta Content */}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Meta Title:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={title}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );
                                setTitle(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Meta Title"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span>H1 Tag:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={H1Tag}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );
                                setH1Tag(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter H1 Tag"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Meta Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={description}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Meta Description"
                          />
                        </div>
                      </div>

                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Meta keywords:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={keywords}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setKeywords(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Og Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={ogDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setOgDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Og Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <span> Twitter Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={twitterDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              ); setTwitterDescription(formattedValue)
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Twitter Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Twitter Image:</span>
                          <img src={twitterImage} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectTwitter}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> Og Image:</span>
                          <img src={ogImage} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectOg}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Selected:</span>
                          <img src={imgURLLB} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="selected"
                              onChange={onFileSelected}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> Un-Selected:</span>

                          <img src={imgURLUNLB} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileUnSelected}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>

                      {/**Banner Image */}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span>Banner Image Upload: </span>

                          <img
                            src={
                              bannerImageLB ? bannerImageLB : selectBannerImageLB
                            }
                            style={{ width: 40 }} alt=""
                          />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileBannerLB}
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                          <div className="edit__popup-inner"><i
                            onClick={removeBannerLB}

                            className="far fa-times-circle edit__popup-close"
                          ></i></div>
                          {/* <CancelIcon style={{ cursor: "pointer" }} onClick={removeBannerLB} /> */}
                        </div>
                      </div>

                      <div className={"edit__popup-row button-row"}>
                        <div
                          onClick={() => !isLoading && onUpdateLeagueBank()}
                          className={"save btn"}
                          style={{ width: "fit-content" }}
                        >
                          Add to League Bank
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                {showGPT ?

                  <div style={{ maxWidth: "600px", marginLeft: "5px", borderLeft: "2px solid" }}>
                    <GptSection data={gptData} show={setShowGPT} />

                  </div>
                  : ""}
              </div>
            </div>
          </div>
        </div>
        {/* League bank edit end */}

        {/* Add League to main League list */}
        <div className="edit__popup">
          <div
            onClick={() => setShowLeagueAdd(false)}
            className={
              showLeagueAdd ? "edit__backdrop active" : "edit__backdrop"
            }
          ></div>

          <div
            className={
              showLeagueAdd ? (showGPT ? "edit__popup-body-gpt active" : "edit__popup-body active") : "edit__popup-body"
            }
            style={{ zIndex: 1000, display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "100%" }}>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>

                  <h1 className="edit__popup-heading">Add League/Competition</h1>
                  <h3 className="edit__popup-mandatory">
                    Mandatory Fields are marked with *
                  </h3>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>

                  {gptLoading ? <div className="loader"></div> : ""}
                  {/* {showGPT ? <span>Scroll down for content</span>:""} */}
                  <Button
                    variant="contained"
                    style={{ margin: "10px", marginRight: "50px", backgroundColor: "#28faae" }}
                    onClick={() => { generateGpt() }}>Generate GPT META CONTENT</Button>
                  <i
                    onClick={() => { setShowLeagueAdd(false); setShowGPT(false); setDescription(""); setTitle(""); setKeywords(""); setOgDescription(""); setH1Tag(""); }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                </div>
              </div>
              <div className={!showGPT ? "edit__popup-inner" : "edit__popup-inner-gpt"} >
                <div style={{ marginRight: "5px" }}>
                  <div className="edit__popup--form">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>League Name:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={leagueNameLeague}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9 ]/g,
                                  ""
                                );
                                setLeagueNameLeague(formattedValue);
                                setGptKeyword(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter League Name"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>League Id:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={addLeagueId}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9]/g,
                                  ""
                                );

                                setAddLeagueId(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter League ID"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> ABBR:</span>

                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={abbrLeague}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9 ]/g,
                                  ""
                                );
                                setLeagueAbbr(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Abbreviation"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Season:</span>
                          <div className="relative w-full ">
                            <input
                              type="number"
                              name="text"
                              value={season}
                              onChange={(e) => { setSeason(e.target.value) }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Season"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Vendor*/}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span>Vendor Name:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={vendorName}
                              onChange={(e) => setVendorName(e?.target?.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Vendor Name"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span>Vendor Id:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={addVendorId}
                              onChange={(e) => setAddVendorId(e?.target?.value)}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Vendor ID"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="edit__popup-row"
                        style={{ marginLeft: "50px" }}
                      >
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Select Sports: </span>
                        <SportsWithoutReduxDropDown
                          onChange={onSportsSelected}
                          selectedSports={sportsSelected}
                        />
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>Start Date:</span>

                          <div
                            className="relative w-full edit__popup-row"
                            style={{ position: "relative", zIndex: "1000000" }}
                          >
                            <DatePicker
                              autoOk
                              hideTabs
                              minDateMessage=""
                              format="yyyy/MM/dd"
                              value={startDateLeague}
                              onChange={setLeagueStartDate}
                            />
                          </div>
                        </div>

                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span>End Date:</span>

                          <div
                            className="relative w-full"
                            style={{ position: "relative", zIndex: "100" }}
                          >
                            <DatePicker
                              autoOk
                              hideTabs
                              minDateMessage=""
                              format="yyyy/MM/dd"
                              value={endDateLeague}
                              onChange={setLeagueEndDate}
                              popperProps={{ strategy: "fixed" }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Meta Content */}
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <i
                            className="fa fa-star"
                            style={{ "font-size": "6px", color: "red" }}
                          ></i>
                          <span> Meta Title:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={title}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );
                                setTitle(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Meta Title"
                            />
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span>H1 Tag:</span>
                          <div className="relative w-full ">
                            <input
                              type="text"
                              name="text"
                              value={H1Tag}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                  ""
                                );
                                setH1Tag(formattedValue);
                              }}
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter H1 Tag"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Meta Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={description}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Meta Description"
                          />
                        </div>
                      </div>

                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Meta keywords:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={keywords}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setKeywords(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <i
                          className="fa fa-star"
                          style={{ "font-size": "6px", color: "red" }}
                        ></i>
                        <span>Og Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={ogDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              );
                              setOgDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Og Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-row">
                        <span> Twitter Description:</span>
                        <div className="relative w-full ">
                          <input
                            type="text"
                            name="text"
                            value={twitterDescription}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const formattedValue = inputValue.replace(
                                /[^A-Za-z0-9\u0900-\u097F &|:;,-\.!?_@'"“()]/g,
                                ""
                              ); setTwitterDescription(formattedValue);
                            }}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Twitter Description"
                          />
                        </div>
                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Twitter Image:</span>
                          <img src={twitterImage} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectTwitter}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="edit__popup-row">
                          <span> Og Image:</span>
                          <img src={ogImage} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileSelectOg}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>

                      </div>
                      <div className="edit__popup-rows">
                        <div className="edit__popup-row">
                          <span> Banner Image:</span>
                          <img src={banerImageMain} style={{ width: 40 }} alt="" />
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              name="unselected"
                              onChange={onFileMainBanner}
                              accept=".png"
                              style={{
                                backgroundColor: "#000",
                                height: 35,
                                width: 40,
                                position: "absolute",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: "#555",
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                          <div className="edit__popup-inner"><i
                            onClick={removeMain}

                            className="far fa-times-circle edit__popup-close"
                          ></i></div>
                          {/* <CancelIcon style={{cursor:"pointer"}} onClick={removeMain}/> */}
                        </div>
                      </div>
                      <div className={"edit__popup-row button-row"}>
                        <div
                          onClick={() => !isLoading && onLeagueAdd()}
                          className={"save btn"}
                        >
                          Add
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                  <SyncLoader
                    color="#23A5E0"
                    loading={isLoading}
                    css={override}
                    size={12}
                  />
                </div>
                {showGPT ?

                  <div style={{ maxWidth: "600px", marginLeft: "5px", borderLeft: "2px solid" }}>
                    <GptSection data={gptData} show={setShowGPT} />
                  </div>
                  : ""}
              </div>
            </div>
          </div>
        </div>

        {/** Rank suggested League */}
        <div className="edit__popup">
          <div
            onClick={() => {
              setShowRankeUpdate(false);
              updateSuggestedButtonTap && UpdateRankOfLeague();
            }}
            className={
              showRankUpdate ? "edit__backdrop active" : "edit__backdrop"
            }
          ></div>

          <div
            className={
              showRankUpdate ? "edit__popup-body active" : "edit__popup-body"
            }
            style={{ zIndex: 1000, display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "100%" }}>

              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                <div>
                  <h1 className="edit__popup-heading">Ranking of Suggested League</h1>
                  <h3 className="edit__popup-mandatory" >
                    Drag the league to the desired position.
                  </h3>
                </div>
                <div>
                  {rankChanged && <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px", marginTop: "5px", backgroundColor: "#dd0019", }}
                    onClick={() => {
                      setUpdatedDocs(suggestedLeagueList);
                      setUpdatedRank([]);
                      setRankChanged(false);
                    }}
                  >
                    Reset
                  </Button>}
                  {rankChanged && <Button
                    variant="contained"

                    style={{ marginTop: "5px", marginRight: "40px", backgroundColor: "#6cd15f", color: "#FFFFFF", fontWeight: "700" }}
                    onClick={!isLoading && UpdateRankOfLeague}
                  >
                    Update Rank
                  </Button>}

                  <i
                    onClick={() => { setShowRankeUpdate(false); updateSuggestedButtonTap && UpdateRankOfLeague(); }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                </div>

              </div>
              <div >
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                                  (props.color === "light"
                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                                style={{ width: "10%" }}
                              >
                                Rank
                              </th>
                              <th
                                className={
                                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                                  (props.color === "light"
                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                                style={{ width: "30%" }}
                              >
                                League Name
                              </th>
                              <th
                                className={
                                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                                  (props.color === "light"
                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                                style={{ width: "20%" }}
                              >
                                Sport
                              </th>
                              <th
                                className={
                                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                  (props.color === "light"
                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                                style={{ width: "20%" }}
                              >
                                Start Date
                              </th>
                              <th
                                className={
                                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                  (props.color === "light"
                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                }
                                style={{ width: "20%" }}
                              >
                                End Date
                              </th>




                            </tr>
                          </thead>
                          <tbody>
                            {updatedDocs?.map((item, index) => {
                              return (
                                <Draggable
                                  key={item._id}
                                  draggableId={item._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <td className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4 " style={{ width: "10%" }}>
                                        {index + 1}
                                      </td>
                                      <td className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4 " style={{ width: "30%" }}>
                                        {item?.comp_name}
                                      </td>

                                      <td
                                        className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-2"
                                        style={{ cursor: "pointer", width: "20%" }}
                                      >
                                        {item.sports?.name}
                                      </td>
                                      <td
                                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2  "
                                        style={{ cursor: "pointer", fontWeight: "bolder", width: "20%" }}
                                      >
                                        {_dateTimeFormat(item?.dateStart, "ddd, DD MMM YYYY")}
                                      </td>
                                      <td
                                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2  "
                                        style={{ cursor: "pointer", fontWeight: "bolder", width: "20%" }}
                                      >
                                        {_dateTimeFormat(item?.dateEnd, "ddd, DD MMM YYYY")}
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              );
                            })}
                          </tbody>
                        </table>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <div style={{ textAlign: "center", padding: 10 }}>
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
              </div>
            </div>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingSuggested}
              css={override}
              size={12}
            />
          </div>
        </div>

        {/** Main display Page */}
        <Sidebar />

        <div style={{ marginLeft: "280px", width: "76%" }}>
          <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
            <h3>League List</h3>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>

                <input
                  style={{

                    border: '2px solid #acb9ce',
                    borderRadius: 10,
                    // marginRight: '3%',
                    padding: '4px',
                    // paddingLeft: '10px',
                    marginLeft: "0px"

                  }}
                  placeholder="Search Leagues"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setShowLeagueAdd(true);
                  setSportsFilter("All");
                  setTitle("");
                  setH1Tag("");
                  setDescription("");
                  setKeywords("");
                  setOgDescription("");
                  setTwitterDescription("")
                  setCompName("")
                  setVendorName("")
                  setTwitterImage("")
                  setOgImage("");
                  setBanerImageMain("");
                }}
              >
                Add League
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "5px" }}
                onClick={arrangingRanking}
              >
                Set Rank for Suggested League
              </Button>
            </div>
          </div>
          <div style={{ display: "inline-block", marginLeft: 10 }}>
            {/* <SyncLoader
              color="#23A5E0"
              loading={isLoading}
              css={override}
              size={10}
            /> */}
          </div>
          <div
            style={{
              // width: "80%",
              display: "flex",
              marginBottom: "10px",
              alignItems: "center"
            }}
          >
            {/** sports Filter */}
            <div style={{ marginRight: "5px" }}>
              <label>Sport</label>
              <SportsDropDown

                onChange={sportsFilterChange}
              />
            </div>

            {/** date Filter */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="relative ">
                <DatePicker
                  label="Start Date"
                  autoOk
                  hideTabs
                  format="dd/MM/yyyy"
                  value={startDateFilter}
                  onChange={(time) => setStartDateFilter(time)}
                  style={{
                    marginRight: "10px",
                    width: "40%",
                  }}
                // disableToolbar
                // maxDate={endDateFilter}
                />
                <DatePicker
                  label="End Date"
                  // emptyLabel="End Date"
                  autoOk
                  hideTabs
                  format="dd/MM/yyyy"
                  value={endDateFilter}
                  onChange={(e) => setEndDateFilter(e)}
                  style={{
                    width: "40%",
                  }}
                // disableToolbar
                // minDate={startDateFilter}
                />
              </div>
            </MuiPickersUtilsProvider>

            {/** League Type Filter*/}
            <div>
              <label>League Type</label>
              <select
                style={{
                  // marginLeft: 20,
                  border: '2px solid #acb9ce',
                  borderRadius: 10,
                }}
                value={listFilter}
                onChange={(e) => { setListFilter(e.target.value) }}
              >
                <option value="all">Select </option>
                <option value="leagueBank">League Bank</option>
                <option value="suggessted">Suggested League</option>
              </select>
            </div>

            {/**Display Filter */}
            <div>
              <label>Display Type</label>
              <select
                style={{
                  // marginLeft: 20,
                  border: '2px solid #acb9ce',
                  borderRadius: 10,
                }}
                value={displayFilter}
                onChange={(e) => { setDisplayFilter(e.target.value) }}
              >
                <option value="all">ALL </option>
                <option value="featured">Featured</option>

              </select>
            </div>

            {/**Sort By Filter */}
            <div>
              <label>Sort By</label>
              <select
                style={{
                  // marginLeft: 20,
                  border: '2px solid #acb9ce',
                  borderRadius: 10,
                }}
                value={sortByFilter}
                onChange={(e) => { setSortByFilter(e.target.value) }}
              >
                <option value="all">Select </option>
                <option value="rank">Rank</option>
                <option value="createdAt">Created At</option>
              </select>
            </div>

            {/**Filter Reset button */}
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "5px", width: "fit-content" }}
              onClick={() => {
                setSportsFilter("All");
                setStartDateFilter(null);
                setEndDateFilter(null);
                setListFilter("all");
                setDisplayFilter("all");
                dispatchSelectedSports({
                  selectedSports: "All",
                  selectedSportsFilter: "All",
                });
                setSortByFilter("all");
                setSearchString("");
              }}
            >
              Reset Filter
            </Button>
          </div>

          {/**Main League List */}
          <div style={{
            textAlign: "left"
          }}>

            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table className="table-fixed">
                <thead>
                  <tr>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                      style={{ width: "25%" }}
                    >
                      League Name
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                      style={{ width: "15%" }}
                    >
                      Sport
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                      style={{ width: "20%" }}
                    >
                      Start Date
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                    >
                      End Date
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                      style={{ width: "20%" }}
                    >
                      Add To League Bank
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                      style={{ width: "20%" }}
                    >
                      Add To Suggested League
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                      style={{ width: "20%" }}
                    >
                      Mark As Featured
                    </th>
                    {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                  style={{width:"20%"}}
                >
                  Show on Web
                </th> */}
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                      style={{ width: "5%" }}
                    >
                      Action / Edit
                    </th>

                  </tr>
                </thead>

                <tbody>


                  {leagueList?.docs?.map((league, index) => (
                    <tr>
                      <td
                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        style={{ cursor: "pointer", fontWeight: "bolder", width: "25%" }}
                      >
                        {league?.comp_name}
                      </td>
                      <td
                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        style={{ cursor: "pointer", fontWeight: "bolder", width: "25%" }}
                      >
                        {league?.sports?.name}
                      </td>
                      <td
                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        style={{ cursor: "pointer", fontWeight: "bolder", width: "25%" }}
                      >
                        {_dateTimeFormat(league?.dateStart, "ddd, DD MMM YYYY")}
                      </td>
                      <td
                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        style={{ cursor: "pointer", fontWeight: "bolder", width: "25%" }}
                      >
                        {_dateTimeFormat(league?.dateEnd, "ddd, DD MMM YYYY")}
                      </td>
                      <td
                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        style={{ cursor: "pointer", fontWeight: "bolder", width: "25%" }}
                      >
                        <Checkbox
                          checked={league?.presentInLeagueBank}
                          onChange={() => { OnClickEditLeagueBank(league) }} // Add to League Bank 
                          color="default"
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                      </td>
                      <td
                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        style={{ cursor: "pointer", fontWeight: "bolder", width: "25%" }}
                      >
                        <Checkbox
                          checked={league?.presentInDefaultLeague}
                          onChange={() => OnClickSuggestedLeague(league)} //Add to Suggested League
                          color="default"
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                      </td>
                      <td
                        className="border border-green-800  border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                        style={{ cursor: "pointer", fontWeight: "bolder", width: "25%" }}
                      >
                        <Checkbox
                          checked={league?.isFeaturedLeague}
                          onChange={() => tagFeaturedLeague(league)} // Tag League as a featured
                          color="default"
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                      </td>

                      <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style={{ width: "5%" }} >
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            marginRight: "10px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            OnClickEditCompetition(league);  //Edit Competition league button
                            setLBImgURL("");
                          }
                          }
                        ></i>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    page={page}
                    onChange={handlePagination}
                    count={leagueList?.totalPages}
                    variant="outlined"
                    color="primary"
                  />
                </Stack>
              </div>
              <div style={{ textAlign: "center", padding: 10 }}>

                {/** main League loader */}
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />

                {/**Suggested League Loader */}
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoadingSuggested}
                  css={override}
                  size={12}
                />
              </div>
            </div>
          </div>

        </div>
      </div >
    </>
  );
};

LeagueManagement.defaultProps = {
  color: "light",
};

LeagueManagement.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

export default LeagueManagement;