import AppConstants from '../constants';
import LoginAppConstants from '../constants/LoginAppConstants';
import AxiosInstance from './http.handlers';

const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = {
    'cms-access-token': accessToken,
    source: 'cms',
    language: CURRENT_LANGUAGE,
};

export const getWhatsNewList = async (body) => {
    try {
        console.log('body', body)
        let query = `?limit=${body.limit}&page=${body.page}`
        if (body.start) {
            query = query + `&start=${body.start}`
        }
        if (body.end) {
            query = query + `&end=${body.end}`

        }
        if(body.search){
            if(body?.search['title']?.length > 0){

                query = query + `&search=title:${body.search["title"]}`
            }else{
                query = query + `&search=version:${body.search["version"]}`

            }
        }
        const response = await AxiosInstance.get(
            `${AppConstants.WorkerConstants.API_ROUTES.WHATS_NEW.GET_LIST}`+query,
            {
                headers, 
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const getWhatsNew = async (id) => {
    try {
        const response = await AxiosInstance.get(
            `${AppConstants.WorkerConstants.API_ROUTES.WHATS_NEW.GET_WHATS_NEW}?id=${id}`,
            {
                headers,
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const addWhatsNews = async (body) => {
    try {
        const response = await AxiosInstance.post(
            `${AppConstants.WorkerConstants.API_ROUTES.WHATS_NEW.ADD_WHATS_NEW}`,
            body,
            { 
                headers,
             }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const updateWhatsNews = async (id,data) => {
    try {
        let body = {
            id:id,
            data:data
        }
        const response = await AxiosInstance.post(
            `${AppConstants.WorkerConstants.API_ROUTES.WHATS_NEW.UPDATE_WHATS_NEW}`,
            body,
            {
                headers,
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}