import { configStore } from '../store';
import { ProductivityActionType } from '../action-types';
export const dispatchStartDateForNews = (payload) => {
  configStore.dispatch({
    payload,
    type: ProductivityActionType.NEWS_START_DATE,
  });
};
export const dispatchEndDateForNews = (payload) => {
  configStore.dispatch({
    payload,
    type: ProductivityActionType.NEWS_END_DATE,
  });
};
export const dispatchStartDateForVideos = (payload) => {
  configStore.dispatch({
    payload,
    type: ProductivityActionType.VIDEOS_START_DATE,
  });
};
export const dispatchEndDateForVideos = (payload) => {
  configStore.dispatch({
    payload,
    type: ProductivityActionType.VIDEOS_END_DATE,
  });
};
