import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { API_BASE_PATH, OLD_BASE_PATH } from '../../constants/workerConstants';

import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatchSelectedNewsHyperLink } from '../../redux/action-dispatchers/newsHyperlink.dispatchers';
import LoginAppConstants from '../../constants/LoginAppConstants';
import { GetPaginatedNews } from '../../handlers/news.handlers';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const NewsHyperlinkDropDown = (props) => {
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const getTopLeagues = () => {
    const body = {
      page: page,
      limit: 250,
      showArticle: true,
      searchKey: props.searchKey,
    };
    GetPaginatedNews(body)
      .then((response) => {
        setDocs(response.data.docs);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getTopLeagues();
  }, [props.searchKey]);
  const onChange = (event) => {
    const foundObject = _.find(docs, ['_id', event.target.value]);

    props.onChange && props.onChange(event.target.value, foundObject._id);
    getTopLeagues();
    dispatchSelectedNewsHyperLink({
      selectedNewsHyperLink: event.target.value,
      selectedNewsHeadlineLink: foundObject.headline,
    });
  };
  //
  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      value={props.selectedNewsHyperLink}
      onChange={onChange}
    >
      <option value="All">Select News To HyperLink</option>
      {docs.map((newsHyperlink) => (
        <>
          {/* {console.log('news hyper link', newsHyperlink.headline)} */}
          <option value={newsHyperlink._id}>{newsHyperlink.headline}</option>
        </>
      ))}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedNewsHyperLink: state.newsHyperLinkReducer.selectedNewsHyperLink,
  };
};

export default connect(mapStateToProps)(NewsHyperlinkDropDown);
