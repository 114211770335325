import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import CropperModal from '../../../components/CropperModal';
import {
  GetNewsInfo,
  mapArticleToSports,
  mapArticleToLeague,
  toggleArticleStatus,
  
} from '../../../handlers/news.handlers';
import { getAllSports } from '../../../handlers/sports.handler';
import LeaguesDropDown from '../../../components/Dropdowns/LeaguesDropDown';
import SportsDropDown from '../../../components/Dropdowns/SportsDropDown';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {
  dispatchAllLeagues,
  dispatchSelectedLeague,
} from '../../../redux/action-dispatchers/leagues.dispatchers';
import {
  dispatchAllSports,
  dispatchSelectedSports,
} from '../../../redux/action-dispatchers/sports.dispatchers';
import { GetAllDefaultLeagues } from '../../../handlers/leagues.handlers';
import ReactDevicePreview from 'react-device-preview';
import PreviewModal from '../../../components/PreviewModal';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AddArticle = (props) => {
  const [newsInfo, setNewsInfo] = useState(undefined);
  const [showCropper, setShowCropper] = useState(false);
  const [taggedLeague, setTaggedLeague] = useState('');
  const [changeLeague, setChangeLeague] = useState(false);
  const [taggedSport, setTaggedSport] = useState(''); // Sports
  const [changeSport, setChangeSport] = useState(false); // Sports
  const [isImageUploading, setIsImageUploading] = useState(false);

  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });

  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState();
  let fileInput = React.useRef();

  useEffect(() => {
    GetAllDefaultLeagues({
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        dispatchAllLeagues(response.data);
      }
    });

    getAllSports({
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        dispatchAllSports(response.data);
      }
    });

    getNewsInformation();
    //onSportSelected();  //Sport
  }, []);

  useEffect(() => {
    if (newsInfo) {
      const leagueInfo = props.leagues.find(
        (item) => item.comp_id === newsInfo.league
      );

      if (leagueInfo) {
        setTaggedLeague(leagueInfo.abbr);
      }
    }
  }, [newsInfo, props.leagues]);

  //Sport Start

  //Sport End

  const getNewsInformation = () => {
    GetNewsInfo(id, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        setNewsInfo(response.data);
      }
    });
  };

  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setNewsInfo({
      ...newsInfo,
      imgUrl: imagePath,
    });
    // sendArticleImage(newsInfo._id, imageData, {
    //   Authorization: props.Authorization,
    // })
    //   .then((response) => {})
    //   .catch((error) => {});

    setShowCropper(false);
  };

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
          });
        } else {
          alert('Image width and height must be 1920x1080 px');
          return false;
        }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) {};
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    var publishedStatus = newsInfo.status;
    publishedStatus === 1 ? (publishedStatus = 0) : (publishedStatus = 1);
    toggleArticleStatus(newsInfo._id, publishedStatus, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        getNewsInformation();
      }
    });
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const showModalForPublish = () => {
    var title = 'Publish/Unpublish Article';
    var message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onLeagueSelected = (value) => {
    setChangeLeague(false);
    newsInfo &&
      value !== 0 &&
      mapArticleToLeague(newsInfo._id, value, '')
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            alert(response.message);
          }
        })
        .catch((error) => {});
  };

  // Sport Start
  const onSportSelected = (value) => {
    setChangeSport(true);
    newsInfo &&
      value !== 0 &&
      mapArticleToSports(newsInfo._id, value, '')
        .then((response) => {
          if (response.status) {
            getNewsInformation();
            alert(response.message);
          }
        })
        .catch((error) => {});
  };

  // Sport End

  const onPreviewClose = () => setPreviewModal({ imageData: '', show: false });
  const onPreviewSuccess = () => {
    setIsImageUploading(true);
    setNewsInfo({
      ...newsInfo,
      imgUrl: previewModal.imageData,
    });
    setPreviewModal({
      imageData: '',
      show: false,
    });
    // sendArticleImage(newsInfo, previewModal.imageData, {
    //   Authorization: props.Authorization,
    // })
    //   .then((response) => {
    //     setIsImageUploading(false);
    //   })
    //   .catch((error) => {
    //     setIsImageUploading(false);
    //   });
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {/* <div>Video Page Render</div> */}
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            {showCropper && newsInfo && (
              <CropperModal
                image={selectedImage}
                onClose={onCropperClose}
                closeCropperAndSetData={closeCropperAndSetData}
              />
            )}
            {previewModal.show && (
              <PreviewModal
                imageData={previewModal.imageData}
                title="Upload Article Image"
                message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                onSuccess={onPreviewSuccess}
                onClose={onPreviewClose}
              />
            )}
            {
              <div>
                <div>
                  <div
                    style={{ display: 'inline-block', verticalAlign: 'top' }}
                  >
                    <img
                      // src={ newsInfo !== undefined &&
                      //   newsInfo.media && newsInfo.imgUrl !== undefined ? newsInfo.imgUrl :  newsInfo.media.thumbnail[0].url
                      // }
                      style={{
                        width: 500,
                        height: 300,
                        border: '2px solid gray',
                      }} // yha pehla Sir width thi vo bhi 500px for knowledge
                    />
                    {isImageUploading ? (
                      <div style={{ textAlign: 'center', padding: 10 }}>
                        <div>Uploading Image Please Wait...</div>
                        <div>
                          <PulseLoader
                            color="#23A5E0"
                            loading={true}
                            css={override}
                            size={10}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>
                          Please upload image in the dimension of 1920x1080
                        </div>
                        <div>
                          <input
                            ref={fileInput}
                            type="file"
                            multiple={false}
                            onChange={onFileSelect}
                            accept="image/x-png,image/jpeg"
                            style={{
                              backgroundColor: '#000',
                              height: 35,
                              width: 40,
                              position: 'absolute',
                              opacity: 0,
                              cursor: 'pointer',
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                              margin: 8,
                              color: '#555',
                              cursor: 'pointer',
                            }}
                          ></i>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                      padding: 20,
                    }}
                  >
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div>
                        {
                          // newsInfo.status === 1
                          // ? 'Video is published. Click below to unpublish this Video'
                          // :
                          'Article is not published. Click below to publish this Article'
                        }
                      </div>

                      <button
                        className={`bg-${
                          // newsInfo.status === 1 ? 'red' :
                          'emerald'
                        }-500 text-white active:bg-${
                          // newsInfo.status === 1 ? 'red' :
                          'emerald'
                        }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        onClick={showModalForPublish}
                      >
                        {
                          // newsInfo.status === 1
                          // ? 'Unpublish Video'
                          // :
                          'Publish Article'
                        }
                      </button>

                      {/* Draft button */}
                      <button
                        className={`bg-${
                          // newsInfo.status === 1 ? 'red' :
                          'emerald'
                        }-500 text-white active:bg-${
                          // newsInfo.status === 1 ? 'red' :
                          'emerald'
                        }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        // onClick={(showModalForPublish, minWords)}
                        style={{ backgroundColor: 'red', marginLeft: '10px' }}
                      >
                        {
                          // newsInfo.status === 1
                          // ? 'Unpublish Video'
                          // :
                          'Draft Article'
                        }
                      </button>
                      {/* Draft Button End */}
                    </div>
                    <div>Tagged League</div>
                    {!changeLeague && (
                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                        {taggedLeague}{' '}
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeLeague(true);
                            // dispatchSelectedLeague({
                            //   selectedLeague: newsInfo.league,
                            //   selectedLeagueFilter: newsInfo.league,
                            // });
                          }}
                        ></i>
                      </div>
                    )}
                    {changeLeague && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <LeaguesDropDown onChange={onLeagueSelected} />
                        <i
                          className="fas fa-times fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          // onClick={() => {
                          //   setChangeLeague(false);
                          // }}
                        ></i>
                      </div>
                    )}

                    {/* Key Word */}
                    <form>
                      <label>Key Words</label>
                      <br></br>
                      <input
                        type="text"
                        placeholder="Key Words Sports, Player name, Event etc.. "
                        style={{ width: '500px', height: '30px' }}
                      />
                    </form>
                    <br></br>
                    {/* Key Word End */}

                    {/* Tagged Sport  */}

                    <div>Tagged Sports</div>
                    {!changeSport && (
                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                        {taggedSport}{' '}
                        <i
                          className="fas fa-edit fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeSport(true);
                            // dispatchSelectedSports({
                            //   selectedSports: newsInfo.sports,
                            //   selectedSportsFilter: newsInfo.sports,
                            // });
                          }}
                        ></i>
                      </div>
                    )}
                    {changeSport && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <SportsDropDown onChange={onSportSelected} />{' '}
                        <i
                          className="fas fa-times fa-1x"
                          style={{
                            margin: 8,
                            color: '#c3c3c3',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setChangeSport(false);
                          }}
                        ></i>
                      </div>
                    )}

                    {/* End Tagged Sports  */}

                    <div>
                      {/* <div>Headline</div> */}
                      <div>
                        {/* {newsInfo.title} */}
                        <form>
                          <label>Headline</label>
                          <br></br>
                          <input
                            type="text"
                            placeholder="Headlines "
                            style={{ width: '500px', height: '30px' }}
                          />
                        </form>
                      </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <div>Published At</div>
                      {/* <div>{newsInfo.PublishedAt}</div> */}
                      {/* {
                      newsInfo.copyrights === 'PTI'
                        ? _dateTimeFormat(
                            newsInfo.date,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(
                            newsInfo.date,
                            'ddd, DD MMM hh:mm A'
                          )} */}
                    </div>
                    <div style={{ marginTop: 20 }}>
                      {/* <div>News Type</div> */}
                      <div>
                        {/* {newsInfo.copyright} */}
                        <form>
                          <label> Articles Type</label>
                          <br></br>
                          <input
                            type="text"
                            placeholder="Sports Tak "
                            style={{ width: '100px', height: '30px' }}
                          />

                          <input
                            type="text"
                            placeholder="Editor's Name "
                            style={{
                              width: '124px',
                              height: '30px',
                              marginLeft: '20px',
                            }}
                          />
                        </form>
                      </div>
                    </div>
                    <br></br>
                    {/* <textarea name="desc" placeholder="Description (Min 20 Words, Max 200 Words)" 
                       styel={{fontSize: "16px",
                            height: "auto",
                            margin: 0,
                            outline: 0,
                            padding: "15px",
                            width: "100%",
                            maxWidth:"300px"
                          }}>
                     </textarea> */}
                    {/* <td>ADDRESS <br /><br /><br /></td> */}
                    <td>
                      <textarea
                        name="Address"
                        rows="3"
                        cols="100"
                        id="textbox"
                        placeholder="Write the Article Here...   (Min 100 Words) "
                      ></textarea>
                    </td>
                  </div>
                </div>
                <div
                  style={{ paddingTop: 30 }}
                  //   dangerouslySetInnerHTML={{ __html: newsInfo.story }}
                >
                  {/* {newsInfo.description} */}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leagues: state.leaguesReducer.leagues,
    sports: state.sportsReducer.sports,
  };
};
export default connect(mapStateToProps)(AddArticle);

//
