import { configStore } from '../store';
import { AuthActionTypes } from '../action-types';

export const dispatchLoginUser = (payload) => {
  configStore.dispatch({
    payload,
    type: AuthActionTypes.LOGIN,
  });
};
export const dispatchUserLanguagesAllowed = (payload) => {
  configStore.dispatch({
    payload,
    type: AuthActionTypes.LANGUAGES_ALLOWED,
  });
};
export const dispatchCurrentLanguage = (payload) => {
  configStore.dispatch({
    payload,
    type: AuthActionTypes.CURRENT_LANGUAGE,
  });
};
export const dispatchUserLanguages = (payload) => {
  configStore.dispatch({
    payload,
    type: AuthActionTypes.USER_LANGUAGE,
  });
};
export const dispatchUserRole = (payload) => {
  configStore.dispatch({ payload, type: AuthActionTypes.GET_USER_ROLE });
};
export const dispatchModulePermission = (payload) => {
  configStore.dispatch({ payload, type: AuthActionTypes.GET_USER_MODULE });
};
export const dispatchUserProfilePicture = (payload) => {
  configStore.dispatch({
    payload: payload,
    type: AuthActionTypes.PROFILE_IMAGE,
  });
};
export const dispatchFirstModuleName = (payload) => {
  configStore.dispatch({
    payload: payload,
    type: AuthActionTypes.FIRST_MODULE_NAME,
  });
};

export const dispatchUserLocation = (payload) => {
  configStore.dispatch({
    payload: payload,
    type: AuthActionTypes.USER_LOCATION,
  });
};
export const dispatchLogoutUser = () => {
  configStore.dispatch({
    payload: undefined,
    type: AuthActionTypes.LOGOUT,
  });
};
