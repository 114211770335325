import React from 'react'
import AdminSideBar from './AdminSideBar'
import UserManagerInfo from './userManagerInfo'
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const UserManager = () => {
    return (
        <div>
            <AdminSideBar/>
            <div
                className="relative md:ml-64 bg-blueGray-100"
                style={{ paddingTop: 10 }}
            >
            <div className="flex flex-wrap">
            <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
               <UserManagerInfo color='light'/>
             </div>
             </div>
             </div>
            
        </div>
    )
}

export default UserManager
