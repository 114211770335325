import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const GetPollsInfo = async (data) => {
    return await AxiosInstance.get(
      `${AppConstants.WorkerConstants.API_ROUTES.POLLS.GET_ALL_POLLS}?sort=${data.sort}&page=${data.page}&limit=${data.limit}`,
    )
      .then((response) => response)
      .catch((error) => error);
};
export const GetPollsForRank = async () => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.POLLS.GET_ALL_POLLS_FOR_RANK}`,
  )
    .then((response) => response)
    .catch((error) => error);
};
export const DeletePolls= async (pollId) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.POLLS.DELETE_POLLS}`,
    pollId,
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddPolls = async (body) => {

  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.POLLS.ADD_POLLS}`,
    body,
  )
    .then((response) => response)
    .catch((error) => error);
};
export const EditPolls = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.POLLS.EDIT_POLLS}`,
    body,
  )
    .then((response) => response)
    .catch((error) => error);
};
export const ReorderPolls = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.POLLS.REORDER_POLLS}`,
    body,
  )
    .then((response) => response)
    .catch((error) => error);
};
