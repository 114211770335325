import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import SyncLoader from "react-spinners/SyncLoader";
import { useEffect, useState, useRef } from "react";
import { css } from "@emotion/core";
import { getWhatsNewList } from "../../handlers/whatsNew.handler";
import { _dateTimeFormatinIST } from "../../constants/appDefaults";
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
import { Button, Overlay, Tooltip, Dropdown } from "react-bootstrap"
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WhatsNewTable = (props) => {
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('title');
    const [search, setSearch] = useState('');
    const [startDate, setStart] = useState('');
    const [endDate, setEnd] = useState('');
    const history = useHistory();
    const handleChange = (event) => {
        // Update the state to the new value when the selection changes.
        setFilter(event.target.value);
    };

    useEffect(() => {
        setIsLoadingMore(true);
        let body = {
            limit: 10,
            page: page
        }
        getWhatsNewList(body)
            .then((response) => { setData(response); setIsLoadingMore(false) })
            .catch((error) => { console.log(error); setIsLoadingMore(false) });

    }, []);

    useEffect(() => {
        setIsLoadingMore(true);
        let body = {
            limit: 10,
            page: page
        }
        if(startDate != ""){
            let newDate = new Date(startDate)
            // newDate.setMinutes(newDate.getMinutes() + 330)
            newDate = new Date(newDate).toISOString();
            body = {
                ...body,
                start: newDate
            }
        }
        if(endDate != ""){
            let newDate = new Date(endDate)
            newDate.setMinutes(newDate.getMinutes() + 330)
            newDate = new Date(newDate).toISOString();
            body = {
                ...body,
                end: newDate
            }
        }
        getWhatsNewList(body)
            .then((response) => { setData(response); setIsLoadingMore(false) })
            .catch((error) => { console.log(error); setIsLoadingMore(false) });

    }, [startDate,endDate]);

    const searchData = () =>{
        setIsLoadingMore(true);
        let body = {
            limit: 10,
            page: page,
        }
        if (search != "") {
            body = {
                ...body,
                search:{
                    [filter]:search
                }
            }
        }
        getWhatsNewList(body)
            .then((response) => { setData(response); setIsLoadingMore(false) })
            .catch((error) => { console.log(error); setIsLoadingMore(false) });
    }
    const handlePagination = (event, value) => {
        setPage(value);
    };

    function CopyButton({ row }) {
        const [show, setShow] = useState(false);
        const target = useRef(null);

        const handleClick = async () => {
            if (row?.permalink) {
                if ("clipboard" in navigator) {
                    await navigator.clipboard.writeText(row.permalink);
                    setShow(true);
                    setTimeout(() => setShow(false), 500); // Hide the tooltip after 2 seconds
                }
            }
        };

        return (
            <>
                <Button
                    ref={target}
                    onClick={handleClick}
                    disabled={!row?.permalink}
                    title="Click to copy permalink"
                    style={{ outline: "none", minWidth: '20px', paddingLeft: "5px" }}
                    className={`btn btn-icon btn-light btn-sm mx-3`}
                >
                    <img
                        src="/copy-1.png"
                        alt="copy to clipboard"
                        style={{ width: "25px", height: "25px", marginLeft: "10px" }}
                    />
                </Button>

                <Overlay target={target.current} show={show} placement="top">
                    {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                            Copied!
                        </Tooltip>
                    )}
                </Overlay>
            </>
        );
    }
    return (
        <>
            <div
                className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (props.color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
                }
                style={{ display: 'block', }}
            >
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                <h3
                                    className={
                                        "font-semibold text-lg " +
                                        (props.color === "light" ? "text-blueGray-700" : "text-white")
                                    }
                                    style={{ marginBottom: "30px" }}
                                >
                                    What's New Feature
                                </h3>
                                <Link to="addWhatsNew" >
                                    <button style={{ marginBottom: '1.5%', height: "32px", cursor: "pointer", width: "100px", borderRadius: "10px", backgroundColor: "#50C878", color: "white" }}>Create</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex mb-6" style={{marginLeft:"20px",justifyContent:"space-evenly"}}>
                    <div style={{ display: "flex",flexDirection:"column" }}>

                        <label>Search</label>
                        <div>
                            <select style={{ borderRadius: "10px" }} name="filter" value={filter} onChange={handleChange}>
                                <option value="title">Title</option>
                                <option value="version">Version</option>
                            </select>
                            <input type="text" placeholder="Search" style={{ width: "300px" }} onChange={(e) => setSearch(e.target.value)}/>
                            <button style={{ marginBottom: '1.5%', height: "32px", cursor: "pointer", width: "80px", borderRadius: "10px", backgroundColor: "#50C878", color: "white" ,marginLeft:"10px"}} onClick={searchData}>Search</button>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" ,alignItems:"center"}}>

                        <label>Start Date</label>
                        <input type="datetime-local" style={{ width: "200px", border: "2px solid black" }} onChange={(e)=>setStart(e.target.value)}/>
                    </div >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" ,}}>
                        <label>End Date</label>

                        <input type="datetime-local" style={{ width: "200px", border: "2px solid black" }} onChange={(e) => setEnd(e.target.value)} />
                    </div>
                </div>

                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    {data?.length > 0 ? <table className="table-fixed">
                        <thead>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (props.color === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }
                                    style={{ width: "10%" }}
                                >
                                    Sr. No.
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (props.color === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }
                                    style={{ width: "10%" }}
                                >
                                    Title
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (props.color === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }
                                    style={{ width: "20%" }}
                                >
                                    Thumbnail
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (props.color === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }
                                    style={{ width: "20%" }}
                                >
                                    PermaLink
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (props.color === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }
                                    style={{ width: "20%" }}
                                >
                                    Version
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (props.color === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }
                                    style={{ width: "25%" }}
                                >
                                    Created At
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (props.color === "light"
                                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                    }
                                    style={{ width: "5%" }}
                                >
                                    Edit
                                </th> 
                            </tr>
                        </thead>

                        <tbody>

                            <SyncLoader
                                color="#23A5E0"
                                loading={isLoadingMore}
                                css={override}
                                size={12}
                            />

                                    
                            {data?.map((item, position) => (
                                <tr>
                                    <td
                                        className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                                        style={{ fontWeight: "bolder", width: "10%" }}
                                    >
                                        {position + 1}
                                    </td>
                                    <td
                                        className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                                        style={{ fontWeight: "bolder", width: "10%" }}
                                    >
                                        {item.title}
                                    </td>
                                    <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 " style={{ width: "20%" }}>

                                        <img
                                            src={item?.thumbnail}
                                            style={{
                                                // background: `url('${players?.player?.player_img}?${players?._id}') 0% 0% / cover no-repeat`,
                                                width: "80px",
                                                height: "80px",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        />
                                    </th>
                                    <td
                                        className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                                        onClick={() => {
                                            if (!item.permalink) {

                                                alert("Feature does not have permalink...")
                                            }

                                        }}
                                        style={{ cursor: "pointer", fontWeight: "bolder" }}
                                    >
                                        <div style={{ display: 'flex' }}>

                                            <a href={item?.permalink}
                                                target="_blank">
                                                <img src="/LinkIcon.png" style={{ width: "20px", height: "30px", marginLeft: "20px" }} />
                                            </a>
                                            <CopyButton row={item} />
                                        </div>
                                    </td>
                                    <td
                                        className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                                        style={{ fontWeight: "bolder", width: "10%" }}
                                    >
                                        {item?.version}
                                    </td>
                                    <td
                                        className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                                        style={{ fontWeight: "bolder", width: "10%" }}
                                    >
                                        {item.createdAt}
                                    </td>
                                    <td
                                        className="border border-green-800  border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
                                        style={{ fontWeight: "bolder", width: "10%" }}
                                    >
                                        <button style={{ backgroundColor:"rgb(80, 200, 120)",color:"white",padding:"10px",marginRight:"20px",borderRadius:"15px",width:"70px",cursor:"pointer"}} onClick={()=>{history.push(`whatsNew/${item.id}`)}}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table> :
                        <div style={{ width: '100%', textAlign: "center", marginTop: "40px", fontSize: "20px", fontWeight: "700" }}>
                            No Data Found.
                        </div>}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            padding: 20,
                        }}
                    >
                        <Stack spacing={2}>
                            <Pagination
                                page={page}
                                onChange={handlePagination}
                                // count={Math.floor(data?.totalCount / 10) + 1}
                                variant="outlined"
                                color="primary"
                            />
                        </Stack>

                    </div>
                    <div style={{ textAlign: "center", padding: 10 }}>
                        <SyncLoader
                            color="#23A5E0"
                            loading={isLoadingMore}
                            css={override}
                            size={12}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default WhatsNewTable;