import React, { useEffect,useRef ,useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import ReactQuill from "react-quill";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SyncLoader from "react-spinners/SyncLoader";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { css } from "@emotion/core";
import UnsavedChangesWarning from "../../../../components/AlertModal/UnsavedChangesWarning";
import { GetAllAuthor } from "../../../../handlers/author.handlers";
import {
  _getDateTime,
  _dateTimeFormat,
} from "../../../../constants/appDefaults";
import { GetPlayerInfo, UpdatePlayerImages, updatePlayer } from "../../../../handlers/playerProfile.handler";
import LeaguesDropDown from "../../../../components/Dropdowns/LeaguesDropDown";
import MultipleLeagueDropDown from "../../../../components/Dropdowns/MultipleLeagueDropDown";
import TeamsLeagueDropDown from "../../../../components/Dropdowns/TeamsLeagueDropDown";
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const PlayerInfo = (props) => {
  const [playerData, setPlayerData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [compDetails, setCompDetails] = useState([]);
  const [open, setOpen] = useState(false); // for Modal Open State
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const [metaDescriptionLength, setMetaDescriptionLength] = useState(0) // 
  const [metaDescription, setMetaDescription] = useState("") // 
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });
  const { id } = useParams();
  const [docs, setDocs] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [updateImage, setUpdateImage] = useState("");
  const [formData, setFormData] = useState({
    _id : "",
    socials:{},
    summary:"",
    details:{
    }, 
    keywords:"",
    author:"",
    compIds:[],
    meta_title:"",
    meta_description:""
  });
  const getAuthor = () => {
    GetAllAuthor(
      
    )
      .then((response) => {
        setDocs(response.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAuthor();
    window.scrollTo(0, 0);
  }, []);
  let fileInput = useRef();
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = '';

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if(height==width)
        {
          setSelectedImage(reader.result);
          setUpdateImage(reader.result);
        }
        else{
          alert("Please upload a square shaped photo of player...")
        }
      };
      // setSelectedImage(file);
      
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
    
  };
  {/**Modal open and close */}
  const handleOpen = () => {
    setOpen(true);
  };
  const onSuccess = () =>
  setConfirmationModal({ message: "", show: false, title: "" });
  const onAuthorSelected = (e) => {
    setFormData({ ...formData, author: e.target.value });
  };
  const updatePlayerImage = () =>{
    if(selectedImage)
    {
      const data = {
          _id : playerData?._id,
          image: selectedImage
      };
      const imageData = new FormData();
  
      Object?.keys(data)?.forEach(function (key) {
        imageData?.append(key, data[key]);
      });

      UpdatePlayerImages(imageData).then((response)=>{
       
          alert('Player Image uploaded Successfully!');
          setSelectedImage('');
          setUpdateImage('');
          window.location.reload(false);
      })
    }
    else{
      alert('Please upload a Image in given Ratio')
    }
    
  };
  const onLeaguesSelected = (league) => {
    setCompDetails(league);
  };
  const isValidURL = (url)=>{
    const regex =  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;    
    return regex.test(url);
  } 
  const onAddPlayer = () => {
    if (formData?.details?.about?.data?.length == 0 || formData?.details?.about?.data==undefined) {
      setConfirmationModal({
        message: "About Can not be empty",
        show: true,
        title: "",
      });
    } else if (formData?.details?.achievements?.data?.length==0 || formData?.details?.achievements?.data==undefined) {
      setConfirmationModal({
        message: "Achievements Can not be empty",
        show: true,
        title: "",
      });
    } else if (formData?.details?.career?.data?.length==0 || formData?.details?.career?.data==undefined) {
      setConfirmationModal({
        message: "Career Can not be empty",
        show: true,
        title: "",
      });
    }
    else if (formData?.details?.early_life?.data?.length==0 || formData?.details?.early_life?.data==undefined) {
      setConfirmationModal({
        message: "Early life description can not be empty! ",
        show: true,
        title: "",
      });
    } else if (formData?.details?.family?.data?.length==0 || formData?.details?.family?.data==undefined) {
      setConfirmationModal({
        message: "Family description can not be empty.",
        show: true,
        title: "",
      });
    } else if (formData?.socials?.facebook?.length===0 ||
         formData?.socials?.instagram?.length===0 || 
         formData?.socials?.twitter?.length===0 || 
         formData?.socials?.facebook==undefined ||
         formData?.socials?.instagram==undefined ||
         formData?.socials?.twitter==undefined) {
      setConfirmationModal({
        message: "Socials can not be empty.",
        show: true,
        title: "",
      });
    } 
    else if(formData?.author?.length==0)
    {
      setConfirmationModal({
        message: "Please tag author",
        show: true,
        title: "",
      });
    } 
    else if(formData?.summary?.length==0)
    {
      setConfirmationModal({
        message: "Please enter summary for player!!",
        show: true,
        title: "",
      });
    } 
    else if(compDetails?.length==0)
    {
      setConfirmationModal({
        message: "Please tag Leagues",
        show: true,
        title: "",
      });
    // } else if(!isValidURL(formData?.socials?.facebook)||!isValid(formData?.socials?.instagram)||!isValid(formData?.socials?.twitter))
    // {
    //   setConfirmationModal({
    //     message: "Please enter a valid social URLs.",
    //     show: true,
    //     title: "",
    //   });
    }
    else if (formData?.meta_title?.length == 0) {
      setConfirmationModal({
        message: "Meta title Cannot be empty.",
        show: true,
        title: "",
      });
      return;
    } else if (formData?.meta_title?.length < 50) {
      setConfirmationModal({
        message: "Please update your Meta title to minimum 50 Characters.",
        show: true,
        title: "",
      });
      return;
    } else if (formData?.meta_title?.length > 120) {
      setConfirmationModal({
        message: "Please update your Meta title to maximum 120 Characters.",
        show: true,
        title: "",
      });
      return;
    } else if (metaDescription?.length == 0) {
      setConfirmationModal({
        message: "Meta Descripiton Can not be empty.",
        show: true,
        title: "",
      });
      return;
    } else if (metaDescription?.length < 146) {
      setConfirmationModal({
        message: "Please update your Meta Description to minimum 140 Characters.",
        show: true,
        title: "",
      });
      return;
    } else if (metaDescription?.length > 206) {
      setConfirmationModal({
        message: "Please update your Meta Description to maximum 200 Characters.",
        show: true,
        title: "",
      });
      return;
    }
    else {
      setIsLoading(true);
      updatePlayer(
          {...formData, compIds:compDetails, meta_description:metaDescription}
      )
        .then((response) => {
          if (response.status) {
            setIsLoading(false);
            alert("Success..");
            window.location.reload(false);

          } else {
            alert("Failed..");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Erorr while updating Player", error);
        });
    }
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const onClose = () => {
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };

  useEffect(()=>{
    setIsLoading(true);
    GetPlayerInfo(id)
    .then((res)=>{

      console.log("data",res?.data)
      setPlayerData(res?.data)
      setIsLoading(false)
      // setFormData(res?.data)
    setFormData({ ...formData, 
      details:{...res?.data?.details},  
      author:res?.data?.author?res?.data?.author:"", 
      keywords:res?.data?.keywords?res?.data?.keywords:"",
      summary:res?.data?.summary?res?.data?.summary:"",
      socials:{...res?.data?.socials},
      _id:res?.data?._id,
      meta_title:res?.data?.meta_title?res?.data?.meta_title:"",
      meta_description:res?.data?.meta_description?res?.data?.meta_description:"",
      
})
setMetaDescription(res?.data?.meta_description?res?.data?.meta_description:"");
})
    .catch((error)=> error)
  }, []);
  useEffect(()=>{
    setUpdateImage(playerData?.player?.player_img)
  }, [playerData])
  return (
    <>
      <Sidebar />
      <SyncLoader
              color="#23A5E0"
              loading={isLoading}
              css={override}
              size={12}
            />
      {
        playerData ? 
        <>
        <div className="relative md:ml-64" style={{ padding: 50, display:open?'none':'block' }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
                <div>
                <div style={{display:"flex", marginBottom:"10px", marginTop:"10px", justifyContent:"space-between", width:"100%"}}>
                      <div >
                        <div style={{ display: "flex", }}>

                        <div style={{ fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Player Name:
                         <span style={{fontWeight:"400", marginLeft:"10px",}}>
                             {playerData?.player?.title}
                        </span>
                        </div>
                          <div style={{
                            marginTop: 0,
                            marginBottom: 10,
                            fontWeight: "bold",
                            marginRight: "80px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "25px"
                          }}>{`Permalink: `}
                            <a href={(props?.selectedLanguage?.toLowerCase()=="english"?"https://m.thesportstak.com":"https://m.hindi.thesportstak.com")+"/playerstats/cricket/"+playerData?.seo_slug}
                              target="_blank">
                              <img src="/LinkIcon.png" style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
                            </a>


                          </div>
                        </div>
                        
                        <div style={{ fontSize:"16px", fontWeight:"700"}}>Sports: 
                        <span style={{fontWeight:"400",marginLeft:"10px",}}>
                             Cricket
                        </span>
                        </div>
                        <div style={{ marginBottom:"10px"}}>
                    <div style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                            marginRight: "80px",
                            marginBottom:"10px"
                          }}>{`Player Socials URL (Please attach a proper full url)`}:
                    <div style={{border:"1px solid #111", width:"100%", padding:"10px", borderRadius:"10px"}}> 
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",  marginBottom:"10px"}}>
                            <div style={{width:"30px"}}>
                            <img
                        src={`${process.env.PUBLIC_URL}/assets/img/facebook.svg`}
                      />
                            </div>
                            <input
              type="url"
              placeholder="Facebook*"
              name="facebook"
              pattern="https://.*"
              value={formData?.socials?.facebook}
              onChange={(event) =>{
                  setFormData({ ...formData, socials:{...formData?.socials, facebook:event.target.value}});
              }
              
              }
              onBlur={()=>{
                if(!isValidURL(formData?.socials?.facebook) && formData?.socials?.facebook.length>0)
                {
                  alert("Please Enter a valid Url!!!");
                  setFormData({ ...formData, socials:{...formData?.socials, facebook:""}});
                }
              }}
              style={styles.inputBox}
            />
                        <a href={`javascript:window.open('${formData?.socials?.facebook}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${formData?.socials?.facebook}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>

                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"10px"}}>
                            <div style={{width:"30px"}}>
                            <img
                        src={`${process.env.PUBLIC_URL}/assets/img/instagram.svg`}
                      />
                            </div>
                            <input
              type="url"
              placeholder="Instagram*"
              name="instagram"
              value={formData?.socials?.instagram}
              onChange={(event) =>{
                    setFormData({ ...formData, socials:{...formData?.socials, instagram:event.target.value}});
              }
              }
              onBlur={()=>{
                if(!isValidURL(formData?.socials?.instagram)&&formData?.socials?.instagram.length>0)
                {
                  alert("Please Enter a valid Url!!!");
                  setFormData({ ...formData, socials:{...formData?.socials, instagram:""}});
                }
              }}
              style={styles.inputBox}
            />
                        <a href={`javascript:window.open('${formData?.socials?.instagram}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${formData?.socials?.instagram}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>

                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <div style={{width:"30px"}}>
                            <img
                        src={`${process.env.PUBLIC_URL}/assets/img/twitter.svg`}
                      />
                            </div>
                            <input
              type="url"
              placeholder="Twitter*"
              name="twitter"
              value={formData?.socials?.twitter}
              onChange={(event) =>{
                    setFormData({ ...formData, socials:{...formData?.socials, twitter:event.target.value}});
              }
              }
              onBlur={()=>{
                if(!isValidURL(formData?.socials?.twitter)&&formData?.socials?.twitter.length>0)
                {
                  alert("Please Enter a valid Url!!!");
                  setFormData({ ...formData, socials:{...formData?.socials, twitter:""}});
                }
              }}
              style={styles.inputBox}
            />
            <a href={`javascript:window.open('${formData?.socials?.twitter}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${formData?.socials?.twitter}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>
                        </div>
                    </div>
                          </div>
                </div>
                <div style={{marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "500",
                            marginRight: "80px",
                            marginBottom:"10px",
                           
                            alignItems:"center"}}>
                    <div style={{marginTop:"10px", marginRight:"10px"}}>
                    {`Select Leagues:* `} <span style={{fontSize:"12px", color:"grey"}}>{`(dropdown to select and unselect it.)`}</span>
                    </div>
                    <TeamsLeagueDropDown
                      onChange={onLeaguesSelected}
                      id={playerData?._id}
                      data={playerData?.compDetails}
                      sports={"60a13cf7079e615a0edc7e84"}
                      // onClick={tagLeaguesToPlayer}
                    />
                    
 
                  
                </div>
                    </div>
                    <div style={{}}>
                    {updateImage?<img src={updateImage} style={{  alignItems:"right", border:"1px solid #111", width:"200px", height:"200px" }}/>:null}
                    
                      <div style={{width:"100%", textAlign:"center"}}>
                        <div style={{display:"flex", alignItems:"center", textAlign:"center"}}>
                            <div>

                          <input
                            ref={fileInput}
                            type="file"
                            // multiple={false}
                            accept="image/webp"
                            onChange={onFileSelect}
                            style={{
                              backgroundColor: "#111",
                              height: 50,
                              width: 35,
                              position: "absolute",
                              opacity: 0,
                              cursor: "pointer",
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                                margin:8,
                              color: "#555",
                              cursor: "pointer",
                            }}
                          ></i>
                            </div>
                          <div className="col-lg-12">
                <button
                  onClick={() => {
                    updatePlayerImage();
                    
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  update Image
                </button>
              </div>
                        </div>
                        <div style={{width:"80%"}}>*Please upload a Image in 1:1</div>
                      </div>
                    
                    </div>
                </div>

                <hr className="my-0 md:min-w-full" />
                <div style={{ marginTop: "20px", marginBottom:'10px' }}>
                <label className="addArticle_label">Meta Title*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Meta Title *"
                  name="meta_title"
                  value={formData?.meta_title}
                  style={styles.inputBox}
                  onChange={(e)=>setFormData({...formData, meta_title:e?.target?.value })}
                />
                <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${formData?.meta_title?.length}`}
                </span>
              </div>
                <hr className="my-0 md:min-w-full" />
                <div style={{ marginTop: "20px", marginBottom:"10px" }}>
                <label className="addArticle_label">Meta Description*</label>
                <br></br>

                <ReactQuill
                  placeholder="Meta description will be added here after full stop in description."
                  className="shadow-sm"
                  theme="snow"
                  name="description"
                  style={{
                    minHeight: 50,
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  value={metaDescription}
                  modules={{
                    toolbar: [
                      [
                        // { header: "1" },
                        // { header: "2" },
                        { header: [1, 2, 3, 4, 5, 6] },
                        { font: [] },
                      ],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ align: [] }],
                      [{ color: [] }, { background: [] }],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      // ["link", "image"],
                      ["link", "video", "image"],
                      // ["link", "video", "image", "code-block"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "color",
                    "background",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "video",
                    "image",
                    "code-block",
                    "align",
                  ]}
                  onChange={(val, delta, source, editor) => {
                   setMetaDescription(val)
                    
                  
                  setMetaDescriptionLength(editor.getText()?.length)
                }
                }
                /> 
              </div>
              <div style={{width:'100%', 
                  marginTop: '1rem',
                  marginBottom:"10px"
                  }}>
              <span 
                style={{
                  background: "#50C878",
                  height: "auto",
                  width: "auto",
                  color: "#fff",
                  fontWeight: "600",
                  padding: "5px",
                  borderRadius: "8px",
                  
                }}>
                  {`Characters: ${metaDescriptionLength-1 < 1 ? 0 : metaDescriptionLength-1}`}
                </span>
                </div>
                <hr className="my-0 md:min-w-full" />
                <div style={{ marginTop: "20px", fontWeight: 600 }}>
                        <label>Player Summary*</label>
                        <br></br>
                        <CKEditor
                    onChange={(event, editor) => {
                      setFormData({ ...formData, summary: editor.getData()});
                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.summary!==undefined?playerData?.summary:""}
                    
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  
                      </div>
                      <hr className="my-0 md:min-w-full" />
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">About*</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                      setFormData({ ...formData, details: {...formData?.details, about:{...(formData.details?.about), data:editor.getData(), tab_name:"About"} }});
                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.about?.data!==undefined?playerData?.details?.about?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Achievement Details*</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                       setFormData({ ...formData, details: {...formData?.details, achievements:{...(formData.details?.achievements), data:editor.getData(), tab_name:"Achievements"} }});
                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.achievements?.data!==undefined?playerData?.details?.achievements?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Career Details*</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                    setFormData({ ...formData, details: {...formData?.details, career:{...(formData.details?.career), data:editor.getData(), tab_name:"Career"} }});

                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.career?.data!==undefined?playerData?.details?.career?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Early Life Details*</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                    setFormData({ ...formData, details: {...formData?.details, early_life:{...(formData.details?.early_life), data:editor.getData(), tab_name:"Early Life"} }});

                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.early_life?.data!==undefined?playerData?.details?.early_life?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Earning Details</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                    setFormData({ ...formData, details: {...formData?.details, earnings:{...(formData.details?.earnings), data:editor.getData(), tab_name:"Earnings"} }});

                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.earnings?.data!==undefined?playerData?.details?.earnings?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Family Details*</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                    setFormData({ ...formData, details: {...formData?.details, family:{...(formData.details?.family), data:editor.getData(), tab_name:"Family"} }});

                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.family?.data!==undefined?playerData?.details?.family?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      {/**Franchise detail */}
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Franchise Details</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                    setFormData({ ...formData, details: {...formData?.details, franchise:{...(formData.details?.franchise), data:editor.getData(), tab_name:"Franchise"} }});

                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.franchise?.data?playerData?.details?.franchise?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      {/**Lifestyle Details */}
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Lifestyle Details</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                    setFormData({ ...formData, details: {...formData?.details, lifestyle:{...(formData.details?.lifestyle), data:editor.getData(), tab_name:"Lifestyle"} }});

                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.lifestyle?.data!==undefined?playerData?.details?.lifestyle?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                      <hr className="my-0 md:min-w-full" />
                      {/**Other Detaila */}
                      <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Other Details</label>
                  <br></br>
                  
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                    setFormData({ ...formData, details: {...formData?.details, other_details:{...(formData.details?.other_details), data:editor.getData(), tab_name:"Other information"} }});

                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={playerData?.details?.other_details?.data!=undefined?playerData?.details?.other_details?.data:""}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                    }}
                    
                  />
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
                <hr className="my-0 md:min-w-full" />
                <div style={{ marginTop: "20px" , marginBottom:"10px",}}>
                <label className="addArticle_label">Key Words</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Keywords - ( Sports, player name, etc ) *"
                  name="slug"
                  value={formData?.keywords}
                  onChange={(event) => {
                    setFormData({ ...formData, keywords: event.target.value});
                    }}
                  style={styles.inputBox}
                />
              </div>
              <hr className="my-0 md:min-w-full" />
              <div style={{marginTop: "20px" }}>
               
                <label className="addArticle_label">Tag Author*</label>
                <br></br>
                  <select
                    style={{
                      // marginLeft: 20,
                      border: "2px solid #acb9ce",
                      borderRadius: 10,
                    }}
                    className="mb-5 mt-3"
                    value={playerData?.author}
                    onChange={onAuthorSelected}
                  >
                    <option value="">Select Author</option>
                    {docs &&
                      docs.map((author) => (
                        <>
                          <option
                            selectedvalue={author && author._id}
                            value={author._id}
                          >
                            {author.name}
                          </option>
                        </>
                      ))}
                  </select>
                
                
              </div>              
              <div className="col-lg-12">
                <button
                  onClick={() => {
                    onAddPlayer(true);
                    setPristine();
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Update Player
                </button>
              </div>

              </div>
            
          </div>
        </div>
      </div>
      {Prompt}
        </>
        :
        <>
        </>

      }
    </>
  );
};
const styles = {
    inputBox: {
      width: "500px",
      height: "30px",
      marginTop: 5,
      borderRadius: "5px",
    },
  };
const mapStateToProps = (state) => {
  return {
    leaguesTag: state.leaguesReducer.leaguesTag,
    leagues: state.leaguesReducer.leagues,
    leagueSearch: state.leaguesReducer.leagueSearch,
  };
};
export default connect(mapStateToProps)(PlayerInfo);
