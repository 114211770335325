import AppConstants from '../constants';
import AxiosInstance from './http.handlers';
import AxiosInstanceV2Call from './http.v2Call.handlers';
import AxiosMessageInstance from './httpMessage.handler';
import axios from 'axios';
import { API_BASE_PATH, OLD_BASE_PATH } from '../constants/workerConstants';
import { responsiveFontSizes } from '@material-ui/core';
let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
const headers = { 'cms-access-token': cms_access_token, source: 'cms' };

//LegueManager API
export const GetAllComps = async (body) =>{
  return await AxiosMessageInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_ALL_COMPS, body,
    {headers}
  )
  .then((res)=>res)
  .catch((error)=>error)
};

export const GetSuggestedLeagueList = async (body) =>{
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.SUGGESTED_LEAGUE_LIST,
    {
      headers,
    }
  )
  .then((response)=>response)
  .catch((error)=>error);
};

export const UpdateRankOfSuggestedLeague = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPDATE_RANK_OF_SUGGESTED_LEAGUE, body,
    {
      headers,
    }
  )
  .then((response)=>response)
  .catch((error)=>error);
};

export const UpdateLeagueType = async (body)=>{
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPDATE_LEAGUE_TYPE,body,
    {
      headers
    } 
  )
  .then((res)=>res)
  .catch((error)=>error);
};

export const GetAllDefaultLeagues = async (headers) => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_ALL_DEFAULT,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetLeagueSchedules = async (league, page, headers) => {
  return axios.get(
    `${OLD_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_SCHEDULES}?leagueFilter=${league}&page=${page}`
  );
  // return await AxiosInstance.get(`${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_SCHEDULES}?leagueFilter=${league}&page=1`, {
  //     headers
  // }).then((response) => response).catch((error) => error);
};

export const GetAllTagLeagues = async (sportsId, searchKey) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_ALL_TAG_LEAGUE}/${sportsId}/${searchKey}`,
    { headers }
  );
};

export const GetAllMatches = async (payload) => {
  return axios.post(
    `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_Matches_SCHEDULES}`,
    payload
  );
  // return await AxiosInstance.get(`${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_SCHEDULES}?leagueFilter=${league}&page=1`, {
  //     headers
  // }).then((response) => response).catch((error) => error);
};

export const GetUpcomingSchedules = async (league, page, headers) => {
  //return axios.get(`${OLD_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_UPCOMING}?leagueFilter=${league}&page=${page}`);
  const lang = localStorage.getItem('lang')
    ? localStorage.getItem('lang')
    : 'english';
  return axios.get(
    `${OLD_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_UPCOMING}?leagueFilter=${league}`
  );
  // return await AxiosInstance.get(`${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_SCHEDULES}?leagueFilter=${league}&page=1`, {
  //     headers
  // }).then((response) => response).catch((error) => error);
};
export const GetAllLiveMatches = async (league) => {
  return axios.get(
    `${OLD_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_TODAY_MATCHES}?leagueFilter=${league}`
  );

  // return await AxiosInstance.get(`${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_SCHEDULES}?leagueFilter=${league}&page=1`, {
  //     headers
  // }).then((response) => response).catch((error) => error);
};
export const GetHomeFeature = async (body) => {
  return axios
    .post(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPDATE_LEAGUE}`,
      body,
      { headers }
    )
    .then((response) => response)
    .catch((error) => error);
};

export const GetScheduleMatchOptaGraphics = async (matchId, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_MATCH_GRAPHICS}/${matchId}`
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetBuzzMatchOptaGraphics = async (matchId, headers) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_BUZZ_MATCH_GRAPHICS}/${matchId}`
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetHomeGraphics = async (id) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_HOME_GRAPHICS}`
  )
    .then((response) => response)
    .catch((error) => error);
};

export const UploadHomeGraphics = async (data) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPLOAD_HOME_GRAPHICS,
    data
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetDefaultPRef = async () => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_DEFAULT_PREF
  )
    .then((response) => response)
    .catch((error) => error);
};

export const TagFeatureToHome = async (body) => {
  return await axios
    .post(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.TAG_LEAGUE_AT_HOME}`,
      body,
      { headers }
    )
    .then((response) => response)
    .catch((error) => error);
};
export const TagLeagueOnWeb = async (body) => {
  return await axios
    .post(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.SHOW_ON_WEB}`,
      body,
      { headers }
    )
    .then((response) => response)
    .catch((error) => error);
};

export const UpdateArenaFeed = async (body) => {
  return await axios
    .post(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.SET_ARENA_FEED}`,
      body,
      { headers }
    )
    .then((response) => response)
    .catch((error) => error);
};
export const GetArenaFeed = async (body) => {
  return await axios
    .post(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_ARENA_FEED}`,
      body,
      { headers }
    )
    .then((response) => response)
    .catch((error) => error);
};
export const AddArenaFeed = async (body) => {
  return await axios
    .post(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.ADD_ARENA_FEED}`,
      body,
      { headers }
    )
    .then((response) => response)
    .catch((error) => error);
};
export const ShowTaggedFeatureCard = async () => {
  return await axios
    .get(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.SHOW_HOME_TAGGED}`,
      { headers }
    )
    .then((response) => response)
    .catch((error) => error);
};
export const PostMatchGraphics = async (data, headers) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPLOAD_GRAPHICS,
    data,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const PostMatchUploadGraphics = async (data, headers) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPLOAD_SCHEDULE_GRAPHICS,
    data,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const PostBuzzMatchUploadGraphics = async (data, headers) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPLOAD_BUZZ_GRAPHICS,
    data,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const UpdateGraphics = async (data, headers) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LEAGUES.UPDATE_MATCH_GRAPHICS,
    data,
    {
      headers,
    }
  )

    .then((response) => response)
    .catch((error) => error);
};


export const GetAllTagLeaguesWithoutToken = async (sportsId, searchKey) => {
  return await AxiosInstanceV2Call.get(
    `${AppConstants.WorkerConstants.API_ROUTES.LEAGUES.GET_ALL_TAG_LEAGUE}/${sportsId}/${searchKey}`,
    { headers }
  );
};