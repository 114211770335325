import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ReactQuill from "react-quill";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-quill/dist/quill.snow.css";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CropperModal from "../../../components/CropperModal";
import PreviewModal from "../../../components/PreviewModal";
import LeaguesDropDown from "../../../components/Dropdowns/LeaguesDropDown";
import SportsDropdown from "../../../components/Dropdowns/SportsDropDown";
import SyncLoader from "react-spinners/SyncLoader";
import { GetAllAuthor } from "../../../handlers/author.handlers";
import UnsavedChangesWarning from "../../../components/AlertModal/UnsavedChangesWarning";
import {
  addNewArticle,
  addNewArticleWithoutToken,
  draftNewArticle,
} from "../../../handlers/news.handlers";
import { GetTaggedLocation } from "../../../handlers/twitter.handlers";
import { useHistory } from "react-router";
import "./AddArticle.css";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Axios from "axios";
import { css } from "@emotion/core";
{/**Modal Css */ }
import TrendsAllLocations from "../../../components/Cards/TrendsAllLocations";
import Fab from "@mui/material/Fab";

import { set } from "date-fns";
import LoginAppConstants from "../../../constants/LoginAppConstants";
import { API_BASE_PATH } from "../../../constants/workerConstants";
import { GetPlayersList } from "../../../handlers/players.handler";
import PlayerListDropDownAdd from "../../../components/Dropdowns/PlayerListDropDownAdd";
import SportsTaggingDropdown from "../../../components/Dropdowns/SportsTaggingDropdown";
import LeagueDropdownTagging from "../../../components/Dropdowns/LeagueDropdownTagging";
import MatchesDropdownTagging from "../../../components/Dropdowns/MatchesDropdownTagging";
import TeamsDropdownForTagging from "../../../components/Dropdowns/TeamsDropdownForTagging";
import {
  GetAllMatches,
  GetAllTagLeagues,
  GetAllTagLeaguesWithoutToken,
} from "../../../handlers/leagues.handlers";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeagueTagging,
} from "../../../redux/action-dispatchers/leagues.dispatchers";
import {
  dispatchAllSports,
  dispatchSelectedSportsTagging,
} from "../../../redux/action-dispatchers/sports.dispatchers";
import { GetMatchesForTagging } from "../../../handlers/matches.handlers";
import {
  dispatchAllMatchesTag,
  dispatchNumberOfMatchesPages,
} from "../../../redux/action-dispatchers/matches.dispatcher";
import TextEditor from "../../../components/TextEditor";
import NewsHyperlinkDropDown from "../../../components/Dropdowns/NewsHyperlinkDropDown";
import { Button } from "@material-ui/core";
import { logoutUser } from "../../../services/auth";
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const API_URL = API_BASE_PATH;
const UPLOAD_ENDPOINT = "news/uploadArticleStoryImage";
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const AddArticle = (props) => {
  const [selectedImage, setSelectedImage] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changeAuthor, setChangeAuthor] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [docs, setDocs] = useState([]);
  const [searchHyperLink, setSearchHyperLink] = useState("");
  const [copyText, setCopyText] = useState(false);
  const [textToCopy, setTextToCopy] = useState("");
  const [copyHyperLink, setCopyHyperLink] = useState(false);
  const [hyperLinkChange, setHyperLinkChange] = useState(false);
  const [getHyperLink, setGetHyperLink] = useState("");
  const [playersList, setPlayersList] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [tagTrends, setTagTrends] = useState(false); // Tag Trends
  const [open, setOpen] = useState(false); // for Modal Open State
  const [metaDescriptionLength, setMetaDescriptionLength] = useState(0) // 
  //if SEO Slug is Invalid characters
  const [invalidSeoSlug, setInvalidSeoSlug] = useState(false);
  const [twitterTaggedLocations, setTwitterTaggedLocations] = useState([]) // All trending places tagged from Trends api
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: "",
    file: "",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const [formData, setFormData] = useState({
    file: undefined,
    sportsId: "",
    story: "",
    summary: "",
    league: "",
    headline: "",
    showArticle: false,
    slug: "",
    copyrights: "SportsTak",
    uuid: "",
    authorId: "",
    seo_title: "",
    seo_slug: "",
    seo_description: "",
    keyword: "",
    // teams: [],
    players: [],
    match_id: "",
    isTrending: false,   // For Tagging to Trends in Twitter
    userDetails: (JSON.stringify({
      email: localStorage.getItem("email"),
    })),
    shortTitle:""
  });
  {/**Modal open and close */ }
  const handleOpen = () => {
    setOpen(true);
  };
  // Tagged Locations from twitter Trending part
  useEffect(() => {
    const body = 'section=twitter'
    if (open) {
      GetTaggedLocation(body)
        .then((response) => {
          setTwitterTaggedLocations(response?.data)
        })
        .catch((error) => error)
    }
  }, [open])

  const mHistory = useHistory();
  const onClose = () =>
    setConfirmationModal({ message: "", show: false, title: "" });
  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) =>
    setShowCropper(false);
  const onSuccess = () =>
    setConfirmationModal({ message: "", show: false, title: "" });
  const onPreviewSuccess = () =>
    setPreviewModal({
      imageData: previewModal.imageData,
      file: previewModal.file,
      show: false,
    });
  const onPreviewClose = () =>
    setPreviewModal({ imageData: "", file: "", show: false });

  const ConfirmationModalWrapper = () => (
    <ConfirmationModal
      message={confirmationModal.message}
      title={confirmationModal.title}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );

  const CropperModalWrapper = () => (
    <CropperModal
      image={selectedImage}
      onClose={onCropperClose}
      closeCropperAndSetData={closeCropperAndSetData}
    />
  );

  const PreviewModalWrapper = () => (
    <PreviewModal
      imageData={previewModal.imageData}
      title="Upload Video Image"
      message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
      onSuccess={onPreviewSuccess}
      onClose={onPreviewClose}
    />
  );
  // search hyperlink
  const onHyperLinkChange = (value) => {
    setGetHyperLink(value);
    setHyperLinkChange(true);
    setCopyHyperLink(false);
    setCopyText(false);
  };
  const onHyperLinkSearch = (event) => {
    setSearchHyperLink(event.target.value);
  };

  const onCopyHyperLink = () => {
    const body = {
      _id: props.selectedNewsHyperLink,
      type: "news",
    };
    setCopyHyperLink(true);
    setHyperLinkChange(false);
    // GetCopyHyperlink(body)
    Axios.post(
      `${API_BASE_PATH}/content/admin/generateURL`,
      {
        _id: props.selectedNewsHyperLink,
        type: "news",
      },
      {
        headers: { "cms-access-token": accessToken, source: "cms" },
      }
    )
      .then((response) => {
        setTextToCopy(response.data);
      })
      .catch((error) => error);
  };
  // search hyperlink end
  // CKEditor image upload
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
              Axios.post(
                `${API_URL}/${UPLOAD_ENDPOINT}`,
                { file: reader.result },
                {
                  headers: { "cms-access-token": accessToken, source: "cms" },
                }
              )
                // .then((res) => res.json())
                .then((res) => {
                  resolve({
                    default: `${res.data.data}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            };
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  // ckeditor image upload end
  const onImageFileSelect = (event) => {
    let file = event.target.files[0];
    event.target.value = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
            file: file,
          });
        } else {
          alert("Image width and height must be 1920x1080 px");
          return false;
        }
      };
    };
    reader.onerror = function (error) { };
    setSelectedImage(reader.result);

    setIsUnsaved();
  };

  const onFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event?.target?.value });
    setIsUnsaved();
  };
  const onDescriptionChange = (value) => {
    setFormData({ ...formData, story: value });
  };
  const onSeoDescriptionChange = (value) => {
    setFormData({ ...formData, seo_description: value });
  };
  // const onTeamsChange = (value) => {
  //   setFormData({ ...formData, teams: value });
  // };
  const onSportChange = (value) => {
    setFormData({ ...formData, sportsId: value });
    dispatchSelectedSportsTagging({
      selectSports: value,
      selectedSportsTagging: value,
    });
    dispatchAllLeaguesTag([]);
    dispatchSelectedLeagueTagging({ selectedLeagueTag: "" });
    // const timer = setTimeout(() => {
    //   getAllTaggedLeague();
    // }, 1000);
    // return () => clearTimeout(timer);
  };
  const onPlayerSelect = (value) => {
    setFormData({ ...formData, players: value })
  };
  const onLeagueChange = (league, uuid) => {
    setFormData({ ...formData, league, uuid });
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: league,
      selectedLeagueFilterTag: league,
    });
    // getAllTaggedMatches();
  };
  const onMatchesChange = (value) => {
    setFormData({ ...formData, match_id: value });
  };
  const getAllTaggedLeague = () => {
    setIsLoading(false); // Originally True
    if (props.selectSports !== undefined) {
      GetAllTagLeaguesWithoutToken(
        props.selectSports,
        props.leagueSearch == "" ? "search" : props.leagueSearch
      )
        .then((response) => {
          if (
            response.statusCode == 401 &&
            response.message.message == "Invalid cms-access-token"
          ) {
            alert(`Access token Expired!!! Please Login Again!`);
            // logoutUser();
            mHistory.push("/");
          } else if (response?.status) {
            dispatchAllLeaguesTag(response?.data);
            setIsLoading(false);
          } else {
            dispatchAllLeaguesTag([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          dispatchAllLeaguesTag([]);
        });
    }
  };
  useEffect(() => {
    dispatchAllMatchesTag([]);
    dispatchAllSports([]);
    dispatchSelectedSportsTagging("All");
  }, []);
  useEffect(() => {
    // const timer = setTimeout(() => {
    getAllTaggedLeague();
    // }, 1000);
    // return () => clearTimeout(timer);
  }, [props.leagueSearch, props.selectSports]);
  const getAllTaggedMatches = () => {
    GetMatchesForTagging(
      props.selectedLeagueTag,
      props.selectSports,
      props.matchesSearch == "" ? "search" : props.matchesSearch,
      props.matchesPage,
      20
    )
      .then((response) => {
        if (response.status) {
          dispatchNumberOfMatchesPages(response?.data?.totalPages);
          dispatchAllMatchesTag(response?.data?.docs);
        } else {
          dispatchAllMatchesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllMatchesTag([]);
      });
  };
  useEffect(() => {
    getAllTaggedMatches();
  }, [
    props.selectSports,
    props.selectedLeagueTag,
    props.matchesPage,
    props.matchesSearch,
  ]);
  const onAuthorSelected = (e) => {
    setFormData({ ...formData, authorId: e.target.value });
  };
  const history = useHistory();

  const onAddArticle = (showArticleStatus = false) => {
    if (previewModal && previewModal.imageData == "") {
      setConfirmationModal({
        message: "Please select the image with correct format",
        show: true,
        title: "",
      });
      return;
    } if (imageTitle?.length === 0) {
      setConfirmationModal({
        message: "Image Title can not be empty.",
        show: true,
        title: "",
      });
      return;
    } if (formData?.headline?.length == 0) {
      setConfirmationModal({
        message: "Headline Can not be empty",
        show: true,
        title: "",
      });
      return;
    } 
    if (formData.shortTitle!="" && formData?.shortTitle?.length < 100) {
      setConfirmationModal({
        message: "Short title Can not be less than 100",
        show: true,
        title: "",
      });
      return;
    } if (formData.shortTitle != ""&& formData?.shortTitle?.length > 161) {
      setConfirmationModal({
        message: "Short title Can not be greater than 160",
        show: true,
        title: "",
      });
      return;
    } if (formData?.slug?.length == 0) {
      setConfirmationModal({
        message: "Keyword cannot be empty, please enter keywords",
        show: true,
        title: "",
      });
      return;
    } if (formData?.story?.length == 0) {
      setConfirmationModal({
        message: "News Box Description Can not be empty",
        show: true,
        title: "",
      });
      return;
    } if (imageTitle?.length === 0) {
      setConfirmationModal({
        message: "Image Title can not be empty.",
        show: true,
        title: "",
      });
      return;
    }
    if (!(/^[A-Za-z0-9-\s]*$/.test(formData?.seo_slug)) || formData?.seo_slug?.charAt(0) == '-') {
      setConfirmationModal({
        message: "Slug is Invalid, It must contain only characters between a-z, A-Z, 0-9 or -.",
        show: true,
        title: "",
      });
      setInvalidSeoSlug(true);
      return;
    } if (formData?.seo_title?.length == 0) {
      setConfirmationModal({
        message: "seo title Cannot be empty.",
        show: true,
        title: "",
      });
      return;
    } if (formData?.seo_title?.length < 50) {
      setConfirmationModal({
        message: "Please update your SEO title to minimum 50 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (formData?.seo_title?.length > 140) {
      setConfirmationModal({
        message: "Please update your SEO title to maximum 140 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (formData?.seo_description?.length == 0) {
      setConfirmationModal({
        message: "Meta Descripiton Can not be empty.",
        show: true,
        title: "",
      });
      return;
    } if (formData?.seo_description?.length < 146) {
      setConfirmationModal({
        message: "Please update your Meta Description to minimum 140 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (formData?.seo_description?.length > 206) {
      setConfirmationModal({
        message: "Please update your Meta Description to maximum 200 Characters.",
        show: true,
        title: "",
      });
      return;
    }
    if (formData?.summary?.length == 0) {
      setConfirmationModal({
        message: "Bullet Points can not be empty",
        show: true,
        title: "",
      });
      return;
    }
    // league tagg
    //  if (formData.league == '') {
    //   setConfirmationModal({
    //     message: 'League is not tagged, please tag league',
    //     show: true,
    //     title: '',
    //   });
    // }
    if (formData?.sportsId == "") {
      setConfirmationModal({
        message: "Sports is not tagged, please tag sports",
        show: true,
        title: "",
      });
      return;
    } if (formData?.authorId == "") {
      setConfirmationModal({
        message: "Author is not tagged, please tag author",
        show: true,
        title: "",
      });
      return;
    }
    else {
      formData.seo_slug = formData.seo_slug.trim();
      setFormData({ ...formData, showArticle: showArticleStatus });
      setIsLoading(true);
      addNewArticleWithoutToken(
        {
          ...formData,
          showArticle: showArticleStatus,
          image: previewModal.file,
          selfSeoSlugCreated: true,
          translatedHeadlineExists: true,
          imgTitle: imageTitle
        },
        { Authorization: props.Authorization }
      )
        .then((response) => {
          console.log('response', response)
          if (response.status) {
            setIsLoading(false);
            alert("Success..");
            history.push("/news");
          } else if(response?.message?.errors?.code == 1){
            alert("Article Exists please change seo title");
            setIsLoading(false);
          }
          else {
            alert("failed ...");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Eror while adding article", error);
        });
    }
  };
  // seo changes
  const changeTitle = () => {
    let new_seo_title = formData.seo_title;

    setFormData({ ...formData, seo_title: new_seo_title.toLowerCase() });
  };
  const changeSlug = () => {
    let new_seo_slug = formData.seo_title;
    new_seo_slug = new_seo_slug.replaceAll(" ", "-");
    setFormData({ ...formData, seo_slug: new_seo_slug.toLowerCase() });
  };
  // Sending is Trending to Form Data
  const changeTrending = () => {
    // let trendingKeyword = formData?.isTrending;
    let trendingKeyword = tagTrends;
    setFormData({ ...formData, isTrending: trendingKeyword })
  };

  // const changeDescription = () => {
  //   let metaDescription = formData.story;

  //   metaDescription =
  //     metaDescription.substring(0, metaDescription.indexOf(".") + 1) ||
  //     metaDescription.substring(0, metaDescription.indexOf("।") + 1);

  //   setFormData({ ...formData, seo_description: metaDescription });
  // };

  useEffect(() => {
    changeTitle();
  }, [formData.seo_title]);

  useEffect(() => {
    changeSlug();
  }, [formData.seo_title]);

  // Changing form data depending upon 
  useEffect(() => {
    console.log('asd', tagTrends)
    changeTrending();
  }, [tagTrends])

  // useEffect(() => {
  //   changeDescription();
  // }, [formData.story]);
  // seo change end
  const getAuthor = () => {
    GetAllAuthor()
      .then((response) => {
        setDocs(response.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAuthor();
    window.scrollTo(0, 0);
  }, []);
  const getPlayers = (id,) => {
    const body = {
      sportId: id,
      limit: 10,
      page: 1,
      searchKey: "",

    }
    GetPlayersList(body)
      .then((response) => {
        setPlayersData(response?.data);
      })
      .catch((error) => error);
  }
  useEffect(() => {
    // setSports_id(formData?.sportsId)
    getPlayers(formData?.sportsId);
  }, [formData?.sportsId]);
  // const onAuthorSelected = (event, value) => {
  //   setChangeAuthor(false);
  //   const body = { authorId: event.target.value };

  //   ChangeNewsUpdate(body)
  //     .then((response) => {
  //       if (response.status) {
  //         // getNewsInformation();
  //         alert(response.message);
  //       } else {
  //         alert('Sorry your author is not updated');
  //       }
  //     })
  //     .catch((error) => error);
  // };

  return (
    <>
      <Sidebar />
      {confirmationModal.show && <ConfirmationModalWrapper />}
      {showCropper && <CropperModalWrapper />}
      {previewModal.show && <PreviewModalWrapper />}

      <div
        className="relative md:ml-64"
        style={{ display: open ? "none" : "block", padding: 50 }}
      >
        {console.log("formData", formData)}
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="addArticle_heading">
                  All Fields are mandatory:
                </h1>
                <hr className="my-0 md:min-w-full" />
                <h1 style={{ fontWeight: "bolder", color: "tomato" }}>
                  Please Select Sports first:
                </h1>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <SportsTaggingDropdown onChange={onSportChange} />
                </div>
                {props.selectSports !== undefined && (
                  <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <LeagueDropdownTagging onChange={onLeagueChange} />
                  </div>
                )}
                {
                  <>
                    {props.selectSports !== undefined &&
                      props.selectedLeagueTag !== "" &&
                      props.matchesTag.length !== 0 && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <MatchesDropdownTagging onChange={onMatchesChange} />
                        </div>
                      )}
                  </>
                }
                {/* {props.selectSports !== 'all' && props.selectedLeagueTag !== '' && (
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <TeamsDropdownForTagging onChange={onTeamsChange} />
                </div>
              )} */}
                <hr className="my-0 md:min-w-full" />
                <div className="addArticle_label">
                  *Please upload image in the dimension of 1920x1080
                </div>
                <div>
                  {previewModal.imageData && (
                    <img
                      src={previewModal.imageData}
                      style={{
                        width: 500,
                        height: 300,
                        border: "2px solid gray",
                      }}
                    />
                  )}
                  <input
                    type="file"
                    name="file"
                    onChange={onImageFileSelect}
                    style={{
                      backgroundColor: "#000",
                      height: 60,
                      width: 60,
                      position: "absolute",
                      opacity: 0,
                      cursor: "pointer",
                    }}
                  />

                  <i
                    className="fas fa-camera fa-2x"
                    style={{
                      margin: 8,
                      color: "#555",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div style={{ marginTop: "20px" }}>
                <label className="addArticle_label">Image Title*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Image Title *"
                  name="headline"
                  value={imageTitle}
                  style={styles.inputBox}
                  onChange={(e) => setImageTitle(e.target.value)}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <label className="addArticle_label">Headline*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Headline *"
                  name="headline"
                  value={formData.headline}
                  style={styles.inputBox}
                  onChange={onFormDataChange}
                />

                <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${formData?.headline?.length}`}
                </span>
              </div>
              <div style={{ marginTop: "20px" }}>
                <label className="addArticle_label">Short Title</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Short Title"
                  name="shortTitle"
                  value={formData.shortTitle}
                  style={styles.inputBox}
                  onChange={onFormDataChange}
                />

                <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${formData?.shortTitle?.length}`}
                </span>
              </div>

              {props.selectSports !== undefined && (
                <div
                  style={{
                    marginTop: 5,
                    marginBottom: 10,
                    marginRight: "8px",
                    fontWeight: "500",

                    alignItems: "center",
                  }}
                >

                  <PlayerListDropDownAdd
                    data={playersData}
                    sportsId={formData?.sportsId}
                    onChange={onPlayerSelect}
                  />
                </div>
              )}
              {/* <label>Key Words*</label><br/> */}

              <div style={{ marginTop: "20px" }}>
                <label className="addArticle_label">SEO Title*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="SEO Title *"
                  name="seo_title"
                  value={formData?.seo_title}
                  style={styles.inputBox}
                  onChange={onFormDataChange}
                />
                <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${formData?.seo_title?.length}`}
                </span>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  color: invalidSeoSlug ? "red" : "",
                }}
              >
                <label className="addArticle_label">Slug*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Slug*"
                  name="seo_slug"
                  value={formData?.seo_slug}
                  style={{
                    width: "500px",
                    height: "30px",
                    marginTop: 5,
                    borderRadius: "5px",
                    borderColor: invalidSeoSlug ? "red" : "",
                    color: invalidSeoSlug ? "red" : "",
                  }}
                  onInput={(e) => setInvalidSeoSlug(false)}
                  onChange={onFormDataChange}
                />
                <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${formData?.seo_slug?.length}`}
                </span>
                {invalidSeoSlug && (
                  <span
                    style={{ fontSize: "12px", color: "#ff0000" }}
                  >{`(*SEO Slug must contain character in between a-z, A-Z, 0-9 or -.)`}</span>
                )}
              </div>

              <div style={{ marginTop: "20px" }}>
                <label className="addArticle_label">Key Words*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Keywords - ( Sports, player name, etc ) *"
                  name="slug"
                  // onPaste={(e) => {
                  //   e.preventDefault();
                  //   alert("Copy Paste is not Allowed!!!!");
                  //   return false;
                  // }}
                  value={formData?.slug}
                  onChange={onFormDataChange}
                  style={styles.inputBox}
                />
                {/**Keywords Count */}
                {/* <span
                  style={{
                    background: "#50C878",
                    height: "auto",
                    width: "auto",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "5px",
                    borderRadius: "8px",
                    marginLeft: "2rem",
                    marginBottom: "0.1px",
                  }}
                >
                  {`Characters: ${formData?.slug?.length}`}
                </span> */}
              </div>

              <div>
                {/* <textarea
                  name="story"
                  rows="3"
                  cols="100"
                  id="textbox"
                  value={formData.story}
                  placeholder="Write the Article Here...     (Min 100 Words) "
                  onChange={onFormDataChange}
                  style={{ marginTop: 20, borderRadius: '5px' }}
                ></textarea> */}

                <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">Bullet Points*</label>
                  <br></br>
                  <CKEditor
                    onChange={(event, editor) => {
                      setFormData({ ...formData, summary: editor?.getData() });
                      setIsUnsaved();

                      const wordCountPlugin = editor.plugins.get("WordCount");

                      const wordCountWrapper =
                        document.getElementById("word-count");

                      wordCountWrapper.appendChild(
                        wordCountPlugin.wordCountContainer
                      );

                    }}
                    onReady={(editor) => {
                      const wordCountPlugin = editor?.plugins?.get("WordCount");

                      const wordCountWrapper =
                        document.getElementById("word-count");

                      if (wordCountWrapper)
                        wordCountWrapper.appendChild(
                          wordCountPlugin.wordCountContainer
                        );

                      // Insert the toolbar before the editable area.


                      // this.set( 'viewportTopOffset', 0 );

                      // this.editor = editor;
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={formData.summary}

                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    },
                      customStyles: {
                        ul: {
                          marginLeft: '20px', // Adjust the padding value as needed
                        },
                      },
                    }}

                  // config={{
                  //   toolbar: [
                  //     'heading',
                  //     '|',
                  //     'bold',
                  //     'italic',
                  //     'blockQuote',
                  //     'link',
                  //     'numberedList',
                  //     'bulletedList',
                  //     'imageUpload',
                  //     'insertTable',
                  //     'tableColumn',
                  //     'tableRow',
                  //     'mergeTableCells',

                  //     'htmlEmbed',
                  //     '|',
                  //     'undo',
                  //     'redo',
                  //   ],
                  // }}
                  />
                  <span

                    id="word-count"></span>
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <label className="addArticle_label">Meta Description*</label>
                  <br></br>

                  <ReactQuill
                    placeholder="Meta description will be added here after full stop in description."
                    className="shadow-sm"
                    theme="snow"
                    name="description"
                    style={{
                      minHeight: 50,
                      marginTop: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    value={({ ...formData.story }, formData.seo_description)}
                    modules={{
                      toolbar: [
                        [
                          // { header: "1" },
                          // { header: "2" },
                          { header: [1, 2, 3, 4, 5, 6] },
                          { font: [] },
                        ],
                        [{ size: [] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ align: [] }],
                        [{ color: [] }, { background: [] }],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        // ["link", "image"],
                        ["link", "video", "image"],
                        // ["link", "video", "image", "code-block"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "color",
                      "background",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "video",
                      "image",
                      "code-block",
                      "align",
                    ]}
                    onChange={(val, delta, source, editor) => {
                      onSeoDescriptionChange(val)
                      setMetaDescriptionLength(editor.getText()?.length)
                    }
                    }
                  />
                </div>
                <div style={{
                  width: '100%',
                  marginTop: '1rem'
                }}>
                  <span
                    style={{
                      background: "#50C878",
                      height: "auto",
                      width: "auto",
                      color: "#fff",
                      fontWeight: "600",
                      padding: "5px",
                      borderRadius: "8px",
                    }}>
                    {`Characters: ${metaDescriptionLength - 1 < 1 ? 0 : metaDescriptionLength - 1}`}
                  </span>
                </div>

                <div style={{ marginTop: 20 }}>
                  <label className="addArticle_label">News Box*</label>
                  <br></br>
                  {/* <ReactQuill
                    placeholder="Write article story here..."
                    className="shadow-sm"
                    theme="snow"
                    name="description"
                    style={{
                      minHeight: 50,
                      marginTop: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    value={formData.story}
                    modules={{
                      toolbar: [
                        [
                          // { header: "1" },
                          // { header: "2" },
                          { header: [1, 2, 3, 4, 5, 6] },
                          { font: [] },
                        ],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ align: [] }],
                        [{ color: [] }, { background: [] }],
                        [
                          { list: 'ordered' },
                          { list: 'bullet' },
                          { indent: '-1' },
                          { indent: '+1' },
                        ],
                        // ["link", "image"],
                        ['link', 'video', 'image'],
                        // ["link", "video", "image", "code-block"],
                        ['clean'],
                      ],
                    }}
                    formats={[
                      'header',
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'color',
                      'background',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                      'video',
                      'image',
                      'code-block',
                      'align',
                    ]}
                    onChange={(val) => onDescriptionChange(val)}
                  /> */}
                  {/* <TextEditor
                    data={formData.story}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFormData({ ...formData, story: data });
                      setIsUnsaved();
                    }}
                  /> */}
                  <hr className="my-0 md:min-w-full" />
                  <div className="my-4">
                    <input
                      type="text"
                      value={searchHyperLink}
                      onChange={(event) => onHyperLinkSearch(event)}
                      style={{
                        border: "2px solid grey",
                        borderRadius: "5px",
                        marginBottom: "20px",
                      }}
                      placeholder="Search for articles to add"
                    />

                    <NewsHyperlinkDropDown
                      searchKey={searchHyperLink}
                      selectedValue={getHyperLink}
                      onChange={onHyperLinkChange}
                    />
                  </div>
                  {hyperLinkChange ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onCopyHyperLink()}
                      style={{ marginBottom: "20px" }}
                    >
                      Select This To Link
                    </Button>
                  ) : (
                    ""
                  )}
                  {copyHyperLink ? (
                    <CopyToClipboard
                      text={textToCopy}
                      onCopy={() => setCopyText(true)}
                    >
                      <i
                        className=" fas fa-clipboard-list"
                        style={{
                          padding: "9px 10px",
                          color: "#f34a17",
                          width: "",
                          height: "55px",
                          cursor: "pointer",
                        }}
                      >
                        Copy
                      </i>
                    </CopyToClipboard>
                  ) : (
                    ""
                  )}
                  {copyText ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "green" }}>
                        Copied... Click Link below to see the article detail
                        page.
                      </span>
                      <a
                        href={textToCopy}
                        target="_blank"
                        style={{ color: "blue", marginBottom: "10px" }}
                      >
                        {textToCopy}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <hr className="my-0 md:min-w-full" />
                  <CKEditor
                    onChange={(event, editor) => {
                      setFormData({ ...formData, story: editor?.getData() });
                      setIsUnsaved();

                      const wordCountPlugin = editor.plugins.get("WordCount");

                      const wordCountWrapper =
                        document.getElementById("word-count1");

                      wordCountWrapper.appendChild(
                        wordCountPlugin.wordCountContainer
                      );

                    }}
                    onReady={(editor) => {
                      const wordCountPlugin = editor?.plugins?.get("WordCount");

                      const wordCountWrapper =
                        document.getElementById("word-count1");

                      if (wordCountWrapper)
                        wordCountWrapper.appendChild(
                          wordCountPlugin.wordCountContainer
                        );

                      // Insert the toolbar before the editable area.


                      // this.set( 'viewportTopOffset', 0 );

                      // this.editor = editor;
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={formData?.story}
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                      link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: "https://",
                        decorators: {
                          toggleDownloadable: {
                            mode: "manual",
                            label: "Downloadable",
                            attributes: {
                              download: "file",
                            },
                          },
                          openInNewTab: {
                            mode: "manual",
                            label: "Open in a new tab",
                            defaultValue: true,
                            attributes: {
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                        },
                      },
                      heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        ]
                    }
                    }}
                  // config={{
                  //   toolbar: [
                  //     'heading',
                  //     '|',
                  //     'bold',
                  //     'italic',
                  //     'blockQuote',
                  //     'link',
                  //     'numberedList',
                  //     'bulletedList',
                  //     'imageUpload',
                  //     'insertTable',
                  //     'tableColumn',
                  //     'tableRow',
                  //     'mergeTableCells',

                  //     'htmlEmbed',
                  //     '|',
                  //     'undo',
                  //     'redo',
                  //   ],
                  // }}
                  />
                  <span
                    id="word-count1"></span>
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </div>
              </div>



              <div style={{ display: "flex" }}>
                <>
                  <select
                    style={{
                      // marginLeft: 20,
                      border: "2px solid #acb9ce",
                      borderRadius: 10,
                    }}
                    className="mb-5 mt-3"
                    value={formData?.authorId}
                    onChange={onAuthorSelected}
                  >
                    <option value="">Select Author*</option>
                    {docs &&
                      docs.map((author) => (
                        <>
                          <option
                            selectedValue={author && author._id}
                            value={author._id}
                          >
                            {author.name}
                          </option>
                        </>
                      ))}
                  </select>
                </>
                <>
                  {/*Trending*/}
                  <div
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                      marginLeft: "4rem",
                      marginTop: "1.2rem",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={tagTrends}
                      onChange={() => setTagTrends(!tagTrends)}
                      style={{
                        marginRight: "10px",
                        borderRadius: "2px",
                        border: "2px solid grey",
                        cursor: "pointer",
                      }}
                      color="default"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                    Trending
                  </div>
                </>
              </div>

              <div className="col-lg-12">
                <button
                  onClick={() => {
                    onAddArticle(true);
                    setPristine();
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Publish Article
                </button>
                <button
                  onClick={() => {
                    onAddArticle(false);
                    setPristine();
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  style={{ backgroundColor: "red", marginLeft: "10px" }}
                >
                  {"Draft Article"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Trending Keyword List*/}
      <div style={{ position: "fixed", bottom: "30px", right: "20px" }}>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          onClick={() => setOpen(true)}
          style={{ marginRight: 5 }}
        >
          Trending Keywords
        </Fab>
      </div>
      <>
        <div
          className="edit__popup"
          style={{ display: open ? "block" : "none" }}
        >
          <div
            onClick={() => setOpen(false)}
            className={open ? "edit__backdrop active" : "edit__backdrop"}
          ></div>

          <div
            className={open ? "edit__popup-body active" : "edit__popup-body"}
            style={{
              zIndex: 1000,
              maxWidth: "1000px",
              width: "1000px",
              height: "80%",
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="edit__popup-heading">Top Trends</div>
              <div className="edit__popup-inner" style={{ marginTop: "7px" }}>
                <i
                  onClick={() => setOpen(false)}
                  className="far fa-times-circle edit__popup-close"
                ></i>
              </div>
            </div>
            <TrendsAllLocations
              twitterTaggedLocations={twitterTaggedLocations}
            />
          </div>
        </div>
      </>
      {Prompt}
    </>
  );
};
const styles = {
  inputBox: {
    width: "500px",
    height: "30px",
    marginTop: 5,
    borderRadius: "5px",
  },
};
const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leaguesTag: state.leaguesReducer.leaguesTag,
    matchesTag: state.matchesReducer.matchesTag,
    leagues: state.leaguesReducer.leagues,
    leagueSearch: state.leaguesReducer.leagueSearch,
    matchesSearch: state.matchesReducer.matchesSearch,
    teamsSearch: state.teamsReducer.teamsSearch,
    matchesPage: state.matchesReducer.matchesPage,
    sports: state.sportsReducer.sports,
    selectSports: state.sportsReducer.selectSports,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
    selectedSports: state.sportsReducer.selectedSports,
    selectedNewsHyperLink: state.newsHyperLinkReducer.selectedNewsHyperLink,
    selectedNewsHeadlineLink:
      state.newsHyperLinkReducer.selectedNewsHeadlineLink,
  };
};
export default connect(mapStateToProps)(AddArticle);