import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { API_BASE_PATH, OLD_BASE_PATH } from '../../constants/workerConstants';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  dispatchSelectedAllLeague,
  dispatchSelectedLeague,
} from '../../redux/action-dispatchers/leagues.dispatchers';
import {
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LoginAppConstants from '../../constants/LoginAppConstants';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ShortsLeagueDropDown = (props) => {
  const classes = useStyles();
  const [docs, setDocs] = useState([]);
  const [leagueName, setLeagueName] = useState([]);
  const isAllSelected = docs.length > 0 && leagueName.length === docs.length;
  const getTopLeagues = () => {
    Axios.get(`${API_BASE_PATH}leagues/leagueWatchList`, {
      headers: { 'cms-access-token': accessToken, source: 'cms' },
    })
      .then((response) => {
        setDocs(response.data.data.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getTopLeagues();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    props.onChange(event.target.value);
    setLeagueName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    const foundObject = _.find(docs, ['comp_id', value]);
    if (value[value.length - 1] === 'all') {
      setLeagueName(leagueName.length === docs.length ? [] : docs);
      return;
    }
    // props.onChange && props.onChange(event.target.value, foundObject.comp_id);
    getTopLeagues();
    dispatchSelectedAllLeague({
      selectedAllLeague: leagueName,
    });
    // const value = event.target.value;
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        id="demo-mutiple-checkbox-label"
        style={{ paddingLeft: '10px', paddingTop: '3px' }}
      >
        Select Leagues
      </InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        style={{
          border: '2px solid #acb9ce',
          borderRadius: 5,
        }}
        multiple
        value={leagueName}
        // value={props.leaguesReducer.selectedLeague}
        onChange={(val) => handleChange(val)}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
      >
        {docs.map((league, index) => (
          <MenuItem
            key={index}
            value={league.abbr == 'All' ? league.comp_id : league.abbr}
          >
            {/* {league.abbr == 'All' ? league.comp_id : league.abbr} */}

            <ListItemText
              primary={league.abbr == 'All' ? league.comp_id : league.abbr}
            />
            <Checkbox
              checked={
                // true
                // leagueName.indexOf(league.comp_id) > -1
                leagueName &&
                leagueName.indexOf(
                  league.abbr == 'All' ? league.comp_id : league.abbr
                ) > -1
              }
              color="default"
              inputProps={{
                'aria-label': 'checkbox with default color',
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state) => {
  return {
    leaguesReducer: state.leaguesReducer,
    selectedLeagueFilter: state.leaguesReducer.selectedLeagueFilter,
  };
};

export default connect(mapStateToProps)(ShortsLeagueDropDown);
