import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector,useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import CropperModal from '../../../components/CropperModal';
import { ProgressBar } from 'react-bootstrap';
import UnsavedChangesWarning from '../../../components/AlertModal/UnsavedChangesWarning';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
  uploadVideo,
  Upload_Shorts_Videos,
} from '../../../handlers/videos.handlers';
import ReactPlayer from 'react-player';
import LeaguesDropDown from '../../../components/Dropdowns/LeaguesDropDown';
import FilteredLeagueDropDown from '../../../components/Dropdowns/FilteredLeagueDropDown';
import SportsDropDown from '../../../components/Dropdowns/SportsDropDown';
import ConfirmationModal from '../../../components/ConfirmationModal';
import PreviewModal from '../../../components/PreviewModal';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';
import Selects from 'react-select';
import SVG from 'react-inlinesvg';
import { Get_All_Shorts_Videos } from '../../../handlers/videos.handlers';
import { toAbsoluteUrl } from '../../../_helpers/AssistsHelpers';
import {
  dispatchShortsVideosList,
  dispatchVideosList,
  dispatchVideosListFilter,
} from '../../../redux/action-dispatchers/videos.dispatcher';
import { tagShortsSports } from '../../../handlers/sports.handler';
import ShortsLeagueDropdown from '../../../components/Dropdowns/ShortsLeagueDropdown';
import AppConstants from '../../../constants';
import { API_BASE_PATH } from '../../../constants/workerConstants';
import {dispatchAllLeagues} from "../../../redux/action-dispatchers/leagues.dispatchers";
import {dispatchAllSports} from "../../../redux/action-dispatchers/sports.dispatchers"
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AddShortsVideos = (props) => {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(true);
  const [showCropper, setShowCropper] = useState(false);
  const [taggedLeague, setTaggedLeague] = useState('');
  const [changeLeague, setChangeLeague] = useState(false);
  const [taggedSport, setTaggedSport] = useState(''); // Sports
  const [sportID, setSportsID] = useState(''); // Sports
  const [leagueID, setLeagueID] = useState('');
  const [changeSport, setChangeSport] = useState(false); // Sports
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [isPlaying, setIsplaying] = useState(false);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [mediaValue, setMediaValue] = useState({});
  const [videoData, setVideoData] = useState();
  const [keyWord, setKeyword] = useState('');
  const [headline, setHeadLine] = useState('');
  const [videoType, setVideoType] = useState('Sports Tak');
  const [description, setDescription] = useState('');
  const [imgselected, setImgSelected] = useState('');
  const [seoSlug, setSeoSlug] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [title, setTitle] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const [uuid, setUuid] = useState('');
  const [videoPreview, setVideoPreview] = useState('');
  const [videoAdd, setVideoAdd] = useState(false);
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const history = useHistory();
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const [selectVideo, setSelectedVideo] = useState('');
  const [searchVideo, setSearchVideo] = useState('');
  const [thumbName, setThumbName] = useState('');
  const [invalidSeoSlug, setInvalidSeoSlug] = useState(false);
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
    height: '',
    width: '',
    file: '',
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [progress, setProgress] = useState();
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState();
  let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
  // const [selectedImage, setSelectedImage] = useState({
  //   file: [],
  //   filePreview: null,
  // });
  let fileInput = React.useRef();
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatchAllLeagues([]);
    dispatchAllSports([]);
  },[]);
  // useEffect(() => {
  //   if (showEdit == false) {
  //     
  //     setIsplaying(false);
  //   }
  // }, [showEdit, isPlaying]);
  const defaultAdditional = {
    page: page,
  };
  useEffect(() => {
    changeTitle();
  }, [seoTitle]);

  useEffect(() => {
    changeSlug();
  }, [seoTitle]);

  const changeTitle = () => {
    let new_seo_title = seoTitle;
    new_seo_title = new_seo_title.replaceAll(" ", "-");
    if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(new_seo_title)) || new_seo_title.charAt(0) == '-') {
      setInvalidSeoSlug(true);
      return;
    }
    setInvalidSeoSlug(false);
    setSeoTitle(new_seo_title.toLowerCase());
  };
  const changeSlug = () => {
    let new_seo_slug = seoTitle;
    new_seo_slug = new_seo_slug.replaceAll(" ", "-");
    if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(new_seo_slug)) || new_seo_slug.charAt(0) == '-'){
      setInvalidSeoSlug(true);
      return;
    }
    setInvalidSeoSlug(false);
    setSeoSlug(new_seo_slug.toLowerCase());
  };
  useEffect(() => {
    if (props.selectedAllLeague) {
      const leagueInfo = props.leagues.find(
        (item) => item.comp_id == props.selectedAllLeague
      );

      if (leagueInfo) {
        setTaggedLeague(leagueInfo.abbr);
        setUuid(leagueInfo.optaUUID);
      }
    }

    if (props.selectedSports) {
      const sportsInfo = props.sports.find(
        (item) => item._id === props.selectedSports
      );
      if (sportsInfo) {
        setTaggedSport(sportsInfo.name);
        setSportsID(sportsInfo._id);
      }
    }
  }, [props.selectedSports, props.leagues, props.selectedAllLeague]);

  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setShowCropper(false);
  };
  const handleInputChange = (event) => {
    // setSelectedImage({
    //   ...selectedImage,
    //   file: event.target.files[0],
    //   filePreview: URL.createObjectURL(event.target.files[0]),
    // });
  };

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    setThumbName(file.name);
    event.target.value = '';

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (width === 960 && height === 540) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
            height: this.height,
            width: this.width,
            file: file,
          });
        } else {
          alert('Image width and height must be 960x540 px');
          setSelectedImage("");
          return false;
        }
      };
      setSelectedImage(reader.result);
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
    setIsUnsaved();
  };

  const onVideoFileSelect = (event) => {
    const file = event.target.files[0];
    let blobURL = URL.createObjectURL(file);
    setVideoPreview(blobURL);
    setVideoData(file);
    setIsUnsaved();
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const onSuccess = () => {
    //   var publishedStatus = VideosInfo.status;
    //   publishedStatus === 1 ? (publishedStatus = 0) : (publishedStatus = 1);
    //   ChangeVideoStatus(VideosInfo._id, publishedStatus, {
    //     Authorization: props.Authorization,
    //   }).then((response) => {
    //     if (response.status) {
    //       getVideosInformation();
    //     }
    //   });
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const showModalForPublish = () => {
    var title = 'Publish/Unpublish Video';
    var message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onLeagueSelected = (value) => {
    setLeagueID(value);
    setChangeLeague(true);
    setIsUnsaved();
  };

  // Sport Start
  const onSportSelected = (value) => {
    setChangeSport(true);
    setSportsID(value)
    setIsUnsaved();
  };

  // Sport End

  const onPreviewClose = () =>
    setPreviewModal({ imageData: '', file: '', show: false });
  const onPreviewSuccess = () => {
    setPreviewModal({
      imageData: previewModal.imageData,
      show: false,
      file: previewModal.file,
    });
  };

  const uploadVideoHandler = (event) => {
    event.preventDefault();
    if (Object.keys(mediaValue).length == 0) {
      setConfirmationModal({
        message: 'Please select your video file',
        show: true,
        title: '',
      });
      return;
    } if (previewModal && previewModal.imageData == '') {
      setConfirmationModal({
        message: 'Please select thumbnail',
        show: true,
        title: '',
      });
      return;
    } 
    if (title?.length == 0) {
      setConfirmationModal({
        message:  "Title Cannot be empty.",
        show: true,
        title: "",
      });
      return;
    } if (title?.length < 50) {
      setConfirmationModal({
        message: "Please update your title to minimum 50 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (title?.length > 120) {
      setConfirmationModal({
        message: "Please update your title to maximum 120 Characters.",
        show: true,
        title: "",
      });
      return;
    }
    if (description?.length == 0) {
      setConfirmationModal({
        message: "Descripiton Can not be empty.",
        show: true,
        title: "",
      });
      return;
    } if (description?.length < 140) {
      setConfirmationModal({
        message: "Please update your Description to minimum 140 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (description?.length > 200) {
      setConfirmationModal({
        message: "Please update your Description to maximum 200 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(seoTitle)) || seoTitle?.charAt(0) == '-') {
      setConfirmationModal({
        message: "SEO Title is Invalid, It must contain only characters between a-z, A-Z, 0-9 or -.",
        show: true,
        title: "",
      });
      setInvalidSeoSlug(true);
      return;
    }
    if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(seoSlug)) || seoSlug?.charAt(0) == '-') {
      setConfirmationModal({
        message: "Slug is Invalid, It must contain only characters between a-z, A-Z, 0-9 or -.",
        show: true,
        title: "",
      });
      setInvalidSeoSlug(true);
      return;
    } if (seoTitle?.length == 0) {
      setConfirmationModal({
        message: "seo title Cannot be empty.",
        show: true,
        title: "",
      });
      return;
    } if (seoTitle?.length < 50) {
      setConfirmationModal({
        message: "Please update your SEO title to minimum 50 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (seoTitle?.length > 140) {
      setConfirmationModal({
        message: "Please update your SEO title to maximum 140 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (seoDescription?.length == 0) {
      setConfirmationModal({
        message: "Meta Descripiton Can not be empty.",
        show: true,
        title: "",
      });
      return;
    } if (seoDescription?.length < 140) {
      setConfirmationModal({
        message: "Please update your SEO Description to minimum 140 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (seoDescription?.length > 200) {
      setConfirmationModal({
        message: "Please update your SEO Description to maximum 200 Characters.",
        show: true,
        title: "",
      });
      return;
    } if (!changeLeague || leagueID == '' || leagueID == "All") {
      setConfirmationModal({
        message: "Please Select league.",
        show: true,
        title: "",
      });
      return;
    } if (!changeSport || sportID == 'All' || sportID == '') {
      setConfirmationModal({
        message: "Please select sports.",
        show: true,
        title: "",
      });
      return;
    } else {
      let selectedLeagues = [];
      selectedLeagues.push(leagueID);
      // props.selectedAllLeague &&
      //   props.selectedAllLeague.map((leagues) => selectedLeagues.push(leagues));

      const data = {
        base_file_name: selectVideo.label,
        mediaValue: mediaValue,
        league: selectedLeagues,
        status: status === 'false' ? false : true,
        sportsId: props.selectedSports,
        tagHome: true,
        thumbnail: selectedImage,
        thumbnailName: thumbName,
        title:title,
        description:description,
        metaTitle:seoTitle,
        seoSlug:seoSlug,
        metaDescription:seoDescription,
      };

      const formData = new FormData();
      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });

      Upload_Shorts_Videos(data, {
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
        .then((response) => {
          if (response.status) {
            setVideoAdd(true);
            setPreviewModal({
              imageData: '',
              show: false,
              height: '',
              width: '',
              file: '',
            });
            setConfirmationModal({
              message: 'Video uploaded successfully',
              show: true,
              title: 'Success!',
            });
            history.push(`/shorts`);
          } else {
            alert("'Failed, please try again!");      
            setVideoAdd(false);
            setProgress("");
          }
        })
        .catch((error) => { alert('catch error: ', error); setVideoAdd(true);});
    }
  };
  // const getShortsVideos = () => {
  //   const body = {
  //     page: 1,
  //     limit: 4,
  //     query: searchVideo,
  //   };
  //   Get_All_Shorts_Videos(body)
  //     .then((res) => {
  //       dispatchShortsVideosList(res.data.docs);
  //       
  //     })
  //     .catch((err) => err);
  // };
  // useEffect(() => {
  //   getShortsVideos();
  // }, []);


  const handleSelect = (value) => {
    setSelectedVideo(value);
    setMediaValue(value);
    
  };

  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };



  async function loadOptions({ page }) {
    setPage(defaultAdditional.page + 1);
    const body = {
      page: parseInt(defaultAdditional.page),
      limit: 5,
      query: searchVideo,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'cms-access-token': cms_access_token, source: 'cms'
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `${API_BASE_PATH}${AppConstants.WorkerConstants.API_ROUTES.VIDEOS.GET_SHORTS_LIST_TO_UPLOAD}`,
      options
    );

    const responseJSON = await response.json();

    const videosList = responseJSON.data.docs;

    const videos = videosList?.map((video) => {

      return {
        value: video._id,
        label: video.base_file_name,
        url:
          'https://' +
          video.media.content[0].cdn_hostname +
          video.media.content[0].url,
      };
    });

    return {
      options: videos,
      hasMore: responseJSON.data.hasNextPage,
      additional: {
        page: page + 1,
      },
    };
  }
  // useEffect(() => {
  //   loadOptions(page);
  // }, [searchVideo]);
  return (
    <>
      <Sidebar />
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '55%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                setIsplaying(false);
                showEditHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={showEdit ? true : false}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <form onSubmit={uploadVideoHandler}>
            <div>
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: '1.3rem',
                  marginBottom: '30px',
                }}
              >
                All fields are mandatory:
              </h1>
              {/* <div> */}
              <div className="flex">
                <label
                  style={{
                    marginRight: '10px',
                    marginTop: '8px',
                    fontWeight: 600,
                  }}
                >
                  Search Videos:
                </label>
                <input
                  
                  type="text"
                  className="form-control"
                  name="searchVideoVal"
                  placeholder="Search Videos"
                  style={styles.searchBox}
                  onChange={(e) => {
                    setSearchVideo(e.target.value);
                  }}
                />

                <button
                  type="button"
                  onClick={() => {
                    loadOptions(page);
                  }}
                  className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Search
                </button>
              </div>

              <>
                <div className="col-lg-8" style={{ marginTop: '25px' }}>
                  <label
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Select Video *
                  </label>
                  <AsyncPaginate
                    name="videoId"
                    scrollHeight={true}
                    loadOptions={loadOptions}
                    additional={defaultAdditional}
                    placeholder="Select Video"
                    style={styles.inputBox}
                    onChange={(values) => {
                      handleSelect(values);
                    }}
                  // value={value}
                  // value={
                  //   videos
                  //     ? videos.find(
                  //         (option) => option.value === selectVideo.value
                  //       )
                  //     : ''
                  // }
                  ></AsyncPaginate>
                  {selectVideo && (
                    <div
                      style={{
                        display: 'flex',

                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      Click the icon for preview
                      <i
                        onClick={() => onClickOpenMedia(selectVideo.url)}
                        className="fas fa-video "
                        style={{
                          margin: 8,
                          color: '#555',
                          cursor: 'pointer',
                          fontSize: '1.5rem',
                        }}
                      ></i>
                    </div>
                  )}
                </div>

                <div
                  className="col-lg-1"
                  style={{
                    margin: 'auto',
                    marginTop: '26px',
                  }}
                >
                  <a
                    title="Media File"
                    className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                    onClick={() => {

                    }}
                  ></a>
                </div>
              </>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px" }} >
              <label
                style={{
                  marginRight: '10px',
                  marginTop: '8px',
                  fontWeight: 600,
                }}
              >
                Enter Title*:
              </label>
              <input style={{ marginLeft: 0, borderRadius: "8px",width:"500px" }} name="title" placeholder='Title' value={title} type="text" label="title" onChange={(e) => {
                const inputValue = e.target.value;
                // const formattedValue = inputValue.replace(
                //   /[^A-Za-z0-9 &|:;,\.!?'"“()]/g,
                //   ""
                // );
                setTitle(inputValue);
              }} />
              <span> {`Characters :${title.length}`}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px" }} >
              <label
                style={{
                  marginRight: '10px',
                  marginTop: '8px',
                  fontWeight: 600,
                }}
              >
                Enter Description *:
              </label>
              <input style={{ marginLeft: 0, borderRadius: "8px",width:"500px" }} name="description" value={description}placeholder='Description' type="text" label="description" onChange={(e) => {
                const inputValue = e.target.value;
                // const formattedValue = inputValue.replace(
                //   /[^A-Za-z0-9 &|:;,\.!?'"“()]/g,
                //   ""
                // );
                setDescription(inputValue);
              }} />
              <span> {`Characters :${description.length}`}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px" }} >
              <label
                style={{
                  marginRight: '10px',
                  marginTop: '8px',
                  fontWeight: 600,
                }}
              >
                Enter SEO Title*:
              </label>
              <input style={{ marginLeft: 0, borderRadius: "8px",width:"500px" }} name="seoTitle" placeholder='SEO Title' value={seoTitle} type="text" label="seoTitle" onChange={(e) => {
                // const inputValue = e.target.value;
                // const formattedValue = inputValue.replace(
                //   /[^A-Za-z0-9 &|:;/,\.!?'"“()]/g,
                //   ""
                // );
                setSeoTitle(e.target.value);
              }} />
              <span> {`Characters :${seoTitle.length}`}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px", color: invalidSeoSlug ? "red" : "", }} >
              <label
                style={{
                  marginRight: '10px',
                  marginTop: '8px',
                  fontWeight: 600,
                }}
              >
                Slug:
              </label>
              <input style={{
                marginLeft: 0, borderRadius: "8px", borderColor: invalidSeoSlug ? "red" : "", width:"500px",
                color: invalidSeoSlug ? "red" : "", }} name="seoSlug" placeholder='Slug' value={seoSlug} type="text" label="seoSlug" onChange={(e) => {
                const inputValue = e.target.value;
                const formattedValue = inputValue.replace(
                  /[^A-Za-z0-9 &|:;,/\.!?'"“()०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]/g,
                  ""
                );
                setSeoSlug(formattedValue);
              }} 
              onInput={(e) => setInvalidSeoSlug(false)}
                />
              {invalidSeoSlug && (
                <span
                  style={{ fontSize: "12px", color: "#ff0000" }}
                >{`(*SEO Slug must contain character in between a-z, A-Z, 0-9 or -. and hindi )`}</span>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "30px" }} >
              <label
                style={{
                  marginRight: '10px',
                  marginTop: '8px',
                  fontWeight: 600,
                }}
              >
                Enter SEO Description *:
              </label>
              <input style={{ marginLeft: 0, borderRadius: "8px",width:"500px" }} name="seoDescription" placeholder='SEO description' type="text" value={seoDescription}label="seoDescription" onChange={(e) => {
                const inputValue = e.target.value;
                // const formattedValue = inputValue.replace(
                //   /[^A-Za-z0-9 &|:;,/\.!?'"“()]/g,
                //   ""
                // );
                setSeoDescription(inputValue);
              }} />
              <span> {`Characters :${seoDescription.length}`}</span>
            </div>
            <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
              {confirmationModal.show && (
                <ConfirmationModal
                  message={confirmationModal.message}
                  title={confirmationModal.title}
                  onClose={!videoAdd && onClose}
                  onSuccess={!videoAdd && onSuccess}
                />
              )}
              {showCropper && (
                <CropperModal
                  image={selectedImage}
                  onClose={onCropperClose}
                  closeCropperAndSetData={closeCropperAndSetData}
                />
              )}
              {previewModal.show && (
                <PreviewModal
                  imageData={previewModal.imageData}
                  title="Upload video image"
                  message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                  onSuccess={onPreviewSuccess}
                  onClose={onPreviewClose}
                />
              )}
              {
                <div>
                  <div>
                    <div
                      style={{
                        // display: 'inline-block',
                        verticalAlign: 'top',
                      }}
                    >
                      {selectedImage !== null ? (
                        <img
                          src={selectedImage}
                          style={{
                            width: 150,
                            height: 245,
                            border: '2px solid gray',
                          }} // yha pehla Sir width thi vo bhi 500px for knowledge
                        />
                      ) : null}
                      {isImageUploading ? (
                        <div
                          style={{
                            textAlign: 'center',
                            padding: 10,
                            fontWeight: 600,
                          }}
                        >
                          <div>Uploading Image Please Wait...</div>
                          <div>
                            <PulseLoader
                              color="#23A5E0"
                              loading={true}
                              css={override}
                              size={10}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Please upload image in the dimension of 960x540 *
                          </div>
                          <div>
                            <input
                              ref={fileInput}
                              type="file"
                              multiple={false}
                              onChange={onFileSelect}
                              accept="image/x-png,image/jpeg"
                              style={{
                                backgroundColor: '#000',
                                height: 35,
                                width: 40,
                                position: 'absolute',
                                opacity: 0,
                                cursor: 'pointer',
                              }}
                            />
                            <i
                              className="fas fa-camera fa-2x"
                              style={{
                                margin: 8,
                                color: '#555',
                                cursor: 'pointer',
                              }}
                            ></i>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        padding: '0px 0',
                      }}
                    >
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>Sports*</label>
                        <SportsDropDown onChange={onSportSelected} />
                        </div>
                      </div>
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {/* <ShortsLeagueDropdown onChange={onLeagueSelected} /> */}
                        <div style={{display:"flex",flexDirection:"column"}}>

                        <label>League*</label>
                        <FilteredLeagueDropDown onChange={onLeagueSelected} sports={sportID}/>
                        </div>
                      </div>

                      

                      <br></br>

                      <div
                        style={{ margin: '0px 0px 10px 0px', fontWeight: 600 }}
                      >
                        <div>
                          <label>Status*</label>
                        </div>
                        <select
                          onChange={(e) => setStatus(e.target.value)}
                          class="form-select"
                          aria-label="Status"
                          style={{ borderRadius: '4px' }}
                        >
                          <option value={true} selected>
                            Published
                          </option>
                          <option value={false}>Draft</option>
                        </select>
                      </div>
                      <button
                        className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="submit"
                        onClick={() => {
                          setPristine();
                        }}
                      >
                        {'Publish Video'}
                      </button>
                    </div>
                  </div>

                  <div style={{ paddingTop: 30 }}></div>
                </div>
              }
            </div>
            <div
              style={{
                width: '100%',
                justifySelf: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {progress && (
                <ProgressBar
                  striped
                  variant="info"
                  now={progress}
                  label={`${progress}%`}
                  style={{
                    width: '400px',
                    backgroundColor: '#04b4c4',
                    textAlign: 'center',
                    color: 'white',
                    borderRadius: '10px',
                  }}
                />
              )}
            </div>
          </form>
          {Prompt}
        </div>
      </div>
    </>
  );
};

const styles = {
  inputBox: {
    width: '500px',
    height: '30px',
    marginTop: 5,
    borderRadius: '5px',
    padding: '15px 10px',
  },
  searchBox: {
    width: '140px',
    height: '30px',
    marginTop: 5,
    borderRadius: '5px',
    padding: '15px 10px',
    marginRight: '20px',
  },
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leagues: state.leaguesReducer.leagues,
    sports: state.sportsReducer.sports,
    selectedSports: state.sportsReducer.selectedSports,
    selectedAllLeague: state.leaguesReducer.selectedAllLeague,
  };
};
export default connect(mapStateToProps)(AddShortsVideos);