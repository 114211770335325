import React, {useState, useEffect} from 'react';
import { Prompt } from 'react-router';
const UnsavedChangesWarning = (message = "Are you sure you want to discard the changes")=>{
    const [isUnsaved, setIsUnsaved] = useState(false)
    useEffect(() => {       
window.onbeforeunload = isUnsaved && (()=> message);
return ()=>{
    window.onbeforeunload = null;
};
},[isUnsaved]);
const routerPrompt =<Prompt when={isUnsaved} message={message}/>

    return[routerPrompt, ()=> setIsUnsaved(true), () =>setIsUnsaved(false)]
}; 
export default UnsavedChangesWarning;