import React, { useState } from "react";
import * as UserHandler from "../../../handlers/admin.handlers";
import { loginUser } from "../../../services/auth";
import { Link, useHistory } from "react-router-dom";
import {
  dispatchCurrentLanguage,
  dispatchFirstModuleName,
  dispatchLoginUser,
  dispatchModulePermission,
  dispatchUserLanguagesAllowed,
  dispatchUserProfilePicture,
  dispatchUserRole,
} from "../../../redux/action-dispatchers/auth.dispatchers";
import SignUp from "../../Signup/Signup";
import { css } from "@emotion/core";
import PacmanLoader from "react-spinners/PacmanLoader";
const override = css`
  border-color: red;
  position: fixed;
  right: 50%;
  top: 50.4%;
`;
const Login = (props) => {
  const mHistory = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const onLogin = () => {
    setIsLoading(true);
    UserHandler.Login(formData)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.message);
          setIsLoading(false);
        } 
        else if (!response.data.error) {
          loginUser(response.data.data.accessToken);

          dispatchUserLanguagesAllowed(response.data.data.languagesAllowed);
          dispatchCurrentLanguage(response.data.data.languagesAllowed[0]);
          dispatchUserRole(response.data.data.roles);
          dispatchModulePermission(response.data.data.roles.modulePermission);
          localStorage.setItem(
            "roles",
            JSON.stringify(response.data.data.roles)
          );

          localStorage.setItem(
            "modulePermission",
            JSON.stringify(response.data.data.roles.modulePermission)
          );
          localStorage.setItem("Login_Time", Date.now());
          localStorage.setItem("email", formData.email);
          localStorage.setItem(
            "User Profile Picture",
            response.data.data.imageUrl
          );
          localStorage.setItem("userRole", response.data.data.roles.name);

          localStorage.setItem(
            "Languages Allowed",
            response.data.data.languagesAllowed
          );
          let languagesAllowed = localStorage.getItem("Languages Allowed");
          let languagesAllowedArrLen =
            response.data.data.languagesAllowed.length;

          if (languagesAllowedArrLen == 2) {
            localStorage.setItem("Current_Language", "English");
          } else localStorage.setItem("Current_Language", languagesAllowed);
         
          dispatchUserProfilePicture({
            userProfilePic: response.data.data.imageUrl,
          });
          localStorage.setItem("userName", response.data.data.name);
          localStorage.setItem(
            "FirstModuleName",
            response.data.data.roles.modulePermission[0].moduleName
          );
          dispatchFirstModuleName({
            firstModuleName:
              response.data.data.roles.modulePermission[0].moduleName,
          });
          //  window.location.href = `/${response.data.data.roles.modulePermission[0]?.moduleName}`;
          setIsLoading(false);
         
         setTimeout(()=>{
          dispatchLoginUser({
            isLoggedIn: true,
            authorizationToken: response.data.data.accessToken,
          });
          mHistory.push(
            `/${response.data.data.roles.modulePermission[0]?.moduleName}`
          );
          window.location.reload();
         },0) 
          // mHistory.push('/news');
        } else {
          alert(response.message);
        }

        // if (
        //   localStorage.getItem("SESSION_STORAGE_ACCESS_TOKEN ") &&
        //   localStorage.getItem("SESSION_STORAGE_IS_USER_LOGGED_IN") &&
        //   localStorage.getItem("roles") &&
        //   localStorage.getItem("modulePermission") &&
        //   localStorage.getItem("Login_Time") &&
        //   localStorage.getItem("email") &&
        //   localStorage.getItem("userRole") &&
        //   localStorage.getItem("Languages Allowed") &&
        //   localStorage.getItem("Current_Language") &&
        //   localStorage.getItem("userName") &&
        //   localStorage.getItem("FirstModuleName")
        // ) {
        
        // }
      })
      .catch((error) => {
        alert(error);
      });
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <main>
        <section className="relative w-full  h-full py-5 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              background: "#383838",
            }}
          ></div>
          <div className="container-fluid  ">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 ">
                <div
                  className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0"
                  style={{ background: "#525252" }}
                >
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-5">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/SportsTak.png`}
                    />
                    <form style={{ marginTop: 30 }}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-xs font-bold mb-2"
                          style={{ color: "#FFF" }}
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={onChange}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-xs font-bold mb-2"
                          style={{ color: "#FFF" }}
                          htmlFor="grid-password"
                        >
                          Password{" "}
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                          onChange={onChange}
                          value={formData.password}
                        />
                      </div>
                      <div className="text-center mt-6">
                        <button
                          onClick={onLogin}
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="button"
                          style={{ backgroundColor: "#797979" }}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                    <PacmanLoader
                      color="#65a765"
                      loading={isLoading}
                      css={override}
                      size={25}
                    />
                    {/* <div className="text-center mt-6">
                      <Link
                        className=" text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        to="/signUp"
                      >
                        New User? Register here!
                      </Link>
                    </div> */}
                    <div className="text-center mt-6">
                      <Link
                        className=" text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        to="/forgotPassword"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Login;
