import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const UserLanguageDropdown = (props) => {
    
  const onChange = (event) => {
    props.onChange && props.onChange(event.target.value);
 
  };
  
  return (
    <>
      <select
        style={{
          border: '2px solid #acb9ce',
          borderRadius: 10,
        }}
        onChange={onChange}
      >
        <option>Select Language</option>
        
          <option key={1} value='English' disabled={props.current_lang == 'Hindi' ? true : false}>
           English
          </option>
          <option key={2} value='Hindi' disabled={props.current_lang == 'English' ? true : false}>
           Hindi
          </option>
       
      </select>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    docs: state.rolesReducer.docs,
    selectedRoles: state.rolesReducer.selectedRoles,
  };
};
export default connect(mapStateToProps)(UserLanguageDropdown);
