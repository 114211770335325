import React, { useEffect, useState, useRef } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const GptSection = ({ data }) => {
    const [formattedData, setFormattedData] = useState({});
    const [show, setShow] = useState(false);
    const target = useRef(null);

    useEffect(() => {
        const lines = data?.split('\n');
        const formattedData = {};
        lines?.forEach((line) => {
            if (line == "" || line == '') return;
            const [key, value] = line?.split(':');
            const formattedKey = key?.trim().replace(/\*+/g, '');
            const formattedValue = value?.trim().replace(/"/g, ''); // Remove double quotes from the value

            let keys='';
            // console.log('formattedKey', formattedKey)
            if (formattedKey === '1. Meta Title' ||
                formattedKey === '2. Meta Description' ||
                formattedKey === '3. Twitter Description' ||
                formattedKey === '4. Open Graph(OG) Description' ||              
                formattedKey === '5. H1 Tag' ||
                formattedKey === '6. Meta Keywords') {
                    

                        formattedData[formattedKey] = formattedValue;
                    }
            else{
                keys = formattedKey; 
            }

            if(formattedData['6. Meta Keywords'] == ''){
                formattedData['6. Meta Keywords']=keys;
            }

        });
        setFormattedData(formattedData);
    }, [data])

    const copy = async (item) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(item);
            alert("Copied!!");
            // setShow(true);
            // setTimeout(() => { setShow(false) }, 800);

            // return  (show && {<div className={`alert alert-success`}>Copied!!</div>})
        }
    }

    return (
        <>
            {/* {

                show ? 
                    (alert("Copied!!"))
                : null
            } */}

            
            {Object.keys(formattedData).length !== 0 ?

                <div>
                    <List dense={true}>
                        {Object.entries(formattedData).map(([item, value]) => (
                            <ListItem key={item} secondaryAction={<img
                                src="/copy-1.png"
                                ref={target}
                                style={{ maxHeight: "25px", maxWidth: "25px", cursor: "pointer" }}
                                edge="end"
                                alt="cp"
                                onClick={() => copy(value)} />}>
                                <ListItemAvatar style={{ maxWidth: "70px" }}>
                                    {item}
                                </ListItemAvatar>
                                <ListItemText primary={value} style={{ marginLeft: "50px" }} />
                            </ListItem>

                        ))}
                    </List>
                </div> : ""
            }
        </>
    );
}



export default GptSection;