import { LanguageActionTypes } from '../action-types/index';
const initialState = localStorage.getItem('Current_Language', 'English');

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LanguageActionTypes.SWITCH_HINDI:
      localStorage.setItem('Current_Language', 'Hindi');
      window.location.reload();
      return 'Hindi';
    case LanguageActionTypes.SWITCH_ENGLISH:
      localStorage.setItem('Current_Language', 'English');
      window.location.reload();
      return 'English';
    default:
      return state;
  }
};
export default languageReducer;
