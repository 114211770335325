import { configStore } from "../store";
import { LeaguesActionTypes } from "../action-types";

export const dispatchAllLeagues = (payload) => {
  configStore.dispatch({
    payload,
    type: LeaguesActionTypes.GET_ALL_LEAGUES,
  });
};
export const dispatchAllLeaguesTag = (payload) => {
  configStore.dispatch({
    payload,
    type: LeaguesActionTypes.GET_ALL_LEAGUES_TAG,
  });
};

export const dispatchSelectedLeague = (payload) => {
  configStore.dispatch({
    payload,
    type: LeaguesActionTypes.CHANGE_SELECTED_LEAGUE,
  });
};
export const dispatchSearchLeague = (payload) => {
  configStore.dispatch({
    payload,
    type: LeaguesActionTypes.CHANGE_SEARCH_LEAGUE,
  });
};

export const dispatchSelectedLeagueTagging = (payload) => {
  console.log("league payload", payload);
  configStore.dispatch({
    payload,
    type: LeaguesActionTypes.CHANGE_SELECTED_LEAGUE_TAGGING,
  });
};
export const dispatchSelectedAllLeague = (payload) => {
  configStore.dispatch({
    payload,
    type: LeaguesActionTypes.CHANGE_SELECTED_ALL_LEAGUE,
  });
};
