import React, { useEffect, useState } from 'react';
// import { GetPlayersList } from '../../handlers/players.handler';
import Axios from 'axios';
import { API_BASE_PATH,} from '../../constants/workerConstants';
import { makeStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import AlertModal from '../AlertModal';
import LoginAppConstants from '../../constants/LoginAppConstants';
const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
const useStyles = makeStyles((theme) => ({
      formControl: {
        // margin: theme.spacing(1),
        minWidth: 200,
        maxWidth: 200,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        // margin: 2,
      },
      noLabel: {
        // marginTop: theme.spacing(3),
      },
    }));
    
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

const MultipleLeagueDropDown = (props) => {
    // const playersData= props?.data;
    const classes = useStyles();
  const [alertModal, setAlertModal] = useState(false);
  const [name, setName] = useState("");
  const [taggedLeague, setTaggedLeague] = useState([]);
  const [docs, setDocs] = useState([]);
  const [searchedData, setSearchData] = useState([]);

  useEffect(()=>{
    getTopLeagues();
  }, [props?.id]);
  //   let arr = []
  //   taggedLeague?.map((item, index)=>{
  //       if(item!==undefined)
  //       {
  //           arr.push(item?._id);
  //       }
  //   })
  //   if (arr?.length ==0) {
  //       alert("No League has been selected.");
  //     } 
  //   //   else if(props?.playerId!==undefined) {
  //   //     const data = {
  //   //       _id: props?.playerId,
  //   //       league: arr
  //   //     };
  //   //     tagTeamsSpoerWise(data)
  //   //       .then((response) => {
  //   //         if (response.status) {
  //   //           setAlertModal(true);
  //   //         }
  //   //       })
  //   //       .catch((error) => error);
  //   //   }
  //     else{
  //           props.onChange(arr);
  //     }
  // };

    const handleChange = (value) => {
            
                setTaggedLeague(value);
            
            // const value = event.target.value;
            // if (value[value.length - 1] === 'all') {
            //   setTaggedLeague(taggedLeague.length === docs?.length ? [] : docs);
            //   return;
            // }

            let arr = []
          value?.map((item, index)=>{
                if(item!==undefined)
                {
                    arr.push(item?.comp_id);
                }
            })
            props.onChange(arr);
          };

          const getTopLeagues = () => {
            Axios.get(`${API_BASE_PATH}leagues/leagueWatchList`, {
              headers: { 'cms-access-token': accessToken, source: 'cms' },
            })
              .then(async (response) => {
                let leagueData = await response.data.data.data;
                setDocs(leagueData);
                setSearchData(leagueData);
                findSelectedLegue(leagueData);
              })
              .catch((error) => error);
              
          };
          const searchWithKey = (event, value) => {
            
            setName(event.target.value);
            if(event.target.value.length==0)
            {
              setSearchData(docs);
            }
            else{
              const filteredItems = docs?.filter(item =>
              
                item?.abbr?.toLowerCase().includes(event.target.value.toLowerCase())
              
                
            );
            setSearchData(filteredItems);
            }
            
          };
          const findSelectedLegue= (leagueData)=>{
            let arr = [];
              props?.data?.map((item, index)=>{
                const selectLeague = leagueData.filter(x=> x&&x?.comp_id==item?.comp_id)
                if(selectLeague?.[0]!==undefined){
                  arr.push(selectLeague?.[0]);
                }
              })
              // setTaggedLeague(arr);
              handleChange(arr);
          }

    return (
    <>
    {alertModal && (
        <AlertModal
          title="Thanks"
          message="Player has been updated successfully !"
          onSuccess={() => setAlertModal(false)}
        />
      )}
      <div style={{display:"flex", alignItems:"center", marginBottom:"10px"}}>
        <FormControl className={classes.formControl}>
                 <InputLabel id="demo-mutiple-checkbox-label">Tag Leagues</InputLabel>
               <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={taggedLeague}
                  onChange={(event) => handleChange(event.target.value)}
                  input={<Input />}
                  renderValue={(selected) => {
                    return (
                      selected &&
                      selected
                        .map((items) => {
                          return items && items.abbr == 'All' ? items.comp_id : items.abbr;
                        })
                        .join(', ')
                    );
                  }}
                  // MenuProps={MenuProps}
                >
                  {/* <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : '',
                    }}
                  >
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        playerName.length > 0 && playerName.length < playersData?.docs.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem> */}
                  {searchedData?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Checkbox
                        checked={
                          taggedLeague &&
                          taggedLeague?.findIndex((x) => x && x?.comp_id=== item?.comp_id) > -1
                        }
                      />
                      <ListItemText primary={item.abbr == 'All' ? item.comp_id : item.abbr} />
                    </MenuItem>
                  ))}
                    
                </Select>

                  
              </FormControl>
              <input
          type="text"
          placeholder="Search Leagues"
          value={name}
          style={{width:"40%"}}
          onChange={searchWithKey}
          className="border-0 mx-3 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>

        <div style={{display:"flex", flexWrap:"wrap", width:"700px"}}>
          {taggedLeague?.map((item, index)=>
                      <div style={{border:"1px solid green", margin:"5px", padding:"5px", borderRadius:"10px", backgroundColor:"#f1f2f6"}}>{item.abbr == 'All' ? item.comp_id : item.abbr}</div>
          )}
        </div>
            {/* <Button
        className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
          style={{
            marginLeft: "5px",
            // height: '30px',
            // width: 'auto',
            backgroundColor: "rgba(52, 211, 153)",
            color:"#fff",
            marginLeft:"10px",
            marginTop:"25px"
            // color:newsInfo.showArticle && 'white'
          }}
          onClick={tagLeagueToPlayer}
      >
        Tag
      </Button>
      <span style={{fontSize:"10px", marginTop:"25px", marginLeft:"5px"}}>{`(please tap on tag buttton after selecting the options for tagging)`}</span> */}
    </>
    );
    }


export default MultipleLeagueDropDown;

