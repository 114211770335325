import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import NotificationDropdown from "../Dropdowns/NotificationDropdown.js";
import UserDropdown from "../Dropdowns/UserDropdown.js";
import { logoutUser } from "../../services/auth/index.js";
import {
  dispatchHindiLang,
  dispatchEnglishLang,
} from "../../redux/action-dispatchers/language.dispatcher";
import Timer from "../../constants/Timer.js";
import {
  dispatchUserLanguagesAllowed,
} from "../../redux/action-dispatchers/auth.dispatchers.js";
import "./sidebar.css";
import { dispatchSelectedSports } from "../../redux/action-dispatchers/sports.dispatchers.js";
import { dispatchSelectedLeague } from "../../redux/action-dispatchers/leagues.dispatchers.js";
// import { flexbox } from "@mui/system";
let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};
const userName = localStorage.getItem("userName");
const userRole = localStorage.getItem("userRole");
const routes = {
  news: "/news",
  videos: "/videos",
  shorts: "/shorts",
  highlights: "/highlights",
  "editor-choice": "/editor-choice",
  infocus: "/infocus",
  teams: "/teams",
  "st-videos": "/st-videos",
  "add-articles": "/add-articles",
  "add-videos": "/add-videos",
  "top-news": "/top-news",
  "top-videos": "/top-videos",
  "home-page-news": "/home-page-news",
  "home-page-videos": "/home-page-videos",
  "home-page-feature": "/home-page-feature",
  youtube: "/youtube",
  "opta-graphics": "/opta-graphics",
  "feature-card": "/feature-card",
  banners: "/banners",
  "manage-league": "/manage-league",
  "manage-live": "/manage-live",
  vimeo: "/vimeo",
  version: "/version",
  "manage-author": "/manage-author",
  "cms-users": "/cms-users",
  roles: "#",
  quiz: "/quiz",
  polls: "/polls",
  "add-shorts": "/add-shorts",
  productivity: "/productivityReportNews",
  report:"/report",
  "team-player-notification": "/teamPlayerNotification",
  "dynamic-banner": "/dynamicBanner",
  purge: "/purge",
  "featured-content":"/featuredContent",
  "player-profile":"/player-profile",
  "google-analytics-manager":"google-analytics-manager",
  "team-manager":"/team-manager",
};
const Sidebar = (props) => {
  const mHistory = useHistory();
  const [collapseShow, setCollapseShow] = useState("hidden");
  const [modulePermissionLocalStArr, setModulePermissionLocalStArr] = useState(
    []
  );
  const modulePermissionLocalSt = localStorage.getItem("modulePermission");

  const lang = localStorage.getItem("Languages Allowed");
  const langArr = lang?.split(",");

  let parseModulePermission = JSON.parse(modulePermissionLocalSt);
  useEffect(() => {
    setModulePermissionLocalStArr(parseModulePermission);
  }, []);
  useEffect(() => {
    if (langArr) {
      dispatchUserLanguagesAllowed(langArr);
    }
  }, []);
  let currentLang = localStorage.getItem("Current_Language");
  // const timer = Date.now();
  const localTime = window.localStorage.getItem("Login_Time");
  const timer = parseInt(localTime);
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-1 px-0"
            to="/"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/SportsTak.png`}
              style={{ paddingBottom: "0px" }}
            />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-1 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times "></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {props.languagesAllowed && props.languagesAllowed.length == 1 ? (
                <>
                  <h1
                    style={{
                      display: "flex",
                      // alignSelf: 'center',
                      justifySelf: "center",
                      fontSize: "14px",
                      padding: "4px",
                      // marginLeft: '28px',
                      fontWeight: "600",
                      width: "46%",
                      height: "32px",
                      // position: 'relative',
                      // top: '-10px',
                      // left: '7px',
                      color: "green",
                      // border: '1.5px solid green',
                      borderRadius: "30px",
                      opacity: 0.8,
                      background: "lightgreen",
                    }}
                  >
                    <>
                      <i
                        className={
                          "fas fa-globe mr-1 mb-1 ml-1 opacity-80 text- font-bold"
                        }
                        style={{ lineHeight: "1.5" }}
                      ></i>
                      <span style={{ paddingRight: "4px" }}>{currentLang}</span>
                    </>
                  </h1>
                </>
              ) : (
                <h1
                  style={{
                    // alignSelf: 'center',
                    display: "flex",
                    justifySelf: "center",
                    fontSize: "14px",
                    padding: "4px",
                    // marginLeft: '28px',
                    fontWeight: "600",
                    width: "46%",
                    height: "32px",

                    // position: 'relative',
                    // top: '-10px',
                    // left: '7px',
                    color: "green",
                    // border: '1.5px solid green',
                    borderRadius: "30px",
                    opacity: 0.8,
                    background: "lightgreen",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (currentLang == "Hindi") {
                      localStorage.setItem("Current_Language", "English");
                      mHistory.push("/news");
                      dispatchEnglishLang("English");
                      alert("Language will be changed to English");
                    } else {
                      localStorage.setItem("Current_Language", "Hindi");
                      mHistory.push("/news");
                      dispatchHindiLang("Hindi");
                      alert("Language will be changed to Hindi");
                    }
                    dispatchSelectedSports({
                      selectedSports: "",
                      selectedSportsFilter: "",
                    });
                    dispatchSelectedLeague({
                      selectedLeague: "",
                      selectedLeagueFilter: "",
                   });
                   localStorage.removeItem("videoType");
                   localStorage.removeItem("videoStatusType");
                   localStorage.removeItem("newsStatusType");
                   localStorage.removeItem("newsTypeDropdown");
                   localStorage.removeItem("newsFilterEndDate");
                   localStorage.removeItem("newsFilterStartDate");
                   localStorage.removeItem("newsSportsFilter");
                   localStorage.removeItem("newsLeagueFilter");
                   localStorage.removeItem("newsSearchFilter");
                   localStorage.removeItem("videoSearch");
                    localStorage.removeItem("videosLeagueFilter");
                    localStorage.removeItem("videoSportsFilter");
                    localStorage.removeItem("teamsSportFilter");
                      localStorage.removeItem("teamLeagueFilter");
                      localStorage.removeItem("teamSearchFilter");
                  }}
                  
                >
                  {/* Language- <span style={{ color: 'red' }}>"{capitalLang}"</span> */}

                  <>
                    {currentLang == "Hindi" || currentLang == undefined ? (
                      <div className="language-container">
                        <i
                          className={
                            "fas fa-globe mr-1 mb-1 ml-1 opacity-80 text- font-bold"
                          }
                        ></i>{" "}
                        <span> Hindi</span>
                      </div>
                    ) : (
                      <div className="language-container">
                        <i
                          className={
                            "fas fa-globe mr-1 mb-1 ml-1 opacity-80 text- font-bold"
                          }
                          style={{ lineHeight: 1.5 }}
                        ></i>
                        <span style={{ paddingRight: "5px" }}>English</span>
                      </div>
                    )}
                  </>
                </h1>
              )}
              <div>
                <p style={{ fontWeight: "bold", marginLeft: "20px" }}>
                  {userName}
                </p>
              </div>
            </div>
            <div className="timer-role-container">
              <p
                style={{
                  fontWeight: "600",
                  marginRight: "20px",
                }}
              >
                {userRole}
              </p>
              {/* <Timer start={timer} /> */}
            </div>
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                {modulePermissionLocalStArr?.map(({ moduleName }) => (
                  <>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(
                          `${routes[moduleName]}`
                        ) !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to={routes[moduleName]}
                      onClick={()=>{
                        dispatchSelectedSports({
                          selectedSports: "",
                          selectedSportsFilter: "",
                        });
                        dispatchSelectedLeague({
                          selectedLeague: "",
                          selectedLeagueFilter: "",
                       });
                      //  localStorage.removeItem("videoType");
                      //  localStorage.removeItem("videoStatusType");
                  //      localStorage.removeItem("newsStatusType");
                  //      localStorage.removeItem("newsTypeDropdown");
                  //      localStorage.removeItem("newsFilterEndDate");
                  // localStorage.removeItem("newsFilterStartDate");
                      }}
                    >
                      <i
                        className={
                          "fas fa-tv mr-2 text-sm " +
                          (window.location.href.indexOf(
                            `${routes[moduleName]}`
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>
                      {moduleName}
                    </Link>
                  </>
                ))}
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase mt-0 py-3 font-bold block " +
                    (window.location.href.indexOf("/whatsNew") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600 "
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/whatsNew"
                  onClick={()=>{
                    dispatchSelectedSports({
                      selectedSports: "",
                      selectedSportsFilter: "",
                    });
                    dispatchSelectedLeague({
                      selectedLeague: "",
                      selectedLeagueFilter: "",
                   });
                  //  localStorage.removeItem("videoType");
                  //      localStorage.removeItem("videoStatusType");
                  //      localStorage.removeItem("newsStatusType");
                  //      localStorage.removeItem("newsTypeDropdown");
                  //      localStorage.removeItem("newsFilterEndDate");
                  // localStorage.removeItem("newsFilterStartDate");
                  }}
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/whatsNew") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  whatsNew Feature
                </Link>
              </li>
              <hr className="my-4 md:min-w-full" />
              <li className="items-center">              
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/team-manager") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/team-manager"
                      onClick={()=>{
                        dispatchSelectedSports({
                          selectedSports: "",
                          selectedSportsFilter: "",
                        });
                        dispatchSelectedLeague({
                          selectedLeague: "",
                          selectedLeagueFilter: "",
                       });
                      //  localStorage.removeItem("videoType");
                      //  localStorage.removeItem("videoStatusType");
                  //      localStorage.removeItem("newsStatusType");
                  //      localStorage.removeItem("newsTypeDropdown");
                  //      localStorage.removeItem("newsFilterEndDate");
                  // localStorage.removeItem("newsFilterStartDate");
                      }}
                    >
                      <i
                        className={
                          "fas fa-tv mr-2 text-sm " +
                          (window.location.href.indexOf("/team-manager") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>
                      Team-Manager
                    </Link>
              </li>
              <li className="items-center">              
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/featured-content") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/featured-content"
                      onClick={()=>{
                        dispatchSelectedSports({
                          selectedSports: "",
                          selectedSportsFilter: "",
                        });
                        dispatchSelectedLeague({
                          selectedLeague: "",
                          selectedLeagueFilter: "",
                       });
                      //  localStorage.removeItem("videoType");
                      //  localStorage.removeItem("videoStatusType");
                      //  localStorage.removeItem("newsStatusType");
                      //  localStorage.removeItem("newsTypeDropdown");
                  //      localStorage.removeItem("newsFilterEndDate");
                  // localStorage.removeItem("newsFilterStartDate");
                      }}
                    >
                      <i
                        className={
                          "fas fa-tv mr-2 text-sm " +
                          (window.location.href.indexOf("/featured-content") !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>
                      Featured-Content
                    </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/report") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600 "
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/report"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/report") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  Report
                </Link>
              </li>
              <hr className="my-4 md:min-w-full" />
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase mt-0 pb-4 font-bold block " +
                    (window.location.href.indexOf("/twitterTag") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600 "
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/twitterTag"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/twitterTag") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  Twitter
                </Link>
              </li>
              <hr className="my-4 md:min-w-full" />
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase mt-0 pb-4 font-bold block " +
                    (window.location.href.indexOf("/google-analytics-manager") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600 "
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/google-analytics-manager"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/google-analytics-manager") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  GA-Manager
                </Link>
              </li>
              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase mt-0 pb-4 font-bold block " +
                    (window.location.href.indexOf("/purge") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600 "
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/purge"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/twitterTag") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  Purge
                </Link>
              </li> */}
              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase mt-0 pb-4 font-bold block " +
                    (window.location.href.indexOf("/dynamicBanner") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600 "
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/dynamicBanner"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/dynamicBanner") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  Dynamic Banner
                </Link>
              </li> */}
              {/*  <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase mt-0 pb-4 font-bold block ' +
                    (window.location.href.indexOf('/teamPlayerNotification') !==
                    -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600 '
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/teamPlayerNotification"
                >
                  <i
                    className={
                      'fas fa-tv mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/teamPlayerNotification'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }
                  ></i>
                  Team Player Notify
                </Link>
              </li> */}
              {/* <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase mt-0 pb-4 font-bold block ' +
                    (window.location.href.indexOf('/productivityReportNews') !==
                    -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600 '
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/productivityReportNews"
                >
                  <i
                    className={
                      'fas fa-tv mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/productivityReportNews'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }
                  ></i>
                  Productivity Report
                </Link>
              </li> */}
              {/* <hr className="my-4 md:min-w-full" /> */}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase mt-0 pb-4 font-bold block " +
                    (window.location.href.indexOf("/admin/manage") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600 "
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/"
                  onClick={() => {
                    logoutUser();
                    mHistory.push("/");
                  }}
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/logout") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    userType: state.authReducer.userType,
    userData: state.authReducer.userData,
    languagesAllowed: state.authReducer.languagesAllowed,
    currentLanguage: state.authReducer.currentLanguage,
    modulePermissionLocalSt: state.authReducer.modulePermissionLocalSt,
  };
};
export default connect(mapStateToProps)(Sidebar);
