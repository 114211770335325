import WhatsNewTable from '../../../../components/Cards/WhatsNewTable';
import Sidebar from '../../../../components/Sidebar/Sidebar';

const WhatsNew = () => {
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100"
                style={{ paddingTop: 10 }}>
                <div className="flex flex-wrap">
                    <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
                        <WhatsNewTable color="light" />
                    </div>
                </div>
            </div>
        </>
    );

}

export default WhatsNew;
