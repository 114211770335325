import { dispatchLiveSchedules } from '../redux/action-dispatchers/schedules.dispatchers';
import { dispatchAllLeagues } from '../redux/action-dispatchers/leagues.dispatchers';

const socketToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmVkOWQyYmIzMDdjMjVkNWViN2ExOTEiLCJpYXQiOjE2MTEzMDAxOTl9.uXRWTt3l8fXz-t4TQUSLZk6UrnOjAme0LUkLR-61CAc';
const lang = localStorage.getItem('lang')
  ? localStorage.getItem('lang')
  : 'english';
const mSocket = new WebSocket(
  `wss://production-socket.thesportstak.com/${lang}/`,
  socketToken
);
// const mSocket = new WebSocket(
//   `wss://hindi-develop-socket.thesportstak.com/${lang}/`,
//   socketToken
// );

export const subscribeSocketMatchId = (matchId, eventName, sportName) => {
  mSocket.send(
    JSON.stringify({
      event: eventName,
      matchId,
      sports: sportName,
    })
  );
};

mSocket.onopen = (event) => {
  subscribeSocketMatchId('', 'liveScoreCard', '');
};

mSocket.onmessage = (event) => {
  const data = JSON.parse(event.data);
  switch (data.event) {
    case 'liveScoreCard':
      dispatchAllLeagues(data.response.leagues);
      dispatchLiveSchedules(data.response.items);
      break;
    case 'liveScore':
      break;
    default:
      break;
  }
};
mSocket.onerror = (event) => {};

mSocket.onclose = (event) => subscribeSocketMatchId('', 'liveScoreCard', '');
