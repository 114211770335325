import { configStore } from "../store";
import { TwitterNativeActionTypes } from "../action-types";

export const dispatchTwitterDocsToEdit = (payload) => {
  configStore.dispatch({
    payload,
    type: TwitterNativeActionTypes.TWITTER_VIDEOS_DOCS,
  });
};
export const dispatchAllTwitterVideosInfo = (payload) => {
  configStore.dispatch({
    payload,
    type: TwitterNativeActionTypes.TWITTER_VIDEOS_ALL_INFO,
  });
};
export const dispatchSelectedTwitterTag = (payload) => {
  configStore.dispatch({
    payload,
    type: TwitterNativeActionTypes.GET_SELECTED_TAG,
  });
};
export const dispatchTwitterMenuTxt = (payload) => {
  configStore.dispatch({
    payload,
    type: TwitterNativeActionTypes.GET_TWITTER_MENU_TEXT,
  });
};
