import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {
  DeleteTweet,
  GetTwitterVideos,
} from '../../../handlers/twitter.handlers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './TwitterTag.css';
import { useHistory, useParams } from 'react-router-dom';
import { _dateTimeFormat } from '../../../constants/appDefaults';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';

import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', maybeHandler);

    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  });

  return domNode;
};
const SearchTagTwitter = (props) => {
  const { tag } = useParams();
  const history = useHistory();
  const [docs, setDocs] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [id, setTwitterID] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const mHistory = useHistory();
  const editVideo =(video_id)=>{
    mHistory?.push(`/twitterVideosDetail/${video_id}`);
  }
  let domNode = useClickOutside(() => {
    setOpenDropdown(false);
  });
  const getTwitterVideo = () => {
    setIsLoading(true);
    GetTwitterVideos(page, tag, searchKey, props?.selectedLanguage).then((response) => {
      if (response.status) {
        setIsLoading(false);
        setDocs(response.data.docs);
        setTotalPages(response.data.totalPages);
      }
    });
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    getTwitterVideo();
  }, [tag, page]);
  const handleBack = () => {
    history.goBack();
  };
  const handlePagination = (event, value) => {
    setPage(value);
  };
  const showModalForPublish = (id) => {
    let title = 'Delete Twitter?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    DeleteTweet({ _id: id })
      .then((res) => {
        if (res) {
          // alert("Twitter Deleted Successfully");
          getTwitterVideo();
        } else {
          alert('Something went wrong');
        }
      })
      .catch((error) => error);
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 ">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal?.message}
                title={confirmationModal?.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                'bg-white'
              }
            >
              <div className="rounded-t mb-0 py-3 border-0 searchTagTwitter-outer-container">
                <div className="flex flex-wrap flex-col searchTagTwitter-big-container">
                  <>
                    <div
                      className=" pb-4"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                        width: '30px',
                      }}
                      onClick={handleBack}
                    >
                      <ArrowBackIcon />
                    </div>
                    <h1 className="px-2 py-2">Published</h1>

                    <h1 className="searchTagTwitter-title">
                      {props.twitterMenuTxt}
                    </h1>
                  </>

                  <div className="searchTagTwitter-content">
                    {docs?.map((item) => (
                      <div className="searchTagTwitter-wrapper"
                      style={{cursor:'pointer'}}
                      onClick={() => {
                        editVideo(item?._id);
                      }}>
                        <div className="searchTagTwitter-container">
                          <div className="searchTagTwitter-info">
                            <img
                              className="searchTagTwitter-mainImg"
                              src={`${item?.media?.preview_image_url}`}
                            />
                            <div className="searchTagTwitter-title-container">
                              <h1 className="searchTagTwitter-box-title">
                                {item?.author?.name}
                              </h1>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {/* <img
                                  src={`${process.env.PUBLIC_URL}/assets/img/SportsTak1.png`}
                                  height="30"
                                  width="30"
                                /> */}
                                <i
                                  onClick={() => {
                                    setTwitterID(item?._id);
                                    showModalForPublish();
                                  }}
                                  className="fas fa-trash "
                                  style={{
                                    cursor: 'pointer',
                                    paddingLeft: '15px',
                                  }}
                                ></i>
                              </div>
                            </div>
                            <p className="searchTagTwitter-detail">
                              {item?.text?.substr(0, 70)}...
                            </p>
                            <h2 className="searchTagTwitter-time">
                              {_dateTimeFormat(
                                item?.created_at,
                                'ddd - DD MMM YYYY'
                              )}
                            </h2>
                          </div>
                        </div>
                        {/* <div className="searchTagTwitter-tools">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/more.png`}
                            width="180px"
                            // height={'250px'}
                            style={{ marginTop: "-5px", cursor: "pointer" }}
                            onClick={() =>
                              setOpenDropdown((openDropdown) => !openDropdown)
                            }
                          />
                        </div> */}

                        {/* <div
                          ref={domNode}
                          className={`${
                            openDropdown
                              ? "searchTagTwitter-tools__opened"
                              : "searchTagTwitter-tools__closed"
                          }`}
                        >
                          <ul className="searchTagTwitter-dropdown-list">
                            <li className="searchTagTwitter-dropdown-items">
                              <span>
                                <span className="searchTagTwitter-droptown-span">
                                  Feature Post
                                </span>
                              </span>
                            </li>
                            <li className="searchTagTwitter-dropdown-items">
                              <span>
                                <span className="searchTagTwitter-droptown-span">
                                  Edit Post
                                </span>
                              </span>
                            </li>
                            <li className="searchTagTwitter-dropdown-items">
                              <span>
                                <span className="searchTagTwitter-droptown-span">
                                  Delete Post
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    ))}
                    {/* end */}
                  </div>
                  {/* main end */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      padding: 20,
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        page={page}
                        onChange={handlePagination}
                        count={totalPages}
                        variant="outlined"
                        color="primary"
                      />
                    </Stack>
                    {/* console.log */}
                  </div>
                  <div style={{ textAlign: 'center', padding: 10 }}>
                    <SyncLoader
                      color="#23A5E0"
                      loading={isLoading}
                      css={override}
                      size={12}
                    />
                  </div>
                </div>
                {/* <div className="searchTagTwitter-small-container">
                  <div className="searchTagTwitter-search-container">
                    <h1>Search</h1>
                    <hr className="my-4 md:min-w-full" />
                    <div className="searchTagTwitter-search-radio">
                      <div className="searchTagTwitter-seach-radio-container">
                        <input
                          type="radio"
                          id="hashtag"
                          name="search_type"
                          value="Hashtag"
                        />
                        <label for="hashtag">Hashtag</label>
                      </div>
                      <div className="searchTagTwitter-seach-radio-container">
                        <input
                          type="radio"
                          id="keyword"
                          name="search_type"
                          value="Keyword"
                        />
                        <label for="keyword">Keyword</label>
                      </div>
                      <div className="searchTagTwitter-seach-radio-container">
                        <input
                          type="radio"
                          id="user_profile"
                          name="search_type"
                          value="User Profile"
                        />
                        <label for="user_profile" style={{ width: "100px" }}>
                          User Profile
                        </label>
                      </div>
                    </div>
                    <div className="searchTagTwitter-search">
                      <input type="text" placeholder="Content Search" />
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/search.svg`}
                        width={60}
                      />
                    </div>
                    <div className="searchTagTwitter-empty">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/empty.png`}
                        width={300}
                      />
                      <h1>
                        Search new social contents and discover a new world of
                        digital assets
                      </h1>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    twitterMenuTxt: state.twitterNativeReducer.twitterMenuTxt,
    selectedLanguage: state.languageReducer,
  };
};
export default connect(mapStateToProps)(SearchTagTwitter);
