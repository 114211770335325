import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import CropperModal from '../../../../components/CropperModal';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import {
  GetShortsVideosInfo,
  mapVideosToLeague,
  mapArticleToSports,
  ChangeVideoStatus,
  ChangeShortsStatus,
  uploadShortsVideoThumbnail,
  updateInfo,
  tagHome,
  tagVideoToTop,
  tagHighlight,
  mapShortsVideosToLeague,
  mapShortsToSports,
} from '../../../../handlers/videos.handlers';
import HomeSpecialDropdown from '../../../../components/Dropdowns/HomeSpecialDropdown';
import { KabaddiSpecialVideos } from '../../../../handlers/Kabaddi.handlers';
import { getAllSports } from '../../../../handlers/sports.handler';
import LeaguesDropDown from '../../../../components/Dropdowns/LeaguesDropDown';
import AllLeagueDropDown from '../../../../components/Dropdowns/AllLeagueDropDown';
import SportsDropdown from '../../../../components/Dropdowns/SportsDropDown';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import {
  dispatchAllLeagues,
  dispatchSelectedLeague,
} from '../../../../redux/action-dispatchers/leagues.dispatchers';
import {
  dispatchAllSports,
  dispatchSelectedSports,
} from '../../../../redux/action-dispatchers/sports.dispatchers';
import Axios from 'axios';
import {
  API_BASE_PATH,
  OLD_BASE_PATH,
} from '../../../../constants/workerConstants';
import PreviewModal from '../../../../components/PreviewModal';
import PulseLoader from 'react-spinners/PulseLoader';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { css } from '@emotion/core';
import {
  _getDateTime,
  _dateTimeFormat,
} from '../../../../constants/appDefaults';
import AlertModal from '../../../../components/AlertModal';
import ShortsLeagueDropdown from '../../../../components/Dropdowns/ShortsLeagueDropdown';
import axios from 'axios';
import LoginAppConstants from '../../../../constants/LoginAppConstants';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const ShortsVideosInfo = (props) => {
  const [alertModal, setAlertModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [VideosInfo, setVideosInfo] = useState(undefined);
  const [showCropper, setShowCropper] = useState(false);
  const [taggedLeague, setTaggedLeague] = useState('');
  const [changeLeague, setChangeLeague] = useState(false);
  const [taggedSports, setTaggedSports] = useState('');
  const [changeSports, setChangeSports] = useState(false);
  const [homeSpecialType, setHomeSpecialType] = useState('');
  const [taggedAllLeague, setTaggedAllLeague] = useState(false);
  const [changeAllLeague, setChangeAllLeague] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [thumbName, setThumbName] = useState('');
  const [seoSlug, setSeoSlug] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [title, setTitle] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const [description, setDescription] = useState('');
  const [invalidSeoSlug, setInvalidSeoSlug] = useState(false);

  const [leagues, setLeagues] = useState([]);
  // const [isHomeTagged, setIsHomeTagged] = useState();
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });

  const { id } = useParams();
  const { isyoutube } = useParams();

  const [selectedImage, setSelectedImage] = useState();
  const [titleInput, setTitleInput] = useState('');
  let fileInput = React.useRef();
  const history = useHistory();
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  // highlights

  useEffect(() => {
    axios
      .get(
        `${API_BASE_PATH}leagues/leagueWatchList`,
        { headers: { 'cms-access-token': accessToken, source: 'cms' } },
        {
          params: JSON.stringify({
            username: localStorage.getItem('email'),
          }),
        }
      )
      .then((res) => {
        //
        dispatchAllLeagues(res.data.data.data);
      });
  }, []);
  useEffect(() => {
    getAllSports({ Authorization: props.Authorization })
      .then((response) => {
        if (response.status) {
          dispatchAllSports(response.data);
        } else {
          dispatchAllSports([]);
        }
      })
      .catch((error) => {
        dispatchAllSports([]);
      });

    getVideosInformation();
    //onSportSelected();  //Sport
  }, []);

  useEffect(() => {
    if (VideosInfo) {
      const leagueInfo = props.leagues.find(
        (item) => item.comp_id === VideosInfo.league[0]
      );
      if (leagueInfo) {
        setTaggedLeague(leagueInfo.abbr);
      }
      
      if (VideosInfo.title) {
        setTitle(VideosInfo.title)
      }
      if (VideosInfo.metaTitle) {
        setSeoTitle(VideosInfo.metaTitle)
        setSeoSlug(VideosInfo.seoSlug);
      }
      if (VideosInfo.metaDescription) {
        setSeoDescription(VideosInfo.metaDescription)
      }
      if (VideosInfo.description) {
        setDescription(VideosInfo.description)
      }
    }
    //
    if (VideosInfo && VideosInfo.sports && VideosInfo.sports._id) {
      const sportsInfo = props.sports.find(
        (item) => item._id === VideosInfo.sports._id
      );
      if (sportsInfo) {
        setTaggedSports(sportsInfo.name);
      }
    }
  }, [VideosInfo, homeSpecialType, props.leagues, props.sports]);

  useEffect(() => {
    changeTitle();
  }, [seoTitle]);

  useEffect(() => {
    changeSlug();
  }, [seoTitle]);

  const changeTitle = () => {
    let new_seo_title = seoTitle;
    new_seo_title = new_seo_title.replaceAll(" ", "-");
    if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(new_seo_title)) || new_seo_title.charAt(0) == '-') {
      setInvalidSeoSlug(true);
      return;
    }
    setInvalidSeoSlug(false);
    setSeoTitle(new_seo_title.toLowerCase());
  };
  const changeSlug = () => {
    let new_seo_slug = seoTitle;
    new_seo_slug = new_seo_slug.replaceAll(" ", "-");
    if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(new_seo_slug)) || new_seo_slug.charAt(0) == '-') {
      setInvalidSeoSlug(true);
      return;
    }
    setInvalidSeoSlug(false);
    setSeoSlug(new_seo_slug.toLowerCase());
  };

  
  
  //Sport Start

  //Sport End

  const getVideosInformation = () => {
    GetShortsVideosInfo(id, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        setVideosInfo(response.data);

        setShowMedia(response.data.media.content[0].url);
        setMediaThumbnail(
          response.data.media.thumbnail[0] &&
            response.data.media.thumbnail[0].url
        );
      }
    });
  };

  const onCropperClose = () => setShowCropper(false);
  const closeCropperAndSetData = (imageData, imagePath) => {
    setVideosInfo({
      ...VideosInfo,
      imgUrl: imagePath,
    });
    const body = {
      _id: VideosInfo._id,
      thumbnail: imageData,
      thumbnailName: thumbName,
    };
    uploadShortsVideoThumbnail(body, {
      Authorization: props.Authorization,
    })
      .then((response) => {})
      .catch((error) => {});

    setShowCropper(false);
  };

  //send notificaion tagged teams

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    setSelectedImage(file);
    setThumbName(file.name);
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 960 && width === 540) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
          });
        } else {
          alert('Image width and height must be 960x540 px');
          return false;
        }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) {};
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    var publishedStatus = VideosInfo.status;
    publishedStatus === 1 ? (publishedStatus = 0) : (publishedStatus = 1);
    ChangeShortsStatus(VideosInfo._id, publishedStatus, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.status) {
        getVideosInformation();
      }
    });
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };

  const showModalForPublish = () => {
    var title = 'Publish/Unpublish Video';
    var message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };
  // home expiry

  // Top videos home videos

  const onLeagueSelected = (value) => {
    // dispatchSelectedAllLeague({
    //   selectedAllLeague: leagueName,
    // });

    setChangeLeague(false);
    let selectedLeagues = [];
    selectedLeagues.push(value);
    // props.selectedAllLeague &&
    //   props.selectedAllLeague.map((leagues) => selectedLeagues.push(leagues));
    // setLeagues([...leagues, value]);
    const body = {
      _id: VideosInfo._id,
      league: selectedLeagues,
    };

    VideosInfo &&
      mapShortsVideosToLeague(body)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };

  const tagLeagues = () => {
    const body = {
      _id: VideosInfo._id,
      league: leagues,
    };

    VideosInfo &&
      mapShortsVideosToLeague(body)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };
  // All league selection dropdown
  const onAllLeagueSelected = (value, uuid) => {
    setChangeAllLeague(false);
    VideosInfo &&
      value !== 0 &&
      mapVideosToLeague(VideosInfo._id, value, uuid)
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };
  // Sport Start
  const onSportsSelected = (value) => {
    setChangeSports(false);
    VideosInfo &&
      value !== 0 &&
      mapShortsToSports(VideosInfo._id, value, '')
        .then((response) => {
          if (response.status) {
            getVideosInformation();
            setAlertModal(true);
          }
        })
        .catch((error) => {});
  };

  // Sport End

  const onPreviewClose = () => setPreviewModal({ imageData: '', show: false });
  const onPreviewSuccess = () => {
    let videoId = VideosInfo._id;
    let thumbnailId = VideosInfo.media.thumbnail[0]._id;

    setIsImageUploading(true);
    setVideosInfo({
      ...VideosInfo,
      imgUrl: previewModal.imageData,
    });
    setPreviewModal({
      imageData: '',
      show: false,
    });
    const data = {
      _id: VideosInfo._id,
      // thumbnail: selectedImage,
      thumbnail: previewModal.imageData,
      thumbnailName: thumbName,
    };
    const formData = new FormData();
    Object.keys(data).forEach(function (key) {
      formData.append(key, data[key]);
    });
    uploadShortsVideoThumbnail(data, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setIsImageUploading(false);
      })
      .catch((error) => {
        setIsImageUploading(false);
      });
  };
  const handleBack = () => {
    history.goBack();
  };
  const updateInfoCall = () => {
    if (title?.length == 0) {
      alert("Title Cannot be empty.");
      return;
    } if (title?.length < 50) {
      alert("Please update your title to minimum 50 Characters.");
      return;
    } if (title?.length > 120) {
      alert("Please update your title to maximum 120 Characters.");
      return;
    }
    if (description?.length == 0) {
      alert("Descripiton Can not be empty.");
      return;
    } if (description?.length < 140) {
      alert("Please update your Description to minimum 140 Characters.");
      return;
    } if (description?.length > 200) {
      alert("Please update your Description to maximum 200 Characters.");
      return;
    }
    if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(seoTitle)) || seoTitle?.charAt(0) == '-') {
      alert("SEO Title is Invalid, It must contain only characters between a-z, A-Z, 0-9 or -.");
      setInvalidSeoSlug(true);
      return;
    }
    if (!(/^[A-Za-z0-9-\s०-९ऀ-हऽ-ौॎ-ॏक-हऽ-य़\u0900-\u097F]*$/.test(seoSlug)) || seoSlug?.charAt(0) == '-') {
      alert("Slug is Invalid, It must contain only characters between a-z, A-Z, 0-9 or -.");
      setInvalidSeoSlug(true);
      return;
    } if (seoTitle?.length == 0) {
      alert("seo title Cannot be empty.");
      return;
    } if (seoTitle?.length < 50) {
      alert("Please update your SEO title to minimum 50 Characters.");
      return;
    } if (seoTitle?.length > 140) {
      alert("Please update your SEO title to maximum 140 Characters.");
      return;
    } if (seoDescription?.length == 0) {
      alert("Meta Descripiton Can not be empty.");
      return;
    } if (seoDescription?.length < 140) {
      alert("Please update your SEO Description to minimum 140 Characters.");
      return;
    } if (seoDescription?.length > 200) {
      alert("Please update your SEO Description to maximum 200 Characters.")
      return;
    }

    const body = {
      _id: VideosInfo._id,
      title: title,
      seoSlug: seoSlug,
      metaTitle: seoTitle,
      metaDescription: seoDescription,
      description: description,
    };
    updateInfo(body, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response.status) {
          getVideosInformation();
          // setConfirmationModal({ message: "Info updated successfully", show: true, title: ""})
          setAlertModal(true);
        }
      })
      .catch((error) => { alert("error occured in updateing info"+error)});

  };
  return (
    <>
      <Sidebar />

      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            {showCropper && VideosInfo && (
              <CropperModal
                image={selectedImage}
                onClose={onCropperClose}
                closeCropperAndSetData={closeCropperAndSetData}
              />
            )}
            {previewModal.show && (
              <PreviewModal
                imageData={previewModal.imageData}
                title="Upload Video"
                message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                onSuccess={onPreviewSuccess}
                onClose={onPreviewClose}
              />
            )}
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your video has been updated successfully !"
                onSuccess={() => setAlertModal(false)}
              />
            )}
            {VideosInfo && (
              <div>
                <div>
                  <div
                    className=" pb-4"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '-20px',
                      width: '30px',
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIcon />
                  </div>
                  <div
                    style={{ display: 'inline-block', verticalAlign: 'top' }}
                  >
                    <ReactPlayer
                      controls
                      url={showMedia}
                      light={mediaThumbnail}
                    />

                    {isImageUploading ? (
                      <div style={{ textAlign: 'c', padding: 10 }}>
                        <div>Uploading Image Please Wait...</div>
                        <div>
                          <PulseLoader
                            color="#23A5E0"
                            loading={true}
                            css={override}
                            size={10}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div></div>
                        <div>
                          <input
                            ref={fileInput}
                            type="file"
                            multiple={false}
                            onChange={onFileSelect}
                            accept="image/x-png,image/jpeg"
                            style={{
                              backgroundColor: '#000',
                              height: 35,
                              width: 40,
                              position: 'absolute',
                              opacity: 0,
                              cursor: 'pointer',
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                              margin: 8,
                              color: '#555',
                              cursor: 'pointer',
                            }}
                          ></i>
                          Please upload image in the dimension of 960x540
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                      padding: 20,
                    }}
                  >
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ marginBottom: 15 }}>
                        {VideosInfo.status === 1
                          ? 'Video is published. Click below to unpublish this video'
                          : 'Video is not published. Click below to publish this video'}
                      </div>

                      <button
                        className={`bg-${
                          VideosInfo.status === 1 ? 'red' : 'emerald'
                        }-500 text-white active:bg-${
                          VideosInfo.status === 1 ? 'red' : 'emerald'
                        }-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                        type="button"
                        onClick={showModalForPublish}
                      >
                        {VideosInfo.status === 1
                          ? 'Unpublish Video'
                          : 'Publish Video'}
                      </button>

                      {/* {VideosInfo?.status == 1 && disable == false && (
                        <input
                          type="text"
                          style={{
                            margin: '2px 5px',
                            height: '40px',
                            borderRadius: '5px',
                          }}
                          placeholder="Please  Title here"
                          value={titleInput}
                          onChange={(e) => setTitleInput(e.target.value)}
                        />
                      )} */}
                    </div>
                    <hr className="my-4 md:min-w-full" />
                    <div className="flex">
                      {!changeLeague && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            marginRight: '80px',
                            fontWeight: '500',
                          }}
                        >
                          Tagged League:
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeLeague(true);
                              // dispatchSelectedLeague({
                              //   selectedLeague: VideosInfo.league,
                              //   selectedLeagueFilter: VideosInfo.league,
                              // });
                            }}
                          ></i>
                          {taggedLeague}
                        </div>
                      )}
                      {changeLeague && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          {/* <ShortsLeagueDropdown onChange={onLeagueSelected} /> */}
                          <LeaguesDropDown onChange={onLeagueSelected} />

                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              margin: 8,
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeLeague(false);
                            }}
                          ></i>
                        </div>
                      )}
                      {/* All league dropdown */}
                      {/* <div>
                        {!changeAllLeague && (
                          <div
                            style={{
                              marginTop: 5,
                              marginBottom: 10,
                              marginRight: '80px',
                              fontWeight: '500',
                            }}
                          >
                            Tagged All League:{' '}
                            <i
                              className="fas fa-edit fa-1x"
                              style={{
                                marginLeft: '10px',
                                marginRight: '5px',
                                color: '#c3c3c3',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setChangeAllLeague(true);
                                dispatchSelectedLeague({
                                  selectedLeague: VideosInfo.league,
                                  selectedLeagueFilter: VideosInfo.league,
                                });
                              }}
                            ></i>
                            {taggedAllLeague}
                          </div>
                        )}

                        {changeAllLeague && (
                          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            
                            <AllLeagueDropDown onChange={onAllLeagueSelected} />

                            <i
                              className="fas fa-times fa-1x"
                              style={{
                                margin: 8,
                                color: '#c3c3c3',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setChangeAllLeague(false);
                                dispatchSelectedLeague({
                                  selectedLeague: VideosInfo.league,
                                  selectedLeagueFilter: VideosInfo.league,
                                });
                              }}
                            ></i>
                          </div>
                        )}
                      </div> */}
                      {/* All league dropdown end*/}

                      {/* Tagged Sport  */}

                      {!changeSports && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            fontWeight: '500',
                          }}
                        >
                          Tagged Sports:
                          <i
                            className="fas fa-edit fa-1x"
                            style={{
                              marginLeft: '10px',
                              marginRight: '5px',
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeSports(true);
                              dispatchSelectedSports({
                                selectedSports:
                                  VideosInfo.sports && VideosInfo.sports._id
                                    ? VideosInfo.sports._id
                                    : 'All',
                                selectedSportsFilter:
                                  VideosInfo.sports && VideosInfo.sports._id
                                    ? VideosInfo.sports._id
                                    : 'All',
                              });
                            }}
                          ></i>
                          {taggedSports}
                        </div>
                      )}
                      {changeSports && (
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <SportsDropdown onChange={onSportsSelected} />
                          <i
                            className="fas fa-times fa-1x"
                            style={{
                              margin: 8,
                              color: '#c3c3c3',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setChangeSports(false);
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px" }} >
                      <label
                        style={{
                          marginRight: '10px',
                          marginTop: '8px',
                          fontWeight: 600,
                        }}
                      >
                         Title*:
                      </label>
                      <input style={{ marginLeft: 0, borderRadius: "8px" ,width:"600px"}} name="title" placeholder='Title' value={title} type="text" label="title" onChange={(e) => {
                        const inputValue = e.target.value;
                        // const formattedValue = inputValue.replace(
                        //   /[^A-Za-z0-9 &|:;,\.!?'"“()]/g,
                        //   ""
                        // );
                        setTitle(inputValue);
                      }} />
                      <span> {`Characters :${title.length}`}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px" }} >
                      <label
                        style={{
                          marginRight: '10px',
                          marginTop: '8px',
                          fontWeight: 600,
                        }}
                      >
                         Description *:
                      </label>
                      <input style={{ marginLeft: 0, borderRadius: "8px" ,width:"600px"}} name="description" value={description} placeholder='Description' type="text" label="description" onChange={(e) => {
                        const inputValue = e.target.value;
                        // const formattedValue = inputValue.replace(
                        //   /[^A-Za-z0-9 &|:;,\.!?'"“()]/g,
                        //   ""
                        // );
                        setDescription(inputValue);
                      }} />
                      <span> {`Characters :${description.length}`}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px" }} >
                      <label
                        style={{
                          marginRight: '10px',
                          marginTop: '8px',
                          fontWeight: 600,
                        }}
                      >
                         SEO Title*:
                      </label>
                      <input style={{ marginLeft: 0, borderRadius: "8px" ,width:"600px"}} name="seoTitle" placeholder='SEO Title' value={seoTitle} type="text" label="seoTitle" onChange={(e) => {
                        // const inputValue = e.target.value;
                        // const formattedValue = inputValue.replace(
                        //   /[^A-Za-z0-9 &|:;/,\.!?'"“()]/g,
                        //   ""
                        // );
                        setSeoTitle(e.target.value);
                      }} />
                      <span> {`Characters :${seoTitle.length}`}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "20px", color: invalidSeoSlug ? "red" : "", }} >
                      <label
                        style={{
                          marginRight: '10px',
                          marginTop: '8px',
                          fontWeight: 600,
                        }}
                      >
                        Slug:
                      </label>
                      <input style={{
                        marginLeft: 0, borderRadius: "8px", borderColor: invalidSeoSlug ? "red" : "", width:"600px",
                        color: invalidSeoSlug ? "red" : "",
                      }} name="seoSlug" placeholder='Slug' value={seoSlug} type="text" label="seoSlug" onChange={(e) => {
                        const inputValue = e.target.value;
                        
                        setSeoSlug(inputValue);
                      }}
                        onInput={(e) => setInvalidSeoSlug(false)}
                      />
                      {invalidSeoSlug && (
                        <span
                          style={{ fontSize: "12px", color: "#ff0000" }}
                        >{`(*SEO Slug must contain character in between a-z, A-Z, 0-9 or -. hindi)`}</span>
                      )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "30px" }} >
                      <label
                        style={{
                          marginRight: '10px',
                          marginTop: '8px',
                          fontWeight: 600,
                        }}
                      >
                         SEO Description *:
                      </label>
                      <input style={{ marginLeft: 0, borderRadius: "8px" ,width:"600px"}} name="seoDescription" placeholder='SEO description' type="text" value={seoDescription} label="seoDescription" onChange={(e) => {
                        const inputValue = e.target.value;
                        
                        setSeoDescription(inputValue);
                      }} />
                      <span> {`Characters :${seoDescription.length}`}</span>
                    </div>
                    <button onClick={()=>updateInfoCall()} style={{color: "white",borderRadius: "10px",padding: "8px",background: "#4bb84b"}}>Update Info</button>
                    {/* <hr className="my-4 md:min-w-full" /> */}

                    <hr className="my-4 md:min-w-full" />
                    <div style={{ display: 'flex' }}>
                      <div>
                        <div style={{ fontWeight: '500' }}>Published At:</div>
                        {_dateTimeFormat(
                          VideosInfo.date,
                          'ddd, DD MMM hh:mm A'
                        )}
                      </div>

                      <div style={{ marginLeft: 100 }}>
                        <div style={{ fontWeight: '500' }}>Videos Type:</div>
                        <div>{VideosInfo.copyright}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-0 md:min-w-full" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leagues: state.leaguesReducer.leagues,
    sports: state.sportsReducer.sports,
    selectedAllLeague: state.leaguesReducer.selectedAllLeague,
  };
};
export default connect(mapStateToProps)(ShortsVideosInfo);
