import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Selects from 'react-select';
import {
  updateQuizHomeTag,
  getQuizDetail,
  getUpdateQuizVideo,
  updateQuizVideoThumbnail,
} from '../../../handlers/quiz.handlers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReactPlayer from 'react-player';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';
import { getUpdateQuiz, sendNotification } from '../../../handlers/quiz.handlers';
import PreviewModal from '../../../components/PreviewModal';
import { Button, Checkbox } from '@material-ui/core';
import { Get_All_S3_Videos } from '../../../handlers/videos.handlers';
import { dispatchS3VideosList } from '../../../redux/action-dispatchers/videos.dispatcher';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { _dateTimeFormat } from '../../../constants/appDefaults';
import { GetAllAuthor } from "../../../handlers/author.handlers";
import SportsDropDown from '../../../components/Dropdowns/SportsDropDown';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const QuizDetailPage = (props) => {
  const { id } = useParams();
  const [topic, setTopic] = useState('');
  const [quizInfo, setQuizInfo] = useState(undefined);
  const [editQuestions, setEditQuestions] = useState(false);
  const [editTopic, setIsEditTopic] = useState(false);
  const [searchVideo, setSearchVideo] = useState('');
  const [showMedia, setShowMedia] = useState(null);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [plays, setPlays] = useState();
  const [homeTagged, setIsHomeTagged] = useState(false);
  const [changeAuthor, setChangeAuthor] = useState(false);
  const [authorName, setAuthorName] = useState({name:"",id:""});
  const [docs, setDocs] = useState([]);
  const [liveTagged, setIsLiveTagged] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(false);
  const [selectVideo, setSelectedVideo] = useState('');
  const [notification, setNotification] = useState("");
  const [editNotification, setEditNotification] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [mediaValue, setMediaValue] = useState({});
  const [showEdit, showEditHandler] = useState(false);
  const [sportsId, setSportsId] = useState("");
  const [sportsName, setSportsName] = useState("");
  const [editSports, setEditSports] = useState(false);
  const [notificationDisable, setNotificationDisable] = useState(false);
  const [questionList, setQuestionList] = useState([
    {
      question: '',
      screenTime: 20,
      options: [{ value: '', isCorrect: false }],
    },
  ]);
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
  });
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const history = useHistory();
  let fileInput = React.useRef();
  const getQuizDetialById = () => {
    getQuizDetail(id, {
      Authorization: props.Authorization,
    }).then((response) => {
      if (response.data !== null) {
        setQuizInfo(response.data);
        setQuestionList(response.data.questions);
        setPlays(response.data.plays);
        setMediaThumbnail(response.data.videoThumbnail);
        setShowMedia(response.data.videoUrl);
        setTopic(response.data.topic);
        setIsHomeTagged(response.data.isHomeTagged);
        setIsLiveTagged(response.data?.isTaggedOnLive);
        setNotification(response.data?.notification);
        setAuthorName(response.data?.authorId);
        setSportsId(response.data?.sports?._id);
        setSportsName(response.data?.sports?.name);
        setIsNotification(response.data?.notification ? true: false);
      }
    });
  };

  useEffect(() => {
    getQuizDetialById();
  }, []);

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 1080 && width === 1920) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
          });
        } else {
          alert('Image width and height must be 1920x1080 px');
          return false;
        }
      };
    };
    reader.onerror = function (error) {};
  };

  const onPreviewClose = () => setPreviewModal({ imageData: '', show: false });
  const onPreviewSuccess = () => {
    setIsImageUploading(true);
    setQuizInfo({
      ...quizInfo,
      videoThumbnail: previewModal.imageData,
    });
    setPreviewModal({
      imageData: '',
      show: false,
    });
    const body = {
      _id: quizInfo._id,
      videoThumbnail: previewModal.imageData,
      sportsId: sportsId
    };
    updateQuizVideoThumbnail(body, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response) {
          alert('Thumbnail update successfully');
          setIsImageUploading(false);
          getQuizDetialById();
        } else {
          alert('Something went wrong');
          setIsImageUploading(false);
        }
      })
      .catch((error) => {});
  };

  // Function to input a question
  const handleQuestionChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...questionList];
    list[index][name] = value;
    setQuestionList(list);
  };
  // Function to input screen time
  const handleScreenTime = (event, index) => {
    const { name, value } = event.target;
    const numericValue = Number(value);

    if (numericValue >= 0) { // Ensure value is not negative
      const list = [...questionList];
      list[index][name] = numericValue; // Using numericValue ensures consistency
      setQuestionList(list);
    }
      
    
  };

  // Function to change is ture
  const handleOptionCheck = (event, index, optionIndex) => {
    const { name, checked } = event.target;
    const list = [...questionList];
    const optionList = [...list[index].options];
    optionList[optionIndex][name] = checked;
    list[index].options = optionList;
    setQuestionList(list);
  };
  const getAuthor = () => {
    GetAllAuthor()
      .then((response) => {
        setDocs(response.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAuthor();
  }, []);
  const onAuthorSelected = (event, value) => {
    setChangeAuthor(false);
    if (event.target.value == "" || event.target.value == undefined){
      alert("Please select author")
    }
    else{
    const body = { authorId: event.target.value, _id: quizInfo._id, sportsId: sportsId };
    quizInfo &&
      value !== 0 &&
      getUpdateQuiz(body)
        .then((response) => {
          if (response.status) {
            getQuizDetialById();
            // alert(response.message);
          } else {
            alert("Sorry your author is not updated");
          }
        })
        .catch((error) => error);
    }
  };
  //   Function to input an option
  const handleOptionChange = (event, index, optionIndex) => {
    const { name, value } = event.target;
    const list = [...questionList];
    const optionList = [...list[index].options];
    optionList[optionIndex][name] = value;
    list[index].options = optionList;
    setQuestionList(list);
  };
  // Function to add a new question
  const handleQuestionAdd = () => {
    setQuestionList([
      ...questionList,
      {
        question: '',
        screenTime: 20,
        options: [{ value: '', isCorrect: false }],
      },
    ]);
  };

  const onSportSelected = (value) => {
    if(value == "All"){
      alert("please select sports");
    }
    else{
      setEditSports(false);
      let body = {
        _id: id,
        sportsId: value,
        topic : topic,
        sportsChange:true,
      }
      getUpdateQuiz(body).then((response) => {
        if (response.status == true) {
          alert('Quiz Updated.');
          getQuizDetialById();
        }
      });
      
    }
  };
  //   Function To add an option
  const handleOptionAdd = (index) => {
    const list = [...questionList];
    const optionList = [
      ...list[index].options,
      { value: '', isCorrect: false },
    ];
    list[index].options = optionList;
    setQuestionList(list);
  };

  // Function to remove a question
  const handleQuestionRemove = (index) => {
    const list = [...questionList];
    list.splice(index, 1);
    setQuestionList(list);
  };
  // Function to remove option
  const handleOptionRemove = (index, optionIndex) => {
    const list = [...questionList];
    const optionList = [...list[index].options];
    optionList.splice(optionIndex, 1);
    list[index].options = optionList;
    setQuestionList(list);
  };
  // Function to update questions
  const handleSubmit = () => {
    const body = {
      topic: topic,
      questions: questionList,
      _id: id,
      sportsId: sportsId,
      sportsChange:true,
    };
    if (topic == "" || topic == undefined){
      alert("please add topic");
    }
    else if (questionList.length < 5 || questionList.length >= 6) {
      alert("Please add 5 question to quiz....");
      return;
    }
    else if (questionList.length == 5) {
      for (const item of questionList) {
        if (item.question === '') {
          alert("Question cannot be empty");
          return;
        }

        if (item.options.length < 2) {
          alert("Please add at least 2 options in questions");
          return;
        }

        let correctOptionCount = 0;
        let optionEmpty = false;

        for (const option of item.options) {
          if (option.isCorrect) {
            correctOptionCount++;
          }

          if (option.value === '') {
            alert("Please Fill all options");
            optionEmpty = true;
            return;
          }
        }

        // The outer loop returns if any option was empty.
        if (optionEmpty) {
          return;
        }

        if (correctOptionCount === 0) {
          alert("Please mark at least one option as correct for each question");
          return;
        }

        if (correctOptionCount > 1) {
          alert("Please mark only one option as correct for each question");
          return;
        }
      }
      
  
        getUpdateQuiz(body).then((response) => {
          if (response.status == true) {
            alert('Quiz Updated.');
            setIsEditTopic(false);
            setEditQuestions(false);
            getQuizDetialById();
          }
        });
      
    }
  };

  // video upload
  const getS3Videos = () => {
    const body = {
      page: 1,
      limit: 10,
      query: searchVideo,
    };
    Get_All_S3_Videos(body)
      .then((res) => {
        dispatchS3VideosList(res.data);
      })
      .catch((err) => err);
  };
  useEffect(() => {
    getS3Videos();
  }, []);
  const videosList = state.s3VideosReducer.docs;
  const videos = videosList?.map((video) => {
    return {
      value: video._id,
      label: video.original_file_name,
      url: 'https://' + video.cdn_hostname + video.url,
    };
  });
  const selectHomeNews = (event) => {
    setIsHomeTagged(event.target.checked);
    const body = {
      _id: id,
      isHomeTagged: event.target.checked,
      sportsId: sportsId
    };
    updateQuizHomeTag(body).then((response) => {
      if (response.status == true) {
        alert('Home tagging success');
        getQuizDetialById();
      } else {
        alert('something went wrong');
      }
    });
  };
  const notificationChange = () => {
    setNotificationDisable(true);
    const body = {
      id: id,
      notification: notification,
    };
    if (isNotification && (notification == "" || notification == undefined)){
      alert("please add notification");
      setNotificationDisable(false);
    }
    else if(!isNotification){
      alert("Please tick the box to send notification")
    }
    else{
      sendNotification(body).then((response) => {
        if (response.status == true) {
          alert(response.message);
          getQuizDetialById();
        } else {
          alert('something went wrong');
        }
        setNotificationDisable(false);
      });
    }
  };
  const handleSelect = (value) => {
    setSelectedVideo(value);
    setMediaValue(value);
    if(value == undefined || value == ""){
      alert("please select video")
    }
    else{

      const body = { transcodedVideoId: value.value, _id: id, sportsId: sportsId };
      getUpdateQuizVideo(body).then((res) => {
        if (res.status == true) {
          alert('Video Updated');
          setUploadVideo(false);
          getQuizDetialById();
        }
      });
    }
  };
  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };
  // video upload end

  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          {previewModal.show && (
            <PreviewModal
              imageData={previewModal.imageData}
              title="Upload Video"
              message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
              onSuccess={onPreviewSuccess}
              onClose={onPreviewClose}
            />
          )}
          {quizInfo && (
            <>
              <div>
                <div>
                  <div
                    className=" pb-4"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '-20px',
                      width: '30px',
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIcon />
                  </div>
                  <div className="quizDetial-topic">
                    <p className="quizDetail-topic">Quiz Topic: </p>
                    {editTopic ? (
                      <i
                        onClick={() => setIsEditTopic(false)}
                        className="far fa-times-circle secondary btn quizDetail-topic-editButtonCancel"
                        style={{
                          cursor: 'pointer',
                        }}
                      ></i>
                    ) : (
                      <i
                        onClick={() => setIsEditTopic(true)}
                        className="far fa-edit px-3 quizDetail-topic-editButton"
                      ></i>
                    )}
                    {editTopic ? (
                      <div className="quizDetil-edit-topic">
                        <input
                          className="quizDetail-input"
                          type="text"
                          name="topic"
                          value={topic}
                          onChange={(event) => setTopic(event.target.value)}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          Update
                        </Button>
                      </div>
                    ) : (
                      <span>{topic}</span>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                    }}
                  >
                    <ReactPlayer
                      style={{ marginLeft: '45px' }}
                      controls
                      url={showMedia}
                      light={mediaThumbnail}
                      width={450}
                      height={240}
                    />
                    {isImageUploading ? (
                      <div style={{ textAlign: 'center', padding: 10 }}>
                        <div>Uploading Image Please Wait...</div>
                        <div>
                          <PulseLoader
                            color="#23A5E0"
                            loading={true}
                            css={override}
                            size={10}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div></div>
                        <div>
                          <input
                            ref={fileInput}
                            type="file"
                            multiple={false}
                            onChange={onFileSelect}
                            accept="image/x-png,image/jpeg"
                            style={{
                              backgroundColor: '#000',
                              height: 35,
                              width: 40,
                              position: 'absolute',
                              opacity: 0,
                              cursor: 'pointer',
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                              margin: 8,
                              color: '#555',
                              cursor: 'pointer',
                            }}
                          ></i>
                          Please upload image in the dimension of 1920x1080
                        </div>
                        <hr className="my-4 md:min-w-full" />
                        {/* Add Video */}
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '700',
                          }}
                        >
                          <i
                            onClick={() => setUploadVideo(true)}
                            className="fas fa-video fa-2x"
                            style={{
                              margin: 8,
                              color: '#555',
                              cursor: 'pointer',
                            }}
                          ></i>
                          {uploadVideo ? (
                            <>
                              <i
                                onClick={() => setUploadVideo(false)}
                                className="far fa-times-circle secondary btn quizDetail-topic-editButtonCancel"
                                style={{
                                  cursor: 'pointer',
                                  margin: '0',
                                }}
                              ></i>

                              <div
                                className="addQuiz-search"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginLeft: '50px',
                                }}
                              >
                                <input
                                  style={{ marginLeft: '13px', width: '500px' }}
                                  type="text"
                                  className="addQuiz-video"
                                  name="searchVideoVal"
                                  placeholder="Search Videos"
                                  onChange={(e) => {
                                    setSearchVideo(e.target.value);
                                  }}
                                />

                                <button
                                  type="button"
                                  onClick={() => {
                                    getS3Videos();
                                  }}
                                  style={{
                                    width: '100px',
                                    alignSelf: 'center',
                                    marginTop: '7px',
                                    padding: '10px  0px',
                                  }}
                                  className={`bg-${'emerald'}-500 text-white active:bg-${'emerald'}-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                                >
                                  Search
                                </button>

                                <>
                                  <div className="col-lg-8">
                                    {/* <label
                                    style={{
                                      fontWeight: 600,
                                      marginLeft: '12px',
                                    }}
                                  >
                                    Select Video
                                  </label> */}
                                    <Selects
                                      name="videoId"
                                      className="quizDetail-select"
                                      placeholder="Select Video"
                                      options={videos}
                                      onChange={(values) => {
                                        handleSelect(values);
                                      }}
                                      value={
                                        videos
                                          ? videos.find(
                                              (option) =>
                                                option.value ===
                                                selectVideo.value
                                            )
                                          : ''
                                      }
                                    ></Selects>
                                    {selectVideo && (
                                      <div
                                        style={{
                                          display: 'flex',

                                          alignItems: 'center',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Click the icon for preview
                                        <i
                                          onClick={() =>
                                            onClickOpenMedia(selectVideo.url)
                                          }
                                          className="fas fa-video "
                                          style={{
                                            margin: 8,
                                            color: '#555',
                                            cursor: 'pointer',
                                            fontSize: '1.5rem',
                                          }}
                                        ></i>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    className="col-lg-1"
                                    style={{
                                      margin: 'auto',
                                      marginTop: '26px',
                                    }}
                                  >
                                    <a
                                      title="Media File"
                                      className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                                      onClick={() => {}}
                                    ></a>
                                  </div>
                                </>
                              </div>
                              {/* Upload video end */}
                            </>
                          ) : (
                            <p>Click on video icon to change video</p>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <hr className="my-4 md:min-w-full" />
                  <div>
                    <div
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      <div style={{display:"flex"}}>
                        <span style={{fontWeight:"700",marginRight:"10px"}}>Sports: </span>
                        
                        {editSports ? (
                          <div >
                           
                            <SportsDropDown onChange={onSportSelected} />
                            <i
                              onClick={() => setEditSports(false)}
                              className="far fa-times-circle secondary btn quizDetail-topic-editButtonCancel"
                              style={{
                                cursor: 'pointer',
                                marginLeft: '5px',
                              }}
                            ></i>
                            </div>
                        ) : (
                          <div style={{display:"flex",marginLeft:"10px"}}>
                            <div >{sportsName}</div>
                          <i
                            onClick={() => setEditSports(true)}
                            className="far fa-edit px-3 quizDetail-topic-editButton"
                            style={{ margin: '0' }}
                            ></i>
                            </div>
                        )}

                      </div>
                    </div>
                  </div>
                  <hr className="my-4 md:min-w-full" />
                  <p className="quizDetail-plays">
                    Total Plays: <span>{plays} Times</span>
                  </p>
                  <hr className="my-4 md:min-w-full" />
                  <div
                    style={{
                      fontWeight: '700',
                    }}
                  >
                    <div style={{display:"flex",alignItems:"center"}}>
                    Home Tag:

                    <Checkbox
                      checked={homeTagged}
                      color="primary"
                      disabled={liveTagged}
                      onChange={selectHomeNews}
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                    {liveTagged ? <div style={{ color: "red", marginLeft: "20px", fontSize: "13px" }}>*Tagging on Home is not allowed if Tagged on Live</div> : null}
                    </div>

                  </div>
                  <hr className="my-4 md:min-w-full" />
                  <div>
                    <div
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Notification : 
                      <input type="checkbox" checked={isNotification} onChange={(e) => { setIsNotification(e.target.checked); }} />
                      <label style={{ color: "red", marginLeft: "10px", fontSize: "10px" }}>(Please select Checkbox to trigger Notification)</label>
                   
                      {isNotification ? <div style={{ marginTop: "10px" }}>
                        <input type="text" name="notification" id="textbox" value={notification} onChange={(e)=>{setNotification(e.target.value)}} placeholder='notification' style={{ width: "500px" }} />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop:"10px"
                          }}
                        >
                          <Button
                            variant="contained"
                            color={`${ notificationDisable ? 'default' : 'primary'}`}
                            onClick={notificationChange}
                            disabled= {notificationDisable}

                          >
                            Send
                          </Button>
                        </div>
                      </div> : <div>
                        <label>{notification}</label>
                        </div>}
                    </div>
                  </div>
                  <hr className="my-4 md:min-w-full" />
                  <div
                    style={{
                      fontWeight: '700',
                      display:'flex',
                      alignItems:'center'
                    }}
                  >
                    Tag On Live:
                    <div style={{display:'flex',flex:"column",alignItems:"center"}}>

                    <Checkbox
                      checked={liveTagged}
                      disabled={true}
                      color="primary"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                    {true ? <div style={{ color: "red", marginLeft: "20px", fontSize: "13px" }}>*Tagging on Live is not allowed in edit</div> : null}
                    </div>

                  </div>
                  <div>
                    <div>
                        Start Date:{` `}{_dateTimeFormat(
                            quizInfo?.startDate,
                            'ddd, DD MMM hh:mm A'
                          )}
                    </div>
                    <div>
                      Duration:{` `}{quizInfo?.duration} Seconds
                    </div>
                  </div>
                  <hr className="my-4 md:min-w-full" />
                  <div className="quizDetail-quistions">
                    <div style={{ fontWeight: '700', marginTop: '20px' }}>
                      Questions:{' '}
                      {editQuestions ? (
                        <i
                          onClick={() => setEditQuestions(false)}
                          className="far fa-times-circle secondary btn quizDetail-topic-editButtonCancel"
                          style={{
                            cursor: 'pointer',
                            margin: '0',
                          }}
                        ></i>
                      ) : (
                        <i
                          onClick={() => setEditQuestions(true)}
                          className="far fa-edit px-3 quizDetail-topic-editButton"
                          style={{ margin: '0' }}
                        ></i>
                      )}
                    </div>
                    {editQuestions ? (
                      <div
                        className="question-container"
                        style={{ margin: '0' }}
                      >
                        {questionList.map((ques, index) => (
                          <div className="questions" style={{ width: '100%' }}>
                            <div key={index} className="first-div">
                              <div>
                                <span>{`Ques ${index + 1}: `}</span>
                                <span style={{ marginRight: '5px' }}>
                                  Screen Time(in sec.)*
                                </span>
                                <input
                                  className="addQuiz-input difficulty-input"
                                  type="number"
                                  name="screenTime"
                                  id="screenTime"
                                  value={ques.screenTime}
                                  disabled={true}
                                />
                              </div>
                              <input
                                style={{ marginTop: '10px', width: '600px' }}
                                className="quizDetail-input"
                                type="text"
                                id="question"
                                name="question"
                                value={ques.question}
                                onChange={(event) => {
                                  handleQuestionChange(event, index);
                                }}
                                required
                              />
                              {ques.options.map((opt, optionIndex) => (
                                <div
                                  className="options"
                                  style={{ width: '60%' }}
                                >
                                  <div key={optionIndex} className="first-div">
                                    <div className="options-isTrue">
                                      <span>{`Option ${
                                        optionIndex + 1
                                      }: `}</span>
                                      <span>Is Correct?</span>
                                      <Checkbox
                                        checked={opt.isCorrect}
                                        name="isCorrect"
                                        onChange={(event) => {
                                          handleOptionCheck(
                                            event,
                                            index,
                                            optionIndex
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="options">
                                      <input
                                        className="addQuiz-input"
                                        type="text"
                                        id="value"
                                        name="value"
                                        value={opt.value}
                                        onChange={(event) =>
                                          handleOptionChange(
                                            event,
                                            index,
                                            optionIndex
                                          )
                                        }
                                      />
                                    </div>
                                    {ques.options.length - 1 ===
                                      optionIndex && (
                                      <Button
                                        className="add-btn"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleOptionAdd(index)}
                                      >
                                        Add Option
                                      </Button>
                                    )}
                                  </div>
                                  <div className="second-div">
                                    {ques.options.length > 1 && (
                                      <Button
                                        className="remove-btn-opt"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() =>
                                          handleOptionRemove(index, optionIndex)
                                        }
                                      >
                                        Remove Opt.
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              ))}
                              {questionList.length - 1 === index &&
                                questionList.length < 10 && (
                                  <Button
                                    className="add-btn"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleQuestionAdd}
                                  >
                                    Add Ques
                                  </Button>
                                )}
                            </div>
                            <div className="second-div" style={{ marginLeft: "10px", marginTop: "4px" }}>
                              {questionList.length > 1 && (
                                <Button
                                  className="remove-btn"
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleQuestionRemove(index)}
                                >
                                  Remove
                                </Button>
                              )}
                            </div>
                          </div>
                        ))}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    ) : (
                      questionList &&
                      questionList.map((quest, index) => (
                        <>
                          <p className="quizDetails-question">
                            {`Question ${index + 1}:`}
                            <span>{quest.question}</span>
                          </p>
                          {quest.options.map((option, optionIndex) => (
                            <>
                              <p
                                className={`${
                                  option.isCorrect == true
                                    ? 'quizDetails-correctOptions'
                                    : 'quizDetails-options'
                                }  `}
                              >
                                {` ${optionIndex + 1}.`}
                                <span> {option.value}</span>
                              </p>
                            </>
                          ))}
                        </>
                      ))
                    )}
                  </div>
                  <hr className="my-4 md:min-w-full" />
                  { !changeAuthor && (
                    <div
                      style={{
                        
                        marginTop: 5,
                        marginBottom: 10,
                        fontWeight: "500",
                      }}
                    >
                      <div>
                        Tagged Author:{" "}
                        <i
                          className="far fa-edit px-3 quizDetail-topic-editButton"
                          style={{
                            marginLeft: "10px",
                            marginRight: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setChangeAuthor(true);
                          }}
                        ></i>
                        {authorName?.name}
                      </div>
                    </div>
                  )}

                  {changeAuthor && (
                    <>
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 10,
                          fontWeight: "500",
                          marginRight: "80px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // width: '300px',
                        }}
                      >
                        Tagged Author:{" "}
                        <select
                          style={{
                            // marginLeft: 20,
                            border: "2px solid #acb9ce",
                            borderRadius: 10,
                          }}
                          value={authorName?.name}
                          onChange={onAuthorSelected}
                        >
                          <option value="">Select Author</option>
                          {docs?.map((author) => (
                            <>
                              <option
                                selectedValue={author && author._id}
                                value={author._id}
                              >
                                {author.name}
                              </option>
                            </>
                          ))}
                        </select>
                        {changeAuthor ? (
                          <i
                            onClick={() => setChangeAuthor(false)}
                            className="far fa-times-circle secondary btn quizDetail-topic-editButtonCancel"
                            style={{
                              cursor: 'pointer',
                            }}
                          ></i>
                        ) : (
                          <i
                            onClick={() => setChangeAuthor(true)}
                              style={{ color: "none"}}
                              className="far fa-edit px-3 quizDetail-topic-editButton"
                          ></i>
                        )}
                      </div>
                    </>
                  )}
                  <hr className="my-4 md:min-w-full" />

                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default QuizDetailPage;
