import React, { useState, useEffect, useRef } from 'react';
import {
  GetAllAuthorList,
  UpdateAuthor,
  AddAuthor,
  DeleteAuthor,
} from '../../../handlers/author.handlers';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
import ConfirmationModal from '../../../components/ConfirmationModal';
import { _getDateTime, _dateTimeFormat } from '../../../constants/appDefaults';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const ManageAuthor = () => {
  const [docs, setDocs] = useState([]);
  // Edit author
  const [showEdit, showEditHandler] = useState(false);
  const [ isLoadingMore, setIsLoadingMore] = useState(false);
  const [authorName, setAuthorName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editTwitter, setEditTwitter] = useState('');
  const [editFacebook, setEditFacebook] = useState('');
  const [editInstagram, setEditInstagram] = useState('');
  const [editTwitterHandle, setEditTwitterHandle] = useState('');
  const [editRedirectionUrl, setEditRedirectionUrl] = useState('');

  const [editSelectedOption, setEditSelectedOption] = useState(false);
  const [editUpdateActiveStatus, setEditUpdateActiveStatus] = useState('');
  const [editAvatar, setEditAvatar] = useState('');
  const [editAvatarUrl, setEditAvatarUrl] = useState('');
  const [editBio, setEditBio] = useState('');
  // Add Author
  const [showAdd, showAddHandler] = useState(false);
  const [addAuthorName, setAddAuthorName] = useState('');
  const [email, setEmail] = useState('');
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [authorID, setAuthorID] = useState();
  const [selectedOption, setSelectedOption] = useState(false);
  const [disable, setDisable] = useState(false);
  const [updateActiveStatus, setUpdateActiveStatus] = useState('ACTIVE');
  const [avatar, setAvatar] = useState('');
  const [redirectionUrl, setRedirectionUrl] = useState('');

  const [bio, setBio] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });

  const mHistory = useHistory();
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: '',
  });
  let fileInput = React.useRef();
  let editfileInput = React.useRef();
  const color = 'light';

  const getAuthor = () => {
    GetAllAuthorList()
      .then((response) => {
        
        setDocs(response?.data && response?.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAuthor();
  }, []);
  const OnClickEditAuthor = (
    id,
    avatar,
    name,
    email,
    redirectionUrl,
    twitterHandle,
    facebook,
    instagram,
    twitter,
    bio,
    status
  ) => {
    setAuthorID(id);
    setEditAvatarUrl(avatar);
    setAuthorName(name);
    setEditEmail(email);
    setEditRedirectionUrl(redirectionUrl)
    setEditTwitterHandle(twitterHandle);
    setEditFacebook(facebook);
    setEditInstagram(instagram);
    setEditTwitter(twitter);
    setEditBio(bio);
    setEditUpdateActiveStatus(status);

    showEditHandler(true);
  };

  const OnClickAddAuthor = () => {
    showAddHandler(true);
  };
  const handleChange = () => {
    if(!isValidEmail(editEmail)){
      alert("Please enter a Valid Email Address")
    }
    else if(!isValidTwitterUrl(editTwitter)&&editTwitter.length!==0){
      alert("Please enter a Valid Twitter URL")
    }
    else if(!isValidInstagramUrl(editInstagram)&&editInstagram.length!==0){
      alert("Please enter a Valid Instagram URL")
    }
    else if(!isValidFacebookUrl(editFacebook)&&editFacebook.length!==0){
      alert("Please enter a Valid Facebook URL")
    }
    else{
      setIsLoadingMore(true)
      const body = {
        name: authorName,
        _id: authorID,
        avatar: editAvatar,
        avatarUrl: editAvatarUrl !== '' ? avatar : editAvatarUrl,
        email: editEmail,
        redirectionUrl: editRedirectionUrl,
        twitterHandle: editTwitterHandle,
        social: {
          facebook: editFacebook,
          twitter: editTwitter,
          instagram: editInstagram,
        },
        bio: editBio,
        status: editUpdateActiveStatus,
      };
      UpdateAuthor(body).then((response) => {
  
        if (response?.data?.message == 'success') {
          showEditHandler(false);
          alert('Author updated successfully');
          getAuthor();
        } else {
          alert(response?.data?.message);
        }
        window.location.reload(false);
        setIsLoadingMore(false);
      });
    }
  };
  const handleAdd = () => {
    if (avatar == '') {
      alert('Please select profile picture.');
    } else if (addAuthorName == '') {
      alert('Author name can not be empty.');
    } else if (email == '') {
      alert('Email can not be empty.');
    } else if (updateActiveStatus == '') {
      alert('Please Select Active.');
    } else if(!isValidEmail(email)){
      alert("Please enter a Valid Email Address")
    }
    else if(!isValidTwitterUrl(twitter)&&twitter.length!==0){
      alert("Please enter a Valid Twitter")
    }
    else if(!isValidInstagramUrl(instagram)&&instagram.length!==0){
      alert("Please enter a Valid Twitter")
    }
    
    else if(!isValidFacebookUrl(facebook)&&facebook.length!==0){
      alert("Please enter a Valid Twitter")
    }

    else {
      setIsLoadingMore(true)
      const body = {
        name: addAuthorName,
        avatar: avatar,
        avatarUrl: editAvatarUrl !== '' ? '' : editAvatarUrl,
        email: email,
        redirectionUrl: redirectionUrl,
        twitterHandle: twitterHandle,
        social: {
          facebook: facebook,
          twitter: twitter,
          instagram: instagram,
        },
        bio: bio,
        status: updateActiveStatus,
      };
      setDisable(true)

      AddAuthor(body)
        .then((response) => {
          setIsLoadingMore(false)
          getAuthor();
          if (response?.message ) {
            alert('Author added successfully');
            showAddHandler(false);
            getAuthor();
            setAvatar('');
            setAddAuthorName('');
            setEmail('');
            setTwitter('');
            setFacebook('');
            setInstagram('');
            setTwitterHandle('');
            window.location.reload(false);
          } else {
            alert(response?.message[0]?.msg);
          }
          window.location.reload(false);
          
        })
        .catch((err) => {
          console.log('err', err);
          setIsLoadingMore(false)
        });
      setDisable(false);
    }
  };
  const showModalForPublish = (id) => {
    let title = 'Delete Author';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };

  const onFileSelect = (event) => {
    var file = event?.target?.files?.[0];
    setAvatar(file);
    // setThumbName(file.name);
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader?.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        // if (height === 960 && width === 540) {
        setPreviewModal({
          imageData: reader?.result,
          show: true,
        });
        setAvatar(reader?.result);
        // } else {
        //   alert('Image width and height must be 960x540 px');
        //   return false;
        // }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) {};
  };
  const onEditFileSelect = (event) => {
    var file = event?.target?.files?.[0];
    setEditAvatar(file);
    // setThumbName(file.name);
    event.target.value = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader?.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        // if (height === 960 && width === 540) {
        setPreviewModal({
          imageData: reader?.result,
          show: true,
        });
        setEditAvatar(reader?.result);
        // } else {
        //   alert('Image width and height must be 960x540 px');
        //   return false;
        // }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) {};
  };
  const onView = (authorId) => {
    mHistory.push(`/manageAuthor/${authorId}`);
  };

  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    setIsLoadingMore(true)
    DeleteAuthor(authorID)
      .then((res) => {
        if (res.status) {
          alert('Author Deleted Successfully');
          getAuthor();
        } else {
          alert('Something went wrong');
        }
        setIsLoadingMore(false);
      })
      .catch((error) => error);
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const isValidEmail = (email) =>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  function isValidTwitterUrl(url) {
    const twitterUrlRegex =/^(https?:\/\/)?(www\.)?(twitter|x|X)\.com\/(\w+)(?:\/\w+)*(?:\?.*)?$/;
    return twitterUrlRegex.test(url);
  }

  function isValidInstagramUrl(url) {
    const instagramUrlRegex =/^(https?:\/\/)?(www\.)?instagram\.com\/(?:[a-zA-Z0-9_.-]+)\/?$/;
  
    return instagramUrlRegex.test(url);
  }

  function isValidFacebookUrl(url) {
    const facebookUrlRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/(?:[a-zA-Z0-9_.-]+)\/?$/;
  
    return facebookUrlRegex.test(url);
  }


  return (
    <>
      {/*Edit Author  */}
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '100%' }}
        >
          <div className="edit__popup-inner">
            {/* <div style={{ fontWeight: 'bold' }}> Author Name- {authorName}</div> */}
            <i
              onClick={() => {
                showEditHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* 1st box */}
              <div style={{ width: '50%' }}>
                <div className="edit__popup-row ">
                  *Avatar:
                  {editAvatar ? (
                    <img
                      src={editAvatar}
                      style={{
                        width: '50px',
                        height: '50px',
                        marginLeft: '22px',
                        borderRadius: '50px',
                      }}
                    />
                  ) : (
                    <img
                      src={editAvatarUrl}
                      style={{
                        width: '50px',
                        height: '50px',
                        marginLeft: '22px',
                        borderRadius: '50px',
                      }}
                    />
                  )}
                  <div>
                    <input
                      ref={editfileInput}
                      type="file"
                      name="avatar"
                      onChange={(event) => onEditFileSelect(event)}
                      style={{
                        backgroundColor: '#000',
                        height: 35,
                        width: 40,
                        position: 'absolute',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                    />
                    <i
                      className="fas fa-camera"
                      style={{
                        margin: 8,
                        color: '#555',
                        cursor: 'pointer',
                        fontSize: '24px',
                        paddingTop: '0',
                      }}
                    ></i>
                  </div>
                </div>
                <div className="edit__popup-row ">
                  *Name:{' '}
                  <input
                    type="text"
                    //   name=
                    value={authorName}
                    onChange={(e) => {
                      setAuthorName(e.target.value);
                    }}
                    placeholder="Author Name"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '26px',
                      marginRight: '4%',
                    }}
                  />
                </div>
                <div className="edit__popup-row ">
                  *Email:
                  <input
                    type="email"
                    //   name=
                    value={editEmail}
                    onChange={(e) => {
                      setEditEmail(e.target.value);
                    }}
                    placeholder="Email ID"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '32px',
                      marginRight: '4%',
                    }}
                  />
                </div>
                
                {/*Redirection Url*/}
                <div className="edit__popup-row ">
                  Redirection Url
                  <input
                    type="text"
                    //   name=
                    value={editRedirectionUrl}
                    onChange={(e) => {
                      setEditRedirectionUrl(e?.target?.value);
                    }}
                    placeholder="@JsonKey(name: 'avatarUrl')"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '2px',
                      marginRight: '4%',
                    }}
                  />
                </div>

                <div className="edit__popup-row ">
                  Twitter Handle:
                  <input
                    type="text"
                    //   name=
                    value={editTwitterHandle}
                    onChange={(e) => {
                      setEditTwitterHandle(e.target.value);
                    }}
                    placeholder="Twitter Handle eg: @alex"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '2px',
                      marginRight: '4%',
                    }}
                  />
                </div>
                


              </div>
              {/* 1st box end */}

              {/* 2nd box */}
              <div style={{ width: '50%' }}>
                <div className="edit__popup-row " style={{ marginTop: '10%' }}>
                  facebook:{' '}
                  <input
                    type="email"
                    //   name=
                    value={editFacebook}
                    onChange={(e) => {
                      setEditFacebook(e.target.value);
                    }}
                    placeholder="https://www.facebook.com/search/top?q=sports%20tak"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '12px',
                      marginRight: '4%',
                    }}
                  />
                </div>
                <div className="edit__popup-row">
                  Instagram:{' '}
                  <input
                    type="email"
                    //   name=
                    value={editInstagram}
                    onChange={(e) => {
                      setEditInstagram(e.target.value);
                    }}
                    placeholder="https://www.instagram.com/sportstakofficial/"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '7px',
                      marginRight: '4%',
                    }}
                  />
                </div>

                <div className="edit__popup-row ">
                  Twitter:{' '}
                  <input
                    type="email"
                    //   name=
                    value={editTwitter}
                    onChange={(e) => {
                      setEditTwitter(e.target.value);
                    }}
                    placeholder="https://twitter.com/sports_tak"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '30px',
                      marginRight: '4%',
                    }}
                  />
                </div>

                {/* dropdown */}
                <div className="edit__popup-row">
                  Active:
                  <select
                    style={{
                      width: '30%',
                      height: '40px',
                      // margin: '1.2%',
                      marginLeft: '49px',
                      
                    }}
                    value={editUpdateActiveStatus}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    onChange={(e) => setEditUpdateActiveStatus(e.target.value)}
                  >
                    <option
                      selected={
                        editSelectedOption == editUpdateActiveStatus
                          ? 'selected'
                          : ''
                      }
                      value={'ACTIVE'}
                    >
                      ACTIVE
                    </option>
                    <option
                      selected={
                        editSelectedOption == editUpdateActiveStatus
                          ? 'selected'
                          : ''
                      }
                      value={'DELETED'}
                    >
                      INACTIVE
                    </option>
                  </select>
                </div>

                {/* dropdown end */}
              </div>
            </div>
            <div className="edit__popup-row ">
              Biodata:{' '}
              <textarea
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                name="bio"
                rows="4"
                cols="80"
                id="textbox"
                value={editBio}
                onChange={(e) => setEditBio(e.target.value)}
                style={{
                  width: '100%',
                  // height: '33px',
                  marginLeft: '10px',

                  border: 'none',
                }}
                placeholder="Biodata"
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => !isLoadingMore&&handleChange()}
                style={{ marginBottom: '1.5%' }}
              >
                Save
              </Button>
            </div>
          </div>
          <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
      {/* Edit Author end */}
      {/* Add author */}
      <div className="edit__popup">
        <div
          onClick={() => showAddHandler(false)}
          className={showAdd ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showAdd ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '65%' }}
        >
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                showAddHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* 1st box */}
              <div style={{ width: '50%' }}>
                <div className="edit__popup-row ">
                  *Avatar:
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      marginLeft: '22px',
                      borderRadius: '50px',
                    }}
                  />
                  <div>
                    <input
                      ref={fileInput}
                      type="file"
                      name="avatar"
                      onChange={(event) => onFileSelect(event)}
                      style={{
                        backgroundColor: '#000',
                        height: 35,
                        width: 40,
                        position: 'absolute',
                        opacity: 0,
                        cursor: 'pointer',
                        marginTop: '10px',
                        marginLeft: '10px',
                      }}
                    />
                    <i
                      className="fas fa-camera"
                      style={{
                        color: '#555',
                        cursor: 'pointer',
                        fontSize: '24px',
                        marginTop: '30px',
                        marginLeft: '10px',
                      }}
                    ></i>
                  </div>
                </div>
                <div className="edit__popup-row ">
                  *Name:{' '}
                  <input
                    type="text"
                    //   name=
                    value={addAuthorName}
                    onChange={(e) => {
                      setAddAuthorName(e.target.value);
                    }}
                    placeholder="Author Name"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '26px',
                      marginRight: '4%',
                    }}
                  />
                </div>
                <div className="edit__popup-row ">
                  *Email:
                  <input
                    type="email"
                    //   name=
                    pattern="[^ @]*@[^ @]*"
                    value={email}
                    onChange={(e) => {

                      setEmail(e.target.value);
                    }}
                    placeholder="Email ID"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '32px',
                      marginRight: '4%',
                    }}
                  />
                </div>
                
                {/*Redirection Url*/}
                <div className="edit__popup-row ">
                  Redirection Url
                  <input
                    type="text"
                    //   name=
                    value={redirectionUrl}
                    onChange={(e) => {
                      setRedirectionUrl(e?.target?.value);
                    }}
                    placeholder="@JsonKey(name: 'avatarUrl')"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '2px',
                      marginRight: '4%',
                    }}
                  />
                </div>

                <div className="edit__popup-row ">
                  Twitter Handle:
                  <input
                    type="text"
                    //   name=
                    value={twitterHandle}
                    onChange={(e) => {
                      setTwitterHandle(e.target.value);
                    }}
                    placeholder="Twitter Handle eg: @sports_tak"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '2px',
                      marginRight: '4%',
                    }}
                  />
                </div>
              </div>
              {/* 1st box end*/}

              {/* 2nd box */}
              <div style={{ width: '50%' }}>
                <div className="edit__popup-row " style={{ marginTop: '10%' }}>
                  facebook:{' '}
                  <input
                    type="text"
                    //   name=
                    value={facebook}
                    onChange={(e) => {
                      setFacebook(e.target.value);
                    }}
                    placeholder="https://www.facebook.com/search/top?q=sports%20tak"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '12px',
                      marginRight: '0',
                    }}
                  />
                </div>
                <div className="edit__popup-row ">
                  Instagram:{' '}
                  <input
                    type="text"
                    //   name=
                    value={instagram}
                    onChange={(e) => {
                      setInstagram(e.target.value);
                    }}
                    placeholder="https://www.instagram.com/sportstakofficial/"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '7px',
                      marginRight: '0',
                    }}
                  />
                </div>
                <div className="edit__popup-row ">
                  Twitter:{' '}
                  <input
                    type="text"
                    //   name=
                    value={twitter}
                    onChange={(e) => {
                      setTwitter(e.target.value);
                    }}
                    placeholder="https://twitter.com/sports_tak"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    style={{
                      width: '100%',
                      height: '33px',
                      marginLeft: '30px',
                      marginRight: '4%',
                    }}
                  />
                </div>

                {/* dropdown */}
                <div className="edit__popup-row">
                  Active:
                  <select
                    style={{
                      width: '30%',
                      height: '40px',
                      // margin: '1.2%',
                      marginLeft: '49px',
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                    onChange={(e) => setUpdateActiveStatus(e.target.value)}
                  >
                    <option
                      selected={
                        selectedOption == updateActiveStatus ? 'selected' : ''
                      }
                      value={'ACTIVE'}
                    >
                      ACTIVE
                    </option>
                    <option
                      selected={
                        selectedOption == updateActiveStatus ? 'selected' : ''
                      }
                      value={'DELETED'}
                    >
                      INACTIVE
                    </option>
                  </select>
                </div>

                {/* dropdown end */}
              </div>
            </div>
            <div className="edit__popup-row ">
              Description:{' '}
              <textarea
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                name="bio"
                rows="2"
                cols="80"
                id="textbox"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                style={{
                  width: '100%',
                  // height: '33px',
                  marginLeft: '10px',

                  border: 'none',
                }}
                placeholder="Biodata"
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Button
                disbaled={disable}
                variant="contained"
                color="primary"
                onClick={() => !isLoadingMore&&handleAdd()}
                style={{ marginBottom: '1.5%' }}
              >
                Save
              </Button>
            </div>
          </div>
          <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
      {/* Add Author End */}
      <Sidebar />

      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Manage Author
                    </h3>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => OnClickAddAuthor()}
                  >
                    Add Author
                  </Button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <div>
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Avatar
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Author
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Email
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Status
                        </th>

                        <th
                          className={
                            'px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    {/* <PulseLoader
                      color="#23A5E0"
                      loading={isLoading}
                      css={override}
                      size={10}
                    /> */}
                    <tbody>
                      {docs &&
                        docs?.map((item) => {
                          return (
                            <tr>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                <div>
                                  <img
                                    style={{
                                      width: '60px',
                                      height: '60px',
                                      borderRadius: '50px',
                                    }}
                                    src={item.avatarUrl && item.avatarUrl}
                                  />
                                </div>
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                  cursor: 'pointer',
                                }}
                                // onClick={() => {
                                //   OnClickEditAuthor(
                                //     item?._id,
                                //     item?.avatarUrl,
                                //     item?.name,
                                //     item?.email,
                                //     item?.redirectionUrl,
                                //     item?.twitterHandle &&
                                //       item?.twitterHandle,
                                //     item?.social &&
                                //       item?.social?.facebook &&
                                //       item?.social?.facebook,
                                //     item?.social &&
                                //       item?.social?.instagram &&
                                //       item?.social?.instagram,
                                //     item?.social &&
                                //       item?.social?.twitter &&
                                //       item?.social?.twitter,
                                //     item?.bio && item?.bio,
                                //     item?.status && item?.status
                                //   );
                                // }}
                              >
                                {item && item.name ? item.name : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item?.email ? item?.email : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {item && item?.status ? (item?.status == "DELETED" ? "INACTIVE" : "ACTIVE") : 'N/A'}
                              </td>

                              {/* <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {/* <Button
                                  variant="contained"
                                  onClick={() => {
                                    OnClickEditAuthor(item._id, item.name);
                                  }}
                                >
                                  Edit
                                </Button> 
                              </td> */}
                              <td className="border border-green-800 border-t-0 border-l-0 border-r-0">
                                <div>
                                  <i
                                    onClick={() => {
                                      OnClickEditAuthor(
                                        item?._id,
                                        item?.avatarUrl,
                                        item?.name,
                                        item?.email,
                                        item?.redirectionUrl,
                                        item?.twitterHandle &&
                                          item?.twitterHandle,
                                        item?.social &&
                                          item?.social?.facebook &&
                                          item?.social?.facebook,
                                        item?.social &&
                                          item?.social?.instagram &&
                                          item?.social?.instagram,
                                        item?.social &&
                                          item?.social?.twitter &&
                                          item?.social?.twitter,
                                        item?.bio && item?.bio,
                                        item?.status && item?.status
                                      );
                                    }}
                                    className="fas fa-edit"
                                    style={{
                                      marginRight: '30px',
                                      cursor: 'pointer',
                                    }}
                                  ></i>

                                  <i
                                    onClick={() => {
                                      showModalForPublish();
                                      setAuthorID(item?._id);
                                    }}
                                    className="fas fa-trash "
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
      </div>
    </>
  );
};

export default ManageAuthor;
