import React from 'react';

const VideoStatusDropDown = (props) => {
  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.value}
    >
      <option value={'all'}>Select Status</option>
      <option value={true}>Published</option>
      <option value={false}>Draft</option>
    </select>
  );
};

export default VideoStatusDropDown;
