import AppConstants from "../constants";
import LoginAppConstants from "../constants/LoginAppConstants";
import AxiosInstance from "./http.handlers";

const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem("Current_Language");
const headers = {
  "cms-access-token": accessToken,
  source: "cms",
  language: CURRENT_LANGUAGE,
};
export const GetDynamicBanner = async (page, limit, location, sportsId) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.DYNAMIC_BANNER.GET_DYNAMIC_BANNER}?page=${page}&limit=${limit}&location=${location}&sports=${sportsId}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetDynamicBannerDetail = async (id, seo_slug) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.DYNAMIC_BANNER.GET_DYNAMIC_BANNER_DETAIL}?id=${id}&seo_slug=${seo_slug}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UploadDynamicBanner = async (body) => {
  const formData = new FormData();

  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.DYNAMIC_BANNER.CREATE_DYNAMIC_BANNER}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UpdateDynamicBanner = async (body) => {
  const formData = new FormData();

  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  return await AxiosInstance.put(
    `${AppConstants.WorkerConstants.API_ROUTES.DYNAMIC_BANNER.UPDATE_DYNAMIC_BANNER}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UpdateDynamicBannerRanking = async (body) => {
  const formData = new FormData();

  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.DYNAMIC_BANNER.UPDATE_DYNAMIC_BANNER_RANKING}`,
    body,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
