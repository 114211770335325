import React, { useState } from 'react';

const HomeSpecialDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState();
  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.selectedValue}
    >
      {/* <option>==Tag Home==</option> */}
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="ALL"
      >
        All
      </option>
      <option
        selected={selectedOption == props.selectedValue ? 'selected' : ''}
        value="EDITOR_CHOICE"
      >
        Editor's Choice
      </option>
      <option
        selected={selectedOption == props.selectedValue ? 'selecetd' : ''}
        value="KABBADI_SPECIAL"
      >
        In Focus
      </option>
    </select>
  );
};

export default HomeSpecialDropdown;
