import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  AddArenaFeed,
  GetHomeFeature,
  TagFeatureToHome,
  UpdateArenaFeed,
  GetArenaFeed,
} from '../../../handlers/leagues.handlers';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VimeoTitleDropDown from '../../../components/Dropdowns/VimeoTitleDropDown';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
import { _getDateTime, _dateTimeFormat, _dateTimeFormatinIST } from '../../../constants/appDefaults';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { dispatchSelectedLeague } from '../../../redux/action-dispatchers/leagues.dispatchers';
import { Checkbox } from '@material-ui/core';
import LeaguesDropDown from '../../../components/Dropdowns/LeaguesDropDown';

import AlertModal from '../../../components/AlertModal';
import { connect } from 'react-redux';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { Pagination, Stack } from '@mui/material';
import { GetVimeoTitle } from '../../../handlers/live.handlers';
import { sendNotification } from '../../../handlers/notification.handler';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const FeatureCard = (props) => {
  const [alertModal, setAlertModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [leagueData, setLeagueData] = useState([]);
  const [updateDocs, setUpdateDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [compIdFilter, setCompIdFilter] = useState('All');
  const [searchString, setSearchString] = useState('');
  const [checkedFeatureCards, setCheckFeatureCards] = useState([]);
  const [showEditFeature, showEditFeatureCard] = React.useState(false);
  const [arenaFeed, setArenaFeed] = useState('');
  const [liveBlogId, setLiveBlogId] = useState('');
  const [videoId, setVideoId] = useState({});
  const [editHeading, setEditHeading] = useState(false);
  const [vimeoUrl, setVimeoUrl] = useState('');
  const [vimeoChat, setVimeoChat] = useState('');
  const [leagueID, setLeagueID] = useState('');
  const [isHomeChecked, setIsHomeChecked] = useState(false);
  const [matchDetail, setMatchDetail] = useState('');
  const [vimeoInfo, setVimeoInfo] = useState('');
  const [matchID, setMatchID] = useState('');
  const [hasNextPage, setHasNextPage] = useState(false);
  const [limit, setLimit] = useState(10);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);


  {/** Notification */}
  const [showNotificationBox, setShowNotificationBox] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationContent, setNotificationContent] = useState("");
  const [notificationMatchID, setNotificationMatchID] = useState("");
  const [disableNotificationButton, setNotificationButton] = useState(false);

  const SendNotification = () =>{
    if(notificationTitle?.length==0){
        alert("Please enter a Title for Notification....");
    }
    else if(notificationContent?.length==0){
      alert("Please enter a Content for Notification....");
    }
    else{
      setNotificationButton(true);
      const body = {
        matchId:notificationMatchID.toString(), 
        title:notificationTitle, 
        content:notificationContent
      }
      sendNotification(body)
      .then((res)=>{
        setShowNotificationBox(false);
        setNotificationContent("");
        setNotificationMatchID("");
        setNotificationTitle("");
        setNotificationButton(false);
        alert(res?.data?.message);
      })
      .catch((err)=>console.log(err))
    }
  };

  const color = 'light';
  const [language, setLanguage] = useState(localStorage.getItem('Current_Language'))
  useEffect(() => {
    dispatchSelectedLeague({
      selectedLeague: 'All',
      selectedLeagueFilter: 'All',
    });
  }, []);

  useEffect(()=>{
    if(typeof window != undefined){
      setLanguage(localStorage.getItem('Current_Language'))
    }
  }, [typeof window])
  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(leagueData, source.index, destination.index);
    const docsUpdated = [];
    items.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        ranking: index + 1,
      });
    });

    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setDocs(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const selectHomeFeature = (
    event,
    match_id,
    abbr,
    title,
    date,
    istagged,
    sports,
    competition
  ) => {
    const body = {
      match_id,
      abbr,
      title,
      date_start: date,
      isTaggedOnHome: istagged,
      sports,
      competition: competition,
    };
    const recordFound = _.find(
      checkedFeatureCards,
      (item) => item === match_id
    );

    if (recordFound) {
      const newList = _.filter(
        checkedFeatureCards,
        (item) => item !== match_id
      );

      setCheckFeatureCards(newList);
      TagFeatureToHome(body)
        .then((response) => {
          if (response) {
            getHomeFeatureCard(10, page, props.selectedLeague);
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });

      // getHomeFeatureCard(props.selectedLeague);
      // window.location.reload();
      // setAlertModal(true);
    } else {
      setCheckFeatureCards([...checkedFeatureCards, match_id]);
      TagFeatureToHome(body)
        .then((response) => {
          if (response) {
            getHomeFeatureCard(10, page, props.selectedLeague);
          }
        })
        .catch((error) => {
          if (error) {
            alert(error);
          }
        });
      // getHomeFeatureCard(props.selectedLeague);
      // window.location.reload();
      // setAlertModal(true);
    }
  };

  React.useEffect(() => {
    getHomeFeatureCard(limit, page);
  }, [page, compIdFilter]);
  const getHomeFeatureCard = (limit, page, isLoadMore) => {
    setIsLoading(true);
    const body = {
      limit: limit,
      page: page,
      comp_id: compIdFilter,
    };
    GetHomeFeature(body)
      .then(async (response) => {
        //setLeagueData(response.data.data.docs);
        // setHasNextPage(response.data.data.hasNextPage);

        if (response.status == 200) {
          let featureCardData = await response.data.data.limit;
          setIsLoading(false);
          setTotalPages(response.data.data.totalPages);
          setLimit(featureCardData);

          setLeagueData(response.data.data.docs);
        }
      })
      .catch((error) => {
        isLoadMore && setIsLoadingMore(false);
      });
  };
  const getArenaFeed = () => {
    const body = {
      match_id: matchID,
    };
    GetArenaFeed(body).then((response) => {
      setArenaFeed(response.data?.data && response.data.data.arenaFeed);
      setVideoId(
        response.data?.data && response.data.data.video._id
          ? response.data.data.video._id
          : ''
      );
      setLiveBlogId(response.data?.data && response.data.data.liveBlogId);
      setVimeoUrl(response.data?.data && response.data.data.vimeoVideo);
      setVimeoChat(response.data?.data && response.data.data.vimeoChat);
    });
  };
  useEffect(() => {
    getArenaFeed();
  }, [matchID]);
  const OnClickEditFeatureCard = (league) => {
    //setArenaFeed(league.arenaFeed == undefined ? '' : league.arenaFeed);

    setVimeoUrl(league.vimeoVideo);
    setLeagueID(league._id);
    setMatchDetail(league.title);
    setVimeoChat(league.vimeoChat);
    setMatchID(league.match_id);
    showEditFeatureCard(true);
    getArenaFeed();
    // getVimeoData(league.match_id);
  };

  const onLeagueChange = (item)=>{
    setCompIdFilter(item);
  }


  const getVimeoData = (match_id) => {
    GetVimeoTitle(match_id)
      .then((response) => {
        setVimeoInfo(response.data);
      })
      .catch((error) => error);
  };
  const onUpdateFeatureCard = () => {
    const data = {
      match_id: matchID,
      arenaFeed: arenaFeed,
      vimeoVideo: vimeoUrl,
      vimeoChat: vimeoChat,
      liveBlogId: liveBlogId,
      video: videoId,
    };
    AddArenaFeed(data).then((response) => {
      if (response.status == 200) {
        alert('Feed successfully updated');

        showEditFeatureCard(false);
        getHomeFeatureCard();
      } else alert('Opps! something went wrong, please try again');
    });
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };
  return (
    <>
      {/* Feature card edit */}

      <div className="edit__popup">
        <div
          onClick={() => showEditFeatureCard(false)}
          className={
            showEditFeature ? 'edit__backdrop active' : 'edit__backdrop'
          }
        ></div>

        <div
          className={
            showEditFeature ? 'edit__popup-body active' : 'edit__popup-body'
          }
          style={{ zIndex: 10000 }}
        >
          <div className="edit__popup-inner">
            <i
              onClick={() => showEditFeatureCard(false)}
              className="far fa-times-circle edit__popup-close"
            ></i>

            <div className="edit__popup--form">
              <div style={{ display: 'flex', marginBottom: '30px' }}>
                <h1 style={{ fontWeight: 'bold' }}>Match name, Match id: -</h1>
                <h1 style={{ fontWeight: '600' }}>
                  {matchDetail}, {matchID}
                </h1>
              </div>
              <div className="edit__popup-row">
                <span style={{ fontWeight: '600' }}>Arena Feed :</span>

                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="text"
                    value={arenaFeed}
                    onChange={(e) => setArenaFeed(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please provide arena id here..."
                  />
                </div>
              </div>
              <div className="edit__popup-row">
                <span style={{ fontWeight: '600' }}>Live Blog Id :</span>

                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="text"
                    value={liveBlogId}
                    onChange={(e) => setLiveBlogId(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please provide Live Blog id here..."
                  />
                </div>
              </div>
              <div className="edit__popup-row">
                <span style={{ fontWeight: '600' }}>Video Id :</span>

                <div className="relative w-full mb-3">
                  {/* {console.log('videos', videoId)} */}
                  <input
                    type="text"
                    name="text"
                    value={videoId}
                    onChange={(e) => setVideoId(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please provide Video id here..."
                  />
                </div>
              </div>

              <div className="edit__popup-row">
                <span style={{ fontWeight: '600' }}>Video URL :</span>

                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="text"
                    value={vimeoUrl}
                    onChange={(e) => setVimeoUrl(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please Add Video URL here..."
                  />
                </div>
              </div>
              {/* <span
                  style={{ fontWeight: '600' }}
                  onClick={() => setEditHeading(true)}
                >
                  Select Vimeo Title : <i className="far fa-edit px-3"></i>
                </span>

                {editHeading ? (
                  <div className="edit__popup-row" style={{ marginLeft: '55px' }}>
                    <VimeoTitleDropDown match_id={matchID} />
                    <i
                      onClick={() => {
                        setEditHeading(false);
                        getVimeoData(matchID);
                      }}
                      className="far fa-times-circle "
                    ></i>{' '}
                  </div>
                ) : (
                  <div>{vimeoInfo && vimeoInfo.title}</div>
                )} */}
              <div className="edit__popup-row">
                <span style={{ fontWeight: '600' }}>Chat URL :</span>

                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="text"
                    value={vimeoChat}
                    onChange={(e) => setVimeoChat(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please Chat URL id here..."
                  />
                </div>
              </div>
              <div className={'edit__popup-row button-row'}>
                <div
                  onClick={() => onUpdateFeatureCard()}
                  className={'save btn'}
                >
                  Update
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature card edit end */}

      {/** Send Notification */}
      <div className="edit__popup">
        <div
          onClick={() => setShowNotificationBox(false)}
          className={
            showNotificationBox ? 'edit__backdrop active' : 'edit__backdrop'
          }
        ></div>

        <div
          className={
            showNotificationBox ? 'edit__popup-body active' : 'edit__popup-body'
          }
          style={{ zIndex: 10000, width:"40%", height:"unset", justifyContent:"center" }}
        >
          <div className="edit__popup-inner">
            <i
              onClick={() => setShowNotificationBox(false)}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <div style={{padding:"20px"}}>
                <div style={{marginBottom:"10px"}}>
                  <div style={{marginBottom:"10px"}}>Title: * </div>
                  <textarea
                    style={{height:"100px"}}
                    type="text"
                    name="text"
                    value={notificationTitle}
                    onChange={(e) => setNotificationTitle(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please provide Title for Notification here..."
                  />
                </div>
                <div style={{marginBottom:"10px"}}>
                  <div style={{marginBottom:"10px"}}>Content: * </div>
                  <textarea
                    style={{height:"100px"}}
                    type="text"
                    name="text"
                    value={notificationContent}
                    onChange={(e) => {setNotificationContent(e.target.value)}}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Please provide Content for Notification here..."
                  />
                </div>
                <div style={{ display:"flex", justifyContent:"center", width:"100%", marginTop:"10px"}}>
                  <button style={{backgroundColor:disableNotificationButton?"grey":"green", color:"white", width:"fit-content", padding:"5px 10px", borderRadius:"15px",}} onClick={()=>
                    !disableNotificationButton&&SendNotification()
                  } >
                    Send Notification
                    <NotificationsActiveIcon style={{marginLeft:"10px"}}/>
                  </button>
                </div>
            </div>
            
          </div>
        </div>
      </div>
      <Sidebar />

      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your card has been tagged successfully!"
                onSuccess={() => window.location.reload()}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Feature-Card
                      <SyncLoader
                        color="#23A5E0"
                        loading={isLoading}
                        css={override}
                        size={12}
                      />
                    </h3>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <input
                        style={{
                          position: 'relative',
                          right: '-10px',
                          top: '-15px',
                          border: '2px solid #acb9ce',
                          borderRadius: 10,
                          // marginRight: '3%',
                          padding: '4px',
                          // paddingLeft: '10px',
                        }}
                        placeholder="Search..."
                        onChange={(event) => {
                          setSearchString(event.target.value);
                        }}
                      />
                    </div>
                    {showAction && (
                      <div className={'save-reset enabled'}>
                        <div className="save btn">Save</div>

                        <div className="reset btn">Reset</div>
                      </div>
                    )}
                  </div>
                  <span
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    <LeaguesDropDown 
                        onChange={onLeagueChange}
                    />
                  </span>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* <DragDropContext onDragEnd={handleDragEnd}> */}
                {/* <Droppable droppableId="droppable"> */}
                {/* {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}> */}
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Match ID
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Match Detail
                      </th>
                      {/* <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Language
                              </th> */}
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        League
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Sports
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Match Date & Time
                      </th>
                      {/* <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Status
                              </th> */}

                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Home
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Arena Feed
                      </th>
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        Notification
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {leagueData.length > 0 &&
                      leagueData
                        .filter((val) => {
                          if (searchString == '') {
                            return val;
                          } else if (
                            val.title
                              .toLowerCase()
                              .includes(searchString.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .map((league, index) => {
                          return (
                            // <Draggable
                            //   key={league._id}
                            //   draggableId={league._id}
                            //   index={index}
                            // >
                            // {(provided, snapshot) => (
                            // <tr
                            //   ref={provided.innerRef}
                            //   {...provided.draggableProps}
                            //   {...provided.dragHandleProps}
                            // >
                            <tr>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  // cursor: "pointer",
                                  fontWeight: 'bolder',
                                }}
                              >
                                {league && league.match_id
                                  ? league.match_id
                                  : 'na'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                                style={{
                                  // cursor: "pointer",
                                  fontWeight: 'bolder',
                                }}
                              >
                                {league && league.title ? league.title : 'na'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  // cursor: "pointer",
                                  fontWeight: 'bolder',
                                }}
                              >
                                {league && league.competition
                                  ? league.competition.abbr
                                  : 'na'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  // cursor: "pointer",
                                  fontWeight: 'bolder',
                                }}
                              >
                                {league &&
                                league.sports &&
                                league.sports[0].name
                                  ? league.sports[0].name
                                  : 'na'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  // cursor: "pointer",
                                  fontWeight: 'bolder',
                                }}
                              >
                                {language?.toLocaleLowerCase() == 'english'
                                ?
                                _dateTimeFormatinIST(
                                  league && league ? league.date_start : 'na',
                                  'ddd, DD MMM hh:mm A'
                                )
                                :
                                _dateTimeFormat(
                                  league && league ? league.date_start : 'na',
                                  'ddd, DD MMM hh:mm A'
                                )}
                              </td>{' '}
                              {/* <td
                                          className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                          style={{
                                            // cursor: "pointer",
                                            fontWeight: 'bolder',
                                          }}
                                        ></td> */}
                              <td
                                className="border border-green-800 px-6"
                                style={{
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                {/* _.find(
                                                checkedFeatureCards,
                                                (item) =>
                                                  item === league.match_id
                                              ) !== undefined */}
                                <Checkbox
                                  checked={
                                    league.isTaggedOnHome === true
                                      ? true
                                      : false &&
                                        _.find(
                                          checkedFeatureCards,
                                          (item) => item === league.match_id
                                        ) !== undefined
                                  }
                                  onChange={(event) => {
                                    selectHomeFeature(
                                      event,
                                      league.match_id,
                                      league &&
                                        league.competition &&
                                        league.competition.abbr,
                                      league.title,
                                      league.date_start,
                                      league.isTaggedOnHome,
                                      league.sports && league.sports[0]._id,
                                      league.competition
                                    );
                                  }}
                                  color="default"
                                  inputProps={{
                                    'aria-label': 'checkbox with default color',
                                  }}
                                />
                              </td>
                              <td
                                className="border border-green-800 px-6"
                                style={{
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <i
                                  onClick={() => OnClickEditFeatureCard(league)}
                                  className="far fa-edit"
                                ></i>
                              </td>
                              <td
                                className="border border-green-800 px-6"
                                style={{
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                  alignItems:"center",
                                  cursor: 'pointer',
                                }}
                                onClick={()=>{
                                  setShowNotificationBox(true);
                                  setNotificationMatchID(league.match_id);
                                }}
                              >
                                <NotificationsActiveIcon style={{marginLeft:"30px", fill:"green"}}/>
                             
                              </td>
                            </tr>
                            // )}
                            // </Draggable>
                          );
                        })}
                  </tbody>
                  {/* {provided.placeholder} */}
                </table>
                {/* {hasNextPage && !isLoadingMore && (
                  <div style={{ textAlign: 'center', padding: 20 }}>
                    <button
                      className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      onClick={() => {
                        getHomeFeatureCard(limit, parseInt(page + 1), true);
                      }}
                    >
                      Load More
                    </button>
                  </div>
                )} */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      padding: 20,
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        page={page}
                        onChange={handlePagination}
                        count={totalPages}
                        variant="outlined"
                        color="primary"
                      />
                    </Stack>
                  </div>

                  <input
                    type="number"
                    min="1"
                    max={totalPages}
                    value={page}
                    onChange={(event) => setPage(event.target.value)}
                    style={{
                      width: '80px',
                      height: '40px',
                      borderRadius: '20px',
                      border: '1px solid green',
                      outline: '1px solid green ',
                      textDecoration: 'none',
                    }}
                  ></input>
                </div>
                {/* </div> */}
                {/* )} */}
                {/* </Droppable> */}
                {/* </DragDropContext> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
  };
};
export default connect(mapStateToProps)(FeatureCard);
