import React from 'react';

const YtShortsDropdown = (props) => {
  return (
    <select
      style={{
        // marginLeft: 20,
        border: '2px solid #acb9ce',
        borderRadius: 10,
      }}
      onChange={props.onChange}
      value={props.selectedValue}
    >
      <option value={'All'}>Select Status</option>
      <option value={1}>Published</option>
      <option value={0}>Draft</option>
    </select>
  );
};

export default YtShortsDropdown;
