const lang = localStorage.getItem("Current_Language");
const lowerCaseLang = lang && lang.toLowerCase();
// ? localStorage.getItem('lang')
// && 'english';

// Production AWS urls

// export const OLD_BASE_PATH = `https://production-data-crawler.thesportstak.com/${lowerCaseLang}/`;
// export const API_BASE_PATH = `https://cms-rest-microservice.thesportstak.com/${lowerCaseLang}/api/cms/`;
// export const API_AUTH_PATH = `https://cms-rest-microservice.thesportstak.com/${lowerCaseLang}/authentication/api/`;

// Production AWS urls end

// Production urls
if (lang == "Hindi") {
  var API_BASE = `https://cms-rest-microservice.thesportstak.com/hindi/api/cms/`;
  var API_BASE_V2 = `https://cms-rest-microservice.thesportstak.com/hindi/api/v2/`;
  var API_BASE_PATH_OLD = `https://production-data-crawler.thesportstak.com/hindi/`;
} else {
  var API_BASE = `https://cms-rest-microservice.thesportstak.com/english/api/cms/`;
  var API_BASE_V2 = `https://cms-rest-microservice.thesportstak.com/english/api/v2/`;
  var API_BASE_PATH_OLD = `https://production-data-crawler.thesportstak.com/english/`;
  var TWITTER_BASE =
    'https://production-twitter-microservice.thesportstak.com/api/';
}
export const OLD_BASE_PATH = API_BASE_PATH_OLD;
export const API_BASE_PATH = API_BASE;
export const API_BASE_PATH_V2 = API_BASE_V2;
export const Home_Special_Path = API_BASE;
export const API_AUTH_PATH =
  "https://cms-rest-microservice.thesportstak.com/authentication/api/";
export const TWITTER_BASE_PATH =
  "https://production-twitter-microservice.thesportstak.com/api/";
//  Production end

// Staging urls
// if (lang == "Hindi") {
//   var API_BASE = `https://staging-hindi-rest-microservice.thesportstak.mobiletak.com/api/cms/`;
//   var API_BASE_V2 = `https://staging-hindi-rest-microservice.thesportstak.mobiletak.com/api/v2/`;
//   var API_BASE_PATH_OLD = `https://staging-hindi-data-crawler.thesportstak.mobiletak.com/`;
// } else {
//   var API_BASE = `https://staging-rest-microservice.thesportstak.mobiletak.com/api/cms/`;
//   var API_BASE_V2 = `https://staging-rest-microservice.thesportstak.mobiletak.com/api/v2/`;
//   var API_BASE_PATH_OLD = `https://staging-data-crawler.thesportstak.mobiletak.com/`;
//   var TWITTER_BASE =
//     "https://staging-twitter-microservice.thesportstak.mobiletak.com/api/";
// }
// export const OLD_BASE_PATH = API_BASE_PATH_OLD;
// export const API_BASE_PATH = API_BASE;
// export const API_BASE_PATH_V2 = API_BASE_V2;
// export const Home_Special_Path = API_BASE;
// export const API_AUTH_PATH =
//   "https://staging-rest-microservice.thesportstak.mobiletak.com/authentication/api/";
// export const TWITTER_BASE_PATH =
//   "https://staging-twitter-microservice.thesportstak.mobiletak.com/api/";
//  Staging end

//development

// if (lang && lang == 'Hindi') {
//   var API_BASE = `https://develop-hindi-rest-microservice.thesportstak.mobiletak.com/api/cms/`;
//   var API_BASE_PATH_OLD = `https://develop-hindi-data-crawler.thesportstak.mobiletak.com/`;
// } else if (lang == 'English') {
//   // var API_BASE = `https://rest-microservice.thesportstak.mobiletak.com/api/`;
//   var API_BASE = `https://develop-rest-microservice.thesportstak.mobiletak.com/api/cms/`;
//   var API_BASE_PATH_OLD = `https://develop-data-crawler.thesportstak.mobiletak.com/`;
// var TWITTER_BASE =
//     'https://production-twitter-microservice.thesportstak.com/api/';
// }
// export const OLD_BASE_PATH = API_BASE_PATH_OLD;
// export const API_BASE_PATH = API_BASE;
// export const Home_Special_Path = API_BASE;
// export const API_AUTH_PATH =
//   'https://develop-rest-microservice.thesportstak.mobiletak.com/authentication/api/';
// export const TWITTER_BASE_PATH =
//   "https://production-twitter-microservice.thesportstak.com/api/";
// development end

export const API_ROUTES = {
  ADMIN: {
    SIGN_UP: "user/addRequest",
    FORGOT_PASSWORD: "user/forgot-password",
    UPDATE_PASSWORD: "user/update-password-otp",
    ADD_ROLE: "admin/addroles",
    ADD_PERMISSION: "admin/addPermissionToModule",
    GET_PERMISSION_ROLE: "admin/permissionModule",
    GET_ALL_ROLE: "admin/getallRole",
    GET_REQUEST: "admin/request-list",
    GET_SEARCH_REQUEST: "admin/search-requests",
    ADD_REQUEST: "user/addRequest",
    CANCEL_REQUEST: "admin/cancelRequest",
    APPROVE_REQUEST: "admin/approveRequest",
    GET_REQUEST_LIST: "admin/request-list",
    ACTION_TOGGLED: "admin/action",
    GET_SEARCH: "admin/search",
    // DELETE_USER: 'admin/delete',
    GET_DATE_SEARCH: "admin/onlyDateSearch",
    GET_DATE_SEARCH_REQUEST: "admin/onlyDateRequestSearch",
    ADD_USER: "admin/adduser",
    GET_ALL_USER: "admin/alluser",
  },
  AUTHOR: {
    GET_AUTHOR: "author/authorDetail",
    UPDATE_AUTHOR: "author/admin/updateAuthor",
    ADD_AUTHOR: "author/admin/addAuthor",
    GET_ALL_AUTHOR: "author/getAll",
    GET_ALL_AUTHOR_LIST: "author/getAllList",
    DELETE_AUTHOR: "author/admin/delete",
  },
  BANNERS: {
    GET_BANNER: "global/getSettings",
    UPDATE_BANNER: "global/updateSettings",
  },
  DYNAMIC_BANNER: {
    GET_DYNAMIC_BANNER: "dynamicBanners/list",
    CREATE_DYNAMIC_BANNER: "dynamicBanners/admin/create",
    GET_DYNAMIC_BANNER_DETAIL: "dynamicBanners/detail",
    UPDATE_DYNAMIC_BANNER: "dynamicBanners/admin/update",
    UPDATE_DYNAMIC_BANNER_RANKING: "dynamicBanners/admin/updateBannerRanking",
  },

  FEATURED:{
    GET_FEATURED_CONTENT: "content/admin/featured",
    TOGGLE_FEATURED_STATUS: "/video/tagToFeaturedSection", //For changing the status of featured
  },

  GALLERY: {
    // UPLOAD: 'gallery/uploadImageToAws',
    ADD_INSIGHTS: "/insights/admin/insertNew",
    TOGGLE_IS_PUBLISHED_INSIGHTS: "/insights/admin/toggleIsPublished",
    UPDATE_INSIGHTS: "/insights/admin/update",
    DELETE_INSIGHT: "/insights/admin/delete",
    UPDATE_HOME_INSIGHT_RANKING: "/insights/admin/updateHomeInsightRanking",
    GET_ALL_INSIGHTS: "/insights/admin/all",
    GET_INSIGHT_INFO: "/insights/admin",
    GET_HOME_INSIGHTS: "insights/admin/homeTagged",
    TOGGLE_IS_HOME_TAGGED: "insights/admin/homeTagged",
  },

  GOOGLE_ANALYTICS_MANAGER:{
    CATEGORY_DATA_FROM_GA:"googleAnalytics/admin/categoryDataFromGA",
    AUTHOR_DATA_FROM_GA:"googleAnalytics/admin/authorDataFromGA",
    TYPE_DATA_FROM_GA:"googleAnalytics/admin/typeDataFromGA"
  },

  HOME_SPECIAL: {
    // New home special
    REORDER_HOME_SPECIAL: "homeSpecial/updateRanking",
    HOME_SPECIAL: "homeSpecial/addOrRemoveHomeSpecial",
    GET_ALL_HOME_SPECIAL: "homeSpecial/getEditorChoice",
    HOME_SPECIAL_CONTENT: "global/addHomeSpecialContent",
    HOME_SPECIAL_UNTAGGED: "global/unTaggSpecial",
    GET_HOME_SPECIAL: "global/getHomeSpecial",
    GET_HOME_LIVE_BLOG: "homeSpecial/fetchHomeLiveBlog",
    ADD_HOME_LIVE_BLOG: "homeSpecial/addHomeLiveBlog",
  },
  LEAGUES: {
    GET_ALL_TAG_LEAGUE: "tag/leagues",
    GET_ALL_DEFAULT: "leagues/default-leagues",
    GET_SCHEDULES: "leagues/getScheduleAll",
    GET_Matches_SCHEDULES: "match/getSchedule",

    // GET_SCHEDULES: 'leagues/getScheduleAllForCMS',
    GET_UPCOMING: "leagues/getUpcommingMatches",
    // GET_UPCOMING: 'leagues/getUpcommingMatchesForCMS',
    GET_TODAY_MATCHES: "leagues/getTodaysMatches",
    // GET_TODAY_MATCHES: 'leagues/getTodaysMatchesForCMS',
    // UPDATE_LEAGUE: 'leagues/getMasterListForMatchTagging',
    UPDATE_LEAGUE: "match/getMasterListForMatchTagging",
    TAG_LEAGUE_AT_HOME: "leagues/taggLeaguesOnHome",
    SHOW_HOME_TAGGED: "match/getScoreCard",
    SET_ARENA_FEED: "match/editArenaFeedForMatch",
    ADD_ARENA_FEED: "match/addArenaFeed",
    GET_ARENA_FEED: "match/getArenaFeed",
    GET_MATCH_GRAPHICS: "graphics/graphicsOfMatch",
    GET_BUZZ_MATCH_GRAPHICS: "graphics/getNewBuzzGraphics",
    UPLOAD_GRAPHICS: "graphics/uploadGraphics",
    UPLOAD_SCHEDULE_GRAPHICS: "graphics/scheduleGraphics",
    UPLOAD_BUZZ_GRAPHICS: "graphics/buzzGraphics",
    UPDATE_MATCH_GRAPHICS: "graphics/uploadGraphics",
    GET_HOME_GRAPHICS: "graphics/getHomeGraphics",

    // GET_HOME_GRAPHICS: "graphics/getNewHomeGraphics",
    UPLOAD_HOME_GRAPHICS: "graphics/homeGraphics",
    GET_DEFAULT_PREF: "leagues/getDefaultPref",
    // GET_DEFAULT_PREF: 'leagues/getDefaultPrefForCMS',
    CHANGE_HOME_RANKING: "match/updateHomeRanking",
    // CHANGE_HOME_RANKING: 'leagues/changeHomeLeagueRanking',
    SHOW_ON_WEB: "leagues/admin/toggleShowOnWeb",

    //LeagueManager endPoints
    GET_ALL_COMPS:"leagues/getAllComps",
    UPDATE_RANK_OF_SUGGESTED_LEAGUE: "leagues/admin/reorderAll",
    SUGGESTED_LEAGUE_LIST:"leagues/getDefaultPrefForCMS",
    UPDATE_LEAGUE_TYPE:"leagues/admin/addToLeague",
    
  },

  LIVE: {
    GET_ALL_LIVE_STREAM: "/livestream/getAllLiveStreamsCMS",
    PUBLISH_LIVE_STREAM: "livestream/publishlivestream",
    PUBLISH_LIVE_STREAM_VIMEO: "livestream/unpublishlivestream",
    EDIT_TITLE: "livestream/updatelivestreamtitle",
    GET_CMS_LIVESTREAM_VIMEO: "livestream/getAllLiveStreamsCMS",
  },
  MATCHES: {
    TAG_MATCHES: "tag/matches",
  },
  NEWS: {
    GET_PAGINATED: "news/admin/paginate",
    NEWS_INFO: "news",
    MAP_ARTICLE: "news/mapArticleToLeague",
    MAP_ARTICLE_TO_MATCHES: "news/admin/tagMatch",
    MAP_SPORTS: "news/mapArticleToSports",
    TOGGLE_ARTICLE_STATUS: "news/articleToggle",
    UPLOAD_ARTICLE_IMAGE: "news/uploadArticleImage",
    UPDATE_SPORTS: "news/updateSports",
    ADD_NEWS: "news/addNews",
    UPDATE_NEWS: "news/updateNews",
    GET_SECTION: "news/admin/newsArticleBySection",
    UPDATE_NEWS_RANKING: "news/updateNewsRanking",
    UPDATE_HOME_RANKING: "/news/updateHomeRanking",
    TAG_NEWS_TO_HOME: "news/tagHome",
    TAG_NEWS_TO_TEAMS: "news/tagTeams",
    TAG_NEWS_TO_TEAMS_SPORTSWISE: "news/admin/tagTeamsOrPlayers",
    GET_HOME_NEWS: "news/admin/home",
    REMOVE_TAGGED_TEAMS: "news/removeTeams",
    COPY_HYPERLINK: "content/admin/generateURL",
    TAG_NEWS_TO_TRENDING: "/news/admin/toggleTrendingNews", // Tag News to Trends in Twitter
    CLICKBAIT_HEADLINE: "/content/admin/generateClickbaitHeadline", // Titles for Notification from Chat GPT
  },
  PLAYER_PROFILE: {
    GET_PLAYER_LIST: "player/admin/list",
    GET_PLAYER_INFO: "player/getPlayerStats",
    UPDATE_PLAYER_PROFILE: "player/admin/update",
    UPDATE_PLAYER_IMAGE: 'player/admin/updateImage'
  },
  PLAYERS: {
    GET_PLAYERS:"/tag/players"
  },
  POLLS: {
    GET_ALL_POLLS: "polls/admin/getAll",
    GET_ALL_POLLS_FOR_RANK: "polls/admin/getAllForPolls",
    DELETE_POLLS: "polls/admin/delete",
    ADD_POLLS: "polls/admin/create",
    EDIT_POLLS: "polls/admin/edit",
    REORDER_POLLS: "polls/admin/reorderAll",
  },
  QUIZ: {
    ADD_QUIZ: "quiz/admin/create",
    GET_QUIZ: "quiz/admin/getAllByPagination",
    GET_QUIZ_DETAIL: "quiz/admin/getQuiz",
    DELETE_QUIZ: "quiz/admin/deleteQuiz",
    UPDATE_QUIZ_VIDEO_THUMBNAIL: "/quiz/admin/updateVideoThumbnail",
    UPDATE_QUIZ: "quiz/admin/updateQuiz",
    UPDATE_QUIZ_VIDEO: "quiz/admin/updateVideo",
    UPDATE_QUIZ_HOMETAG: "quiz/admin/toggleTagOnHome",
    NOTIFICATION: "quiz/admin/sendNotification",
  },
  ROLES: {
    ALL_ROLES: "cmsRole/all",
  },
  SPORTS: {
    SPORTS_NAME: "sports",
    SPORTS_NAME_TAG: "tag/sports",
  },
  TEAMS: {
    TEAM_NAME: "teams/getAllTeams",
    MAP_TEAM_SEGMENTS: "teams/mapTeamSegments",
    SEND_NOTIFICATION_FOR_TEAMS: "teams/sendArticleNotificationForTeams",
    SEND_ARTICLE_NOTIFICATION: "teams/sendArticleNotification",
    SEND_VIDEO_NOTIFICATION_FOR_TEAMS: "teams/sendVideoNotificationForTeams",
    SEND_VIDEO_NOTIFICATION_SPECIFIC_USERS: "teams/sendVideoNotification",
    GET_TEAMS_LIST:'/teams/admin/byFilters',
    GET_TEAM_DETAILS:'/teams/admin/details',
    UPDATE_TEAM_DETAILS:'/teams/admin/update',
    DELETE_TEAM:'/teams/admin/delete'
  },
  TEAMSTAGGING: {
    GET_TEAMS_FOR_TAGGING: "tag/teamsOrPlayers",
    GET_TEAMS_PLAYERS: "teams/getTeams",
    TEAMS_TOP_TAGGING: "teams/topTagging",
  },
  TWITTER: {
    ADD_TO_VIDEOS: "twitter/admin/addToVideo",
    GET_TWITTER_VIDEOS: "display/admin/feedByTag",
    TWITTER_VIDEOS_DETAILS: "display/admin",
    TWITTER_TAG_FILTER: "display/admin/allTags",
    ADD_OR_EDIT_TWITTER_CONTENT: "update/admin/menu/addOrEdit",
    DELETE_TWITTER_CONTENT: "update/admin/menu/delete",
    DELETE_TWEET: "update/admin/tweet/delete",
    EDIT_TWITTER_CONTENT_DOCS: "display/admin/menu",
    EDIT_TWITTER_CONTENT_DOCS: "display/admin/menu",
    SUGGESTED_TWITTER_HANDLES: "display/admin/twitterHandleSuggestion",
  },
  USER: {
    LOGIN: "cmsUser/login",
    LOGOUT: "cmsUser/logout",
    ADD_USER: "cmsUser",
    ALL_USERS: "cmsUser/all",
    DELETE_USER: "cmsUser/deleteUser",
    UPDATE_USER: "cmsUser/updateUser",
    GET_PRODUCTIVITY_REPORT_NEWS: "user/news/productivityList",
    GET_PRODUCTIVITY_REPORT_VIDEOS: "user/video/productivityList",
    GET_PRODUCTIVITY_DETAIL_NEWS: "user/news/productivityDetails",
    GET_PRODUCTIVITY_DETAIL_VIDEOS: "user/video/productivityDetails",
  },
  VERSION: {
    GET_VERSIONS: "global/getVersions",
    UPDATE_VERSION: "global/updateVersion",
    ADD_VERSION: "global/addVersion",
  },
  VIDEOS: {
    GET_PAGINATED: "video/admin/paginate",
    GET_YOUTUBE_PAGINATED: "video/admin/youtube/paginate",
    TOGGLE_STATUS: "video/toggleStatus",
    VIDEOS_INFO: "video",
    MAP_VIDEOS: "video/mapVideoToLeague",
    UPLOAD_ARTICLE_IMAGE: "video/uploadArticleImage",
    UPDATE_SPORTS: "video/updateSports",
    UPLOAD_VIDEO_THUMBNAIL: "video/uploadVideoThumbnail",
    UPLOAD_VIDEO: "video/addVideo",
    TAG_VIDEO_TO_HOME: "video/tagHome",
    GET_HOME_VIDEO: "video/admin/home",
    TAG_VIDEO_TO_TOP: "video/updateVideoDetails",
    UPDATE_VIDEOS_RANKING: "video/updateVideoRanking",
    GET_TOP_VIDEOS: "video/admin/getVideoBySection",
    TAG_VIDEO_TO_TEAMS: "video/tagTeams",
    REMOVE_TAGGED_TEAMS: "video/removeTeams",
    TAG_HIGHLIGHTS: "video/taghighlight",
    GET_HIGHLIGHTS: "video/admin/highlight",
    GET_YT_SHORTS: "shorts/admin",
    REMOVE_YT_SHORTS: "shorts/updateStatus",
    GET_OPTA_SHORTS: "optaSocial/socialForCMS",
    GET_SHORT_TABLE: "optaSocial/admin/paginate",
    UPDATE_SHORTS_THUMBNAIL: "optaSocial/updateThumbnail",
    UPDATE_INFO: "optaSocial/updateInfo",
    SHORTS_VIDEO_INFO: "optaSocial/admin",
    TOGGLE_OPTA_SHORTS: "optaSocial/toggleOptaSocialParamFromCMS",
    UPLOAD_SHORTS_VIDEOS: "optaSocial/admin/addShorts",
    GET_SHORTS_LIST_TO_UPLOAD: "optaSocial/admin/getListOfShorts",
    TAG_SHORT_SPORTS: "optaSocial/admin/tagSports",
    TAG_SHORT_LEAGUE: "optaSocial/admin/tagLeagues",
    GET_TRANSCODED_VIDEOS_TO_UPLOAD:
      "transcodedVideo/admin/getTranscodedVideos",
    UPLOAD_TRANSCODED_VIDEO: "video/addVideoFromCMS",
    MAP_VIDEOS_TO_MATCHES: "video/admin/tagMatch",
    MAP_VIDEOS_TO_TEAMS: "video/admin/tagTeamsOrPlayers",
    MAP_ALL_LEAGUE_TO_VIDEOS: "video/mapVideoToAllLeague",
    TAG_VIDEOS_TO_TRENDING: "/video/admin/toggleTrendingVideos", // Tag News to Trends in Twitter
    CLICKBAIT_HEADLINE: "/content/admin/generateClickbaitHeadline", // Titles for Notification from Chat GPT
  },

  VIMEO: {
    GET_ALL_VIMEO_STREAMS_CMS: "vimeo/getAllVimeoStreamsCMS",
    UPDATE_VIMEO_STREAM_TITLE: "vimeo/updatevimeostreamtitle",
    PUBLISH_VIMEO_STREAM: "vimeo/publishvimeostream",
    UNPUBLISH_VIMEO_STREAM: "vimeo/unpublishvimeostream",
    GET_ALL_VIMEO_STREAMS: "vimeo/getAllVimeoStreams",
    TAG_HOME_OR_FEATURE: "vimeo/tagHome",
    TAG_FEATURE: "vimeo/tagFeatureCard",
    TAG_MATCH_AT_VIMEO: "vimeo/tagMatch",
    GET_TAGGED_MATCH_AT_VIMEO: "vimeo/getTaggedMatch",
    GET_VIMEO_TITLE: "vimeo/getTaggedMatch",
    ADD_VIMEO_URL: "streamUrl/addOrUpdateUrl",
    ADD_VIMEO_Images: "/vimeo/admin/updateThumbnail",
    GET_VIMEO_URL: "streamUrl/getUrlByEventId",
  },

  TRENDING: {
    CONTENT_LIMIT:"geographicInfo/admin/updateContentLimit",
    GET_ALL_LOCATIONS: "/geographicInfo/admin/allLocations",
    TOGGLE_LOCATIONS: "/geographicInfo/admin/toggleIsActive",
    TAGGED_LOCATIONS: "/geographicInfo/admin/activeLocations",
    TOP_TRENDS: "/geographicInfo/admin/getTopTrendsForParticularPlace",
    ADD_LOCATIONS: "/geographicInfo//admin/addOrUpdateLocation",
    GOOGLE_KEYWORDS:"/trending/admin/relatedKeywordsFromGoogleTrends"
  },

  PURGING: {
    ADD_URL_TO_PURGE:"/content/purge",
  },
  WHATS_NEW:{
    ADD_WHATS_NEW:"whatsNew/admin/addFeature",
    GET_LIST:"whatsNew/admin/list",
    UPDATE_WHATS_NEW:"whatsNew/admin/update",
    GET_WHATS_NEW:"whatsNew/featureById",
  },
  REPORT: {
    PUBLISHED_NEWS: "/news/admin/publishedNewsCount",
    PUBLISHED_VIDEOS: "/video/admin/publishedVideosCount",
    PUBLISHED_SHORTS: "/optaSocial/admin/publishedShortsCount",
    PUBLISHED_WEBSTORIES: "/webstories/admin/publishedWebStoriesCount",
    GENERATE_REPORT: "/generate/admin/generateReport",
  }
};