import { ColorPickerActionTypes } from "../action-types";

const initialState = {
  selectedHexColor: "#FFFFFF",
  r: "",
  g: "",
  b: "",
  a: "",
};

const colorPickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ColorPickerActionTypes.GET_SELECTED_HEX_COLOR:
      return Object.assign({}, state, {
        selectedHexColor: action.payload.selectedHexColor,
        r: action.payload.r,
        g: action.payload.g,
        b: action.payload.b,
        a: action.payload.a,
      });

    default:
      return state;
  }
};

export default colorPickerReducer;
