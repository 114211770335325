import AppConstants from '../constants';
import AxiosInstance from './http.handlers';
import axios from 'axios';
import { OLD_BASE_PATH } from '../constants/workerConstants';

export const GetAllLiveStream = async () => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.LIVE.GET_ALL_LIVE_STREAM
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetCMSAllLiveStream = async () => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.LIVE.GET_CMS_LIVESTREAM_VIMEO
  )
    .then((response) => response)
    .catch((error) => error);
};

export const PublishLiveStream = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LIVE.PUBLISH_LIVE_STREAM,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UnPublishLiveStream = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LIVE.PUBLISH_LIVE_STREAM_VIMEO,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const EditTitle = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.LIVE.EDIT_TITLE,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};

// Vimeo Stream

export const TagMatchAtVimeo = async (body, headers) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VIMEO.TAG_MATCH_AT_VIMEO}`,

    body,

    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetAllVimeoLiveStream = async () => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.GET_ALL_VIMEO_STREAMS
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetVimeoTitle = async (matchId) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIMEO.GET_VIMEO_TITLE}/${matchId}`
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetCMSAllVimeoLiveStream = async () => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.GET_ALL_VIMEO_STREAMS_CMS
  )
    .then((response) => response)
    .catch((error) => error);
};

export const PublishVimeoLiveStream = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.PUBLISH_VIMEO_STREAM,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddVimeoURL = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.ADD_VIMEO_URL,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddVimeoImages = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.ADD_VIMEO_Images,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const GetVimeoURL = async (event_id) => {
  return await AxiosInstance.get(
    `${AppConstants.WorkerConstants.API_ROUTES.VIMEO.GET_VIMEO_URL}/${event_id}`
  )
    .then((response) => response)
    .catch((error) => error);
};
export const TagHomeFeature = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.TAG_HOME_OR_FEATURE,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const TagFeature = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.TAG_FEATURE,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UnPublishVimeoLiveStream = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.UNPUBLISH_VIMEO_STREAM,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const EditTitleVimeo = async (body) => {
  return await AxiosInstance.post(
    AppConstants.WorkerConstants.API_ROUTES.VIMEO.UPDATE_VIMEO_STREAM_TITLE,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
