export const VIDEOS_LIST = 'VIDEOS_LIST';
export const PLAY_VIDEO = 'PLAY_VIDEO';
export const PAUSE_VIDEO = 'PAUSE_VIDEO';
export const VIDEOS_LIST_FILTER = 'VIDEOS_LIST_FILTER';
export const RESET_VIDEOS_LIST = 'RESET_VIDEOS_LIST';
export const REMOVE_VIDEO_TOGGLE = 'REMOVE_VIDEO_TOGGLE';
export const VIDEO_TOGGLE_STATUS = 'VIDEO_TOGGLE_STATUS';
export const S3_VIDEOS_LIST = 'S3_VIDEOS_LIST';
export const SHORTS_VIDEOS_LIST = 'SHORTS_VIDEOS_LIST';
export const NUMBER_OF_PAGES_VIDEOS = 'NUMBER_OF_PAGES_VIDEOS';
export const NUMBER_OF_PAGES_SHORTS_VIDEOS = 'NUMBER_OF_PAGES_SHORTS_VIDEOS';
export const CURRENT_PAGE_VIDEOS = 'CURRENT_PAGE_VIDEOS';
export const CURRENT_PAGE_VIDEOS_SHORTS = 'CURRENT_PAGE_VIDEOS_SHORTS';
