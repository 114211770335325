import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

export const GetVersions = async () => {
  return await AxiosInstance.get(
    AppConstants.WorkerConstants.API_ROUTES.VERSION.GET_VERSIONS
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UpdateVersion = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VERSION.UPDATE_VERSION}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
export const AddVersion = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.WorkerConstants.API_ROUTES.VERSION.ADD_VERSION}`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};
