import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { logoutUser } from "../services/auth";
import { Switch, Redirect } from "react-router-dom";
import { GetAllDefaultLeagues } from "../handlers/leagues.handlers";
import { getAllSports } from "../handlers/sports.handler";
import { createBrowserHistory } from "history";
import EditorsChoice from "../pages/Dashboard/EditorsChoice/EditorsChoice";
import InFocus from "../pages/Dashboard/InFocus/InFocus";
import Login from "../pages/Auth/Login";
import Dashboard from "../pages/Dashboard";
import News from "../pages/Dashboard/News";
import NewsManagement from "../pages/Dashboard/News/NewsManagement/NewsManagement";
import TwitterManagement from "../pages/Dashboard/Twitter/TwitterManagement/TwitterManagement";
import NewsInfo from "../pages/Dashboard/News/NewsInfo";
import WhatsNewInfo from "../pages/Dashboard/WhatsNew/WhatsNewInfo/WhatsNewInfo";
import PlayerInfo from "../pages/Dashboard/PlayerManager/PlayerInfo/PlayerInfo";
import TeamInfo from "../pages/Dashboard/TeamManger/TeamInfo";
import TwitterInfo from "../pages/Dashboard/Twitter/TwitterInfo/TwitterInfo";
import AddArticle from "../pages/Dashboard/News/AddArticle";
import UploadNews from "../pages/Dashboard/News/UploadNews";
import OptaGraphics from "../pages/Dashboard/OptaGraphics/OptaGraphics";
import Videos from "../pages/Dashboard/Videos";
import TopVideos from "../pages/Dashboard/Videos/TopVideos/TopVideos";
import UploadVideos from "../pages/Dashboard/Videos/UploadVideos";
import VideosInfo from "../pages/Dashboard/Videos/VideosInfo/VideosInfo";
import Highlights from "../pages/Dashboard/Videos/Highlights/Highlights";
import Version from "../pages/Dashboard/Version/Version";
import Quiz from "../pages/Dashboard/Quiz/Quiz";
import QuizDetailPage from "../pages/Dashboard/Quiz/QuizDetailPage";
import ManageUser from "../pages/Dashboard/UserRoles/Users/ManageUser";
import ManageAuthor from "../pages/Dashboard/ManageAuthor/ManageAuthor";
import { dispatchLoginUser } from "../redux/action-dispatchers/auth.dispatchers";
import { dispatchAllLeagues } from "../redux/action-dispatchers/leagues.dispatchers";
import { dispatchAllSports } from "../redux/action-dispatchers/sports.dispatchers";
import { checkAuthentication, getTokens } from "../services/auth";
import LoggedInRoute from "./loggedInRoutes";
import LoggedOutRoute from "./loggedOutRoutes";
import LeagueManagement from "../pages/Dashboard/LeagueManagement/LeagueManagement";
import videos from "../pages/Dashboard/Youtube/videos";
import SportsTakVideos from "../pages/Dashboard/Videos/SportsTakVideos/SportsTakVideos";
import Twitter from "../pages/Dashboard/Twitter";
import HomeNews from "../pages/Dashboard/HomePage/HomeNews";
import HomeVideoComponent from "../pages/Dashboard/HomePage/HomeVideoComponent";
import ShortsVideosInfo from "../pages/Dashboard/Videos/ShortsVideo/ShortsVideosInfo";
import FeatureCard from "../pages/Dashboard/FeatureCard/FeatureCard";
import HomeFeatureCard from "../pages/Dashboard/HomePage/HomeFeatureCard";
import Banners from "../pages/Dashboard/Banners/Banners";
import PlayerManager from "../pages/Dashboard/PlayerManager/PlayerManagerList";
import TeamManager from "../pages/Dashboard/TeamManger/TeamManager";
import Teams from "../pages/Dashboard/Teams/Teams";
import ManageLive from "../pages/Dashboard/ManageLive/ManageLive";
import UploadArticle from "../pages/Dashboard/News/UploadArticle";
import AdminPanel from "../pages/Dashboard/AdminPanel/AdminPanel";
import UserManager from "../pages/Dashboard/AdminPanel/UserManager";
import ModuleManager from "../pages/Dashboard/AdminPanel/ModuleManager";
import EditPermission from "../pages/Dashboard/AdminPanel/EditPermission";
import ViewRequest from "../pages/Dashboard/AdminPanel/ViewRequest";
import Signup from "../pages/Signup/Signup";
import ForgotPassword from "../pages/Auth/ForgotPassword/ForgotPassword";
import UpdatePassword from "../pages/Auth/UpdatePassword/UpdatePassword";
import Vimeo from "../pages/Dashboard/Vimeo/Vimeo";
import KabaddiSpecial from "../pages/Dashboard/KabaddiSpecial/KabaddiSpecial";
import HomeSpecialVideosComponent from "../pages/Dashboard/KabaddiSpecial/KabaddiSpecialVideoComp";
import YTShorts from "../components/Cards/YTShorts";
import YTShortsIndex from "../components/Cards/YTShortsIndex";
import HomeSpecialEditor from "../pages/Dashboard/KabaddiSpecial/HomeSpecialEditor";
import HomeSpecialEditorVideo from "../pages/Dashboard/KabaddiSpecial/HomeSpecialEditorVideo";
import HomeSpecialEditoComp from "../pages/Dashboard/KabaddiSpecial/HomeSpecialEditorComp";
import OptaShortsIndex from "../pages/Dashboard/OptaShorts/OptaShortsIndex";
import Polls from "../pages/Dashboard/Polls/Polls";
import AddPoles from "../pages/Dashboard/Polls/AddPoles";
import AuthorInfo from "../pages/Dashboard/ManageAuthor/AuthorInfo";
import AddQuiz from "../pages/Dashboard/Quiz/AddQuiz";
import AddShortsVideos from "../pages/Dashboard/Videos/AddShortsVideos";
import AddUser from "../pages/Dashboard/UserRoles/Users/AddUser";
import EditUser from "../pages/Dashboard/UserRoles/Users/EditUser";
import LandingPage from "../pages/LandingPage/LandingPage";
import UploadGraphics from "../pages/Dashboard/OptaGraphics/UploadGraphics";
import EditInsight from "../components/Accordion/OptaGraphicItem/EditInsight";
import HomeLiveBlog from "../pages/Dashboard/HomePage/HomeLiveBlog";
import TwitterTag from "../pages/Dashboard/TwitterTag/TwitterTag";
import Purge from "../pages/Dashboard/Purge/Purge"; // Purge Component
import TwitterList from "../pages/Dashboard/TwitterTag/TwitterList";
import CreateTwitterContent from "../pages/Dashboard/TwitterTag/CreateTwitterContent";
import SearchTagTwitter from "../pages/Dashboard/TwitterTag/SearchTagTwitter";
import TeamPlayerNotification from "../pages/Dashboard/TeamPlayerNotification/TeamPlayerNotification";
import ProductivityReportNews from "../pages/Dashboard/ProductivityReportNews/ProductivityReportNews";
import ProductivityReportVideos from "../pages/Dashboard/ProductivityReportVideos/ProductivityReportVideos";
import ProductivityDetailNews from "../pages/Dashboard/ProductivityReportNews/ProductivityDetailNews";
import ProductivityDetailVideos from "../pages/Dashboard/ProductivityReportVideos/ProductivityDetialVideos";
import TwitterVideosList from "../pages/Dashboard/TwitterTag/TwitterVideosList";
import EditTwitterVideos from "../pages/Dashboard/TwitterTag/EditTwitterVideos";
import Axios from "axios";
import EditTwitterContent from "../pages/Dashboard/TwitterTag/EditTwitterContent";
import DynamicBannerList from "../pages/Dashboard/DynamicBanner/DynamicBannerList";
import AddDynamicBanner from "../pages/Dashboard/DynamicBanner/AddDynamicBanner";
import AddWhatsNew from "../pages/Dashboard/WhatsNew/AddNew/AddWhatsNew";
import FeaturedContent from "../pages/Dashboard/FeaturedContent/FeaturedContent";
import DynamicBannerDetail from "../pages/Dashboard/DynamicBanner/DynamicBannerDetail";
import { API_BASE_PATH } from "../constants/workerConstants";
import LoginAppConstants from "../constants/LoginAppConstants";
import GoogleAnalytics from "../pages/Dashboard/GoogleAnalyticsManager/GoogleAnalytics";
import WhatsNew from "../pages/Dashboard/WhatsNew/AddNew/WhatsNew";
{/**Twitter Trending News Trends */}
import TwitterTrendingNewsTrendsList from "../pages/Dashboard/TwitterTag/TwitterTrendingTrendsList";
import Report from "../pages/Dashboard/Report/Report.js";
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const AppRoutes = (props) => {
  //...
  const location = useLocation();
  const mHistory = useHistory();
  useEffect(() => {
    const isAuthenticated = checkAuthentication();

    if (isAuthenticated) {
      const data = getTokens();

      dispatchLoginUser({
        isLoggedIn: true,
        authorizationToken: data.accessToken,
        userType: props.userType,
        userData: props.userData,
      });
    }
  }, []);
  const history = createBrowserHistory();

  // useEffect(() => {
  //   props.authReducer.isLoggedIn &&
  //     GetAllDefaultLeagues({
  //       Authorization: props.authReducer.authorizationToken,
  //     }).then((response) => {
  //       if (response.status) {
  //         dispatchAllLeagues(response.data);
  //       }
  //     });
  // }, [props.authReducer]);

  useEffect(() => {
    localStorage.getItem("SESSION_STORAGE_ACCESS_TOKEN ")?.length > 0 &&
      props.authReducer.isLoggedIn &&
      getAllSports({
        Authorization: props.authReducer.authorizationToken,
      }).then((response) => {
        if (
          response.statusCode == 401 &&
          response.message.message == "Invalid cms-access-token"
        ) {
          // alert(`Access token Expired!!! Please Login Again!!!!!!!`);
          // logoutUser();
          // mHistory.push("/");
        } else if (response.status) {
          dispatchAllSports(response.data);
        }
      });

    Axios.get(`${API_BASE_PATH}leagues/leagueWatchList`, {
      headers: { "cms-access-token": accessToken, source: "cms" },
    })
      .then(async (response) => {
        let leagueData = await response.data.data.data;
        dispatchAllLeagues(leagueData);
      })
      .catch((error) => error);
  }, [props.authReducer.authorizationToken, location]);

  return (
    <Switch>
      <LoggedOutRoute path="/" exact={true} component={Login} />

      <LoggedOutRoute path="/signup" exact={true} component={Signup} />
      <LoggedOutRoute
        path="/forgotPassword"
        exact={true}
        component={ForgotPassword}
      />
      <LoggedOutRoute
        path="/updatePassword"
        exact={true}
        component={UpdatePassword}
      />

      <LoggedInRoute path="/dashboard" exact={true} component={Dashboard} />
      <LoggedInRoute path="/news/:id" exact={true} component={NewsInfo} />
      <LoggedInRoute path="/whatsNew/:id" exact={true} component={WhatsNewInfo} />
      <LoggedInRoute path="/player-profile/:id" exact={true} component={PlayerInfo} />
      <LoggedInRoute path="/team-manager/:id" exact={true} component={TeamInfo} />

      <LoggedInRoute
        path="/manage-author/:id"
        exact={true}
        component={AuthorInfo}
      />

      <LoggedInRoute path="/news" component={News} />
      <LoggedInRoute path="/quiz" component={Quiz} />
      <LoggedInRoute path="/quizDetial/:id" component={QuizDetailPage} />
      <LoggedInRoute path="/addQuiz" component={AddQuiz} />
      <LoggedInRoute path="/editor-choice" component={EditorsChoice} />
      <LoggedInRoute path="/infocus" component={InFocus} />
      <LoggedInRoute path="/welcome" component={LandingPage} />
      {/*<LoggedInRoute  path="/admin-panel" exact={true} component={AdminPanel}/>*/}
      <Redirect
        exact
        from="/admin-panel"
        to="/admin-panel/user-manager"
        component={AdminPanel}
      />

      <LoggedInRoute
        path="/admin-panel/user-manager"
        exact={true}
        component={UserManager}
      />
      <LoggedInRoute
        path="/admin-panel/module-manager"
        exact={true}
        component={ModuleManager}
      />
      <LoggedInRoute
        path="/admin-panel/edit-permission"
        exact={true}
        component={EditPermission}
      />
      <LoggedInRoute
        path="/admin-panel/view-request"
        exact={true}
        component={ViewRequest}
      />
      <LoggedInRoute path="/top-news" exact={true} component={NewsManagement} />
      <LoggedInRoute path="/highlights" exact={true} component={Highlights} />
      <LoggedInRoute path="/home-page" exact={true} component={HomeNews} />
      <LoggedInRoute path="/cms-users" exact={true} component={ManageUser} />
      <LoggedInRoute path="/addUser" exact={true} component={AddUser} />
      <LoggedInRoute path="/editUser/:id" exact={true} component={EditUser} />
      <LoggedInRoute path="/twitter" exact={true} component={Twitter} />
      <LoggedInRoute
        path="/dynamicBanner"
        exact={true}
        component={DynamicBannerList}
      />
      <LoggedInRoute
        path="/addDynamicBanner"
        exact={true}
        component={AddDynamicBanner}
      />
      <LoggedInRoute
        path="/addWhatsNew"
        exact={true}
        component={AddWhatsNew}
      />
      <LoggedInRoute
        path="/dynamicBannerDetail/:id"
        exact={true}
        component={DynamicBannerDetail}
      />
      <LoggedInRoute
        path="/home-page-news"
        exact={true}
        component={KabaddiSpecial}
      />
      <LoggedInRoute
        path="/home-page-videos"
        exact={true}
        component={HomeSpecialVideosComponent}
      />

      <LoggedInRoute
        path="/feature-card"
        exact={true}
        component={FeatureCard}
      />
      <LoggedInRoute path="/banners" exact={true} component={Banners} />
      <LoggedInRoute path="/polls" exact={true} component={Polls} />
      <LoggedInRoute
        path="/home-page-feature"
        exact={true}
        component={HomeFeatureCard}
      />
      <LoggedInRoute
        path="/home-editor"
        exact={true}
        component={HomeSpecialEditor}
      />
      <LoggedInRoute path="/live_blog" exact={true} component={HomeLiveBlog} />
      <LoggedInRoute
        path="/home-editor-video"
        exact={true}
        component={HomeSpecialEditoComp}
      />

      <LoggedInRoute path="/teams" exact={true} component={Teams} />

      <LoggedInRoute path="/version" exact={true} component={Version} />
      <LoggedInRoute
        path="/manage-author"
        exact={true}
        component={ManageAuthor}
      />
      <LoggedInRoute path="/twitterTag" exact={true} component={TwitterTag} />
      
      <LoggedInRoute
        path="/teamPlayerNotification"
        exact={true}
        component={TeamPlayerNotification}
      />
      <LoggedInRoute
        path="/productivityReportNews"
        exact={true}
        component={ProductivityReportNews}
      />
      <LoggedInRoute
        path="/player-profile"
        exact={true}
        component={PlayerManager}
      />
      <LoggedInRoute
        path="/google-analytics-manager"
        exact={true}
        component={GoogleAnalytics}
      />
      <LoggedInRoute
        path="/whatsNew"
        exact={true}
        component={WhatsNew}
        />
        <LoggedInRoute
        path="/team-manager"
        exact={true}
        component={TeamManager}
      />
      <LoggedInRoute
        path="/productivityDetailNews/:email"
        exact={true}
        component={ProductivityDetailNews}
      />
      <LoggedInRoute
        path="/productivityReportVideos"
        exact={true}
        component={ProductivityReportVideos}
      />
      <LoggedInRoute
        path="/productivityDetailVideos/:email"
        exact={true}
        component={ProductivityDetailVideos}
      />
      <LoggedInRoute path="/twitterList" exact={true} component={TwitterList} />
      <LoggedInRoute
        path="/twitterVideosList"
        exact={true}
        component={TwitterVideosList}
      />
      <LoggedInRoute
        path="/twitterVideosDetail/:id"
        exact={true}
        component={EditTwitterVideos}
      />
      <LoggedInRoute
        path="/searchTagTwitter/:tag"
        exact={true}
        component={SearchTagTwitter}
      />
      <LoggedInRoute
        path="/createTwitterList"
        exact={true}
        component={CreateTwitterContent}
      />
      <LoggedInRoute
        path="/editTwitterList/:id"
        exact={true}
        component={EditTwitterContent}
      />
      <LoggedInRoute
        path="/twitter-management"
        exact={true}
        component={TwitterManagement}
      />

      <LoggedInRoute path="/twitter/:id" exact={true} component={TwitterInfo} />
      <LoggedInRoute path="/videos" exact={true} component={Videos} />
      <LoggedInRoute path="/shorts" exact={true} component={OptaShortsIndex} />
      <LoggedInRoute
        path="/shorts/:id"
        exact={true}
        component={ShortsVideosInfo}
      />
      <LoggedInRoute
        path="/st-videos"
        exact={true}
        component={SportsTakVideos}
      />
      <LoggedInRoute path="/top-videos" exact={true} component={TopVideos} />
      <LoggedInRoute
        path="/HomeVideoComponent"
        exact={true}
        component={HomeVideoComponent}
      />
      <LoggedInRoute path="/add-videos" exact={true} component={UploadVideos} />
      <LoggedInRoute
        path="/add-shorts"
        exact={true}
        component={AddShortsVideos}
      />
      <LoggedInRoute
        path="/featured-content"
        exact={true}
        component={FeaturedContent}
      />
      <LoggedInRoute path="/add-articles" exact={true} component={AddArticle} />
      <LoggedInRoute path="/addpolls" exact={true} component={AddPoles} />
      {/* <LoggedInRoute
        path="/uploadArticle"
        exact={true}
        component={UploadArticle}
      /> */}
      {/* <LoggedInRoute path="/uploadNews" exact={true} component={UploadNews} /> */}
      <LoggedInRoute
        path="/videos/:id/:isyoutube"
        exact={true}
        component={VideosInfo}
      />

      <LoggedInRoute
        path="/opta-graphics"
        exact={true}
        component={OptaGraphics}
      />
      <LoggedInRoute
        path="/opta-graphics/:id"
        exact={true}
        component={EditInsight}
      />
      <LoggedInRoute
        path="/upload-graphics"
        exact={true}
        component={UploadGraphics}
      />
      <LoggedInRoute
        path="/manage-league"
        exact={true}
        component={LeagueManagement}
      />
      {/**Trending News */}
      <LoggedInRoute path="/trendingNewsList" exact={true} component={TwitterTrendingNewsTrendsList} />
      {/** */}
      <LoggedInRoute path="/manage-live" exact={true} component={ManageLive} />
      <LoggedInRoute path="/vimeo" exact={true} component={Vimeo} />
      {/**Purge Component */}
      <LoggedInRoute path="/purge" exact={true} component={Purge} />
      <LoggedInRoute path="/report" exact={true} component={Report} />
      <LoggedInRoute path="/youtube" exact={true} component={videos} />
    </Switch>
  );
};
const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    userType: state.authReducer.userType,
    userData: state.authReducer.userData,
  };
};
export default connect(mapStateToProps)(AppRoutes);
