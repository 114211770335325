import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  DeleteTwitterContent,
  GetTwitterTagsForFilter,
} from "../../../handlers/twitter.handlers";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { dispatchTwitterMenuTxt } from "../../../redux/action-dispatchers/twitterNative.dispatchers";
import { dispatchSelectedSportsTagging } from "../../../redux/action-dispatchers/sports.dispatchers";

import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const TwitterList = () => {
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setTwitterID] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: "",
    message: "",
  });

  const getTwitterDocs = () => {
    setIsLoading(true);
    GetTwitterTagsForFilter()
      .then((response) => {
        setIsLoading(false);
        setDocs(response.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getTwitterDocs();
    window.scrollTo(0, 0);
  }, []);
  const mHistory = useHistory();
  const onView = (tag) => {
    mHistory.push(`/searchTagTwitter/${tag}`);
  };
  const showModalForPublish = (id) => {
    let title = "Delete Twitter?";
    let message = "Are you sure to continue?";

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };
  const onClick1= () =>{
    dispatchSelectedSportsTagging({
      selectSports: "",
      selectedSportsTagging: "",
    });
  }
  const onClose = () => {
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };
  const onSuccess = () => {
    DeleteTwitterContent({ _id: id })
      .then((res) => {
        if (res) {
          // alert("Twitter Deleted Successfully");
          getTwitterDocs();
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => error);
    setConfirmationModal({
      message: "",
      show: false,
      title: "",
    });
  };

  const handleEdit = (id) => {
    mHistory.push(`/editTwitterList/${id}`);
  };
  const handleBack = () => {
    mHistory.goBack();
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 60 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                "bg-white"
              }
              style={{ height: "100%" }}
            >
              <div className="rounded-t mb-0 px-4 py-1 border-0">
                <div
                  className=" pb-4"
                  style={{
                    cursor: "pointer",
                    marginLeft: "-60px",
                    width: "140px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleBack}
                >
                  <ArrowBackIcon />
                </div>
                <div className="flex flex-wrap flex-col">
                  <div className="twitterList-button-container">
                    <Link to="/createTwitterList">
                      <Button variant="contained" color="primary" onClick={onClick1}>
                        Create New Content Wall
                      </Button>
                    </Link>
                    <input
                      type="text"
                      placeholder="Search content wall"
                      className="twitterList-input"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "60px",
                    }}
                  >
                    {docs?.map((tags) => (
                      <div
                        className="relative flex flex-col min-w-0 w-full break-words
                    shadow-lg shadow-ug rounded twitterList-content"
                      >
                        <div className="twitterList-wrapper">
                          <div className="twitterList-container">
                            <h1
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                onView(tags.tag);
                                dispatchTwitterMenuTxt(tags.menuTxt);
                              }}
                            >
                              {tags.menuTxt}
                            </h1>
                          </div>
                          <div className="twitterList-list-button">
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => {
                                handleEdit(tags._id);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              color="secondary"
                              variant="text"
                              onClick={() => {
                                setTwitterID(tags._id);
                                showModalForPublish();
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ textAlign: "center", padding: 10 }}>
                    <SyncLoader
                      color="#23A5E0"
                      loading={isLoading}
                      css={override}
                      size={12}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwitterList;
