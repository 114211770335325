import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import PreviewModal from "../../../components/PreviewModal";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import DynamicDropdownLocationDropdown from "../../../components/Dropdowns/DynamicDropdownLocationDropdown";
import { UploadDynamicBanner } from "../../../handlers/dynamicBanner.handers";
import DynamicBannerTypeDropdown from "../../../components/Dropdowns/DynamicBannerTypeDropdown";
import SportsTaggingDropdown from "../../../components/Dropdowns/SportsTaggingDropdown";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  dispatchAllSports,
  dispatchSelectedSportsTagging,
} from "../../../redux/action-dispatchers/sports.dispatchers";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeagueTagging,
} from "../../../redux/action-dispatchers/leagues.dispatchers";
import MatchesDropdownTagging from "../../../components/Dropdowns/MatchesDropdownTagging";
import LeagueDropdownTagging from "../../../components/Dropdowns/LeagueDropdownTagging";
import {
  dispatchAllMatchesTag,
  dispatchNumberOfMatchesPages,
} from "../../../redux/action-dispatchers/matches.dispatcher";
import { dispatchHexColor } from "../../../redux/action-dispatchers/colorPicker.dispatchers";
import { GetMatchesForTagging } from "../../../handlers/matches.handlers";
import { GetAllTagLeagues } from "../../../handlers/leagues.handlers";
import { connect } from "react-redux";
import UnsavedChangesWarning from "../../../components/AlertModal/UnsavedChangesWarning";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
import { dispatchSelectedDynamicType } from "../../../redux/action-dispatchers/dynamicBanner.dispatcher";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const AddDynamicBanner = (props) => {
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: "",
    thumbnail: "",
  });
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [getLocation, setGetLocation] = useState("Home");
  const [getType, setGetType] = useState("league");
  const [isTypeMatch, setIsTypeMatch] = useState(false);
  const [hexColor, setHexColor] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const [formData, setFormData] = useState({
    sports: "",
    overview: "",
    league: "",
    match_id: "",
  });
  const mHistory = useHistory();
  const handleBack = () => {
    mHistory.goBack();
  };
  useEffect(() => {
    // Create a new Date object and add 5 hours to it
    const newEndDate = new Date(endDate.getTime() + 12 * 60 * 60 * 1000);
    // Update the state with the new end date
    setEndDate(newEndDate);
  }, []); // This effect runs once when the component mounts

  // CKEditor image upload

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
              Axios.post(
                `${API_URL}/${UPLOAD_ENDPOINT}`,
                { file: reader.result },
                {
                  headers: { "cms-access-token": accessToken, source: "cms" },
                }
              )
                // .then((res) => res.json())
                .then((res) => {
                  resolve({
                    default: `${res.data.data}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            };
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  // ckeditor image upload end

  const PreviewModalWrapper = () => (
    <PreviewModal
      imageData={previewModal.imageData}
      title="Upload Image"
      message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
      onSuccess={onPreviewSuccess}
      onClose={onPreviewClose}
    />
  );
  const onPreviewSuccess = () =>
    setPreviewModal({
      imageData: previewModal.imageData,
      file: previewModal.file,
      show: false,
    });
  const onPreviewClose = () =>
    setPreviewModal({ imageData: "", file: "", show: false });

  const onImageFileSelect = (event) => {
    let file = event.target.files[0];
    event.target.value = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height === 368 && width === 1616) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
            file: file,
          });
        } else {
          alert(
            `Image width and height must be 1616 x 368 px. Your Image width and height is ${width} x  ${height} px `
          );
          return false;
        }
      };
    };
    reader.onerror = function (error) { };
    setSelectedImage(reader.result);

    setIsUnsaved();
  };
  const onBannerLocationChange = (value) => {
    setGetLocation(value);
  };
  const onBannerTypeChange = (value) => {
    if (value == "match") {
      setIsTypeMatch(true);
    } else {
      setIsTypeMatch(false);
    }
    setGetType(value);
  };
  const onSportChange = (value) => {
    setFormData({ ...formData, sports: value });
    dispatchSelectedSportsTagging({
      selectSports: value,
      selectedSportsTagging: value,
    });
    dispatchAllLeaguesTag([]);
    dispatchSelectedLeagueTagging({ selectedLeagueTag: "" });
  };
  const onLeagueChange = (league) => {
    setFormData({ ...formData, league });
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: league,
      selectedLeagueFilterTag: league,
    });
    // getAllTaggedMatches();
  };
  const onMatchesChange = (value) => {
    setFormData({ ...formData, match_id: value });
  };
  const getAllTaggedLeague = () => {
    if (props.selectSports !== undefined) {
      setIsLoading(true);
      GetAllTagLeagues(
        props.selectSports,
        props.leagueSearch == "" ? "search" : props.leagueSearch
      )
        .then((response) => {
          if (
            response.statusCode == 401 &&
            response.message.message == "Invalid cms-access-token"
          ) {
            alert(`Access token Expired!!! Please Login Agian!`);
            logoutUser();
            mHistory.push("/");
          } else if (response.status) {
            dispatchAllLeaguesTag(response.data);
            setIsLoading(false);
          } else {
            dispatchAllLeaguesTag([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          dispatchAllLeaguesTag([]);
        });
    }
  };
  useEffect(() => {
    dispatchAllMatchesTag([]);
    dispatchAllSports([]);
    dispatchSelectedSportsTagging("All");
  }, []);
  useEffect(() => {
    // const timer = setTimeout(() => {
    getAllTaggedLeague();
    // }, 1000);
    // return () => clearTimeout(timer);
  }, [props.leagueSearch, props.selectSports]);
  const getAllTaggedMatches = () => {
    GetMatchesForTagging(
      props.selectedLeagueTag,
      props.selectSports,
      props.matchesSearch == "" ? "search" : props.matchesSearch,
      props.matchesPage,
      20
    )
      .then((response) => {
        if (response.status) {
          dispatchNumberOfMatchesPages(response.data.totalPages);
          dispatchAllMatchesTag(response.data.docs);
        } else {
          dispatchAllMatchesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllMatchesTag([]);
      });
  };
  useEffect(() => {
    // dispatchSelectedDynamicType("league");
    getAllTaggedMatches();
  }, [
    props.selectSports,
    props.selectedLeagueTag,
    props.matchesPage,
    props.matchesSearch,
  ]);
  useEffect(() => {
    dispatchSelectedSportsTagging({
      selectSports: "All",
      selectedSportsTagging: "All",
    });
  }, []);
  const onAddBanner = () => {
    const body = {
      overview: formData.overview,
      sports: formData.sports,
      location: getLocation,
      bgColor: props.selectedHexColor,
      shortTitle: shortTitle,
      bannerType: getType,
      leagueForMatch: formData.league,
      taggedWithId:
        formData.match_id !== "" ? formData.match_id : formData.league,
      thumbnail:
        previewModal.imageData == undefined ? "" : previewModal.imageData,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };
    // console.log('body', body)
    if (startDate > endDate) {
      alert("Start date cannot be greater than End Date");
      return;
    }
    if (formData.sports == "") {
      alert("Please Add Sports");
      return;
    } if (formData.league == "") {
      alert("Please Add League");
      return;
    } if (getType == "match" && formData.match_id == "") {
      {
        alert("Please add Match");
        return;
      }
    } if (previewModal.imageData == "") {
      alert("Please add image.");
      return;
    } if (shortTitle == "") {
      alert("Please add title");
      return;
    } if (getType == "league" && props.selectedHexColor == "") {
      alert("Please add Background Color");
      return;
    } if (getType == "league" && formData.overview == "") {
      alert("Please add overview");
      return;
    } else {
      setIsLoading(true);
      setIsButtonDisabled(true);
      UploadDynamicBanner(body)
        .then((response) => {
          setIsLoading(true);
          if (response.status) {
            if (!response.data) {
              setIsLoading(false);
              alert(`Banner already exists for selected time slot. Please create banner for this league/match for different time slot.`);
              setIsButtonDisabled(false);
            } else {
              setIsLoading(false);
              alert("Added dynamic banner");
              mHistory.push("/dynamicBanner");
            }
          } else {
            alert("Something went wrong..");
            setIsLoading(false);
          }
        })
        .catch((error) => error);
    }
  };
  // color picker change
  const onColorChange = (color) => {
    dispatchHexColor({
      selectedHexColor: color?.hex,
      r: color.rgb.r,
      g: color.rgb.g,
      b: color.rgb.b,
      a: color.rgb.a,
    });
  };
  // color picker change end
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div className="relative bg-blueGray-100">
              <div
                className={
                  "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                  "bg-white"
                }
              >
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                      <div
                        className=" pb-4"
                        style={{
                          cursor: "pointer",
                          // marginLeft: "20px",
                          width: "30px",
                        }}
                        onClick={handleBack}
                      >
                        <ArrowBackIcon />
                      </div>
                      <h3
                        className={
                          "font-semibold text-lg " + "text-blueGray-700"
                        }
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {"Add Banner"}
                        {/* <div
                          className="relative w-full  max-w-full flex-grow flex-1"
                          style={{ textAlign: "right" }}
                        >
                          <span style={{ marginLeft: 5 }}>
                            <Link to="/dynamicBanner">
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginBottom: "1.5%" }}
                              >
                                Dynamic Banner
                              </Button>
                            </Link>
                          </span>
                        </div> */}
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="block w-full overflow-x-auto addQuizStyle"
                  style={{ padding: "20px" }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyCotent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "220px",
                          marginBottom: "20px",
                        }}
                      >
                        <label className="addArticle_label">
                          Please Select Type of Banner*
                        </label>
                        <DynamicBannerTypeDropdown
                          selectedValue={getType}
                          onChange={onBannerTypeChange}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // marginLeft:"50px",
                        // marginRight:"50px",
                      }}
                    >
                      <div style={{ marginBottom: "30px", padding: 0 }}>
                        <label className="addArticle_label">
                          Select Start Date:
                        </label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          showTimeInput
                          dateFormat="dd/MM/yyyy HH:mm"
                          minDate={today} // Set the minimum date to today
                        />
                      </div>
                      <div style={{ marginBottom: "30px", padding: 0 }}>
                        <label className="addArticle_label">
                          Select End Date :
                        </label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          showTimeInput
                          dateFormat="dd/MM/yyyy HH:mm"
                          minDate={today} // Set the minimum date to today
                        />
                      </div>
                    </div>
                    <hr className="my-0 md:min-w-full" />
                    <h1 style={{ fontWeight: "bolder", color: "tomato" }}>
                      Please Select Sports*:
                    </h1>
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <SportsTaggingDropdown
                        onChange={onSportChange}
                        bannerType={getType}
                        isDynamicBannerPage={true}
                      />
                    </div>

                    {props.selectSports !== undefined && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <h1 style={{ fontWeight: "bolder", color: "tomato" }}>
                          Please Select League:
                        </h1>
                        <LeagueDropdownTagging
                          onChange={onLeagueChange}
                          isDynamicBannerPage={true}
                        />
                      </div>
                    )}
                    {
                      <>
                        {props.selectSports !== undefined &&
                          props.selectedLeagueTag !== "" &&
                          props.matchesTag.length !== 0 &&
                          isTypeMatch && (
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <h1
                                style={{
                                  fontWeight: "bolder",
                                  color: "tomato",
                                }}
                              >
                                Please Select a match if Banner type is Match*:
                              </h1>
                              <MatchesDropdownTagging
                                onChange={onMatchesChange}
                              />
                            </div>
                          )}
                      </>
                    }
                    {
                      <>
                        {props.selectSports !== undefined &&
                          props.selectedLeagueTag !== "" &&
                          props.matchesTag.length == 0 &&
                          isTypeMatch && (
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <h1
                                style={{
                                  fontWeight: "bolder",
                                  color: "tomato",
                                }}
                              >
                                No matches available for the selected league, Select other league
                              </h1>
                            </div>
                          )}
                      </>
                    }
                    <hr className="my-3 md:min-w-full" />
                    <div className="addArticle_label">
                      Please upload image*:
                    </div>
                    <div>
                      {previewModal.imageData && (
                        <img
                          src={previewModal.imageData}
                          style={{
                            width: 640,
                            height: 100,
                            border: "2px solid gray",
                            borderRadius: "12px",
                          }}
                        />
                      )}
                      <input
                        type="file"
                        name="file"
                        onChange={onImageFileSelect}
                        style={{
                          backgroundColor: "#000",
                          height: 60,
                          width: 60,
                          position: "absolute",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                      />{" "}
                      <i
                        className="fas fa-camera fa-2x"
                        style={{
                          margin: 8,
                          color: "#555",
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                    {/* <hr className="my-0 md:min-w-full" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                        margin: "20px 0",
                      }}
                    >
                      <label className="addArticle_label">Location*</label>
                      <DynamicDropdownLocationDropdown
                        selectedValue={getLocation}
                        onChange={onBannerLocationChange}
                      />
                    </div> */}

                    <hr className="my-3 md:min-w-full" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                        // margin: "20px 0",
                      }}
                    >
                      <label className="addArticle_label">Short Title*:</label>
                      <input
                        type="text"
                        placeholder="Short Title*"
                        value={shortTitle}
                        style={styles.inputBox}
                        onChange={(e) => setShortTitle(e.target.value)}
                      />
                    </div>
                    {isTypeMatch == false && (
                      <>
                        {" "}
                        <hr className="py-3 pt-3 md:min-w-full" />
                        <label className="addArticle_label">
                          Please Select a background color
                        </label>
                        <div style={{ margin: "20px  0 40px 40px" }}>
                          <ColorPicker onChange={onColorChange} />
                          Click 👆👆 here to pick a color.
                        </div>
                        {/* <input
                          type="text"
                          placeholder="Paste Hex color code here *"
                          value={hexColor}
                          style={styles.inputBox}
                          onChange={(e) => setHexColor(`#${e.target.value}`)}
                        /> */}
                        <hr className="my-3 md:min-w-full" />
                        <label className="addArticle_label">Overview*</label>
                        <CKEditor
                          style={{ marginTop: "10px" }}
                          onChange={(event, editor) => {
                            setFormData({
                              ...formData,
                              overview: editor.getData(),
                            });
                            setIsUnsaved();
                          }}
                          editor={Editor}
                          data={formData.overview}
                          config={{
                            extraPlugins: [uploadPlugin],
                            height: 500,
                            link: {
                              addTargetToExternalLinks: true,
                              defaultProtocol: "https://",
                              decorators: {
                                toggleDownloadable: {
                                  mode: "manual",
                                  label: "Downloadable",
                                  attributes: {
                                    download: "file",
                                  },
                                },
                                openInNewTab: {
                                  mode: "manual",
                                  label: "Open in a new tab",
                                  defaultValue: true,
                                  attributes: {
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                  },
                                },
                              },
                            },
                            heading: {
                              options: [
                                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                  { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                  { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                  { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                  { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                  { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                  { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                              ]
                          }
                          }}
                        />
                        <SyncLoader
                          color="#23A5E0"
                          css={override}
                          size={12}
                          loading={isLoading}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="col-lg-12"
                  style={{ margin: "20px auto", marginBottom: "40px" }}
                >
                  <button
                    disabled={isButtonDisabled}
                    onClick={() => {
                      onAddBanner();
                    }}
                    className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  >
                    Add Banner
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const styles = {
  inputBox: {
    width: "500px",
    height: "30px",
    marginTop: "10px",
    borderRadius: "5px",
    marginBottom: "10px",
  },
};
const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
    leaguesTag: state.leaguesReducer.leaguesTag,
    matchesTag: state.matchesReducer.matchesTag,
    leagues: state.leaguesReducer.leagues,
    leagueSearch: state.leaguesReducer.leagueSearch,
    matchesSearch: state.matchesReducer.matchesSearch,
    teamsSearch: state.teamsReducer.teamsSearch,
    matchesPage: state.matchesReducer.matchesPage,
    sports: state.sportsReducer.sports,
    selectSports: state.sportsReducer.selectSports,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
    selectedSports: state.sportsReducer.selectedSports,
    selectedHexColor: state.colorPickerReducer.selectedHexColor,
  };
};
export default connect(mapStateToProps)(AddDynamicBanner);
