import React from 'react'
import AdminSidebar from './AdminSideBar'
import PermissionTable from './PermissionTable'
import ViewRequestInfo from './ViewRequestInfo'


const ViewRequest = (props) => {
    return (
        <>
            <AdminSidebar/>
            <div
                className="relative md:ml-64 bg-blueGray-100"
                style={{ paddingTop: 10 }}
            >
            <div className="flex flex-wrap">
            <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
               <ViewRequestInfo color="light"/>
             </div>
             </div>
             </div>
        </>
    )
}

export default ViewRequest
