import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import PreviewModal from "../../../components/PreviewModal";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SyncLoader from "react-spinners/SyncLoader";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SportsTaggingDropdown from "../../../components/Dropdowns/SportsTaggingDropdown";
import LeagueDropdownTagging from "../../../components/Dropdowns/LeagueDropdownTagging";
import { GetAllTagLeagues } from "../../../handlers/leagues.handlers";
import {
  dispatchAllLeaguesTag,
  dispatchSelectedLeagueTagging,
} from "../../../redux/action-dispatchers/leagues.dispatchers";
import {
  dispatchAllSports,
  dispatchSelectedSportsTagging,
} from "../../../redux/action-dispatchers/sports.dispatchers";
import UnsavedChangesWarning from "../../../components/AlertModal/UnsavedChangesWarning";

import {
  GetDynamicBannerDetail,
  UpdateDynamicBanner,
} from "../../../handlers/dynamicBanner.handers";
import DynamicBannerTypeDropdown from "../../../components/Dropdowns/DynamicBannerTypeDropdown";
import DynamicDropdownLocationDropdown from "../../../components/Dropdowns/DynamicDropdownLocationDropdown";
import { css } from "@emotion/core";
import { dispatchSelectedDynamicType } from "../../../redux/action-dispatchers/dynamicBanner.dispatcher";
import { dispatchHexColor } from "../../../redux/action-dispatchers/colorPicker.dispatchers";
import TextEditor from "../../../components/TextEditor";
import {
  dispatchAllMatchesTag,
  dispatchNumberOfMatchesPages,
  dispatchSelectedMatchesTagging,
} from "../../../redux/action-dispatchers/matches.dispatcher";
import MatchesDropdownTagging from "../../../components/Dropdowns/MatchesDropdownTagging";
import { GetMatchesForTagging } from "../../../handlers/matches.handlers";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const DynamicBannerDetail = (props) => {
  let fileInput = React.useRef();
  const today = new Date();
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(true);
  const [variants, setVariants] = useState([]);
  const [getType, setGetType] = useState("");
  const [bannerId, setBannerId] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [isTypeMatch, setIsTypeMatch] = useState(false);
  const [getLocation, setGetLocation] = useState("");
  const [showMedia, setShowMedia] = useState();
  const [mediaThumbnail, setMediaThumbnail] = useState();
  const [title, setShortTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [seoTitle, setSEOTitle] = useState("");
  const [seoSlug, setSEOSlug] = useState("");
  const [seoDescription, setSEODescription] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [editShortTitle, setEditShortTitle] = useState(false);
  const [editSEOTitle, setEditSEOTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editSEODescription, setEditSEODescription] = useState(false);
  const [editKeywords, setEditKeyWords] = useState(false);
  const [editSEOSlug, setEditSEOSlug] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(new Date());
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(startDate));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(endDate));
  const [previewModal, setPreviewModal] = useState({
    show: false,
    imageData: "",
    height: "",
    width: "",
    file: "",
  });
  const [overview, setOverView] = useState("");
  const [sports, setSports] = useState("");
  const [league, setLeague] = useState("");
  const [changeLeague, setChangeLeague] = useState(false);
  const [changeSport, setChangeSport] = useState(false);
  const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
  const [docs, setDocs] = useState();
  const [bannerType, setBannerType] = useState("");
  const [matchId, setMatchId] = useState("");

  useEffect(()=>{
    setSelectedDate(new Date(startDate))
    setSelectedEndDate(new Date(endDate))
  }, [endDate, startDate])

  const handleBack = () => {
    history.goBack();
  };
  let tid = parseInt(id);
  const getBannerDetails = () => {
    GetDynamicBannerDetail(id, seoSlug)
      .then((response) => {
        console.log('response', response.data.bannerDetails)
        const dynamicBanner = response.data.bannerDetails;
        setBannerId(dynamicBanner?._id);
        setImgUrl(dynamicBanner?.imgUrl);
        setDocs(response.data);
        setShortTitle(dynamicBanner?.shortTitle);
        setSEOSlug(dynamicBanner?.seo_slug);
        setBannerType(dynamicBanner?.bannerType);
        setGetType(dynamicBanner?.bannerType);
        dispatchSelectedDynamicType(dynamicBanner?.bannerType);
        setMatchId(dynamicBanner?.taggedWithId);
        setGetLocation(dynamicBanner?.location);
        setOverView(dynamicBanner?.overview);
        setSports(dynamicBanner?.sports);
        setStartDate(dynamicBanner?.startDate);
        setEndDate(dynamicBanner?.endDate);
        dispatchSelectedLeagueTagging({
          selectedLeagueTag:
            dynamicBanner.bannerType == "league"
              ? dynamicBanner.taggedWithId
              : dynamicBanner.leagueForMatch,
          selectedLeagueFilterTag:
            dynamicBanner.bannerType == "league"
              ? dynamicBanner.taggedWithId
              : dynamicBanner.leagueForMatch,
        });
        dispatchSelectedSportsTagging({
          selectSports: dynamicBanner.sports,
          selectedSportsTagging: dynamicBanner.sports,
        });
        setLeague(
          dynamicBanner.bannerType == "match"
            ? dynamicBanner.leagueForMatch
            : dynamicBanner.taggedWithId
        );

        dispatchSelectedMatchesTagging({
          selectedMatchesTag:
            dynamicBanner.bannerType == "match" && dynamicBanner.taggedWithId,
          selectedMatchesFilterTag:
            dynamicBanner.bannerType == "match" && dynamicBanner.taggedWithId,
        });
        const without_rgba = hexToRgbA(dynamicBanner.bgColor).replace(
          "rgba",
          ""
        );
        const without_Braces = without_rgba.replace("(", "");
        const toConvertIntoArray = without_Braces.replace(")", "");
        const rgba_Array = toConvertIntoArray.split(",");
        const r = rgba_Array[0];
        const g = rgba_Array[1];
        const b = rgba_Array[2];
        const a = rgba_Array[3];
        dispatchHexColor({
          selectedHexColor: dynamicBanner.bgColor,
          r: r,
          g: g,
          b: b,
          a: a,
        });
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getBannerDetails();
  }, []);
  // console.log("form data. match id", props.selectedMatchesTag);
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height === 368 && width === 1616) {
          setPreviewModal({
            imageData: reader.result,
            show: true,
            file: file,
          });
          setImgUrl(reader.result);
        } else {
          alert(
            `Image width and height must be 1616 x 368 px. Your Image width and height is ${width} x  ${height} px `
          );
          return false;
        }
      };
      // setSelectedImage(reader.result)
      // setShowCropper(true);
    };
    reader.onerror = function (error) { };
  };
  const onPreviewClose = () =>
    setPreviewModal({ imageData: "", file: "", show: false });
  const onPreviewSuccess = () => {
    setMediaThumbnail(previewModal.imageData);
    setPreviewModal({
      imageData: previewModal.imageData,
      show: false,
      file: previewModal.file,
    });
    setIsLoading(true);
    const body = {
      thumbnail: previewModal.imageData,
      bannerId: bannerId,
      sports: sports == "" ? props.selectSports : sports,
      taggedWithId: getType == "match" ? matchId : league,
      overview: overview,
      location: getLocation,
      bgColor: props.selectedHexColor,
      shortTitle: title,
      bannerType: getType,
      status: selectedStatus,
      leagueForMatch: league,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };

    UpdateDynamicBanner(body)
      .then((response) => {
        setIsLoading(false);
        if (response.status) {
          alert("Banner Image updated..");
          getBannerDetails();
        } else {
          alert(`Something went wrong`);
        }
      })
      .catch((error) => error);
  };
  const getAllTaggedLeague = () => {
    GetAllTagLeagues(
      props.selectSports,
      props.leagueSearch == "" ? "search" : props.leagueSearch
    )
      .then((response) => {
        if (response.status) {
          dispatchAllLeaguesTag(response.data);
        } else {
          dispatchAllLeaguesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllLeaguesTag([]);
      });
  };
  const onSportSelected = (value) => {
    setChangeSport(false);
    setIsUnsaved();
    setLeague("");
    dispatchSelectedSportsTagging({
      selectSports: value,
      selectedSportsTagging: value,
    });
    dispatchAllLeaguesTag([]);
    dispatchSelectedLeagueTagging({ selectedLeagueTag: "" });

    // getAllTaggedLeague();
    setSports(value);
  };

  const onLeagueChange = (league) => {
    setMatchId("");
    setLeague(league);
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: league,
      selectedLeagueFilterTag: league,
    });
    // getAllTaggedMatches();
  };
  const onMatchesChange = (value) => {
    setMatchId(value);
  };
  const getAllTaggedMatches = () => {
    GetMatchesForTagging(
      props.selectedLeagueTag,
      props.selectSports,
      props.matchesSearch == "" ? "search" : props.matchesSearch,
      props.matchesPage,
      20
    )
      .then((response) => {
        if (response.status) {
          dispatchNumberOfMatchesPages(response.data.totalPages);
          dispatchAllMatchesTag(response.data.docs);
        } else {
          dispatchAllMatchesTag([]);
        }
      })
      .catch((error) => {
        dispatchAllMatchesTag([]);
      });
  };
  useEffect(() => {
    getAllTaggedMatches();
  }, [
    props.selectSports,
    props.selectedLeagueTag,
    props.matchesPage,
    props.matchesSearch,
  ]);
  const onLeagueSelected = (value) => {
    setChangeLeague(false);
    setIsUnsaved();
    dispatchSelectedLeagueTagging({
      selectedLeagueTag: value,
      selectedLeagueFilterTag: value,
    });
  };
  const changeTitle = () => {
    let new_seo_title = title;

    setSEOTitle(new_seo_title.toLowerCase());
  };
  useEffect(() => {
    changeTitle();
  }, [title]);
  const changeSlug = () => {
    let new_seo_slug = seoTitle;
    new_seo_slug = new_seo_slug.replaceAll(" ", "-");
    setSEOSlug(new_seo_slug.toLowerCase());
  };
  //   useEffect(() => {
  //     changeSlug();
  //   }, [seoTitle]);
  useEffect(() => {
    dispatchAllSports([]);
    dispatchSelectedSportsTagging("");
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getAllTaggedLeague();
  }, [props.leagueSearch, props.selectSports]);
  const updateDynamicBanner = () => {
    const body = {
      overview: overview,
      sports: sports == "" ? props.selectSports : sports,
      location: getLocation,
      bgColor: props.selectedHexColor,
      shortTitle: title,
      bannerType: getType,
      taggedWithId: getType == "match" ? matchId : league,
      bannerId: bannerId,
      status: selectedStatus,
      leagueForMatch: league,

      startDate: new Date(selectedDate),
      endDate: new Date(selectedEndDate),
    };
    console.log('asd', body)
    if (body.startDate > body.endDate) {
      alert("Start date cannot be greater than End Date");
      return;
    }
    if (getType == "league" && sports == "") {
      alert("Please Add Sports");
      return;
    } else if (league == "") {
      alert("Please Add League");
      return;
    } else if (getType == "match" && matchId == "") {
      alert("Please add Match");
      return;
    } else if (title == "") {
      alert("Please add title");
      return;
    } else if (getType == "league" && overview == "") {
      alert("Please add overview");
      return;
    } else {
      setIsLoading(true);
      UpdateDynamicBanner(body)
        .then((response) => {
          if (response.status) {
            if (!response.data) {
              setIsLoading(false);
              alert(`Banner already exists for selected time slot. Please create banner for this league/match for different time slot.`);
              setIsButtonDisabled(false);
            } else {
              setIsLoading(false);
              alert("Updated successfully");
              history.push("/dynamicBanner");
              getBannerDetails();
              setEditShortTitle(false);
            }
          } else {
            setIsLoading(false);
            alert(
              `Something went wrong, Please check if you have not entered required fields.`
            );
          }
        })
        .catch((error) => error);
    }
  };
  const onBannerLocationChange = (value) => {
    setGetLocation(value);
  };
  const onBannerTypeChange = (value) => {
    if (value == "match") {
      setIsTypeMatch(true);
    } else {
      setIsTypeMatch(false);
    }
    setGetType(value);
  };
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }
  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
      );
    }
    throw new Error("Bad Hex");
  }
  // CKEditor image upload
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
              Axios.post(
                `${API_URL}/${UPLOAD_ENDPOINT}`,
                { file: reader.result },
                {
                  headers: { "cms-access-token": accessToken, source: "cms" },
                }
              )
                // .then((res) => res.json())
                .then((res) => {
                  resolve({
                    default: `${res.data.data}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            };
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  // ckeditor image upload end
  // color picker change
  const onColorChange = (color) => {
    dispatchHexColor({
      selectedHexColor: color?.hex,
      r: color.rgb.r,
      g: color.rgb.g,
      b: color.rgb.b,
      a: color.rgb.a,
    });
  };
  // color picker change end
  const onStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 50 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div>
              <div
                className=" pb-4"
                style={{
                  cursor: "pointer",
                  marginLeft: "-20px",
                  width: "30px",
                }}
                onClick={handleBack}
              >
                <ArrowBackIcon />
              </div>
              {previewModal.show && (
                <PreviewModal
                  imageData={previewModal.imageData}
                  title="Upload video image"
                  message="Preview of mobile device. This is how the image will appear on mobile devices. Are you sure you want to upload this image?"
                  onSuccess={onPreviewSuccess}
                  onClose={onPreviewClose}
                />
              )}
              <div style={{ display: "inline-block", verticalAlign: "top" }}>
                {isImageUploading ? (
                  <div style={{ textAlign: "center", padding: 10 }}>
                    <div>Uploading Image Please Wait...</div>
                    <div>
                      <PulseLoader
                        color="#23A5E0"
                        loading={true}
                        css={override}
                        size={10}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <img
                        src={imgUrl}
                        style={{
                          width: 640,
                          height: 100,
                          border: "2px solid gray",
                          borderRadius: "12px",
                        }}
                      />
                      <input
                        ref={fileInput}
                        type="file"
                        multiple={false}
                        onChange={onFileSelect}
                        accept="image/x-png,image/jpeg"
                        style={{
                          backgroundColor: "#000",
                          height: 35,
                          width: 40,
                          position: "absolute",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                      />
                      <i
                        className="fas fa-camera fa-2x"
                        style={{
                          margin: 8,
                          color: "#555",
                          cursor: "pointer",
                        }}
                      ></i>
                      Please upload image
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "220px",
                  marginBottom: "20px",
                }}
              >
                <label className="addArticle_label">
                  Please Select Type of Banner*
                </label>
                <DynamicBannerTypeDropdown
                  selectedValue={getType}
                  onChange={onBannerTypeChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // marginLeft:"50px",
                  // marginRight:"50px",
                }}
              >
                <div style={{ marginBottom: "30px", padding: 0 }}>
                  <label className="addArticle_label">
                    Select Start Date :
                  </label>
                  <DatePicker
                    // value={new Date(selectedDate).toLocaleDateString("en-IN", {

                    //   hour: "2-digit",

                    //   minute: "2-digit",

                    // })}

                    selected={selectedDate}
                    onChange={(date) => {
                      console.log(date, "--------");

                      setSelectedDate(date);
                    }}
                    onInvalidChange={() => alert("invalid date")}
                    showTimeInput
                    dateFormat="dd/MM/yyyy HH:mm"
                    minDate={today}
                  />
                </div>
                <div style={{ marginBottom: "30px", padding: 0 }}>
                <label className="addArticle_label">
                    Select End Date :
                  </label>
                <DatePicker

                    // value={new Date(selectedDate).toLocaleDateString("en-IN", {

                    //   hour: "2-digit",

                    //   minute: "2-digit",

                    // })}

                    selected={selectedEndDate}

                    onChange={(date) => {

                      console.log(date, "--------");

                      setSelectedEndDate(date);

                    }}

                    onInvalidChange={() => alert("invalid date")}

                    showTimeInput

                    dateFormat="dd/MM/yyyy HH:mm"

                    minDate={today}

                  />
                  </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "150px",
                  margin: "20px 0",
                }}
              >
                <label className="addArticle_label">Location*</label>
                <DynamicDropdownLocationDropdown
                  selectedValue={getLocation}
                  onChange={onBannerLocationChange}
                />
              </div> */}
              <hr className="my-4 md:min-w-full" />
              {/* seo */}
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditShortTitle(true)}
              >
                *Short Title:{" "}
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editShortTitle ? (
                <div className="py-2 px-2">
                  <input
                    name="story"
                    id="textbox"
                    value={title}
                    onChange={(e) => setShortTitle(e.target.value)}
                    style={{
                      marginTop: 20,
                      borderRadius: "5px",
                      border: "2px solid grey",
                    }}
                  ></input>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditShortTitle(false);
                        setShortTitle("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{title}</div>
              )}
              {getType !== "match" && (
                <>
                  {" "}
                  <hr className="py-3 pt-3 md:min-w-full" />
                  <label className="addArticle_label">
                    Please Select a background color
                  </label>
                  <div style={{ margin: "20px  0 40px 40px" }}>
                    <ColorPicker onChange={onColorChange} />
                    Click 👆👆 here to pick a color.
                  </div>
                  {/* <input
                          type="text"
                          placeholder="Paste Hex color code here *"
                          value={hexColor}
                          style={styles.inputBox}
                          onChange={(e) => setHexColor(`#${e.target.value}`)}
                        /> */}
                  <hr className="my-3 md:min-w-full" />
                  <label className="addArticle_label">Overview*</label>
                  <TextEditor
                    data={overview}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      setOverView(data);
                    }}
                  />
                  <span id="word-count1"></span>
                  <SyncLoader
                    color="#23A5E0"
                    css={override}
                    size={12}
                    loading={isLoading}
                  />
                </>
              )}
              <hr className="my-4 md:min-w-full" />
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <SportsTaggingDropdown
                  onChange={onSportSelected}
                  bannerType={getType}
                  isDynamicBannerPage={true}
                />
              </div>
              {props.selectSports !== undefined && (
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <h1 style={{ fontWeight: "bolder", color: "tomato" }}>
                    Please Select League:
                  </h1>
                  <LeagueDropdownTagging
                    onChange={onLeagueChange}
                    isDynamicBannerPage={true}
                  />
                </div>
              )}
              {
                <>
                  {props.selectSports !== undefined &&
                    props.selectedLeagueTag !== "" &&
                    getType == "match" && (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <h1
                          style={{
                            fontWeight: "bolder",
                            color: "tomato",
                          }}
                        >
                          Please Select a match if Banner type is Match*:
                        </h1>
                        <MatchesDropdownTagging onChange={onMatchesChange} />
                      </div>
                    )}
                </>
              }
              <hr className="my-4 md:min-w-full" />
              {/* <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditSEOTitle(true)}
              >
                *SEO Title:
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editSEOTitle ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="4"
                    cols="80"
                    id="textbox"
                    value={seoTitle}
                    onChange={(e) => setSEOTitle(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditSEOTitle(false);
                        setSEOTitle("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{seoTitle}</div>
              )}
              <hr className="my-4 md:min-w-full" />
              <div
                style={{ fontWeight: "500" }}
                onClick={() => setEditSEOSlug(true)}
              >
                *SEO Slug:
                <i
                  className="fas fa-edit fa-1x"
                  style={{
                    marginLeft: "6px",
                    marginRight: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              {editSEOSlug ? (
                <div className="py-2 px-2">
                  <textarea
                    name="story"
                    rows="4"
                    cols="80"
                    id="textbox"
                    value={seoSlug}
                    onChange={(e) => setSEOSlug(e.target.value)}
                    style={{ marginTop: 20, borderRadius: "5px" }}
                  ></textarea>
                  <div
                    className={"edit__popup-row button-row"}
                    style={{ marginTop: "10px", justifyContent: "flex-start" }}
                  >
                    <i
                      onClick={() => {
                        setEditSEOSlug(false);
                        setSEOSlug("");
                      }}
                      className="far fa-times-circle secondary btn "
                      style={{
                        padding: "9px 10px",
                        background: "#f34a17",
                      }}
                    >
                      Cancel
                    </i>
                  </div>
                </div>
              ) : (
                <div>{seoSlug}</div>
              )} */}
              <SyncLoader
                color="#23A5E0"
                css={override}
                size={12}
                loading={isLoading}
              />
              <h1>*Delete or Publish</h1>
              <select
                style={{
                  // marginLeft: 20,
                  border: "2px solid #acb9ce",
                  borderRadius: 10,
                }}
                value={selectedStatus}
                onChange={onStatusChange}
              >
                <option value={true}>Publish</option>
                <option value={false}>Delete</option>
              </select>
            </div>
            <button
              className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mt-6`}
              type="submit"
              onClick={updateDynamicBanner}
              disabled={isButtonDisabled}
            >
              {"Update"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    matchesTag: state.matchesReducer.matchesTag,
    selectedMatchesTag: state.matchesReducer.selectedMatchesTag,
    sports: state.sportsReducer.sports,
    selectSports: state.sportsReducer.selectSports,
    selectedLeagueTag: state.leaguesReducer.selectedLeagueTag,
    selectedSports: state.sportsReducer.selectedSports,
    selectedLeague: state.leaguesReducer.selectedLeague,
    leagueSearch: state.leaguesReducer.leagueSearch,
    selectedHexColor: state.colorPickerReducer.selectedHexColor,
    r: state.colorPickerReducer.r,
    g: state.colorPickerReducer.g,
    b: state.colorPickerReducer.b,
    a: state.colorPickerReducer.a,
    Authorization: state.authReducer.authorizationToken,
    leaguesTag: state.leaguesReducer.leaguesTag,
    leagues: state.leaguesReducer.leagues,
    matchesSearch: state.matchesReducer.matchesSearch,
    matchesPage: state.matchesReducer.matchesPage,
  };
};
export default connect(mapStateToProps)(DynamicBannerDetail);
