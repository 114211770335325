import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { twitterVideoListData } from './twitterVideoListData';
import LoginAppConstants from '../../../constants/LoginAppConstants';
import { useHistory } from 'react-router';
import ReactPlayer from 'react-player';
import SyncLoader from 'react-spinners/SyncLoader';
import {
  DeleteTweet,
  GetTwitterVideos,
} from '../../../handlers/twitter.handlers';
import TwitterTagFilterDropdown from '../../../components/Dropdowns/TwitterTagFilterDropdown';
import { connect } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { css } from '@emotion/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ConfirmationModal from '../../../components/ConfirmationModal';

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let CURRENT_LANGUAGE = localStorage.getItem('Current_Language');
const headers = {
  'cms-access-token': accessToken,
  source: 'cms',
  language: CURRENT_LANGUAGE,
};
const TwitterVideosList = (props) => {
  const [docs, setDocs] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [page, setPage] = useState(1);
  const [getTags, setGetTags] = useState('');
  const [showEdit, showEditHandler] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [id, setTwitterID] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const gettwitterVides = () => {
    setIsLoading(true);
    GetTwitterVideos(
      page,
      props.twitterNativeReducer.selectedTag,
      searchKey,
      props?.selectedLanguage
    ).then((response) => {
      if (response.status) {
        setIsLoading(false);
        setDocs(response.data.docs);
        setTotalPages(response.data.totalPages);
      }
    });
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    gettwitterVides();
  }, [props.twitterNativeReducer.selectedTag, page, searchKey]);
  useEffect(() => {
    setPage(1);
  }, [searchKey]);
  useEffect(() => {
    const search = localStorage.getItem('searchTweet');
    if (search) {
      setSearchKey(JSON.parse(search));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('searchTweet', JSON.stringify(searchKey));
  }, [searchKey]);
  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };
  const mHistory = useHistory();
  const onView = (id) => {
    mHistory.push(`/twitterVideosDetail/${id}`);
    // dispatchTwitterDocsToEdit({
    //   video: item.media?.variants[0].url,
    //   thumbnail: item.media.preview_image_url,
    //   title: item.text,
    //   description: item.author?.description,
    // });
    // dispatchAllTwitterVideosInfo(item);
  };
  const onTwitterTagChange = (value) => {
    setGetTags(value);
  };
  const handlePagination = (event, value) => {
    setPage(value);
  };
  const handleBack = () => {
    mHistory.goBack();
  };
  const showModalForPublish = (id) => {
    let title = 'Delete Twitter?';
    let message = 'Are you sure to continue?';

    setConfirmationModal({
      message: message,
      show: true,
      title: title,
    });
  };
  const onClose = () => {
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  const onSuccess = () => {
    DeleteTweet({ _id: id })
      .then((res) => {
        if (res) {
          // alert("Twitter Deleted Successfully");
          gettwitterVides();
        } else {
          alert('Something went wrong');
        }
      })
      .catch((error) => error);
    setConfirmationModal({
      message: '',
      show: false,
      title: '',
    });
  };
  return (
    <>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
            <div className="edit__popup">
              <div
                onClick={() => showEditHandler(false)}
                className={
                  showEdit ? 'edit__backdrop active' : 'edit__backdrop'
                }
              ></div>

              <div
                className={
                  showEdit ? 'edit__popup-body active' : 'edit__popup-body'
                }
                style={{ width: '55%' }}
              >
                {' '}
                <div className="edit__popup-inner">
                  <i
                    onClick={() => {
                      // alert(isPlaying);
                      // setIsplaying(false);

                      showEditHandler(false);
                      // setIsplaying((isPlaying) => !isPlaying);
                      // alert(isPlaying);
                    }}
                    className="far fa-times-circle edit__popup-close"
                  ></i>
                  <ReactPlayer
                    playing={showEdit ? true : false}
                    controls
                    url={showMedia}
                    light={mediaThumbnail}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                'bg-white'
              }
            >
              <div className="rounded-t mb-0 px-2 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <div
                      className=" pb-4"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '-5px',
                        width: '20px',
                      }}
                      onClick={handleBack}
                    >
                      <ArrowBackIcon />
                    </div>{' '}
                    <p style={{ fontWeight: 'bolder' }}>Twitter Videos List</p>
                  </div>
                  <div
                    style={{
                      width: '30%',

                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <input
                      value={searchKey}
                      style={{
                        border: '2px solid #acb9ce',
                        borderRadius: 10,
                        marginRight: '3%',
                        padding: '3px',
                        paddingLeft: '10px',
                        height: '45px',
                      }}
                      placeholder="Search Tweet"
                      onChange={(e) => setSearchKey(e.target.value)}
                    />
                  </div>
                  <TwitterTagFilterDropdown
                    selectedValue={getTags}
                    onChange={onTwitterTagChange}
                  />
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={
                        'px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      }
                    >
                      Video
                    </th>

                    <th
                      className={
                        'px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      }
                    >
                      Title
                    </th>

                    <th
                      className={
                        'px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      }
                    >
                      Author
                    </th>
                    <th
                      className={
                        'px-6 text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      }
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {docs?.map((item) => (
                    <tr>
                      <th className="border border-green-800 border-t-0 px-6 align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <img
                          src={item.media.preview_image_url}
                          style={{
                            // maxHeight: 300,
                            // minHeight: 253,
                            // maxWidth: 450,
                            // minWidth: 400,
                            maxHeight: 100,
                            minHeight: 53,
                            maxWidth: 53,
                            minWidth: 100,
                          }}
                          height={300}
                          width={400}
                          alt="..."
                          onClick={() =>
                            onClickOpenMedia(
                              item.media.variants[0].url,
                              item.media.preview_image_url
                            )
                          }
                        ></img>
                      </th>

                      <td
                        className="border border-green-800  border-t-0 px-3  align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        style={{ cursor: 'pointer' }}
                        onClick={() => onView(item._id)}
                      >
                        {item.text}
                      </td>

                      <th className="border border-green-800 border-t-0 px-6 align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {item.author?.name}
                      </th>
                      <th className="border border-green-800 border-t-0 px-6 align-middle text-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i
                          onClick={() => {
                            showModalForPublish();
                            setTwitterID(item._id);
                          }}
                          className="fas fa-trash "
                          style={{
                            cursor: 'pointer',
                          }}
                        ></i>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: 20,
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  page={page}
                  onChange={handlePagination}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
              {/* console.log */}
            </div>
          </div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoading}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    twitterNativeReducer: state.twitterNativeReducer,
    selectedLanguage: state.languageReducer,
  };
};

export default connect(mapStateToProps)(TwitterVideosList);
