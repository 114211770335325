import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import ReactPlayer from 'react-player';
import {
  tagHighlight,
  GetHighlights,
  updateVideosRanking,
} from '../../../../handlers/videos.handlers';
import {
  _dateTimeFormat,
  _getDateTime,
} from '../../../../constants/appDefaults';
import AlertModal from '../../../../components/AlertModal';
import '../../HomePage/HomeNews.css';
import { css } from '@emotion/core';
import SyncLoader from 'react-spinners/SyncLoader';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const Highlights = (props) => {
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [updateDocs, setUpdateDocs] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [showAction, setShowAction] = React.useState(false);
  const mHistory = useHistory();
  const [homeType, setHomeType] = useState('');
  const [section, setSection] = React.useState('TOP_VIDEO');
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [isPlaying, setIsplaying] = useState(false);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const color = 'light';

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const items = reorder(docs, source.index, destination.index);
    const docsUpdated = [];
    items?.map((data, index) => {
      docsUpdated.push({
        _id: data._id,
        highlightVideoRanking: index + 1,
      });
    });
    setUpdateDocs(docsUpdated);
    setShowAction(true);
    setDocs(items);
  };

  const handlehighlightVideoRanking = (data) => {
    let temp = data.sort((a, b) => {
      return a.highlightVideoRanking - b.highlightVideoRanking;
    });
    return temp;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  //Media pop up
  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };
  React.useEffect(() => {
    setIsLoading(true);
    GetHighlights(page)
      .then((response) => {
        setIsLoading(false);
        setHasNextPage(response.data.hasNextPage);
        // setDocs(handlehighlightVideoRanking(response.data.docs));
        setDocs(response.data.docs);
      })
      .catch((error) => error);
  }, [page]);

  const removeVideoFromHome = (id) => {
    const body = {
      _id: id,
      tagHighlight: false,
    };
    tagHighlight(body)
      .then((response) => response)
      .catch((error) => error);
    setAlertModal(true);
  };
  const updateVideos = () => {
    updateVideosRanking(updateDocs, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        if (response.status == true) {
          setUpdateDocs([]);
          setAlertModal(true);
          setShowAction(false);
          GetHighlights(page);
        } else {
          alert('Something went wrong..');

          setShowAction(false);
          GetHighlights(page);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  const getVideoTableItem = (video, position) => {
    if (video.media?.content.length > 0) {
      const videoUrl = video.media.content[1]
        ? video.media.content[1].url
        : video.media.content[0].url;
      const height = video.media.content[1]
        ? video.media.content[1].height
        : video.media.content[0].height;
      const width = video.media.content[1]
        ? video.media.content[1].width
        : video.media.content[0].width;
      const thumbnail =
        video.media.content[video.media.content.length - 1].type === 'image'
          ? video.media.content[video.media.content.length - 1].url
          : video.media.thumbnail[video.media.thumbnail.length - 1].url;
      return (
        <Draggable key={video._id} draggableId={video._id} index={position}>
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                <img
                  src={thumbnail}
                  style={{
                    // maxHeight: 300,
                    // minHeight: 253,
                    // maxWidth: 450,
                    // minWidth: 400,
                    maxHeight: 100,
                    minHeight: 53,
                    maxWidth: 53,
                    minWidth: 100,
                    cursor: 'pointer',
                  }}
                  height={300}
                  width={400}
                  alt="..."
                  onClick={() => onClickOpenMedia(videoUrl, thumbnail)}
                ></img>
              </th>
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.copyright}
              </td>
              <td
                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                onClick={() => onView(video._id)}
                style={{ cursor: 'pointer', fontWeight: '500' }}
              >
                {video.title}
              </td>
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.sports && video.sports.name ? video.sports.name : 'N/A'}
              </td>
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {leagueName(video.league)}
              </td>
              {props.isYoutube === true ? (
                <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {video.channelTitle}
                </td>
              ) : (
                ''
              )}
              {props.isYoutube === true ? (
                <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {video.tags}
                </td>
              ) : (
                ''
              )}
              {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">N/A</td> */}
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {_dateTimeFormat(video.lastUpdate, 'ddd, DD MMM hh:mm A')}
                {/* {video.copyrights === "PTI"
                ? _dateTimeFormat(video.date, "ddd, DD MMM hh:mm A")
                : _getDateTime(video.date, "ddd, DD MMM hh:mm A")} */}
              </td>

              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {_dateTimeFormat(
                  video.highlightVideoExpiry,
                  'ddd, DD MMM hh:mm A'
                )}
                {/* {_dateTimeFormat(video.topVideoExpiry, 'ddd, DD MMM hh:mm A')} */}
              </td>
              <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.status === 1 ? (
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    Published
                  </span>
                ) : (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Draft
                  </span>
                )}
              </td>
              <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.tagHome ? (
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    Tagged
                  </span>
                ) : (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Untagged
                  </span>
                )}
              </td>
              <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {video.section == 'TOP_VIDEO' ? (
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    Tagged
                  </span>
                ) : (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Untagged
                  </span>
                )}
              </td>
              <td
                className="border border-green-800"
                style={{ cursor: 'pointer' }}
              >
                <div
                  onClick={() => removeVideoFromHome(video._id)}
                  className="text-center"
                >
                  <i className="fas fa-trash"></i>
                </div>
              </td>
            </tr>
          )}
        </Draggable>
      );
    } else {
      return <></>;
    }
  };
  const onView = (videoId) => {
    props.isYoutube === true
      ? mHistory.push(`/videos/${videoId}/yes`)
      : mHistory.push(`/videos/${videoId}/no`);
  };
  return (
    <>
      <Sidebar />{' '}
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '55%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showEditHandler(false);
                setIsplaying((isPlaying) => !isPlaying);
                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={isPlaying}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 ">
            {alertModal && (
              <AlertModal
                title="Thanks"
                message="Your article has been updated successfully!"
                onSuccess={() => window.location.reload()}
              />
            )}
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                  <div className="flex flex-wrap items-center">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Highlights
                    </h3>
                    {showAction && (
                      <div
                        className={
                          showAction ? 'save-reset enabled' : 'save-reset'
                        }
                      >
                        <div
                          onClick={() => updateVideos()}
                          className="save btn"
                        >
                          Save
                        </div>

                        <div
                          onClick={() => {
                            GetHighlights(page);
                            setShowAction(false);
                            alert('No changes were made');
                          }}
                          className="reset btn"
                        >
                          Reset
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <SyncLoader
                  color="#23A5E0"
                  loading={isLoading}
                  css={override}
                  size={12}
                />
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Video
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Copyright
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Title
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Sports
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                League
                              </th>
                              {props.isYoutube === true ? (
                                <th
                                  className={
                                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                    (color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                  }
                                >
                                  YT Channel
                                </th>
                              ) : (
                                ''
                              )}
                              {props.isYoutube === true ? (
                                <th
                                  className={
                                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                    (color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                  }
                                >
                                  Tags
                                </th>
                              ) : (
                                ''
                              )}
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Published Date
                              </th>

                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Expiry_Date Time
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Status
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Home
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Top
                              </th>
                              <th
                                className={
                                  'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                                  (color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                                }
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {docs?.map((video, position) =>
                              getVideoTableItem(video, position)
                            )}
                          </tbody>
                        </table>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {hasNextPage && (
                  <div style={{ textAlign: 'center', padding: 20 }}>
                    <button
                      className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      onClick={() => {
                        GetHighlights(page + 1)
                          .then((response) => {
                            setDocs([...docs, ...response.data.docs]);
                            setHasNextPage(response.data.hasNextPage);
                          })
                          .catch((error) => console.log(error));
                      }}
                    >
                      Load More
                    </button>
                  </div>
                )}
                <div style={{ textAlign: 'center', padding: 10 }}>
                  <SyncLoader
                    color="#23A5E0"
                    loading={isLoadingMore}
                    css={override}
                    size={12}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    leagues: state.leaguesReducer.leagues,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
  };
};
export default connect(mapStateToProps)(Highlights);
