import { useState ,useRef , useEffect} from "react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Sidebar from "../../../components/Sidebar/Sidebar";
import UnsavedChangesWarning from "../../../components/AlertModal/UnsavedChangesWarning";
import MultipleLeagueDropDown from "../../../components/Dropdowns/MultipleLeagueDropDown";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { css } from "@emotion/core";
import { GetAllAuthor } from "../../../handlers/author.handlers";
import SyncLoader from "react-spinners/SyncLoader";
import { GetTeamDetail, UpdateTeam } from "../../../handlers/team.handlers";
import { useParams } from "react-router-dom";
import TeamsLeagueDropDown from "../../../components/Dropdowns/TeamsLeagueDropDown"; 

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const styles = {
  inputBox: {
    width: "500px",
    height: "30px",
    marginTop: 5,
    borderRadius: "5px",
  },
};

const TeamInfo = ()=>{
    const userEmail = localStorage.getItem("email");
    const [isLoading, setIsLoading] = useState(false);
    const [Prompt, setIsUnsaved, setPristine] = UnsavedChangesWarning();
    const {id} = useParams();
    const [country, setCountry] = useState("");
    // const [open, setOpen] = useState(false); 
    const [compDetails, setCompDetails] = useState([]);
    const [description, setDescription] = useState("");
    const [author, setAuthor] = useState("");
    const [summary, setSummary] = useState("");
    const [gender, setGender] = useState("");
    const [keywords, setKeywords] = useState("");
    const [teamData, setTeamData]  = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [updateImage, setUpdateImage] = useState("");
    
    const [confirmationModal, setConfirmationModal] = useState({
        show: false,
        title: "",
        message: "",
      });
      const [docs, setDocs] = useState([]);
    
      const [socials, setSocials] = useState({
        facebook : "",
        instagram : "",
        snapchat :"",
        youtube : "",
        website : "",
      });

      
      useEffect(() => {
        GetAllAuthor()
          .then((response) => {
            setDocs(response.data);
          })
          .catch((error) => error);
      }, []);
      

      const TeamData = ()=>{
        setIsLoading(true);
        GetTeamDetail({
          seo_slug:id
        })
          .then((response) => {
            setIsLoading(false);
            setTeamData(response?.data);
            setCountry( response?.data?.country?response?.data?.country:"");
            setDescription(response?.data?.description?response?.data?.description:"");
            setSummary(response?.data?.summary?response?.data?.summary:"");
            setAuthor(response?.data?.author?response?.data?.author:"");
            setKeywords(response?.data?.keywords?response?.data?.keywords:"");
            setGender(response?.data?.gender?response?.data?.gender:"men");
            setCompDetails(response?.data?.comp_ids);
            setUpdateImage(response?.data?.logo);
            setSocials({
              facebook : response?.data?.social?.facebook?response?.data?.social?.facebook:"",
              instagram : response?.data?.social?.instagram?response?.data?.social?.instagram:"",
              snapchat : response?.data?.social?.snapchat? response?.data?.social?.snapchat:"",
              twitter : response?.data?.social?.twitter?response?.data?.social?.twitter:"",
              youtube : response?.data?.social?.youtube?response?.data?.social?.youtube:"",
              website : response?.data?.social?.website?response?.data?.social?.website:"",
            });
          })
          .catch((error)=>error); 
      }
      useEffect(()=>{
          setIsLoading(true);
          TeamData();
      },[id])
      function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
      }

      const onAuthorSelected = (e) => {
        setAuthor(e.target.value );
      };

      const handleTeamGender = (event)=>{
            setGender(event.target.value);
      };

      const tagLeaguesToTeam = (value) =>{
        setCompDetails(value)
      };
      const isValidURL = (url)=>{
        const regex =  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;    
        return regex.test(url);
      } 
      const onUpdateTeam = () => {
        if(compDetails?.length==0){
          setConfirmationModal({
            message: "Please Tag a League",
            show: true,
            title: "",
          });
        }
        else if(summary?.length==0){
          setConfirmationModal({
            message: "Summary cannot be empty!!!",
            show: true,
            title: "",
          });
        }
        else if(description?.length==0){
          setConfirmationModal({
            message: "Description cannot be empty!!!",
            show: true,
            title: "",
          });
        }
        else if(author?.length==0){
          setConfirmationModal({
            message: "Please Tag an Auhtor!!!",
            show: true,
            title: "",
          });
        }
        else if(country?.length==0){
          setConfirmationModal({
            message: "Please Tag an Country!!!",
            show: true,
            title: "",
          });
        }
        else if(gender?.length==0){
          setConfirmationModal({
            message: "Please Select Team's Gender",
            show: true,
            title: "",
          });
        }
        else if(keywords?.length==0){
          setConfirmationModal({
            message: "Keywords canot be empty!!!",
            show: true,
            title: "",
          });
        }
        else {
          setIsLoading(true);
          UpdateTeam(
              {
                sportId:teamData?.sports?._id, 
                teamId:teamData?.teamId, 
                seo_slug:teamData?.seo_slug,
                country:country, 
                description:description,
                summary:summary,
                comp_ids:compDetails,
                author:author,
                gender, gender,
                keywords:keywords,
                emailId:userEmail,
                social:socials
              }
          )
            .then((response) => {
              if (response.status) {
                setIsLoading(false);
                alert("Success..");
                // window.location.reload(false);
                TeamData();
    
              } else {
                alert("Failed..");
                setIsLoading(false);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              alert("Erorr while updating Team", error);
            });
        }
      };

      const onSuccess = () =>
      setConfirmationModal({ message: "", show: false, title: "" });

      const onClose = () => {
        setConfirmationModal({
          message: "",
          show: false,
          title: "",
        });
      };

      let fileInput = useRef();
  const onFileSelect = (event) => {
    var file = event.target.files[0];
    // setImageTitle(file.name);
    event.target.value = '';

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if(height==width)
        {
          setSelectedImage(reader.result);
          setUpdateImage(reader.result);
        }
        else{
          alert("Please upload a square shaped photo of Team...")
        }
      };
      // setSelectedImage(file);
      
      // setShowCropper(true);
    };
    reader.onerror = function (error) {
      //
    };
    
  };

  const updatePlayerImage = () =>{
    if(selectedImage)
    {
      const data = {
        sportId:teamData?.sports?._id, 
        teamId:teamData?.teamId, 
        seo_slug:teamData?.seo_slug,
        logo: selectedImage,
        emailId:userEmail
      };
      const imageData = new FormData();
  
      Object?.keys(data)?.forEach(function (key) {
        imageData?.append(key, data[key]);
      });

      UpdateTeam(imageData).then((response)=>{
       
          alert('Team Image uploaded Successfully!');
          setSelectedImage('');
          setUpdateImage('');
          window.location.reload(false);
      })
    }
    else{
      alert('Please upload a Image in given Ratio')
    }
    
  };


    return(
        <>{console.log("comp", compDetails)}
        <Sidebar />
        <SyncLoader
              color="#23A5E0"
              loading={isLoading}
              css={override}
              size={12}
            />
        <>
        <div className="relative md:ml-64" style={{  display:'block', backgroundColor:"#F1F2F6", padding:"15px", height:"100vh" , marginBottom:"50px"}}>
        <div className="flex flex-wrap" style={{backgroundColor:"#FFFFFF"}}>
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            {confirmationModal.show && (
              <ConfirmationModal
                message={confirmationModal.message}
                title={confirmationModal.title}
                onClose={onClose}
                onSuccess={onSuccess}
              />
            )}
                 <div>
                <div style={{display:"flex", marginBottom:"10px", marginTop:"10px", justifyContent:"space-between", width:"100%"}}>
                      <div >
                        <div style={{ display: "flex", }}>

                        <div style={{ fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Team Name:
                         <span style={{fontWeight:"400", marginLeft:"10px",}}>
                            {teamData?.name}
                        </span>
                        </div>
                          {/* <div style={{
                            marginTop: 0,
                            marginBottom: 10,
                            fontWeight: "bold",
                            marginRight: "80px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "25px"
                          }}>{`Permalink: `}
                            <a href="https://m.thesportstak.com"
                              target="_blank">
                              <img src="/LinkIcon.png" style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
                            </a>


                          </div> */}
                        </div>
                        
                        <div style={{ fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Sports: 
                        <span style={{fontWeight:"400",marginLeft:"10px",}}>
                             {teamData?.sports?.name}
                        </span>
                        </div>
                        <div style={{ fontSize:"16px", fontWeight:"700"}}>
                          Abbreviation:
                          <span style={{fontWeight:"400",marginLeft:"10px",}}>
                            {teamData?.abbr}
                          </span>
                        </div>
                        <div style={{ fontSize:"16px", fontWeight:"700", marginTop:"10px"}}>
                          SEO Slug:
                          <span style={{fontWeight:"400",marginLeft:"10px",}}>
                            {teamData?.seo_slug}
                          </span>
                        </div>
                         {/**League dropdown */}

                <div style={{marginTop: 5,
                            marginBottom: 10,
                            fontWeight: "600",
                            marginRight: "80px",
                            marginBottom:"10px",
                           
                            alignItems:"center"}}>
                    <div style={{marginTop:"10px", marginRight:"10px"}}>
                    {`Select Leagues:* `} <span style={{fontSize:"12px", color:"grey"}}>{`(dropdown to select and unselect it.)`}</span>
                    </div>
                    <TeamsLeagueDropDown
                      onChange={tagLeaguesToTeam}
                      id={teamData?.teamId}
                      data={teamData}
                      sports={teamData?.sports?._id}
                      // onClick={tagLeaguesToTeam}
                    />
                </div>
               
                    </div>

                    <div>
                      
                      <div style={{}}>
                    {updateImage?<img src={updateImage} style={{  alignItems:"right", width:"200px", height:"200px" }}/>:null}
                    
                      <div style={{width:"100%", textAlign:"center"}}>
                        <div style={{display:"flex", alignItems:"center", textAlign:"center"}}>
                            <div>

                          <input
                            ref={fileInput}
                            type="file"
                            // multiple={false}
                            accept="image/webp"
                            onChange={onFileSelect}
                            style={{
                              backgroundColor: "#111",
                              height: 50,
                              width: 35,
                              position: "absolute",
                              opacity: 0,
                              cursor: "pointer",
                            }}
                          />
                          <i
                            className="fas fa-camera fa-2x"
                            style={{
                                margin:8,
                              color: "#555",
                              cursor: "pointer",
                            }}
                          ></i>
                            </div>
                          <div className="col-lg-12">
                <button
                  onClick={() => {
                    updatePlayerImage();
                    
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  update Image
                </button>
              </div>
                        </div>
                        <div style={{width:"80%"}}>*Please upload a Image in 1:1</div>
                      </div>
                    
                    </div>
                    </div>
                    
                </div>

               {/**Country */}
               <div>
               <span style={{fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Country: </span>
               <input
                    type="text"
                    name="summary"
                    value={country}
                    onChange={(event)=>{
                        
                        setCountry(event.target.value)
                    }}
                    style={{
                        margin:"10px",
                        width:"400px",
                        borderRadius:"5px",
                       
                    }}
                />
               </div>
                
                {/**Gender of Team (men/women) */}
                <div style={{margin:"10px 0px"}}>
                <span style={{fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Gender: </span>
                <select

                  value={gender}
                  onChange={handleTeamGender}
                  style={{borderRadius:"10px"}}
                >
                  <option value="men">Men</option>
                  <option value="women">Women</option>
                </select>
                </div>

               {/** Socials */}
               <hr className="my-0 md:min-w-full" />
                <div style={{fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Socials:</div>
               <div style={{margin:"10px 0px", display:"flex", flexWrap:"wrap",}}>
                {/**facebook */}
                <div style={{display:"flex", alignItems:"center", marginRight:"100px"}}>
                <img
                        src={`${process.env.PUBLIC_URL}/assets/img/facebook.svg`}
                        style={{height:"30px"}}
                      />
                      <input
                    type="url"
                    name="facebook"
                    placeholder="Facebook"
                    pattern="https://.*"
                    value={socials?.facebook}
                    onChange={(event)=>{
                        setSocials({...socials, facebook:event.target.value})
                    }}
                    onBlur={()=>{
                      if(!isValidURL(socials?.facebook) && socials?.facebook.length>0)
                      {
                        alert("Please Enter a valid Url!!!");
                        setSocials({...socials, facebook:""});
                      }
                    }}
                    style={{
                        margin:"10px",
                        width:"300px",
                        borderRadius:"5px",
                       
                    }}
                />
                 <a href={`javascript:window.open('${socials?.facebook}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${socials?.facebook}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>
                </div>
                {/**instagram */}
                <div style={{display:"flex", alignItems:"center", marginRight:"100px"}}>
                <img
                        src={`${process.env.PUBLIC_URL}/assets/img/instagram.svg`}
                        style={{height:"30px", width:"30px"}}
                      />
                      <input
                    type="url"
                    name="instagram"
                    pattern="https://.*"
                    placeholder="Instagram"
                    value={socials?.instagram}
                    onChange={(event)=>{
                        setSocials({...socials, instagram:event.target.value})
                    }}
                    onBlur={()=>{
                      if(!isValidURL(socials?.instagram) && socials?.instagram.length>0)
                      {
                        alert("Please Enter a valid Url!!!");
                        setSocials({...socials, instagram:""});
                      }
                    }}
                    style={{
                        margin:"10px",
                        width:"300px",
                        borderRadius:"5px",
                       
                    }}
                />
                 <a href={`javascript:window.open('${socials?.instagram}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${socials?.instagram}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>
                </div>
                {/**twitter */}
                <div style={{display:"flex", alignItems:"center", marginRight:"100px"}}>
                <img
                        src={`${process.env.PUBLIC_URL}/assets/img/twitter.svg`}
                        style={{height:"30px", width:"30px"}}
                      />
                      <input
                    type="url"
                    name="twitter"
                    pattern="https://.*"
                    placeholder="Twitter"
                    value={socials?.twitter}
                    onChange={(event)=>{
                        setSocials({...socials, twitter:event.target.value})
                    }}
                    onBlur={()=>{
                      if(!isValidURL(socials?.twitter) &&socials?.twitter.length>0)
                      {
                        alert("Please Enter a valid Url!!!");
                        setSocials({...socials, twitter:""});
                      }
                    }}
                    style={{
                        margin:"10px",
                        width:"300px",
                        borderRadius:"5px",
                       
                    }}
                />
                 <a href={`javascript:window.open('${socials?.twitter}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${socials?.twitter}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>
                </div>
                {/**snapchat */}
                <div style={{display:"flex", alignItems:"center", marginRight:"100px"}}>
                <img
                        src="/snapchat.png"
                        style={{height:"30px"}}
                      />
                      <input
                    type="url"
                    name="facebook"
                    pattern="https://.*"
                    placeholder="Snapchat"
                    value={socials?.snapchat}
                    onChange={(event)=>{
                        setSocials({...socials, snapchat:event.target.value})
                    }}
                    onBlur={()=>{
                      if(!isValidURL(socials?.snapchat) && socials?.snapchat.length>0)
                      {
                        alert("Please Enter a valid Url!!!");
                        setSocials({...socials, snapchat:""});
                      }
                    }}
                    style={{
                        margin:"10px",
                        width:"300px",
                        borderRadius:"5px",
                       
                    }}
                />
                 <a href={`javascript:window.open('${socials?.snapchat}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${socials?.snapchat}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>
                </div>
                {/**Youtube */}
                <div style={{display:"flex", alignItems:"center", marginRight:"100px"}}>
                <img
                        src='/youtube.png'
                        style={{height:"30px"}}
                      />
                      <input
                    type="url"
                    name="youtube"
                    pattern="https://.*"
                    placeholder="Youtube"
                    value={socials?.youtube}
                    onChange={(event)=>{
                        setSocials({...socials, youtube:event.target.value})
                    }}
                    onBlur={()=>{
                      if(!isValidURL(socials?.youtube) && socials?.youtube.length>0)
                      {
                        alert("Please Enter a valid Url!!!");
                        setSocials({...socials, youtube:""});
                      }
                    }}
                    style={{
                        margin:"10px",
                        width:"300px",
                        borderRadius:"5px",
                       
                    }}
                />
                 <a href={`javascript:window.open('${socials?.youtube}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${socials?.youtube}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>
                </div>
                {/**Website */}
                <div style={{display:"flex", alignItems:"center", marginRight:"100px"}}>
                <img
                        src="/world-wide-web.png"
                        style={{height:"30px"}}
                      />
                      <input
                    type="url"
                    name="facebook"
                    pattern="https://.*"
                    value={socials?.website}
                    placeholder="Website"
                    onChange={(event)=>{
                        setSocials({...socials, website:event.target.value})
                    }}
                    onBlur={()=>{
                      if(!isValidURL(socials?.website) && socials?.website.length>0)
                      {
                        alert("Please Enter a valid Url!!!");
                        setSocials({...socials, website:""});
                      }
                    }}
                    style={{
                        margin:"10px",
                        width:"300px",
                        borderRadius:"5px",
                       
                    }}
                />
                 <a href={`javascript:window.open('${socials?.facebook}','popup','width=600,height=400')`}
  // target="popup" 
  onClick={`window.open('${socials?.facebook}','popup','width=600,height=400'); return false`} style={{width:"25px", marginLeft:"5px"}}><img src="/HyperLink.png"/></a>
                </div>
                  
               </div>
               {/**Summary Section */}
               <hr className="my-0 md:min-w-full" />
               <div style={{margin:"10px 0px"}}>
                <div style={{fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Summary:</div>
               
                <CKEditor
                    onChange={(event, editor) => {
                      setSummary(editor.getData())
                     
                    }}
                    editor={Editor}
                    data={summary}
                    
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                    }}
                    
                  />
               </div>
                
                {/**Team Description */}
                <hr className="my-0 md:min-w-full" />
                <div style={{margin:"10px 0px"}}>
                <div style={{fontSize:"16px", fontWeight:"700", marginBottom:"5px"}}>Description:</div>
                <CKEditor
                    onChange={(event, editor) => {
                      setDescription( editor.getData());
                      setIsUnsaved();
                    }}
                    editor={Editor}
                    // style={{ height: '300px' }}
                    data={description}
                    
                    config={{
                      extraPlugins: [uploadPlugin],
                      height: 500,
                    }}
                    
                  />
                </div>
                {/**Keywords */}

                <div style={{ marginTop: "20px" , marginBottom:"10px",}}>
                <label className="addArticle_label">Key Words</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Keywords - ( Sports, player name, etc ) *"
                  name="slug"
                  value={keywords}
                  onChange={(event) => {
                    setKeywords(event.target.value);
                    }}
                  style={styles.inputBox}
                />
              </div>
                {/**Author selection */}
                <hr className="my-0 md:min-w-full" />
              <div style={{marginTop: "20px" }}>
               
                <label className="addArticle_label">Tag Author*</label>
                <br></br>
                  <select
                    style={{
                      // marginLeft: 20,
                      border: "2px solid #acb9ce",
                      borderRadius: 10,
                    }}
                    className="mb-5 mt-3"
                    value={author}
                    onChange={onAuthorSelected}
                  >
                    <option value="">Select Author</option>
                    {
                      docs.map((author) => (
                        <>
                          <option
                            selectedvalue={author && author._id}
                            value={author._id}
                          >
                            {author.name}
                          </option>
                        </>
                      ))}
                  </select>
                
                
              </div>  
              </div>
            
          </div>
        </div>
      <div className="col-lg-12" style={{marginLeft:"20px", paddingBottom:"20px"}}>
                <button
                  onClick={() => {
                    onUpdateTeam(true);
                    setPristine();
                  }}
                  className={`bg-${"emerald"}-500 text-white active:bg-${"emerald"}-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                >
                  Update
                </button>
              </div>
      </div>
      {Prompt}
        </>
        </>
    );
};

export default TeamInfo;