import { NewsActionTypes } from '../action-types';

const initialState = {
  docs: [],
  hasNextPage: false,
  hasPrevPage: false,
  limit: 8,
  page: 1,
  totalDocs: 0,
  totalPages: 0,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NewsActionTypes.NEWS_LIST:
      return Object.assign({}, state, {
        ...action.payload,
        docs: [...state.docs, ...action.payload.docs],
      });
    case NewsActionTypes.NUMBER_OF_PAGES:
      return Object.assign({}, state, {
        ...action.payload,
        totalPages: action.payload.totalPages,
      });
    case NewsActionTypes.CURRENT_PAGE:
      return Object.assign({}, state, {
        ...action.payload,
        page: action.payload,
      });
    case NewsActionTypes.NEWS_LIST_FILTER:
      return Object.assign({}, state, {
        ...action.payload,
        docs: action.payload.docs, //[...state.docs, ...action.payload.docs],
      });

    case NewsActionTypes.RESET_NEWS_LIST:
      return Object.assign({}, state, {
        docs: [],
        hasNextPage: false,
        hasPrevPage: false,
        limit: 10,
        page: 0,
        totalDocs: 0,
        totalPages: 0,
      });
    case NewsActionTypes.NEWS_TOGGLE_STATUS:
      const docs = [...state.docs];
      docs[action.payload.index].status = action.payload.status;
      return Object.assign({}, state, {
        docs,
      });
    default:
      return state;
  }
};
export default newsReducer;
