import React, { useState, useEffect } from "react";
import Axios from "axios";
import { API_BASE_PATH, OLD_BASE_PATH } from "../../constants/workerConstants";

import _ from "lodash";
import { connect } from "react-redux";
import {
  dispatchAllLeaguesTag,
  dispatchSearchLeague,
  dispatchSelectedLeague,
  dispatchSelectedLeagueTagging,
} from "../../redux/action-dispatchers/leagues.dispatchers";
import LoginAppConstants from "../../constants/LoginAppConstants";
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const LeagueDropDownTagging = (props) => {
  const onChange = (event) => {
    const foundObject = _.find(props.leaguesReducer.leaguesTag, [
      "comp_id",
      event.target.value,
    ]);

    props.onChange &&
      props.onChange(
        event.target.value,
        foundObject?.optaUUID,
        foundObject._id
      );

    dispatchSelectedLeagueTagging({
      selectedLeagueTag: event.target.value,
      selectedLeagueFilterTag: event.target.value,
      selectedLeagueIDTag: foundObject._id,
    });
  };
  const onSearchLeague = (event) => {
    dispatchSearchLeague(event.target.value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {console.log(
          "props.leaguesReducer.selectedLeagueTag",
          props.leaguesReducer.selectedLeagueTag
        )}
        <select
          style={{
            // marginLeft: 20,
            border: "2px solid #acb9ce",
            borderRadius: 10,
            marginRight: "20px",
          }}
          value={props.leaguesReducer.selectedLeagueTag}
          onChange={onChange}
        >
          {props.isDynamicBannerPage == true ? (
            <>
              <option value="">Select Leagues</option>
              {props.leaguesReducer.leaguesTag
                .slice(1, props.leaguesReducer.leaguesTag.length)
                ?.map((league) => (
                  <option value={league.comp_id}>
                    {league.comp_name == "All"
                      ? league.comp_id
                      : league.comp_name}
                  </option>
                ))}
            </>
          ) : (
            <>
              <option value="All">Select Leagues</option>
              {props.leaguesReducer.leaguesTag?.map((league) => (
                <>
                  <option value={league.comp_id}>
                    {league.comp_name == "All"
                      ? league.comp_id
                      : league.comp_name}
                  </option>
                </>
              ))}
            </>
          )}

          {/* {props.leaguesReducer.leagues.map((league) => (
       league.abbr === "All" ? <></> : <option value={league.comp_id}>{league.abbr}</option>
      ))} */}
        </select>
        <input
          type="text"
          placeholder="Search leagues"
          value={props.leagueSearch}
          onChange={onSearchLeague}
          className="border-0 mx-3 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leaguesReducer: state.leaguesReducer,
    leagueSearch: state.leaguesReducer.leagueSearch,
    selectedLeagueFilter: state.leaguesReducer.selectedLeagueFilter,
    selectedSportsTagging: state.sportsReducer.selectedSportsTagging,
  };
};

export default connect(mapStateToProps)(LeagueDropDownTagging);
