import { ceil } from 'lodash';
import { connect } from 'react-redux';

import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import {
  getAllRoles,
  addPermissionToModule,
  GetAddedRole,
  getAllPermissions,
} from '../../../handlers/admin.handlers';
import SyncLoader from 'react-spinners/SyncLoader';
import { css } from '@emotion/core';
const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;

const permissionArray = [
  'Add',
  'All',
  'Delete',
  'Edit',
  'Publish',
  'Tag',
  'View',
];

const PermissionTable = (props) => {
  const [docs, setdocs] = useState([]);
  const [permissionData, setPermissionData] = useState([]);
  const [userRoles, setUserRoles] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getRoles = () => {
    const headers = {
      Authorization: props.Authorization,
    };
    getAllRoles(headers)
      .then((res) => {
        if (res.status == true) {
          setIsLoading(false);
          setdocs(res.data);
        }
      })
      .catch((error) => error);
  };

  const onRoleChange = (event) => {
    const headers = {
      Authorization: props.Authorization,
    };
    setUserRoles(event.target.value);
    getAllPermissions(event.target.value, headers)
      .then((res) => {
        if (res.status == true) {
          setPermissionData(res.data);
        }
      })
      .catch((error) => error);
  };

  const isChecked = (event, arrayIndex, objectKey) => {
    let data = [...permissionData];
    data[arrayIndex].permission[objectKey] =
      data[arrayIndex].permission[objectKey] == 'true' ? 'false' : 'true';
    // data[arrayIndex].module[objectIndex][objectKey] =
    //   data[arrayIndex].module[objectIndex][objectKey] == "true"
    //     ? "false"
    //     : "true";
    setPermissionData(data);
    // setData(data[arrayIndex]);
  };

  const savePermissions = () => {
    const headers = {
      Authorization: props.Authorization,
    };
    addPermissionToModule(
      { role: userRoles, module: permissionData },
      headers
    ).then((response) => {
      if (response.status) {
        alert('Updated Successfully');
      }
    });
  };

  const checkPermissions = (array, val) => {
    if (val == 'all') {
      return array.length == 6 ? true : false;
    } else {
      const isFound = array.some((item) => item === val);
      return isFound;
    }
  };

  const onChangeChecked = (event) => {};

  useEffect(() => {
    setIsLoading(true);
    getRoles();
  }, [props.Authorization]);

  return (
    <div
      className={
        'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
        (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
      }
    >
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-2 max-w-full flex-grow flex-1 py-4">
            <Link
              className=" pb-4"
              style={{
                cursor: 'pointer',
                marginLeft: '10px',
                marginBottom: '-30px',
                width: '30px',
                fontSize: '15px',
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 'bold',
                color: '#1e1e1e',
              }}
              to="/"
            >
              <ArrowBackIcon />
              Home
            </Link>

            <h3
              className={
                'font-semibold text-lg ' +
                (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
              }
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '24px',
              }}
            >
              CMS PERMISSIONS
            </h3>
          </div>
          {
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              color="success"
              onClick={savePermissions}
            >
              {/*  onClickAdd saveRolesData */}
              Save Permission
            </Button>
          }
          <select
            style={{
              // marginLeft: 20,
              border: '2px solid #acb9ce',
              borderRadius: 10,
            }}
            onChange={onRoleChange}
            // value={role}
          >
            <option>Select Role Type</option>
            {docs.map((item) => (
              <option value={item.name}>{item.name}</option>
            ))}
          </select>

          <div className="block w-full overflow-x-auto">
            {/* Projects table */}
            <table className="table-fixed">
              <thead>
                {permissionData.length > 0 && (
                  <tr>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                        (props.color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                      }
                    >
                      Module
                    </th>
                    {permissionArray.map((heading, index) => (
                      <th
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (props.color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody>
                {permissionData.length > 0 &&
                  permissionData.map((role, index) => (
                    <tr>
                      <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        {Object.keys(role)[0]}
                      </td>
                      {Object.keys(role.permission).map((key) => (
                        <td className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                          <input
                            type="checkbox"
                            checked={
                              role.permission[key] == 'true' ? 'checked' : ''
                            }
                            // checked={checkPermissions(role.permission, item)}
                            // checked={
                            //   role.module &&
                            //   role.module[4].topvideos == "true"
                            //     ? "checked"
                            //     : ""
                            // }
                            onChange={(event) =>
                              // setChecked(
                              //   role.module && role.module[3].topnews
                              // )
                              isChecked(event, index, key)
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Authorization: state.authReducer.authorizationToken,
  };
};

export default connect(mapStateToProps)(PermissionTable);
