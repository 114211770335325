import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import appReducer from "./app";
import newsReducer from "./news.reducer";
import playerProfileReducer from "./playerProfile.reducer";
import homeNewsReducer from "./news.reducer";
import videosReducer from "./videos.reducer";
import s3VideosReducer from "./s3Videos.reducer";
import leaguesReducer from "./leagues.reducer";
import sportsReducer from "./sports.reducer";
import schedulesReducer from "./schedule.reducer";
import languageReducer from "./language.reducer";
import ytvideosReducer from "./youtubevideos.reducer";
import shortsVideosReducer from "./shortsVideosReducer";
import rolesReducer from "./roles.reducer";
import matchesReducer from "./matches.reducer";
import teamsReducer from "./teams.reducer";
import productivityReducer from "./productivity.reducer";
import newsHyperLinkReducer from "./newsHyperlink.reducer";
import twitterNativeReducer from "./twitteNative.reducer";
import dynamicBannerReducer from "./dynamicBanner.reducer";
import colorPickerReducer from "./colorPicker.reducer";
export const RootReducer = combineReducers({
  appReducer,
  authReducer,
  newsReducer,
  playerProfileReducer,
  videosReducer,
  s3VideosReducer,
  leaguesReducer,
  sportsReducer,
  schedulesReducer,
  languageReducer,
  ytvideosReducer,
  homeNewsReducer,
  shortsVideosReducer,
  rolesReducer,
  matchesReducer,
  teamsReducer,
  productivityReducer,
  newsHyperLinkReducer,
  twitterNativeReducer,
  dynamicBannerReducer,
  colorPickerReducer,
});
