import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import { OLD_BASE_PATH } from '../../../constants/workerConstants';

import Sidebar from '../../../components/Sidebar/Sidebar';
import { Button } from '@material-ui/core';
import LoginAppConstants from '../../../constants/LoginAppConstants';

const Teams = () => {
  const [docs, setDocs] = useState([]);
  const [showEdit, showEditHandler] = useState(false);
  const [segmentData, setSegmentData] = useState([]);
  const [teamName, setTeamName] = useState('');
  const [teamId, setTeamId] = useState('');

  const [teamSegment, setTeamSegment] = useState([]);

  const [segmentId, setSegmentId] = useState('');

  const color = 'light';
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  const getAllTeams = () => {
    Axios.get(`${OLD_BASE_PATH}teams/getAllTeams`, {
      headers: { 'cms-access-token': accessToken, source: 'cms' },
    })
      .then((res) => {
        setDocs(res.data.result.data);
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getAllTeams();
  }, []);
  const OnClickEditLeague = (team, name, id) => {
    setSegmentData(team);
    setTeamName(name);
    setTeamId(id);
    showEditHandler(true);
    let array = [];
    team.map((item, index) => {
      array.push({
        _id: item._id,
        event: item.event,
        segmentId: item.segmentId,
      });
    });
    setTeamSegment(array);
    const initialSeg = docs.find((item) => item.teamId === id);
  };

  const sendSegment = () => {
    var payload = {
      name: teamName,
      teamId: teamId,
      segments: teamSegment,
    };

    Axios.post(`${OLD_BASE_PATH}teams/mapTeamSegments`, payload)
      .then((response) => {
        if (response.status == 200) {
          showEditHandler(false);
          alert('Segment send success');
          getAllTeams();
        } else {
          alert('Something went wrong');
        }
      })
      .catch((error) => error);
  };

  const handleChange = (e, id, evntName, index) => {
    const value = e.target.value;
    let newArr = [...teamSegment];
    newArr[index].segmentId = value;
    setTeamSegment(newArr);
  };
  return (
    <>
      <div className="edit__popup">
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '40%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => showEditHandler(false)}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <div style={{ fontWeight: 'bold' }}>Team Name: {teamName}</div>
            <div className="edit__popup--form">
              <div className="edit__popup-row">
                <span style={{ fontWeight: 'bold' }}>Events:</span>
              </div>

              {segmentData.length > 0 &&
                segmentData.map((team, index) => {
                  return (
                    <div
                      className="relative w-full"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontWeight: '500',
                      }}
                    >
                      {team && team.event} :-
                      <input
                        type="text"
                        name={segmentId}
                        value={
                          teamSegment.length > 0 &&
                          teamSegment[index] &&
                          teamSegment[index].segmentId
                        }
                        onChange={(e) =>
                          handleChange(e, team._id, team.event, index)
                        }
                        placeholder="Segment id"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-100"
                        style={{ width: '60%', height: '33px', margin: '1.2%' }}
                      />
                      {/* {console.log(
                        'segmetn event and segment id',
                        team.event,
                        team.segmentId
                      )} */}
                    </div>
                  );
                })}

              <Button
                style={{
                  position: 'relative',
                  left: '40%',
                  width: '100px',
                  marginTop: '3%',
                }}
                variant="contained"
                onClick={sendSegment}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{ paddingTop: 10 }}
      >
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
              }
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3
                      className={
                        'font-semibold text-lg ' +
                        (color === 'light' ? 'text-blueGray-700' : 'text-white')
                      }
                    >
                      Teams
                    </h3>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <div>
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr
                        className={
                          'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                          (color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                        }
                      >
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Team ID
                        </th>
                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Team Name
                        </th>

                        <th
                          className={
                            'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            (color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                          }
                        >
                          Segments
                        </th>
                      </tr>
                    </thead>
                    {/* <PulseLoader
                      color="#23A5E0"
                      loading={isLoading}
                      css={override}
                      size={10}
                    /> */}
                    <tbody>
                      {docs.length > 0 &&
                        docs.map((team) => {
                          return (
                            <tr>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {team && team.teamId ? team.teamId : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                {team && team.name ? team.name : 'N/A'}
                              </td>
                              <td
                                className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                style={{
                                  fontWeight: 'bolder',
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    OnClickEditLeague(
                                      team.segments,
                                      team.name,
                                      team.teamId
                                    )
                                  }
                                >
                                  View Segment
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
