import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./TwitterTag.css";
const TwitterTag = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64" style={{ padding: 60 }}>
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                "bg-white"
              }
              style={{ height: "100vh" }}
            >
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div
                  className=" pb-4"
                  style={{
                    cursor: "pointer",
                    marginLeft: "-60px",
                    width: "140px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleBack}
                >
                  <ArrowBackIcon />
                </div>
                <div className="flex flex-wrap items-center">
                  <div className="block w-full overflow-x-auto flex flex-row  ">
                    {/* <h1>Twitter Tag</h1> */}

                    <div
                      className="relative flex flex-col min-w-0 break-words mb-1
                    shadow-lg shadow-ug rounded twitter-content"
                    >
                      <Link to="/twitterList">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            // overflow: 'hidden',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <h1 style={{ fontWeight: "bold" }} className="">
                            Content
                          </h1>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/content.png`}
                          />
                        </div>
                      </Link>
                    </div>
                    <div
                      className="relative flex flex-col min-w-0 break-words mb-1
                    shadow-lg shadow-ug rounded twitter-content"
                    >
                      <Link to="/twitterVideosList">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            // overflow: 'hidden',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <h1 style={{ fontWeight: "bold" }} className="">
                            Videos
                          </h1>
                          <img
                            style={{ width: "215x", height: "165px" }}
                            src={`${process.env.PUBLIC_URL}/assets/img/videos.png`}
                          />
                        </div>
                      </Link>
                    </div>
                    {/**Trending Topic in CMS */}
                    <div
                      className="relative flex flex-col min-w-0 break-words mb-1
                    shadow-lg shadow-ug rounded twitter-content"
                    >
                      <Link to="/trendingNewsList">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            // overflow: 'hidden',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <h1 style={{ fontWeight: "bold" }} className="">
                            Trending 
                          </h1>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/content.png`}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwitterTag;
