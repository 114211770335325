import axios from 'axios';
import AppConstants from '../constants';
import LoginAppConstants from '../constants/LoginAppConstants';
import AxiosAuthInstance from './http.authhandlers';
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
let lang = localStorage.getItem('Current_Language');
let cms_access_token = localStorage.getItem('SESSION_STORAGE_ACCESS_TOKEN ');
const headers = { 'cms-access-token': cms_access_token, source: 'cms' };
export const Login = async (IParam) => {
  return await AxiosAuthInstance.post(
    LoginAppConstants.LoginConstant.API_ROUTES.USER.LOGIN,
    IParam
  )
    .then((response) => response)
    .catch((error) => error);
};
export const LogOut = async (IParam) => {
  return await AxiosAuthInstance.post(
    LoginAppConstants.LoginConstant.API_ROUTES.USER.LOGOUT,
    IParam
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetAllUsers = async (page, lang) => {
  return await AxiosAuthInstance.get(
    `${LoginAppConstants.LoginConstant.API_ROUTES.USER.ALL_USERS}?limit=50&page=${page}&orderBy=asc&sortBy=email&language=${lang}`,
    { headers: { 'cms-access-token': accessToken, source: 'cms' } }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const DeleteUser = async (id, language) => {
  //   var config = {
  //     method: 'delete',
  //     url: 'https://develop-rest-microservice.thesportstak.mobiletak.com/authentication/api/cmsUser/deleteUser?id=63042b14b48bb95b9929d8d4&language=English',
  //     headers: {'cms-access-token': cms_access_token, source: 'cms' }
  //   };
  //   return axios(config)
  //   .then((response)=> response)
  //   .catch((error) =>error);
  // }

  return await AxiosAuthInstance.delete(
    `${LoginAppConstants.LoginConstant.API_ROUTES.USER.DELETE_USER}?id=${id}&language=${language}`,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const AddUserHandler = async (params) => {
  // const formData = new FormData();

  // Object.keys(params).forEach((key) => {
  //   formData.append(key, params[key]);
  // });

  return await AxiosAuthInstance.post(
    `${LoginAppConstants.LoginConstant.API_ROUTES.USER.ADD_USER}`,
    params,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UpdateUser = async (data) => {
  return await AxiosAuthInstance.put(
    `${LoginAppConstants.LoginConstant.API_ROUTES.USER.UPDATE_USER}`,
    data,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const UpdatePassword = async (data) => {
  return await AxiosAuthInstance.put(
    `${LoginAppConstants.LoginConstant.API_ROUTES.USER.UPDATE_PASSWORD}`,
    data,
    { headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
// users handlers end

// Roles handlers start

export const GetAllRoles = async (language) => {
  return await AxiosAuthInstance.get(
    `${LoginAppConstants.LoginConstant.API_ROUTES.ROLES.ALL_ROLES}?language=${language}&limit=8&page=1&orderBy=asc&sortBy=name`,
    { headers }
  );
};
export const GetUserDetails = async (id) => {
  return await AxiosAuthInstance.get(
    `${LoginAppConstants.LoginConstant.API_ROUTES.USER.GET_USER}?id=${id}`,
    { headers }
  ).then((response) => response)
    .catch((error) => error);
};
