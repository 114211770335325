import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GetPaginatedNews } from '../../handlers/news.handlers';
import TableDropdown from '../Dropdowns/TableDropdown.js';
import { connect } from 'react-redux';
import {
  dispatchNewsList,
  dispatchNewsListFilter,
} from '../../redux/action-dispatchers/news.dispatchers';
// import SportsDropDown from '../Dropdowns/SportsDropDown';
import NewsTypeDropDown from '../Dropdowns/NewsTypeDropDown';
import LeaguesDropDown from '../Dropdowns/LeaguesDropDown';
import { useHistory } from 'react-router';
import { GetAllDefaultLeagues } from '../../handlers/leagues.handlers';
import {
  dispatchAllLeagues,
  dispatchSelectedLeague,
} from '../../redux/action-dispatchers/leagues.dispatchers';
import ArticleStatusDropDown from '../Dropdowns/ArticleStatusDropdown';
import AspectRatio from 'react-aspect-ratio';
import { css } from '@emotion/core';
import PulseLoader from 'react-spinners/PulseLoader';
import { _getDateTime, _dateTimeFormat } from '../../constants/appDefaults';
import { mapArticleToLeague } from '../../handlers/news.handlers';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const TwitterTable = (props) => {
  const mHistory = useHistory();
  const [newsType, setNewsType] = useState('All');
  const [newsStatusType, setNewsStatusType] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [getSports, setGetSports] = useState('All');
  useEffect(() => {
    dispatchSelectedLeague({
      selectedLeague: 'All',
      selectedLeagueFilter: 'All',
    });
  }, []);

  useEffect(() => {
    getNews(
      1,
      parseInt(props.limit),
      props.selectedLeague,
      newsType,
      newsStatusType,
      false
    );
  }, [props.selectedLeague, newsType, newsStatusType]);

  const leagueName = (id) => {
    const leagueInfo = props?.leagues?.find((item) => item?.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };
  // const onSportsTypeChange = (value) => {
  //   setGetSports(value);
  // };
  const getNews = (
    page,
    _limit,
    _selectedLeague,
    _newsStatusType,
    _newsType,
    isLoadMore
  ) => {
    isLoadMore && setIsLoadingMore(true);

    GetPaginatedNews(
      page,
      parseInt(props.limit),
      props.selectedLeague,
      newsType,
      newsStatusType,
      {
        Authorization: props.Authorization,
      }
    )
      .then((response) => {
        isLoadMore && setIsLoadingMore(false);

        if (response.status) {
          if (isLoadMore) {
            dispatchNewsList(response.data);
            setIsLoading(true);
          } else {
            dispatchNewsListFilter(response.data);
          }
        } else {
        }
      })

      .catch((error) => {
        isLoadMore && setIsLoadingMore(false);
      });
  };

  const onView = (newsId) => {
    mHistory.push(`/twitter/${newsId}`);
  };

  const onNewsTypeChange = (event) => {
    setNewsType(event.target.value);
  };

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  'font-semibold text-lg ' +
                  (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
              >
                Twitter Feed
              </h3>
            </div>
            <div
              className="relative w-full px-8 max-w-full flex-grow flex-1"
              style={{ textAlign: 'right' }}
            >
              <NewsTypeDropDown
                selectedValue={newsType}
                onChange={onNewsTypeChange}
              />
              <span style={{ marginLeft: 20 }}>
                <LeaguesDropDown />
              </span>
              {/* <span style={{ marginLeft: 20 }}>
                <SportsDropDown onChange={onSportsTypeChange} />
              </span> */}
              <span style={{ marginLeft: 20 }}>
                <ArticleStatusDropDown
                  value={newsStatusType}
                  onChange={(event) => setNewsStatusType(event.target.value)}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Thumb Image
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Title
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Sports
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  League
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Match
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Tagged League
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Published Date
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Status
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                ></th>
              </tr>
            </thead>

            <tbody>
              {!isLoading ? (
                GetPaginatedNews
              ) : (
                <PulseLoader color="#23A5E0" css={override} size={10} />
              )}

              {props.docs.map((news) => (
                <tr>
                  <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    {/* <AspectRatio
                      ratio={16 / 9}
                      style={{
                        background : `url('${news.imgUrl}')`,
                        width:'450px',
                        height:'253px',
                        backgroundSize : 'cover',
                        backgroundRepeat :'no-repeat'
                      }}
                    > */}
                    {/* <img
                        src={news.imgUrl}
                        className="h-12 w-12 bg-white"
                        alt="..."
                      ></img> */}
                    {/* </AspectRatio> */}

                    <div
                      style={{
                        background: `url('${news.imgUrl}?${news._id}') 0% 0% / cover no-repeat`,
                        width: '100px',
                        height: '53px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    ></div>
                  </th>
                  <td
                    className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    onClick={() => onView(news._id)}
                    style={{ cursor: 'pointer', fontWeight: 'bolder' }}
                  >
                    {news.headline}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.sports && news.sports.name ? news.sports.name : 'N/A'}
                  </td>

                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {leagueName(news.league)}
                    {/* {news.league ? news.league : 'N/A'} */}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {leagueName(news.league)}
                    {/* {news.league ? news.league : 'N/A'} */}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {leagueName(news.league)}
                    {/* {news.league ? news.league : 'N/A'} */}
                  </td>
                  {/* news publish time */}
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {_dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')}
                    {/* {news.copyrights === 'PTI'
                      ? props.selectedLanguage === 'english'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''}
                    {news.copyrights === 'Opta'
                      ? props.selectedLanguage === 'english'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''}
                    {news.copyrights === 'PTI_Bhasha'
                      ? props.selectedLanguage === 'hindi'
                        ? _dateTimeFormat(
                            news.PublishedAt,
                            'ddd, DD MMM hh:mm A'
                          )
                        : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : ''} */}
                    {/* {news.copyrights === 'PTI'
                      ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                    {/* {news.copyrights === 'PTI_Bhasha'
                      ? _dateTimeFormat(news.PublishedAt, 'ddd, DD MMM hh:mm A')
                      : _getDateTime(news.PublishedAt, 'ddd, DD MMM hh:mm A')} */}
                  </td>
                  <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {news.showArticle ? (
                      <span style={{ color: 'green', fontWeight: 'bold' }}>
                        Published
                      </span>
                    ) : (
                      <span style={{ color: 'red', fontWeight: 'bold' }}>
                        Draft
                      </span>
                    )}
                  </td>
                  {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <TableDropdown showArticle={news.showArticle} newsId={news._id} onView={onView} />
                    <button
                      className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                      type="button"
                      onClick={() => onView(news._id)}
                    >
                      Manage
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          {props.hasNextPage && !isLoadingMore && (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  getNews(
                    parseInt(props.page) + 1,
                    parseInt(props.limit),
                    props.selectedLeague,
                    newsType,
                    newsStatusType,
                    true
                  );
                }}
              >
                Load More
              </button>
            </div>
          )}
          <div style={{ textAlign: 'center', padding: 10 }}>
            <PulseLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={10}
            />
          </div>
        </div>
      </div>
    </>
  );
};

TwitterTable.defaultProps = {
  color: 'light',
};

TwitterTable.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};
const mapStateToProps = (state) => {
  return {
    docs: state.newsReducer.docs,
    page: state.newsReducer.page,
    limit: state.newsReducer.limit,
    leagues: state.leaguesReducer.leagues,
    hasNextPage: state.newsReducer.hasNextPage,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedLanguage: state.languageReducer,
  };
};
export default connect(mapStateToProps)(TwitterTable);
