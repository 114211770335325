import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactPlayer from 'react-player';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import TableDropdown from '../Dropdowns/TableDropdown.js';
import LeaguesDropDown from '../Dropdowns/LeaguesDropDown.js';
import FilteredLeagueDropDown from '../Dropdowns/FilteredLeagueDropDown.js';
import VideoTypeDropDown from '../Dropdowns/VideoTypeDropDown';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  dispatchAllLeagues,
  dispatchSelectedLeague,
} from '../../redux/action-dispatchers/leagues.dispatchers.js';
import { GetTaggedLocation } from '../../handlers/twitter.handlers' // Trending Twitter Location
import { dispatchSelectedSports } from '../../redux/action-dispatchers/sports.dispatchers.js';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  ChangeVideoStatus,
  GetAllVideos,
  GetAllYoutubeVideos,
} from '../../handlers/videos.handlers.js';
import { ToggleVideoFeatured } from '../../handlers/featuredContent.handler';
import {
  dispatchCurrnetVideosPage,
  dispatchResetVideos,
  dispatchToggleVideoStatus,
  dispatchVideosList,
  dispatchVideosListFilter,
} from '../../redux/action-dispatchers/videos.dispatcher.js';
import { Button, Checkbox } from '@material-ui/core';
import { _getDateTime, _dateTimeFormat, _dateTimeFormatinIST } from '../../constants/appDefaults';

import { css } from '@emotion/core';
import SyncLoader from 'react-spinners/SyncLoader';
import SportsDropDown from '../Dropdowns/SportsDropDown.js';
import VideoStatusDropDown from '../Dropdowns/VideoStatusDropdown.js';
import { API_BASE_PATH } from '../../constants/workerConstants.js';
import { logoutUser } from '../../services/auth/index.js';
import LoginAppConstants from '../../constants/LoginAppConstants.js';
{/**Modal Css */ }
import TrendsAllLocations from './TrendsAllLocations';
import Fab from "@mui/material/Fab";
import { Tooltip, Overlay } from "react-bootstrap";

const override = css`
  border-color: red;
  position: fixed;
  right: 42%;
  top: 45%;
`;
const accessToken = localStorage.getItem(
  LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
);
const VideosTable = (props) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [videoStatusType, setVideoStatusType] = useState(localStorage.getItem("videoStatusType")??'All');
  const [sportsType, setSportsType] = useState();
  const [videoType, setVideoType] = useState(localStorage.getItem("videoType")??'');
  const [getsports, setGetSports] = useState(localStorage.getItem("videoSportsFilter")??'All');
  const [leagueFilter, setLeagueFilter]  = useState(localStorage.getItem("videosLeagueFilter")??"All")
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEdit, showEditHandler] = useState(false);
  const [showMedia, setShowMedia] = useState(null);
  const [isPlaying, setIsplaying] = useState(false);
  const [mediaThumbnail, setMediaThumbnail] = useState(null);
  const [togglingStatus, setTogglingStatus] = useState({
    index: 0,
    loading: false,
  });
  const [searchString, setSearchTags] = useState('');
  const [searchVideo, setSearchVideo] = useState(localStorage.getItem("videoSearch")??'');
  const [language, setLanguage] = useState(localStorage.getItem('Current_Language'))
  const [video_Id, setVideo_Id] = useState("");
  const [open, setOpen] = useState(false); // for Modal Open State
  const [twitterTaggedLocations, setTwitterTaggedLocations] = useState([]) // All trending places tagged from Trends api
  const [showFeatureEdit, showEditFeatureHandler] = useState(false); //To Update featured status 
  const [featureImage, setFeatureImage] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [featureStatus, setFeatureStatus] = useState("");
  var searchStringGlobal = ' ';
  let searchVideoString = '';
  let timer = null;
  let fileInput = React.useRef();
  var lastSports = 'All';
  var lastLeague = 'All';

  {/**Modal open and close */ }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (event) => {
    setSearchTags(event.target.value);

    searchStringGlobal = event.target.value;

    // if (searchStringGlobal === '') {
    //   searchStringGlobal = 'noTags';
    // }

    getYoutubeVideos(
      props.page,
      10,
      props.selectedLeague,
      false,
      'Youtube',
      searchStringGlobal,
      videoStatusType,
      props.selectedSports
    );
  };
  const handleInputString = (event) => {
    setSearchVideo(event.target.value);
    localStorage.setItem("videoSearch", event.target?.value )
    // if (timer) {
    //   clearTimeout(timer);
    // }
    // timer = setTimeout(() => {
    //   searchVideoString = event.target?.value;

    //   getVideos(
    //     props.page,
    //     parseInt(props.limit),
    //     props.selectedLeague,
    //     props.selectedSports,
    //     false,
    //     videoType,
    //     props.selectedSports,
    //     startDate,
    //     endDate,
    //     searchVideoString,
    //     false
    //   );
    // }, 500);
  };

  const onSportsTypeChange = (value) => {
    setGetSports(value);
    setLeagueFilter("");
    localStorage.setItem("videoSportsFilter", value);
    localStorage.removeItem("videosLeagueFilter");
  };
  useEffect(() => {
    if (typeof window != undefined) {
      setLanguage(localStorage.getItem('Current_Language'))
    }
  }, [typeof window])
  const onVideoTypeChange = (event) => {
    localStorage.setItem("videoType", event.target.value)
    setVideoType(event.target.value);
  };
  const onSportChange = (value) => {
    getYoutubeVideos(
      1,
      10,
      props.selectedLeague,
      false,
      'Youtube',
      searchStringGlobal,
      videoStatusType,
      props.selectedSports
    );
  };

  // Tagged Locations from twitter Trending part
  useEffect(() => {
    const body = 'section=twitter'
    if (open) {
      GetTaggedLocation(body)
        .then((response) => {
          setTwitterTaggedLocations(response?.data)
        })
        .catch((error) => error)
    }
  }, [open])

  useEffect(() => {
    Axios.get(
      `${API_BASE_PATH}leagues/leagueWatchList`,
      { headers: { 'cms-access-token': accessToken, source: 'cms' } },
      {
        params: JSON.stringify({
          username: localStorage.getItem('email'),
        }),
      }
    ).then((res) => {
      if (res.data.message == 'Error in authorization middleware') {
        alert(`${res.data.message} in League watch list`);
      } else dispatchAllLeagues(res.data.data?.data);
    });
  }, []);
  const leagueName = (id) => {
    const leagueInfo = props.leagues?.find((item) => item.comp_id === id);
    if (leagueInfo) {
      return leagueInfo.abbr;
    }
    return 'N/A';
  };

  const mHistory = useHistory();
  useEffect(() => {
    dispatchSelectedLeague({
      selectedLeague: localStorage.getItem("videosLeagueFilter"),
      selectedLeagueFilter: localStorage.getItem("videosLeagueFilter"),
    });
  }, []);

  useEffect(() => {
    dispatchSelectedSports({
      selectedSports: localStorage.getItem("videoSportsFilter"),
      selectedSportsFilter:  localStorage.getItem("videoSportsFilter"),
    });
  }, []);
  const updateStartDate = (time) => {
    setStartDate(time);
  };
  const updateEndDate = (time) => {
    setEndDate(time);
  };
  var page = 1;

  const checkFilter = () => {
    if (
      lastSports != props.selectedSports ||
      lastLeague != props.selectedLeague
    ) {
      lastSports = props.selectedSports;
      lastLeague = props.selectedLeague;
      //dispatchResetVideos()
      page = 1;
    } else {
      page = props.page;
    }
  };
  // start date end date
  const onClickOpenMedia = (media, thumb) => {
    showEditHandler(true);
    setShowMedia(media);
    setMediaThumbnail(thumb);
  };

  //leaguefilter
  const onLeagueChange = (value) =>{
    localStorage.setItem("videosLeagueFilter", value);
    setLeagueFilter(value);
  }
  // useEffect(() => {
  //   const search = localStorage.getItem('searchVideo');
  //   if (search) {
  //     setSearchVideo(JSON.parse(search));
  //   }
  // }, []);
  // useEffect(() => {
  //   localStorage.setItem('searchVideo', JSON.stringify(searchVideo));
  // });
  useEffect(() => {
    props.isYoutube === true ? checkFilter() : (page = props.page);

    props.isYoutube === true
      ? getYoutubeVideos(
        parseInt(props.page),
        parseInt(props.limit),
        props.selectedLeague,
        false,
        'Youtube',
        '',
        videoStatusType,
        props.selectedSports
      )
      : getVideos(
        1,
        parseInt(props.limit),
        props.selectedLeague,
        props.selectedSports,
        false,
        videoType,
        props.selectedSports,

        startDate,
        endDate,
        searchVideo
      );
  }, [
    props.selectedSports,
    props.selectedLeague,
    videoStatusType,
    getsports,
    videoType,
    props.page,
    startDate,
    endDate,
    searchVideo,
  ]);

  const getVideos = (
    page,
    _limit,
    _selectedLeague,
    _selectedSports,
    _videoStatusType,
    _videoType,
    _id,
    startDate,
    endDate,
    _search,
    isLoadMore
  ) => {
    setIsLoading(true);
    let body = {
      page: props.page,
      limit: parseInt(props.limit),
      league: leagueFilter,
      sportId:getsports,
      copyRight: videoType,
      startDate: startDate,
      endDate: endDate,
      searchKey: searchVideo,
    }

    if (videoStatusType != 'All'){
      body = {
        ...body,
        status: videoStatusType,
      }
    }
    GetAllVideos(body)
      .then((response) => {
        setIsLoading(false);
        // console.log('resopone',response)
        if (response.status == false) {
          // logoutUser();
          // mHistory.push('/');
        }
        if (isLoadMore) {
          dispatchVideosList(response.data);
        } else {
          dispatchVideosListFilter(response.data);
        }
      })
      .catch((error) => {
        isLoadMore && setIsLoadingMore(false);
      });
  };

  const getYoutubeVideos = (
    page,
    _limit,
    _selectedLeague,
    isLoadMore,
    _copyright,
    _search,
    _videoStatusType,
    _selectedSports
  ) => {
    isLoadMore && setIsLoadingMore(true);
    GetAllYoutubeVideos(
      page,
      _limit,
      _selectedLeague,
      _copyright,
      _search,
      _videoStatusType,
      _selectedSports,

      {
        Authorization: props.Authorization,
      }
    )
      .then((response) => {
        isLoadMore && setIsLoadingMore(false);
        if (response.status) {
          if (isLoadMore) {
            dispatchVideosList(response.data);
          } else {
            dispatchVideosListFilter(response.data);
          }
        }
      })
      .catch((error) => {
        isLoadMore && setIsLoadingMore(false);
      });
  };
  const handleChange = () => {
    if (featureImage) {
      updateFeatureStatus(video_Id, featureStatus);
    }
    else {
      alert('Please upload a Image in the Ratio of 4:3')
    }
  };
  const handleFeaturedStatus = (item) => {
    setVideo_Id(item?._id);
    setFeatureStatus(item?.isFeatured);
    setUpdateImage(item?.thumbnail?.aspectRatio_43?.url)
    if (item?.isFeatured) {
      updateFeatureStatus(item?._id, item?.isFeatured);
    }
    else {
      showEditFeatureHandler(true);
    }
  };
  const updateFeatureStatus = (id, status) => {
    const data = {
      _id: id,
      image: featureImage,
      isFeatured: !status
    };
    const formData = new FormData();

    Object?.keys(data)?.forEach(function (key) {
      formData?.append(key, data[key]);
    });
    ToggleVideoFeatured(formData).then((response) => {

      alert(response?.message)
      setFeatureImage('');
      setUpdateImage('');
      window.location.reload(false);
    })
  };

  const onFileSelect = (event) => {
    var file = event.target.files[0];
    event.target.value = '';

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (width !== (4 * height) / 3) {
          alert('Image width and height must be in ratio of 4:3 ');
          return false;
        }
      };
      setFeatureImage(file);
      setUpdateImage(reader.result);

    };
    reader.onerror = function (error) {
      //
    };
  };
  const toggleVideoStatus = (index, videoId, status) => {
    setTogglingStatus({
      index,
      loading: true,
    });
    ChangeVideoStatus(videoId, status, {
      Authorization: props.Authorization,
    })
      .then((response) => {
        setTogglingStatus({
          index: -1,
          loading: false,
        });
        if (response.status) {
          dispatchToggleVideoStatus({ index, status });
        } else {
          alert(JSON.stringify(response.message));
        }
      })
      .catch((error) => {
        setTogglingStatus({
          index: -1,
          loading: false,
        });
        alert(JSON.stringify(error));
      });
  };
  // pagination
  const handlePagination = (event, value) => {
    dispatchCurrnetVideosPage(value);
  };
  // pagination end
  function CopyButton({ row }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleClick = async () => {
      if (row?.permalink) {
        if ("clipboard" in navigator) {
          await navigator.clipboard.writeText(row.permalink);
          setShow(true);
          setTimeout(() => setShow(false), 500); // Hide the tooltip after 2 seconds
        }
      }
    };

    return (
      <>
        <Button
          ref={target}
          onClick={handleClick}
          disabled={!row?.permalink}
          title="Click to copy permalink"
          style={{ outline: "none", minWidth: '20px', paddingLeft: "5px" }}
          className={`btn btn-icon btn-light btn-sm mx-3`}
        >
          <img
            src="/copy-1.png"
            alt="copy to clipboard"
            style={{ width: "25px", height: "25px", marginLeft: "10px" }}
          />
        </Button>

        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Copied!
            </Tooltip>
          )}
        </Overlay>
      </>
    );
  }
  //Media popup

  const getVideoTableItem = (video, position) => {
    if (video.media.content.length > 0) {
      const videoUrl = video.media.content[1]
        ? video.media?.content[1]?.url
        : video.media?.content[0]?.url;
      const height = video.media?.content[1]
        ? video.media?.content[1].height
        : video.media?.content[0].height;
      const width = video.media.content[1]
        ? video.media?.content[1].width
        : video.media?.content[0].width;
      const thumbnail =
        video.media?.content[video.media?.content?.length - 1].type === 'image'
          ? video.media?.content[video.media?.content?.length - 1]?.url
          : video.media?.thumbnail[video.media?.thumbnail?.length - 1]?.url;
      return (
        <tr
        >
          <th className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
            <img
              src={thumbnail}
              style={{
                // maxHeight: 300,
                // minHeight: 253,
                // maxWidth: 450,
                // minWidth: 400,
                maxHeight: 100,
                minHeight: 53,
                maxWidth: 53,
                minWidth: 100,
              }}
              height={300}
              width={400}
              alt="..."
              onClick={() => onClickOpenMedia(videoUrl, thumbnail)}
            ></img>
          </th>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.copyright}
          </td>
          <td
            className="border border-green-800 border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            onClick={() => onView(video._id)}
            style={{ cursor: 'pointer', fontWeight: '500' }}
          >
            {video.title}
          </td>
          <td
            className="border border-green-800  border-t-0 px-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4  "
            onClick={() => {
              if (video.status == 0) {

                alert("Video is an unpublished one...")
              }

            }}
            style={{ cursor: "pointer", fontWeight: "bolder" }}
          >
            <div style={{ display: 'flex' }}>

              <a href={video?.permalink}
                target="_blank">
                <img src="/LinkIcon.png" style={{ width: "20px", height: "30px", marginLeft: "20px" }} />
              </a>
              <CopyButton row={video} />
            </div>
          </td>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.sports && video.sports.name ? video.sports.name : 'N/A'}
          </td>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {props.selectedLeague == 'All' ? 'All' : leagueName(video.league)}
            {video.AllLeague !== undefined && ' / ' + video.AllLeague}
          </td>
          <td className="border border-green-800 border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.teams && video?.teams?.length
              ? video.teams
                .map((items) => {
                  return items?.name;
                })
                .join(', ')
              : 'N/A'}
          </td>
          {props.isYoutube === true ? (
            <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {video.channelTitle}
            </td>
          ) : (
            ''
          )}
          {props?.isYoutube === true ? (
            <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {video.tags}
            </td>
          ) : (
            ''
          )}
          {/* <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">N/A</td> */}
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {language?.toLocaleLowerCase() == 'english'
              ?
              _dateTimeFormatinIST(
                video.lastUpdate,
                'ddd, DD MMM hh:mm A'
              )
              :
              _dateTimeFormat(
                video.lastUpdate,
                'ddd, DD MMM hh:mm A'
              )}
            {/* {video.copyrights === "PTI"
              ? _dateTimeFormat(video.date, "ddd, DD MMM hh:mm A")
              : _getDateTime(video.date, "ddd, DD MMM hh:mm A")} */}
          </td>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.status === 1 ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>
                Published
              </span>
            ) : (
              <span style={{ color: 'red', fontWeight: 'bold' }}>Draft</span>
            )}
          </td>
          <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.tagHome ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>Tagged</span>
            ) : (
              <span style={{ color: 'red', fontWeight: 'bold' }}>Untagged</span>
            )}
          </td>
          <td className="border border-green-800  px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {video.section == 'TOP_VIDEO' ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>Tagged</span>
            ) : (
              <span style={{ color: 'red', fontWeight: 'bold' }}>Untagged</span>
            )}
          </td>
          <td className="border border-green-800 border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
            {!video.isFeatured ? (
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  handleFeaturedStatus(video);
                  // onView(video._id)
                }}
              >
                Tag
              </button>
            ) : (
              <button
                className={`bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => handleFeaturedStatus(video)}
              >
                Untag
              </button>
            )}
          </td>
          <td className="border border-green-800 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
            {video.status === 1 ? (
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  toggleVideoStatus(position, video._id, 0);
                  // onView(video._id)
                }}
              >
                Hide
              </button>
            ) : (
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => toggleVideoStatus(position, video._id, 1)}
              >
                Unhide
              </button>
            )}
          </td>
        </tr>
      );
    } else {
      return <></>;
    }
  };
  const onView = (videoId) => {
    props.isYoutube === true
      ? mHistory.push(`/videos/${videoId}/yes`)
      : mHistory.push(`/videos/${videoId}/no`);
  };
  return (
    <>
      <div className="edit__popup" style={{ display: open ? 'none' : 'block' }}>
        <div
          onClick={() => showEditHandler(false)}
          className={showEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '55%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                // alert(isPlaying);
                // setIsplaying(false);

                showEditHandler(false);
                // setIsplaying((isPlaying) => !isPlaying);
                // alert(isPlaying);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            <ReactPlayer
              playing={showEdit ? true : false}
              controls
              url={showMedia}
              light={mediaThumbnail}
            />
          </div>
        </div>
      </div>
      <div className="edit__popup">
        <div
          onClick={() => showEditFeatureHandler(false)}
          className={showFeatureEdit ? 'edit__backdrop active' : 'edit__backdrop'}
        ></div>

        <div
          className={showFeatureEdit ? 'edit__popup-body active' : 'edit__popup-body'}
          style={{ width: '45%' }}
        >
          {' '}
          <div className="edit__popup-inner">
            <i
              onClick={() => {
                showEditFeatureHandler(false);
              }}
              className="far fa-times-circle edit__popup-close"
            ></i>
            Upload Image:{' '}

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px" }}>

              <div style={{
                width: 284,
                height: 213,
                border: '2px solid gray', marginBottom: "10px"
              }}>
                {updateImage ? <img src={updateImage} style={{ height: 210, maxWidth: 280 }} /> : null}
              </div>
              <div style={{ fontWeight: "600", color: "gray" }}>Please upload the image in the ratio of 4:3 *</div>
              <div>
                <input
                  ref={fileInput}
                  type="file"
                  name="thumbnail Image"
                  onChange={onFileSelect}
                  style={{
                    backgroundColor: "#000",
                    borderRadius: "1px solid #111",
                    height: 35,
                    width: 40,
                    position: "absolute",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                />
                <i
                  className="fas fa-camera fa-2x"
                  style={{
                    margin: 8,
                    color: "#555",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <Button
                variant="contained"
                onClick={() => handleChange()}
                style={{ marginBottom: '1.5%', backgroundColor: "#50C878", color: "#fff" }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (props.color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
        style={{ display: open ? 'none' : 'block' }}
      >
        <div className="rounded-t mb-0 px-2 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-2 max-w-full flex-grow flex-1">
              <h3
                className={
                  'font-semibold text-lg ' +
                  (props.color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {props.isYoutube === true ? 'Youtube Videos' : 'Videos'}
                <div
                  className="relative w-full  max-w-full flex-grow flex-1"
                  style={{ textAlign: 'right' }}
                >
                  <span style={{ marginLeft: 5 }}>
                    <VideoTypeDropDown
                      selectedValue={videoType}
                      onChange={onVideoTypeChange}
                    />
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    <SportsDropDown
                      selectedValue={getsports}
                      onChange={onSportsTypeChange}
                    />
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    <FilteredLeagueDropDown sports={getsports} onChange={onLeagueChange}/>
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    <VideoStatusDropDown
                      value={videoStatusType}
                      onChange={(event) =>{
                        setVideoStatusType(event.target.value);
                        localStorage.setItem("videoStatusType", event.target.value)
                      }
                      }
                    />
                  </span>
                </div>
              </h3>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                  alignItems:"center"
                }}
              >
                {props.isYoutube === true ? (
                  <input
                    value={searchString}
                    style={{
                      border: '2px solid #acb9ce',
                      borderRadius: 10,
                      padding: '4px',
                    }}
                    placeholder="Search..."
                    // value={this.state.query}
                    onChange={handleInputChange}
                  />
                ) : (
                  <input
                    value={searchVideo}
                    style={{
                      border: '2px solid #acb9ce',
                      borderRadius: 10,
                      // marginRight: '3%',
                      padding: '4px',
                      // paddingLeft: '10px',
                    }}
                    placeholder="Search..."
                    // value={this.state.query}
                    onChange={handleInputString}
                  />
                )}
              <Button style={{marginRight:"50px", minWidth:"fit-content", background:"#1876d1", padding:"5px 10px", borderRadius:"10px", color:"#FFFFFF", marginLeft:"10px", fontWeight:'700'}} onClick={()=>{
                    localStorage.removeItem("videoStatusType");
                    setVideoStatusType("All");
                    setSearchTags("");
                    dispatchSelectedLeague({
                      selectedLeague: "",
                      selectedLeagueFilter: "",
                   });
                   dispatchSelectedSports({
                    selectedSports: "",
                    selectedSportsFilter: "",
                  });
                  setGetSports("All");
                  localStorage.removeItem("videoType");
                  setSearchVideo("");
                  setVideoType('');
                  localStorage.removeItem("videoSearch");
                  localStorage.removeItem("videosLeagueFilter");
                  localStorage.removeItem("videoSportsFilter");
                  setLeagueFilter("")
             }}>
              Reset Filter
             </Button>
              </div>
            </div>
            {/* <div className="relative w-full px-2 max-w-full flex-grow flex-1">
        
              <input
                style={{ border: "2px solid #acb9ce", borderradius: 10 }}
                placeholder="Search hashtag"
                // value={this.state.query}
                onChange={handleInputChange}
              />
            </div> */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                position: 'relative',
                left: '3.4%',
              }}
            >
              {/* <DateFilter startDate={startDate} endDate={endDate} /> */}
              <div className="wrapper">
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="relative ">
                    <DateTimePicker
                      label="Start Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy, hh:mm a"
                      value={startDate}
                      onChange={updateStartDate}
                      style={{
                        marginRight: '10px',
                        width: '40%',
                      }}
                    />

                    <DateTimePicker
                      label="End Date"
                      // emptyLabel="End Date"
                      autoOk
                      hideTabs
                      format="dd/MM/yyyy, hh:mm a"
                      value={endDate}
                      onChange={(e) => updateEndDate(e)}
                      style={{
                        width: '40%',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider> */}
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Video
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Copyright
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Title
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Perma Link
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Sports
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  League
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Teams
                </th>
                {props.isYoutube === true ? (
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    YT Channel
                  </th>
                ) : (
                  ''
                )}
                {props.isYoutube === true ? (
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (props.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Tags
                  </th>
                ) : (
                  ''
                )}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Published Date
                </th>

                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Status
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Home
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Top
                </th>
                <th
                  className={
                    'border border-green-800 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >Featured</th>
                <th
                  className={
                    'border border-green-800 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (props.color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                ></th>
              </tr>
            </thead>
            {!isLoading ? (
              GetAllVideos || GetAllYoutubeVideos
            ) : (
              <SyncLoader color="#23A5E0" css={override} size={12} />
            )}
            <tbody>
              {props.docs.map((video, position) =>
                getVideoTableItem(video, position)
              )}
            </tbody>
          </table>
          {/* <div
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              justifyContent: 'center',
            }}
          > */}
          {/* {props.hasPrevPage && parseInt(props.page) >= 2 && !isLoadingMore && (
              <div style={{ textAlign: 'center', padding: 20 }}>
                <button
                  className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                  type="button"
                  onClick={() => {
                    props.isYoutube === true
                      ? getYoutubeVideos(
                          parseInt(props.page) - 1,
                          parseInt(props.limit),
                          props.selectedLeague,
                          true,
                          'Youtube',
                          searchString,
                          videoStatusType,
                          props.selectedSports,
                          startDate,
                          endDate
                        )
                      : getVideos(
                          parseInt(props.page) - 1,
                          parseInt(props.limit),
                          props.selectedLeague,
                          props.selectedSports,
                          videoStatusType,
                          videoType,
                          props._id,
                          startDate,
                          endDate,
                          false,
                          searchVideoString
                        );
                  }}
                >
                  Load Prev
                </button>
              </div>
            )} */}
          {/* {props.hasNextPage && !isLoadingMore && (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <button
                className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  props.isYoutube === true
                    ? getYoutubeVideos(
                        parseInt(props.page) + 1,
                        parseInt(props.limit),
                        props.selectedLeague,
                        true,
                        'Youtube',
                        searchString,
                        videoStatusType,
                        props.selectedSports,
                        startDate,
                        endDate
                      )
                    : getVideos(
                        parseInt(props.page) + 1,
                        parseInt(props.limit),
                        props.selectedLeague,
                        props.selectedSports,
                        videoStatusType,
                        videoType,
                        props._id,
                        startDate,
                        endDate,
                        searchVideoString,
                        true
                      );
                }}
              >
                Load More
              </button>
            </div>
          )} */}
          {/* </div> */}


          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                page={props.page}
                onChange={handlePagination}
                count={props.totalPages}
                variant="outlined"
                color="primary"
              />
            </Stack>
          </div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <SyncLoader
              color="#23A5E0"
              loading={isLoadingMore}
              css={override}
              size={12}
            />
          </div>
        </div>
      </div>
      {/*Trending Keyword List*/}
      <div style={{ position: 'fixed', bottom: '30px', right: '20px' }}>
        <Fab variant="extended" size="medium" color="primary" aria-label="add"
          onClick={() => setOpen(true)}
          style={{ marginRight: 5 }}>
          Trending Keywords
        </Fab>
      </div>
      <>
        <div className="edit__popup" style={{ display: open ? 'block' : 'none', }}>
          <div
            onClick={() => setOpen(false)}
            className={
              open ? "edit__backdrop active" : "edit__backdrop"
            }
          ></div>

          <div
            className={
              open ? "edit__popup-body active" : "edit__popup-body"
            }
            style={{
              zIndex: 1000, maxWidth: '1000px', width: '1000px', height: '80%',
              position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="edit__popup-heading">Top Trends</div>
              <div className="edit__popup-inner"
                style={{ marginTop: '7px' }}>
                <i
                  onClick={() => setOpen(false)}
                  className="far fa-times-circle edit__popup-close"
                ></i>

              </div>
            </div>
            <TrendsAllLocations twitterTaggedLocations={twitterTaggedLocations} />
          </div>
        </div>
      </>
    </>
  );
};

VideosTable.defaultProps = {
  color: 'light',
};

VideosTable.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
  isYoutube: PropTypes.bool,
};
const mapStateToProps = (state) => {
  return {
    totalPages: state.videosReducer.totalPages,
    docs: state.videosReducer.docs,
    page: state.videosReducer.page,
    limit: state.videosReducer.limit,
    leagues: state.leaguesReducer.leagues,
    _id: state.videosReducer._id,
    hasNextPage: state.videosReducer.hasNextPage,
    hasPrevPage: state.videosReducer.hasPrevPage,
    Authorization: state.authReducer.authorizationToken,
    selectedLeague: state.leaguesReducer.selectedLeague,
    selectedSports: state.sportsReducer.selectedSports,
  };
};
export default connect(mapStateToProps)(VideosTable);