import LoginAppConstants from "../../constants/LoginAppConstants";
import { parseJwt } from "../../constants/appDefaults";
import { dispatchLoginUser } from "../../redux/action-dispatchers/auth.dispatchers";
import { Logout } from "../../handlers/admin.handlers";

const setSessionData = (accessToken) => {
  localStorage.setItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN,
    accessToken
  );
  localStorage.setItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_IS_USER_LOGGED_IN,
    "true"
  );
};
export const loginUser = (accessToken) => {
  setSessionData(accessToken);
};

const getUserDataFromSession = (accessToken) => {
  return parseJwt(accessToken);
};
export const validatelocalStorage = () => {
  const isLoggedIn =
    localStorage.getItem(
      LoginAppConstants.SessionStorageConstants
        .SESSION_STORAGE_IS_USER_LOGGED_IN
    ) !== null &&
    localStorage.getItem(
      LoginAppConstants.SessionStorageConstants
        .SESSION_STORAGE_IS_USER_LOGGED_IN
    ) === "true";

  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  const userData =
    accessToken !== undefined && accessToken !== null
      ? getUserDataFromSession(accessToken)
      : undefined;
  return {
    userData,
    isLoggedIn,
    tokens:
      accessToken === null
        ? undefined
        : {
            accessToken,
          },
  };
};
export const checkAuthentication = () => {
  const sessionData = validatelocalStorage();

  if (sessionData.isLoggedIn && sessionData.tokens !== undefined) {
    loginUser(sessionData.tokens.accessToken);
    return true;
  }
  return false;
};

export const getAuthData = () => {
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  const authData =
    accessToken !== undefined && accessToken !== null
      ? getUserDataFromSession(accessToken)
      : undefined;
  return authData;
};
let userEmail = localStorage.getItem("email");
export const logoutUser = () => {
  dispatchLoginUser({
    isLoggedIn: false,
    authorizationToken: undefined,
    userType: null,
    userData: undefined,
    userProfilePic: undefined,
    languagesAllowed: undefined,
    currentLanguage: undefined,
    userRole: undefined,
    modulePermission: undefined,
    firstModuleName: undefined,
    userLanguage: undefined,
  });
  // localStorage.clear();
  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i);
  
    // Remove the key and its corresponding value from local storage
    localStorage.setItem(key,"");
  }
  let body = { email: userEmail };

  Logout(body);
};

export const getTokens = () => {
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  const refreshToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_REFRESH_TOKEN
  );
  return {
    accessToken,
    refreshToken,
  };
};

export const getProfileName = () => {
  const accessToken = localStorage.getItem(
    LoginAppConstants.SessionStorageConstants.SESSION_STORAGE_ACCESS_TOKEN
  );
  const authData =
    accessToken !== undefined && accessToken !== null
      ? getUserDataFromSession(accessToken)
      : undefined;
  return `${authData?.firstName} ${authData?.lastName}`;
};
