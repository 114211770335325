import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { GetUsersProductivityReportNews } from '../../../handlers/productivity.handlers';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ProductivityDropDown from '../../../components/Dropdowns/ProductivityDropDown';
import { useHistory } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
  dispatchEndDateForNews,
  dispatchStartDateForNews,
} from '../../../redux/action-dispatchers/productivity.dispatchers';
import { connect } from 'react-redux';
const ProductivityReportNews = (props) => {
  const mHistory = useHistory();
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [homeType, setHomeType] = useState('n');

  var date = new Date();
  // Set it to one month ago
  date.setMonth(date.getMonth() - 1);
  // Zero the time component
  date.setHours(0, 0, 0, 0);
  const getProductivityReport = () => {
    if (props.newsEndDate < props.newsStartDate) {
      alert('Start Date should be less than end date');
      dispatchStartDateForNews(date);
      dispatchEndDateForNews(new Date());
    } else {
      const body = {
        page: page,
        limit: 10,
        start_date: props.newsStartDate,
        end_date: props.newsEndDate,
      };

      GetUsersProductivityReportNews(body)
        .then((response) => {
          setDocs(response.data.docs);
          setTotalPages(response.data.totalPages);
        })
        .catch((error) => error);
    }
  };
  useEffect(() => {
    getProductivityReport();
  }, [props.newsStartDate, props.newsEndDate, page]);
  const updateStartDate = (time) => {
    var date = new Date(time),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let formattedDate = [date.getFullYear(), mnth, day].join('-');
    dispatchStartDateForNews(formattedDate);
  };
  const updateEndDate = (time) => {
    var date = new Date(time),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let formattedDate = [date.getFullYear(), mnth, day].join('-');
    dispatchEndDateForNews(formattedDate);
  };
  const onHomeTypeChange = (event) => {
    setHomeType(event.target.value);
  };

  if (homeType === 'videos') {
    mHistory.push(`/productivityReportVideos`);
  }
  const handleViewDetails = (email) => {
    mHistory.push(`/productivityDetailNews/${email}`);
  };
  const handlePagination = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <div className="flex flex-wrap">
          <div className="w-full xl-12 mb-12 xl:mb-0 px-4">
            <div
              className={
                'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
                'bg-white'
              }
              style={{ height: '100%' }}
            >
              <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                <h3
                  className={
                    'font-semibold text-lg' +
                    'text-blueGray-700 productivity_title'
                  }
                >
                  Productivity Report News
                </h3>

                <div className="wrapper">
                  <div
                    className="relative w-full px-10  max-w-full flex-grow flex-1"
                    style={{
                      textAlign: 'right',
                      paddingBottom: '20px',
                      marginTop: '-20px',
                    }}
                  >
                    <ProductivityDropDown
                      selectedValue={homeType}
                      onChange={onHomeTypeChange}
                    />
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="relative" style={{ marginLeft: '50%' }}>
                      <DatePicker
                        label="Start Date"
                        autoOk
                        hideTabs
                        format="dd/MM/yyyy"
                        disableFuture={true}
                        value={props.newsStartDate}
                        onChange={updateStartDate}
                        style={{
                          paddingLeft: '5px',
                          marginRight: '10px',
                          width: '40%',
                          border: '2px solid grey',
                          borderBottom: '1.5px solid gray',
                          borderRadius: '5px',
                        }}
                      />
                      <DatePicker
                        label="End Date"
                        // emptyLabel="End Date"
                        autoOk
                        hideTabs
                        format="dd/MM/yyyy"
                        disableFuture={true}
                        value={props.newsEndDate}
                        onChange={(e) => updateEndDate(e)}
                        style={{
                          paddingLeft: '5px',
                          marginRight: '10px',
                          width: '40%',
                          border: '2px solid grey',
                          borderBottom: '1.5px solid gray',
                          borderRadius: '5px',
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div
                  className="flex justify-center w-full overflow-x-auto py-4  "
                  style={{ widht: '100vw' }}
                >
                  {/* Projects table */}
                  <table
                    className="table-fixed"
                    style={{ background: '#6cf97d52', width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th
                          className={
                            'px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ' +
                            'bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading'
                          }
                        >
                          S No.
                        </th>
                        <th
                          className={
                            'px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ' +
                            'bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading'
                          }
                        >
                          Author
                        </th>

                        <th
                          className={
                            'px-6 align-middle border text-center border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                            'bg-blueGray-50 text-blueGray-500 border-blueGray-100 table_heading'
                          }
                        >
                          News Count
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {docs?.map((item, index) => (
                        <tr>
                          <td className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data_title">
                            {index + 1}
                          </td>
                          <td
                            className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data_title"
                            onClick={() => handleViewDetails(item._id)}
                          >
                            {item._id}
                          </td>
                          <td className="border border-green-800  border-t-0 p-5 px-4 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap table_data">
                            {item.count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: 20,
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    page={page}
                    onChange={handlePagination}
                    count={totalPages}
                    variant="outlined"
                    color="primary"
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    newsStartDate: state.productivityReducer.newsStartDate,
    newsEndDate: state.productivityReducer.newsEndDate,
  };
};
export default connect(mapStateToProps)(ProductivityReportNews);
